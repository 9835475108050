import Acceptance from "./pages/Acceptance";
import DeliveryNotes from "./pages/DeliveryNotes";
import DeliveryNotesItems from "./pages/DeliveryNotesItems";
import MaterialRequests from "./pages/MaterialRequests";
import WarrantyVisit from "./pages/WarrantyVisit";
import PreQuotations from "./pages/PreQuotations";
import PurchaseRequests from "./pages/PurchaseRequests";
import Quotations from "./pages/Quotations";
import SitesDatabase from "./pages/SitesDatabase";
import WorkOrders from "./pages/WorkOrders";
import InventoryManagement from "./pages/InventoryManagement";
import CompanyFleet from "./pages/CompanyFleet";
import MaterialChangeRequest from "./pages/MaterialChangeRequest";
import MobileInternetLines from "./pages/MobileInternetLines";
import ClientInvoices from "./pages/ClientInvoices";
import MissionChangeRequest from "./pages/MissionChangeRequest";
import Attendance from "./pages/Attendance";
import ClientPurchaseOrders from "./pages/ClientPurchaseOrders";
import StatementsManagement from "./pages/StatementsManagement";
import PriceLists from "./pages/PriceLists";
import UsersManagement from "./pages/UsersManagement";
import EmployeeRecords from "./pages/EmployeeRecords";
import AccountsProjects from "./pages/Accounts&Projects/AccountsProjects";
import Vacations from "./pages/Vacations";
import Missions from "./pages/Missions/Missions";
import MissionInfo from "./pages/InformationPages/Missions/MissionInfo";
import Quotation from "./pages/InformationPages/Quotation/Quotation";
import AcceptanceInfo from "./pages/InformationPages/Acceptance/AcceptanceInfo";
import PreQuotation from "./pages/InformationPages/PreQuotation/PreQuotation";
import SiteInfo from "./pages/InformationPages/SitesDatabase/SiteInfo";
import MailInfo from "./pages/InformationPages/MailService/MailInfo";
import PriceList from "./pages/InformationPages/PriceList/PriceList";
import CarInfo from "./pages/InformationPages/CompanyFleet/CarInfo";
import WorkOrdersInfo from "./pages/InformationPages/WorkOrders/WorkOrdersInfo";
import EmployeeInfo from "./pages/InformationPages/EmployeeRecords/EmployeeInfo";
import EmployeeHistory from "./pages/InformationPages/EmployeeRecords/EmployeeHistory/EmployeeHistory";
import MobileInternetLine from "./pages/InformationPages/MobileInternetLine/MobileInternetLine";
import MaterialRequestInfo from "./pages/InformationPages/MaterialRequest/MaterialRequestInfo";
import PurchaseRequestInfo from "./pages/InformationPages/PurchaseRequest/PurchaseRequestInfo";
import InventoryManagementInfo from "./pages/InformationPages/InventoryManagement/InventoryManagementInfo";
import ClientInvoicesInfo from "./pages/InformationPages/ClientInvoices/ClientInvoicesInfo";
import ClientPurchaseOrderInfo from "./pages/InformationPages/ClientPurchaseOrder/ClientPurchaseOrderInfo";
import UsersManagementInfo from "./pages/InformationPages/UsersManagement/UsersManagementInfo";
import MailService from "./pages/MailService/MailService";
import FileManager from "./pages/FileManager/FileManager";
import Dashboard from "./pages/Dashboard/Dashboard";

export const routes = [
  {
    path: "/",
    title: "Dashboard",
    component: Dashboard,
  },
  {
    path: "/missions",
    title: "Missions",
    component: Missions,
  },
  {
    path: "/workorders",
    title: "Work Orders",
    component: WorkOrders,
  },
  {
    path: "/warrantyvisit",
    title: "Warranty Visit",
    component: WarrantyVisit,
  },
  {
    path: "/sitesdatabase",
    title: "Sites Database",
    component: SitesDatabase,
  },
  {
    path: "/quotations",
    title: "Quotations",
    component: Quotations,
  },
  {
    path: "/acceptances",
    title: "Acceptances",
    component: Acceptance,
  },
  {
    path: "/pre-quotations",
    title: "Pre-Quotations",
    component: PreQuotations,
  },
  {
    path: "/deliverynotes",
    title: "Delivery Notes",
    component: DeliveryNotes,
  },
  {
    path: "/deliverynoteitems",
    title: "Delivery Notes Items",
    component: DeliveryNotesItems,
  },
  {
    path: "/materialrequests",
    title: "Material Requests",
    component: MaterialRequests,
  },
  {
    path: "/purchaserequests",
    title: "Purchase Requests",
    component: PurchaseRequests,
  },
  {
    path: "/inventorymanagement",
    title: "Inventory Management",
    component: InventoryManagement,
  },
  {
    path: "/companyfleet",
    title: "Company Fleet",
    component: CompanyFleet,
  },
  {
    path: "/materialchangerequests",
    title: "Material Change Request",
    component: MaterialChangeRequest,
  },
  {
    path: "/mobile&internetlines",
    title: "Mobile & Internet Lines",
    component: MobileInternetLines,
  },
  {
    path: "/clientinvoices",
    title: "Client Invoices",
    component: ClientInvoices,
  },
  {
    path: "/missionchangerequests",
    title: "Mission Change Requests",
    component: MissionChangeRequest,
  },
  {
    path: "/attendance",
    title: "Attendance",
    component: Attendance,
  },
  {
    path: "/clientpurchaseorders",
    title: "Client Purchase Orders",
    component: ClientPurchaseOrders,
  },
  {
    path: "/statementsmanagement",
    title: "Statements Management",
    component: StatementsManagement,
  },
  {
    path: "/pricelists",
    title: "Price Lists",
    component: PriceLists,
  },
  {
    path: "/accounts&projects",
    title: "Accounts & Projects",
    component: AccountsProjects,
  },
  {
    path: "/usersmanagement",
    title: "Users Management",
    component: UsersManagement,
  },
  {
    path: "/vacationrequests",
    title: "Vacations Requests",
    component: Vacations,
  },
  {
    path: "/mailservice",
    title: "Mail Service",
    component: MailService,
  },
  {
    path: "/employeerecords",
    title: "Employee Records",
    component: EmployeeRecords,
  },
  {
    path: "/missions/:missionId",
    title: "Missions",
    component: MissionInfo,
  },
  {
    path: "/sitesdatabase/:siteId",
    title: "Sites Database",
    component: SiteInfo,
  },
  {
    path: "/warrantyvisit/:missionId",
    title: "Warranty Visit",
    component: MissionInfo,
  },
  {
    path: "/quotations/:quotationId",
    title: "Quotations",
    component: Quotation,
  },
  {
    path: "/acceptances/:quotationId",
    title: "Acceptances",
    component: AcceptanceInfo,
  },
  {
    path: "/pre-quotations/:quotationId",
    title: "Pre-Quotations",
    component: PreQuotation,
  },
  {
    path: "/mailservice/:mailInfo",
    title: "Mail Service",
    component: MailInfo,
  },
  {
    path: "/mobile&internetlines/:mobileNumber",
    title: "Mobile & Internet Lines",
    component: MobileInternetLine,
  },
  {
    path: "/materialrequests/:materialRequestId",
    title: "Material Requests",
    component: MaterialRequestInfo,
  },
  {
    path: "/purchaserequests/:purchaseRequestId",
    title: "Purchase Requests",
    component: PurchaseRequestInfo,
  },
  {
    path: "/inventorymanagement/:inventoryManagementId",
    title: "Inventory Management",
    component: InventoryManagementInfo,
  },
  {
    path: "/pricelists/:priceListId",
    title: "Price Lists",
    component: PriceList,
  },
  {
    path: "/employeerecords/:empCode",
    title: "Employee Records",
    component: EmployeeInfo,
  },
  {
    path: "/employeerecords/:empCode/EmployeeHistory",
    title: "Employee Records",
    component: EmployeeHistory,
  },
  {
    path: "/clientinvoices/:clientInvoiceId",
    title: "Client Invoices",
    component: ClientInvoicesInfo,
  },
  {
    path: "/clientpurchaseorders/:clientPurchaseId",
    title: "Client Purchase Orders",
    component: ClientPurchaseOrderInfo,
  },
  {
    path: "/usersmanagement/:userId",
    title: "Users Management",
    component: UsersManagementInfo,
  },
  {
    path: "/companyfleet/:carNumber",
    title: "Company Fleet",
    component: CarInfo,
  },
  {
    path: "/workorders/:workOrderId",
    title: "Work Orders",
    component: WorkOrdersInfo,
  },
  {
    path: "/cloudstorage/*",
    title: "Cloud Storage",
    component: FileManager,
  },
];
