import { useTranslation } from "react-i18next";
import CardContainer from "../../../CardContainer";
import DateFormatGrid from "../../../../../components/DateFormatGrid";
import { Link as RouterLink } from "react-router-dom";
import PieChartContainer from "../../../../../components/charts/PieChart";

const OpenMissions = ({
  missionInProgressPerProject,
  missionInProgressPerUser,
}) => {
  const { t } = useTranslation("global");
  const combinedMissoinInProgressPerProject = missionInProgressPerProject.map(
    (project) => ({
      name: project.CompanyProjectsName,
      data: project.Count,
    })
  );

  const combinedMissoinInProgressPerUser = missionInProgressPerUser.map(
    (user) => ({
      name: user.ERPUserNickName,
      data: user.Count,
    })
  );

  const columns = [
    {
      field: "MissionNo",
      headerName: t("data_grid.headers.ref_no"),
      width: 110,
      renderCell: (params) => {
        return (
          <RouterLink
            style={{
              color: "#1790FF",
            }}
            to={`/missions/${params.value}`}
            target="_blank"
          >
            {params.value}
          </RouterLink>
        );
      },
    },
    {
      field: "SiteName",
      headerName: t("data_grid.headers.site_name"),
      flex: 1,
    },
    {
      field: "SiteCode",
      headerName: t("data_grid.headers.site_code"),
      width: 110,
    },
    {
      field: "MUser",
      headerName: t("data_grid.headers.mission_by"),
      width: 115,
    },
    {
      field: "WUser",
      headerName: t("data_grid.headers.order_by"),
      width: 115,
    },
    {
      field: "MissionStart",
      headerName: t("data_grid.headers.start_date"),
      width: 140,
      renderCell: ({ value }) => {
        return <> {value ? <DateFormatGrid value={value} /> : <> </>}</>;
      },
    },
    {
      field: "MissionEnd",
      headerName: t("data_grid.headers.end_date"),
      width: 140,
      renderCell: ({ value }) => {
        return <> {value ? <DateFormatGrid value={value} /> : <> </>}</>;
      },
    },
  ];

  return (
    <CardContainer>
      <PieChartContainer
        chartName="open_missions"
        chartData={combinedMissoinInProgressPerProject}
        chartData2={combinedMissoinInProgressPerUser}
        title={t("main_cards.open_missions")}
        switchConfig={{
          leftLabel: t("main_cards.labels.per_user"),
          rightLabel: t("main_cards.labels.per_project"),
        }}
        modalConfig={{
          queryKey: "openMissionsByState",
          perUserState: "AllMissionsinProgressPerUser",
          perProjectState: "AllMissionsinProgressPerProject",
          columns,
          dataGridId: "idMissions",
        }}
      />
    </CardContainer>
  );
};

export default OpenMissions;
