import {
  Box,
  Button,
  Stack,
  Typography,
  Checkbox,
  FormControlLabel,
} from "@mui/material";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { useSnackbar } from "notistack";
import FormSpinner from "../../../../components/Spinners/FormSpinner";
import { attendanceService } from "../../../../api/attendance";
import TimePickerComponent from "../../../../components/DateAndTime/TimePicker";
import { useTranslation } from "react-i18next";
import { useRef, useState } from "react";
import { useFormik } from "formik";
import dayjs from "dayjs";
import * as yup from "yup";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import Image from "@mui/icons-material/Image";
import DeleteIcon from "@mui/icons-material/Delete";
import IconButton from "@mui/material/IconButton";
import ButtonGroup from "../../../../components/forms/ButtonGroup/ButtonGroup";
import ImageModal from "../../../../components/Modals/ImageModal";
import AntSwitch from "../../../../components/Switches/AntSwitch";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import VisuallyHiddenInput from "../../../../components/VisuallyHiddenInput";
import { convertImage } from "../../../../utils/utils";
import {
  MAX_FILE_SIZE,
  supportedImageTypes,
} from "../../../../constants/constants";

const EditAttendance = ({ handleClose, signatureID }) => {
  const { data, isLoading, isRefetching } = useQuery({
    queryKey: ["editAttendanceForm", signatureID],
    queryFn: () => attendanceService.getAttendanceEditData(signatureID),
  });

  if (isLoading || isRefetching) return <FormSpinner />;

  return <Form data={data[0]} handleClose={handleClose} />;
};

const Form = ({ data, handleClose }) => {
  const { enqueueSnackbar } = useSnackbar();
  const queryClient = useQueryClient();
  const [t] = useTranslation("global");
  const [timeOutDisabled, setTimeOutDisabled] = useState(
    data.EmpAttendanceOut !== null
  );

  const { mutateAsync: editAttendance, isPending } = useMutation({
    mutationFn: attendanceService.editAttendance,
    onSuccess: (data) => {
      handleClose();
      enqueueSnackbar(data.data.msg, { variant: "success" });
      queryClient.invalidateQueries({
        predicate: (query) => {
          return ["employeeHistory", "attendance"].includes(query.queryKey[0]);
        },
      });
    },
  });

  const handleCheckChange = (event) => {
    setTimeOutDisabled(event.target.checked);
  };

  const handleFormSubmit = (values) => {
    const formData = new FormData();

    formData.append("PermissionAttachement", values.signatureAttachment);
    formData.append(
      "Start",
      dayjs(values.date).format("YYYY/MM/DD") +
        " " +
        dayjs(values.timeIn).format("HH:mm:ss")
    );
    formData.append(
      "End",
      timeOutDisabled
        ? dayjs(values.date).format("YYYY/MM/DD") +
            " " +
            dayjs(values.timeOut).format("HH:mm:ss")
        : null
    );
    formData.append("idEmpInfo", data.idEmpInfo);
    formData.append("SelectedID", data.idEmpAttendance);
    formData.append("EmpPermission", values.permission);
    formData.append(
      "ImageState",
      isImageRemoved
        ? "Remove"
        : values.signatureAttachment !== null
        ? "Exist"
        : "NotExist"
    );

    editAttendance(formData);
  };

  const initialValues = {
    date: dayjs(data.EmpAttendanceIn),
    timeIn: dayjs(data.EmpAttendanceIn, "YYYY-MM-DD hh:mm A"),
    timeOut: data.EmpAttendanceOut
      ? dayjs(data.EmpAttendanceOut, "YYYY-MM-DD hh:mm A")
      : dayjs(data.EmpAttendanceIn).hour(16).minute(0).second(0),
    signatureAttachment: null,
    permission: data.EmpPermission === "true",
  };

  const schema = yup.object().shape({
    date: yup.string().required(t("modules.global_schema.required")),
    timeIn: yup.string().required(t("modules.global_schema.required")),
    signatureAttachment: yup
      .mixed()
      .test("fileSize", t("modules.global_schema.file_size"), (value) => {
        return !value || (value && value.size <= MAX_FILE_SIZE);
      })
      .test(
        "fileType",
        t("modules.global_schema.file_type"),
        (value) => !value || (value && supportedImageTypes.includes(value.type))
      )
      .nullable(),
    permission: yup.boolean(),
  });

  const {
    handleSubmit,
    setFieldValue,
    setFieldTouched,
    values,
    touched,
    errors,
  } = useFormik({
    initialValues,
    validationSchema: schema,
    onSubmit: handleFormSubmit,
  });
  console.log(dayjs(values.date).format("YYYY/MM/DD"));
  console.log(dayjs(values.timeIn).format("HH:mm:ss"));
  console.log(dayjs(values.timeOut).format("HH:mm:ss"));

  const [isImageModalOpen, setIsImageModalOpen] = useState(false);

  const handleImageModalOpen = () => {
    setIsImageModalOpen(true);
  };

  const handleCloseImageModal = () => {
    setIsImageModalOpen(false);
  };

  // Image handling logic
  const fileInputRef = useRef(null);
  const [image, setImage] = useState(null);

  const handleImageChange = (event) => {
    if (!event.currentTarget.files[0]) return;
    resetImageRemove();
    const file = event.currentTarget.files[0];
    setFieldTouched("signatureAttachment", true, false); // Set touched to true (to show error message if any
    setFieldValue("signatureAttachment", file);
    setImage(URL.createObjectURL(file));
  };

  const handleRemoveImage = () => {
    handleImageRemove();
    setFieldValue("signatureAttachment", null);
    setImage(null);
    if (fileInputRef.current) {
      fileInputRef.current.value = ""; // Reset the input value
    }
  };

  const [isImageRemoved, setIsImageRemoved] = useState(false);

  const handleImageRemove = () => {
    setIsImageRemoved(true);
  };

  const resetImageRemove = () => {
    setIsImageRemoved(false);
  };

  const convertedImage = convertImage(data.PermissionAttachement?.data);

  return (
    <form onSubmit={handleSubmit} noValidate>
      <Stack spacing={3} alignItems={"flex-start"} p={2}>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: "1rem",
          }}
        >
          <Typography variant="h6">{data.EmpName}</Typography>
          <Box display="flex" flexDirection="column" gap="12px">
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DatePicker
                label={t("modules.attendance.fields.date")}
                value={values.date}
                onChange={(date) => setFieldValue("date", date)}
                format="YYYY/MM/DD"
                slotProps={{
                  textField: {
                    size: "small",
                  },
                }}
              />
            </LocalizationProvider>
            <Box display="flex">
              <TimePickerComponent
                label={t("modules.attendance.fields.time_in")}
                value={values.timeIn}
                onChange={(time) => {
                  setFieldValue("timeIn", dayjs(time, "hh:mm A"));
                }}
              />
              <FormControlLabel
                control={
                  <Checkbox
                    checked={timeOutDisabled}
                    onChange={handleCheckChange}
                  />
                }
                sx={{
                  margin: 0,
                }}
              />
              <TimePickerComponent
                label={t("modules.attendance.fields.time_out")}
                value={values.timeOut}
                onChange={(time) => {
                  setFieldValue("timeOut", dayjs(time, "hh:mm A"));
                }}
                disabled={!timeOutDisabled}
              />
            </Box>
            <Stack direction="row" spacing={1} alignItems="center">
              <Typography fontWeight={600}>
                {t(
                  "info_modules.employee_records.cards.attendance.edit_form.fields.permission"
                )}
              </Typography>
              <AntSwitch
                checked={values.permission}
                onChange={(e, checked) => setFieldValue("permission", checked)}
                size="small"
                color="primary"
                inputProps={{ "aria-label": "toggle dark mode" }}
                switchSize={1.25}
              />
            </Stack>
            {values.permission && (
              <Stack spacing={2} alignItems={"flex-start"} direction={"row"}>
                <Button
                  component="label"
                  role={undefined}
                  variant="contained"
                  tabIndex={-1}
                  startIcon={<CloudUploadIcon />}
                >
                  {t("modules.buttons.select_image")}
                  <VisuallyHiddenInput
                    type="file"
                    name="signatureAttachment"
                    accept="image/*"
                    onChange={handleImageChange}
                    ref={fileInputRef}
                  />
                </Button>
                {!errors.signatureAttachment &&
                  (values.signatureAttachment !== null ||
                    (data.PermissionAttachement !== null &&
                      !isImageRemoved)) && (
                    <>
                      <IconButton onClick={handleImageModalOpen}>
                        <Image />
                      </IconButton>
                      <IconButton
                        aria-label="delete"
                        onClick={handleRemoveImage}
                      >
                        <DeleteIcon color="error" />
                      </IconButton>
                    </>
                  )}
                {touched.signatureAttachment && errors.signatureAttachment && (
                  <Typography
                    color="red"
                    sx={{
                      backgroundColor: "#ffdfdf",
                      p: 1,
                      borderRadius: "5px",
                      fontSize: "14px",
                    }}
                  >
                    {errors.signatureAttachment}
                  </Typography>
                )}
              </Stack>
            )}
          </Box>
        </Box>
      </Stack>
      <ButtonGroup
        isPending={isPending}
        onClickClose={handleClose}
        saveLabel={t("modules.buttons.save")}
      />
      <ImageModal
        open={isImageModalOpen}
        handleClose={handleCloseImageModal}
        image={
          image || (data.PermissionAttachement !== null ? convertedImage : null)
        }
      />
    </form>
  );
};

export default EditAttendance;
