import Toolbar from "@mui/material/Toolbar";
import { AppBar, Box } from "@mui/material";
import NotificationsPanel from "../../Notifications/Notifications";
import { useSelector } from "react-redux";
import { useQuery } from "@tanstack/react-query";
import { DashboardService } from "../../../api/dashboard";
import AccountIcon from "./AccountIcon/AccountIcon";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import CustomSeparator from "../../BreadCrumbs/BreadCrumbs";

const Navbar = ({ handleLogOut }) => {
  const [, i18n] = useTranslation("global");

  const { accessToken } = useSelector((state) => state.auth);
  const { language } = useSelector((state) => state.theme);

  const { data: notifications, isLoading: areNotificationsLoading } = useQuery({
    queryKey: ["notifications"],
    queryFn: () => DashboardService.getNotifications(),
    refetchInterval: 10 * 1000, // Refetch every 10 seconds
    enabled: Boolean(accessToken), // If true the query will run
  });

  useEffect(() => {
    // Set the language9 preference in Redux and i18n
    i18n.changeLanguage(language?.toLowerCase());
    if (language === "AR") {
      document.documentElement.setAttribute("dir", "rtl");
    } else {
      document.documentElement.removeAttribute("dir");
    }
  }, [language, i18n]);

  return (
    <AppBar
      position="fixed"
      sx={{
        background: (theme) => theme.palette.background.paper,
        borderBottom: "1px solid",
        borderBottomColor: (theme) => theme.palette.secondary.main,
        width: `calc(100% - 165px)`,
        boxShadow: "none",
      }}
    >
      <Toolbar
        sx={{
          justifyContent: "space-between",
          alignItems: "center",
          marginTop: "4px",
          "&.MuiToolbar-root": {
            minHeight: "50px",
          },
        }}
      >
        <Box paddingLeft="7rem">
          <CustomSeparator />
        </Box>
        <Box display="flex">
          {notifications && (
            <NotificationsPanel
              data={notifications}
              isLoading={areNotificationsLoading}
            />
          )}
          <AccountIcon handleLogOut={handleLogOut} />
        </Box>
      </Toolbar>
    </AppBar>
  );
};

export default Navbar;
