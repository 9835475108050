import { useState } from "react";

const useHandleRowSelection = () => {
  const [isRowSelected, setIsRowSelected] = useState(false);
  const [newRows, setNewRows] = useState([]);
  const [selectedItems, setSelectedItems] = useState([]);

  const handleRowSelection = (selectedItems) => {
    setIsRowSelected(selectedItems.length > 0);
  };

  const handleDeleteRow = (id) => {
    setNewRows((prevRows) =>
      prevRows.filter((row) => row.idStoreItemsTemplateInfo !== id)
    );
    setSelectedItems((prevSelectedItems) =>
      prevSelectedItems.filter((selectedId) => selectedId !== id)
    );
  };
  return {
    handleRowSelection,
    handleDeleteRow,
    isRowSelected,
    newRows,
    selectedItems,
    setNewRows,
    setSelectedItems,
  };
};

export default useHandleRowSelection;
