import { Grid, Stack, Typography } from "@mui/material";
import InfoModal from "../../../../components/Modals/InfoModal";
import ChipComponent from "../../../../components/Chips/ChipComponent";
import CustomAccordion from "../../../../components/Accordion/CustomAccordion";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import ButtonGroup from "../../../../components/forms/ButtonGroup/ButtonGroup";
import { useMutation } from "@tanstack/react-query";
import Axios from "../../../../network/Axios";
import { useSnackbar } from "notistack";

const CreateFinalAcceptance = ({
  open,
  handleClose,
  rows,
  columns,
  quotationInfo,
  quotationId,
}) => {
  const [t] = useTranslation("global");
  const { enqueueSnackbar } = useSnackbar();

  const [expanded, setExpanded] = useState("panel1");

  const handleChange = (panel) => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false);
  };

  const finalAcceptanceData = [
    {
      label: t("data_grid.headers.work_order_no"),
      value: quotationInfo.WorkOrderNo,
    },
    {
      label: t("data_grid.headers.site_name"),
      value: quotationInfo.SiteName,
    },
    {
      label: t("data_grid.headers.site_code"),
      value: quotationInfo.SiteCode,
    },
    {
      label: t("data_grid.headers.d6_type"),
      value: quotationInfo.WorkOrderD6TypeName.replace("Initial", "Final"),
    },
  ];

  const { mutateAsync: createFinalAcceptance, isPending } = useMutation({
    mutationFn: async () => {
      return Axios.patch("/Quotations", {
        State: "CreateFinalAcceptance",
        SelectedNo: quotationId,
      });
    },
    onSuccess: (data) => {
      enqueueSnackbar(data.data.msg, {
        variant: "success",
      });
      handleClose();
      handleCloseCreationWindow();
    },
  });

  const [openCreationWindow, setOpenCreationWindow] = useState(false);

  const handleOpenCreationWindow = () => setOpenCreationWindow(true);
  const handleCloseCreationWindow = () => setOpenCreationWindow(false);

  const [openCancellationWindow, setOpenCancellationWindow] = useState(false);

  const handleOpenCancellationWindow = () => setOpenCancellationWindow(true);
  const handleCloseCancellationWindow = () => setOpenCancellationWindow(false);

  return (
    <>
      <InfoModal
        open={open}
        handleClose={handleClose}
        width="66.5rem"
        title={t(
          "info_modules.quotation.description.final_acceptance.create_final_acceptance"
        )}
        content={
          <Stack
            spacing={2}
            sx={{
              padding: "1rem",
            }}
          >
            <Grid container spacing={1}>
              {finalAcceptanceData.map(({ label, value }) => (
                <Grid item xs={4} key={label}>
                  <ChipComponent label={label} value={value} />
                </Grid>
              ))}
            </Grid>
            <CustomAccordion
              title={t("info_modules.quotation.description.items_list")}
              rows={rows}
              columns={columns}
              height={255}
              getRowId={(row) => row.idWorkOrderD6Items}
              hideFooter
              expanded={expanded === "panel1"}
              onChange={handleChange("panel1")}
            />
            <ButtonGroup
              onClickSave={handleOpenCreationWindow}
              onClickClose={handleOpenCancellationWindow}
              saveLabel={t("buttons.create")}
              cancelLabel={t("modules.buttons.cancel")}
              backgroundColor="success.main"
            />
          </Stack>
        }
      />

      <FinalAcceptanceCreationWindow
        open={openCreationWindow}
        onClose={handleCloseCreationWindow}
        createFinalAcceptance={createFinalAcceptance}
        isPending={isPending}
      />
      <InfoModal
        open={openCancellationWindow}
        handleClose={handleCloseCancellationWindow}
        disableCloseOnBackdropClick
        width="30rem"
        title={t(
          "info_modules.quotation.description.final_acceptance.cancellation_validation_window.title"
        )}
        content={
          <Stack
            spacing={2}
            sx={{
              padding: "1rem",
            }}
          >
            <Typography>
              {t(
                "info_modules.quotation.description.final_acceptance.cancellation_validation_window.message"
              )}
            </Typography>
            <ButtonGroup
              onClickSave={() => {
                handleClose();
                handleCloseCancellationWindow();
              }}
              onClickClose={handleCloseCancellationWindow}
              saveLabel={t("modules.buttons.yes")}
              cancelLabel={t("modules.buttons.no")}
              backgroundColor="success.main"
              sx={{
                alignSelf: "center",
              }}
            />
          </Stack>
        }
      />
    </>
  );
};

export const FinalAcceptanceCreationWindow = ({
  open,
  onClose,
  createFinalAcceptance,
  isPending,
}) => {
  const [t] = useTranslation("global");

  return (
    <InfoModal
      open={open}
      handleClose={onClose}
      disableCloseOnBackdropClick
      width="30rem"
      title={t(
        "info_modules.quotation.description.final_acceptance.creation_validation_window.title"
      )}
      content={
        <Stack
          spacing={2}
          sx={{
            padding: "1rem",
          }}
        >
          <Typography>
            {t(
              "info_modules.quotation.description.final_acceptance.creation_validation_window.message"
            )}
          </Typography>
          <ButtonGroup
            isPending={isPending}
            onClickSave={createFinalAcceptance}
            onClickClose={onClose}
            saveLabel={t("modules.buttons.yes")}
            cancelLabel={t("modules.buttons.no")}
            backgroundColor="success.main"
            sx={{
              alignSelf: "center",
            }}
          />
        </Stack>
      }
    />
  );
};

export default CreateFinalAcceptance;
