import * as yup from "yup";
import { useFormik } from "formik";
import { useSnackbar } from "notistack";
import { useTranslation } from "react-i18next";
import ButtonGroup from "../../ButtonGroup/ButtonGroup";
import { Box, Stack, TextField } from "@mui/material";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { InventoryManagementService } from "../../../../api/inventoryManagement";
import FormSpinner from "../../../Spinners/FormSpinner";
import { useConfirmationDialog } from "../../../../contexts/ConfirmationDialogContext";

const NewStore = ({
  onClose,
  storeNameAR,
  storeNameEN,
  location,
  description,
  isEditStore,
  storeName,
}) => {
  const { isLoading, isError } = useQuery({
    queryKey: ["newStorePrivilege"],
    queryFn: InventoryManagementService.checkNewStorePrivilege,
    retry: false,
  });

  if (isLoading) return <FormSpinner />;

  if (isError) return onClose();

  return (
    <NewStoreForm
      onClose={onClose}
      storeNameAR={storeNameAR}
      storeNameEN={storeNameEN}
      location={location}
      description={description}
      isEditStore={isEditStore}
      storeName={storeName}
    />
  );
};

const NewStoreForm = ({
  onClose,
  storeNameAR,
  storeNameEN,
  location,
  description,
  storeName,
}) => {
  const [t] = useTranslation("global");

  const { enqueueSnackbar } = useSnackbar();
  const queryClient = useQueryClient();

  const initialValues = {
    storeNameAR: storeNameAR || "",
    storeNameEN: storeNameEN || "",
    location: location || "",
    description: description || "",
  };

  const schema = yup.object().shape({
    storeNameAR: yup
      .string()
      .matches(/^[\u0600-\u06FF\s]+$/, t("modules.global_schema.only_arabic"))
      .required(t("modules.global_schema.required")),
    storeNameEN: yup.string().required(t("modules.global_schema.required")),
    location: yup.string().required(t("modules.global_schema.required")),
    description: yup.string().required(t("modules.global_schema.required")),
  });

  const handleFormSubmit = (values) => {
    const { storeNameAR, storeNameEN, location, description } = values;
    storeName
      ? EditNewStore({
          CompanyStoresName: storeNameEN,
          CompanyStoresNameArabic: storeNameAR,
          CompanyStoresLocation: location,
          CompanyStoresDiscription: description,
          SelectedNo: storeName,
          State: "EditStore",
        })
      : AddNewStore({
          CompanyStoresName: storeNameEN,
          CompanyStoresNameArabic: storeNameAR,
          CompanyStoresLocation: location,
          CompanyStoresDiscription: description,
          State: "NewStore",
        });
  };

  const {
    values,
    errors,
    touched,
    handleBlur,
    handleChange,
    handleSubmit,
    dirty,
  } = useFormik({
    initialValues,
    validationSchema: schema,
    onSubmit: handleFormSubmit,
    validateOnChange: true,
  });

  const { setOpen } = useConfirmationDialog();

  const onClickClose = () => {
    if (dirty) {
      setOpen(t("modules.confirmation_message"), () => onClose);
    } else {
      onClose();
    }
  };

  const { mutateAsync: AddNewStore, isPending } = useMutation({
    mutationFn: InventoryManagementService.AddNewItem,
    onSuccess: (data) => {
      onClose();
      enqueueSnackbar(data.msg, { variant: "success" });
    },
    onError: (error) => {
      enqueueSnackbar(error.message, { variant: "error" });
    },
  });
  const { mutateAsync: EditNewStore, isPending: pendingEditStore } =
    useMutation({
      mutationFn: InventoryManagementService.EditStore,
      onSuccess: (data) => {
        onClose();
        enqueueSnackbar(data.msg, { variant: "success" });
        queryClient.invalidateQueries({
          queryKey: ["inventoryManagementStores"],
        });
      },
    });

  return (
    <Box
      component="form"
      onSubmit={handleSubmit}
      sx={{
        display: "flex",
        flexDirection: "column",
        gap: "10px",
      }}
    >
      <Stack spacing={2} p={2}>
        <TextField
          label={t(
            "modules.inventory_management.new_store_form.fields.name_ar"
          )}
          sx={{ width: "100%" }}
          size="small"
          value={values.storeNameAR}
          onBlur={handleBlur}
          onChange={handleChange("storeNameAR")}
          error={!!touched.storeNameAR && !!errors.storeNameAR}
          helperText={touched.storeNameAR && errors.storeNameAR}
        />
        <TextField
          label={t(
            "modules.inventory_management.new_store_form.fields.name_en"
          )}
          sx={{ width: "100%" }}
          size="small"
          value={values.storeNameEN}
          onBlur={handleBlur}
          onChange={handleChange("storeNameEN")}
          error={!!touched.storeNameEN && !!errors.storeNameEN}
          helperText={touched.storeNameEN && errors.storeNameEN}
        />
        <TextField
          label={t(
            "modules.inventory_management.new_store_form.fields.location"
          )}
          sx={{ width: "100%" }}
          size="small"
          value={values.location}
          onBlur={handleBlur}
          onChange={handleChange("location")}
          error={!!touched.location && !!errors.location}
          helperText={touched.location && errors.location}
        />
        <TextField
          label={t(
            "modules.inventory_management.new_store_form.fields.description"
          )}
          sx={{ width: "100%" }}
          size="small"
          value={values.description}
          onBlur={handleBlur}
          onChange={handleChange("description")}
          error={!!touched.description && !!errors.description}
          helperText={touched.description && errors.description}
        />
      </Stack>
      <ButtonGroup
        isPending={storeName ? pendingEditStore : isPending}
        onClickClose={onClickClose}
        saveLabel={t("modules.buttons.save")}
      />
    </Box>
  );
};

export default NewStore;
