import { Link } from "react-router-dom";
import { Box, Grid } from "@mui/material";
import { useTranslation } from "react-i18next";
import ChipStatus from "../../../../components/ChipStatus";
import ChipComponent from "../../../../components/Chips/ChipComponent";

const WorkOrderDetails = ({ quotationInfo }) => {
  const [t] = useTranslation("global");

  const {
    WorkOrderNo,
    WorkOrderDate,
    WorkOrderStatus,
    SiteName,
    SiteType,
    WUser,
    SiteCode,
    RequestorsName,
    WorkOrderHoldReason,
    RejectedByAuthorities,
    CompanyProjectsName,
    SubProjectsName,
    TeamLeadersName,
    Latitude,
    Longitude,
    SiteAddress,
    AcquisitionContractor,
    ConstructionContractor,
    SupportType,
    SupportHeight,
    NumberOfSectors,
    EquipmentRoom,
    FirstRunPower,
    SharingPowerSource,
    Consultant,
    SharingStatus,
    GovernoratesName,
  } = quotationInfo?.Details[0];

  const workOrderProperty = [
    {
      label: t("data_grid.headers.work_order_no"),
      value: (
        <Link
          style={{
            color: "#1790FF",
          }}
          to={`/workorders/${WorkOrderNo}`}
          target="_blank"
        >
          {WorkOrderNo}
        </Link>
      ),
    },
    {
      label: t("data_grid.headers.date"),
      value: WorkOrderDate,
    },
    {
      label: t("data_grid.headers.status"),
      value: <ChipStatus value={WorkOrderStatus} />,
    },
    {
      label: t("data_grid.headers.site_name"),
      value: SiteName,
    },
    {
      label: t("data_grid.headers.type"),
      value: SiteType,
    },

    {
      label: t("data_grid.headers.user"),
      value: WUser,
    },
    {
      label: t("data_grid.headers.site_code"),
      value: SiteCode,
    },

    {
      label: t("data_grid.headers.requestor"),
      value: RequestorsName,
    },
    {
      label: t("data_grid.headers.hold_reason"),
      value: WorkOrderHoldReason,
    },
    {
      label: t("data_grid.headers.authorities"),
      value: RejectedByAuthorities,
    },
    {
      label: t("data_grid.headers.project"),
      value: CompanyProjectsName,
    },
    {
      label: t("data_grid.headers.sub_project"),
      value: SubProjectsName,
    },
    {
      label: t("data_grid.headers.team_leader"),
      value: TeamLeadersName,
    },
    {
      label: t("data_grid.headers.latitude"),
      value: Latitude,
    },
    {
      label: t("data_grid.headers.longitude"),
      value: Longitude,
    },
    {
      label: t("data_grid.headers.acquisition_contractor"),
      value: AcquisitionContractor,
    },
    {
      label: t("data_grid.headers.construction_contractor"),
      value: ConstructionContractor,
    },
    {
      label: t("data_grid.headers.support_type"),
      value: SupportType,
    },
    {
      label: t("data_grid.headers.support_height"),
      value: SupportHeight,
    },
    {
      label: t("data_grid.headers.number_of_sectors"),
      value: NumberOfSectors,
    },
    {
      label: t("data_grid.headers.equipment_room"),
      value: EquipmentRoom,
    },
    {
      label: t("data_grid.headers.first_run_power"),
      value: FirstRunPower,
    },
    {
      label: t("data_grid.headers.sharing_power_source"),
      value: SharingPowerSource,
    },
    {
      label: t("data_grid.headers.consultant"),
      value: Consultant,
    },
    {
      label: t("data_grid.headers.sharing_status"),
      value: SharingStatus,
    },
    {
      label: t("data_grid.headers.area"),
      value: GovernoratesName,
    },
  ];
  return (
    <Box>
      <Grid container spacing={1}>
        {workOrderProperty.map((property, index) => (
          <Grid item xs={4} key={index}>
            <ChipComponent label={property.label} value={property.value} />
          </Grid>
        ))}
        <Grid item xs={8}>
          <ChipComponent
            label={t("data_grid.headers.site_address")}
            value={SiteAddress}
          />
        </Grid>
      </Grid>
    </Box>
  );
};
export default WorkOrderDetails;
