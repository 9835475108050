import { styled } from "@mui/material/styles";
import {
  CssBaseline,
  Box,
  Drawer,
  TextField,
  InputAdornment,
  IconButton,
} from "@mui/material";
import { useEffect, useState } from "react";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { clearTokens } from "../../store/slices/authSlice";
import { useIdleTimer } from "react-idle-timer";
import SidebarMenu from "./SidebarMenu";
import ErrorModal from "../Modals/ErrorModal";
import { clearUserPreferences } from "../../store/slices/themeSlice";
import Navbar from "./Navbar/Navbar";
import FAB from "./FAB";
import SideDrawer from "./SideDrawer";
import { useTranslation } from "react-i18next";
import SearchIcon from "@mui/icons-material/Search";
import { Clear as ClearIcon } from "@mui/icons-material";

const drawerWidth = 280;

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  padding: theme.spacing(0, 1),
  marginBottom: 5,
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
}));

export default function DemoLayout() {
  // Checking user activity state
  const [state, setState] = useState("Active");

  const onIdle = () => {
    setState("Idle");
  };

  const onActive = () => {
    setState("Active");
  };

  const onAction = () => {
    //update local storage with new time
    const lastAction = localStorage.getItem("lastAction");
    const currentTime = Date.now();

    if (lastAction !== null) {
      const idleTime = currentTime - parseInt(lastAction);

      if (idleTime > timeout) {
        handleLogOut();
      } else {
        updateLastAction(currentTime);
      }
    } else {
      updateLastAction(currentTime);
    }

    function updateLastAction(time) {
      localStorage.setItem("lastAction", JSON.stringify(time));
    }
  };

  // Session duration => 3 Hours, if user is idle for 4 hours he will be redirected to the login page
  const timeout = 3 * 60 * 60 * 1000;

  useIdleTimer({
    onIdle,
    onActive,
    onAction,
    timeout,
    throttle: 1000,
  });

  const { pathname } = useLocation();

  const handleLogOut = () => {
    // clear redux state and localStorage and redirect user to login page
    dispatch(clearTokens());
    dispatch(clearUserPreferences()); // clear theme and language options
    navigate("/login", { state: { from: pathname } });
  };

  useEffect(() => {
    if (state === "Idle") {
      handleLogOut();
    }
  }, [state]);

  const navigate = useNavigate();

  const dispatch = useDispatch();

  const theme = useSelector((state) => state.theme.colorTheme); // light | dark

  const [searchQuery, setSearchQuery] = useState("");

  const handleSearchQuery = (event) => setSearchQuery(event.target.value);

  const clearSearch = () => setSearchQuery("");

  const { t } = useTranslation("global");

  return (
    <Box sx={{ display: "flex" }}>
      <CssBaseline />
      <Navbar drawerWidth={drawerWidth} handleLogOut={handleLogOut} />

      <Drawer
        sx={{
          width: drawerWidth,
          flexShrink: 0,
          "& .MuiDrawer-paper": {
            width: drawerWidth,
            boxSizing: "border-box",
            overflow: "hidden",
          },
        }}
        variant="permanent"
        anchor="left"
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            padding: "1rem",
          }}
        >
          <img
            src={
              theme === "light"
                ? "/GP Logo Web (Light).png"
                : "/GP Logo Web (White).png"
            }
            alt="GeniProcess Logo"
            style={{ width: "75%" }}
          />
        </Box>
        <TextField
          variant="standard"
          value={searchQuery}
          onChange={handleSearchQuery}
          placeholder="Search…"
          size="small"
          sx={(theme) => ({
            marginRight: "26px",
            marginLeft: "23px",
            marginTop: "10px",
            "& .MuiOutlinedInput-root": {
              backgroundColor: theme.palette.background.paper,
              "&.Mui-focused": {
                backgroundColor: theme.palette.background.default,
                boxShadow: theme.shadows[1],
              },
              "& fieldset": {
                borderColor: theme.palette.divider,
              },
            },
          })}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <SearchIcon />
              </InputAdornment>
            ),
            endAdornment: searchQuery && (
              <InputAdornment position="end">
                <IconButton onClick={clearSearch} edge="end">
                  <ClearIcon />
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
        <SidebarMenu searchQuery={searchQuery} />
      </Drawer>
      <Box
        component="main"
        sx={{
          width: "100%",
          minHeight: "100vh",
          flexGrow: 1,
          p: "0px 15px",
          backgroundColor: "background.main",
        }}
      >
        <DrawerHeader />
        <Outlet />
      </Box>
      <ErrorModal />
      <FAB />
      <SideDrawer />
    </Box>
  );
}
