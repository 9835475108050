import {
  Box,
  Stack,
  TextField,
  Typography,
  Button,
  Menu,
  MenuItem,
  ListItemIcon,
  ListItemText,
} from "@mui/material";
import { SimpleTreeView, TreeItem2 } from "@mui/x-tree-view";
import { forwardRef, useState } from "react";
import { useTranslation } from "react-i18next";
import ButtonGroup from "../../../components/forms/ButtonGroup/ButtonGroup";
import ChipStatus from "../../../components/ChipStatus";
import FormsDataGrid from "../../../components/DataGrid/FormsDataGrid";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { ERPSystemRolesService } from "../../../api/ERPSystemRoles";
import { useSnackbar } from "notistack";
import { useConfirmationDialog } from "../../../contexts/ConfirmationDialogContext";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import DeleteIcon from "@mui/icons-material/Delete";
import TemplateIcon from "@mui/icons-material/FileCopy";
import { useSelector } from "react-redux";
import InfoModal from "../../../components/Modals/InfoModal";
import { getFinalArray, sortUserRoles } from "./utils";

const CustomTreeItemCheckbox = forwardRef(() => null);

const NewUserRole = ({
  newRole,
  setNewRole,
  cloneUser,
  setCloneModal,
  cloneModal,
  isPendingTemplate,
}) => {
  const { t } = useTranslation("global");
  return (
    <InfoModal
      title={t("info_modules.users_management.buttons.duplicate")}
      open={cloneModal}
      width="30rem"
      handleClose={() => {
        setCloneModal(false);
        setNewRole("");
      }}
      content={
        <Box display="flex" flexDirection="column" gap={1} margin={1.5}>
          <TextField
            label={t("data_grid.headers.user_role")}
            size="small"
            value={newRole}
            onChange={(event) => {
              setNewRole(event.target.value);
            }}
          />
          <ButtonGroup
            isPending={isPendingTemplate}
            onClickSave={cloneUser}
            onClickClose={() => {
              setCloneModal(false);
              setNewRole("");
            }}
            saveLabel={t("modules.buttons.save")}
          />
        </Box>
      }
    />
  );
};

export const UserRolePrivilegesTree = ({
  currentUserRole,
  handleUserRoleChange,
  selectedItems,
  handleSelectedItemsChange,
  sortedUserRoles,
  data,
  isNewUserRole,
  isPending,
  handleSubmit,
  onClose,
  isTouched,
  disableEdit,
  dashboardData,
  selectedDashboardItems,
  handleDashboardItemsChange,
}) => {
  const { t } = useTranslation("global");
  const queryClient = useQueryClient();
  const theme = useSelector((state) => state.theme.colorTheme);
  const { enqueueSnackbar } = useSnackbar();
  const [anchorEl, setAnchorEl] = useState(null);
  const [newRole, setNewRole] = useState("");
  const [cloneModal, setCloneModal] = useState(false);
  const openAction = Boolean(anchorEl);
  const handleClickAction = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleCloseAction = () => {
    setAnchorEl(null);
  };
  const { setOpen } = useConfirmationDialog();

  const { mutateAsync: deleteUserRole } = useMutation({
    mutationFn: ERPSystemRolesService.deleteUserRole,
    onSuccess: (data) => {
      onClose();
      enqueueSnackbar(data.msg, { variant: "success" });
      queryClient.invalidateQueries({ queryKey: ["userRoles"] });
    },
  });
  const { mutateAsync: cloneUser, isPendingTemplate } = useMutation({
    mutationFn: () =>
      ERPSystemRolesService.cloneUsersManagement(currentUserRole, newRole),
    onSuccess: (data) => {
      setCloneModal(false);
      setNewRole("");
      enqueueSnackbar(data.msg, { variant: "success" });
      queryClient.invalidateQueries({ queryKey: ["userRoles"] });
    },
  });

  const actions = ["Delete", "Clone"];

  const actionsConfig = {
    Delete: {
      label: "Delete",
      icon: <DeleteIcon sx={{ width: "1.1rem", height: "1.1rem" }} />,
      onClick: () => {
        setOpen(
          t(
            "modules.users_management.user_roles.confirmation_messages.delete_role"
          ),
          () => () => deleteUserRole(currentUserRole),
          "error"
        );
      },
    },
    Clone: {
      label: t("info_modules.users_management.buttons.duplicate"),
      icon: <TemplateIcon sx={{ width: "1.1rem", height: "1.1rem" }} />,
      onClick: () => {
        setCloneModal(true);
      },
    },
  };

  const updatePrivileges = (privilegesArray1, privilegesArray2) => {
    const updatedArray = privilegesArray1.map((item1) => {
      // Find the corresponding item in the second array
      const matchingItem = privilegesArray2.find(
        (item2) => item2.idModulePrivilege === item1.idModulePrivilege
      );

      // If a matching item is found, update the Checked property
      if (matchingItem) {
        return {
          ...item1,
          Checked: matchingItem.checked ? 1 : 0, // Convert boolean to 0 or 1
        };
      }

      // If no matching item is found, return the original item
      return item1;
    });

    return updatedArray;
  };

  const countCheckedItems = () => {
    const dataFromAPI = Array.isArray(data) ? data : data.result;

    const outputArray = getFinalArray(selectedItems, dataFromAPI); // Format the selectedItems Array to look like the array from the API

    const updatedPrivileges = updatePrivileges(dataFromAPI, outputArray);

    const sortedUserRoles = sortUserRoles(updatedPrivileges);

    const result = {};

    for (const moduleName in sortedUserRoles) {
      result[moduleName] = {
        subModuleCount: 0,
        subModules: {},
      };

      for (const subModuleName in sortedUserRoles[moduleName]) {
        const privileges = sortedUserRoles[moduleName][subModuleName];
        const checkedCount = privileges.filter(
          (privilege) => privilege.Checked === 1
        ).length;

        if (checkedCount > 0) {
          result[moduleName].subModuleCount++;
          result[moduleName].subModules[subModuleName] = checkedCount;
        }
      }
    }

    return result;
  };

  const userRolesCount = countCheckedItems();

  const graphItems = dashboardData.filter(
    (item) => item.ElementType === "Graph"
  );
  const notificationsItems = dashboardData.filter(
    (item) => item.ElementType === "Notifications"
  );

  const dashboardAndGraphItems = {
    graph: graphItems,
    notifications: notificationsItems,
  };

  const graphItemIds = new Set(
    graphItems.map((item) => item.idDashboard.toString())
  );
  const selectedDashboardItemsCount = selectedDashboardItems.filter((item) =>
    graphItemIds.has(item)
  ).length;

  const notificationsItemIds = new Set(
    notificationsItems.map((item) => item.idDashboard.toString())
  );
  const selectedNotificationsItemsCount = selectedDashboardItems.filter(
    (item) => notificationsItemIds.has(item)
  ).length;

  return (
    <Stack p={2} spacing={2}>
      <TextField
        label={t("modules.users_management.user_roles.labels.role_name")}
        size="small"
        value={currentUserRole}
        onChange={handleUserRoleChange}
        error={isTouched && currentUserRole.length === 0}
        disabled={disableEdit}
        helperText={
          isTouched && currentUserRole.length === 0
            ? t(
                "modules.users_management.user_roles.error_messages.role_name_required"
              )
            : ""
        }
        required
      />
      <Box
        sx={{
          display: "grid",
          gridTemplateColumns: !isNewUserRole ? "0.9fr 1fr 1fr" : "1fr 1fr",
          gap: 1,
        }}
      >
        <Stack spacing={2}>
          <Typography variant="h6">
            {t(
              "modules.users_management.user_roles.headlines.user_role_privileges"
            )}
          </Typography>
          <Box sx={{ height: 352, overflowY: "auto" }}>
            <SimpleTreeView
              selectedItems={selectedItems}
              onSelectedItemsChange={
                disableEdit ? () => {} : handleSelectedItemsChange
              }
              multiSelect
              checkboxSelection
              disableCheckboxes={disableEdit}
            >
              {sortedUserRoles && Object.entries(sortedUserRoles).length > 0 ? (
                Object.entries(sortedUserRoles).map(([category, modules]) => (
                  <TreeItem2
                    key={category}
                    itemId={category}
                    label={
                      <Stack direction="row" spacing={1} alignItems="center">
                        <Typography>{category}</Typography>
                        <Typography color="primary.main">
                          ({userRolesCount[category]?.subModuleCount || 0})
                        </Typography>
                      </Stack>
                    }
                    slots={{ checkbox: CustomTreeItemCheckbox }}
                  >
                    {modules &&
                      Object.entries(modules).map(([module, privileges]) => (
                        <TreeItem2
                          key={module}
                          itemId={module}
                          label={
                            <Stack
                              direction="row"
                              spacing={1}
                              alignItems="center"
                            >
                              <Typography>{module}</Typography>
                              <Typography color="primary.main">
                                (
                                {userRolesCount[category]?.subModules[module] ||
                                  0}
                                )
                              </Typography>
                            </Stack>
                          }
                          slots={{ checkbox: CustomTreeItemCheckbox }}
                        >
                          {privileges?.map(
                            ({ SystemPrivilegeName, idModulePrivilege }) => (
                              <TreeItem2
                                key={idModulePrivilege}
                                itemId={`${SystemPrivilegeName}-${idModulePrivilege}`}
                                label={SystemPrivilegeName}
                              />
                            )
                          )}
                        </TreeItem2>
                      ))}
                  </TreeItem2>
                ))
              ) : (
                <Typography>
                  {t("modules.users_management.user_roles.labels.no_roles")}
                </Typography>
              )}
            </SimpleTreeView>
          </Box>
        </Stack>

        {/* Dashboard Features Tree */}
        <Stack spacing={2}>
          <Typography variant="h6">
            {t("modules.users_management.user_roles.headlines.dashboard")}
          </Typography>
          <Box sx={{ height: 352, overflowY: "auto" }}>
            <SimpleTreeView
              multiSelect
              checkboxSelection
              selectedItems={selectedDashboardItems}
              onSelectedItemsChange={
                disableEdit ? () => {} : handleDashboardItemsChange
              }
            >
              {dashboardAndGraphItems &&
              Object.entries(dashboardAndGraphItems).length > 0 ? (
                Object.entries(dashboardAndGraphItems).map(
                  ([category, modules]) => {
                    return (
                      <TreeItem2
                        key={category}
                        itemId={category}
                        label={
                          <Stack
                            direction="row"
                            spacing={1}
                            alignItems="center"
                          >
                            <Typography>
                              {category === "graph"
                                ? "Graphs"
                                : "Notifications"}
                            </Typography>
                            <Typography color="primary.main">
                              (
                              {category === "graph"
                                ? selectedDashboardItemsCount
                                : selectedNotificationsItemsCount}
                              )
                            </Typography>
                          </Stack>
                        }
                        slots={{ checkbox: CustomTreeItemCheckbox }}
                      >
                        {modules &&
                          Object.values(modules).map((item) => {
                            return (
                              <TreeItem2
                                key={item.idDashboard}
                                itemId={item.idDashboard.toString()}
                                label={item.DashboardFeature}
                              />
                            );
                          })}
                      </TreeItem2>
                    );
                  }
                )
              ) : (
                <Typography>
                  {t("modules.users_management.user_roles.labels.no_roles")}
                </Typography>
              )}
            </SimpleTreeView>
          </Box>
        </Stack>

        {!isNewUserRole && (
          <Stack spacing={2}>
            <Box display="flex" justifyContent="space-between">
              <Typography variant="h6">
                {t("modules.users_management.user_roles.headlines.users_list")}
              </Typography>
              {!disableEdit && (
                <div>
                  <Button
                    id="action-button"
                    aria-controls={openAction ? "action-menu" : undefined}
                    aria-haspopup="true"
                    aria-expanded={openAction ? "true" : undefined}
                    onClick={handleClickAction}
                    startIcon={<MoreVertIcon />}
                    endIcon={<KeyboardArrowDownIcon />}
                    variant="outlined"
                    sx={{
                      "& .MuiButton-startIcon": {
                        marginRight: 0.3,
                        fontSize: "0.5rem",
                      },
                      padding: "3px 8px",
                      fontSize: 13.8,
                      textTransform: "capitalize",
                      border: 2.3,
                      ":hover": {
                        border: 2.3,
                      },
                    }}
                  >
                    {t("info_modules.work_orders.buttons.actions")}
                  </Button>
                  <Menu
                    id="action-menu"
                    anchorEl={anchorEl}
                    open={openAction}
                    onClose={handleCloseAction}
                    MenuListProps={{
                      "aria-labelledby": "action-button",
                      sx: {
                        pb: 0,
                        pt: 0,
                      },
                    }}
                    slotProps={{
                      paper: {
                        sx: {
                          border: "3px solid",
                          borderColor: theme === "dark" ? "#333" : "#e0e0e0",
                        },
                      },
                    }}
                    sx={{
                      "& .MuiList-root": {
                        "& .MuiMenuItem-root": {
                          fontSize: 13.8,
                        },
                        "& .MuiMenuItem-root:not(:last-child)": {
                          borderBottom: "3px solid",
                          borderBottomColor:
                            theme === "dark" ? "#333" : "#e0e0e0",
                        },
                      },
                    }}
                    anchorOrigin={{
                      vertical: 30,
                      horizontal: "right",
                    }}
                    transformOrigin={{
                      vertical: 0,
                      horizontal: "right",
                    }}
                  >
                    {actions.map((action) => {
                      const isDeleteDisabled =
                        action === "Delete" &&
                        data?.UsersInRole &&
                        data.UsersInRole.length > 0;
                      return (
                        <MenuItem
                          key={action}
                          onClick={() => {
                            if (!isDeleteDisabled)
                              actionsConfig[action].onClick();
                            handleCloseAction();
                          }}
                          sx={{
                            color: isDeleteDisabled
                              ? "grey.400"
                              : actionsConfig[action].color,
                          }}
                          disabled={isDeleteDisabled}
                        >
                          <ListItemIcon>
                            {actionsConfig[action].icon}
                          </ListItemIcon>
                          <ListItemText
                            sx={{
                              "& span": {
                                fontSize: 13.8,
                              },
                            }}
                          >
                            {actionsConfig[action].label}
                          </ListItemText>
                        </MenuItem>
                      );
                    })}
                  </Menu>
                </div>
              )}
            </Box>
            {data?.UsersInRole && data.UsersInRole.length > 0 ? (
              <UsersList rows={data.UsersInRole} />
            ) : (
              <Typography>No users in role</Typography>
            )}
          </Stack>
        )}
      </Box>
      {!disableEdit && (
        <ButtonGroup
          isPending={isPending}
          onClickSave={handleSubmit}
          onClickClose={onClose}
          saveLabel={t("modules.buttons.save")}
        />
      )}

      <NewUserRole
        cloneUser={cloneUser}
        newRole={newRole}
        setNewRole={setNewRole}
        setCloneModal={setCloneModal}
        cloneModal={cloneModal}
        isPendingTemplate={isPendingTemplate}
      />
    </Stack>
  );
};

export const UsersList = ({ rows }) => {
  const { t } = useTranslation("global");
  const columns = [
    {
      field: "ERPUserName",
      headerName: t("data_grid.headers.username"),
      flex: 1,
    },
    {
      field: "ERPUserStatus",
      headerName: t("data_grid.headers.status"),
      headerAlign: "center",
      width: 130,
      renderCell: ({ value }) => {
        return <ChipStatus value={value} />;
      },
    },
  ];

  return (
    <FormsDataGrid
      rows={rows}
      columns={columns}
      height={300}
      getRowId={(row) => row.idERPUsers}
      disableColumnMenu
    />
  );
};
