import { Button, Stack, Typography } from "@mui/material";
import { useState } from "react";
import { useQuery } from "@tanstack/react-query";
import FormsDataGrid from "../../../components/DataGrid/FormsDataGrid";
import { ERPSystemRolesService } from "../../../api/ERPSystemRoles";
import InfoModal from "../../../components/Modals/InfoModal";
import FormSpinner from "../../../components/Spinners/FormSpinner";
import SingleDataGroup from "./SingleDataGroup";
import { useTranslation } from "react-i18next";

const DataGroupsModal = ({ open, onClose }) => {
  const { t } = useTranslation("global");

  return (
    <InfoModal
      open={open}
      handleClose={onClose}
      title={t("modules.users_management.data_groups.title")}
      content={<DataGroups onClose={onClose} />}
      width="30rem"
    />
  );
};

const DataGroups = ({ onClose }) => {
  const { t } = useTranslation("global");

  // Handling data group modal
  const [openSingleDataGroup, setOpenSingleDataGroup] = useState(false);

  const handleOpenSingleDataGroupModal = () => setOpenSingleDataGroup(true);
  const handleCloseSingleDataGroupModal = () => setOpenSingleDataGroup(false);

  // Handling data group state
  const [dataGroup, setDataGroup] = useState(null);

  const columns = [
    {
      field: "GroupsName",
      headerName: t("data_grid.headers.data_group_name"),
      flex: 1,
      renderCell: (params) => {
        return (
          <Typography
            style={{
              color: "#1790FF",
              fontSize: "14px",
              cursor: "pointer",
              userSelect: "none",
            }}
            onClick={() => {
              setDataGroup(params.value);
              handleOpenSingleDataGroupModal();
            }}
          >
            {params.value}
          </Typography>
        );
      },
    },
  ];

  const { data, isLoading } = useQuery({
    queryKey: ["dataGroups"],
    queryFn: ERPSystemRolesService.getAllDataGroups,
  });

  if (isLoading) return <FormSpinner />;

  return (
    <>
      <Stack p={2} spacing={2}>
        <FormsDataGrid
          columns={columns}
          rows={data}
          height={400}
          toolbar
          showQuickFilter
          getRowId={(row) => row.GroupsName}
          disableMultipleRowSelection
        />
        <Button
          variant="contained"
          sx={{
            alignSelf: "flex-end",
          }}
          color="secondary"
          onClick={onClose}
        >
          {t("modules.buttons.cancel")}
        </Button>
      </Stack>

      <InfoModal
        open={openSingleDataGroup}
        handleClose={handleCloseSingleDataGroupModal}
        title={dataGroup}
        content={
          <SingleDataGroup
            onClose={handleCloseSingleDataGroupModal}
            dataGroup={dataGroup}
          />
        }
        width="50rem"
      />
    </>
  );
};

export default DataGroupsModal;
