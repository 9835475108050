import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Typography,
  Box,
} from "@mui/material";
import ExpandCircleDownIcon from "@mui/icons-material/ExpandCircleDown";

const DashboardAccordion = ({ title, children }) => {
  return (
    <Accordion disableGutters defaultExpanded>
      <AccordionSummary
        expandIcon={<ExpandCircleDownIcon />}
        aria-controls="panel1-content"
        id="panel1-header"
        sx={{
          background: (theme) => theme.palette.background.paper,
          minHeight: "35px",
          padding: "0 8px",
          "& .MuiAccordionSummary-content": {
            margin: 0,
          },
        }}
      >
        <Typography>{title}</Typography>
      </AccordionSummary>
      <AccordionDetails
        sx={{
          padding: "8px",
          background: (theme) => theme.palette.background.paper,
        }}
      >
        <Box
          sx={{
            display: "grid",
            gridTemplateColumns: "1fr 1fr",
            gridAutoRows: "40vh" /* Set a fixed height for all rows */,
            gap: "8px",
          }}
        >
          {children}
        </Box>
      </AccordionDetails>
    </Accordion>
  );
};

export default DashboardAccordion;
