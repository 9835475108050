import { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Box, CircularProgress } from "@mui/material";
import Axios from "../../../network/Axios";
import { useSnackbar } from "notistack";
import SelectInvoice from "./Steps/SelectInvoice";
import InvoiceDetails from "./Steps/InvoiceDetails";
import { useMutation, useQueryClient, useQuery } from "@tanstack/react-query";
import { useFormik } from "formik";
import * as yup from "yup";
import dayjs from "dayjs";
import useHandleForms from "../../../hooks/useHandleForms";
import StepperButton from "../ButtonGroup/StepperButton";

const ClientInvoicesForm = ({ onDirtyChange }) => {
  const [t] = useTranslation("global");
  const { enqueueSnackbar } = useSnackbar();
  const [activeStep, setActiveStep] = useState(0);
  const [selectedWorkOrders, setSelectedWorkOrders] = useState([]);
  const [isRowSelected, setIsRowSelected] = useState(false);
  const [newInvoices, setNewInvoices] = useState([]);
  const [totalSupply, setTotalSupply] = useState();
  const [totalInstall, setTotalInstall] = useState();
  const [currentTab, setCurrentTab] = useState(0);

  const queryClient = useQueryClient();

  const { data: clientInvoicesData, isLoading } = useQuery({
    queryKey: ["clientInvoices", "New"],
    queryFn: async () => {
      const response = await Axios.get("/ClientInvoices", {
        params: {
          State: "New",
        },
      });
      return response.data;
    },
  });
  const { data: invoiceSupply } = useQuery({
    queryKey: ["clientInvoices", "invoiceSupply"],
    queryFn: async () => {
      const response = await Axios.get("/ClientInvoices", {
        params: {
          State: "InvoiceSupply",
        },
      });
      return response.data.result;
    },
  });
  const { data: invoiceInstall } = useQuery({
    queryKey: ["clientInvoices", "invoiceInstall"],
    queryFn: async () => {
      const response = await Axios.get("/ClientInvoices", {
        params: {
          State: "InvoiceInstall",
        },
      });
      return response.data.result;
    },
  });

  const padSerial = (number) => {
    return number.toString().padStart(6, "0");
  };

  const resetFormClose = () => {
    resetForm();
    setActiveStep(0);
    setSelectedWorkOrders([]);
    setIsRowSelected(false);
    setNewInvoices([]);
    setTotalInstall(0);
    setTotalSupply(0);
  };

  const initialState = {
    selectedPoNo: "",
    selectedType: "",
    d6Date: dayjs(),
    invoiceDate: dayjs(),
    supplySerial: "",
    installSerial: "",
  };
  const schema = yup.object().shape({
    selectedPoNo: yup
      .string()
      .required(t("modules.global_schema.select_value")),
    selectedType: yup
      .string()
      .required(t("modules.global_schema.select_value")),
    supplySerial: yup.string().required(t("modules.global_schema.required")),
    installSerial: yup.string().required(t("modules.global_schema.required")),
  });

  const serialSupply = `MEM${dayjs().format("YYYY")}${padSerial(
    parseInt(clientInvoicesData?.InvoiceNo)
  )}`;

  const serialInstall =
    currentTab === 0
      ? `MEM${dayjs().format("YYYY")}${padSerial(
          parseInt(clientInvoicesData?.InvoiceNo + 1)
        )}`
      : serialSupply;

  const handleNext = async () => {
    if (activeStep === 0) {
      let selectedData = [];

      for (const element of selectedWorkOrders) {
        const row = (
          currentTab === 0
            ? clientInvoicesData.InvoiceSupllyInstall
            : currentTab === 1
            ? invoiceSupply
            : invoiceInstall
        ).find((r) => r.idWorkOrderD6 === element);
        if (row) {
          selectedData.push(row);
        }
      }

      setNewInvoices(selectedData);
      setFieldValue("supplySerial", serialSupply);
      setFieldValue("installSerial", serialInstall);
    }
    if (activeStep === 1) {
      const data = await validateForm();
      if (Object.keys(data).length > 0) return handleSubmit();
      addNewClientInvoice();
      return;
    }
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };
  const handleRowSelection = (selectedItems) => {
    setIsRowSelected(selectedItems.length > 0);
  };
  const {
    errors,
    handleChange,
    values,
    setFieldValue,
    validateForm,
    handleSubmit,
    resetForm,
    dirty,
    touched,
  } = useFormik({
    initialValues: initialState,
    validationSchema: schema,
    onSubmit: handleNext,
    validateOnChange: true,
  });
  const { handleClose } = useHandleForms({ dirty, onDirtyChange });

  useEffect(() => {
    if (isRowSelected !== dirty) {
      onDirtyChange(isRowSelected);
    }
  }, [isRowSelected, dirty, onDirtyChange]);

  const { selectedPoNo, selectedType, d6Date, invoiceDate } = values;

  const workOrderD6 = newInvoices.map((order) => order.WorkOrderD6No);
  const { mutateAsync: addNewClientInvoice, isPending } = useMutation({
    mutationFn: async () => {
      return Axios.post("/ClientInvoices", {
        WorkOrderD6: workOrderD6,
        Activity: selectedType,
        ClientPONo: selectedPoNo,
        D6Date: d6Date.format("YYYY-MM-DD"),
        InvoiceDate: invoiceDate.format("YYYY-MM-DD"),
        InvoiceNoI: values.installSerial,
        InvoiceNoS: values.supplySerial,
        InvoiceType: currentTab === 0 ? "SI" : currentTab === 1 ? "S" : "I",
      });
    },
    onSuccess: (data) => {
      if (!data) return;
      handleClose();
      resetFormClose();
      enqueueSnackbar(t("modules.error_messages.added_successfully"), {
        variant: "success",
      });
      queryClient.invalidateQueries({ queryKey: ["clientInvoices"] });
    },
  });

  const views = {
    0: (
      <SelectInvoice
        rows={
          currentTab === 0
            ? clientInvoicesData?.InvoiceSupllyInstall
            : currentTab === 1
            ? invoiceSupply
            : invoiceInstall
        }
        onRowSelection={handleRowSelection}
        selectedRows={selectedWorkOrders}
        totalInstall={totalInstall}
        totalSupply={totalSupply}
        setTotalInstall={setTotalInstall}
        setTotalSupply={setTotalSupply}
        setSelectedWorkOrders={setSelectedWorkOrders}
        currentTab={currentTab}
        setCurrentTab={setCurrentTab}
      />
    ),
    1: (
      <InvoiceDetails
        poNo={clientInvoicesData?.ClientPONo}
        type={clientInvoicesData?.Activity}
        errors={errors}
        handleChange={handleChange}
        setFieldValue={setFieldValue}
        touched={touched}
        values={values}
        totalInstall={totalInstall}
        totalSupply={totalSupply}
        newInvoices={newInvoices}
        currentTab={currentTab}
        setCurrentTab={setCurrentTab}
      />
    ),
  };
  return (
    <form onSubmit={handleSubmit}>
      {isLoading ? (
        <Box
          width="60rem"
          display="flex"
          justifyContent="center"
          margin="10rem 0"
        >
          <CircularProgress />
        </Box>
      ) : (
        <>
          {views[activeStep]}
          <StepperButton
            isPending={isPending}
            onClick={handleNext}
            handleBack={handleBack}
            activeStep={activeStep}
            disabled={!isRowSelected}
            label={
              activeStep === 1
                ? t("modules.buttons.save")
                : t("modules.buttons.next")
            }
          />
        </>
      )}
    </form>
  );
};
export default ClientInvoicesForm;
