import * as yup from "yup";
import { useFormik } from "formik";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { useSnackbar } from "notistack";
import { useTranslation } from "react-i18next";
import { priceListsService } from "../../../../api/priceLists";
import { Autocomplete, Box, Grid } from "@mui/material";
import ChipComponent from "../../../../components/Chips/ChipComponent";
import ChipStatus from "../../../../components/ChipStatus";
import {
  autoCompleteStyles,
  StyledTextField,
} from "../../MobileInternetLine/MobileInternetLine";
import ButtonGroup from "../../../../components/forms/ButtonGroup/ButtonGroup";
import { useNavigate } from "react-router-dom";
import FormSpinner from "../../../../components/Spinners/FormSpinner";

const EditForm = ({ data, handleCancelEditMode }) => {
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const queryClient = useQueryClient();
  const [t] = useTranslation("global");

  const schema = yup.object().shape({
    PriceListRefNo: yup
      .string()
      .required(t("modules.global_schema.select_value")),
    PriceListRefYear: yup.date(),
  });

  const {
    CreationDate,
    ERPUserNickName,
    InstallCount,
    LastUpdateDate,
    PriceListRefNo: SelectedNo,
    PriceListYear,
    Status,
    SupplyCount,
    Total,
    idPriceListRef,
    CompanyAccountName,
  } = data;

  const {
    data: priceListEditFormData,
    isLoading,
    isRefetching,
  } = useQuery({
    queryKey: ["priceListEditFormData", SelectedNo],
    queryFn: () => priceListsService.getEditFormData(SelectedNo),
  });

  const handleFormSubmit = (values) => {
    const { PriceListRefNo, PriceListRefYear, CompanyAccountName } = values;

    updatePriceList({
      PriceListRefNo,
      PriceListRefYear: dayjs(PriceListRefYear).format("YYYY"),
      CompanyAccountName,
      SelectedNo,
    });
  };

  const initialValues = {
    PriceListRefNo: SelectedNo,
    PriceListRefYear: PriceListYear,
    CompanyAccountName,
  };

  const {
    values,
    errors,
    touched,
    handleBlur,
    handleChange,
    handleSubmit,
    setFieldValue,
  } = useFormik({
    initialValues,
    validationSchema: schema,
    onSubmit: handleFormSubmit,
  });

  const { mutateAsync: updatePriceList, isPending } = useMutation({
    mutationFn: priceListsService.updatePriceList,
    onSuccess: (data) => {
      handleCancelEditMode();
      enqueueSnackbar(data.msg, {
        variant: "success",
      });
      queryClient.invalidateQueries({
        queryKey: ["priceList"],
      });
      navigate(
        `/pricelists/${values.PriceListRefNo}?SelectedID=${idPriceListRef}`,
        {
          replace: true,
        }
      );
    },
  });

  if (isLoading || isRefetching) {
    return (
      <Box>
        <FormSpinner
          sx={{
            marginTop: "50px",
            marginBottom: "50px",
          }}
        />
      </Box>
    );
  }

  const companyAccountNameOptions =
    priceListEditFormData.CompanyAccountName.map(
      (account) => account.CompanyAccountName
    ) || [];

  const priceListDetails = [
    {
      label: t("data_grid.headers.ref_no"),
      value: SelectedNo,
      input: (
        <StyledTextField
          size="small"
          name="phoneNumber"
          value={values.PriceListRefNo}
          onBlur={handleBlur}
          onChange={handleChange("PriceListRefNo")}
          error={!!touched.PriceListRefNo && !!errors.PriceListRefNo}
          label={touched.PriceListRefNo ? errors.PriceListRefNo : ""}
          autoFocus
        />
      ),
    },
    {
      label: t("data_grid.headers.status"),
      value: <ChipStatus value={Status} />,
    },
    {
      label: t("data_grid.headers.price_list_year"),
      value: PriceListYear,
      input: (
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <DatePicker
            label={t("data_grid.headers.start_date")}
            value={dayjs(values.PriceListRefYear)}
            onChange={(newValue) => {
              setFieldValue("PriceListRefYear", newValue);
            }}
            format="YYYY"
            views={["year"]}
            slots={{
              textField: StyledTextField,
            }}
          />
        </LocalizationProvider>
      ),
    },
    {
      label: t("data_grid.headers.account_name"),
      value: (
        <Autocomplete
          disablePortal
          options={companyAccountNameOptions}
          getOptionLabel={(option) => option}
          sx={autoCompleteStyles}
          size="small"
          onChange={(event, newValue) => {
            setFieldValue("CompanyAccountName", newValue || "");
          }}
          value={
            companyAccountNameOptions.find(
              (option) => option === values.CompanyAccountName
            ) || null
          }
          disableClearable
          renderInput={(params) => (
            <StyledTextField
              {...params}
              onBlur={handleBlur}
              error={
                !!touched.CompanyAccountName && !!errors.CompanyAccountName
              }
              label={
                touched.CompanyAccountName ? errors.CompanyAccountName : ""
              }
            />
          )}
        />
      ),
    },
    {
      label: t("data_grid.headers.total_count"),
      value: Total,
    },
    {
      label: t("data_grid.headers.created_by"),
      value: ERPUserNickName,
    },
    {
      label: t("data_grid.headers.creation_date"),
      value: CreationDate,
    },
    {
      label: t("data_grid.headers.last_update"),
      value: LastUpdateDate,
    },
    {
      label: t("data_grid.headers.install_count"),
      value: InstallCount,
    },
    {
      label: t("data_grid.headers.supply_count"),
      value: SupplyCount,
    },
  ];

  return (
    <Box component="form" onSubmit={handleSubmit}>
      <Grid container spacing={2} columnSpacing={3}>
        {priceListDetails.map(({ label, value, input }) => (
          <Grid
            item
            xs={4}
            key={label}
            sx={{
              display: "flex",
              "& > div": {
                width: "100%",
              },
            }}
          >
            <ChipComponent label={label} value={input || value} />
          </Grid>
        ))}
      </Grid>
      <ButtonGroup
        saveLabel={t("modules.buttons.save")}
        isPending={isPending}
        onClickClose={handleCancelEditMode}
      />
    </Box>
  );
};

export default EditForm;
