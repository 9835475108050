import * as React from "react";
import { Unstable_Popup as BasePopup } from "@mui/base/Unstable_Popup";
import { ClickAwayListener } from "@mui/base/ClickAwayListener";
import { styled } from "@mui/system";

export default function SimplePopup({ content, open, anchor, id, onClose }) {
  const handleClickAway = () => {
    onClose();
  };

  return (
    open && (
      <ClickAwayListener onClickAway={handleClickAway}>
        <BasePopup id={id} open={open} anchor={anchor} placement="top">
          <PopupBody>{content}</PopupBody>
        </BasePopup>
      </ClickAwayListener>
    )
  );
}

const grey = {
  50: "#F3F6F9",
  100: "#E5EAF2",
  200: "#DAE2ED",
  300: "#C7D0DD",
  400: "#B0B8C4",
  500: "#9DA8B7",
  600: "#6B7A90",
  700: "#434D5B",
  800: "#303740",
  900: "#1C2025",
};

const PopupBody = styled("div")(
  ({ theme }) => `
    width: max-content;
    padding: 12px 10px 16px; // Changed the order of padding values
    margin: 8px;
    border-radius: 8px;
    border: 1px solid ${theme.palette.mode === "dark" ? grey[700] : grey[200]};
    background-color: ${theme.palette.mode === "dark" ? grey[900] : "#fff"};
    box-shadow: ${
      theme.palette.mode === "dark"
        ? `0px 4px 8px rgb(0 0 0 / 0.7)`
        : `0px 4px 8px rgb(0 0 0 / 0.1)`
    };
    font-family: 'IBM Plex Sans', sans-serif;
    font-weight: 500;
    font-size: 0.875rem;
    z-index: 1;
`
);
