import { Box, Grid } from "@mui/material";
import ChipStatus from "../../../ChipStatus";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import ChipComponent from "../../../Chips/ChipComponent";
import * as React from "react";
import CustomAccordion from "../../../Accordion/CustomAccordion";

const Review = ({
  workOrderRows,
  itemsRows,
  startDate,
  endDate,
  selectedD6,
  totalAmount,
  totalInstall,
  totalSupply,
  useTemplate,
  isThreeStepProcess,
  type,
}) => {
  const [expanded, setExpanded] = useState("panel1");
  const [t] = useTranslation("global");
  const handleChange = (panel) => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false);
  };
  const workOrderColumns = [
    {
      field: "WorkOrderNo",
      headerName: t("data_grid.headers.work_order_no"),
      width: 120,
    },

    {
      field: "WorkOrderDiscription",
      headerName: t("data_grid.headers.description"),
      width: 140,
    },
    {
      field: "SiteName",
      headerName: t("data_grid.headers.site_name"),
      width: 110,
    },
    {
      field: "SiteCode",
      headerName: t("data_grid.headers.site_code"),
      width: 110,
    },
    {
      field: "SubProjectsName",
      headerName: t("data_grid.headers.sub_project"),
      width: 100,
    },
    {
      field: "WorkOrderDate",
      headerName: t("data_grid.headers.date"),
      width: 120,
    },
    {
      field: "ERPUserNickName",
      headerName: t("data_grid.headers.created_by"),
      width: 120,
      flex: 1,
    },
    {
      field: "WorkOrderStatus",
      headerName: t("data_grid.headers.status"),
      width: 150,
      renderCell: ({ value }) => {
        return <ChipStatus value={value} />;
      },
    },
  ];

  const quantityColumn = {
    field: "Quantity",
    headerName: t("data_grid.headers.quantity"),
    width: 120,
  };

  const totalColumn = {
    field: "Total",
    headerName: t("data_grid.headers.total"),
    width: 120,
    valueGetter: (params) => {
      const total = params.row.Price * (params.row.Quantity || 0);
      return total.toFixed(2);
    },
    flex: 1,
  };

  const priceColumn = {
    field: "Price",
    headerName: t("data_grid.headers.unit_price"),
    width: 120,
    flex: 1,
  };

  const itemsBaseColumns = [
    {
      field: "PriceListSN",
      headerName: t("data_grid.headers.code"),
      width: 160,
    },
    {
      field: "Description",
      headerName: t("data_grid.headers.description"),
      width: 210,
      flex: 1,
    },
    {
      field: "Unit",
      headerName: t("data_grid.headers.unit"),
    },
    {
      field: "SI",
      headerName: t("data_grid.headers.s_i"),
    },
  ];

  const itemsColumns =
    type === "acceptance"
      ? [...itemsBaseColumns, quantityColumn]
      : [...itemsBaseColumns, priceColumn, quantityColumn, totalColumn];

  return (
    <Box sx={{ margin: "15px 2px" }}>
      {!useTemplate && (
        <Grid container spacing={1} marginBottom={1}>
          <Grid item xs={4}>
            <ChipComponent
              label={t("modules.acceptance.labels.start_date")}
              value={startDate}
            />
          </Grid>
          <Grid item xs={4}>
            <ChipComponent
              label={t("modules.acceptance.labels.end_date")}
              value={endDate}
            />
          </Grid>
          <Grid item xs={4}>
            <ChipComponent
              label={t("modules.acceptance.labels.D6_type")}
              value={selectedD6}
            />
          </Grid>
          {type !== "acceptance" && (
            <>
              <Grid item xs={4}>
                <ChipComponent
                  label={t("modules.acceptance.labels.total_amount")}
                  value={totalAmount}
                />
              </Grid>
              <Grid item xs={4}>
                <ChipComponent
                  label={t("modules.acceptance.labels.total_supply")}
                  value={totalInstall}
                />
              </Grid>
              <Grid item xs={4}>
                <ChipComponent
                  label={t("modules.acceptance.labels.total_install")}
                  value={totalSupply}
                />
              </Grid>
            </>
          )}
        </Grid>
      )}
      {isThreeStepProcess === false && (
        <CustomAccordion
          title={t("modules.material_request.label.work_orders")}
          rows={workOrderRows}
          columns={workOrderColumns}
          height={255}
          getRowId={(row) => row.idWorkOrder}
          hideFooter
          expanded={expanded === "panel2"}
          onChange={handleChange("panel2")}
        />
      )}

      <CustomAccordion
        title={t("modules.material_request.label.items")}
        rows={itemsRows}
        columns={itemsColumns}
        height={255}
        getRowId={(row) => row.idPriceList}
        hideFooter
        expanded={expanded === "panel1"}
        onChange={handleChange("panel1")}
      />
    </Box>
  );
};
export default Review;
