import Axios from "../network/Axios";

const getMailService = async (startDate, endDate) => {
  const response = await Axios.get("/MailService", {
    params: {
      Start: startDate,
      End: endDate,
    },
  });
  return response.data.result;
};

const getMailInfo = async (mailId) => {
  const response = await Axios.get("/MailService/", {
    params: {
      SelectedID: mailId,
      State: "CardDetailed",
    },
  });
  return response.data.result[0];
};

const resendMail = async (id) => {
  const response = await Axios.put("/MailService", {
    SelectedID: id,
    State: "Resend",
  });
  return response.data.result;
};

const getSettingsFormData = async () => {
  const response = await Axios.get("/MailService", {
    params: {
      State: "New",
    },
  });
  return response.data.result;
};

const updateMailServiceSettings = async (data) => {
  const response = await Axios.put("/MailService", data);
  return response.data;
};

export const MailServiceService = {
  getMailService,
  getMailInfo,
  resendMail,
  getSettingsFormData,
  updateMailServiceSettings,
};
