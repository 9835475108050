import FormsDataGrid from "../../../DataGrid/FormsDataGrid";
import DeleteIcon from "@mui/icons-material/Delete";
import { GridActionsCellItem } from "@mui/x-data-grid-pro";
import { Box } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

const ModifyItem = ({
  rows,
  onClick,
  setNewRows,
  getRowClassName,
  addMaterialsBtn,
}) => {
  const theme = useSelector((state) => state.theme.colorTheme);
  const handleDeleteClick = (id) => {
    onClick(id);
  };
  const [t] = useTranslation("global");
  const columns = [
    {
      field: "StoreItemsTemplateCode",
      headerName: t("data_grid.headers.item_code"),
    },
    {
      field: "StoreItemsTemplateName",
      headerName: t("data_grid.headers.item_description"),
      flex: 1,
    },
    {
      field: "StoreItemsTemplateUnit",
      headerName: t("data_grid.headers.unit"),
    },
    {
      field: "StoreItemsTemplateType",
      headerName: t("data_grid.headers.type"),
    },
    {
      field: "StoreItemsTemplateQty",
      headerName: t("data_grid.headers.item_total_balance"),
    },
    {
      field: "Quantity",
      headerName: t("data_grid.headers.quantity"),
      // valueGetter: (params) => params.row.Quantity || 0,
      renderCell: (params) => (
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            backgroundColor: theme === "dark" ? "#333" : "#e0e0e0",
            width: "100%",
            padding: "10px ",
            border: "1px solid ",
            borderColor: theme === "dark" ? "#e0e0e0" : "#333",
          }}
        >
          {params.row.Quantity || 0}
        </Box>
      ),
      editable: true,
      type: "number",
    },
    {
      field: "Actions",
      headerName: t("data_grid.headers.actions"),
      type: "actions",

      getActions: (params) => [
        <GridActionsCellItem
          key={params.row.idStoreItemsTemplateInfo}
          icon={<DeleteIcon color="error" />}
          label="Delete"
          onClick={() => handleDeleteClick(params.row.idStoreItemsTemplateInfo)}
        />,
      ],
    },
  ];

  return (
    <Box sx={{ margin: "12px 1px" }}>
      <FormsDataGrid
        rows={rows}
        columns={columns}
        getRowId={(row) => row.idStoreItemsTemplateInfo}
        height={400}
        onCellEditStop={(params, event) => {
          params.row.Quantity = event.target.value;
          setNewRows([...rows]);
        }}
        getRowClassName={getRowClassName}
        addMaterialsBtn={addMaterialsBtn}
        toolbar
      />
    </Box>
  );
};
export default ModifyItem;
