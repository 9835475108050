// Grouping the data by ModulesCategoryName using reduce
export const sortUserPriv = (UserPrivTable) => {
  return UserPrivTable.reduce((result, item) => {
    const category = item.ModulesCategoryName;

    if (!result[category]) {
      result[category] = {};
      result[category].header = item.ModulesCategoryName;
      result[category].headerAR = item.ModulesCategoryNameAR;
      result[category].menu = [];
    }

    result[category].menu.push({
      SystemModuleName: item.SystemModuleName,
      SystemPrivilegeAlias: item.SystemPrivilegeAlias,
      SystemModuleNameAR: item.SystemModuleNameAR,
    });

    return result;
  }, {});
};

// Convert image data to base64
export const convertImage = (data) => {
  let binary = "";
  let bytes = new Uint8Array(data);
  let len = bytes.byteLength;
  for (let i = 0; i < len; i++) {
    binary += String.fromCharCode(bytes[i]);
  }

  return `data:image/png;base64,` + btoa(binary);
};

// Load and combine two images into one
const loadImage = (src) => {
  return new Promise((resolve, reject) => {
    const img = new Image();
    img.src = src;
    img.onload = () => resolve(img);
    img.onerror = reject;
  });
};

export const combineImages = (base64Image1, base64Image2) => {
  return new Promise((resolve, reject) => {
    Promise.all([loadImage(base64Image1), loadImage(base64Image2)])
      .then(([img1, img2]) => {
        const canvas = document.createElement("canvas");
        const width = img1.width + img2.width;
        const height = Math.max(img1.height, img2.height);
        canvas.width = width;
        canvas.height = height;
        const ctx = canvas.getContext("2d");

        ctx.drawImage(img1, 0, 0);
        ctx.drawImage(img2, img1.width, 0);

        const combinedBase64 = canvas.toDataURL("image/png");
        resolve(combinedBase64);
      })
      .catch((error) => {
        reject("Error loading images:", error);
      });
  });
};
