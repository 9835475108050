import {
  Autocomplete,
  Box,
  Button,
  Checkbox,
  FormControlLabel,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFnsV3";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import * as XLSX from "xlsx";
import { useTranslation } from "react-i18next";
import FormsDataGrid from "../../../DataGrid/FormsDataGrid";
import { Controller } from "react-hook-form";
import VisuallyHiddenInput from "../../../VisuallyHiddenInput";

const checkboxStyles = {
  "& .MuiCheckbox-root": {
    padding: 0,
    pr: 0.5,
  },
  "& .MuiTypography-root": { userSelect: "none" },
};

const Upload = ({
  dispatch,
  state,
  handleSubmit,
  errors,
  register,
  control,
  setError,
  setValue,
  clearErrors,
  watch,
  resetField,
  companyAccounts,
}) => {
  const [t] = useTranslation("global");

  const { error, rows, columns } = state;

  const handleFileChange = (e) => {
    resetField("PriceList");
    const file = e.target.files[0];
    if (!file) return; // No file selected
    const reader = new FileReader();

    reader.onload = (event) => {
      const binaryString = event.target.result;
      const workbook = XLSX.read(binaryString, { type: "binary" });
      const sheetName = workbook.SheetNames[0];
      const sheet = workbook.Sheets[sheetName];
      const data = XLSX.utils.sheet_to_json(sheet, { header: 1 });

      const headerRow = data[0];
      const dataRows = data.slice(1);
      if (
        !headerRow ||
        headerRow.length !== 5 ||
        headerRow[0] !== "SN" ||
        headerRow[1] !== "Description" ||
        headerRow[2] !== "Unit" ||
        headerRow[3] !== "Supply" ||
        headerRow[4] !== "Install"
      ) {
        dispatch({
          type: "rows",
          payload: [],
        });

        setError("PriceList", {
          type: "invalid_file",
          message: t("modules.global_schema.invalid_file"),
        });

        return;
      }

      setValue("PriceList", file);
      clearErrors("PriceList");

      const columnsData = headerRow.map((header, index) => {
        const field =
          index === 0
            ? "SN"
            : index === 1
            ? "Description"
            : index === 2
            ? "Unit"
            : index === 3
            ? "Supply"
            : "Install";

        return index === 1
          ? { field, headerName: header, flex: 1 }
          : {
              field,
              headerName: header,
            };
      });

      const rowsData = dataRows.map((row) =>
        columnsData.reduce((acc, col, colIndex) => {
          acc[col.field] = row[colIndex];
          return acc;
        }, {})
      );

      dispatch({
        type: "columns",
        payload: columnsData,
      });
      dispatch({
        type: "rows",
        payload: rowsData,
      });
    };

    reader.readAsArrayBuffer(file);
  };

  return (
    <Stack alignItems={"stretch"} spacing={3} py={3}>
      <form onSubmit={handleSubmit()} noValidate>
        <Stack spacing={3}>
          <Stack direction={"row"} spacing={1} alignItems={"center"}>
            <TextField
              size="small"
              label={t("modules.prices_list.new_form.fields.price_list_name")}
              sx={{
                flexGrow: 1,
              }}
              error={Boolean(errors.PriceListRefNo)}
              helperText={errors.PriceListRefNo?.message}
              {...register("PriceListRefNo")}
              required
            />
            <Controller
              control={control}
              name="CompanyAccountName"
              render={({ field: { onChange, value } }) => (
                <Autocomplete
                  disableClearable
                  sx={{
                    flexGrow: 1,
                  }}
                  size="small"
                  options={companyAccounts.map(
                    (item) => item.CompanyAccountName
                  )}
                  onChange={(event, item) => {
                    onChange(item);
                  }}
                  value={value || null}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label={t(
                        "modules.prices_list.new_form.fields.account_name"
                      )}
                      error={!!errors.CompanyAccountName}
                      helperText={errors.CompanyAccountName?.message}
                      required
                    />
                  )}
                />
              )}
            />
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <Controller
                control={control}
                name="PriceListRefYear"
                render={({ field }) => {
                  return (
                    <DatePicker
                      label={t(
                        "modules.prices_list.new_form.fields.price_list_year"
                      )}
                      views={["year"]}
                      name="PriceListRefYear"
                      value={field.value}
                      inputRef={field.ref}
                      onChange={(date) => {
                        field.onChange(date);
                      }}
                      slotProps={{
                        textField: {
                          size: "small",
                          error: Boolean(errors.PriceListRefYear),
                          helperText: errors.PriceListRefYear?.message,
                          required: true,
                        },
                      }}
                      sx={{
                        flexBasis: "10%",
                      }}
                    />
                  );
                }}
              />
            </LocalizationProvider>

            <Controller
              name="SState"
              control={control}
              render={({ field }) => (
                <FormControlLabel
                  control={<Checkbox {...field} />}
                  label={t("modules.prices_list.new_form.fields.supply_suffix")}
                  checked={field.value}
                  sx={checkboxStyles}
                />
              )}
            />

            <TextField
              size="small"
              label={t("modules.prices_list.new_form.fields.supply_suffix")}
              disabled={!watch("SState")}
              error={Boolean(errors.SSuffiex)}
              helperText={errors.SSuffiex?.message}
              {...register("SSuffiex")}
              required
              sx={{
                flexBasis: "12%",
              }}
            />

            <Controller
              name="IState"
              control={control}
              render={({ field }) => (
                <FormControlLabel
                  control={<Checkbox {...field} />}
                  label={t(
                    "modules.prices_list.new_form.fields.install_suffix"
                  )}
                  checked={field.value}
                  sx={checkboxStyles}
                />
              )}
            />

            <TextField
              size="small"
              label={t("modules.prices_list.new_form.fields.install_suffix")}
              error={Boolean(errors.ISuffex)}
              helperText={errors.ISuffex?.message}
              {...register("ISuffex")}
              disabled={!watch("IState")}
              required
              sx={{
                flexBasis: "12%",
              }}
            />
          </Stack>
          <Stack direction={"row"} spacing={3} alignItems={"center"}>
            <Button
              component="label"
              role={undefined}
              variant="contained"
              startIcon={<CloudUploadIcon />}
              sx={{
                alignSelf: "flex-start",
              }}
            >
              {t("modules.global_schema.choose_file")}
              <Controller
                name={"PriceList"}
                control={control}
                render={() => {
                  return (
                    <VisuallyHiddenInput
                      type="file"
                      accept=".xlsx, .xls"
                      onChange={(e) => {
                        handleFileChange(e);
                      }}
                    />
                  );
                }}
              />
            </Button>
            {errors.PriceList && (
              <Typography
                color="red"
                sx={{
                  backgroundColor: "#ffdfdf",
                  p: 1,
                  borderRadius: "5px",
                  fontSize: "14px",
                }}
              >
                {errors.PriceList.message}
              </Typography>
            )}
          </Stack>
        </Stack>
      </form>
      {rows.length > 0 ? (
        <FormsDataGrid
          rows={rows}
          columns={columns}
          getRowId={(row) => row.SN}
          height={300}
        />
      ) : (
        <Box
          sx={{
            display: "grid",
            placeItems: "center",
            py: 10,
            border: "1px dashed grey",
            borderRadius: 5,
          }}
        >
          <Stack spacing={2} alignItems={"center"}>
            <Typography>
              {t(
                "modules.prices_list.new_form.instructions.upload_file_to_see_data"
              )}
            </Typography>
            {error && (
              <Typography
                color="red"
                sx={{
                  backgroundColor: "#ffdfdf",
                  p: 1,
                  borderRadius: "5px",
                  fontSize: "14px",
                }}
              >
                {error}
              </Typography>
            )}
          </Stack>
        </Box>
      )}
    </Stack>
  );
};

export default Upload;
