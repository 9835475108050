import { useTranslation } from "react-i18next";
import BarChart from "../../../../../components/charts/BarChart";
import CardContainer from "../../../CardContainer";
import { Link as RouterLink } from "react-router-dom";

const CompletedWorkWithoutAcceptance = ({
  CompleteWorkWithoutAcceptancePerProject,
  CompleteWorkWithoutAcceptancePerTeamLeader,
}) => {
  const { t } = useTranslation("global");

  const totalCountByProject = CompleteWorkWithoutAcceptancePerProject.reduce(
    (acc, data) => acc + data.Count,
    0
  );

  const totalCountByTeamLeader =
    CompleteWorkWithoutAcceptancePerTeamLeader.reduce(
      (acc, data) => acc + data.Count,
      0
    );

  const combinedUncompletedAcceptancePerProject = [
    "Total",
    ...CompleteWorkWithoutAcceptancePerProject.map(
      (project) => project.CompanyProjectsName
    ),
  ];

  const combinedUncompletedAcceptancePerTeamLeader = [
    "Total",
    ...CompleteWorkWithoutAcceptancePerTeamLeader.map(
      (teamLeader) => teamLeader.TeamLeadersName
    ),
  ];

  const uncompletedAcceptancePerProjectSeries = [
    {
      name: "Count",
      data: [
        totalCountByProject,
        ...CompleteWorkWithoutAcceptancePerProject.map(
          (project) => project.Count
        ),
      ],
    },
  ];

  const uncompletedAcceptancePerTeamLeaderSeries = [
    {
      name: "Count",
      data: [
        totalCountByTeamLeader,
        ...CompleteWorkWithoutAcceptancePerTeamLeader.map(
          (teamLeader) => teamLeader.Count
        ),
      ],
    },
  ];

  const columns = [
    {
      field: "WorkOrderNo",
      headerName: t("data_grid.headers.ref_no"),
      width: 140,
      flex: 1,
      renderCell: (params) => (
        <RouterLink
          style={{
            color: "#1790FF",
          }}
          to={`/workorders/${params.value}`}
          target="_blank"
        >
          {params.value}
        </RouterLink>
      ),
    },
    {
      field: "ActionDate",
      headerName: t("data_grid.headers.creation_date"),
      width: 140,
      flex: 1,
    },
    {
      field: "ERPUserNickName",
      headerName: t("data_grid.headers.created_by"),
      width: 140,
      flex: 1,
    },
    {
      field: "SiteName",
      headerName: t("data_grid.headers.site_name"),
      width: 140,
      flex: 1,
    },
    {
      field: "SiteCode",
      headerName: t("data_grid.headers.site_code"),
      width: 140,
      flex: 1,
    },
    {
      field: "TeamLeadersName",
      headerName: t("data_grid.headers.team_leader"),
      width: 140,
      flex: 1,
    },
  ];

  return (
    <CardContainer>
      <BarChart
        chartName="completed_work_without_acceptance"
        chartData={combinedUncompletedAcceptancePerProject}
        chartData2={combinedUncompletedAcceptancePerTeamLeader}
        seriesData={uncompletedAcceptancePerProjectSeries}
        seriesData2={uncompletedAcceptancePerTeamLeaderSeries}
        title={t("main_cards.completed_work_without_acceptance")}
        horizontal
        type="bar"
        switchConfig={{
          leftLabel: t("main_cards.labels.per_team_leader"),
          rightLabel: t("main_cards.labels.per_project"),
        }}
        modalConfig={{
          queryKey: "completedWorkWithoutAcceptanceByState",
          perUserState: "WorkOrdersAndWaitingApprovalPerTeamLeader",
          perProjectState: "WorkOrdersAndWaitingApprovalPerProject",
          columns,
          dataGridId: "idWorkOrder",
        }}
      />
    </CardContainer>
  );
};

export default CompletedWorkWithoutAcceptance;
