import { useState } from "react";
import { Box, Grid, Autocomplete, TextField, Typography } from "@mui/material";
import ChipComponent from "../../../../components/Chips/ChipComponent";
import { useTranslation } from "react-i18next";
import { useQuery, useMutation, useQueryClient } from "@tanstack/react-query";
import ChipStatus from "../../../../components/ChipStatus";
import PendingOutlinedIcon from "@mui/icons-material/PendingOutlined";
import RefreshOutlinedIcon from "@mui/icons-material/RefreshOutlined";
import DoneOutlineOutlinedIcon from "@mui/icons-material/DoneOutlineOutlined";
import DoneAllOutlinedIcon from "@mui/icons-material/DoneAllOutlined";
import PublishedWithChangesIcon from "@mui/icons-material/PublishedWithChanges";
import ConfirmationInfoModal from "../../../../components/Modals/ConfirmationInfoModal";
import ButtonGroup from "../../../../components/forms/ButtonGroup/ButtonGroup";
import ActionMenuButton from "../../../../components/forms/ButtonGroup/ActionMenuButton";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import {
  StyledTextField,
  autoCompleteStyles,
} from "../../../../utils/globalFunctions";
import Axios from "../../../../network/Axios";
import { useParams } from "react-router-dom";
import { useSnackbar } from "notistack";
import { useFormik } from "formik";
import * as Yup from "yup";
import dayjs from "dayjs";
import InfoModal from "../../../../components/Modals/InfoModal";
import ActionButton from "../../../../components/forms/ButtonGroup/ActionButton";
import ChangeCircleOutlinedIcon from "@mui/icons-material/ChangeCircleOutlined";

const QuotationDetailsCard = ({
  quotationInfo,
  handleOpenModal,
  isEditable,
  setIsEditable,
  outerModal,
}) => {
  const [t] = useTranslation("global");
  const { quotationId } = useParams();
  const queryClient = useQueryClient();
  const { enqueueSnackbar } = useSnackbar();
  const [confirmModal, setConfirmModal] = useState(false);
  const [isCancel, setIsCancel] = useState(false);

  const validationSchema = Yup.object().shape({
    D6Type: Yup.string().required("D6 Type is required"),
  });

  const initialValues = {
    D6Type: quotationInfo?.Details[0]?.WorkOrderD6TypeName,
    startDate: dayjs(quotationInfo?.Details[0]?.WorkOrderD6Start),
    endDate: dayjs(quotationInfo?.Details[0]?.WorkOrderD6End),
    survey: quotationInfo?.Details[0]?.Survey,
    powerUp: quotationInfo?.Details[0]?.PowerUP,
    projectName: quotationInfo?.Details[0]?.ProjectName,
    installation: quotationInfo?.Details[0]?.Installation,
  };
  const { data: comoBoxData } = useQuery({
    queryKey: ["quotation", "D6TypesCombo"],
    queryFn: async () => {
      const response = await Axios.get("/Quotations", {
        params: {
          State: "WorkOrderD6Types",
          SubProjectsName: quotationInfo?.Details[0]?.SubProjectsName,
        },
      });
      return response.data.result;
    },
    enabled: isEditable,
  });
  const {
    handleSubmit,
    values,
    setFieldValue,
    resetForm,
    dirty,
    errors,
    touched,
    handleBlur,
  } = useFormik({
    initialValues,
    validationSchema,
    onSubmit: () => {
      if (isCancel) {
        setConfirmModal(false);
      } else {
        setConfirmModal(true);
      }
    },
    enableReinitialize: true,
  });

  const actions =
    quotationInfo.Actions.filter(
      (action) =>
        action !== "Delete" &&
        action !== "View" &&
        action !== "Edit Details" &&
        action !== "Export"
    ) || [];

  const actionsConfig = {
    "Use As Template": {
      label: t("tooltip.use_as_template"),
      icon: (
        <ChangeCircleOutlinedIcon sx={{ width: "1.1rem", height: "1.1rem" }} />
      ),
      onClick: () => handleOpenModal("useAsTemplate"),
      color: "primary",
    },
    "Waiting Approval": {
      label: t("info_modules.quotation.description.waiting_approval"),
      icon: <PendingOutlinedIcon sx={{ width: "1.1rem", height: "1.1rem" }} />,
      onClick: () => handleOpenModal("confirmWaitingApproval"),
      color: "warning",
    },
    Approved: {
      label: t("info_modules.quotation.description.approve"),
      icon: <DoneAllOutlinedIcon sx={{ width: "1.1rem", height: "1.1rem" }} />,
      onClick: () =>
        (["Work Complete", "Cancelled"].includes(
          quotationInfo?.Details[0]?.WorkOrderStatus
        ) &&
          ["Single", "Final"].some((type) =>
            quotationInfo?.Details[0]?.WorkOrderD6TypeName?.includes(type)
          )) ||
        quotationInfo?.Details[0]?.WorkOrderD6TypeName?.includes("Extra")
          ? handleOpenModal("confirmApprove")
          : outerModal.handleOpen(),
      color: "success",
    },
    "Approval Check": {
      label: t("info_modules.quotation.description.approval_check"),
      icon: (
        <DoneOutlineOutlinedIcon sx={{ width: "1.1rem", height: "1.1rem" }} />
      ),
      onClick: () => handleOpenModal("approvalCheck"),
      color: "primary",
    },
    "Change To In Progress": {
      label: t("info_modules.quotation.description.change_in_progress"),
      icon: <RefreshOutlinedIcon sx={{ width: "1.1rem", height: "1.1rem" }} />,
      onClick: () => handleOpenModal("confirmChangeToInProgress"),
      color: "secondary",
    },
    "Check Approval": {
      label: t("info_modules.quotation.description.check_approval"),
      icon: (
        <PublishedWithChangesIcon sx={{ width: "1.1rem", height: "1.1rem" }} />
      ),
      onClick: () => handleOpenModal("confirmCheckApproval"),
      color: "primary",
    },
    "ُChange D6 Type": {
      label: t("info_modules.quotation.description.change_d6_type"),
      icon: <RefreshOutlinedIcon sx={{ width: "1.1rem", height: "1.1rem" }} />,
      onClick: () => handleOpenModal("changeD6Type"),
      color: "secondary",
    },
  };

  const newActions = quotationInfo.Actions.filter((action) =>
    action.includes("Create Final Acceptance")
  );

  const newButtonsConfig = {
    "Create Final Acceptance": {
      label: t(
        "info_modules.quotation.description.final_acceptance.create_final_acceptance"
      ),
      onClick: () => handleOpenModal("createFinalAcceptance"),
    },
  };

  const { mutateAsync: editQuotation, isPending: pendingEdit } = useMutation({
    mutationFn: async () => {
      return Axios.put("/Quotations", {
        State: "UpdateWorkOrderD6",
        WorkOrderD6Start: values.startDate.format("YYYY/MM/DD"),
        WorkOrderD6End: values.endDate.format("YYYY/MM/DD"),
        WorkOrderD6TypeName: values.D6Type,
        Survey: values.survey,
        PowerUP: `${values.powerUp}`,
        ProjectName: values.projectName,
        Installation: values.installation,
        SelectedNo: quotationId,
      });
    },
    onSuccess: (data) => {
      enqueueSnackbar("Edited Successfully", {
        variant: "success",
      });
      queryClient.invalidateQueries({
        queryKey: ["quotation", "info"],
      });
      setIsEditable(false);
      setConfirmModal(false);
      resetForm();
    },
  });

  return (
    <>
      <Box>
        <form onSubmit={handleSubmit}>
          {!isEditable && actions.length > 0 && (
            <Box
              display="flex"
              justifyContent="flex-end"
              marginBottom="4px"
              sx={{
                gap: "10px",
              }}
            >
              {newActions.length > 0 && (
                <Box
                  display="flex"
                  justifyContent="flex-end"
                  marginBottom="4px"
                >
                  <ActionMenuButton
                    actions={newActions}
                    actionsConfig={newButtonsConfig}
                    title={t("data_grid.buttons.new")}
                  />
                </Box>
              )}
              {actions.length > 1 ? (
                <ActionMenuButton
                  actions={actions}
                  actionsConfig={actionsConfig}
                />
              ) : (
                <ActionButton
                  icon={actionsConfig[actions[0]].icon}
                  text={actionsConfig[actions[0]].label}
                  onClick={actionsConfig[actions[0]].onClick}
                  color={actionsConfig[actions[0]].color}
                />
              )}
            </Box>
          )}
          <Grid container spacing={1}>
            <Grid item xs={4}>
              <ChipComponent
                label={t("data_grid.headers.d6_no")}
                value={quotationInfo?.Details[0]?.WorkOrderD6No}
              />
            </Grid>
            <Grid item xs={4}>
              <ChipComponent
                label={t("data_grid.headers.user")}
                value={quotationInfo?.Details[0]?.QUser}
              />
            </Grid>
            <Grid item xs={4}>
              <ChipComponent
                label={t("data_grid.headers.d6_type")}
                value={
                  isEditable ? (
                    <Autocomplete
                      disablePortal
                      id="combo-box-demo"
                      options={comoBoxData || []}
                      getOptionLabel={(option) => option.WorkOrderD6TypeName}
                      sx={autoCompleteStyles}
                      size="small"
                      onChange={(event, newValue) => {
                        setFieldValue(
                          "D6Type",
                          newValue ? newValue.WorkOrderD6TypeName : ""
                        );
                      }}
                      value={
                        (comoBoxData || []).find(
                          (option) =>
                            option.WorkOrderD6TypeName === values.D6Type
                        ) || null
                      }
                      renderInput={(params) => (
                        <StyledTextField
                          {...params}
                          onBlur={handleBlur}
                          error={!!touched.D6Type && !!errors.D6Type}
                          label={touched.D6Type && errors.D6Type}
                        />
                      )}
                    />
                  ) : (
                    quotationInfo?.Details[0]?.WorkOrderD6TypeName
                  )
                }
              />
            </Grid>
            <Grid item xs={4}>
              <ChipComponent
                label={t("data_grid.headers.status")}
                value={
                  <ChipStatus
                    value={quotationInfo?.Details[0]?.WorkOrderD6Status}
                  />
                }
              />
            </Grid>
            <Grid item xs={4}>
              <ChipComponent
                label={t("data_grid.headers.start_date")}
                value={
                  isEditable ? (
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <DatePicker
                        value={values.startDate}
                        onChange={(newValue) => {
                          setFieldValue("startDate", newValue);
                        }}
                        format="YYYY/MM/DD"
                        sx={{
                          "& .MuiInputBase-input": {
                            height: "10px",
                            width: "9.8rem",
                            fontSize: "0.85rem",
                            fontWeight: 600,
                          },
                          "& .MuiSvgIcon-root": {
                            width: "0.8em",
                            height: "0.8em",
                          },
                        }}
                        slotProps={{
                          textField: {
                            size: "small",
                          },
                        }}
                      />
                    </LocalizationProvider>
                  ) : (
                    quotationInfo?.Details[0]?.WorkOrderD6Start
                  )
                }
              />
            </Grid>
            <Grid item xs={4}>
              <ChipComponent
                label={t("data_grid.headers.end_date")}
                value={
                  isEditable ? (
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <DatePicker
                        value={values.endDate}
                        onChange={(newValue) => {
                          setFieldValue("endDate", newValue);
                        }}
                        format="YYYY/MM/DD"
                        sx={{
                          "& .MuiInputBase-input": {
                            height: "10px",
                            width: "9.8rem",
                            fontSize: "0.85rem",
                            fontWeight: 600,
                          },
                          "& .MuiSvgIcon-root": {
                            width: "0.8em",
                            height: "0.8em",
                          },
                        }}
                        slotProps={{
                          textField: {
                            size: "small",
                          },
                        }}
                      />
                    </LocalizationProvider>
                  ) : (
                    quotationInfo?.Details[0]?.WorkOrderD6End
                  )
                }
              />
            </Grid>
            <Grid item xs={4}>
              <ChipComponent
                label={t("data_grid.headers.acceptance_date")}
                value={quotationInfo?.Details[0]?.AcceptanceDate}
              />
            </Grid>
            {quotationInfo?.Details[0]?.WorkOrderD6TypeName?.includes(
              "MSC"
            ) && (
              <>
                <Grid item xs={4}>
                  <ChipComponent
                    label={t("data_grid.headers.project_name")}
                    value={
                      isEditable ? (
                        <TextField
                          value={values.projectName}
                          onChange={(event) => {
                            setFieldValue("projectName", event.target.value);
                          }}
                          size="small"
                          sx={{
                            "& .MuiInputBase-input": {
                              height: "10px",
                              fontSize: "0.85rem",
                              fontWeight: 600,
                            },
                          }}
                        />
                      ) : (
                        quotationInfo?.Details[0]?.ProjectName
                      )
                    }
                  />
                </Grid>
                <Grid item xs={4}>
                  <ChipComponent
                    label={t("data_grid.headers.survey")}
                    value={
                      isEditable ? (
                        <TextField
                          value={values.survey}
                          onChange={(event) => {
                            setFieldValue("survey", event.target.value);
                          }}
                          size="small"
                          sx={{
                            "& .MuiInputBase-input": {
                              height: "10px",
                              fontSize: "0.85rem",
                              fontWeight: 600,
                            },
                          }}
                        />
                      ) : (
                        quotationInfo?.Details[0]?.Survey
                      )
                    }
                  />
                </Grid>
                <Grid item xs={4}>
                  <ChipComponent
                    label={t("data_grid.headers.installation")}
                    value={
                      isEditable ? (
                        <TextField
                          value={values.installation}
                          onChange={(event) => {
                            setFieldValue("installation", event.target.value);
                          }}
                          size="small"
                          sx={{
                            "& .MuiInputBase-input": {
                              height: "10px",
                              fontSize: "0.85rem",
                              fontWeight: 600,
                            },
                          }}
                        />
                      ) : (
                        quotationInfo?.Details[0]?.Installation
                      )
                    }
                  />
                </Grid>
                <Grid item xs={4}>
                  <ChipComponent
                    label={t("data_grid.headers.power_up")}
                    value={
                      isEditable ? (
                        <TextField
                          value={values.powerUp}
                          onChange={(event) => {
                            setFieldValue("powerUp", event.target.value);
                          }}
                          size="small"
                          sx={{
                            "& .MuiInputBase-input": {
                              height: "10px",
                              fontSize: "0.85rem",
                              fontWeight: 600,
                            },
                          }}
                        />
                      ) : (
                        quotationInfo?.Details[0]?.PowerUP
                      )
                    }
                  />
                </Grid>
              </>
            )}
          </Grid>
          {isEditable && (
            <ButtonGroup
              onClickSave={() => {
                setIsCancel(false);
                if (dirty && Object.keys(errors).length === 0) {
                  setConfirmModal(true);
                }
              }}
              onClickClose={() => {
                if (dirty) {
                  setIsCancel(true);
                  setConfirmModal(true);
                } else {
                  setIsEditable(false);
                }
              }}
              saveLabel={t("modules.buttons.save")}
            />
          )}
        </form>

        <ConfirmationInfoModal
          open={confirmModal}
          handleClose={() => {
            setConfirmModal(false);
          }}
          onClick={() =>
            isCancel
              ? (setIsEditable(false), setConfirmModal(false), resetForm())
              : editQuotation()
          }
          description={
            isCancel
              ? t("info_modules.missions.description.cancel_without_saving")
              : t(
                  "info_modules.quotation.description.edit_quotation_confirmation"
                )
          }
          isPending={isCancel ? null : pendingEdit}
          color="error"
        />
      </Box>
      <InfoModal
        open={outerModal.open}
        handleClose={outerModal.handleClose}
        disableCloseOnBackdropClick
        title={t(
          "info_modules.quotation.description.confirmation_approve_modal"
        )}
        content={
          <Box padding="10px 15px">
            <Typography>
              {t("info_modules.quotation.description.confirmation_approve")}
            </Typography>
            <Box display="flex" justifyContent="center">
              <ButtonGroup
                onClickClose={outerModal.handleClose}
                onClickSave={() => handleOpenModal("approve")}
                cancelLabel={t("modules.buttons.no")}
                saveLabel={t("modules.buttons.yes")}
                backgroundColor="success.main"
                backgroundColorHover="success.dark"
              />
            </Box>
          </Box>
        }
      />
    </>
  );
};
export default QuotationDetailsCard;
