import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useTranslation } from "react-i18next";
import * as yup from "yup";
import { useSnackbar } from "notistack";
import { Stack, TextField } from "@mui/material";
import { yupResolver } from "@hookform/resolvers/yup";
import useHandleForms from "../../hooks/useHandleForms";
import { useForm } from "react-hook-form";
import { DeliveryNoteItemsService } from "../../api/deliveryNoteItems";
import ButtonGroup from "./ButtonGroup/ButtonGroup";

const initialValues = {
  oracle_code: "",
  description: "",
  unit: "",
};

const DeliveryNotesItemsForm = ({ onDirtyChange, onClickClose }) => {
  const queryClient = useQueryClient();

  const [t] = useTranslation("global");

  const schema = yup.object().shape({
    oracle_code: yup
      .string()
      .test(
        "checkForSpaces",
        t("modules.delivery_note_items.new_form.schema.no_spaces_allowed"),
        (value) => {
          return !/\s/.test(value);
        }
      )
      .required(t("modules.global_schema.required")),
    description: yup.string().required(t("modules.global_schema.required")),
    unit: yup.string().required(t("modules.global_schema.required")),
  });

  const handleFormSubmit = (values) => {
    const { oracle_code, description, unit } = values;
    AddNewDeliveryNoteItem({
      DeliveryNoteItemOracle: oracle_code,
      DeliveryNoteItemDescription: description,
      DeliveryNoteItemUnit: unit,
    });
  };

  const {
    register,
    handleSubmit,
    formState: { errors, isDirty },
  } = useForm({
    resolver: yupResolver(schema),
    mode: "onChange",
    defaultValues: initialValues,
  });

  const { handleClose } = useHandleForms({ dirty: isDirty, onDirtyChange });

  const { enqueueSnackbar } = useSnackbar();
  const { mutateAsync: AddNewDeliveryNoteItem, isPending } = useMutation({
    mutationFn: DeliveryNoteItemsService.AddNewDeliveryNoteItem,
    onSuccess: (data) => {
      handleClose();
      enqueueSnackbar(data.msg, { variant: "success" });
      queryClient.invalidateQueries({ queryKey: ["deliveryNoteItems"] });
    },
  });

  const labels = {
    oracle_code: t("modules.delivery_note_items.new_form.fields.oracle_code"),
    description: t("modules.delivery_note_items.new_form.fields.description"),
    unit: t("modules.delivery_note_items.new_form.fields.unit"),
  };

  return (
    <form onSubmit={handleSubmit(handleFormSubmit)} noValidate>
      <Stack spacing={2} p={2}>
        <TextField
          size="small"
          label={labels.oracle_code}
          error={Boolean(errors.oracle_code)}
          helperText={errors.oracle_code?.message}
          {...register("oracle_code")}
          required
        />
        <TextField
          size="small"
          label={labels.description}
          error={Boolean(errors.description)}
          helperText={errors.description?.message}
          {...register("description")}
          required
        />
        <TextField
          size="small"
          label={labels.unit}
          error={Boolean(errors.unit)}
          helperText={errors.unit?.message}
          {...register(".unit")}
          required
        />
      </Stack>

      <ButtonGroup
        isPending={isPending}
        onClickClose={onClickClose}
        saveLabel={t("modules.buttons.save")}
      />
    </form>
  );
};

export default DeliveryNotesItemsForm;
