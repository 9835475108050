import Axios from "../network/Axios";
const ENDPOINT = "/Accounts&Projects";

const getAllAccountsProjects = async () => {
  const response = await Axios.get(ENDPOINT);
  return response.data.result;
};
const getAccountsProjects = async () => {
  const response = await Axios.get(ENDPOINT, { params: { State: "New" } });
  return response.data;
};
const getAccounts = async () => {
  const response = await Axios.get(ENDPOINT, { params: { State: "Accounts" } });
  return response.data.result;
};
const getProjects = async () => {
  const response = await Axios.get(ENDPOINT, { params: { State: "Projects" } });
  return response.data.result;
};
const newAccount = async (accountName) => {
  const res = await Axios.post(ENDPOINT, {
    State: "NewAccount",
    CompanyAccountName: accountName,
  });

  return res.data;
};
const newProject = async (data) => {
  const res = await Axios.post(ENDPOINT, {
    State: "NewProject",
    ...data,
  });
  return res.data;
};
const newSubProject = async (data) => {
  const res = await Axios.post(ENDPOINT, {
    State: "NewSubProject",
    ...data,
  });
  return res.data;
};
const editAccount = async (data) => {
  const res = await Axios.put(ENDPOINT, {
    State: "EditAccount",
    ...data,
  });
  return res.data;
};
const editProject = async (data) => {
  const res = await Axios.put(ENDPOINT, {
    State: "EditProject",
    ...data,
  });
  return res.data;
};
const editSubProject = async (data) => {
  const res = await Axios.put(ENDPOINT, {
    State: "EditSubProject",
    ...data,
  });
  return res.data;
};
const deleteAccount = async (data) => {
  const res = await Axios.delete(ENDPOINT, {
    params: {
      State: "DeleteAccount",
      ...data,
    },
  });
  return res.data;
};
const deleteProject = async (data) => {
  const res = await Axios.delete(ENDPOINT, {
    params: {
      State: "DeleteProject",
      ...data,
    },
  });
  return res.data;
};
const deleteSubProject = async (data) => {
  const res = await Axios.delete(ENDPOINT, {
    params: { State: "DeleteSubProject", ...data },
  });
  return res.data;
};

export const AccountsProjectsService = {
  getAllAccountsProjects,
  getAccounts,
  getProjects,
  getAccountsProjects,
  newAccount,
  newProject,
  newSubProject,
  editAccount,
  editProject,
  editSubProject,
  deleteAccount,
  deleteProject,
  deleteSubProject,
};
