import Axios from "../network/Axios";

const API_URL = "/PriceLists";

const CheckPriceList = async (data) => {
  const res = await Axios.post(API_URL, data);

  return res.data;
};

const AddNewPriceList = async (data) => {
  const res = await Axios.post(API_URL, data);

  return res.data;
};

const GetPriceLists = async () => {
  const response = await Axios.get(API_URL);
  return response.data.result;
};

const getNewForm = async () => {
  const response = await Axios.get(API_URL, {
    params: {
      State: "New",
    },
  });
  return response.data.msg;
};

const GetPriceList = async (id) => {
  const response = await Axios.get("/PriceLists/", {
    params: {
      SelectedID: id,
      State: "Card",
    },
  });
  return response.data;
};

const updatePriceList = async (data) => {
  const res = await Axios.put(API_URL, data);
  return res.data;
};

const patchPriceList = async (data) => {
  const res = await Axios.patch(API_URL, data);
  return res.data;
};

const getPriceListGroups = async (id) => {
  const response = await Axios.get(API_URL, {
    params: {
      SelectedNo: id,
      State: "Assign",
    },
  });
  return response.data.result;
};

// Get Edit Item data
const getEditItemData = async (id) => {
  const response = await Axios.get(API_URL, {
    params: {
      SelectedID: id,
      State: "EditItems",
    },
  });
  return response.data;
};

// Get Replace Item data
const getReplaceItemData = async (id) => {
  const response = await Axios.get(API_URL, {
    params: {
      SelectedID: id,
      State: "ReplacmentItems",
    },
  });
  return response.data;
};

// Get edit form data
const getEditFormData = async (id) => {
  const response = await Axios.get(API_URL, {
    params: {
      SelectedNo: id,
      State: "Edit",
    },
  });
  return response.data;
};

export const priceListsService = {
  CheckPriceList,
  AddNewPriceList,
  GetPriceLists,
  GetPriceList,
  updatePriceList,
  getPriceListGroups,
  patchPriceList,
  getEditItemData,
  getReplaceItemData,
  getNewForm,
  getEditFormData,
};
