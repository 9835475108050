import BarChart from "../../../../../components/charts/BarChart";
import { useTranslation } from "react-i18next";
import CardContainer from "../../../CardContainer";
import { Link as RouterLink } from "react-router-dom";
import { Link } from "@mui/material";
import dayjs from "dayjs";

const AcceptanceProgress = ({
  acceptanceProgressPerProject,
  acceptanceProgressPerUser,
}) => {
  const { t } = useTranslation("global");

  // Total
  const totalCountByProject = acceptanceProgressPerProject.reduce(
    (acc, data) => acc + data.Count,
    0
  );

  const totalCountByUser = acceptanceProgressPerUser.reduce(
    (acc, data) => acc + data.Count,
    0
  );

  const totalAmountByProject = acceptanceProgressPerProject.reduce(
    (acc, data) => acc + parseFloat(data.TotalAmount.replace(/,/g, "")),
    0
  );

  const totalAmountByUser = acceptanceProgressPerUser.reduce(
    (acc, data) => acc + parseFloat(data.TotalAmount.replace(/,/g, "")),
    0
  );

  // Data sorted by amount and count
  const acceptanceProgressPerUserSortedByAmount =
    acceptanceProgressPerUser.toSorted(
      (a, b) =>
        parseFloat(b.TotalAmount.replace(/,/g, "")) -
        parseFloat(a.TotalAmount.replace(/,/g, ""))
    );

  const acceptanceProgressPerUserSortedByCount =
    acceptanceProgressPerUser.toSorted((a, b) => b.Count - a.Count);

  const acceptanceProgressPerProjectSortedByAmount =
    acceptanceProgressPerProject.toSorted(
      (a, b) =>
        parseFloat(b.TotalAmount.replace(/,/g, "")) -
        parseFloat(a.TotalAmount.replace(/,/g, ""))
    );

  const acceptanceProgressPerProjectSortedByCount =
    acceptanceProgressPerProject.toSorted((a, b) => b.Count - a.Count);

  // Final data for the chart
  // Project
  const combinedPendingQuotationsPerProjectByCount = [
    "Total",
    ...acceptanceProgressPerProjectSortedByCount.map(
      (project) => project.CompanyProjectsName
    ),
  ];

  const combinedPendingQuotationsPerProjectByAmount = [
    "Total",
    ...acceptanceProgressPerProjectSortedByAmount.map(
      (project) => project.CompanyProjectsName
    ),
  ];

  // User
  const combinedPendingQuotationsPerUserByCount = [
    "Total",
    ...acceptanceProgressPerUserSortedByCount.map(
      (user) => user.ERPUserNickName
    ),
  ];

  const combinedPendingQuotationsPerUserByAmount = [
    "Total",
    ...acceptanceProgressPerUserSortedByAmount.map(
      (user) => user.ERPUserNickName
    ),
  ];

  // Series data
  // Project
  const pendingQuotationsPerProjectByCountSeries = [
    {
      name: "Count",
      data: [
        totalCountByProject,
        ...acceptanceProgressPerProjectSortedByCount.map((data) => data.Count),
      ],
    },
  ];

  const pendingQuotationsPerProjectByAmountSeries = [
    {
      name: "Amount",
      data: [
        totalAmountByProject,
        ...acceptanceProgressPerProjectSortedByAmount.map((data) =>
          parseFloat(data.TotalAmount.replace(/,/g, ""))
        ),
      ],
    },
  ];

  // User
  const pendingQuotationsPerUserByCountSeries = [
    {
      name: "Count",
      data: [
        totalCountByUser,
        ...acceptanceProgressPerUserSortedByCount.map((data) => data.Count),
      ],
    },
  ];

  const pendingQuotationsPerUserByAmountSeries = [
    {
      name: "Amount",
      data: [
        totalAmountByUser,
        ...acceptanceProgressPerUserSortedByAmount.map((data) =>
          parseFloat(data.TotalAmount.replace(/,/g, ""))
        ),
      ],
    },
  ];

  const columns = [
    {
      field: "WorkOrderD6No",
      headerName: t("data_grid.headers.ref_no"),
      width: 110,
      renderCell: (params) => {
        return (
          <Link
            component={RouterLink}
            to={`/quotations/${params.value}`}
            target="_blank"
          >
            {params.value}
          </Link>
        );
      },
    },
    {
      field: "SiteName",
      headerName: t("data_grid.headers.site_name"),
      width: 140,
      flex: 1,
    },
    {
      field: "SiteCode",
      headerName: t("data_grid.headers.site_code"),
      width: 140,
      flex: 1,
    },
    {
      field: "QUser",
      headerName: t("data_grid.headers.created_by"),
      width: 140,
      flex: 1,
    },
    {
      field: "WorkOrderD6TypeName",
      headerName: t("data_grid.headers.d6_type"),
      width: 140,
      flex: 1,
    },
    {
      field: "WorkOrderD6Install",
      headerName: t("data_grid.headers.install_amount"),
      width: 140,
      flex: 1,
    },
    {
      field: "WorkOrderD6Supply",
      headerName: t("data_grid.headers.supply_amount"),
      width: 140,
      flex: 1,
    },
    {
      field: "WorkOrderD6Amount",
      headerName: t("data_grid.headers.amount"),
      width: 140,
      flex: 1,
    },
    {
      field: "AcceptanceDate",
      headerName: t("data_grid.headers.acceptance_date"),
      width: 140,
      flex: 1,
    },
    {
      field: "SubProjectsName",
      headerName: t("data_grid.headers.sub_project"),
      width: 140,
      flex: 1,
    },
    {
      field: "WorkOrderDate",
      headerName: t("data_grid.headers.received_date"),
      width: 120,
      type: "date",
      valueGetter: ({ value }) => (value ? new Date(value) : null),
      renderCell: ({ value }) => {
        return value ? dayjs(value).format("YYYY/MM/DD") : "";
      },
    },
  ];

  return (
    <CardContainer>
      <BarChart
        chartName="pendingQuotations"
        chartData={combinedPendingQuotationsPerProjectByCount}
        chartData2={combinedPendingQuotationsPerUserByCount}
        seriesData={pendingQuotationsPerProjectByCountSeries}
        seriesData2={pendingQuotationsPerUserByCountSeries}
        secondView={{
          chartData: combinedPendingQuotationsPerProjectByAmount,
          chartData2: combinedPendingQuotationsPerUserByAmount,
          seriesData: pendingQuotationsPerProjectByAmountSeries,
          seriesData2: pendingQuotationsPerUserByAmountSeries,
        }}
        title={t("main_cards.pending_quotation_status")}
        horizontal
        type="bar"
        switchConfig={{
          leftLabel: t("main_cards.labels.per_user"),
          rightLabel: t("main_cards.labels.per_project"),
        }}
        switch2Config={{
          leftLabel: t("main_cards.labels.by_count"),
          rightLabel: t("main_cards.labels.by_amount"),
        }}
        modalConfig={{
          queryKey: "pendingQuotations",
          perUserState: "QuotationsCountPerUser",
          perProjectState: "QuotationsCountPerProject",
          columns,
          dataGridId: "WorkOrderD6No",
        }}
      />
    </CardContainer>
  );
};

export default AcceptanceProgress;
