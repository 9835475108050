import { Box, Stack, Typography } from "@mui/material";

const ChartType = ({ setActiveStep, handleChartType }) => {
  return (
    <Box
      sx={{
        display: "grid",
        gridTemplateColumns: "repeat(2, 1fr)",
        gap: "1rem",
        height: "100%",
        alignItems: "center",
      }}
    >
      <ChartCard
        image={{ url: "/bar-chart.svg", alt: "bar chart" }}
        setActiveStep={setActiveStep}
        handleChartType={handleChartType}
        type="Bar"
      />

      <ChartCard
        image={{ url: "/pie-chart.svg", alt: "pie chart" }}
        setActiveStep={setActiveStep}
        handleChartType={handleChartType}
        type="Donut"
      />
    </Box>
  );
};

const ChartCard = ({ image, setActiveStep, handleChartType, type }) => {
  return (
    <Box
      sx={{
        height: "20rem",
        border: "1px solid",
        borderColor: "secondary.main",
        borderRadius: "8px",
        padding: "1rem",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        transition: "background-color 0.3s",
        "&:hover": {
          cursor: "pointer",
          backgroundColor: "secondary.main",
        },
      }}
      onClick={() => {
        handleChartType(type);
        setActiveStep(1);
      }}
    >
      <Stack direction="column" spacing={2} alignItems="center">
        <img
          src={image.url}
          alt={image.alt}
          style={{
            width: "35%",
            height: "35%",
            objectFit: "contain",
          }}
        />
        <Typography variant="h6" align="center">
          {type === "Bar" ? "Bar Chart" : "Circle Chart"}
        </Typography>
      </Stack>
    </Box>
  );
};

export default ChartType;
