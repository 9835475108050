import { Box, List, ListItem, Stack, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";

const Modify = () => {
  const [t] = useTranslation("global");

  return (
    <Stack alignItems={"center"} spacing={3} py={2}>
      <Typography variant="h6" color={"primary"}>
        {t("modules.prices_list.new_form.instructions.title")}
      </Typography>
      <Box>
        <img
          src="./ExcelSheet1.png"
          alt="Example excel sheet"
          style={{ width: "100%" }}
        />
      </Box>
      <Box>
        <Typography variant="h6" fontSize={18} color={"primary"}>
          {t("modules.prices_list.new_form.instructions.headline")}
        </Typography>
        <List>
          <ListItem>
            1. {t("modules.prices_list.new_form.instructions.list.item_one")}
          </ListItem>
          <ListItem>
            2. {t("modules.prices_list.new_form.instructions.list.item_two")}
          </ListItem>
          <ListItem>
            3. {t("modules.prices_list.new_form.instructions.list.item_three")}
          </ListItem>
          <ListItem>
            4. {t("modules.prices_list.new_form.instructions.list.item_four")}
          </ListItem>
          <ListItem>
            5. {t("modules.prices_list.new_form.instructions.list.item_five")}
          </ListItem>
        </List>
      </Box>
    </Stack>
  );
};

export default Modify;
