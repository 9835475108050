import { useState, useEffect } from "react";
import CustomDataGrid from "../components/DataGrid/CustomDataGrid";
import { useTranslation } from "react-i18next";
import { statusColors } from "./statuses";
import { Box } from "@mui/material";
import dayjs from "dayjs";
import { useQuery } from "@tanstack/react-query";
import Spinner from "../components/Spinners/Spinner";
import ChipStatus from "../components/ChipStatus";
import { employeeRecordsService } from "../api/employeeRecords";
import useOpenNewForm from "../hooks/useOpenNewForm";
import { useSelector } from "react-redux";
import { useConfirmationDialog } from "../contexts/ConfirmationDialogContext";
import { Link } from "react-router-dom";
import { combineImages, convertImage } from "../utils/utils";
import ImageModal from "../components/Modals/ImageModal";

const EmployeeRecords = () => {
  const { newForm } = useSelector((state) => state.theme);
  const [nationalId, setNationalId] = useState("");
  const [nationalIdImage, setNationalIdImage] = useState(null);
  const [insuranceId, setInsuranceId] = useState("");
  const [insuranceImage, setInsuranceImage] = useState(null);
  const [isImageLoading, setIsImageLoading] = useState(false);
  const [isNationalIdModalOpen, setIsNationalIdModalOpen] = useState(false);
  const [isInsuranceModalOpen, setIsInsuranceModalOpen] = useState(false);

  const handleOpenNationalIdModal = () => setIsNationalIdModalOpen(true);
  const handleCloseNationalIdModal = () => setIsNationalIdModalOpen(false);

  const handleOpenInsuranceModal = () => setIsInsuranceModalOpen(true);
  const handleCloseInsuranceModal = () => setIsInsuranceModalOpen(false);

  // Handling new form logic
  const { handleOpen } = useOpenNewForm();
  const { setOpen } = useConfirmationDialog();

  const [t] = useTranslation("global");

  const statusNames = [
    { name: "Active", displayName: t("data_grid.tabs.active") },
    { name: "Inactive", displayName: t("data_grid.tabs.inactive") },
  ];

  const columns = [
    {
      field: "EmpCode",
      headerName: t("data_grid.headers.emp_code"),
      width: 70,
      renderCell: (params) => {
        return (
          <Link
            style={{
              color: "#1790FF",
            }}
            to={`/employeerecords/${params.value.replace("/", "%2F")}`}
          >
            {params.value}
          </Link>
        );
      },
    },
    {
      field: "EmpNameEn",
      headerName: t("data_grid.headers.employee_name_en"),
      width: 140,
      flex: 1,
    },
    {
      field: "EmpName",
      headerName: t("data_grid.headers.employee_name_ar"),
      width: 140,
      flex: 1,
    },
    {
      field: "EmpTitle",
      headerName: t("data_grid.headers.emp_title"),
      width: 100,
    },
    {
      field: "CompanyDepartmentName",
      headerName: t("data_grid.headers.department"),
      width: 110,
    },
    {
      field: "EmpDateOfHire",
      headerName: t("data_grid.headers.hire_date"),
      type: "date",
      valueGetter: ({ value }) => (value ? new Date(value) : null),
      renderCell: ({ value }) => {
        return value ? dayjs(value).format("YYYY/MM/DD") : "";
      },
      width: 90,
    },
    {
      field: "EmpResignDate",
      headerName: t("data_grid.headers.resign_date"),
      type: "date",
      valueGetter: ({ value }) => (value ? new Date(value) : null),
      renderCell: ({ value }) => {
        return value ? dayjs(value).format("YYYY/MM/DD") : "";
      },
      width: 90,
    },
    {
      field: "EmpDateOfBirth",
      headerName: t("data_grid.headers.date_birth"),
      type: "date",
      valueGetter: ({ value }) => (value ? new Date(value) : null),
      renderCell: ({ value }) => {
        return value ? dayjs(value).format("YYYY/MM/DD") : "";
      },
      width: 90,
    },
    {
      field: "EmpInfoEntExt",
      headerName: t("data_grid.headers.int_ext"),
      width: 110,
      renderCell: ({ value }) => {
        return <ChipStatus value={value} />;
      },
    },
    {
      field: "EmpStatus",
      headerName: t("data_grid.headers.status"),
      width: 110,
      renderCell: ({ value }) => {
        return <ChipStatus value={value} />;
      },
    },
    {
      field: "CompanyProjectsName",
      headerName: t("data_grid.headers.project"),
      width: 90,
    },

    {
      field: "EmpNationalID",
      headerName: t("data_grid.headers.national_id"),
      width: 115,
      renderCell: (params) => {
        return (
          <Link
            style={{
              color: "#1790FF",
            }}
            onClick={() => {
              setNationalId(params.row.EmpCode);
              handleOpenNationalIdModal();
              setIsImageLoading(true);
            }}
          >
            {params.value}
          </Link>
        );
      },
    },
    {
      field: "EmpEffictiveDate",
      headerName: t("data_grid.headers.national_id_expired"),
      width: 90,
      renderCell: ({ value }) => {
        const expired = dayjs(value).isBefore(dayjs(), "day");
        const oneDayFromNow = dayjs().add(1, "day");
        const oneMonthFromNow = dayjs().add(1, "month");
        const isWithinRange = dayjs(value).isBetween(
          oneDayFromNow,
          oneMonthFromNow,
          null
        );
        let backgroundColor = "inherit";
        let color = "inherit";
        if (expired) {
          backgroundColor = "#ff4d6d";
          color = "white";
        } else if (isWithinRange) {
          backgroundColor = "#fa9500";
          color = "white";
        }
        return (
          <Box
            sx={{
              padding: "5px",
              backgroundColor: backgroundColor,
              color: color,
              fontSize: "13px",
              fontWeight: 600,
            }}
          >
            {value ? dayjs(value).format("YYYY/MM/DD") : ""}
          </Box>
        );
      },
    },
    {
      field: "LicenseEndDate",
      headerName: t("data_grid.headers.drive_expired"),
      width: 90,
      renderCell: ({ value }) => {
        const expired = dayjs(value).isBefore(dayjs(), "day");
        const oneDayFromNow = dayjs().add(1, "day");
        const oneMonthFromNow = dayjs().add(1, "month");
        const isWithinRange = dayjs(value).isBetween(
          oneDayFromNow,
          oneMonthFromNow,
          null
        );
        let backgroundColor = "inherit";
        let color = "inherit";
        if (expired) {
          backgroundColor = "#ff4d6d";
          color = "white";
        } else if (isWithinRange) {
          backgroundColor = "#fa9500";
          color = "white";
        }
        return (
          <Box
            sx={{
              padding: "5px",
              backgroundColor: backgroundColor,
              color: color,
              fontSize: "13px",
              fontWeight: 600,
            }}
          >
            {value ? dayjs(value).format("YYYY/MM/DD") : ""}
          </Box>
        );
      },
    },
    {
      field: "EmpInfoSocialInsuranceNo",
      headerName: t("data_grid.headers.insurance_no"),
      width: 115,
      renderCell: (params) => {
        return (
          <Link
            style={{
              color: "#1790FF",
            }}
            onClick={() => {
              setInsuranceId(params.row.EmpCode);
              handleOpenInsuranceModal();
              setIsImageLoading(true);
            }}
          >
            {params.value}
          </Link>
        );
      },
    },
    {
      field: "EmpInfoSocialInsuranceStartDate",
      headerName: t("data_grid.headers.insurance_date"),
      width: 90,
      type: "date",
      valueGetter: ({ value }) => (value ? new Date(value) : null),
      renderCell: ({ value }) => {
        return value ? dayjs(value).format("YYYY/MM/DD") : "";
      },
    },
    {
      field: "EmpInsurance",
      headerName: t("data_grid.headers.social_insurance"),
      width: 110,
      renderCell: ({ value }) => {
        return <ChipStatus value={value} />;
      },
    },
    {
      field: "EmpMedical",
      headerName: t("data_grid.headers.medical_insurance"),
      width: 110,
      renderCell: ({ value }) => {
        return <ChipStatus value={value} />;
      },
    },
  ];
  const { refetch: refetchImage } = useQuery({
    queryKey: ["nationalIdImage", nationalId],
    queryFn: () => employeeRecordsService.getNationalIdFormData(nationalId),
    enabled: false,
  });
  const { refetch: refetchSocialInsurance } = useQuery({
    queryKey: ["socialInsuranceImages", insuranceId],
    queryFn: () =>
      employeeRecordsService.getEmployeeSocialInsuranceImage(insuranceId),
    enabled: false,
  });
  useEffect(() => {
    if (nationalId) {
      refetchImage().then((data) => {
        const frontImage = data.data.NationalID[0].EmpNationalIDPhotoFront;
        const backImage = data.data.NationalID[0].EmpNationalIDPhotoBack;

        if (!frontImage && !backImage) {
          setNationalIdImage(null);
          setIsImageLoading(false);
          return;
        }

        const image1 = convertImage(frontImage.data);
        const image2 = convertImage(backImage.data);

        combineImages(image1, image2)
          .then((b64) => setNationalIdImage(b64))
          .finally(() => setIsImageLoading(false));
      });
    }
  }, [nationalId]); // This will run every time nationalId updates
  useEffect(() => {
    if (insuranceId) {
      refetchSocialInsurance()
        .then((data) => {
          setInsuranceImage(
            convertImage(
              data?.data?.SocialInsurance[0].EmpInfoSocialInsuranceDocument.data
            )
          );
        })
        .then(() => setIsImageLoading(false))
        .catch(() => {
          setInsuranceImage(null);
          setIsImageLoading(false);
        });
    }
  }, [insuranceId]);
  const {
    data: rows,
    isLoading,
    refetch,
    isRefetching,
  } = useQuery({
    queryKey: ["employeeRecords"],
    queryFn: employeeRecordsService.GetEmployeeRecords,
  });

  if (isLoading) {
    return <Spinner />;
  }

  return (
    <>
      <CustomDataGrid
        rows={rows}
        columns={columns}
        loading={isLoading}
        getRowId={(row) => row.idEmpInfo}
        statusColors={statusColors}
        statusNames={statusNames}
        filterField="EmpStatus"
        showReloadOverlay={isRefetching}
        reloadFunction={refetch}
        initialState={{
          columns: {
            columnVisibilityModel: {
              CompanyProjectsName: false,
              EmpNationalID: false,
              EmpEffictiveDate: false,
              LicenseEndDate: false,
              EmpInfoSocialInsuranceNo: false,
              EmpInfoSocialInsuranceStartDate: false,
              EmpInsurance: false,
              EmpMedical: false,
            },
          },
        }}
        newButton
        newFunction={() => {
          if (newForm === "employee_records" || newForm === null) {
            handleOpen("employee_records");
          } else {
            setOpen(t("modules.new_form_confirmation_message"), () => () => {
              handleOpen("employee_records");
            });
          }
        }}
      />
      <ImageModal
        image={nationalIdImage}
        image2
        open={isNationalIdModalOpen}
        handleClose={handleCloseNationalIdModal}
        title={t(
          "info_modules.employee_records.cards.national_id.image_titles.national_id_documents"
        )}
        loading={isImageLoading}
        saveLabel={`${nationalId} - NationalID`}
      />

      <ImageModal
        image={insuranceImage}
        open={isInsuranceModalOpen}
        handleClose={handleCloseInsuranceModal}
        title={t("info_modules.employee_records.cards.social_insurance.title")}
        saveLabel={`${insuranceId} - SocialInsurance`}
        loading={isImageLoading}
      />
    </>
  );
};

export default EmployeeRecords;
