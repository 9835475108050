import { useState } from "react";
import { Box, Grid, TextField } from "@mui/material";
import ChipComponent from "../../../../components/Chips/ChipComponent";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import LockOpenIcon from "@mui/icons-material/LockOpen";
import { useTranslation } from "react-i18next";
import ButtonGroup from "../../../../components/forms/ButtonGroup/ButtonGroup";
import ConfirmationInfoModal from "../../../../components/Modals/ConfirmationInfoModal";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";
import ActionButton from "../../../../components/forms/ButtonGroup/ActionButton";
import { useParams } from "react-router-dom";
import Axios from "../../../../network/Axios";
import { useSnackbar } from "notistack";
import { useFormik } from "formik";
import dayjs from "dayjs";
import * as Yup from "yup";
import ActionMenuButton from "../../../../components/forms/ButtonGroup/ActionMenuButton";

const MissionCard = ({
  missionInfo,
  handleOpenModal,
  isEditable,
  setIsEditable,
}) => {
  const [t] = useTranslation("global");
  const { missionId } = useParams();
  const queryClient = useQueryClient();
  const { enqueueSnackbar } = useSnackbar();
  const [confirmModal, setConfirmModal] = useState(false);
  const [isCancel, setIsCancel] = useState(false);

  const actions =
    missionInfo?.Actions.filter((action) => action !== "Edit Details") || [];

  const actionsConfig = {
    "Change To Done": {
      label: t("info_modules.missions.description.close_mission"),
      icon: (
        <CheckCircleOutlineIcon sx={{ width: "1.1rem", height: "1.1rem" }} />
      ),
      onClick: () => handleOpenModal("closeMission"),
      color: "success",
    },
    "Change To Cancelled": {
      label: t("info_modules.missions.description.cancel_mission"),
      icon: <HighlightOffIcon sx={{ width: "1.1rem", height: "1.1rem" }} />,
      onClick: () => handleOpenModal("cancelMission"),
      color: "warning",
    },
    "Request A Change": {
      label: t("info_modules.missions.description.request_change"),
      icon: <LockOpenIcon sx={{ width: "1.1rem", height: "1.1rem" }} />,
      onClick: () => handleOpenModal("changeMission"),
      color: "primary",
    },
  };

  const validationSchema = Yup.object().shape({
    descriptionMission: Yup.string().required("Description is required"),
  });

  const initialValues = {
    startDate: dayjs(missionInfo?.result[0]?.MissionStart),
    descriptionMission: missionInfo?.result[0]?.MissionDescription || "",
  };

  const { mutateAsync: handleSave, isPending: editMission } = useMutation({
    mutationFn: async () => {
      return Axios.put("/Missions", {
        State: "EditMissionDetails",
        SelectedNo: missionId,
        MissionStart: values.startDate.format("YYYY/MM/DD HH:mm:ss"),
        MissionDescription: values.descriptionMission,
      });
    },
    onSuccess: (data) => {
      if (!data) return;
      enqueueSnackbar("Edited Successfully", {
        variant: "success",
      });
      queryClient.invalidateQueries({
        queryKey: ["missionsInfo", missionId],
      });
      setIsEditable(false);
      setConfirmModal(false);
      resetForm();
    },
  });

  const {
    handleSubmit,
    values,
    setFieldValue,
    resetForm,
    dirty,
    errors,
    touched,
    handleBlur,
  } = useFormik({
    initialValues,
    validationSchema,
    onSubmit: () => {
      if (isCancel) {
        setConfirmModal(false);
      } else {
        setConfirmModal(true);
      }
    },
    enableReinitialize: true,
  });

  return (
    <Box>
      <form onSubmit={handleSubmit}>
        {!isEditable && actions.length > 0 && (
          <Box display="flex" justifyContent="flex-end" marginBottom="4px">
            {actions.length > 1 ? (
              <ActionMenuButton
                actions={actions}
                actionsConfig={actionsConfig}
              />
            ) : (
              <ActionButton
                icon={actionsConfig[actions[0]].icon}
                text={actionsConfig[actions[0]].label}
                onClick={actionsConfig[actions[0]].onClick}
                sx={{ textTransform: "capitalize" }}
                color={actionsConfig[actions[0]].color}
              />
            )}
          </Box>
        )}
        <Grid container spacing={1}>
          <Grid item xs={4}>
            <ChipComponent
              label={t("data_grid.headers.start_date")}
              value={
                isEditable ? (
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DateTimePicker
                      value={values.startDate}
                      onChange={(newValue) => {
                        setFieldValue("startDate", newValue);
                      }}
                      format="YYYY/MM/DD hh:mm A"
                      sx={{
                        "& .MuiInputBase-input": {
                          height: "10px",
                          fontSize: "0.85rem",
                          fontWeight: 600,
                        },
                        "& .MuiSvgIcon-root": {
                          width: "0.8em",
                          height: "0.8em",
                        },
                      }}
                      slotProps={{
                        textField: {
                          size: "small",
                        },
                      }}
                    />
                  </LocalizationProvider>
                ) : (
                  missionInfo?.result[0]?.MissionStart
                )
              }
            />
          </Grid>
          <Grid item xs={4}>
            <ChipComponent
              label={t("data_grid.headers.end_date")}
              value={missionInfo?.result[0]?.MissionEnd}
            />
          </Grid>
          <Grid item xs={4}>
            <ChipComponent
              label={t("data_grid.headers.document_no")}
              value={missionInfo?.result[0].MissionsPaperNo}
            />
          </Grid>
          <Grid item xs={12}>
            <ChipComponent
              label={t("data_grid.headers.description")}
              value={
                isEditable ? (
                  <TextField
                    value={values.descriptionMission}
                    onChange={(e) =>
                      setFieldValue("descriptionMission", e.target.value)
                    }
                    size="small"
                    multiline
                    rows={3}
                    onBlur={handleBlur}
                    error={
                      !!touched.descriptionMission &&
                      !!errors.descriptionMission
                    }
                    helperText={
                      touched.descriptionMission && errors.descriptionMission
                    }
                    sx={{
                      "& .MuiInputBase-input": {
                        height: "10px",
                        fontSize: "0.85rem",
                        fontWeight: 600,
                        width: "60.5rem",
                      },
                    }}
                  />
                ) : (
                  missionInfo?.result[0].MissionDescription
                )
              }
            />
          </Grid>
        </Grid>
        {isEditable && (
          <ButtonGroup
            onClickSave={() => {
              setIsCancel(false);
              if (dirty && Object.keys(errors).length === 0) {
                setConfirmModal(true);
              }
            }}
            onClickClose={() => {
              if (dirty) {
                setIsCancel(true);
                setConfirmModal(true);
              } else {
                setIsEditable(false);
              }
            }}
            saveLabel={t("modules.buttons.save")}
            disabled={!dayjs(values.startDate).isValid()}
          />
        )}
      </form>

      <ConfirmationInfoModal
        open={confirmModal}
        handleClose={() => {
          setConfirmModal(false);
        }}
        onClick={() =>
          isCancel
            ? (setIsEditable(false), setConfirmModal(false), resetForm())
            : handleSave()
        }
        description={
          isCancel
            ? t("info_modules.missions.description.cancel_without_saving")
            : t("info_modules.missions.description.edit_mission_confirmation")
        }
        isPending={isCancel ? null : editMission}
        color="error"
      />
    </Box>
  );
};
export default MissionCard;
