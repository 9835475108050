import { useQuery } from "@tanstack/react-query";
import { DashboardService } from "../../api/dashboard";
import FormSpinner from "../Spinners/FormSpinner";
import { Box } from "@mui/material";
import FormsDataGrid from "../DataGrid/FormsDataGrid";

const GenericModal = ({
  dataName,
  isSwitchChecked,
  modalConfig,
  chartName,
}) => {
  const { data, isLoading, isRefetching } = useQuery({
    queryKey: [modalConfig.queryKey, dataName],
    queryFn: () =>
      DashboardService.getDashboardChartDataByState(
        dataName,
        isSwitchChecked ? modalConfig.perProjectState : modalConfig.perUserState
      ),
  });

  if (isLoading || isRefetching) {
    return <FormSpinner />;
  }

  return (
    <GenericModalContent
      data={data}
      columns={modalConfig.columns}
      dataGridId={modalConfig.dataGridId}
      chartName={chartName}
      isSwitchChecked={isSwitchChecked}
    />
  );
};

const GenericModalContent = ({ data, columns, dataGridId, content }) => {
  return (
    <Box p={2}>
      {(
        <FormsDataGrid
          rows={data[Object.keys(data)[0]]}
          columns={columns}
          getRowId={(row) => row[dataGridId]}
          disableColumnMenu
          height={400}
          toolbar
          showExportButton
          showQuickFilter
          getDetailPanelHeight={() => "auto"}
        />
      ) || content}
    </Box>
  );
};

export default GenericModal;
