import { useState } from "react";
import { useTranslation } from "react-i18next";
import { Stepper, Step, StepLabel, Box, Tooltip } from "@mui/material";
import InfoModal from "../../../../components/Modals/InfoModal";
import StepperButton from "../../../../components/forms/ButtonGroup/StepperButton";
import SelectItems from "../../../../components/forms/Acceptance/Steps/SelectItems";
import ModifyItem from "../../../../components/forms/Acceptance/Steps/ModifyItems";
import ConfirmationInfoModal from "../../../../components/Modals/ConfirmationInfoModal";
import { useQuery, useMutation, useQueryClient } from "@tanstack/react-query";
import { useSnackbar } from "notistack";
import Axios from "../../../../network/Axios";
import { useParams } from "react-router-dom";
import FormSpinner from "../../../../components/Spinners/FormSpinner";

const AddItemsForm = ({ handleClose }) => {
  const {
    data: priceListData,
    isLoading,
    isRefetching,
  } = useQuery({
    queryKey: ["quotations", "priceList"],
    queryFn: async () => {
      const response = await Axios.get("/Quotations", {
        params: {
          State: "PriceLists",
        },
      });
      return response.data.result;
    },
  });

  if (isLoading || isRefetching) return <FormSpinner />;

  return (
    <AddItemsFormdata handleClose={handleClose} priceListData={priceListData} />
  );
};

const AddItemsFormdata = ({ handleClose, priceListData }) => {
  const [t] = useTranslation("global");
  const { quotationId } = useParams();
  const queryClient = useQueryClient();
  const { enqueueSnackbar } = useSnackbar();
  const [activeStep, setActiveStep] = useState(0);
  const [selectedItems, setSelectedItems] = useState([]);
  const [selectedNo, setSelectedNo] = useState([]);
  const [newPriceList, setNewPriceList] = useState([]);
  const [totalAmount, setTotalAmount] = useState(0);
  const [totalInstall, setTotalInstall] = useState(0);
  const [totalSupply, setTotalSupply] = useState(0);
  const [confirmModal, setConfirmModal] = useState(false);
  const [rows, setRows] = useState([]);
  const [selectionModel, setSelectionModel] = useState([]);

  const handleSelectionModel = (ids) => {
    setSelectionModel((prev) => {
      if (ids.length && ids.length < prev.length) {
        const removedId = prev.filter((id) => !ids.includes(id));
        const newSelectedItems = selectedItems.filter(
          (item) => item.idPriceList !== removedId[0]
        );
        setSelectedItems(newSelectedItems);
      }

      return ids;
    });

    const newSelectedRows = rows.filter((row) => ids.includes(row.idPriceList));

    setSelectedItems((prev) => {
      const data = [...prev, ...newSelectedRows];

      const uniqueArray = [
        ...new Map(data.map((item) => [item.idPriceList, item])).values(),
      ];

      return uniqueArray;
    });
  };

  const handleNext = async () => {
    if (activeStep === 0) {
      setNewPriceList(selectedItems);
    }
    if (activeStep === 1) {
      if (
        newPriceList.find(
          (row) => row.Quantity <= 0 || row.Quantity == "" || !row.Quantity
        )
      ) {
        enqueueSnackbar("Quantity should be greater than 0", {
          variant: "error",
        });
        return;
      }
      setConfirmModal(true);
      return;
    }
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const steps = [
    t("modules.material_request.steps.select_item"),
    t("modules.material_request.steps.modify_item"),
  ];

  const handleDeleteRow = (id) => {
    setNewPriceList((prevRows) =>
      prevRows.filter((row) => row.idPriceList !== id)
    );

    const newIds = selectionModel.filter((selectedId) => selectedId !== id);

    handleSelectionModel(newIds);
  };

  const items = newPriceList.map((item) => ({
    idPriceList: item.idPriceList,
    ItemQTY: Number(item.Quantity),
    SI: item.SI,
  }));

  const { mutateAsync: addItems, isPending } = useMutation({
    mutationFn: async () => {
      return Axios.put("/Quotations", {
        Items: items,
        State: "AddWorkOrderD6Items",
        SelectedNo: quotationId,
      });
    },
    onSuccess: (data) => {
      handleClose();
      enqueueSnackbar(data.data.msg, {
        variant: "success",
      });
      queryClient.invalidateQueries({
        queryKey: ["quotation", "info"],
      });
    },
  });

  const columns = [
    {
      field: "PriceListSN",
      headerName: t("data_grid.headers.code"),
    },
    {
      field: "Description",
      headerName: t("data_grid.headers.description"),
      width: 210,
      flex: 1,
      renderCell: ({ value }) => {
        return (
          <Tooltip title={value} arrow>
            <div
              style={{
                overflow: "hidden",
                textOverflow: "ellipsis",
                whiteSpace: "nowrap",
              }}
            >
              {value}
            </div>
          </Tooltip>
        );
      },
    },
    {
      field: "Unit",
      headerName: t("data_grid.headers.unit"),
    },
    {
      field: "SI",
      headerName: t("data_grid.headers.s_i"),
    },
    {
      field: "Price",
      headerName: t("data_grid.headers.unit_price"),
    },
  ];

  const views = {
    0: (
      <SelectItems
        comboBoxData={priceListData}
        rows={rows}
        setSelectedNo={setSelectedNo}
        selectedNo={selectedNo}
        pricesListColumns={columns}
        handleSelectionModel={handleSelectionModel}
        selectionModel={selectionModel}
        setRows={setRows}
      />
    ),
    1: (
      <ModifyItem
        setNewRows={setNewPriceList}
        rows={newPriceList}
        onClick={handleDeleteRow}
        totalAmount={totalAmount}
        totalInstall={totalInstall}
        totalSupply={totalSupply}
        setTotalAmount={setTotalAmount}
        setTotalInstall={setTotalInstall}
        setTotalSupply={setTotalSupply}
      />
    ),
  };

  const isNextBtnDisabled = selectionModel.length === 0;

  return (
    <Box margin="10px">
      <Stepper activeStep={activeStep}>
        {steps.map((label) => {
          return (
            <Step key={label}>
              <StepLabel>{label}</StepLabel>
            </Step>
          );
        })}
      </Stepper>

      {views[activeStep]}
      <StepperButton
        onClick={handleNext}
        handleBack={handleBack}
        activeStep={activeStep}
        disabled={isNextBtnDisabled}
        label={
          activeStep === 1
            ? t("modules.buttons.save")
            : t("modules.buttons.next")
        }
        isLastStep={activeStep === 1}
      />
      <ConfirmationInfoModal
        open={confirmModal}
        handleClose={() => {
          setConfirmModal(false);
        }}
        onClick={() => {
          addItems();
        }}
        description={t("info_modules.material_request.confirmation.add_item")}
        isPending={isPending}
        color="error"
      />
    </Box>
  );
};

const AddItems = ({ open, handleClose }) => {
  const [t] = useTranslation("global");

  return (
    <InfoModal
      open={open}
      handleClose={handleClose}
      width="60rem"
      title={t("info_modules.quotation.description.add_items")}
      content={<AddItemsForm handleClose={handleClose} />}
    />
  );
};

export default AddItems;
