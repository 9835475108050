// Sort data groups data to be used in the tree view component
export const sortDataGroups = (data) => {
  const sortedUserRoles = data.reduce((acc, item) => {
    const {
      Checked,
      CompanyAccountName,
      CompanyProjectsName,
      SubProjectsName,
      idSubProjects,
    } = item;

    // Ensure the CompanyAccountName object exists
    if (!acc[CompanyAccountName]) {
      acc[CompanyAccountName] = {};
    }

    // Ensure the CompanyProjectsName object exists
    if (!acc[CompanyAccountName][CompanyProjectsName]) {
      acc[CompanyAccountName][CompanyProjectsName] = [];
    }

    // Add the privilege to the CompanyProjectsName array
    acc[CompanyAccountName][CompanyProjectsName].push({
      SubProjectsName,
      Checked,
      idSubProjects,
    });

    return acc;
  }, {});

  return sortedUserRoles;
};

// Get the final array to be sent to the API
export const getFinalArray = (selectedItems, data) => {
  // Format the selected items array
  const formattedSelectedItems = selectedItems.map((item) => {
    const [SubProjectsName, idSubProjects] = item.split("-");
    return {
      SubProjectsName,
      idSubProjects,
    };
  });

  // Map the data result array and check if the item is selected
  const outputArray = data.map((item) => {
    const isSelected = formattedSelectedItems.some(
      (selected) => selected.idSubProjects == item.idSubProjects
    );
    return {
      SubProjectsName: item.SubProjectsName,
      checked: isSelected,
      idSubProjects: item.idSubProjects,
    };
  });

  return outputArray;
};
