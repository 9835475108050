import { Stack, Typography } from "@mui/material";
import AntSwitch from "../../../../components/Switches/AntSwitch";

export const Toggle = ({ label, ...rest }) => {
  return (
    <Stack direction="row" spacing={1} alignItems="center">
      <AntSwitch switchSize={1.25} {...rest} />
      {label && <Typography>{label}.</Typography>}
    </Stack>
  );
};
