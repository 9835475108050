import { LinearProgress } from "@mui/material";

const PasswordStrengthBar = ({ strength }) => {
  let color;
  let value;

  switch (strength) {
    case "too weak":
      color = "error";
      value = 25;
      break;
    case "weak":
      color = "warning";
      value = 50;
      break;
    case "medium":
      color = "info";
      value = 75;
      break;
    case "strong":
      color = "success";
      value = 100;
      break;
    default:
      color = "error";
      value = 0;
  }

  return <LinearProgress variant="determinate" value={value} color={color} />;
};

export default PasswordStrengthBar;
