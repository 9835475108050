import Box from "@mui/material/Box";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import { useReducer, useState } from "react";
import { useTranslation } from "react-i18next";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useSnackbar } from "notistack";
import SelectItems from "./Steps/SelectItems";
import ModifyItems from "./Steps/ModifyItems";
import Save from "./Steps/Save";
import { PurchaseRequestsService } from "../../../api/purchaseRequests";
import StepperButton from "../ButtonGroup/StepperButton";
import useHandleForms from "../../../hooks/useHandleForms";

const PurchaseRequestsForm = ({ onDirtyChange }) => {
  const [t] = useTranslation("global");

  const queryClient = useQueryClient();

  const { enqueueSnackbar } = useSnackbar();

  const [activeStep, setActiveStep] = useState(0);

  const handleNext = async () => {
    const { rows, materials_add_request } = state;

    if (activeStep === 1) {
      if (rows.find((row) => row.QTY <= 0 || row.QTY == "" || !row.QTY)) {
        enqueueSnackbar(t("modules.error_messages.zero_or_negative_quantity"), {
          variant: "error",
        });
        return;
      }
    }

    if (activeStep === 2) {
      return AddNewPurchaseRequest({
        State: "New",
        Flag: materials_add_request,
        Items: rows.map((row) => {
          return {
            StoreItemsTemplateCode: row.StoreItemsTemplateCode,
            QTY: Number(row.QTY),
          };
        }),
      });
    }

    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const steps = [
    t("modules.purchase_requests.new_form.steps.select_items"),
    t("modules.purchase_requests.new_form.steps.modify_items"),
    t("modules.purchase_requests.new_form.steps.save"),
  ];

  const initialValues = {
    rows: [],
    materials_add_request: false,
  };

  function reducer(state, action) {
    switch (action.type) {
      case "rows":
      case "materials_add_request":
      case "error":
        return {
          ...state,
          [action.type]: action.payload,
        };
      default:
        throw new Error("Unknown action type: " + action.type);
    }
  }

  const [state, dispatch] = useReducer(reducer, initialValues);

  const { handleClose } = useHandleForms({
    dirty: state.rows.length > 0 || state.materials_add_request == true,
    onDirtyChange,
  });

  const {
    mutateAsync: AddNewPurchaseRequest,
    isPending: isAddingNewPurchaseRequest,
  } = useMutation({
    mutationFn: PurchaseRequestsService.AddNewPurchaseRequest,
    onSuccess: (data) => {
      handleClose();
      enqueueSnackbar(data.msg, { variant: "success" });
      queryClient.invalidateQueries({ queryKey: ["purchaseRequests"] });
    },
    onError: (error) => {
      enqueueSnackbar(error.message, { variant: "error" });
    },
  });

  const views = {
    0: <SelectItems dispatch={dispatch} selectedRows={state.rows} />,
    1: <ModifyItems rows={state.rows} dispatch={dispatch} />,
    2: <Save selectedRows={state.rows} state={state} dispatch={dispatch} />,
  };

  return (
    <Box
      sx={{ width: "100%", display: "flex", flexDirection: "column", gap: 1 }}
    >
      <Stepper activeStep={activeStep}>
        {steps.map((label) => {
          return (
            <Step
              key={label}
              sx={{
                my: 1,
              }}
            >
              <StepLabel>{label}</StepLabel>
            </Step>
          );
        })}
      </Stepper>
      {views[activeStep]}
      <StepperButton
        isPending={isAddingNewPurchaseRequest}
        onClick={handleNext}
        handleBack={handleBack}
        activeStep={activeStep}
        disabled={state.rows.length === 0 || isAddingNewPurchaseRequest}
        label={
          activeStep === steps.length - 1
            ? t("modules.buttons.save")
            : t("modules.buttons.next")
        }
        isLastStep={activeStep === steps.length - 1}
      />
    </Box>
  );
};

export default PurchaseRequestsForm;
