import { Switch, styled } from "@mui/material";

const AntSwitch = styled(Switch, {
  shouldForwardProp: (prop) => prop !== "switchSize",
})(({ switchSize = 1.5, theme }) => ({
  width: 28 * switchSize,
  height: 16 * switchSize,
  padding: 0,
  display: "flex",
  "&:active": {
    "& .MuiSwitch-thumb": {
      width: 15 * switchSize,
    },
    "& .MuiSwitch-switchBase.Mui-checked": {
      transform: "translateX(9px)",
    },
  },
  "& .MuiSwitch-switchBase": {
    padding: 2 * switchSize,
    "&.Mui-checked": {
      transform: `translateX(${12 * switchSize}px)`,
      color: "#fff",
      "& + .MuiSwitch-track": {
        opacity: 1,
        backgroundColor: "primary.main",
      },
    },
  },
  "& .MuiSwitch-thumb": {
    boxShadow: "0 2px 4px 0 rgb(0 35 11 / 20%)",
    width: 12 * switchSize,
    height: 12 * switchSize,
    borderRadius: 6 * switchSize,
    transition: theme.transitions.create(["width"], {
      duration: 200,
    }),
  },
  "& .MuiSwitch-track": {
    borderRadius: (16 / 2) * switchSize,
    opacity: 1,
    backgroundColor:
      theme.palette.mode === "dark"
        ? "rgba(255,255,255,.35)"
        : "rgba(0,0,0,.25)",
    boxSizing: "border-box",
  },
}));

export default AntSwitch;
