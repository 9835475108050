import { Box, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";

export default function CustomNoRowsOverlay() {
  const [t] = useTranslation("global");

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        height: "100%",
      }}
    >
      <Typography>{t("data_grid.no_rows")}</Typography>
    </Box>
  );
}
