import { Autocomplete, Box, Stack, TextField } from "@mui/material";
import InfoModal from "../../../../components/Modals/InfoModal";
import { useFormik } from "formik";
import * as yup from "yup";
import { useTranslation } from "react-i18next";
import ButtonGroup from "../../../../components/forms/ButtonGroup/ButtonGroup";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { priceListsService } from "../../../../api/priceLists";
import { useSnackbar } from "notistack";

const AddItem = ({ open, handleClose, PriceListRefNo: priceListId }) => {
  const { enqueueSnackbar } = useSnackbar();
  const queryClient = useQueryClient();
  const [t] = useTranslation("global");

  const requiredMessage = t("modules.global_schema.required");
  const selectValueMessage = t("modules.global_schema.select_value");

  const schema = yup.object().shape({
    code: yup.string().required(requiredMessage),
    price: yup.string().required(requiredMessage),
    unit: yup.string().required(requiredMessage),
    type: yup.string().required(selectValueMessage),
    description: yup.string().required(requiredMessage),
  });

  const handleFormSubmit = (values) => {
    const { code, price, unit, type, description } = values;

    addItem({
      Description: description,
      Price: price,
      PriceListSN: code,
      SI: type,
      SelectedNo: priceListId,
      State: "AddItems",
      Unit: unit,
    });
  };

  const { mutateAsync: addItem, isPending } = useMutation({
    mutationFn: priceListsService.patchPriceList,
    onSuccess: (data) => {
      resetForm();
      handleClose();
      enqueueSnackbar(data.msg, { variant: "success" });
      queryClient.invalidateQueries({ queryKey: ["priceList"] });
    },
  });

  const {
    values,
    errors,
    setFieldValue,
    touched,
    handleSubmit,
    handleBlur,
    handleChange,
    resetForm,
  } = useFormik({
    initialValues: {
      code: "",
      price: "",
      unit: "",
      type: "",
      description: "",
    },
    validationSchema: schema,
    onSubmit: handleFormSubmit,
  });

  const types = [
    { value: "I", label: "I" },
    { value: "S", label: "S" },
  ];

  return (
    <InfoModal
      open={open}
      handleClose={() => {
        handleClose();
        resetForm();
      }}
      title={t("info_modules.price_list.forms.add_item.title")}
      content={
        <Box
          component={"form"}
          onSubmit={handleSubmit}
          noValidate
          sx={{
            padding: 2,
            display: "flex",
            flexDirection: "column",
            gap: 2,
          }}
        >
          <Stack spacing={2}>
            <TextField
              size="small"
              required
              fullWidth
              label={t("info_modules.price_list.forms.add_item.fields.code")}
              name="code"
              value={values.code}
              onChange={handleChange}
              onBlur={handleBlur}
              error={touched.code && Boolean(errors.code)}
              helperText={touched.code && errors.code}
            />
            <TextField
              size="small"
              required
              fullWidth
              label={t("info_modules.price_list.forms.add_item.fields.price")}
              name="price"
              value={values.price}
              onChange={handleChange}
              onBlur={handleBlur}
              error={touched.price && Boolean(errors.price)}
              helperText={touched.price && errors.price}
            />
            <Stack direction="row" spacing={2}>
              <TextField
                size="small"
                required
                fullWidth
                label={t("info_modules.price_list.forms.add_item.fields.unit")}
                name="unit"
                value={values.unit}
                onChange={handleChange}
                onBlur={handleBlur}
                error={touched.unit && Boolean(errors.unit)}
                helperText={touched.unit && errors.unit}
                sx={{
                  flex: 1,
                }}
              />
              <Autocomplete
                disableClearable
                size="small"
                options={types}
                onChange={(event, item) => {
                  setFieldValue("type", item?.value);
                }}
                value={types.find((item) => item.value === values.type) || null}
                sx={{
                  flex: 1,
                }}
                renderInput={(params) => (
                  <TextField
                    required
                    {...params}
                    label={t(
                      "info_modules.price_list.forms.add_item.fields.type"
                    )}
                    error={!!errors.type}
                    helperText={errors.type?.message}
                  />
                )}
              />
            </Stack>
            <TextField
              size="small"
              required
              fullWidth
              label={t(
                "info_modules.price_list.forms.add_item.fields.description"
              )}
              name="description"
              value={values.description}
              onChange={handleChange}
              onBlur={handleBlur}
              error={touched.description && Boolean(errors.description)}
              helperText={touched.description && errors.description}
              multiline
              rows={4}
            />
          </Stack>
          <ButtonGroup
            onClickClose={() => {
              handleClose();
              resetForm();
            }}
            isPending={isPending}
            saveLabel={t("modules.buttons.save")}
          />
        </Box>
      }
    />
  );
};

export default AddItem;
