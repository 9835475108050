import { useState } from "react";
import { Box } from "@mui/material";
import { useTranslation } from "react-i18next";
import InfoModal from "../../../../components/Modals/InfoModal";
import { useConfirmationDialog } from "../../../../contexts/ConfirmationDialogContext";

const NewEntity = ({
  open,
  handleClose,
  workOrderId,
  handleCloseFromAnotherForm,
  FormComponent,
  titleKey,
  d6Type,
}) => {
  const [t] = useTranslation("global");
  const [dirty, setDirty] = useState(false);

  const onDirtyChange = (isDirty) => {
    setDirty(isDirty);
  };

  const { setOpen } = useConfirmationDialog();

  const onClickClose = () => {
    if (dirty) {
      setOpen(t("modules.confirmation_message"), () => handleClose);
    } else {
      handleClose();
    }
  };

  return (
    <InfoModal
      open={open}
      handleClose={handleCloseFromAnotherForm}
      title={`${t(titleKey)} - ${workOrderId}`}
      width="60rem"
      content={
        <Box padding={1}>
          <FormComponent
            onClickClose={onClickClose}
            onDirtyChange={onDirtyChange}
            handleClose={handleClose}
            isThreeStepProcess={true}
            workOrderId={workOrderId}
            handleCloseFromAnotherForm={handleCloseFromAnotherForm}
            d6Type={d6Type}
          />
        </Box>
      }
    />
  );
};

export default NewEntity;
