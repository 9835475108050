import { useEffect } from "react";
import FormsDataGrid from "../../../DataGrid/FormsDataGrid";
import { Box } from "@mui/material";
import { useTranslation } from "react-i18next";

const SelectTeam = ({
  rows,
  onRowSelection,
  selectedRows,
  setSelectedRows,
  loading,
}) => {
  useEffect(() => {
    onRowSelection(selectedRows);
  }, [selectedRows, onRowSelection]);

  const [t] = useTranslation("global");

  const columns = [
    {
      field: "EmpCode",
      headerName: t("data_grid.headers.emp_code"),
    },
    {
      field: "EmpName",
      headerName: t("data_grid.headers.emp_name"),
      width: 200,
      flex: 1,
    },
    {
      field: "EmpNameEn",
      headerName: t("data_grid.headers.employee_name_en"),
      width: 90,
      flex: 1,
    },
    {
      field: "EmpTitle",
      headerName: t("data_grid.headers.emp_title"),
    },
  ];

  return (
    <Box sx={{ margin: "10px 1px" }}>
      <FormsDataGrid
        loading={loading}
        rows={rows}
        columns={columns}
        getRowId={(row) => row.idEmpInfo}
        height={320}
        showQuickFilter
        checkboxSelection
        toolbar
        disableSelectionOnClick
        onRowSelectionModelChange={(newSelectedRows) => {
          setSelectedRows(newSelectedRows);
        }}
        rowSelectionModel={selectedRows}
      />
    </Box>
  );
};
export default SelectTeam;
