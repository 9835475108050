import { useTranslation } from "react-i18next";
import CardContainer from "../../../CardContainer";
import { Link as RouterLink } from "react-router-dom";
import PieChartContainer from "../../../../../components/charts/PieChart";
import { useSelector } from "react-redux";

const Insurance = ({
  socialInsured,
  socialNotInsured,
  medicalInsured,
  medicalNotInsured,
}) => {
  const { t } = useTranslation("global");
  const socialInsuranceData = [
    {
      name: "Social Insured",
      data: socialInsured[0]?.Count,
    },
    {
      name: "Social Not Insured",
      data: socialNotInsured[0]?.Count,
    },
  ];

  const medicalInsuranceData = [
    {
      name: "Medical Insured",
      data: medicalInsured[0]?.Count,
    },
    {
      name: "Medical Not Insured",
      data: medicalNotInsured[0]?.Count,
    },
  ];

  let columns = [
    {
      field: "EmpCode",
      headerName: t("data_grid.headers.emp_code"),
      renderCell: (params) => {
        return (
          <RouterLink
            style={{
              color: "#1790FF",
            }}
            to={`/employeerecords/${params.value.replace("/", "%2F")}`}
            target="_blank"
          >
            {params.value}
          </RouterLink>
        );
      },
    },
    {
      field: "EmpName",
      headerName: t("data_grid.headers.arabic_name"),
      flex: 1,
    },
    {
      field: "EmpNameEn",
      headerName: t("data_grid.headers.english_name"),
      flex: 1,
    },
    {
      field: "EmpTitle",
      headerName: t("data_grid.headers.title"),
      width: 200,
    },
    {
      field: "EmpDateOfHire",
      headerName: t("data_grid.headers.hire_date"),
    },
  ];

  const { language } = useSelector((state) => state.theme); // EN | AR

  columns = columns.filter((column) =>
    language === "EN"
      ? column.field !== "EmpName"
      : column.field !== "EmpNameEn"
  );

  const colors = ["#388e3c", "#f00"];

  const doesSocialInsuranceDataExist =
    socialInsured.length > 0 || socialNotInsured.length > 0;
  const doesMedicalInsuranceDataExist =
    medicalInsured.length > 0 || medicalNotInsured.length > 0;

  if (doesSocialInsuranceDataExist && !doesMedicalInsuranceDataExist)
    return (
      <CardContainer>
        <PieChartContainer
          chartName="social_insurance"
          chartData={socialInsuranceData}
          title={t("main_cards.social_insurance")}
          modalConfig={{
            queryKey: "notSociallyInsured",
            perUserState: "NotInsured",
            perProjectState: "NotInsured",
            columns,
            dataGridId: "EmpCode",
          }}
          colors={colors}
        />
      </CardContainer>
    );

  if (doesMedicalInsuranceDataExist && !doesSocialInsuranceDataExist)
    return (
      <CardContainer>
        <PieChartContainer
          chartName="medical_insurance"
          chartData={medicalInsuranceData}
          title={t("main_cards.medical_insurance")}
          modalConfig={{
            queryKey: "notMedicallyInsured",
            perUserState: "NotCovered",
            perProjectState: "NotCovered",
            columns,
            dataGridId: "EmpCode",
          }}
          colors={colors}
        />
      </CardContainer>
    );

  if (doesSocialInsuranceDataExist && doesMedicalInsuranceDataExist)
    return (
      <CardContainer>
        <PieChartContainer
          chartName="social_insurance"
          chartData={socialInsuranceData}
          title={t("main_cards.insurance")}
          modalConfig={{
            queryKey: "notSociallyInsured",
            perUserState: "NotInsured",
            perProjectState: "NotInsured",
            columns,
            dataGridId: "EmpCode",
          }}
          colors={colors}
          doubleChart={{
            chartName: "medical_insurance",
            chartData: medicalInsuranceData,
            title: t("main_cards.medical_insurance"),
            modalConfig: {
              queryKey: "notMedicallyInsured",
              perUserState: "NotCovered",
              perProjectState: "NotCovered",
              columns,
              dataGridId: "EmpCode",
            },
            colors,
          }}
        />
      </CardContainer>
    );
};

export default Insurance;
