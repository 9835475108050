import ConfirmationModal from "./Modals/ConfirmationModal";

const ConfirmationDialog = ({
  open,
  closeDialog,
  onConfirm,
  message,
  color,
  cancelColor,
  cancelLabel,
}) => {
  const handleConfirm = () => {
    onConfirm();
    closeDialog();
  };

  const handleCancel = () => {
    closeDialog();
  };

  return (
    <ConfirmationModal
      open={open}
      handleClose={handleCancel}
      confirmFunction={handleConfirm}
      confirmModalMessage={message}
      color={color}
      cancelColor={cancelColor}
      cancelLabel={cancelLabel}
    />
  );
};

export default ConfirmationDialog;
