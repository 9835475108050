import { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Stepper, Step, StepLabel, Box, Typography } from "@mui/material";
import InfoModal from "../../../../../components/Modals/InfoModal";
import StepperButton from "../../../../../components/forms/ButtonGroup/StepperButton";
import FormSpinner from "../../../../../components/Spinners/FormSpinner";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import SelectItems from "./Steps/SelectItems";
import ModifyItems from "./Steps/ModifyItems";
import Review from "./Steps/Review";
import { useSnackbar } from "notistack";
import Axios from "../../../../../network/Axios";
import { useParams } from "react-router-dom";
import { useFormik } from "formik";
import dayjs from "dayjs";
import * as Yup from "yup";
import { generateIdNum } from "../../../../../utils/globalFunctions";

const Issued = ({ open, handleClose, loading, comboBoxData, action }) => {
  const [t] = useTranslation("global");
  const { materialRequestId } = useParams();
  const queryClient = useQueryClient();
  const { enqueueSnackbar } = useSnackbar();
  const [activeStep, setActiveStep] = useState(0);
  const [selectedNo, setSelectedNo] = useState([]);
  const [disableNextRow, setDisableNextRow] = useState(false);
  const [rows, setRows] = useState([]);
  const [selectedEmp, setSelectedEmp] = useState([]);
  const [selectedCar, setSelectedCar] = useState([]);
  const [selectedItemsEmp, setSelectedItemsEmp] = useState([]);
  const [selectedItemsCar, setSelectedItemsCar] = useState([]);
  const [message, setMessage] = useState(null);
  const [replaced, setReplaced] = useState(false);

  const validationSchema = Yup.object().shape({
    employeeName: Yup.string().required("Employee Name is required"),
    carName: Yup.string().required("Car Name is required"),
  });

  const initialValues = {
    paperNo: "",
    date: dayjs(),
    employeeName: "",
    carName: "",
    destination: "",
  };
  const { mutateAsync: getItems, isPending: loadingItems } = useMutation({
    mutationFn: async () => {
      return Axios.get("/MaterialRequests", {
        params: {
          SelectedNo: materialRequestId,
          State: "IssueReturnMaterials",
          ToStore: selectedNo,
        },
      });
    },
    onSuccess: (data) => {
      if (!data) return;
      setRows(data.data.MRItemsInStore);
    },
  });

  const rowsFiltered = rows.map((item) => ({
    IssuedQty: item.IssuedQty,
    MaterialsRequestQty: item.MaterialsRequestQty,
    StoreItemsTemplateCode: item.StoreItemsTemplateCode,
    StoreItemsTemplateName: item.StoreItemsTemplateName,
    StoreItemsTemplateQty: item.MaterialsRequestQty,
    StoreItemsTemplateType: item.StoreItemsTemplateType,
    StoreItemsTemplateUnit: item.StoreItemsTemplateUnit,
    QTY: item.QTY,
    State: item.MaterialsRequestQty === null ? "NotAvailable" : "Available",
    Replaced: item.Replaced,
    id: generateIdNum()(),
    idCompanyStoreTempleteItems: item.idCompanyStoreTempleteItems,
    idMaterialsRequestTemplates: item.idMaterialsRequestTemplates,
  }));
  const newArray = [...rowsFiltered];

  const { mutateAsync: saveTransition, isPending: pendingTransition } =
    useMutation({
      mutationFn: async () => {
        const mutationData = {
          Items: newArray,
          MaterialsRequestNo: materialRequestId,
          StoreTransitionDate: values.date.format("YYYY-MM-DD hh:mm:00"),
          StoreTransitionInfo: values.destination,
          StoreTransitionInvoiceNo: values.paperNo,
          ToStore: selectedNo,
          idCarsInfoCarsNumbers: selectedCar[0].idCarsInfoCarsNumbers,
          idEmpInfo: selectedEmp[0].idEmpInfo,
          State: action === "return" ? "Return" : "Issue",
        };
        return Axios.patch("/InventoryManagement", mutationData);
      },
      onSuccess: (data) => {
        if (!data) return;
        enqueueSnackbar(
          action === "return" ? "Return Successfully" : "Issued Successfully",
          {
            variant: "success",
          }
        );
        handleCloseSave();
        queryClient.invalidateQueries({
          queryKey: ["materialRequestInfo"],
        });
      },
    });

  const handleNext = async () => {
    // Ensure any quantity that hasn't been entered is set to 0
    const updatedRows = rows.map((row) => {
      if (!row.QTY) {
        return { ...row, QTY: 0 };
      }
      return row;
    });
    setRows(updatedRows);

    if (activeStep === 1) {
      const data = await validateForm();
      if (Object.keys(data).length > 0) return handleSubmit();
    }
    if (activeStep === 2) {
      await saveTransition();
      handleCloseSave();
      return;
    }
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const {
    handleSubmit,
    values,
    setFieldValue,
    resetForm,
    validateForm,
    errors,
    touched,
    handleBlur,
  } = useFormik({
    initialValues,
    validationSchema,
    onSubmit: handleNext,
  });

  const handleCloseSave = () => {
    handleClose();
    resetForm();
    setSelectedNo([]);
    setRows([]);
    setActiveStep(0);
    setMessage(null);
  };

  const steps = [
    t("modules.material_request.steps.select_item"),
    t("modules.material_request.steps.modify_item"),
    t("modules.material_request.steps.review"),
  ];

  useEffect(() => {
    const totalQuantity = rows.some((row) => row.QTY > 0);
    setDisableNextRow(totalQuantity);
  }, [rows]);

  const handleQuantityIssue = (params, event) => {
    if (!event || !event.target || typeof event.target.value === "undefined") {
      return; // If event or event.target or event.target.value is undefined, return early
    }
    const newValue = event.target.value;
    const updateRowsAndSetMessage = (message) => {
      setMessage(message);
      const newRows = rows.map((row) => {
        if (row.idMaterialsRequestTemplates === params.id) {
          return { ...row, QTY: 0, error: true };
        }
        return row;
      });
      setRows(newRows);
    };
    if (params.row.CompanyStoreTempleteItemsBalance === null) {
      updateRowsAndSetMessage("Ths Item is not available in the store");
      return;
    }
    if (newValue > params.row.CompanyStoreTempleteItemsBalance) {
      updateRowsAndSetMessage("Quantity can't be more than balance");
      return;
    }
    if (
      newValue > params.row.MaterialsRequestQty ||
      params.row.IssuedQty === params.row.MaterialsRequestQty ||
      newValue > params.row.MaterialsRequestQty - params.row.IssuedQty
    ) {
      updateRowsAndSetMessage("Quantity can't be more than requested quantity");
      return;
    }
    const newRows = rows.map((row) => {
      if (row.idMaterialsRequestTemplates === params.id) {
        return { ...row, QTY: newValue };
      }
      return row;
    });
    setMessage(null);
    setRows(newRows);
  };

  const handleQuantityReturn = (params, event) => {
    if (!event || !event.target || typeof event.target.value === "undefined") {
      return;
    }
    const updateRowsAndSetMessage = (message) => {
      setMessage(message);
      const newRows = rows.map((row) => {
        if (row.idMaterialsRequestTemplates === params.id) {
          return { ...row, QTY: 0 };
        }
        return row;
      });
      setRows(newRows);
    };
    const newValue = event.target.value;
    if (newValue > params.row.IssuedQty) {
      updateRowsAndSetMessage("Quantity can't be more than issued quantity");
      return;
    }
    const newRows = rows.map((row) => {
      if (row.idMaterialsRequestTemplates === params.id) {
        return { ...row, QTY: newValue };
      }
      return row;
    });
    setRows(newRows);
    setMessage(null);
  };

  const views = {
    0: (
      <SelectItems
        replaced={replaced}
        setReplaced={setReplaced}
        comboBoxData={comboBoxData}
        selectedNo={selectedNo}
        setSelectedNo={setSelectedNo}
        rows={rows}
        setRows={setRows}
        getItems={getItems}
        loadingItems={loadingItems}
        handleQuantity={
          action === "return" ? handleQuantityReturn : handleQuantityIssue
        }
        setMessage={setMessage}
      />
    ),
    1: (
      <ModifyItems
        setFieldValue={setFieldValue}
        handleSubmit={handleSubmit}
        values={values}
        selectedItemsEmp={selectedItemsEmp}
        setSelectedItemsEmp={setSelectedItemsEmp}
        selectedItemsCar={selectedItemsCar}
        setSelectedItemsCar={setSelectedItemsCar}
        selectedEmp={selectedEmp}
        setSelectedEmp={setSelectedEmp}
        selectedCar={selectedCar}
        setSelectedCar={setSelectedCar}
        errors={errors}
        touched={touched}
        handleBlur={handleBlur}
      />
    ),
    2: <Review values={values} rows={rows} />,
  };

  return (
    <InfoModal
      open={open}
      handleClose={() => {
        handleCloseSave();
      }}
      width="65rem"
      title={
        action === "return"
          ? t(
              "info_modules.material_request.description.return_material_request"
            )
          : t(
              "info_modules.material_request.description.issue_material_request"
            )
      }
      content={
        <>
          {loading ? (
            <FormSpinner />
          ) : (
            <Box margin="10px">
              <Stepper activeStep={activeStep}>
                {steps.map((label) => {
                  return (
                    <Step key={label}>
                      <StepLabel>{label}</StepLabel>
                    </Step>
                  );
                })}
              </Stepper>

              {views[activeStep]}
              <Box
                display="flex"
                alignItems="center"
                justifyContent={message !== null ? "space-between" : "flex-end"}
              >
                {message && (
                  <Typography
                    color="error"
                    fontSize="16px"
                    fontWeight={500}
                    paddingLeft="10px"
                  >
                    {message}
                  </Typography>
                )}
                <StepperButton
                  isPending={pendingTransition}
                  onClick={handleNext}
                  handleBack={handleBack}
                  activeStep={activeStep}
                  disabled={!disableNextRow}
                  label={
                    activeStep === 2
                      ? t("modules.buttons.save")
                      : t("modules.buttons.next")
                  }
                  isLastStep={activeStep === 2}
                />
              </Box>
            </Box>
          )}
        </>
      }
    />
  );
};

export default Issued;
