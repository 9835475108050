import { Box, Tooltip, Typography } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import ReplayIcon from "@mui/icons-material/Replay";
import IconButton from "@mui/material/IconButton";
import GetAppOutlinedIcon from "@mui/icons-material/GetAppOutlined";
import ModeEditOutlineOutlinedIcon from "@mui/icons-material/ModeEditOutlineOutlined";
import ChangeCircleOutlinedIcon from "@mui/icons-material/ChangeCircleOutlined";
import AttachFileOutlinedIcon from "@mui/icons-material/AttachFileOutlined";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import { useTranslation } from "react-i18next";

const InfoCard = ({
  title,
  content,
  addIcon,
  onClick,
  resetButton,
  onClickReset,
  editButton,
  onClickEdit,
  deleteButton,
  onClickDelete,
  templateButton,
  onClickTemplate,
  viewButton,
  onClickView,
  exportButton,
  onClickExport,
  addIconLabel,
}) => {
  const themePalette = useTheme();
  const [t] = useTranslation("global");
  return (
    <Box
      sx={{
        backgroundColor: "background.card",
        width: "100%",
        height: "100%",
        padding: "10px 0px",
        paddingTop: "0px",
        borderRadius: 2,
        boxShadow: themePalette.palette.boxShadow.main,
      }}
    >
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        marginBottom={1.2}
        backgroundColor="background.headerCard"
        overflow="hidden"
        borderRadius="8px 8px 0 0" // Modified border radius
        padding="3px 10px"
        sx={{
          position: "relative",
        }}
      >
        <Typography
          sx={{
            fontSize: "15px",
            color: "white",
            fontWeight: 400,
          }}
        >
          {title}
        </Typography>
        <Box
          display="flex"
          sx={{
            position: "absolute",
            right: "0",
          }}
        >
          {addIcon && (
            <IconButton
              onClick={onClick}
              aria-label="Add Employee"
              sx={{
                borderRadius: 3,
              }}
            >
              <Box display="flex" gap="3px" alignItems="center">
                <AddCircleOutlineIcon
                  style={{ fontSize: "20px", color: "white" }}
                />
                <Typography
                  sx={{
                    fontWeight: 600,
                    fontSize: "14px",
                    color: "white",
                  }}
                >
                  {addIconLabel || t("buttons.add")}
                </Typography>
              </Box>
            </IconButton>
          )}
          {resetButton && (
            <IconButton
              onClick={onClickReset}
              aria-label="Add"
              sx={{
                borderRadius: 3,
              }}
            >
              <Box display="flex" gap="3px" alignItems="center">
                <ReplayIcon style={{ fontSize: "18px", color: "white" }} />
                <Typography
                  sx={{
                    fontWeight: 600,
                    fontSize: "14px",
                    color: "white",
                  }}
                >
                  {t("info_modules.missions.fields.reset")}
                </Typography>
              </Box>
            </IconButton>
          )}
          {templateButton && (
            <Tooltip title={t("tooltip.use_as_template")}>
              <IconButton
                onClick={onClickTemplate}
                aria-label="Template"
                sx={{
                  borderRadius: 3,
                }}
              >
                <ChangeCircleOutlinedIcon
                  style={{ fontSize: "20px", color: "white" }}
                />
              </IconButton>
            </Tooltip>
          )}
          {viewButton && (
            <Tooltip title={t("tooltip.view")}>
              <IconButton
                onClick={onClickView}
                aria-label="View"
                sx={{
                  borderRadius: 3,
                }}
              >
                <AttachFileOutlinedIcon
                  style={{ fontSize: "20px", color: "white" }}
                />
              </IconButton>
            </Tooltip>
          )}
          {deleteButton && (
            <Tooltip title={t("tooltip.delete")}>
              <IconButton
                onClick={onClickDelete}
                aria-label="Delete"
                sx={{
                  borderRadius: 3,
                }}
              >
                <DeleteOutlineIcon style={{ fontSize: "20px" }} color="error" />
              </IconButton>
            </Tooltip>
          )}
          {exportButton && (
            <Tooltip title={t("tooltip.export")}>
              <IconButton
                onClick={onClickExport}
                aria-label="Delete"
                sx={{
                  borderRadius: 3,
                }}
              >
                <GetAppOutlinedIcon
                  style={{ fontSize: "20px", color: "white" }}
                />
              </IconButton>
            </Tooltip>
          )}
          {editButton && (
            <Tooltip title={t("tooltip.edit")}>
              <IconButton
                onClick={onClickEdit}
                aria-label="Export"
                sx={{
                  borderRadius: 3,
                }}
              >
                <ModeEditOutlineOutlinedIcon
                  style={{ fontSize: "20px", color: "white" }}
                />
              </IconButton>
            </Tooltip>
          )}
        </Box>
      </Box>
      <Box padding="0px 10px">{content}</Box>
    </Box>
  );
};
export default InfoCard;
