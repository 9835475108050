import { useState } from "react";
import { useSnackbar } from "notistack";
import { DashboardService } from "../../../api/dashboard";
import { useMutation, useQueryClient } from "@tanstack/react-query";

const useChangeRequest = () => {
  const queryClient = useQueryClient();
  const { enqueueSnackbar } = useSnackbar();

  // Handling confirmation modal logic
  const [confirmationModalState, setConfirmationModalState] = useState(false);
  const [confirmationModalMessage, setConfirmationModalMessage] = useState("");
  const [confirmFunction, setConfirmFunction] = useState(null);

  const handleOpenConfirmationModal = (message, confirmFunction) => {
    setConfirmationModalMessage(message);
    setConfirmFunction(() => confirmFunction);
    setConfirmationModalState(true);
  };

  const handleCloseConfirmationModal = () => {
    setConfirmationModalState(false);
  };

  // Update change request status
  const { mutateAsync: updateChangeStatus, isPending } = useMutation({
    mutationFn: DashboardService.updateChangeRequestStatus,
    onSuccess: (data) => {
      enqueueSnackbar(data.msg, { variant: "success" });

      queryClient.invalidateQueries({
        predicate: (query) => {
          return [
            "notifications",
            "missionChangeRequests",
            "materialChangeRequests",
          ].includes(query.queryKey[0]);
        },
      });
    },
    onError: (error) => {
      enqueueSnackbar(error.response.data.msg, { variant: "error" });
    },
    onSettled: () => {
      handleCloseConfirmationModal();
    },
  });

  return {
    confirmationModalState,
    confirmationModalMessage,
    confirmFunction,
    handleOpenConfirmationModal,
    handleCloseConfirmationModal,
    updateChangeStatus,
    isPending,
  };
};

export default useChangeRequest;
