import { Box, Button, Stack, Typography } from "@mui/material";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";

const ClearLocalStorage = () => {
  useEffect(() => {
    localStorage.clear();
  }, []);

  const navigate = useNavigate();

  return (
    <Box
      onClick={() => navigate("/login")}
      sx={{
        display: "grid",
        placeItems: "center",
        height: "100vh",
        fontSize: "2rem",
      }}
    >
      <Stack spacing={4} alignItems="center">
        <img
          src="/done.gif"
          alt="done"
          style={{
            width: "100px",
          }}
        />
        <Typography variant="h2">Local Storage Cleared</Typography>
        <Button variant="contained" color="primary">
          Go to Login Page
        </Button>
      </Stack>
    </Box>
  );
};

export default ClearLocalStorage;
