import { configureStore } from "@reduxjs/toolkit";
import dashboardReducer from "./slices/dashboardSlice";
import authReducer from "./slices/authSlice";
import themeReducer from "./slices/themeSlice";
import dateRangeReducer from "./slices/dateRangeSlice";
import dateRangeAttendanceSlice from "./slices/dateRangeAttendanceSlice";
import dateRangeVacationSlice from "./slices/dateRangeVacationsSlice";
import dateRangeMailServicSlice from "./slices/dateRangeMailServiceSlice";
import filterReducer from "./slices/filterSlice";

const store = configureStore({
  reducer: {
    dashboard: dashboardReducer,
    auth: authReducer,
    theme: themeReducer,
    dateRange: dateRangeReducer,
    dateRangeAttendance: dateRangeAttendanceSlice,
    dateRangeVacation: dateRangeVacationSlice,
    dateRangeMailService: dateRangeMailServicSlice,
    filters: filterReducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
});

export default store;
