import InfoModal from "../../../../components/Modals/InfoModal";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { priceListsService } from "../../../../api/priceLists";
import FormSpinner from "../../../../components/Spinners/FormSpinner";
import * as yup from "yup";
import { useSnackbar } from "notistack";
import { useTranslation } from "react-i18next";
import { useFormik } from "formik";
import { Box } from "@mui/material";
import ButtonGroup from "../../../../components/forms/ButtonGroup/ButtonGroup";
import { useState } from "react";
import { DataGrid, FormContent } from "./EditItem";
import { useConfirmationDialog } from "../../../../contexts/ConfirmationDialogContext";

const ReplaceItem = ({ open, handleClose, itemId, PriceListRefNo }) => {
  const [t] = useTranslation("global");
  return (
    <InfoModal
      open={open}
      title={t("info_modules.price_list.forms.replace_item.title")}
      handleClose={handleClose}
      content={
        <ModalContent
          itemId={itemId}
          handleClose={handleClose}
          PriceListRefNo={PriceListRefNo}
        />
      }
    />
  );
};

const ModalContent = ({ itemId, handleClose, PriceListRefNo }) => {
  const { data, isLoading } = useQuery({
    queryKey: ["priceListReplaceItemData", itemId],
    queryFn: () => priceListsService.getReplaceItemData(itemId),
  });

  if (isLoading) return <FormSpinner />;

  return (
    <Form
      data={data}
      handleClose={handleClose}
      PriceListRefNo={PriceListRefNo}
    />
  );
};

const Form = ({ data, handleClose, PriceListRefNo }) => {
  const [t] = useTranslation("global");
  const { enqueueSnackbar } = useSnackbar();
  const queryClient = useQueryClient();
  const { setOpen } = useConfirmationDialog();
  const [selectedRows, setSelectedRows] = useState([]);

  const onRowSelectionModelChange = (rowSelectionModel) => {
    const newRows = data.result.filter((row) =>
      rowSelectionModel.includes(row.WorkOrderD6No)
    );
    setSelectedRows(newRows);
  };

  const { mutateAsync: replaceItem, isPending } = useMutation({
    mutationFn: priceListsService.patchPriceList,
    onSuccess: (data) => {
      handleClose();
      enqueueSnackbar(data.msg, { variant: "success" });
      queryClient.invalidateQueries({ queryKey: ["priceList"] });
    },
  });

  const requiredMessage = t("modules.global_schema.required");
  const selectValueMessage = t("modules.global_schema.select_value");

  const schema = yup.object().shape({
    code: yup.string().required(requiredMessage),
    price: yup.string().required(requiredMessage),
    unit: yup.string().required(requiredMessage),
    type: yup.string().required(selectValueMessage),
    description: yup.string().required(requiredMessage),
  });

  const { Description, Price, PriceListSN, SI, Unit, idPriceList } =
    data.Itemes[0];

  const initialValues = {
    code: PriceListSN,
    price: Price,
    unit: Unit,
    type: SI,
    description: Description,
  };

  const handleFormSubmit = (values) => {
    const modifiedRows = selectedRows.map((row) => {
      const {
        WorkOrderD6No,
        WorkOrderD6Amount,
        WorkOrderD6Status,
        SiteCode,
        SiteName,
        WorkOrderD6Install,
        WorkOrderD6Supply,
        WorkOrderNo,
        WorkOrderStatus,
      } = row;
      return {
        id: row.WorkOrderD6No,
        Amount46: WorkOrderD6Amount,
        D6WorkOrderStatus46: WorkOrderD6Status,
        SiteCode46: SiteCode,
        SiteName46: SiteName,
        WorkOrderD6Install: WorkOrderD6Install,
        WorkOrderD6No: WorkOrderD6No,
        WorkOrderD6Supply: WorkOrderD6Supply,
        WorkOrderNo: WorkOrderNo,
        WorkOrderStatus46: WorkOrderStatus,
      };
    });

    const { code, price, unit, description, type } = values;
    setOpen(
      t("info_modules.price_list.confirmation.replace_item"),
      () => () => {
        replaceItem({
          Description: description,
          Price: price.toString(),
          PriceListSN: code,
          State: "ReplacmentItems",
          Unit: unit,
          Quotations: modifiedRows,
          SI: type,
          SelectedID: idPriceList,
          SelectedNo: PriceListRefNo,
        });
      },
      "success"
    );
  };

  const { values, errors, touched, handleSubmit, handleBlur, handleChange } =
    useFormik({
      initialValues,
      validationSchema: schema,
      onSubmit: handleFormSubmit,
    });

  return (
    <Box
      component={"form"}
      onSubmit={handleSubmit}
      noValidate
      sx={{
        padding: 2,
        display: "flex",
        flexDirection: "column",
        gap: 2,
        width: 1200,
      }}
    >
      <FormContent
        values={values}
        errors={errors}
        touched={touched}
        handleChange={handleChange}
        handleBlur={handleBlur}
      />
      <DataGrid
        rows={data.result}
        handleClose={handleClose}
        onRowSelectionModelChange={onRowSelectionModelChange}
        selectedRows={selectedRows}
        type="replace"
      />
      <ButtonGroup
        onClickClose={handleClose}
        isPending={isPending}
        saveLabel={t("modules.buttons.save")}
      />
    </Box>
  );
};

export default ReplaceItem;
