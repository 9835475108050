import { useTranslation } from "react-i18next";

export const useLabels = () => {
  const [t] = useTranslation("global");

  const labels = {
    drivingLicenseType: t(
      "info_modules.employee_records.cards.driving_license.fields.license_type"
    ),
    drivingLicenseNo: t(
      "info_modules.employee_records.cards.driving_license.fields.license_no"
    ),
    licenseEndDate: t(
      "info_modules.employee_records.cards.driving_license.fields.end_date"
    ),
    licenseRegistrationDate: t(
      "info_modules.employee_records.cards.driving_license.fields.registration_date"
    ),
    licenseTrafficDepartment: t(
      "info_modules.employee_records.cards.driving_license.fields.traffic_department"
    ),
    licenseTrafficUnit: t(
      "info_modules.employee_records.cards.driving_license.fields.traffic_unit"
    ),
  };

  return labels;
};
