import Axios from "../network/Axios";

const getEmployeeHistory = async (empCode, startDate, endDate) => {
  const response = await Axios.get("/Attendance", {
    params: {
      SelectedNo: empCode,
      State: "Employee",
      Start: startDate,
      End: endDate,
    },
  });

  return response.data;
};

const getAttendanceEditData = async (id) => {
  const response = await Axios.get("/Attendance", {
    params: {
      SelectedNo: id,
      State: "Edit",
    },
  });

  return response.data.result;
};

const editAttendance = async (values) => {
  return Axios.put("/Attendance", values);
};

const deleteSignatureRecord = async (id) => {
  const res = await Axios.delete("/Attendance", {
    params: {
      SelectedID: id,
    },
  });

  return res.data;
};

export const attendanceService = {
  getEmployeeHistory,
  getAttendanceEditData,
  editAttendance,
  deleteSignatureRecord,
};
