import { useState } from "react";
import { useSnackbar } from "notistack";
import { TextField, Box } from "@mui/material";
import { useTranslation } from "react-i18next";
import InfoModal from "../../../components/Modals/InfoModal";
import { useQueryClient, useMutation } from "@tanstack/react-query";
import ButtonGroup from "../../../components/forms/ButtonGroup/ButtonGroup";
import { AccountsProjectsService } from "../../../api/accounts&projects";

const NewAccount = ({ handleClose, open }) => {
  const [t] = useTranslation("global");
  const [accountName, setAccountName] = useState("");
  const queryClient = useQueryClient();
  const { enqueueSnackbar } = useSnackbar();

  const handleCloseSave = () => {
    handleClose();
    setAccountName("");
  };

  const { mutateAsync: newAccount, isPending } = useMutation({
    mutationFn: async () => {
      return await AccountsProjectsService.newAccount(accountName);
    },
    onSuccess: (data) => {
      if (!data) return;
      handleCloseSave();
      enqueueSnackbar(data.msg, { variant: "success" });
      queryClient.invalidateQueries({ queryKey: ["accountsProjects"] });
    },
  });
  return (
    <InfoModal
      open={open}
      handleClose={handleCloseSave}
      title={t("modules.accounts_projects.modals.new_account")}
      width="30rem"
      content={
        <Box sx={{ margin: 1 }}>
          <TextField
            fullWidth
            label={t("modules.accounts_projects.modals.account_name")}
            size="small"
            variant="outlined"
            value={accountName}
            onChange={(e) => setAccountName(e.target.value)}
          />
          <ButtonGroup
            isPending={isPending}
            onClickClose={handleCloseSave}
            onClickSave={newAccount}
            saveLabel={t("modules.buttons.save")}
          />
        </Box>
      }
    />
  );
};
export default NewAccount;
