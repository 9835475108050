import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";
import {
  StyledTextField,
  autoCompleteStyles,
} from "../../../../MobileInternetLine/MobileInternetLine";
import { useSnackbar } from "notistack";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useTranslation } from "react-i18next";
import { useFormik } from "formik";
import * as yup from "yup";
import {
  MAX_FILE_SIZE,
  supportedImageTypes,
} from "../../../../../../constants/constants";
import AntSwitch from "../../../../../../components/Switches/AntSwitch";
import {
  Autocomplete,
  Box,
  Grid,
  Stack,
  Typography,
  Button,
  IconButton,
} from "@mui/material";
import ChipComponent from "../../../../../../components/Chips/ChipComponent";
import ButtonGroup from "../../../../../../components/forms/ButtonGroup/ButtonGroup";
import { convertImage } from "../../../../../../utils/utils";
import VisuallyHiddenInput from "../../../../../../components/VisuallyHiddenInput";
import ImageModal from "../../../../../../components/Modals/ImageModal";
import { useRef, useState } from "react";
import { Image } from "@mui/icons-material";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import DeleteIcon from "@mui/icons-material/Delete";
import { employeeRecordsService } from "../../../../../../api/employeeRecords";
import { useNavigate, useParams } from "react-router-dom";
import { useLabels } from "../hooks/useLabels";

const EditEmployeeDetails = ({ data, handleClose }) => {
  const navigate = useNavigate();
  const { empCode } = useParams();
  const { enqueueSnackbar } = useSnackbar();
  const queryClient = useQueryClient();
  const [t] = useTranslation("global");

  const labels = useLabels();

  const { mutateAsync: updateEmployeeDetails, isPending } = useMutation({
    mutationFn: employeeRecordsService.addNewEmployeeDocument,
    onSuccess: (data) => {
      handleClose();
      enqueueSnackbar(data.msg, { variant: "success" });
      queryClient.invalidateQueries({ queryKey: ["employeeInfo"] });

      navigate(`/employeerecords/${values.code.replace("/", "%2F")}`, {
        replace: true,
      });
    },
  });

  const {
    CompanyDepartmentName,
    CompanyProjectsName,
    EmpCode,
    EmpDateOfHire,
    EmpEmail,
    EmpInfoDrive,
    EmpInfoEntExt,
    EmpInfoMission,
    EmpInsurance,
    EmpMedical,
    EmpName,
    EmpNameEn,
    EmpPhone,
    EmpPhoto,
    EmpResignDate,
    EmpStatus,
    EmpTitle,
  } = data.EmployeeInfo[0];

  const convertedImage = EmpPhoto !== null && convertImage(EmpPhoto.data);

  const currentImage = EmpPhoto !== null && convertedImage;

  const initialValues = {
    employeeImage: null,
    intExt: EmpInfoEntExt,
    arabicName: EmpName,
    englishName: EmpNameEn,
    code: EmpCode,
    title: EmpTitle,
    department: CompanyDepartmentName,
    project: CompanyProjectsName,
    hireDate: EmpDateOfHire,
    email: EmpEmail,
    phoneNumber: EmpPhone,
    drive: EmpInfoDrive === "True",
    status: EmpStatus,
    mission: EmpInfoMission === "True",
    socialInsurance: EmpInsurance === "Insured",
    medicalInsurance: EmpMedical === "Covered",
    resignDate:
      EmpStatus === "Active" ? dayjs().format("YYYY/MM/DD") : EmpResignDate,
  };

  const intExtOptions = [
    t(
      "modules.employee_records.new_form.fields.work_information.intExt.options.internal"
    ),
    t(
      "modules.employee_records.new_form.fields.work_information.intExt.options.external"
    ),
  ];

  const statusOptions = [
    t(
      "modules.employee_records.new_form.fields.work_information.status.options.active"
    ),
    t(
      "modules.employee_records.new_form.fields.work_information.status.options.inactive"
    ),
  ];

  const schema = yup.object().shape({
    employeeImage: yup
      .mixed()
      .test("fileSize", t("modules.global_schema.file_size"), (value) => {
        return !value || (value && value.size <= MAX_FILE_SIZE);
      })
      .test(
        "fileType",
        t("modules.global_schema.file_type"),
        (value) => !value || (value && supportedImageTypes.includes(value.type))
      )
      .nullable(),
    intExt: yup.string().required(t("modules.global_schema.select_value")),
    arabicName: yup.string().required(t("modules.global_schema.required")),
    englishName: yup
      .string()
      .matches(/^[A-Za-z\s]+$/, t("modules.global_schema.only_english"))
      .required(t("modules.global_schema.required")),
    code: yup.string().required(t("modules.global_schema.required")),
    title: yup.string().required(t("modules.global_schema.required")),
    department: yup.string().required(t("modules.global_schema.select_value")),
    project: yup.string().required(t("modules.global_schema.select_value")),
    hireDate: yup.date().required(t("modules.global_schema.required")),
    resignDate: yup.date().nullable(),
    email: yup.string().email(),
    phoneNumber: yup
      .string()
      .required(t("modules.global_schema.required"))
      .length(
        10,
        t("modules.mobile_internet_lines.new_form.schema.phone_number_length")
      )
      .matches(
        /^(11|12|10)\d{8}$/,
        t("modules.mobile_internet_lines.new_form.schema.valid_phone_number")
      ),
    drive: yup.boolean().required(t("modules.global_schema.required")),
    status: yup.string().required(t("modules.global_schema.select_value")),
    mission: yup.boolean().required(t("modules.global_schema.required")),
    socialInsurance: yup
      .boolean()
      .required(t("modules.global_schema.required")),
    medicalInsurance: yup
      .boolean()
      .required(t("modules.global_schema.required")),
  });

  const handleFormSubmit = (values) => {
    const {
      employeeImage,
      arabicName,
      englishName,
      code,
      title,
      hireDate,
      department,
      project,
      drive,
      status,
      mission,
      intExt,
      phoneNumber,
      email,
      socialInsurance,
      medicalInsurance,
      resignDate,
    } = values;

    /**
     * The API expects the status to be in English
     * so we need to convert it to English before sending it
     * to the API
     */
    const enActive = t(
      "modules.employee_records.new_form.fields.work_information.status.options.active",
      { lng: "en" }
    );
    const arActive = t(
      "modules.employee_records.new_form.fields.work_information.status.options.active",
      { lng: "ar" }
    );

    const statusLabel = [enActive, arActive].includes(status)
      ? "Active"
      : "Inactive";

    /**
     * The API expects the intExt to be in English
     * so we need to convert it to English before sending it
     * to the API
     */
    const enInternal = t(
      "modules.employee_records.new_form.fields.work_information.intExt.options.internal",
      { lng: "en" }
    );
    const arInternal = t(
      "modules.employee_records.new_form.fields.work_information.intExt.options.internal",
      { lng: "ar" }
    );

    const intExtLabel = [enInternal, arInternal].includes(intExt)
      ? "Internal"
      : "External";

    const formData = new FormData();

    formData.append("EmpPhoto", employeeImage);
    formData.append("CompanyProjectsName", project);
    formData.append("CompanyDepartmentsName", department);
    formData.append("EmpCode", code);
    formData.append("EmpName", arabicName);
    formData.append("EmpNameEn", englishName);
    formData.append("EmpTitle", title);
    const newHireDate = dayjs(hireDate).format("YYYY-MM-DD");
    formData.append("EmpDateOfHire", newHireDate);
    const newResignDate = dayjs(resignDate).format("YYYY-MM-DD");
    formData.append("EmpResignDate", status === "Active" ? "" : newResignDate);
    formData.append("EmpPhone", phoneNumber);
    formData.append("EmpEmail", email);
    formData.append("EmpInfoDrive", drive ? "True" : "False");
    formData.append("EmpInfoMission", mission ? "True" : "False");
    formData.append("EmpStatus", statusLabel);
    formData.append(
      "EmpInsurance",
      socialInsurance ? "Insured" : "Not Insured"
    );
    formData.append("EmpMedical", medicalInsurance ? "Covered" : "Not Covered");
    formData.append("EmpInfoEntExt", intExtLabel);
    formData.append(
      "ImageState",
      isImageRemoved ? "Remove" : employeeImage ? "Exist" : "NotExist"
    );
    formData.append("State", "Employees");
    formData.append("SelectedNo", empCode);

    updateEmployeeDetails(formData);
  };

  const {
    values,
    errors,
    touched,
    handleBlur,
    handleChange,
    handleSubmit,
    setFieldValue,
    setFieldTouched,
  } = useFormik({
    initialValues,
    validationSchema: schema,
    onSubmit: handleFormSubmit,
  });

  const CompanyDepartments =
    data.CompanyDepartments.map(
      (department) => department.CompanyDepartmentName
    ) || [];

  const CompanyProjects =
    data.CompanyProjects.map((project) => project.CompanyProjectsName) || [];

  const employeeDetails = [
    {
      label: labels.hireDate,
      input: (
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <DatePicker
            label={labels.hireDate}
            value={dayjs(values.hireDate)}
            onChange={(newValue) => {
              setFieldValue("hireDate", newValue);
            }}
            format="YYYY/MM/DD"
            views={["year", "month", "day"]}
            slots={{
              textField: StyledTextField,
            }}
          />
        </LocalizationProvider>
      ),
      key: "hireDate",
    },
    {
      label: labels.resignDate,
      input: (
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <DatePicker
            label={labels.resignDate}
            value={dayjs(values.resignDate)}
            onChange={(newValue) => {
              setFieldValue("resignDate", newValue);
            }}
            format="YYYY/MM/DD"
            views={["year", "month", "day"]}
            slots={{
              textField: StyledTextField,
            }}
          />
        </LocalizationProvider>
      ),
      key: "resignDate",
    },
    {
      label: labels.status,
      input: (
        <Autocomplete
          disablePortal
          options={statusOptions || []}
          getOptionLabel={(option) => option}
          sx={autoCompleteStyles}
          size="small"
          onChange={(event, newValue) => {
            setFieldValue("status", newValue || "");
          }}
          value={
            values.status === "Active" || values.status === statusOptions[0]
              ? statusOptions[0]
              : statusOptions[1]
          }
          disableClearable
          renderInput={(params) => (
            <StyledTextField
              {...params}
              onBlur={handleBlur}
              error={!!touched.status && !!errors.status}
              label={touched.status ? errors.status : ""}
            />
          )}
        />
      ),
    },
    {
      label: labels.intExt,
      input: (
        <Autocomplete
          disablePortal
          options={intExtOptions || []}
          getOptionLabel={(option) => option}
          sx={autoCompleteStyles}
          size="small"
          onChange={(event, newValue) => {
            setFieldValue("intExt", newValue || "");
          }}
          value={
            values.intExt === "Internal" || values.intExt === intExtOptions[0]
              ? intExtOptions[0]
              : intExtOptions[1]
          }
          disableClearable
          renderInput={(params) => (
            <StyledTextField
              {...params}
              onBlur={handleBlur}
              error={!!touched.intExt && !!errors.intExt}
              label={touched.intExt ? errors.intExt : ""}
            />
          )}
        />
      ),
    },
    {
      label: labels.arabicName,
      input: (
        <StyledTextField
          name="arabicName"
          value={values.arabicName}
          onBlur={handleBlur}
          onChange={handleChange("arabicName")}
          error={!!touched.arabicName && !!errors.arabicName}
          label={touched.arabicName ? errors.arabicName : ""}
        />
      ),
    },
    {
      label: labels.englishName,
      input: (
        <StyledTextField
          name="englishName"
          value={values.englishName}
          onBlur={handleBlur}
          onChange={handleChange("englishName")}
          error={!!touched.englishName && !!errors.englishName}
          label={touched.englishName ? errors.englishName : ""}
        />
      ),
    },
    {
      label: labels.code,
      input: (
        <StyledTextField
          name="code"
          value={values.code}
          onBlur={handleBlur}
          onChange={handleChange("code")}
          error={!!touched.code && !!errors.code}
          label={touched.code ? errors.code : ""}
        />
      ),
    },
    {
      label: labels.title,
      input: (
        <StyledTextField
          name="title"
          value={values.title}
          onBlur={handleBlur}
          onChange={handleChange("title")}
          error={!!touched.title && !!errors.title}
          label={touched.title ? errors.title : ""}
        />
      ),
    },
    {
      label: labels.department,
      input: (
        <Autocomplete
          disablePortal
          options={CompanyDepartments}
          getOptionLabel={(option) => option}
          sx={autoCompleteStyles}
          size="small"
          onChange={(event, newValue) => {
            setFieldValue("department", newValue || "");
          }}
          value={values.department}
          disableClearable
          renderInput={(params) => (
            <StyledTextField
              {...params}
              onBlur={handleBlur}
              error={!!touched.department && !!errors.department}
              label={touched.department ? errors.department : ""}
            />
          )}
        />
      ),
    },
    {
      label: labels.project,
      input: (
        <Autocomplete
          disablePortal
          options={CompanyProjects}
          getOptionLabel={(option) => option}
          sx={autoCompleteStyles}
          size="small"
          onChange={(event, newValue) => {
            setFieldValue("project", newValue || "");
          }}
          value={values.project}
          disableClearable
          renderInput={(params) => (
            <StyledTextField
              {...params}
              onBlur={handleBlur}
              error={!!touched.project && !!errors.project}
              label={touched.project ? errors.project : ""}
            />
          )}
        />
      ),
    },
    {
      label: labels.email,
      input: (
        <StyledTextField
          name="email"
          value={values.email}
          onBlur={handleBlur}
          onChange={handleChange("email")}
          error={!!touched.email && !!errors.email}
          label={touched.email ? errors.email : ""}
        />
      ),
    },
    {
      label: labels.phoneNumber,
      input: (
        <StyledTextField
          name="phoneNumber"
          value={values.phoneNumber}
          onBlur={handleBlur}
          onChange={handleChange("phoneNumber")}
          error={!!touched.phoneNumber && !!errors.phoneNumber}
          label={touched.phoneNumber ? errors.phoneNumber : ""}
        />
      ),
    },
    {
      label: labels.drive,
      input: (
        <AntSwitch
          name="drive"
          checked={values.drive}
          onChange={(e, checked) => {
            setFieldValue("drive", checked);
          }}
        />
      ),
    },
    {
      label: labels.mission,
      input: (
        <AntSwitch
          name="mission"
          checked={values.mission}
          onChange={(e, checked) => {
            setFieldValue("mission", checked);
          }}
        />
      ),
    },
    {
      label: labels.socialInsurance,
      input: (
        <AntSwitch
          name="socialInsurance"
          checked={values.socialInsurance}
          onChange={(e, checked) => {
            setFieldValue("socialInsurance", checked);
          }}
        />
      ),
    },
    {
      label: labels.medicalInsurance,
      input: (
        <AntSwitch
          name="medicalInsurance"
          checked={values.medicalInsurance}
          onChange={(e, checked) => {
            setFieldValue("medicalInsurance", checked);
          }}
        />
      ),
    },
  ];

  const [isImageRemoved, setIsImageRemoved] = useState(false);

  const handleImageRemove = () => {
    setIsImageRemoved(true);
  };

  const resetImageRemove = () => {
    setIsImageRemoved(false);
  };

  const [isImageModalOpen, setIsImageModalOpen] = useState(false);

  const handleImageModalOpen = () => {
    setIsImageModalOpen(true);
  };

  const handleCloseImageModal = () => {
    setIsImageModalOpen(false);
  };

  // Image handling logic
  const fileInputRef = useRef(null);
  const [image, setImage] = useState(null);

  const handleImageChange = (event) => {
    if (!event.currentTarget.files[0]) return;
    resetImageRemove();
    const file = event.currentTarget.files[0];
    setFieldTouched("employeeImage", true, false); // Set touched to true (to show error message if any
    setFieldValue("employeeImage", file);
    setImage(URL.createObjectURL(file));
  };

  const handleRemoveImage = () => {
    handleImageRemove();
    setFieldValue("employeeImage", null);
    setImage(null);
    if (fileInputRef.current) {
      fileInputRef.current.value = ""; // Reset the input value
    }
  };

  return (
    <>
      <Box
        component="form"
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: "1rem",
        }}
        onSubmit={handleSubmit}
      >
        <Grid container spacing={2} columnSpacing={3}>
          {employeeDetails
            .filter((employee) =>
              employee.key
                ? values.status === "Active" ||
                  values.status === statusOptions[0]
                  ? employee.key !== "resignDate"
                  : employee.key !== "hireDate"
                : employee
            )
            .map(({ label, input }) => (
              <Grid
                item
                xs={4}
                key={label}
                sx={{
                  display: "flex",
                  "& > div": {
                    width: "100%",
                  },
                }}
              >
                <ChipComponent label={label} value={input} />
              </Grid>
            ))}
        </Grid>
        <Stack spacing={2} alignItems={"flex-start"} direction={"row"}>
          <Button
            component="label"
            role={undefined}
            variant="contained"
            tabIndex={-1}
            startIcon={<CloudUploadIcon />}
          >
            {t("modules.buttons.select_image")}
            <VisuallyHiddenInput
              type="file"
              name="employeeImage"
              accept="image/*"
              onChange={handleImageChange}
              ref={fileInputRef}
            />
          </Button>
          {!errors.employeeImage &&
            (values.employeeImage !== null || currentImage) &&
            !isImageRemoved && (
              <>
                <IconButton onClick={handleImageModalOpen}>
                  <Image />
                </IconButton>
                <IconButton aria-label="delete" onClick={handleRemoveImage}>
                  <DeleteIcon color="error" />
                </IconButton>
              </>
            )}
          {touched.employeeImage && errors.employeeImage && (
            <Typography
              color="red"
              sx={{
                backgroundColor: "#ffdfdf",
                p: 1,
                borderRadius: "5px",
                fontSize: "14px",
              }}
            >
              {errors.employeeImage}
            </Typography>
          )}
        </Stack>
        <ButtonGroup
          saveLabel={t("modules.buttons.save")}
          isPending={isPending}
          onClickClose={handleClose}
        />
      </Box>
      <ImageModal
        open={isImageModalOpen}
        handleClose={handleCloseImageModal}
        image={image || currentImage}
      />
    </>
  );
};

export default EditEmployeeDetails;
