import { useTranslation } from "react-i18next";
import { useFormik } from "formik";
import * as yup from "yup";
import { useState, useRef } from "react";
import {
  Box,
  Stack,
  Typography,
  Button,
  IconButton,
  Autocomplete,
} from "@mui/material";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import Image from "@mui/icons-material/Image";
import DeleteIcon from "@mui/icons-material/Delete";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { DateRangePicker } from "@mui/x-date-pickers-pro";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { CustomTextField } from "../../../../../../components/forms/EmployeeRecordsForm";
import ButtonGroup from "../../../../../../components/forms/ButtonGroup/ButtonGroup";
import ImageModal from "../../../../../../components/Modals/ImageModal";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import VisuallyHiddenInput from "../../../../../../components/VisuallyHiddenInput";
import {
  MAX_FILE_SIZE,
  supportedImageTypes,
} from "../../../../../../constants/constants";
import { useSelector } from "react-redux";

const Form = ({
  handleClose,
  isPending,
  empName,
  vacationTypes,
  initialValues,
  handleFormSubmit,
  currentImage,
  isEditMode,
  isImageRemoved,
  handleImageRemove,
  resetImageRemove,
}) => {
  const { language } = useSelector((state) => state.theme); // AR | EN

  const [t] = useTranslation("global");

  const schema = yup.object().shape({
    vacationDateRange: yup
      .array()
      .of(yup.date().required(t("modules.global_schema.required"))),
    vacationType: yup.string().required(t("modules.global_schema.required")),
    vacationAttachment: yup
      .mixed()
      .test("fileSize", t("modules.global_schema.file_size"), (value) => {
        return !value || (value && value.size <= MAX_FILE_SIZE);
      })
      .test(
        "fileType",
        t("modules.global_schema.file_type"),
        (value) => !value || (value && supportedImageTypes.includes(value.type))
      )
      .nullable(),
  });

  const {
    handleSubmit,
    setFieldValue,
    values,
    errors,
    touched,
    setFieldTouched,
  } = useFormik({
    initialValues,
    validationSchema: schema,
    onSubmit: handleFormSubmit,
  });

  const [isImageModalOpen, setIsImageModalOpen] = useState(false);

  const handleImageModalOpen = () => {
    setIsImageModalOpen(true);
  };

  const handleCloseImageModal = () => {
    setIsImageModalOpen(false);
  };

  // Image handling logic
  const fileInputRef = useRef(null);
  const [image, setImage] = useState(null);

  const handleImageChange = (event) => {
    if (!event.currentTarget.files[0]) return;
    if (isEditMode) resetImageRemove();
    const file = event.currentTarget.files[0];
    setFieldTouched("vacationAttachment", true, false); // Set touched to true (to show error message if any
    setFieldValue("vacationAttachment", file);
    setImage(URL.createObjectURL(file));
  };

  const handleRemoveImage = () => {
    if (isEditMode) handleImageRemove();
    setFieldValue("vacationAttachment", null);
    setImage(null);
    if (fileInputRef.current) {
      fileInputRef.current.value = ""; // Reset the input value
    }
  };

  return (
    <form onSubmit={handleSubmit} noValidate>
      <Stack spacing={3} alignItems={"flex-start"} p={2}>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: "1rem",
          }}
        >
          <Typography variant="h6">{empName}</Typography>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DemoContainer components={["DateRangePicker"]}>
              <DateRangePicker
                localeText={{
                  start: t(
                    "modules.vacation_requests.new_form.fields.start_date"
                  ),
                  end: t("modules.vacation_requests.new_form.fields.end_date"),
                }}
                format="YYYY/MM/DD"
                sx={{
                  "& .MuiInputBase-root": {
                    borderRadius: "10px",
                  },
                }}
                onChange={(newValue) => {
                  setFieldValue("vacationDateRange", newValue);
                }}
                value={values.vacationDateRange}
                slotProps={{
                  textField: ({ position }) => ({
                    size: "small",
                    helperText:
                      position === "start"
                        ? touched.vacationDateRange &&
                          errors.vacationDateRange?.[0]
                        : position === "end"
                        ? touched.vacationDateRange &&
                          errors.vacationDateRange?.[1]
                        : null,
                    error:
                      position === "start"
                        ? touched.vacationDateRange &&
                          errors.vacationDateRange?.[0]
                        : position === "end"
                        ? touched.vacationDateRange &&
                          errors.vacationDateRange?.[1]
                        : false,
                    required: true,
                  }),
                }}
              />
            </DemoContainer>
          </LocalizationProvider>
          <Autocomplete
            disableClearable
            size="small"
            name="vacationType"
            options={language === "AR" ? vacationTypes.ar : vacationTypes.en}
            getOptionLabel={(option) => option}
            onChange={(event, value) => {
              setFieldValue("vacationType", value);
            }}
            value={
              language === "EN"
                ? values.vacationType
                : isEditMode
                ? vacationTypes.en.indexOf(values.vacationType) !== -1
                  ? vacationTypes.ar[
                      vacationTypes.en.indexOf(values.vacationType)
                    ]
                  : values.vacationType
                : values.vacationType
            }
            renderInput={(params) => (
              <CustomTextField
                {...params}
                label={t(
                  "modules.vacation_requests.new_form.fields.vacation_type"
                )}
                variant="outlined"
                fullWidth
                error={touched.vacationType && Boolean(errors.vacationType)}
                helperText={touched.vacationType && errors.vacationType}
                required
              />
            )}
          />
        </Box>
        <Stack spacing={2} alignItems={"flex-start"} direction={"row"}>
          <Button
            component="label"
            role={undefined}
            variant="contained"
            tabIndex={-1}
            startIcon={<CloudUploadIcon />}
          >
            {t("modules.buttons.select_image")}
            <VisuallyHiddenInput
              type="file"
              name="vacationAttachment"
              accept="image/*"
              onChange={handleImageChange}
              ref={fileInputRef}
            />
          </Button>
          {!errors.vacationAttachment &&
            (values.vacationAttachment !== null || currentImage) &&
            !isImageRemoved && (
              <>
                <IconButton onClick={handleImageModalOpen}>
                  <Image />
                </IconButton>
                <IconButton aria-label="delete" onClick={handleRemoveImage}>
                  <DeleteIcon color="error" />
                </IconButton>
              </>
            )}
          {touched.vacationAttachment && errors.vacationAttachment && (
            <Typography
              color="red"
              sx={{
                backgroundColor: "#ffdfdf",
                p: 1,
                borderRadius: "5px",
                fontSize: "14px",
              }}
            >
              {errors.vacationAttachment}
            </Typography>
          )}
        </Stack>
      </Stack>
      <ButtonGroup
        isPending={isPending}
        onClickClose={handleClose}
        saveLabel={t("modules.buttons.save")}
      />
      <ImageModal
        open={isImageModalOpen}
        handleClose={handleCloseImageModal}
        image={image || currentImage}
      />
    </form>
  );
};

export default Form;
