import { useState } from "react";
import dayjs from "dayjs";
import DateFormatGrid from "../../../components/DateFormatGrid";
import { Box, Chip, Link } from "@mui/material";
import Axios from "../../../network/Axios";
import { useTranslation } from "react-i18next";
import { useQuery } from "@tanstack/react-query";
import { Link as RouterLink, useParams } from "react-router-dom";
import Spinner from "../../../components/Spinners/Spinner";
import WorkOrderDetails from "./InfoModals/WorkOrderDetails";
import HoldModal from "./InfoModals/HoldModal";
import NewEntity from "./InfoModals/NewEntity";
import ChipStatus from "../../../components/ChipStatus";
import { generateId } from "../../../utils/globalFunctions";
import InfoCard from "../../../components/InfoPagesCard/InfoCard";
import CustomAccordion from "../../../components/Accordion/CustomAccordion";
import MissionsForm from "../../../components/forms/Missions/MissionsForm";
import MaterialRequestForm from "../../../components/forms/MaterialRequests/MaterialRequestForm";
import AcceptanceQuotationsForm from "../../../components/forms/Acceptance/AcceptanceForm";
import WarrantyVisitForm from "../../../components/forms/WarrantyVisit/WarrantyVisitForm";
import MigrateModal from "./InfoModals/MigrateModal";
import ChangeSite from "./InfoModals/ChangeSite";

const WorkOrdersInfo = () => {
  const { workOrderId } = useParams();
  const [t] = useTranslation("global");
  const [expanded, setExpanded] = useState("panel1");
  const [hold, setHold] = useState(false);
  const [newMission, setNewMission] = useState(false);
  const [newAcceptance, setNewAcceptance] = useState(false);
  const [isEditable, setIsEditable] = useState(false);
  const [newWarrantyVisit, setNewWarrantyVisit] = useState(false);
  const [newMaterialRequest, setNewMaterialRequest] = useState(false);
  const [migrate, setMigrate] = useState(false);
  const [changeSite, setChangeSite] = useState(false);

  const handleOpenEditMode = () => setIsEditable(true);
  const handleCloseEditMode = () => setIsEditable(false);

  const { data } = useQuery({
    queryKey: ["workOrderEdit", workOrderId],
    queryFn: async () => {
      const response = await Axios.get("/WorkOrders", {
        params: {
          State: "Edit",
          SelectedNo: workOrderId,
        },
      });
      return response.data;
    },
    enabled: isEditable,
  });

  const handleOpenModal = (modalType) => {
    switch (modalType) {
      case "newMission":
        setNewMission(true);
        break;
      case "newMaterialRequest":
        setNewMaterialRequest(true);
        break;
      case "newAcceptance":
        setNewAcceptance(true);
        break;
      case "newWarrantyVisit":
        setNewWarrantyVisit(true);
        break;
      case "hold":
        setHold(true);
        break;
      case "migrate":
        setMigrate(true);
        break;
      case "changeSite":
        setChangeSite(true);
        break;
      default:
        break;
    }
  };

  const handleChange = (panel) => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false);
  };
  const { data: workOrderInfo, isLoading } = useQuery({
    queryKey: ["workOrderInfo", workOrderId],
    queryFn: async () => {
      const response = await Axios.get("/WorkOrders", {
        params: {
          SelectedNo: workOrderId,
          State: "CardDetailed",
        },
      });
      return response.data;
    },
  });
  const missionsColumns = [
    {
      field: "MissionNo",
      headerName: t("data_grid.headers.ref_no"),
      width: 120,
      renderCell: (params) => {
        return (
          <Link
            component={RouterLink}
            to={
              params.value.startsWith("W")
                ? `/warrantyvisit/${params.value}`
                : `/missions/${params.value}`
            }
            target="_blank"
          >
            {params.value}
          </Link>
        );
      },
    },
    {
      field: "MissionsPaperNo",
      headerName: t("data_grid.headers.paper_no"),
      width: 100,
    },
    {
      field: "SiteName",
      headerName: t("data_grid.headers.site_name"),
      flex: 1,
    },
    {
      field: "RequestedFor",
      headerName: t("data_grid.headers.requested_for"),
      width: 100,
    },
    {
      field: "SiteCode",
      headerName: t("data_grid.headers.site_code"),
      width: 110,
    },
    {
      field: "MUser",
      headerName: t("data_grid.headers.mission_by"),
      width: 150,
    },
    {
      field: "WUser",
      headerName: t("data_grid.headers.order_by"),
      width: 150,
    },
    {
      field: "MissionStart",
      headerName: t("data_grid.headers.start_date"),

      renderCell: ({ value }) => {
        return <> {value ? <DateFormatGrid value={value} /> : <> </>}</>;
      },
    },
    {
      field: "MissionEnd",
      headerName: t("data_grid.headers.end_date"),

      renderCell: ({ value }) => {
        return <> {value ? <DateFormatGrid value={value} /> : <> </>}</>;
      },
    },
    {
      field: "Status",
      headerName: t("data_grid.headers.status"),
      width: 120,
      renderCell: (params) => <ChipStatus value={params.value} />,
    },
  ];
  const materialRequestsColumns = [
    {
      field: "MaterialsRequestNo",
      headerName: t("data_grid.headers.ref_no"),
      width: 120,
      renderCell: (params) => {
        return (
          <Link
            component={RouterLink}
            to={`/materialrequests/${params.value}`}
            target="_blank"
          >
            {params.value}
          </Link>
        );
      },
    },
    {
      field: "ERPUserNickName",
      headerName: t("data_grid.headers.created_by"),
      width: 100,
      flex: 1,
    },
    {
      field: "MaterialsRequestOrigin",
      headerName: t("data_grid.headers.mr_origin"),
      width: 100,
    },
    {
      field: "IssuedOn",
      headerName: t("data_grid.headers.issued_on"),
      width: 145,
      renderCell: ({ value }) => {
        return <> {value ? <DateFormatGrid value={value} /> : <> </>}</>;
      },
    },
    {
      field: "SentOn",
      headerName: t("data_grid.headers.sent_on"),
      width: 145,
      renderCell: ({ value }) => {
        return <> {value ? <DateFormatGrid value={value} /> : <> </>}</>;
      },
    },

    {
      field: "MaterialsRequestStatus",
      headerName: t("data_grid.headers.status"),
      width: 140,
      renderCell: (params) => <ChipStatus value={params.value} />,
    },
  ];
  const issuedColumns = [
    {
      field: "StoreItemsTemplateCode",
      headerName: t("data_grid.headers.item_code"),
      width: 100,
    },
    {
      field: "StoreItemsTemplateName",
      headerName: t("data_grid.headers.item_description"),
      width: 180,
      flex: 1,
    },
    {
      field: "StoreItemsTemplateUnit",
      headerName: t("data_grid.headers.unit"),
      width: 90,
    },
    {
      field: "MaterialsRequestQty",
      headerName: t("data_grid.headers.requested_qty"),
      width: 100,
    },
    {
      field: "TotalIssued",
      headerName: t("data_grid.headers.issued_qty"),
      width: 100,
    },
    {
      field: "TotalReturn",
      headerName: t("data_grid.headers.returned_qty"),
      width: 100,
    },
    {
      field: "IssuedQty",
      headerName: t("data_grid.headers.total_issued"),
      width: 100,
      renderCell: (params) =>
        params.row.IssuedQty === null ? (
          ""
        ) : (
          <Chip
            label={params.value}
            sx={{ color: "white", background: "#4361ee" }}
            size="small"
          />
        ),
    },
    {
      field: "LastIssueOn",
      headerName: t("data_grid.headers.last_issue_date"),
      width: 160,
      renderCell: ({ value }) => {
        return <> {value ? <DateFormatGrid value={value} /> : <> </>}</>;
      },
    },
  ];
  const acceptance = [
    {
      field: "WorkOrderD6No",
      headerName: t("data_grid.headers.d6_no"),
      width: 130,
      renderCell: (params) => {
        return (
          <Link
            component={RouterLink}
            to={`/quotations/${params.value}`}
            target="_blank"
          >
            {params.value}
          </Link>
        );
      },
    },
    {
      field: "WorkOrderD6TypeName",
      headerName: t("data_grid.headers.type"),
      width: 180,
      flex: 1,
    },
    {
      field: "WorkOrderD6Start",
      headerName: t("data_grid.headers.start_date"),
      width: 90,
      renderCell: ({ value }) => {
        return value ? dayjs(value).format("YYYY/MM/DD") : "";
      },
    },
    {
      field: "WorkOrderD6End",
      headerName: t("data_grid.headers.end_date"),
      width: 100,
      renderCell: ({ value }) => {
        return value ? dayjs(value).format("YYYY/MM/DD") : "";
      },
    },
    {
      field: "AcceptanceDate",
      headerName: t("data_grid.headers.acceptance_date"),
      width: 140,
      renderCell: ({ value }) => {
        return value ? dayjs(value).format("YYYY/MM/DD") : "";
      },
    },
    {
      field: "ERPUserNickName",
      headerName: t("data_grid.headers.user"),
      width: 150,
    },
    {
      field: "WorkOrderD6Amount",
      headerName: t("data_grid.headers.amount"),
      width: 120,
    },
    {
      field: "WorkOrderD6Status",
      headerName: t("data_grid.headers.status"),
      width: 160,
      renderCell: (params) => <ChipStatus value={params.value} />,
    },
  ];
  const deliveryNotes = [
    {
      field: "deliverynotesNo",
      headerName: t("data_grid.headers.ref_no"),
      width: 130,
    },
    {
      field: "ERPUserNickName",
      headerName: t("data_grid.headers.user"),
      width: 180,
      flex: 1,
    },
    {
      field: "DeliveryNotesStatus",
      headerName: t("data_grid.headers.status"),
      width: 120,
      renderCell: (params) => <ChipStatus value={params.value} />,
    },
  ];
  const modals = [
    {
      key: "newMission",
      open: newMission,
      handleClose: () => setNewMission(false),
      titleKey: t("info_modules.work_orders.buttons.new_mission"),
      FormComponent: MissionsForm,
    },
    {
      key: "newWarrantyVisit",
      open: newWarrantyVisit,
      handleClose: () => setNewWarrantyVisit(false),
      titleKey: t("info_modules.work_orders.buttons.new_warranty_visit"),
      FormComponent: WarrantyVisitForm,
    },
    {
      key: "newMaterialRequest",
      open: newMaterialRequest,
      handleClose: () => setNewMaterialRequest(false),
      titleKey: t("info_modules.work_orders.buttons.new_material_request"),
      FormComponent: MaterialRequestForm,
    },
    {
      key: "newAcceptance",
      open: newAcceptance,
      handleClose: () => setNewAcceptance(false),
      titleKey: t("info_modules.work_orders.buttons.new_acceptance"),
      FormComponent: AcceptanceQuotationsForm,
      additionalProps: {
        d6Type: workOrderInfo?.result[0],
      },
    },
  ];
  return (
    <>
      {isLoading ? (
        <Spinner />
      ) : (
        <Box
          display="flex"
          flexDirection="column"
          gap={1.7}
          marginBottom={2}
          width="99.5%"
        >
          <InfoCard
            title={t("info_modules.work_orders.description.work_order_details")}
            editButton={workOrderInfo?.Actions.includes("Edit Details")}
            onClickEdit={handleOpenEditMode}
            content={
              <WorkOrderDetails
                workOrderInfo={workOrderInfo}
                handleOpenModal={handleOpenModal}
                workOrderId={workOrderId}
                isEditable={isEditable}
                data={data}
                handleCloseEditMode={handleCloseEditMode}
              />
            }
          />
          <Box display="flex" flexDirection="column" gap={0.5} width="99.5">
            {workOrderInfo?.Missions.length > 0 && (
              <CustomAccordion
                title={t("info_modules.work_orders.description.missions")}
                rows={workOrderInfo.Missions || []}
                columns={missionsColumns}
                hideFooter
                getRowId={(row) => row.idMissions}
                expanded={expanded === "panel1"}
                onChange={handleChange("panel1")}
              />
            )}
            {workOrderInfo?.WarrantyVisit.length > 0 && (
              <CustomAccordion
                title={t("info_modules.work_orders.description.warranty_visit")}
                rows={workOrderInfo.WarrantyVisit || []}
                columns={missionsColumns}
                hideFooter
                getRowId={(row) => row.idMissions}
                expanded={expanded === "panel2"}
                onChange={handleChange("panel2")}
              />
            )}
            {workOrderInfo?.MaterialRequest.length > 0 && (
              <CustomAccordion
                title={t(
                  "info_modules.work_orders.description.material_request"
                )}
                rows={workOrderInfo.MaterialRequest || []}
                columns={materialRequestsColumns}
                hideFooter
                getRowId={(row) => row.MaterialsRequestNo}
                expanded={expanded === "panel3"}
                onChange={handleChange("panel3")}
              />
            )}
            {workOrderInfo?.MaterialRequestIssued.length > 0 && (
              <CustomAccordion
                title={t(
                  "info_modules.work_orders.description.issued_requests"
                )}
                rows={workOrderInfo.MaterialRequestIssued || []}
                columns={issuedColumns}
                getRowId={(row) => row.StoreItemsTemplateCode}
                toolbar
                showExportButton
                hideFooter
                expanded={expanded === "panel4"}
                onChange={handleChange("panel4")}
              />
            )}
            {workOrderInfo?.WorkOrdersD6.length > 0 && (
              <CustomAccordion
                title={t("info_modules.work_orders.description.acceptance")}
                rows={workOrderInfo.WorkOrdersD6 || []}
                columns={acceptance}
                getRowId={generateId()}
                hideFooter
                expanded={expanded === "panel5"}
                onChange={handleChange("panel5")}
              />
            )}
            {workOrderInfo?.deliverynotes.length > 0 && (
              <CustomAccordion
                title={t("info_modules.work_orders.description.delivery_notes")}
                rows={workOrderInfo.deliverynotes || []}
                columns={deliveryNotes}
                getRowId={generateId()}
                hideFooter
                expanded={expanded === "panel6"}
                onChange={handleChange("panel6")}
              />
            )}
          </Box>
          {modals.map(
            ({
              key,
              open,
              handleClose,
              titleKey,
              FormComponent,
              additionalProps,
            }) => (
              <NewEntity
                key={key}
                open={open}
                handleCloseFromAnotherForm={handleClose}
                titleKey={titleKey}
                FormComponent={FormComponent}
                workOrderId={workOrderId}
                {...additionalProps}
              />
            )
          )}
          <HoldModal
            open={hold}
            handleClose={() => setHold(false)}
            holdReason={workOrderInfo?.result[0].WorkOrderHoldReason}
          />
          <MigrateModal
            open={migrate}
            handleClose={() => setMigrate(false)}
            workOrderId={workOrderId}
            workOrderInfo={workOrderInfo}
          />
          <ChangeSite
            open={changeSite}
            handleClose={() => setChangeSite(false)}
          />
        </Box>
      )}
    </>
  );
};
export default WorkOrdersInfo;
