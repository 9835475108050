import ItemDescription from "./Steps/ItemDescription";
import Save from "./Steps/Save";
import Box from "@mui/material/Box";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import { useReducer, useState } from "react";
import { useTranslation } from "react-i18next";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useSnackbar } from "notistack";
import * as yup from "yup";
import SelectTemplateItems from "./Steps/SelectTemplateItems";
import ModifyTemplateItems from "./Steps/ModifyTemplateItems";
import { InventoryManagementService } from "../../../api/inventoryManagement";
import StepperButton from "../ButtonGroup/StepperButton";
import useHandleForms from "../../../hooks/useHandleForms";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";

const InventoryManagementForm = ({ onDirtyChange }) => {
  const [t] = useTranslation("global");

  const queryClient = useQueryClient();

  const { enqueueSnackbar } = useSnackbar();

  const [activeStep, setActiveStep] = useState(0);

  const handleNext = async () => {
    const { rows } = state;

    if (activeStep === 0) {
      const areValid = await trigger([
        "description",
        "itemCode",
        "itemName",
        "itemNameEn",
        "unit",
        "type",
        "minQuantity",
        "length",
      ]);
      if (!areValid) return;
      if (getValues("type") === "Item") return setActiveStep(3);
    }

    if (activeStep === 2) {
      if (rows.find((row) => row.QTY <= 0 || row.QTY == "" || !row.QTY)) {
        enqueueSnackbar(t("modules.error_messages.zero_or_negative_quantity"), {
          variant: "error",
        });
        return;
      }
    }
    const {
      itemName,
      itemNameEn,
      itemCode,
      unit,
      type,
      minQuantity,
      description,
    } = getValues();

    if (activeStep === 3) {
      const enTemplate = t(
        "modules.inventory_management.new_form.fields.type.options.template",
        { lng: "en" }
      );
      const arTemplate = t(
        "modules.inventory_management.new_form.fields.type.options.template",
        { lng: "ar" }
      );

      const typeLabel = [enTemplate, arTemplate].includes(type)
        ? "Template"
        : "Item";

      AddNewItem({
        State: "NewItem",
        StoreItemsTemplateCode: itemCode,
        StoreItemsTemplateDescription: description,
        StoreItemsTemplateMinimum: minQuantity.toString(),
        StoreItemsTemplateName: itemName,
        StoreItemsTemplateNameEn: itemNameEn,
        StoreItemsTemplateType: typeLabel,
        StoreItemsTemplateUnit: unit,
        Items: rows.map((row) => {
          return {
            StoreItemsTemplateCode: row.StoreItemsTemplateCode,
            QTY: Number(row.QTY),
          };
        }),
      });
    }

    if (activeStep !== 3) setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    if (getValues("type") === "Item") return setActiveStep(0);
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const steps = [
    t("modules.inventory_management.new_form.steps.item_description"),
    t("modules.inventory_management.new_form.steps.select_template_items"),
    t("modules.inventory_management.new_form.steps.modify_template_items"),
    t("modules.inventory_management.new_form.steps.save"),
  ];

  const initialValues = {
    rows: [],
    itemName: "",
    itemNameEn: "",
    itemCode: "",
    description: "",
    unit: "",
    length: 0,
    type: "Item",
    minQuantity: 0,
  };

  const schema = yup.object().shape({
    itemName: yup.string().required(t("modules.global_schema.required")),
    itemNameEn: yup.string().required(t("modules.global_schema.required")),
    itemCode: yup.string().required(t("modules.global_schema.required")),
    description: yup.string().required(t("modules.global_schema.required")),
    unit: yup.string().required(t("modules.global_schema.required")),
    length: yup
      .number()
      .typeError(t("modules.global_schema.numbers_only"))
      .required(t("modules.global_schema.required")),
    type: yup.string().required(t("modules.global_schema.select_value")),
    minQuantity: yup
      .number()
      .typeError(t("modules.global_schema.numbers_only"))
      .required(t("modules.global_schema.required")),
  });

  const {
    watch,
    register,
    setValue,
    control,
    trigger,
    getValues,
    formState: { errors, isDirty },
  } = useForm({
    resolver: yupResolver(schema),
    mode: "onChange",
    defaultValues: initialValues,
  });

  const { handleClose } = useHandleForms({ onDirtyChange, dirty: isDirty });

  function reducer(state, action) {
    switch (action.type) {
      case "rows":
      case "columns":
      case "error":
        return {
          ...state,
          [action.type]: action.payload,
        };
      default:
        throw new Error("Unknown action type: " + action.type);
    }
  }

  const [state, dispatch] = useReducer(reducer, initialValues);

  const { mutateAsync: AddNewItem, isPending: isAddingNewAddNewItem } =
    useMutation({
      mutationFn: InventoryManagementService.AddNewItem,
      onSuccess: (data) => {
        handleClose();
        enqueueSnackbar(data.msg, { variant: "success" });
        queryClient.invalidateQueries({ queryKey: ["inventoryManagement"] });
      },
      onError: (error) => {
        enqueueSnackbar(error.message, { variant: "error" });
      },
    });

  const views = {
    0: (
      <ItemDescription
        setFieldValue={setValue}
        values={watch()}
        register={register}
        errors={errors}
        control={control}
      />
    ),
    1: (
      <SelectTemplateItems
        dispatch={dispatch}
        selectedRows={state.rows}
        setValue={setValue}
      />
    ),
    2: <ModifyTemplateItems rows={state.rows} dispatch={dispatch} />,
    3: <Save values={getValues()} selectedRows={state.rows} />,
  };

  const isNextBtnDisabled =
    activeStep === 1 || activeStep === 2
      ? state.rows.length === 0
      : isAddingNewAddNewItem;

  return (
    <Box
      sx={{ width: "100%", display: "flex", flexDirection: "column", gap: 2 }}
    >
      <Stepper activeStep={activeStep}>
        {steps.map((label) => {
          return (
            <Step key={label}>
              <StepLabel>{label}</StepLabel>
            </Step>
          );
        })}
      </Stepper>
      {views[activeStep]}
      <StepperButton
        isPending={isAddingNewAddNewItem}
        onClick={handleNext}
        handleBack={handleBack}
        activeStep={activeStep}
        disabled={isNextBtnDisabled}
        label={
          activeStep === steps.length - 1
            ? t("modules.buttons.save")
            : t("modules.buttons.next")
        }
        isLastStep={activeStep === steps.length - 1}
      />
    </Box>
  );
};

export default InventoryManagementForm;
