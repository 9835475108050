import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { useSnackbar } from "notistack";
import dayjs from "dayjs";
import FormSpinner from "../../../../../../components/Spinners/FormSpinner";
import { vacationsRequestsService } from "../../../../../../api/vacationsRequests";
import Form from "./Form";
import { useState } from "react";
import { convertImage } from "../../../../../../utils/utils";

const EditVacationRequest = ({ handleClose, vacationID }) => {
  const { enqueueSnackbar } = useSnackbar();
  const queryClient = useQueryClient();

  const { data, isLoading, isRefetching } = useQuery({
    queryKey: ["editVacationRequestsForm", vacationID],
    queryFn: () =>
      vacationsRequestsService.getEditVacationRequestData(vacationID),
  });

  const { mutateAsync: addNewVacationsRequest, isPending } = useMutation({
    mutationFn: vacationsRequestsService.editVacationRequest,
    onSuccess: (data) => {
      handleClose();
      enqueueSnackbar(data.msg, { variant: "success" });
      queryClient.invalidateQueries({
        predicate: (query) => {
          return [
            "vacationsRequests",
            "employeeInfo",
            "employeeVacationsList",
            "employeeHistory",
            "attendance",
          ].includes(query.queryKey[0]);
        },
      });
    },
  });

  const [isImageRemoved, setIsImageRemoved] = useState(false);

  const handleImageRemove = () => {
    setIsImageRemoved(true);
  };

  const resetImageRemove = () => {
    setIsImageRemoved(false);
  };

  if (isLoading || isRefetching) return <FormSpinner />;

  const vacationTypes = {
    ar: data.VacationsTypes.map((item) => item.VacationsTypesNameArabic),
    en: data.VacationsTypes.map((item) => item.VacationsTypesName),
  };

  const handleFormSubmit = (values) => {
    const { vacationAttachment, vacationDateRange, vacationType } = values;

    const index =
      vacationTypes.ar.indexOf(vacationType) !== -1
        ? vacationTypes.ar.indexOf(vacationType)
        : vacationTypes.en.indexOf(vacationType);

    const formData = new FormData();
    formData.append("VacationAttachement", vacationAttachment);
    formData.append("Start", dayjs(vacationDateRange[0]).format("YYYY/MM/DD"));
    formData.append("End", dayjs(vacationDateRange[1]).format("YYYY/MM/DD"));
    formData.append("VacationsTypesName", vacationTypes.en[index]);
    formData.append("idEmpInfo", data?.VacationInformation[0].idEmpInfo);
    formData.append(
      "ImageState",
      isImageRemoved ? "Remove" : vacationAttachment ? "Exist" : "NotExist"
    );

    formData.append("SelectedID", vacationID);

    addNewVacationsRequest(formData);
  };

  const convertedImage = convertImage(
    data.VacationInformation[0].VacationAttachement?.data
  );

  const currentImage =
    data.VacationInformation[0].VacationAttachement && convertedImage;

  const initialValues = {
    vacationDateRange: [
      dayjs(data.VacationInformation[0].VacationStartDate),
      dayjs(data.VacationInformation[0].VacationEndDate),
    ],
    vacationAttachment: null,
    vacationType: data.VacationInformation[0].VacationsTypesName,
  };

  return (
    <Form
      handleClose={handleClose}
      isPending={isPending}
      empName={data.VacationInformation[0].EmpName}
      vacationTypes={vacationTypes}
      handleFormSubmit={handleFormSubmit}
      initialValues={initialValues}
      currentImage={currentImage}
      isEditMode
      handleImageRemove={handleImageRemove}
      isImageRemoved={isImageRemoved}
      resetImageRemove={resetImageRemove}
    />
  );
};

export default EditVacationRequest;
