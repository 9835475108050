import { useQuery } from "@tanstack/react-query";
import Axios from "../../../../network/Axios";
import { useState, useCallback } from "react";
import FormSpinner from "../../../../components/Spinners/FormSpinner";
import { Stack, Grid } from "@mui/material";
import { useTranslation } from "react-i18next";
import ChipComponent from "../../../../components/Chips/ChipComponent";
import CustomAccordion from "../../../../components/Accordion/CustomAccordion";
import ChipStatus from "../../../../components/ChipStatus";
import { DetailPanelContent } from "../../PurchaseRequest/PurchaseRequestInfo";
import { generateId } from "../../../../utils/globalFunctions";

const MaterialRequestSnapShot = ({ materialNo, open }) => {
  const { data, isLoading } = useQuery({
    queryKey: ["materialCard", materialNo],
    queryFn: async () => {
      const response = await Axios.get("/MaterialRequests", {
        params: {
          SelectedNo: materialNo,
          State: "Card",
        },
      });
      return response.data;
    },
    enabled: open,
  });

  if (isLoading) return <FormSpinner />;

  return <MaterialSnap data={data} />;
};

const MaterialSnap = ({ data }) => {
  const [t] = useTranslation("global");

  const getDetailPanelContent = useCallback(
    ({ row }) => <DetailPanelContent row={row.StoreTransitionNo} />,
    []
  );
  // WorkOrder data
  const {
    MaterialsRequestNo,
    ERPUserNickName,
    MaterialsRequestStatus,
    WorkOrderNo,
    CSiteCode,
    SiteName,
    MaterialsRequestOrigin,
  } = data.result[0];

  const materialRequests = [
    {
      label: t("data_grid.headers.request_no"),
      value: MaterialsRequestNo,
    },
    {
      label: t("data_grid.headers.user"),
      value: ERPUserNickName,
    },
    {
      label: t("data_grid.headers.status"),
      value: <ChipStatus value={MaterialsRequestStatus} />,
    },
    {
      label: t("data_grid.headers.order_no"),
      value: WorkOrderNo,
    },
    {
      label: t("data_grid.headers.site_code"),
      value: CSiteCode,
    },
    {
      label: t("data_grid.headers.site_name"),
      value: SiteName,
    },
    {
      label: t("data_grid.headers.mr_origin"),
      value: MaterialsRequestOrigin,
    },
  ];

  const requestedItems = [
    {
      field: "StoreItemsTemplateCode",
      headerName: t("data_grid.headers.item_code"),
      width: 90,
    },
    {
      field: "StoreItemsTemplateName",
      headerName: t("data_grid.headers.item_name"),
      width: 180,
      flex: 1,
    },
    {
      field: "StoreItemsTemplateUnit",
      headerName: t("data_grid.headers.unit"),
      width: 80,
    },
    {
      field: "MaterialsRequestQty",
      headerName: t("data_grid.headers.requested_qty"),
      width: 90,
    },
    {
      field: "IssuedQty",
      headerName: t("data_grid.headers.issued_qty"),
      width: 100,
    },
  ];

  const transitions = [
    {
      field: "StoreTransitionNo",
      headerName: t("data_grid.headers.transaction_no"),
      width: 130,
    },
    {
      field: "StoreTransitionInvoiceNo",
      headerName: t("data_grid.headers.invoice_no"),
      width: 120,
    },
    {
      field: "CompanyStoresName",
      headerName: t("data_grid.headers.store_name"),
      width: 120,
    },
    {
      field: "ERPUserNickName",
      headerName: t("data_grid.headers.issuer"),
      width: 90,
      flex: 1,
    },
    {
      field: "StoreTransitionDate",
      headerName: t("data_grid.headers.transaction_date"),
      width: 120,
      renderCell: (params) => params.row.StoreTransitionDate.split("T")[0],
    },
    {
      field: "StoreTransitionType",
      headerName: t("data_grid.headers.transaction_type"),
      width: 120,
    },
  ];

  const [expanded, setExpanded] = useState("panel1");
  const handleChange = (panel) => (_, newExpanded) => {
    setExpanded(newExpanded ? panel : false);
  };

  return (
    <Stack spacing={1.2} p={1}>
      <Grid container spacing={1}>
        {materialRequests.map(({ label, value }) => (
          <Grid item xs={4} key={label}>
            <ChipComponent label={label} value={value} />
          </Grid>
        ))}
      </Grid>

      <CustomAccordion
        title={t("data_grid.headers.requested_items")}
        rows={data?.ItemsDetailed || []}
        columns={requestedItems}
        getRowId={(row) => row.idMaterialsRequestTemplates}
        hideFooter
        height={190}
        expanded={expanded === "panel1"}
        onChange={handleChange("panel1")}
      />
      <CustomAccordion
        title={t("data_grid.headers.related_transactions")}
        rows={data?.Transitions || []}
        columns={transitions}
        getRowId={generateId()}
        hideFooter
        height={220}
        expanded={expanded === "panel2"}
        onChange={handleChange("panel2")}
        getDetailPanelHeight={() => "auto"}
        getDetailPanelContent={getDetailPanelContent}
      />
    </Stack>
  );
};
export default MaterialRequestSnapShot;
