import { useState, useCallback } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useSnackbar } from "notistack";
import { Box } from "@mui/material";
import Axios from "../../../network/Axios";
import InfoCard from "../../../components/InfoPagesCard/InfoCard";
import ChipStatus from "../../../components/ChipStatus";
import Spinner from "../../../components/Spinners/Spinner";
import FormsDataGrid from "../../../components/DataGrid/FormsDataGrid";
import ConfirmationInfoModal from "../../../components/Modals/ConfirmationInfoModal";
import AddMaterial from "../MaterialRequest/InfoModals/AddMaterial";
import { generateId } from "../../../utils/globalFunctions";
import { useQuery, useMutation, useQueryClient } from "@tanstack/react-query";
import PurchaseCard from "./InfoModals/PurchaseCard";
import SimplePopup from "../../../components/PopUp/PopUp";
import EditQuantity from "./InfoModals/EditQuantity";
import { GridActionsCellItem } from "@mui/x-data-grid-pro";
import DeleteIcon from "@mui/icons-material/Delete";
import Edit from "@mui/icons-material/Edit";
import Add from "./InfoModals/Add/Add";

export function DetailPanelContent({ row: rowProp }) {
  const [t] = useTranslation("global");
  const { data: transactionDetails, isLoading: loadDetails } = useQuery({
    queryKey: ["transactionDetails", rowProp],
    queryFn: async () => {
      const response = await Axios.get("/InventoryManagement", {
        params: {
          SelectedNo: rowProp,
          State: "Transactions",
        },
      });
      return response.data.result;
    },
    enabled: !!rowProp,
  });
  const transactionColumns = [
    {
      field: "StoreItemsTemplateCode",
      headerName: t("data_grid.headers.code"),
      width: 160,
    },
    {
      field: "StoreItemsTemplateName",
      headerName: t("data_grid.headers.name"),
      width: 180,
      flex: 1,
    },
    {
      field: "StoreItemsTemplateUnit",
      headerName: t("data_grid.headers.unit"),
      width: 150,
    },
    {
      field: "StoreTransactionQty",
      headerName: t("data_grid.headers.qty"),
      width: 180,
    },
  ];

  return (
    <FormsDataGrid
      rows={transactionDetails || []}
      columns={transactionColumns}
      loading={loadDetails}
      height="auto"
      getRowId={generateId()}
      hideFooter
    />
  );
}

const PurchaseRequestInfo = () => {
  const { purchaseRequestId } = useParams();
  const [t] = useTranslation("global");
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const [actionType, setActionType] = useState(null);
  const [confirmModal, setConfirmModal] = useState(false);
  const [addModal, setAddModal] = useState(false);
  const [addItems, setAddItems] = useState(false);
  const [idToDelete, setIdToDelete] = useState(null);
  const [selectedItems, setSelectedItems] = useState([]);
  const [newRows, setNewRows] = useState([]);
  const [activeStep, setActiveStep] = useState(0);
  const [anchor, setAnchor] = useState(null);
  const [selectedId, setSelectedId] = useState(null);
  const [message, setMessage] = useState(null);
  const [applyRowClassName, setApplyRowClassName] = useState(false);

  const getDetailPanelContent = useCallback(
    ({ row }) => <DetailPanelContent row={row.StoreTransitionNo} />,
    []
  );
  const handleClick = (rowData, event = null) => {
    setSelectedId(rowData);
    setAnchor(anchor ? null : event ? event.currentTarget : null);
  };
  const open = Boolean(anchor);
  const id = open ? "simple-popup" : undefined;

  const getActionType = (modalType) => {
    switch (modalType) {
      case "confirmDeletePurchase":
        return "deletePurchase";
      case "confirmationRequest":
        return "request";
      case "confirmationApprove":
        return "approve";
      case "confirmationReject":
        return "reject";
      case "confirmationDeleteItem":
        return "deleteItem";
      default:
        return null;
    }
  };
  const handleOpenModal = (modalType, id) => {
    switch (modalType) {
      case "add":
        setAddModal(true);
        break;
      case "addItems":
        setAddItems(true);
        break;
      case "confirmDeletePurchase":
      case "confirmationRequest":
      case "confirmationApprove":
      case "confirmationReject":
      case "confirmationDeleteItem":
        setActionType(getActionType(modalType));
        setIdToDelete(id);
        setConfirmModal(true);
        break;
      default:
        break;
    }
  };
  const { data: purchaseRequestInfo, isLoading } = useQuery({
    queryKey: ["purchaseRequest", "info", purchaseRequestId],
    queryFn: async () => {
      const response = await Axios.get("/PurchaseRequests", {
        params: {
          SelectedNo: purchaseRequestId,
          State: "CardDetailed",
        },
      });
      return response.data;
    },
  });
  const items = newRows.map((item) => ({
    StoreItemsTemplateCode: item.StoreItemsTemplateCode,
    QTY: Number(item.Quantity),
  }));
  const handleCloseSave = () => {
    setAddItems(false);
    setNewRows([]);
    setSelectedItems([]);
    setActiveStep(0);
    setMessage(null);
    setApplyRowClassName(false);
  };
  const { mutateAsync: addPurchase, isPending } = useMutation({
    mutationFn: async () => {
      return Axios.put("/PurchaseRequests", {
        Items: items,
        State: "AddItems",
        SelectedNo: purchaseRequestId,
      });
    },
    onSuccess: (data) => {
      if (!data) return;
      handleCloseSave();
      enqueueSnackbar("Added Successfully", { variant: "success" });
      queryClient.invalidateQueries({ queryKey: ["purchaseRequest", "info"] });
    },
  });
  const { mutateAsync: handleDelete, isPending: pendingDelete } = useMutation({
    mutationFn: async () => {
      return Axios.patch("/PurchaseRequests", {
        SelectedNo: purchaseRequestId,
        State: "Delete",
      });
    },
    onSuccess: (data) => {
      if (!data) return;
      enqueueSnackbar("Material Purchase Deleted Successfully", {
        variant: "success",
      });
      navigate("/purchaserequests");
      queryClient.invalidateQueries({
        queryKey: ["purchaseRequests"],
      });
    },
  });
  const useQuotationMutation = (State, successMessage) => {
    const { mutateAsync, isPending } = useMutation({
      mutationFn: async () => {
        return Axios.patch("/PurchaseRequests", {
          SelectedNo: purchaseRequestId,
          State: State,
        });
      },
      onSuccess: (data) => {
        if (!data) return;
        enqueueSnackbar(successMessage, {
          variant: "success",
        });
        queryClient.invalidateQueries({
          queryKey: ["purchaseRequest", "info"],
        });
      },
    });

    return { mutateAsync, isPending };
  };
  const { mutateAsync: handleRequest, isPending: pendingRequest } =
    useQuotationMutation(
      "Request",
      "Material Purchase Request Requested Successfully"
    );
  const { mutateAsync: handleApprove, isPending: pendingApprove } =
    useQuotationMutation(
      "Approve",
      "Material Purchase Request Approved Successfully"
    );
  const { mutateAsync: handleReject, isPending: pendingReject } =
    useQuotationMutation("Reject", "Material Purchase Request Rejected");

  const { data: stores, isLoading: loadingStores } = useQuery({
    queryKey: ["purchaseRequest", "stores"],
    queryFn: async () => {
      const response = await Axios.get("/PurchaseRequests", {
        params: {
          SelectedNo: purchaseRequestId,
          State: "Add",
        },
      });
      return response.data.result;
    },
    enabled: addModal,
  });
  const { mutateAsync: handleDeleteItem, isPending: pendingDeleteItem } =
    useMutation({
      mutationFn: async () => {
        return Axios.put("/PurchaseRequests", {
          State: "DeletedItems",
          SelectedNo: purchaseRequestId,
          DeleteidItems: `${idToDelete}`,
        });
      },
      onSuccess: (data) => {
        if (!data) return;
        enqueueSnackbar(
          t("info_modules.missions.description.deleted_successfully"),
          {
            variant: "success",
          }
        );
        queryClient.invalidateQueries({
          queryKey: ["purchaseRequest", "info"],
        });
      },
    });

  const itemsColumns = [
    {
      field: "StoreItemsTemplateCode",
      headerName: t("data_grid.headers.item_code"),
      width: 120,
    },
    {
      field: "StoreItemsTemplateName",
      headerName: t("data_grid.headers.item_name"),
      width: 180,
      flex: 1,
    },
    {
      field: "StoreItemsTemplateUnit",
      headerName: t("data_grid.headers.unit"),
      width: 120,
    },
    {
      field: "MaterialsPurchaseRequestItemsQty",
      headerName: t("data_grid.headers.requested_qty"),
      width: 120,
    },
    {
      field: "MaterialsPurchaseRequestIssuedQty",
      headerName: t("data_grid.headers.added_qty"),
      width: 120,
    },
  ];
  if (purchaseRequestInfo?.Actions.includes("Edit Details")) {
    itemsColumns.push({
      field: "Actions",
      type: "actions",
      headerName: t("data_grid.headers.actions"),
      width: 110,
      getActions: (params) => [
        <GridActionsCellItem
          key={params.id}
          icon={<Edit />}
          label="Edit"
          onClick={(event) => handleClick(params.row, event)}
        />,
        <GridActionsCellItem
          key={params.id}
          icon={<DeleteIcon color="error" />}
          label="Delete"
          onClick={() =>
            handleOpenModal(
              "confirmationDeleteItem",
              params.row.idMaterialsPurchaseRequestItems
            )
          }
        />,
      ],
    });
  }
  const transactionColumns = [
    {
      field: "StoreTransitionNo",
      headerName: t("data_grid.headers.transaction_no"),
      width: 160,
    },
    {
      field: "StoreTransitionInvoiceNo",
      headerName: t("data_grid.headers.invoice_no"),
      width: 180,
      flex: 1,
    },
    {
      field: "CompanyStoresName",
      headerName: t("data_grid.headers.store_name"),
      width: 90,
      flex: 1,
    },
    {
      field: "ERPUserNickName",
      headerName: t("data_grid.headers.issuer"),
      width: 100,
    },
    {
      field: "StoreTransitionDate",
      headerName: t("data_grid.headers.transaction_date"),
      width: 120,
      renderCell: (params) => params.row.StoreTransitionDate.split("T")[0],
    },
    {
      field: "StoreTransitionType",
      headerName: t("data_grid.headers.transaction_type"),
      width: 130,
      renderCell: (params) => (
        <ChipStatus value={params.row.StoreTransitionType} />
      ),
    },
  ];
  const actionTypeMap = {
    deletePurchase: {
      onClick: handleDelete,
      description: t("info_modules.purchase_request.confirmation.delete"),
      isPending: pendingDelete,
    },
    request: {
      onClick: handleRequest,
      description: t("info_modules.purchase_request.confirmation.request"),
      isPending: pendingRequest,
    },
    approve: {
      onClick: handleApprove,
      description: t("info_modules.purchase_request.confirmation.approve"),
      isPending: pendingApprove,
    },
    reject: {
      onClick: handleReject,
      description: t("info_modules.purchase_request.confirmation.reject"),
      isPending: pendingReject,
    },
    deleteItem: {
      onClick: handleDeleteItem,
      description: t("info_modules.material_request.confirmation.delete_item"),
      isPending: pendingDeleteItem,
    },
  };

  return (
    <>
      {isLoading ? (
        <Spinner />
      ) : (
        <>
          <SimplePopup
            id={id}
            open={open}
            anchor={anchor}
            onClose={() => setAnchor(null)}
            content={
              <EditQuantity
                label={selectedId?.StoreItemsTemplateCode}
                setAnchor={setAnchor}
                quantity={selectedId?.MaterialsPurchaseRequestItemsQty}
                selectedId={selectedId}
              />
            }
          />
          <Box display="flex" flexDirection="column" gap={1.7} marginBottom={2}>
            <InfoCard
              title={t(
                "info_modules.purchase_request.description.purchase_request_details"
              )}
              deleteButton={purchaseRequestInfo?.Actions.includes("Delete")}
              onClickDelete={() => handleOpenModal("confirmDeletePurchase")}
              content={
                <PurchaseCard
                  purchaseRequestInfo={purchaseRequestInfo}
                  handleOpenModal={handleOpenModal}
                />
              }
            />
            <InfoCard
              title={t("info_modules.purchase_request.description.items")}
              addIcon={purchaseRequestInfo?.Actions.includes("Edit Details")}
              onClick={() => handleOpenModal("addItems")}
              content={
                <FormsDataGrid
                  rows={purchaseRequestInfo?.ItemsDetailed || []}
                  columns={itemsColumns}
                  getRowId={(row) => row.idMaterialsPurchaseRequestItems}
                  height={320}
                  toolbar
                  showExportButton
                />
              }
            />
            {purchaseRequestInfo?.Transitions.length > 0 && (
              <InfoCard
                title={t(
                  "info_modules.material_request.description.transaction"
                )}
                content={
                  <FormsDataGrid
                    rows={purchaseRequestInfo?.Transitions}
                    columns={transactionColumns}
                    height={320}
                    getRowId={generateId()}
                    getDetailPanelHeight={() => "auto"}
                    getDetailPanelContent={getDetailPanelContent}
                  />
                }
              />
            )}
          </Box>
          <ConfirmationInfoModal
            open={confirmModal}
            handleClose={() => setConfirmModal(false)}
            onClick={actionTypeMap[actionType]?.onClick}
            description={actionTypeMap[actionType]?.description}
            isPending={actionTypeMap[actionType]?.isPending}
            color="error"
          />
          <Add
            comboBoxData={stores}
            loading={loadingStores}
            open={addModal}
            handleClose={() => setAddModal(false)}
          />
          <AddMaterial
            open={addItems}
            materialRequestInfo={purchaseRequestInfo}
            handleClose={() => setAddItems(false)}
            newRows={newRows}
            setNewRows={setNewRows}
            selectedItems={selectedItems}
            setSelectedItems={setSelectedItems}
            activeStep={activeStep}
            setActiveStep={setActiveStep}
            addItems={addPurchase}
            isPending={isPending}
            handleCloseSave={handleCloseSave}
            message={message}
            setMessage={setMessage}
            setApplyRowClassName={setApplyRowClassName}
            applyRowClassName={applyRowClassName}
          />
        </>
      )}
    </>
  );
};

export default PurchaseRequestInfo;
