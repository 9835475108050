import { useState } from "react";
import { useTranslation } from "react-i18next";
import InfoCard from "../../../../components/InfoPagesCard/InfoCard";
import { Box, Tooltip } from "@mui/material";
import { GridActionsCellItem } from "@mui/x-data-grid";
import EditIcon from "@mui/icons-material/Edit";
import LoopIcon from "@mui/icons-material/Loop";
import DeleteIcon from "@mui/icons-material/Delete";
import AddItem from "../Modals/AddItem";
import FormsDataGrid from "../../../../components/DataGrid/FormsDataGrid";
import EditItem from "../Modals/EditItem";
import ReplaceItem from "../Modals/ReplaceItem";
import DeleteItem from "../Modals/DeleteItem";
import useModal from "../hooks/useModal";
import dayjs from "dayjs";

const PriceListTable = ({ rows, PriceListRefNo }) => {
  const [t] = useTranslation("global");

  const [itemId, setItemId] = useState(null);

  const columns = [
    { field: "PriceListSN", headerName: t("data_grid.headers.code") },
    {
      field: "Description",
      headerName: t("data_grid.headers.description"),
      flex: 1,
    },
    { field: "Unit", headerName: t("data_grid.headers.unit") },
    { field: "SI", headerName: t("data_grid.headers.s_i") },
    { field: "Price", headerName: t("data_grid.headers.unit_price") },
    {
      field: "CreationDate",
      headerName: t("data_grid.headers.creation_date"),
      renderCell: ({ value }) => {
        return value ? dayjs(value).format("YYYY/MM/DD") : "";
      },
    },
    { field: "CreatorName", headerName: t("data_grid.headers.created_by") },
    {
      field: "LastUpdateDate",
      headerName: t("data_grid.headers.last_update"),
      renderCell: ({ value }) => {
        return value ? dayjs(value).format("YYYY/MM/DD") : "";
      },
    },
    { field: "UpdatedName", headerName: t("data_grid.headers.updated_by") },
    {
      field: "Actions",
      headerName: t("data_grid.headers.actions"),
      type: "actions",
      getActions: (params) => [
        <Tooltip title={t("tooltip.edit")} key={params.row.id}>
          <GridActionsCellItem
            icon={<EditIcon />}
            label="Edit"
            onClick={() => {
              setItemId(params.row.idPriceList);
              openEditItemModal();
            }}
          />
        </Tooltip>,
        <Tooltip title={t("tooltip.replace_item")} key={params.row.id}>
          <GridActionsCellItem
            icon={<LoopIcon />}
            label="Replace"
            onClick={() => {
              setItemId(params.row.idPriceList);
              openReplaceItemModal();
            }}
          />
        </Tooltip>,
        <Tooltip title={t("tooltip.delete")} key={params.row.id}>
          <GridActionsCellItem
            icon={<DeleteIcon color="error" />}
            label="Delete"
            onClick={() => {
              setItemId(params.row.idPriceList);
              openDeleteItemModal();
            }}
          />
        </Tooltip>,
      ],
    },
  ];

  // Usage
  const [addItemModal, openAddItemModal, closeAddItemModal] = useModal();
  const [editItemModal, openEditItemModal, closeEditItemModal] = useModal();
  const [replaceItemModal, openReplaceItemModal, closeReplaceItemModal] =
    useModal();
  const [deleteItemModal, openDeleteItemModal, closeDeleteItemModal] =
    useModal();

  return (
    <>
      <Box display="flex" flexDirection="column" gap={1.7} marginBottom={2}>
        <InfoCard
          addIcon
          onClick={openAddItemModal}
          title={t("info_modules.price_list.description.items")}
          content={
            <Box>
              <FormsDataGrid
                height={400}
                rows={rows}
                columns={columns}
                getRowId={(row) => row.idPriceList}
                toolbar
                showExportButton
                showQuickFilter
              />
            </Box>
          }
        />
      </Box>
      <AddItem
        open={addItemModal}
        handleClose={closeAddItemModal}
        PriceListRefNo={PriceListRefNo}
      />
      <EditItem
        open={editItemModal}
        handleClose={closeEditItemModal}
        itemId={itemId}
      />
      <ReplaceItem
        handleClose={closeReplaceItemModal}
        open={replaceItemModal}
        itemId={itemId}
        PriceListRefNo={PriceListRefNo}
      />
      <DeleteItem
        open={deleteItemModal}
        handleClose={closeDeleteItemModal}
        itemId={itemId}
      />
    </>
  );
};

export default PriceListTable;
