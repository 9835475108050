import { useQuery } from "@tanstack/react-query";
import { useParams } from "react-router-dom";
import { employeeRecordsService } from "../../../api/employeeRecords";
import { Box, Stack } from "@mui/material";
import Spinner from "../../../components/Spinners/Spinner";
import EmployeeInfoGlimpse from "./cards/EmployeeInfoGlimpse";
import AttendanceChart from "./cards/Attendance/Attendance";
import VacationChart from "./cards/Vacations/Vacations";
import EmployeeDetails from "./cards/EmployeeDetails/EmployeeDetails";
import NationalID from "./cards/NationalID/NationalID";
import SocialInsurance from "./cards/SocialInsurance/SocialInsurance";
import MedicalInsurance from "./cards/MedicalInsurance/MedicalInsurance";
import Documents from "./cards/Documents/Documents";
import CompanyLines from "./cards/CompanyLines/CompanyLines";
import Cars from "./cards/Cars/Cars";
import DrivingLicense from "./cards/DrivingLicense/DrivingLicense";

const EmployeeInfo = () => {
  const { empCode } = useParams();

  const { data, isLoading } = useQuery({
    queryKey: ["employeeInfo", empCode],
    queryFn: () => employeeRecordsService.GetEmployeeInfo(empCode),
  });

  if (isLoading) return <Spinner />;

  const {
    EmpInfo,
    EmpAnalyticsInfo,
    EmpVactionsInfo,
    NationalID: NationalIDData,
    SocialInsurance: SocialInsuranceData,
    MedicalInsurance: MedicalInsuranceData,
    EmpInfoDocument,
    CompanyPhoneLines,
    CarsInfo,
    DrivingLicense: DrivingLicenseData,
  } = data;

  return (
    <Stack spacing={2}>
      <Box
        sx={{
          display: "grid",
          gridTemplateColumns: "1fr 2fr 1fr",
          gap: "1rem",
        }}
      >
        <EmployeeInfoGlimpse data={EmpInfo[0]} />
        <AttendanceChart data={EmpAnalyticsInfo} />
        <VacationChart data={EmpVactionsInfo} empName={EmpInfo[0].EmpName} />
      </Box>
      <EmployeeDetails data={EmpInfo[0]} />
      <NationalID data={NationalIDData[0]} />
      {(EmpInfo[0].EmpInsurance === "Insured" ||
        EmpInfo[0].EmpMedical === "Covered") && (
        <Box
          sx={{
            display: "grid",
            gridTemplateColumns:
              EmpInfo[0].EmpInsurance === "Insured" &&
              EmpInfo[0].EmpMedical === "Covered"
                ? "1fr 1fr"
                : "1fr",
            gap: "1rem",
          }}
        >
          {EmpInfo[0].EmpInsurance === "Insured" && (
            <SocialInsurance data={SocialInsuranceData[0]} />
          )}
          {EmpInfo[0].EmpMedical === "Covered" && (
            <MedicalInsurance data={MedicalInsuranceData[0]} />
          )}
        </Box>
      )}

      {EmpInfo[0].EmpInfoDrive === "True" && (
        <DrivingLicense
          data={DrivingLicenseData[0]}
          nationalIdData={NationalIDData[0]}
        />
      )}

      <Documents data={EmpInfoDocument} />

      {CompanyPhoneLines.length > 0 && (
        <CompanyLines data={CompanyPhoneLines} />
      )}
      {CarsInfo.length > 0 && <Cars data={CarsInfo} />}
    </Stack>
  );
};

export default EmployeeInfo;
