import dayjs from "dayjs";
import { useSelector } from "react-redux";
import { useQuery } from "@tanstack/react-query";
import CustomDataGrid from "../components/DataGrid/CustomDataGrid";
import { useTranslation } from "react-i18next";
import Spinner from "../components/Spinners/Spinner";
import useOpenNewForm from "../hooks/useOpenNewForm";
import { DeliveryNoteItemsService } from "../api/deliveryNoteItems";
import { useConfirmationDialog } from "../contexts/ConfirmationDialogContext";

const DeliveryNotesItems = () => {
  const { newForm } = useSelector((state) => state.theme);

  // Handling new form logic
  const { handleOpen } = useOpenNewForm();
  const { setOpen } = useConfirmationDialog();

  const [t] = useTranslation("global");

  const columns = [
    {
      field: "DeliveryNoteItemOracle",
      headerName: t("data_grid.headers.oracle_code"),
      width: 250,
    },
    {
      field: "DeliveryNoteItemDescription",
      headerName: t("data_grid.headers.description"),
      flex: 1,
    },
    {
      field: "DeliveryNoteItemUnit",
      headerName: t("data_grid.headers.unit"),
    },
    {
      field: "ERPUserNickName",
      headerName: t("data_grid.headers.created_by"),
      width: 160,
    },
    {
      field: "ActionDate",
      headerName: t("data_grid.headers.creation_date"),
      type: "date",
      valueGetter: ({ value }) => (value ? new Date(value) : null),
      renderCell: (params) =>
        params.value === null ? "" : dayjs(params.value).format("YYYY/MM/DD"),
    },
    {
      field: "LastUpdateDate",
      headerName: t("data_grid.headers.last_update"),
      type: "date",
      valueGetter: ({ value }) => (value ? new Date(value) : null),
      renderCell: (params) =>
        params.value === null ? "" : dayjs(params.value).format("YYYY/MM/DD"),
    },
  ];
  const {
    data: rows,
    isLoading,
    refetch,
    isRefetching,
  } = useQuery({
    queryKey: ["deliveryNoteItems"],
    queryFn: DeliveryNoteItemsService.GetDeliveryNoteItems,
  });

  if (isLoading) {
    return <Spinner />;
  }

  return (
    <CustomDataGrid
      rows={rows}
      columns={columns}
      loading={isLoading}
      getRowId={(row) => row.idDeliveryNoteItems}
      showReloadOverlay={isRefetching}
      reloadFunction={refetch}
      disableStatusTabs
      newButton
      newFunction={() => {
        if (newForm === "delivery_note_items" || newForm === null) {
          handleOpen("delivery_note_items");
        } else {
          setOpen(t("modules.new_form_confirmation_message"), () => () => {
            handleOpen("delivery_note_items");
          });
        }
      }}
    />
  );
};

export default DeliveryNotesItems;
