import { useState } from "react";
import dayjs from "dayjs";
import DateFormatGrid from "../../../components/DateFormatGrid";
import { useQuery, useMutation, useQueryClient } from "@tanstack/react-query";
import { useParams, useNavigate } from "react-router-dom";
import Axios from "../../../network/Axios";
import { Chip, Box } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useSnackbar } from "notistack";
import InfoCard from "../../../components/InfoPagesCard/InfoCard";
import Spinner from "../../../components/Spinners/Spinner";
import MaterialRequestCard from "./InfoModals/MaterialRequestCard";
import { GridActionsCellItem } from "@mui/x-data-grid-pro";
import DeleteIcon from "@mui/icons-material/Delete";
import Edit from "@mui/icons-material/Edit";
import UseAsTemplate from "./InfoModals/UseAsTemplate";
import Issued from "./InfoModals/Issued/Issued";
import AddMaterial from "./InfoModals/AddMaterial";
import ConfirmationInfoModal from "../../../components/Modals/ConfirmationInfoModal";
import FormsDataGrid from "../../../components/DataGrid/FormsDataGrid";
import SimplePopup from "../../../components/PopUp/PopUp";
import EditQuantity from "./InfoModals/EditQuantity";
import ChipStatus from "../../../components/ChipStatus";
import { generateId } from "../../../utils/globalFunctions";
import { DetailPanelContent } from "../PurchaseRequest/PurchaseRequestInfo";
import ChangeRequestForm from "../../../components/forms/ChangeRequestForm";

const MaterialRequestInfo = () => {
  const { materialRequestId } = useParams();
  const queryClient = useQueryClient();
  const [t] = useTranslation("global");
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const [actionType, setActionType] = useState(null);
  const [confirmModal, setConfirmModal] = useState(false);
  const [useAsTemplateModal, setUseAsTemplateModal] = useState(false);
  const [changeRequestModal, setChangeRequestModal] = useState(false);
  const [idToDelete, setIdToDelete] = useState(null);
  const [addItems, setAddItems] = useState(false);
  const [action, setAction] = useState(null);
  const [returnOrIssued, setReturnOrIssued] = useState(false);
  const [anchor, setAnchor] = useState(null);
  const [selectedId, setSelectedId] = useState(null);
  const [selectedItems, setSelectedItems] = useState([]);
  const [newRows, setNewRows] = useState([]);
  const [activeStep, setActiveStep] = useState(0);
  const [message, setMessage] = useState(null);
  const [applyRowClassName, setApplyRowClassName] = useState(false);

  const getDetailPanelContent = ({ row }) => (
    <DetailPanelContent row={row.StoreTransitionNo} />
  );

  const handleClick = (rowData, event = null) => {
    setSelectedId(rowData);
    setAnchor(anchor ? null : event ? event.currentTarget : null);
  };
  const open = Boolean(anchor);
  const id = open ? "simple-popup" : undefined;

  const getActionType = (modalType) => {
    switch (modalType) {
      case "confirmDeleteQuotation":
        return "deleteQuotation";
      case "confirmationRequest":
        return "request";
      case "confirmationCancel":
        return "cancel";
      case "confirmationDeleteItem":
        return "deleteItem";
      default:
        return null;
    }
  };
  const handleOpenModal = (modalType, id) => {
    switch (modalType) {
      case "useAsTemplate":
        setUseAsTemplateModal(true);
        break;
      case "changeRequest":
        setChangeRequestModal(true);
        break;
      case "issued":
        setAction("issued");
        setReturnOrIssued(true);
        break;
      case "return":
        setAction("return");
        setReturnOrIssued(true);
        break;
      case "addItems":
        setAddItems(true);
        break;
      case "confirmDeleteQuotation":
      case "confirmationRequest":
      case "confirmationCancel":
      case "confirmationDeleteItem":
        setActionType(getActionType(modalType));
        setIdToDelete(id);
        setConfirmModal(true);
        break;
      default:
        break;
    }
  };
  const { data: materialRequestInfo, isLoading } = useQuery({
    queryKey: ["materialRequestInfo", materialRequestId],
    queryFn: async () => {
      const response = await Axios.get("/MaterialRequests", {
        params: {
          SelectedNo: materialRequestId,
          State: "CardDetailed",
        },
      });
      return response.data;
    },
  });
  const items = newRows.map((item) => ({
    StoreItemsTemplateCode: item.StoreItemsTemplateCode,
    QTY: Number(item.Quantity),
  }));
  const handleCloseSave = () => {
    setAddItems(false);
    setNewRows([]);
    setSelectedItems([]);
    setActiveStep(0);
    setMessage(null);
    setApplyRowClassName(false);
  };
  const { mutateAsync: addNewMaterialRequest, isPending } = useMutation({
    mutationFn: async () => {
      return Axios.put("/MaterialRequests", {
        Items: items,
        State: "AddItems",
        SelectedNo: materialRequestId,
      });
    },
    onSuccess: (data) => {
      if (!data) return;
      handleCloseSave();
      enqueueSnackbar("Added Successfully", { variant: "success" });
      queryClient.invalidateQueries({ queryKey: ["materialRequestInfo"] });
    },
  });

  const { mutateAsync: handleDelete, isPending: pendingDelete } = useMutation({
    mutationFn: async () => {
      return Axios.patch("/MaterialRequests", {
        SelectedNo: materialRequestId,
        State: "Delete",
      });
    },
    onSuccess: (data) => {
      if (!data) return;
      enqueueSnackbar("Material Request Deleted Successfully", {
        variant: "success",
      });
      navigate("/materialrequests");
      queryClient.invalidateQueries({
        queryKey: ["materialRequests"],
      });
    },
  });
  const { data: issuedStores, isLoading: loadingStores } = useQuery({
    queryKey: ["materialRequest", "stores"],
    queryFn: async () => {
      const response = await Axios.get("/MaterialRequests", {
        params: {
          SelectedNo: materialRequestId,
          State: "IssueReturn",
        },
      });
      return response.data.result;
    },
    enabled: action === "issued" || action === "return",
  });
  const { mutateAsync: handleRequest, isPending: pendingRequest } = useMutation(
    {
      mutationFn: async () => {
        return Axios.patch("/MaterialRequests", {
          SelectedNo: materialRequestId,
          State: "Request",
        });
      },
      onSuccess: (data) => {
        if (!data) return;
        enqueueSnackbar("Material Requested Successfully", {
          variant: "success",
        });
        queryClient.invalidateQueries({
          queryKey: ["materialRequestInfo"],
        });
      },
    }
  );
  const { mutateAsync: handleCancel, isPending: pendingCancel } = useMutation({
    mutationFn: async () => {
      return Axios.patch("/MaterialRequests", {
        SelectedNo: materialRequestId,
        State: "Cancel",
      });
    },
    onSuccess: (data) => {
      if (!data) return;
      enqueueSnackbar("Material Request Cancelled", {
        variant: "success",
      });
      queryClient.invalidateQueries({
        queryKey: ["materialRequestInfo"],
      });
    },
  });
  const { mutateAsync: handleDeleteItem, isPending: pendingDeleteItem } =
    useMutation({
      mutationFn: async () => {
        return Axios.put("/MaterialRequests", {
          State: "DeletedItems",
          SelectedNo: materialRequestId,
          DeleteidItems: `${idToDelete}`,
        });
      },
      onSuccess: (data) => {
        if (!data) return;
        enqueueSnackbar(
          t("info_modules.missions.description.deleted_successfully"),
          {
            variant: "success",
          }
        );
        queryClient.invalidateQueries({
          queryKey: ["materialRequestInfo"],
        });
      },
    });

  const { mutateAsync: handleView } = useMutation({
    mutationFn: async () => {
      const response = await Axios.get("/MaterialRequests", {
        params: { SelectedNo: materialRequestId, State: "View" },
      });
      return response.data;
    },
    onSuccess: (data) => {
      if (!data) {
        enqueueSnackbar("No email body found.", { variant: "error" });
        return;
      }
      let responseHtml = data.MailBody;
      //open the new window and write your HTML to it
      let myWindow = window.open("Helllo", "response", "resizable=yes");
      myWindow.document.write(`
      <html>
        <head>
          <title>Email Body</title>
          <style>
            body {
              margin: 0;
              padding-left: 10px;
              overflow: hidden;
            }
            .scrollable-content {
              height: 100%;
              overflow-y: auto;
            }
          </style>
        </head>
        <body>
          <div class="scrollable-content">${responseHtml}</div>
        </body>
      </html>
    `);
    },
  });
  const actionTypeMap = {
    deleteQuotation: {
      onClick: handleDelete,
      description: t("info_modules.material_request.confirmation.delete"),
      isPending: pendingDelete,
    },
    request: {
      onClick: handleRequest,
      description: t("info_modules.material_request.confirmation.request"),
      isPending: pendingRequest,
    },
    cancel: {
      onClick: handleCancel,
      description: t("info_modules.material_request.confirmation.cancel"),
      isPending: pendingCancel,
    },
    deleteItem: {
      onClick: handleDeleteItem,
      description: t("info_modules.material_request.confirmation.delete_item"),
      isPending: pendingDeleteItem,
    },
  };
  const itemsColumns = [
    {
      field: "StoreItemsTemplateCode",
      headerName: t("data_grid.headers.item_code"),
      width: 100,
    },
    {
      field: "StoreItemsTemplateName",
      headerName: t("data_grid.headers.item_description"),
      width: 180,
      flex: 1,
    },
    {
      field: "StoreItemsTemplateUnit",
      headerName: t("data_grid.headers.unit"),
      width: 90,
    },
    {
      field: "MaterialsRequestQty",
      headerName: t("data_grid.headers.requested_qty"),
      width: 100,
    },
    {
      field: "TotalIssued",
      headerName: t("data_grid.headers.issued_qty"),
      width: 100,
    },
    {
      field: "TotalReturn",
      headerName: t("data_grid.headers.returned_qty"),
      width: 100,
    },
    {
      field: "IssuedQty",
      headerName: t("data_grid.headers.total_issued"),
      width: 100,
      renderCell: (params) =>
        params.row.IssuedQty === null ? (
          ""
        ) : (
          <Chip
            label={params.value}
            sx={{ color: "white", background: "#4361ee" }}
            size="small"
          />
        ),
    },
    {
      field: "OtheComment",
      headerName: t("data_grid.headers.comments"),
      width: 120,
    },
    {
      field: "LastIssueOn",
      headerName: t("data_grid.headers.last_issue_date"),
      width: 160,
      renderCell: ({ value }) => {
        return <> {value ? <DateFormatGrid value={value} /> : <> </>}</>;
      },
    },
  ];
  if (materialRequestInfo?.Actions.includes("Edit")) {
    itemsColumns.push({
      field: "Actions",
      type: "actions",
      headerName: t("data_grid.headers.actions"),
      width: 110,
      getActions: (params) => [
        <GridActionsCellItem
          key={params.id}
          icon={<Edit />}
          label="Edit"
          onClick={(event) => handleClick(params.row, event)}
        />,
        <GridActionsCellItem
          key={params.id}
          icon={<DeleteIcon color="error" />}
          label="Delete"
          onClick={() =>
            handleOpenModal(
              "confirmationDeleteItem",
              params.row.idMaterialsRequestTemplates
            )
          }
        />,
      ],
    });
  }
  const transactionColumns = [
    {
      field: "StoreTransitionNo",
      headerName: t("data_grid.headers.transaction_no"),
      width: 160,
    },
    {
      field: "StoreTransitionInvoiceNo",
      headerName: t("data_grid.headers.invoice_no"),
      width: 180,
      flex: 1,
    },
    {
      field: "CompanyStoresName",
      headerName: t("data_grid.headers.store_name"),
      width: 90,
      flex: 1,
    },
    {
      field: "ERPUserNickName",
      headerName: t("data_grid.headers.issuer"),
      width: 140,
      flex: 1,
    },
    {
      field: "StoreTransitionDate",
      headerName: t("data_grid.headers.transaction_date"),
      width: 150,
      renderCell: ({ value }) => {
        return value ? dayjs(value).format("YYYY/MM/DD") : "";
      },
    },
    {
      field: "StoreTransitionType",
      headerName: t("data_grid.headers.transaction_type"),
      width: 130,
      renderCell: (params) => (
        <ChipStatus value={params.row.StoreTransitionType} />
      ),
    },
  ];

  return (
    <>
      {isLoading ? (
        <Spinner />
      ) : (
        <>
          <SimplePopup
            id={id}
            open={open}
            anchor={anchor}
            onClose={() => setAnchor(null)}
            content={
              <EditQuantity
                label={selectedId?.StoreItemsTemplateCode}
                setAnchor={setAnchor}
                quantity={selectedId?.MaterialsRequestQty}
                selectedId={selectedId}
              />
            }
          />
          <Box display="flex" flexDirection="column" gap={1.7} marginBottom={2}>
            <InfoCard
              title={t(
                "info_modules.material_request.description.material_request_details"
              )}
              deleteButton={materialRequestInfo?.Actions.includes("Delete")}
              viewButton={materialRequestInfo?.Actions.includes("View")}
              onClickDelete={() => handleOpenModal("confirmDeleteQuotation")}
              onClickView={handleView}
              content={
                <MaterialRequestCard
                  materialRequestInfo={materialRequestInfo}
                  handleOpenModal={handleOpenModal}
                />
              }
            />
            <InfoCard
              title={t(
                "info_modules.material_request.description.requested_material"
              )}
              addIcon={materialRequestInfo?.Actions.includes("Edit")}
              onClick={() => handleOpenModal("addItems")}
              content={
                <FormsDataGrid
                  rows={materialRequestInfo?.ItemsDetailed}
                  columns={itemsColumns}
                  height={290}
                  getRowId={(row) => row.idMaterialsRequestTemplates}
                />
              }
            />
            {materialRequestInfo?.Transitions.length > 0 && (
              <InfoCard
                title={t(
                  "info_modules.material_request.description.transaction"
                )}
                content={
                  <FormsDataGrid
                    rows={materialRequestInfo?.Transitions}
                    columns={transactionColumns}
                    height={320}
                    getRowId={generateId()}
                    getDetailPanelHeight={() => "auto"}
                    getDetailPanelContent={getDetailPanelContent}
                  />
                }
              />
            )}
          </Box>
          <ConfirmationInfoModal
            open={confirmModal}
            handleClose={() => setConfirmModal(false)}
            onClick={actionTypeMap[actionType]?.onClick}
            description={actionTypeMap[actionType]?.description}
            isPending={actionTypeMap[actionType]?.isPending}
            color="error"
          />
          <UseAsTemplate
            open={useAsTemplateModal}
            handleClose={() => setUseAsTemplateModal(false)}
            materialRequestInfo={materialRequestInfo}
          />
          <ChangeRequestForm
            open={changeRequestModal}
            handleClose={() => setChangeRequestModal(false)}
            requestId={materialRequestId}
            apiEndpoint={"/MaterialChangeRequests"}
            radioBtnLabel1={t(
              "info_modules.material_request.description.edit_material"
            )}
            radioBtnLabel2={t(
              "info_modules.material_request.description.cancel_material"
            )}
            queryKey={["materialRequestInfo"]}
            titleTranslationKey={t(
              "info_modules.material_request.description.material_change_description"
            )}
            successMessage={"Material Change Requested Successfully"}
            width="35rem"
          />
          <Issued
            open={returnOrIssued}
            handleClose={() => setReturnOrIssued(false)}
            loading={loadingStores}
            comboBoxData={issuedStores}
            action={action}
          />
          <AddMaterial
            open={addItems}
            materialRequestInfo={materialRequestInfo}
            newRows={newRows}
            setNewRows={setNewRows}
            selectedItems={selectedItems}
            setSelectedItems={setSelectedItems}
            activeStep={activeStep}
            setActiveStep={setActiveStep}
            addItems={addNewMaterialRequest}
            isPending={isPending}
            handleCloseSave={handleCloseSave}
            message={message}
            setMessage={setMessage}
            setApplyRowClassName={setApplyRowClassName}
            applyRowClassName={applyRowClassName}
            title={t("info_modules.material_request.description.add_material")}
          />
        </>
      )}
    </>
  );
};
export default MaterialRequestInfo;
