import Axios from "../network/Axios";

const ENDPOINT = "/WarrantyVisit";

const addNewWarrantyVisit = async (data) => {
  const response = await Axios.post(ENDPOINT, data);
  return response.data;
};

export const WarrantyVisitService = {
  addNewWarrantyVisit,
};
