import { useState } from "react";
import { Box, TextField, Chip, Autocomplete } from "@mui/material";
import { useTranslation } from "react-i18next";
import ButtonGroup from "../../../../components/forms/ButtonGroup/ButtonGroup";
import { useMutation, useQueryClient, useQuery } from "@tanstack/react-query";
import { useParams } from "react-router-dom";
import Axios from "../../../../network/Axios";
import { useSnackbar } from "notistack";
import InfoModal from "../../../../components/Modals/InfoModal";
import FormSpinner from "../../../../components/Spinners/FormSpinner";

const AssignToStore = ({ open, handleClose }) => {
  const { t } = useTranslation("global");
  const queryClient = useQueryClient();
  const { inventoryManagementId } = useParams();
  const { enqueueSnackbar } = useSnackbar();
  const [toStore, setToStore] = useState("");

  const { data: stores, isLoading } = useQuery({
    queryKey: ["inventory", "stores"],
    queryFn: async () => {
      const response = await Axios.get("/InventoryManagement", {
        params: {
          State: "ItemStoreTransafer",
        },
      });
      return response.data.CompanyStoresName;
    },
    enabled: open,
  });

  const { mutateAsync: assign, isPending } = useMutation({
    mutationFn: async () => {
      return Axios.patch("/InventoryManagement", {
        SelectedNo: inventoryManagementId,
        State: "Assign",
        ToStore: toStore,
      });
    },
    onSuccess: (data) => {
      if (!data) return;
      enqueueSnackbar(t("Assigned Successfully"), {
        variant: "success",
      });
      queryClient.invalidateQueries({
        queryKey: ["inventory", "info"],
      });
      handleClose();
      setToStore("");
    },
  });

  return (
    <InfoModal
      open={open}
      handleClose={handleClose}
      title={t("info_modules.inventory_management.description.assign_item")}
      width="28rem"
      content={
        <Box padding={1} display="flex" flexDirection="column" gap={1.2}>
          {isLoading ? (
            <Box paddingBottom="60px">
              <FormSpinner />
            </Box>
          ) : (
            <>
              <Chip
                label={inventoryManagementId}
                sx={{ borderRadius: 1, fontSize: "0.9rem" }}
              />
              <Autocomplete
                id="combo-box-demo"
                options={stores || []}
                getOptionLabel={(option) => option.CompanyStoresName}
                sx={{ width: "27rem" }}
                size="small"
                onChange={(event, value) => {
                  setToStore(value ? value.CompanyStoresName : "");
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label={t(
                      "info_modules.inventory_management.description.store"
                    )}
                  />
                )}
              />
              <ButtonGroup
                onClickClose={handleClose}
                isPending={isPending}
                onClickSave={assign}
                saveLabel={t("modules.buttons.save")}
              />
            </>
          )}
        </Box>
      }
    />
  );
};

export default AssignToStore;
