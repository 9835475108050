import * as yup from "yup";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { useSnackbar } from "notistack";
import { useTranslation } from "react-i18next";
import { Box, Stack, TextField, Typography } from "@mui/material";
import { useParams } from "react-router-dom";
import { employeeRecordsService } from "../../../../../api/employeeRecords";
import ButtonGroup from "../../../../../components/forms/ButtonGroup/ButtonGroup";
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { useState } from "react";
import { convertImage } from "../../../../../utils/utils";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFnsV3";
import dayjs from "dayjs";
import {
  MAX_FILE_SIZE,
  supportedImageTypes,
} from "../../../../../constants/constants";
import FormSpinner from "../../../../../components/Spinners/FormSpinner";
import ImagePickerBox from "../../components/ImagePickerBox";

const EditForm = ({ handleClose }) => {
  const { empCode } = useParams();

  const { data, isLoading, isRefetching } = useQuery({
    queryKey: ["employeeSocialInsurance", empCode],
    queryFn: () =>
      employeeRecordsService.getEmployeeSocialInsuranceData(empCode),
  });

  if (isLoading || isRefetching) return <FormSpinner />;

  return <Form data={data.SocialInsurance[0]} handleClose={handleClose} />;
};

const Form = ({ data, handleClose }) => {
  const [t] = useTranslation("global");

  const {
    EmpInfoSocialInsuranceNo,
    EmpInfoSocialInsuranceStartDate,
    EmpInfoSocialInsuranceDocument,
  } = data || {
    EmpInfoSocialInsuranceNo: "",
    EmpInfoSocialInsuranceStartDate: dayjs().format("YYYY-MM-DD"),
    EmpInfoSocialInsuranceDocument: null,
  };

  const initialValues = {
    EmpInfoSocialInsuranceNo,
    EmpInfoSocialInsuranceStartDate,
    EmpInfoSocialInsuranceDocument: null,
  };

  const { empCode } = useParams();

  const { enqueueSnackbar } = useSnackbar();
  const queryClient = useQueryClient();

  const schema = yup.object().shape({
    EmpInfoSocialInsuranceNo: yup
      .string()
      .required(t("modules.global_schema.required")),
    EmpInfoSocialInsuranceStartDate: yup
      .date()
      .required(t("modules.global_schema.required")),
    EmpInfoSocialInsuranceDocument: yup
      .mixed()
      .test("fileSize", t("modules.global_schema.file_size"), (value) => {
        return !value || (value && value.size <= MAX_FILE_SIZE);
      })
      .test(
        "fileType",
        t("modules.global_schema.file_type"),
        (value) => !value || (value && supportedImageTypes.includes(value.type))
      )
      .nullable(),
  });

  const handleFormSubmit = (values) => {
    const {
      EmpInfoSocialInsuranceNo,
      EmpInfoSocialInsuranceStartDate,
      EmpInfoSocialInsuranceDocument,
    } = values;

    if (
      !convertedImage &&
      getValues().EmpInfoSocialInsuranceDocument === null
    ) {
      return setError("EmpInfoSocialInsuranceDocument", {
        message: t("modules.global_schema.required"),
        type: "required",
      });
    }

    const formData = new FormData();

    formData.append("SelectedNo", empCode);
    formData.append("State", "SocialInsurance");
    formData.append("EmpInfoSocialInsuranceNo", EmpInfoSocialInsuranceNo);
    formData.append(
      "EmpInfoSocialInsuranceStartDate",
      dayjs(EmpInfoSocialInsuranceStartDate).format("YYYY-MM-DD")
    );
    formData.append(
      "EmpInfoSocialInsuranceDocument",
      EmpInfoSocialInsuranceDocument
    );
    formData.append(
      "ImageState",
      EmpInfoSocialInsuranceDocument ? "Exist" : "NotExist"
    );
    updateSocialInsurance(formData);
  };

  const { mutateAsync: updateSocialInsurance, isPending } = useMutation({
    mutationFn: employeeRecordsService.addNewEmployeeDocument,
    onSuccess: (data) => {
      if (!data) return;
      handleClose();
      enqueueSnackbar(data.msg, {
        variant: "success",
      });
      queryClient.invalidateQueries(["employeeInfo"]);
    },
  });

  const {
    register,
    handleSubmit,
    setValue,
    getValues,
    trigger,
    control,
    setError,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
    mode: "onChange",
    defaultValues: initialValues,
  });

  const convertedImage =
    EmpInfoSocialInsuranceDocument &&
    convertImage(EmpInfoSocialInsuranceDocument.data);

  // Image handling logic
  const [image, setImage] = useState(null);

  const handleImageChange = (event) => {
    if (!event.currentTarget.files[0]) return;
    const file = event.currentTarget.files[0];
    setValue("EmpInfoSocialInsuranceDocument", file);
    trigger("EmpInfoSocialInsuranceDocument"); // Trigger validation
    setImage(URL.createObjectURL(file));
  };

  return (
    <Box
      component="form"
      onSubmit={handleSubmit(handleFormSubmit)}
      noValidate
      sx={{
        padding: "1rem",
      }}
    >
      <Box
        sx={{
          display: "grid",
          gridTemplateColumns: "1fr 1fr",
          gap: "1rem",
        }}
      >
        <Stack spacing={2}>
          <Typography>
            {t(
              "info_modules.employee_records.cards.social_insurance.edit_form.labels.information"
            )}
          </Typography>
          <TextField
            size="small"
            {...register("EmpInfoSocialInsuranceNo")}
            error={Boolean(errors.EmpInfoSocialInsuranceNo?.message)}
            helperText={errors.EmpInfoSocialInsuranceNo?.message}
            label={t(
              "info_modules.employee_records.cards.social_insurance.edit_form.fields.insurance_no"
            )}
            autoFocus
          />
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <Controller
              control={control}
              name="EmpInfoSocialInsuranceStartDate"
              render={({ field }) => {
                return (
                  <DatePicker
                    label={t(
                      "info_modules.employee_records.cards.social_insurance.edit_form.fields.insurance_start_date"
                    )}
                    views={["year", "month", "day"]}
                    name="EmpInfoSocialInsuranceStartDate"
                    value={field.value}
                    inputRef={field.ref}
                    onChange={(date) => {
                      field.onChange(date);
                    }}
                    slots={{
                      textField: TextField,
                    }}
                    slotProps={{
                      textField: {
                        size: "small",
                        error: Boolean(
                          errors.EmpInfoSocialInsuranceStartDate?.message
                        ),
                        label: t(
                          "info_modules.employee_records.cards.social_insurance.edit_form.fields.insurance_start_date"
                        ),
                      },
                    }}
                  />
                );
              }}
            />
          </LocalizationProvider>
        </Stack>
        <Stack spacing={1}>
          <Typography
            sx={{
              fontSize: "14px",
              fontStyle: "italic",
            }}
          >
            *
            {t(
              "info_modules.employee_records.cards.social_insurance.edit_form.labels.document"
            )}
          </Typography>
          <ImagePickerBox
            error={errors.EmpInfoSocialInsuranceDocument}
            image={image || convertedImage}
            handleImage={handleImageChange}
            control={control}
            name="EmpInfoSocialInsuranceDocument"
          />
        </Stack>
      </Box>
      <ButtonGroup
        saveLabel={t("modules.buttons.save")}
        isPending={isPending}
        onClickClose={handleClose}
      />
    </Box>
  );
};

export default EditForm;
