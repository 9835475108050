import { useState } from "react";
import { useTranslation } from "react-i18next";
import {
  Stepper,
  Step,
  StepLabel,
  Box,
  FormControlLabel,
  Checkbox,
} from "@mui/material";
import InfoModal from "../../../../components/Modals/InfoModal";
import SelectWorkOrder from "../../../../components/forms/MaterialRequests/Steps/SelectWorkOrder";
import Review from "../../../../components/forms/MaterialRequests/Steps/Review";
import StepperButton from "../../../../components/forms/ButtonGroup/StepperButton";
import FormSpinner from "../../../../components/Spinners/FormSpinner";
import { useQuery, useMutation, useQueryClient } from "@tanstack/react-query";
import { useSnackbar } from "notistack";
import Axios from "../../../../network/Axios";
import { useParams } from "react-router-dom";

const UseAsTemplate = ({ open, handleClose }) => {
  const [t] = useTranslation("global");
  const { materialRequestId } = useParams();
  const queryClient = useQueryClient();
  const { enqueueSnackbar } = useSnackbar();
  const [activeStep, setActiveStep] = useState(0);
  const [isRowSelected, setIsRowSelected] = useState(false);
  const [selectedWorkOrders, setSelectedWorkOrders] = useState([]);
  const [newWorkOrder, setNewWorkOrder] = useState([]);
  const [checked, setChecked] = useState(false);

  const { data: workOrderData, isLoading: workOrderIsLoading } = useQuery({
    queryKey: ["materialRequests", "workOrder"],
    queryFn: async () => {
      const response = await Axios.get("/WorkOrders", {
        params: {
          State: "MaterialRequests",
        },
      });
      return response.data.result;
    },
    enabled: open,
  });
  const { data: itemsData, isLoading: itemsIsLoading } = useQuery({
    queryKey: ["materialRequests", "items"],
    queryFn: async () => {
      const response = await Axios.get("/MaterialRequests", {
        params: {
          State: "Edit",
          SelectedNo: materialRequestId,
        },
      });
      return response.data.result;
    },
    enabled: open,
  });
  const workOrders = newWorkOrder.map((order) => ({
    WorkOrderNo: order.WorkOrderNo,
  }));
  const { mutateAsync: addNewMaterialRequest, isPending } = useMutation({
    mutationFn: async () => {
      return Axios.post("/MaterialRequests", {
        Flag: checked,
        SelectedNo: materialRequestId,
        State: "UseAsTemplate",
        WorkOrders: workOrders,
      });
    },
    onSuccess: (data) => {
      if (!data) return;
      handleCloseSave();
      enqueueSnackbar("Material Request Created Successfully", {
        variant: "success",
      });
      queryClient.invalidateQueries({ queryKey: ["materialRequestInfo"] });
    },
  });
  const handleNext = async () => {
    if (activeStep === 0) {
      const selectedData = workOrderData.filter((row) =>
        selectedWorkOrders.includes(row.idWorkOrder)
      );
      setNewWorkOrder(selectedData);
    }
    if (activeStep === 1) {
      return addNewMaterialRequest();
    }
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };
  const handleRowSelection = (selectedItems) => {
    setIsRowSelected(selectedItems.length > 0);
  };
  const handleCloseSave = () => {
    handleClose();
    setActiveStep(0);
    setSelectedWorkOrders([]);
  };
  const steps = [
    t("modules.material_request.steps.select_work_order"),
    t("modules.material_request.steps.review"),
  ];
  const itemsColumns = [
    {
      field: "StoreItemsTemplateCode",
      headerName: t("data_grid.headers.item_code"),
      width: 160,
    },
    {
      field: "StoreItemsTemplateName",
      headerName: t("data_grid.headers.item_description"),
      width: 190,
      flex: 1,
    },
    {
      field: "StoreItemsTemplateUnit",
      headerName: t("data_grid.headers.unit"),
      width: 120,
    },
    {
      field: "StoreItemsTemplateType",
      headerName: t("data_grid.headers.type"),
      width: 120,
    },
    {
      field: "StoreItemsTemplateQty",
      headerName: t("data_grid.headers.balance"),
      width: 120,
    },
    {
      field: "MaterialsRequestQty",
      headerName: t("data_grid.headers.quantity"),
      width: 120,
    },
  ];

  const views = {
    0: (
      <SelectWorkOrder
        rows={workOrderData || []}
        onRowSelection={handleRowSelection}
        selectedRows={selectedWorkOrders}
        setSelectedRows={setSelectedWorkOrders}
      />
    ),
    1: (
      <Review
        workOrderRows={newWorkOrder || []}
        itemsRows={itemsData}
        itemsColumns={itemsColumns}
      />
    ),
  };

  return (
    <InfoModal
      open={open}
      handleClose={() => {
        handleCloseSave();
      }}
      width="66.5rem"
      title={t("info_modules.quotation.description.use_template")}
      content={
        <>
          {workOrderIsLoading && itemsIsLoading ? (
            <FormSpinner />
          ) : (
            <Box margin="10px">
              <Stepper activeStep={activeStep}>
                {steps.map((label) => {
                  return (
                    <Step key={label}>
                      <StepLabel>{label}</StepLabel>
                    </Step>
                  );
                })}
              </Stepper>

              {views[activeStep]}
              <Box
                display="flex"
                alignItems="center"
                justifyContent={activeStep === 1 ? "space-between" : "flex-end"}
              >
                {activeStep === 1 && (
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={checked}
                        onChange={(event) => {
                          setChecked(event.target.checked);
                        }}
                      />
                    }
                    label={t("modules.material_request.label.after_save")}
                    sx={{
                      marginBottom: 1,
                      marginLeft: 1,
                    }}
                  />
                )}
                <StepperButton
                  isPending={isPending}
                  onClick={handleNext}
                  handleBack={handleBack}
                  activeStep={activeStep}
                  disabled={!isRowSelected}
                  label={
                    activeStep === 1
                      ? t("modules.buttons.save")
                      : t("modules.buttons.next")
                  }
                  isLastStep={activeStep === 1}
                />
              </Box>
            </Box>
          )}
        </>
      }
    />
  );
};
export default UseAsTemplate;
