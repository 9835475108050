import Axios from "../../../network/Axios";
import {
  Box,
  Stepper,
  Step,
  StepLabel,
  FormControlLabel,
  Checkbox,
  Typography,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import { useSnackbar } from "notistack";
import { useMutation, useQueryClient, useQuery } from "@tanstack/react-query";
import { useState, useEffect } from "react";
import { useFormik } from "formik";
import SelectWorkOrder from "./Steps/SelectWorkOrder";
import SelectItem from "./Steps/SelectItem";
import ModifyItem from "./Steps/ModifyItem";
import Review from "./Steps/Review";
import useHandleForms from "../../../hooks/useHandleForms";
import StepperButton from "../ButtonGroup/StepperButton";
import FormSpinner from "../../Spinners/FormSpinner";
import { generateId } from "../../../utils/globalFunctions";
import useGetMaterialRequestsFormData from "./hooks/useGetMaterialRequestsFormData";
import useHandleRowSelection from "./hooks/useHandleRowSelection";
import useGetRowClassName from "./hooks/useGetRowClassName";

const MaterialRequestForm = ({
  onDirtyChange,
  isThreeStepProcess = false,
  workOrderId,
  handleCloseFromAnotherForm,
}) => {
  const [activeStep, setActiveStep] = useState(0);
  const [selectedWorkOrders, setSelectedWorkOrders] = useState([]);
  const [newWorkOrder, setNewWorkOrder] = useState([]);
  const [checked, setChecked] = useState(false);
  const [message, setMessage] = useState(null);
  const { enqueueSnackbar } = useSnackbar();
  const queryClient = useQueryClient();
  const [t] = useTranslation("global");

  const {
    isRowSelected,
    newRows,
    selectedItems,
    handleDeleteRow,
    handleRowSelection,
    setNewRows,
    setSelectedItems,
  } = useHandleRowSelection();

  const { getRowClassName, setApplyRowClassName } = useGetRowClassName(newRows);

  const { data: workOrderData, isLoading: workOrderIsLoading } = useQuery({
    queryKey: ["materialRequests", "workOrder"],
    queryFn: async () => {
      const response = await Axios.get("/WorkOrders", {
        params: {
          State: "MaterialRequests",
        },
      });
      return response.data.result;
    },
  });

  const workOrders = newWorkOrder.map((order) => ({
    WorkOrderNo: order.WorkOrderNo,
  }));
  const items = newRows.map((item) => ({
    StoreItemsTemplateCode: item.StoreItemsTemplateCode,
    QTY: Number(item.Quantity),
  }));

  const { data: rows, isLoading } = useGetMaterialRequestsFormData();

  const workOrderRowById = [
    {
      id: generateId(),
      WorkOrderNo: workOrderId,
    },
  ];

  const { mutateAsync: addNewMaterialRequest, isPending } = useMutation({
    mutationFn: async () => {
      return Axios.post("/MaterialRequests", {
        Flag: checked,
        Items: items,
        State: "New",
        WorkOrders: isThreeStepProcess ? workOrderRowById : workOrders,
      });
    },
    onSuccess: (data) => {
      isThreeStepProcess ? handleCloseFromAnotherForm() : handleClose();
      enqueueSnackbar(data.data.msg, { variant: "success" });
      queryClient.invalidateQueries({
        predicate: (query) => {
          return ["materialRequests", "workOrderInfo"].includes(
            query.queryKey[0]
          );
        },
      });
    },
    onError: () => {
      handleClose();
    },
  });

  const itemsColumns = [
    {
      field: "StoreItemsTemplateCode",
      headerName: t("data_grid.headers.item_code"),
      width: 160,
    },
    {
      field: "StoreItemsTemplateName",
      headerName: t("data_grid.headers.item_description"),
      width: 190,
      flex: 1,
    },
    {
      field: "StoreItemsTemplateUnit",
      headerName: t("data_grid.headers.unit"),
      width: 120,
    },
    {
      field: "StoreItemsTemplateType",
      headerName: t("data_grid.headers.type"),
      width: 120,
    },
    {
      field: "StoreItemsTemplateQty",
      headerName: t("data_grid.headers.item_total_balance"),
      width: 120,
    },
    {
      field: "Quantity",
      headerName: t("data_grid.headers.quantity"),
      width: 120,
    },
  ];

  const handleNext = () => {
    if (activeStep === 0) {
      const selectedData = workOrderData.filter((row) =>
        selectedWorkOrders.includes(row.idWorkOrder)
      );
      setNewWorkOrder(selectedData);
    }
    if (isThreeStepProcess ? activeStep === 0 : activeStep === 1) {
      const selectedData = rows.filter((row) =>
        selectedItems.includes(row.idStoreItemsTemplateInfo)
      );
      setNewRows(selectedData);
    }
    if (isThreeStepProcess ? activeStep === 1 : activeStep === 2) {
      if (
        newRows.find(
          (row) => row.Quantity == 0 || row.Quantity === "" || !row.Quantity
        )
      ) {
        setApplyRowClassName(true);
        return setMessage(
          t("modules.material_request.error_messages.zero_quantity")
        );
      } else if (newRows.find((row) => row.Quantity < 0)) {
        return setMessage(
          t("modules.material_request.error_messages.negative_quantity")
        );
      } else {
        setApplyRowClassName(false);
        setMessage(null);
      }
    }
    if (isThreeStepProcess ? activeStep === 2 : activeStep === 3) {
      return addNewMaterialRequest();
    }
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  let steps = [
    t("modules.material_request.steps.select_item"),
    t("modules.material_request.steps.modify_item"),
    t("modules.material_request.steps.review"),
  ];
  if (!isThreeStepProcess) {
    steps = [t("modules.material_request.steps.select_work_order"), ...steps];
  }

  const selectWorkOrderComponent = (
    <SelectWorkOrder
      rows={workOrderData}
      onRowSelection={handleRowSelection}
      selectedRows={selectedWorkOrders}
      setSelectedRows={setSelectedWorkOrders}
    />
  );
  const selectItemsComponent = (
    <SelectItem
      onRowSelection={handleRowSelection}
      rows={rows || []}
      loading={isLoading}
      selectedRows={selectedItems}
      setSelectedRows={setSelectedItems}
    />
  );
  const modifyItemComponent = (
    <ModifyItem
      rows={newRows}
      onClick={handleDeleteRow}
      setNewRows={setNewRows}
      activeStep={activeStep}
      newRows={newRows}
      getRowClassName={getRowClassName}
    />
  );
  const reviewComponent = (
    <Review
      workOrderRows={newWorkOrder}
      itemsRows={newRows}
      itemsColumns={itemsColumns}
      isThreeStepProcess={isThreeStepProcess}
    />
  );

  const views = isThreeStepProcess
    ? {
        0: selectItemsComponent,
        1: modifyItemComponent,
        2: reviewComponent,
      }
    : {
        0: selectWorkOrderComponent,
        1: selectItemsComponent,
        2: modifyItemComponent,
        3: reviewComponent,
      };

  const { dirty, handleSubmit } = useFormik({
    onSubmit: handleNext,
  });
  const { handleClose } = useHandleForms({ dirty, onDirtyChange });

  useEffect(() => {
    if (isRowSelected !== dirty) {
      onDirtyChange(isRowSelected);
    }
  }, [isRowSelected, dirty, onDirtyChange]);

  if (workOrderIsLoading) {
    return <FormSpinner />;
  }
  const isLastStep = isThreeStepProcess ? activeStep === 2 : activeStep === 3;
  const label = isLastStep
    ? t("modules.buttons.save")
    : t("modules.buttons.next");

  return (
    <form onSubmit={handleSubmit}>
      <Stepper activeStep={activeStep}>
        {steps.map((label) => {
          return (
            <Step key={label}>
              <StepLabel>{label}</StepLabel>
            </Step>
          );
        })}
      </Stepper>
      {views[activeStep]}
      <Box
        display="flex"
        alignItems="center"
        justifyContent={
          [0, 1].includes(activeStep)
            ? "flex-end"
            : message !== null || isLastStep
            ? "space-between"
            : "flex-end"
        }
      >
        {isLastStep && (
          <FormControlLabel
            control={
              <Checkbox
                checked={checked}
                onChange={(event) => {
                  setChecked(event.target.checked);
                }}
              />
            }
            label={t("modules.material_request.label.after_save")}
            sx={{
              marginBottom: 1,
              marginLeft: 1,
            }}
          />
        )}
        {message && activeStep === 2 && (
          <Typography
            color="error"
            fontSize="16px"
            fontWeight={500}
            paddingLeft="10px"
          >
            {message}
          </Typography>
        )}

        <StepperButton
          isPending={isPending}
          onClick={handleNext}
          handleBack={handleBack}
          activeStep={activeStep}
          disabled={
            !isRowSelected ||
            ((isThreeStepProcess ? activeStep === 2 : activeStep === 3) &&
              !newRows.length)
          }
          label={label}
          isLastStep={isLastStep}
        />
      </Box>
    </form>
  );
};

export default MaterialRequestForm;
