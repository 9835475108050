import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import Spinner from "../../../components/Spinners/Spinner";
import InfoCard from "../../../components/InfoPagesCard/InfoCard";
import ArticleIcon from "@mui/icons-material/Article";
import { MailServiceService } from "../../../api/mailService";
import { useSearchParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Box, Stack, Typography } from "@mui/material";
import ChipComponent from "../../../components/Chips/ChipComponent";
import ChipStatus from "../../../components/ChipStatus";
import { LoadingButton } from "@mui/lab";
import Send from "@mui/icons-material/Send";
import { useSnackbar } from "notistack";
import ActionButton from "../../../components/forms/ButtonGroup/ActionButton";

const MailInfo = () => {
  const queryClient = useQueryClient();

  const { enqueueSnackbar } = useSnackbar();

  const { t } = useTranslation("global");

  const [params] = useSearchParams();

  const mailId = params.get("id");

  const { mutateAsync: resendMail, isPending } = useMutation({
    mutationFn: MailServiceService.resendMail,
    onSuccess: () => {
      enqueueSnackbar("Added Successfully", { variant: "success" });
      queryClient.invalidateQueries({ queryKey: ["mailService"] });
    },
  });

  const { data, isLoading } = useQuery({
    queryKey: ["mailInfo", mailId],
    queryFn: () => MailServiceService.getMailInfo(mailId),
  });

  if (isLoading) {
    return <Spinner />;
  }

  const {
    messageFrom,
    messageTo,
    messageCC,
    messageBCC,
    messageSubject,
    messageBody,
    messageAccepted,
    messageRejected,
    messageResponse,
    messageError,
    messageStatus,
    messageTimestamp,
    messageTime,
    messageId,
    messageState,
    messageResendTime,
  } = data;

  const mailInfoDetails = [
    {
      label: t("data_grid.headers.message_from"),
      value: messageFrom,
    },
    {
      label: t("data_grid.headers.message_to"),
      value: messageTo,
    },
    {
      label: t("data_grid.headers.message_cc"),
      value: messageCC,
    },
    {
      label: t("data_grid.headers.message_bcc"),
      value: messageBCC,
    },
    {
      label: t("data_grid.headers.message_subject"),
      value: messageSubject,
    },
    {
      label: t("info_modules.mail_service.description.message_body"),
      value: messageBody,
    },
    {
      label: t("data_grid.headers.message_accepted"),
      value: messageAccepted,
    },
    {
      label: t("data_grid.headers.message_rejected"),
      value: messageRejected,
    },
    {
      label: t("data_grid.headers.message_response"),
      value: messageResponse,
    },
    {
      label: t("data_grid.headers.message_error"),
      value: messageError,
    },
    {
      label: t("data_grid.headers.message_status"),
      value: messageStatus,
      showAsChip: true,
      hasAction: true,
    },
    {
      label: t("info_modules.mail_service.description.message_timestamp"),
      value: messageTimestamp,
    },
    {
      label: t("data_grid.headers.message_time"),
      value: messageTime,
    },
    {
      label: t("info_modules.mail_service.description.message_id"),
      value: messageId,
    },
    {
      label: t("data_grid.headers.message_state"),
      value: messageState,
      showAsChip: true,
    },
    {
      label: t("info_modules.mail_service.description.message_resend_time"),
      value: messageResendTime,
    },
  ];

  const openTableWindow = () => {
    const windowName = `window_${Date.now()}`; // Generate a unique window name
    const newWindow = window.open("", windowName, "width=800,height=1000");

    newWindow.document.write(messageBody);
    newWindow.document.title = messageSubject;
  };

  return (
    <Box display="flex" flexDirection="column" gap={1.7} marginBottom={2}>
      <InfoCard
        title={t("info_modules.mail_service.description.details")}
        content={
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: "0.2rem",
            }}
          >
            <ActionButton
              icon={<ArticleIcon />}
              onClick={openTableWindow}
              text={t("info_modules.mail_service.buttons.show_mail_body")}
              sx={{ alignSelf: "flex-end", textTransform: "capitalize" }}
            />
            <Stack spacing={2}>
              {mailInfoDetails.map(
                ({ label, value, showAsChip, hasAction }) => (
                  <ChipComponent
                    extraStyles={{
                      alignItems: "flex-start",
                      width: showAsChip && "fit-content",
                    }}
                    key={label}
                    label={label}
                    value={
                      showAsChip ? (
                        hasAction && value !== "Success" ? (
                          <Stack direction="row" spacing={1}>
                            <ChipStatus value={value} />
                            <LoadingButton
                              onClick={() => resendMail(mailId)}
                              loading={isPending}
                              startIcon={
                                <Send
                                  sx={{ width: "1.1rem", height: "1.1rem" }}
                                />
                              }
                            />
                          </Stack>
                        ) : (
                          <ChipStatus value={value} />
                        )
                      ) : (
                        <Typography
                          sx={{
                            lineBreak: "anywhere",
                          }}
                        >
                          {value}
                        </Typography>
                      )
                    }
                  />
                )
              )}
            </Stack>
          </Box>
        }
      />
    </Box>
  );
};

export default MailInfo;
