import FormsDataGrid from "../../../DataGrid/FormsDataGrid";
import { Box } from "@mui/material";
import { useTranslation } from "react-i18next";

const SelectCar = ({ rows, selectedRows, setSelectedRows }) => {
  const [t] = useTranslation("global");

  const columns = [
    {
      field: "CarsNumber",
      headerName: t("data_grid.headers.cars"),
      width: 120,
    },
    {
      field: "CarsType",
      headerName: t("data_grid.headers.type"),
    },
    {
      field: "EmpName",
      headerName: t("data_grid.headers.driver"),
      width: 120,
      flex: 1,
    },
    {
      field: "EmpTitle",
      headerName: t("data_grid.headers.title"),
    },
  ];

  return (
    <Box sx={{ margin: "10px 1px" }}>
      <FormsDataGrid
        rows={rows}
        columns={columns}
        getRowId={(row) => row.idCarsInfoCarsNumbers}
        height={320}
        showQuickFilter
        checkboxSelection
        toolbar
        disableSelectionOnClick
        onRowSelectionModelChange={(newSelectedRows) => {
          setSelectedRows(newSelectedRows);
        }}
        rowSelectionModel={selectedRows}
      />
    </Box>
  );
};
export default SelectCar;
