import Breadcrumbs from "@mui/material/Breadcrumbs";
import Typography from "@mui/material/Typography";
import Link from "@mui/material/Link";
import Stack from "@mui/material/Stack";
import { useLocation, Link as RouterLink } from "react-router-dom";
import { routes } from "../../routeConfig";

export default function CustomSeparator() {
  const location = useLocation();

  const breadcrumbs = location.pathname.split("/").filter(Boolean);
  const paths = [];
  let currentPath = "";

  return (
    <Stack spacing={2}>
      <Breadcrumbs separator="›" aria-label="breadcrumb">
        {breadcrumbs.map((path, index) => {
          currentPath += `/${path}`;
          paths.push(currentPath);

          const formattedPath = decodeURIComponent(path.includes("%20"))
            ? decodeURIComponent(path.replace(/%20/g, " "))
            : decodeURIComponent(path.replace(/%2F/g, "/"));

          const route = routes.find((r) => r.path === currentPath);
          const isLast = index === breadcrumbs.length - 1;

          return (
            <Link
              key={currentPath}
              component={RouterLink}
              to={currentPath}
              underline={!isLast ? "hover" : "none"}
              sx={{
                pointerEvents: isLast ? "none" : "auto",
              }}
            >
              <Typography
                color={isLast ? "textPrimary" : "primary"}
                sx={{ fontWeight: "bold", fontSize: "16px" }}
              >
                {route ? route.title : formattedPath}
              </Typography>
            </Link>
          );
        })}
      </Breadcrumbs>
    </Stack>
  );
}
