import { DateTimePicker, LocalizationProvider } from "@mui/x-date-pickers";
import dayjs from "dayjs";
import { useState } from "react";
import { useSnackbar } from "notistack";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import { GridActionsCellItem } from "@mui/x-data-grid";
import SimplePopup from "../../../components/PopUp/PopUp";
import { Box, Stack, Chip, Tooltip } from "@mui/material";
import { CompanyFleetService } from "../../../api/companyFleet";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import DateFormatGrid from "../../../components/DateFormatGrid";
import InfoCard from "../../../components/InfoPagesCard/InfoCard";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import FormsDataGrid from "../../../components/DataGrid/FormsDataGrid";
import PopUpsButtons from "../../../components/forms/ButtonGroup/PopUpsButtons";
import { useConfirmationDialog } from "../../../contexts/ConfirmationDialogContext";

const CarHistory = ({ rows }) => {
  const { language } = useSelector((state) => state.theme);
  const queryClient = useQueryClient();
  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslation("global");

  const [empCode, setEmpCode] = useState("");
  const [idEmpInfoCars, setIdEmpInfoCars] = useState("");
  const [startDate, setStartDate] = useState(dayjs());
  const [endDate, setEndDate] = useState(dayjs());
  const [anchor, setAnchor] = useState(null);

  const open = Boolean(anchor);
  const id = open ? "simple-popup" : undefined;

  const handleOpenPopUp = (event) => {
    setAnchor(event.currentTarget);
  };

  const handleClosePopUp = () => {
    setAnchor(null);
  };
  const handleStartDateChange = (date) => {
    setStartDate(date);
  };

  const handleEndDateChange = (date) => {
    setEndDate(date);
  };

  const assignedTo =
    language === "EN"
      ? {
          field: "EmpNameEn",
          headerName: t("data_grid.headers.assigned_to"),
          width: 140,
          flex: 1,
        }
      : {
          field: "EmpName",
          headerName: t("data_grid.headers.assigned_to"),
          width: 140,
          flex: 1,
        };

  const { setOpen } = useConfirmationDialog();

  const columns = [
    {
      field: "EmpCode",
      headerName: t("data_grid.headers.emp_code"),
      width: 140,
    },
    assignedTo,
    {
      field: "EmpTitle",
      headerName: t("data_grid.headers.emp_title"),
      width: 150,
    },
    {
      field: "AssignmentDateStart",
      headerName: t("data_grid.headers.assignment_start_date"),
      width: 160,
      renderCell: ({ value }) => {
        return <> {value ? <DateFormatGrid value={value} /> : <> </>}</>;
      },
    },
    {
      field: "AssignmentDateEnd",
      headerName: t("data_grid.headers.assignment_end_date"),
      width: 160,
      renderCell: ({ value }) => {
        return <> {value ? <DateFormatGrid value={value} /> : <> </>}</>;
      },
    },
    {
      field: "Actions",
      headerName: t("data_grid.headers.actions"),
      type: "actions",
      width: 110,
      getActions: (params) => [
        <Tooltip title={t("tooltip.edit")} key={params.row.id}>
          <GridActionsCellItem
            icon={<EditIcon />}
            label="Edit"
            onClick={(event) => {
              const {
                AssignmentDateStart,
                AssignmentDateEnd,
                EmpCode,
                idEmpInfoCars,
              } = params.row;

              setEmpCode(EmpCode);

              setIdEmpInfoCars(idEmpInfoCars);

              setStartDate(dayjs(AssignmentDateStart));

              setEndDate(dayjs(AssignmentDateEnd));

              handleOpenPopUp(event);
            }}
          />
        </Tooltip>,
        <Tooltip title={t("tooltip.delete")} key={params.row.id}>
          <GridActionsCellItem
            icon={<DeleteIcon color="error" />}
            label="Delete"
            onClick={() => {
              setOpen(
                t("info_modules.mobile_internet_line.confirmation.delete"),
                () => () => {
                  deleteCarHistoryRecord({
                    idEmpInfoCars: params.row.idEmpInfoCars,
                    State: "Delete",
                  });
                },
                "error"
              );
            }}
          />
        </Tooltip>,
      ],
    },
  ];

  // Handle change start and end date
  const { mutateAsync: changeStartEndDate, isPending: isChangingStartEndDate } =
    useMutation({
      mutationFn: CompanyFleetService.patchCarInfo,
      onSuccess: (data) => {
        handleClosePopUp();
        enqueueSnackbar(data.msg, { variant: "success" });
        queryClient.invalidateQueries({ queryKey: ["carInfo"] });
      },
    });

  // Handle delete line history record
  const { mutateAsync: deleteCarHistoryRecord } = useMutation({
    mutationFn: CompanyFleetService.patchCarInfo,
    onSuccess: (data) => {
      enqueueSnackbar(data.msg, { variant: "success" });
      queryClient.invalidateQueries({ queryKey: ["carInfo"] });
    },
  });

  return (
    <>
      <Box display="flex" flexDirection="column" gap={1.7} marginBottom={2}>
        <InfoCard
          title={t("info_modules.company_fleet.car_history.title")}
          content={
            <Box>
              <FormsDataGrid
                height={300}
                rows={rows}
                columns={columns}
                getRowId={(row) => row.idEmpInfoCars}
                hideFooter
              />
            </Box>
          }
        />
      </Box>
      <SimplePopup
        id={id}
        open={open}
        anchor={anchor}
        onClose={handleClosePopUp}
        content={
          <Stack spacing={1.5} p={0.4} width={320}>
            <Chip
              size="small"
              label={empCode}
              sx={{ borderRadius: 1, marginBottom: 1.8 }}
            />
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DateTimePicker
                label={t("data_grid.headers.start_date")}
                value={startDate}
                onChange={handleStartDateChange}
                format="YYYY-MM-DD hh:mm A"
                slotProps={{
                  textField: {
                    size: "small",
                    label: t("data_grid.headers.start_date"),
                  },
                }}
              />
            </LocalizationProvider>

            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DateTimePicker
                label={t("data_grid.headers.end_date")}
                value={endDate}
                onChange={handleEndDateChange}
                format="YYYY-MM-DD hh:mm A"
                slotProps={{
                  textField: {
                    size: "small",
                    label: t("data_grid.headers.end_date"),
                  },
                }}
              />
            </LocalizationProvider>

            <PopUpsButtons
              onClickClose={handleClosePopUp}
              onClickSave={() =>
                changeStartEndDate({
                  AssignmentDateStart: dayjs(startDate).format(
                    "YYYY-MM-DD HH:mm:ss"
                  ),
                  AssignmentDateEnd: dayjs(endDate).format(
                    "YYYY-MM-DD HH:mm:ss"
                  ),

                  State: "Edit",
                  idEmpInfoCars,
                })
              }
              isPending={isChangingStartEndDate}
              saveLabel={t("modules.buttons.save")}
            />
          </Stack>
        }
      />
    </>
  );
};

export default CarHistory;
