import dayjs from "dayjs";
import * as Yup from "yup";
import { useState } from "react";
import { useFormik } from "formik";
import { useSnackbar } from "notistack";
import { Link } from "react-router-dom";
import { statusColors } from "./statuses";
import { useSelector } from "react-redux";
import { Edit } from "@mui/icons-material";
import { useTranslation } from "react-i18next";
import ChipStatus from "../components/ChipStatus";
import Spinner from "../components/Spinners/Spinner";
import useOpenNewForm from "../hooks/useOpenNewForm";
import InfoModal from "../components/Modals/InfoModal";
import { GridActionsCellItem } from "@mui/x-data-grid-pro";
import { Box, TextField, Autocomplete } from "@mui/material";
import FormSpinner from "../components/Spinners/FormSpinner";
import FormsDataGrid from "../components/DataGrid/FormsDataGrid";
import { MobileInternetService } from "../api/mobileInternetLines";
import CustomDataGrid from "../components/DataGrid/CustomDataGrid";
import ButtonGroup from "../components/forms/ButtonGroup/ButtonGroup";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { useConfirmationDialog } from "../contexts/ConfirmationDialogContext";

const EditAccount = ({ data, setEditAccount, accountName }) => {
  const [t] = useTranslation("global");
  const { enqueueSnackbar } = useSnackbar();
  const queryClient = useQueryClient();

  const schema = Yup.object().shape({
    account: Yup.string().required("Required"),
    operator: Yup.string().required("Required"),
  });

  const initialValues = {
    account: data.result[0].CompanyPhoneLinesAccountNo,
    operator: data.result[0].CompanyPhoneLinesOperatorName,
  };
  const handleFormSubmit = (values) => {
    const { account, operator } = values;
    EditAccount({
      CompanyPhoneLinesOperatorName: operator,
      CompanyPhoneLinesAccountNo: account,
      SelectedNo: accountName,
      State: "EditAccount",
    });
  };

  const { values, errors, touched, handleBlur, handleSubmit, setFieldValue } =
    useFormik({
      initialValues,
      validationSchema: schema,
      onSubmit: handleFormSubmit,
      validateOnChange: true,
    });

  const { mutateAsync: EditAccount, isPending } = useMutation({
    mutationFn: MobileInternetService.EditAccount,
    onSuccess: (data) => {
      enqueueSnackbar(data.msg, { variant: "success" });
      setEditAccount(false);
      queryClient.invalidateQueries({
        queryKey: ["mobileInternetLinesAccounts"],
      });
    },
  });

  return (
    <form onSubmit={handleSubmit}>
      <Box
        sx={{ margin: 1.5, display: "flex", flexDirection: "column", gap: 1.5 }}
      >
        <TextField
          label={t(
            "modules.mobile_internet_lines.new_form.fields.account_number"
          )}
          size="small"
          value={values.account}
          onChange={(event) => {
            setFieldValue("account", event.target.value);
          }}
          onBlur={handleBlur}
          error={errors.account && touched.account}
          helperText={errors.account && touched.account && errors.account}
        />
        <Autocomplete
          options={data?.CompanyPhoneLinesOperatorName}
          getOptionLabel={(option) => option.CompanyPhoneLinesOperatorName}
          size="small"
          value={
            (data?.CompanyPhoneLinesOperatorName || []).find(
              (option) =>
                option.CompanyPhoneLinesOperatorName === values.operator
            ) || null
          }
          onChange={(event, newValue) => {
            setFieldValue(
              "operator",
              newValue ? newValue.CompanyPhoneLinesOperatorName : ""
            );
          }}
          renderInput={(params) => (
            <TextField
              {...params}
              label={t(
                "modules.mobile_internet_lines.new_form.fields.operator"
              )}
              error={Boolean(errors.operator)}
              helperText={errors.operator}
            />
          )}
        />
        <ButtonGroup
          saveLabel={t("modules.buttons.save")}
          onClickClose={() => setEditAccount(false)}
          isPending={isPending}
        />
      </Box>
    </form>
  );
};

const MobileInternetLines = () => {
  const { newForm, language } = useSelector((state) => state.theme);
  const [accountModal, setAccountModal] = useState(false);
  const [editAccount, setEditAccount] = useState(false);
  const [accountName, setAccountName] = useState("");

  const [t] = useTranslation("global");

  const statusNames = [
    {
      name: "Not Assigned",
      displayName: t("data_grid.tabs.not_assigned"),
    },
    { name: "Assigned", displayName: t("data_grid.tabs.assigned") },
    { name: "Terminated", displayName: t("data_grid.tabs.terminated") },
  ];

  const empName =
    language === "EN"
      ? {
          field: "EmpNameEn",
          headerName: t("data_grid.headers.assigned_to"),
          width: 140,
          flex: 1,
        }
      : {
          field: "EmpName",
          headerName: t("data_grid.headers.assigned_to"),
          width: 140,
          flex: 1,
        };

  const columns = [
    {
      field: "CompanyPhoneLinesNumber",
      headerName: t("data_grid.headers.line_number"),
      width: 130,
      renderCell: (params) => {
        return (
          <Link
            style={{
              color: "#1790FF",
            }}
            to={`/mobile&internetlines/${params.value}`}
          >
            {params.value}
          </Link>
        );
      },
    },
    {
      field: "CompanyPhoneLinesOperatorName",
      headerName: t("data_grid.headers.operator"),
      width: 110,
    },
    {
      field: "CompanyPhoneLinesType",
      headerName: t("data_grid.headers.type"),
      width: 110,
    },
    {
      field: "CompanyPhoneLinesPackageName",
      headerName: t("data_grid.headers.mobile_package"),
      width: 140,
    },
    {
      field: "CompanyPhoneLinesMobInternetPackage",
      headerName: t("data_grid.headers.internet_package"),
      width: 80,
    },
    empName,
    {
      field: "EmpCompanyPhoneLinesStartDate",
      headerName: t("data_grid.headers.assigned_on"),
      width: 130,
      type: "date",
      valueGetter: ({ value }) => (value ? new Date(value) : null),
      renderCell: ({ value }) => {
        return value ? dayjs(value).format("YYYY/MM/DD") : "";
      },
    },
    {
      field: "CompanyPhoneLinesStatus",
      headerName: t("data_grid.headers.status"),
      width: 130,
      renderCell: ({ value }) => {
        return <ChipStatus value={value} />;
      },
    },
  ];
  const accountColumns = [
    {
      field: "CompanyPhoneLinesAccountNo",
      headerName: t("data_grid.headers.account"),
      width: 140,
    },
    {
      field: "CompanyPhoneLinesOperatorName",
      headerName: t("data_grid.headers.operator"),
      width: 130,
      flex: 1,
    },
    {
      field: "Actions",
      headerName: t("data_grid.headers.actions"),
      type: "actions",
      width: 70,
      getActions: (params) => [
        <GridActionsCellItem
          key={params.row.idCompanyPhoneLinesAccount}
          icon={<Edit />}
          label="Edit"
          onClick={() => {
            setAccountName(params.row.CompanyPhoneLinesAccountNo);
            setEditAccount(true);
          }}
        />,
      ],
    },
  ];
  const { data: accountRows, isLoading: loadingAccounts } = useQuery({
    queryKey: ["mobileInternetLinesAccounts"],
    queryFn: MobileInternetService.getAccounts,
    enabled: accountModal,
  });

  const { data: editAccountRow, isLoading: isEditingAccount } = useQuery({
    queryKey: ["mobileInternetLinesEditAccounts", accountName],
    queryFn: () => MobileInternetService.getAccountsEdit(accountName),
    enabled: editAccount,
  });

  const {
    data: rows,
    isLoading,
    refetch,
    isRefetching,
  } = useQuery({
    queryKey: ["mobileInternetLines"],
    queryFn: MobileInternetService.GetMobileInternetLines,
  });

  // Handling the form card
  const { handleOpen } = useOpenNewForm();

  const { setOpen } = useConfirmationDialog();

  if (isLoading) {
    return <Spinner />;
  }

  return (
    <>
      <CustomDataGrid
        rows={rows}
        columns={columns}
        loading={isLoading}
        getRowId={(row) => row.idCompanyPhoneLines}
        statusColors={statusColors}
        statusNames={statusNames}
        filterField="CompanyPhoneLinesStatus"
        reloadFunction={refetch}
        showReloadOverlay={isRefetching}
        newButton
        accountsButton
        accountsFunction={() => setAccountModal(true)}
        newFunction={() => {
          if (newForm === "mobile&internetlines" || newForm === null) {
            handleOpen("mobile&internetlines");
          } else {
            setOpen(t("modules.new_form_confirmation_message"), () => () => {
              handleOpen("mobile&internetlines");
            });
          }
        }}
      />
      <InfoModal
        open={accountModal}
        handleClose={() => setAccountModal(false)}
        title={t("data_grid.buttons.accounts")}
        content={
          <Box margin={2}>
            {loadingAccounts ? (
              <FormSpinner />
            ) : (
              <FormsDataGrid
                rows={accountRows}
                columns={accountColumns}
                getRowId={(row) => row.idCompanyPhoneLinesAccount}
              />
            )}
          </Box>
        }
        width="35rem"
      />
      <InfoModal
        title={t("modules.mobile_internet_lines.new_form.edit_account")}
        open={editAccount}
        handleClose={() => setEditAccount(false)}
        width="25rem"
        content={
          isEditingAccount ? (
            <Box display="flex" justifyContent="center">
              <FormSpinner />
            </Box>
          ) : (
            <EditAccount
              data={editAccountRow}
              setEditAccount={setEditAccount}
              accountName={accountName}
            />
          )
        }
      />
    </>
  );
};

export default MobileInternetLines;
