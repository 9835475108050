// dateRangeSlice.js
import { createSlice } from "@reduxjs/toolkit";
import dayjs from "dayjs";

const startDate = dayjs().subtract(1, "year").startOf("day");
const endDate = dayjs().endOf("day");

const initialState = {
  startDate,
  endDate,
};

const dateRangeSlice = createSlice({
  name: "dateRange",
  initialState,
  reducers: {
    setStartDate(state, action) {
      state.startDate = action.payload;
    },
    setEndDate(state, action) {
      state.endDate = action.payload;
    },
  },
});

export const { setStartDate, setEndDate } = dateRangeSlice.actions;
export default dateRangeSlice.reducer;
