import { useQuery } from "@tanstack/react-query";
import { useTranslation } from "react-i18next";
import { Box } from "@mui/material";
import { employeeRecordsService } from "../../../../api/employeeRecords";
import FormsDataGrid from "../../../../components/DataGrid/FormsDataGrid";
import FormSpinner from "../../../../components/Spinners/FormSpinner";

const SelectEmployee = ({
  setValue,
  empId,
  handleClose,
  handleEmployeeNameChange,
}) => {
  const [t] = useTranslation("global");

  const columns = [
    {
      field: "EmpName",
      headerName: t("data_grid.headers.name"),
      width: 120,
      flex: 1,
    },
    {
      field: "EmpTitle",
      headerName: t("data_grid.headers.emp_title"),
    },
  ];

  const { data, isLoading } = useQuery({
    queryKey: ["drivers"],
    queryFn: employeeRecordsService.GetAllDrivers,
  });

  const handleRowSelectionModelChange = (newSelectedRows) => {
    // If newSelectedRows array has more than one item, keep only the second item
    if (newSelectedRows.length > 1) {
      newSelectedRows = [newSelectedRows[1]];
    }

    // Disable the "Select All" checkbox
    const updatedSelectionModel =
      newSelectedRows.length === 1 ? newSelectedRows : [];

    // Update selectedRows state with the updatedSelectionModel
    setValue(updatedSelectionModel);
    const employeeName = data.find(
      (row) => row.idEmpInfo === updatedSelectionModel[0]
    ).EmpName;
    handleEmployeeNameChange(employeeName);
    handleClose();
  };

  if (isLoading)
    return (
      <Box width={400}>
        <FormSpinner />
      </Box>
    );

  return (
    <Box width={400}>
      <FormsDataGrid
        rows={data}
        columns={columns}
        getRowId={(row) => row.idEmpInfo}
        disableColumnMenu
        checkboxSelection
        disableMultipleRowSelection
        onRowSelectionModelChange={handleRowSelectionModelChange}
        rowSelectionModel={empId}
        showQuickFilter
        toolbar
        height={400}
        sx={{
          "& .MuiDataGrid-columnHeaderCheckbox": {
            pointerEvents: "none",
          },
        }}
        columnVisibilityModel={{ idEmpInfo: false }}
      />
    </Box>
  );
};

export default SelectEmployee;
