import { Fab, Tooltip } from "@mui/material";
import { useState } from "react";
import AssignmentIcon from "@mui/icons-material/Assignment";
import { useDispatch, useSelector } from "react-redux";
import { setSideDrawerState } from "../../store/slices/themeSlice";
import { new_forms } from "./SideDrawer";
import { useTranslation } from "react-i18next";

const FAB = () => {
  const [t] = useTranslation("global");

  const dispatch = useDispatch();
  const { sideDrawerState, newForm } = useSelector((state) => state.theme);

  const handleOpen = () => {
    dispatch(setSideDrawerState());
  };

  const [isTooltipOpen, setIsTooltipOpen] = useState(false);

  return (
    <Tooltip
      title={t(new_forms[newForm]?.title)}
      aria-label="new form"
      open={isTooltipOpen}
      placement="left"
    >
      <Fab
        size="small"
        color="primary"
        aria-label="new form"
        sx={{
          visibility:
            sideDrawerState || newForm === null ? "hidden" : "visible",
          transform: sideDrawerState ? "translateX(50px)" : "translateX(0)",
          transition: "transform 0.5s ease-in-out, visibility 0.5s ease-in-out",
          position: "fixed",
          top: "50%",
          right: "5px",
          zIndex: 1300,
        }}
        onClick={handleOpen}
        onMouseEnter={() => setIsTooltipOpen(true)}
        onMouseLeave={() => setIsTooltipOpen(false)}
      >
        <AssignmentIcon />
      </Fab>
    </Tooltip>
  );
};

export default FAB;
