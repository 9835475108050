import * as yup from "yup";
import Axios from "../../network/Axios";
import {
  Typography,
  Autocomplete,
  TextField,
  FormControlLabel,
  Checkbox,
  Box,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import { useSnackbar } from "notistack";
import { useMutation, useQueryClient, useQuery } from "@tanstack/react-query";
import useHandleForms from "../../hooks/useHandleForms";
import { useFormik } from "formik";
import ButtonGroup from "./ButtonGroup/ButtonGroup";
import FormSpinner from "../Spinners/FormSpinner";

const SitesDataBaseForm = ({
  onDirtyChange,
  onClickClose,
  handleCloseFromAnotherForm,
}) => {
  const { enqueueSnackbar } = useSnackbar();
  const queryClient = useQueryClient();
  const [t] = useTranslation("global");

  const { data: sitesDatabaseData, isLoading } = useQuery({
    queryKey: ["sitesDatabase", "New"],
    queryFn: async () => {
      const response = await Axios.get("/SitesDatabase", {
        params: {
          State: "New",
        },
      });
      return response.data;
    },
  });

  const initialValues = {
    siteType: "",
    typology: "",
    siteName: "",
    siteCode: "",
    candidate: "",
    phase: "",
    governorate: "",
    checkboxValue: false,
    siteAddress: "",
    latitude: "",
    longitude: "",
    ownerContact: "",
    ownerName: "",
  };

  const schema = yup.object().shape({
    siteType: yup.string().required(t("modules.global_schema.required")),
    typology: yup.string().required(t("modules.global_schema.required")),
    siteName: yup.string().required(t("modules.global_schema.required")),
    siteCode: yup.string().required(t("modules.global_schema.required")),
    candidate: yup.string().required(t("modules.global_schema.required")),
    phase: yup.string().required(t("modules.global_schema.required")),
    governorate: yup.string().required(t("modules.global_schema.required")),
    checkboxValue: yup.boolean(),
    siteAddress: yup.string().required(t("modules.global_schema.required")),
    latitude: yup.string().required(t("modules.global_schema.required")),
    longitude: yup.string().required(t("modules.global_schema.required")),
    ownerContact: yup.string().required(t("modules.global_schema.required")),
    ownerName: yup.string().required(t("modules.global_schema.required")),
  });

  const { mutateAsync: AddNewSite, isPending } = useMutation({
    mutationFn: async (values) => {
      return Axios.post("/SitesDatabase", {
        SiteName: values.siteName,
        Candidate: values.candidate,
        SiteCode: values.siteCode,
        Phase: values.phase,
        SiteType: values.siteType,
        Typology: values.typology,
        Governorates: values.governorate,
        SiteAddress: values.siteAddress,
        Latitude: values.latitude,
        Longitude: values.longitude,
        OwnerName: values.ownerName,
        OwnerContact: values.ownerContact,
        Replacement: values.checkboxValue,
      });
    },
    onSuccess: (data) => {
      if (!data) return;

      if (typeof handleCloseFromAnotherForm === "function") {
        handleCloseFromAnotherForm();
      } else {
        handleClose();
      }

      enqueueSnackbar(data.data.msg, { variant: "success" });

      queryClient.invalidateQueries({
        predicate: (query) => {
          return ["sitesDatabase", "workOrderSites"].includes(
            query.queryKey[0]
          );
        },
      });
    },
  });

  const handleFormSubmit = (values) => {
    AddNewSite(values);
  };

  const {
    values,
    errors,
    touched,
    handleBlur,
    handleChange,
    handleSubmit,
    setFieldValue,
    dirty,
  } = useFormik({
    initialValues,
    validationSchema: schema,
    onSubmit: handleFormSubmit,
  });

  const { handleClose } = useHandleForms({ dirty, onDirtyChange });

  if (isLoading) {
    return <FormSpinner />;
  }

  return (
    <form onSubmit={handleSubmit}>
      <Box display="flex" flexDirection="column" gap="10px">
        <Typography
          sx={{
            fontSize: "14px",
            fontWeight: 600,
            marginLeft: "5px",
          }}
        >
          {t("modules.sites_database.labels.site_info")}
        </Typography>
        <Box display="flex" gap="10px">
          <Autocomplete
            disablePortal
            id="combo-box-demo"
            options={sitesDatabaseData.SitesTypes || []}
            getOptionLabel={(option) => option.SiteTypesNames}
            sx={{ width: "100%" }}
            size="small"
            onChange={(event, newValue) => {
              setFieldValue(
                "siteType",
                newValue ? newValue.SiteTypesNames : ""
              );
            }}
            renderInput={(params) => (
              <TextField
                {...params}
                label={t("modules.sites_database.fields.site_type")}
                value={values.siteType}
                onBlur={handleBlur}
                error={!!touched.siteType && !!errors.siteType}
                helperText={touched.siteType && errors.siteType}
              />
            )}
          />
          <Autocomplete
            disablePortal
            id="combo-box-demo"
            options={sitesDatabaseData.SitesTypologies || []}
            getOptionLabel={(option) => option.Typology}
            sx={{ width: "100%" }}
            size="small"
            onChange={(event, newValue) => {
              setFieldValue("typology", newValue ? newValue.Typology : "");
            }}
            renderInput={(params) => (
              <TextField
                {...params}
                label={t("modules.sites_database.fields.typology")}
                value={values.typology}
                onBlur={handleBlur}
                error={!!touched.typology && !!errors.typology}
                helperText={touched.typology && errors.typology}
              />
            )}
          />
        </Box>
        <Box display="flex" gap="10px">
          <TextField
            label={t("modules.sites_database.fields.site_name")}
            sx={{ width: "100%" }}
            size="small"
            value={values.siteName}
            onBlur={handleBlur}
            onChange={handleChange("siteName")}
            error={!!touched.siteName && !!errors.siteName}
            helperText={touched.siteName && errors.siteName}
          />
          <TextField
            label={t("modules.sites_database.fields.site_code")}
            sx={{ width: "100%" }}
            size="small"
            value={values.siteCode}
            onBlur={handleBlur}
            onChange={handleChange("siteCode")}
            error={!!touched.siteCode && !!errors.siteCode}
            helperText={touched.siteCode && errors.siteCode}
          />
          <TextField
            label={t("modules.sites_database.fields.candidate")}
            sx={{ width: "70%" }}
            size="small"
            value={values.candidate}
            onBlur={handleBlur}
            onChange={handleChange("candidate")}
            error={!!touched.candidate && !!errors.candidate}
            helperText={touched.candidate && errors.candidate}
          />
          <TextField
            label={t("modules.sites_database.fields.phase")}
            sx={{ width: "70%" }}
            size="small"
            value={values.phase}
            onBlur={handleBlur}
            onChange={handleChange("phase")}
            error={!!touched.phase && !!errors.phase}
            helperText={touched.phase && errors.phase}
          />
        </Box>
        <Typography
          sx={{
            fontSize: "14px",
            fontWeight: 600,
            marginLeft: "5px",
          }}
        >
          {t("modules.sites_database.labels.address_info")}
        </Typography>
        <Box display="flex" gap="12px">
          <Autocomplete
            disablePortal
            id="combo-box-demo"
            options={sitesDatabaseData.Governorate || []}
            getOptionLabel={(option) => option.GovernoratesName}
            sx={{ width: "70%" }}
            size="small"
            onChange={(event, newValue) => {
              setFieldValue(
                "governorate",
                newValue ? newValue.GovernoratesName : ""
              );
            }}
            renderInput={(params) => (
              <TextField
                {...params}
                label={t("modules.sites_database.fields.governorate")}
                value={values.governorate}
                onBlur={handleBlur}
                error={!!touched.governorate && !!errors.governorate}
                helperText={touched.governorate && errors.governorate}
              />
            )}
          />
          <FormControlLabel
            control={
              <Checkbox
                checked={values.checkboxValue}
                onChange={handleChange("checkboxValue")}
                color="primary"
              />
            }
            label={t("modules.sites_database.fields.replacement_site")}
          />
        </Box>
        <TextField
          label={t("modules.sites_database.fields.site_address")}
          sx={{ width: "100%" }}
          size="small"
          value={values.siteAddress}
          onBlur={handleBlur}
          onChange={handleChange("siteAddress")}
          error={!!touched.siteAddress && !!errors.siteAddress}
          helperText={touched.siteAddress && errors.siteAddress}
        />
        <Box display="flex" gap="10px">
          <TextField
            label={t("modules.sites_database.fields.latitude")}
            sx={{ width: "100%" }}
            size="small"
            value={values.latitude}
            onBlur={handleBlur}
            onChange={handleChange("latitude")}
            error={!!touched.latitude && !!errors.latitude}
            helperText={touched.latitude && errors.latitude}
          />
          <TextField
            label={t("modules.sites_database.fields.longitude")}
            sx={{ width: "100%" }}
            size="small"
            value={values.longitude}
            onBlur={handleBlur}
            onChange={handleChange("longitude")}
            error={!!touched.longitude && !!errors.longitude}
            helperText={touched.longitude && errors.longitude}
          />
        </Box>
        <Typography
          sx={{
            fontSize: "14px",
            fontWeight: 600,
            marginLeft: "5px",
          }}
        >
          {t("modules.sites_database.labels.contact_info")}
        </Typography>
        <Box display="flex" gap="10px">
          <TextField
            label={t("modules.sites_database.fields.owner_contact")}
            sx={{ width: "100%" }}
            size="small"
            value={values.ownerContact}
            onBlur={handleBlur}
            onChange={handleChange("ownerContact")}
            error={!!touched.ownerContact && !!errors.ownerContact}
            helperText={touched.ownerContact && errors.ownerContact}
          />
          <TextField
            label={t("modules.sites_database.fields.owner_name")}
            sx={{ width: "100%" }}
            size="small"
            value={values.ownerName}
            onBlur={handleBlur}
            onChange={handleChange("ownerName")}
            error={!!touched.ownerName && !!errors.ownerName}
            helperText={touched.ownerName && errors.ownerName}
          />
        </Box>
      </Box>
      <ButtonGroup
        isPending={isPending}
        onClickClose={onClickClose}
        saveLabel={t("modules.buttons.save")}
      />
    </form>
  );
};

export default SitesDataBaseForm;
