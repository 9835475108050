import {
  Box,
  Stack,
  Typography,
  ButtonBase,
  TextField,
  Grid,
  InputAdornment,
  IconButton,
  Button,
} from "@mui/material";
import { filesFoldersService } from "../../api/filesManager";
import { useQuery } from "@tanstack/react-query";
import Spinner from "../../components/Spinners/Spinner";
import FolderIcon from "@mui/icons-material/Folder";
import InsertDriveFileIcon from "@mui/icons-material/InsertDriveFile";
import { useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { Search } from "@mui/icons-material";
import ToggleButtons from "./components/ToggleButtons";
import EmptyFolder from "./EmptyFolder";
import ImageModal from "../../components/Modals/ImageModal";
import { convertImage } from "../../utils/utils";
import SettingsIcon from "@mui/icons-material/Settings";
import { useSelector } from "react-redux";
import SettingsModal from "./SettingsModal";
import { useTranslation } from "react-i18next";
import InfoModal from "../../components/Modals/InfoModal";
import NextPlanIcon from "@mui/icons-material/NextPlan";
import Migration from "./Migration/Migration";
import LogModal from "./LogModal";

const SearchInput = ({ searchValue, handleSearch }) => {
  const { t } = useTranslation("global");

  return (
    <TextField
      size="small"
      placeholder={t("info_modules.next_cloud.labels.search")}
      variant="outlined"
      value={searchValue}
      onChange={handleSearch}
      InputProps={{
        startAdornment: (
          <InputAdornment position="start" className="icon">
            <Search />
          </InputAdornment>
        ),
      }}
      sx={{
        backgroundColor: "background.paper",
      }}
    />
  );
};

const sliceFileName = (name, maxLength = 27) => {
  if (name.length > maxLength) {
    return name.slice(0, maxLength) + "...";
  }
  return name;
};

const Item = ({ type, name, onClick }) => {
  return (
    <ButtonBase
      onClick={(event) => {
        event.preventDefault();
        onClick();
      }}
      sx={{
        display: "block",
        textAlign: "initial",
        padding: 1,
        borderRadius: 2,
        width: "100%",
        border: "1px solid",
        borderColor: "secondary.main",
        backgroundColor: "background.paper",
      }}
    >
      <Stack direction="row" spacing={1}>
        {type === "directory" ? <FolderIcon /> : <InsertDriveFileIcon />}
        <Typography>{sliceFileName(name)}</Typography>
      </Stack>
    </ButtonBase>
  );
};

const ItemsContainer = ({
  items,
  onFolderClick,
  currentView,
  isViewEnabled,
  setFileName,
  setIsImageLoading,
  handleOpenImageModal,
  searchQuery,
}) => {
  // Filter items based on searchQuery
  const filteredItems = items.filter((item) =>
    item.basename.toLowerCase().includes(searchQuery.toLowerCase())
  );

  if (filteredItems.length === 0) {
    return (
      <Box
        display="flex"
        flexDirection="column"
        alignItems="center"
        style={{ marginTop: "10%" }}
      >
        <EmptyFolder />
      </Box>
    );
  }

  return (
    <Stack direction="row">
      <Grid container spacing={1.8}>
        {filteredItems
          .filter((item) =>
            isViewEnabled ? item.type === currentView && item : item
          )
          .map((item) => (
            <Grid item xs={3} key={item.basename}>
              <Item
                name={item.basename}
                type={item.type}
                onClick={() => {
                  if (item.type === "directory") {
                    onFolderClick(item.basename);
                  } else {
                    setFileName(item.basename); // Set basename
                    setIsImageLoading(true);
                    handleOpenImageModal();
                  }
                }}
              />
            </Grid>
          ))}
      </Grid>
    </Stack>
  );
};
const FileManager = () => {
  const { t } = useTranslation("global");
  const navigate = useNavigate();
  const location = useLocation(); // Track location changes
  const theme = useSelector((state) => state.theme.colorTheme);
  const [searchQuery, setSearchQuery] = useState(""); // Track search query
  const [fileName, setFileName] = useState("");
  const [isImageModalOpen, setIsImageModalOpen] = useState(false);
  const [isImageLoading, setIsImageLoading] = useState(false);
  const [convertedImage, setConvertedImage] = useState(null);
  const [currentView, setCurrentView] = useState("directory");
  const [isViewEnabled, setIsViewEnabled] = useState(false);
  const [settingsModal, setSettingsModal] = useState(false);
  const [logModal, setLogModal] = useState(false);
  const [loading, setLoading] = useState(false);

  const handleOpenImageModal = () => setIsImageModalOpen(true);
  const handleCloseImageModal = () => setIsImageModalOpen(false);

  const currentPath =
    location.pathname
      .split("/")
      .filter(Boolean)
      .filter((item) => item !== "cloudstorage")
      .join("/")
      .replace(/%20/g, " ") + "/";

  // Fetch files and folders
  const { data, isLoading } = useQuery({
    queryKey: ["filesAndFolders", location],
    queryFn: () => filesFoldersService.getAllFilesAndFolders(currentPath),
  });

  // Fetch file data
  const { data: fileData } = useQuery({
    queryKey: ["fileData", fileName],
    queryFn: () => filesFoldersService.getFile(currentPath, fileName),
    enabled: fileName !== "",
  });

  // Convert image once file data is fetched
  useEffect(() => {
    if (fileData) {
      setConvertedImage(convertImage(fileData.data));
      setIsImageLoading(false);
    }
  }, [fileData]);

  useEffect(() => {
    setSearchQuery("");
  }, [location.pathname]);

  const isRootDirectory = location.pathname === "/cloudstorage";

  // Calculate folder and file counts
  const folderCount = data
    ? data.filter((item, index) => item.type === "directory" && index !== 0)
        .length
    : 0;
  const fileCount = data
    ? data.filter((item) => item.type !== "directory").length
    : 0;

  // View handling logic
  const handleView = (event, newView) => {
    if (newView !== null) {
      setCurrentView(newView);
    }
  };

  const handleViewEnabled = (value) => {
    setIsViewEnabled(value);
  };

  const handleFolderClick = (folderPath) => {
    navigate(`/cloudstorage/${folderPath}`);
  };

  // Migration modal handling
  const [openMigrationModal, setOpenMigrationModal] = useState(false);
  const handleOpenMigrationModal = () => setOpenMigrationModal(true);
  const handleCloseMigrationModal = () => setOpenMigrationModal(false);

  if (isLoading) return <Spinner />;

  return (
    <Stack spacing={4}>
      <Stack direction="row" spacing={2} justifyContent="space-between">
        <Stack direction="row" spacing={2}>
          <Stack direction="row" spacing={2}>
            <IconButton
              onClick={() => setSettingsModal(true)}
              sx={{
                borderRadius: 3,
                px: 1,
                textTransform: "capitalize",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                gap: "5px",
                marginLeft: "5px",
                ":hover": {
                  backgroundColor:
                    theme === "dark"
                      ? "rgba(34, 85, 153, 0.1)"
                      : "rgba(80, 152, 236, 0.05)",
                },
              }}
            >
              <SettingsIcon color="primary" style={{ fontSize: "18px" }} />
              <Typography color="primary" fontSize="13px" fontWeight="600">
                {t("info_modules.next_cloud.labels.settings")}
              </Typography>
            </IconButton>
            <SearchInput
              searchValue={searchQuery}
              handleSearch={(e) => setSearchQuery(e.target.value)}
            />
          </Stack>
          <Button
            variant="outlined"
            size="small"
            onClick={handleOpenMigrationModal}
            startIcon={<NextPlanIcon />}
            sx={{
              textTransform: "capitalize",
              padding: "3px 8px",
              fontSize: 13.8,
              border: 2.3,
              ":hover": {
                border: 2.3,
              },
            }}
          >
            Migrate
          </Button>
          <Button
            variant="outlined"
            size="small"
            onClick={() => setLogModal(true)}
            startIcon={<InsertDriveFileIcon />}
            sx={{
              textTransform: "capitalize",
              padding: "3px 8px",
              fontSize: 13.8,
              border: 2.3,
              ":hover": {
                border: 2.3,
              },
            }}
          >
            Logs
          </Button>
        </Stack>
        <Box display="flex" alignItems="center" gap={1}>
          <Typography>
            {isRootDirectory
              ? `Total Folders: ${folderCount}`
              : `Total Files: ${fileCount}`}
          </Typography>
          <ToggleButtons
            currentView={currentView}
            handleView={handleView}
            isViewEnabled={isViewEnabled}
            handleViewEnabled={handleViewEnabled}
          />
        </Box>
      </Stack>

      <ItemsContainer
        items={data.filter((item) => item.basename)}
        onFolderClick={handleFolderClick}
        currentView={currentView}
        isViewEnabled={isViewEnabled}
        setFileName={setFileName}
        setIsImageLoading={setIsImageLoading}
        handleOpenImageModal={handleOpenImageModal}
        searchQuery={searchQuery} // Pass the updated search query
      />
      <ImageModal
        open={isImageModalOpen}
        handleClose={() => {
          handleCloseImageModal();
          setFileName("");
        }}
        image={convertedImage}
        loading={isImageLoading}
      />
      <SettingsModal
        open={settingsModal}
        handleClose={() => setSettingsModal(false)}
      />
      <InfoModal
        open={openMigrationModal}
        handleClose={handleCloseMigrationModal}
        title="Migration"
        width="60rem"
        content={
          <Migration
            loading={loading}
            setLoading={setLoading}
            setOpenMigrationModal={setOpenMigrationModal}
          />
        }
      />
      <InfoModal
        open={logModal}
        handleClose={() => setLogModal(false)}
        title="Logs"
        width="64rem"
        content={<LogModal />}
      />
    </Stack>
  );
};

export default FileManager;
