import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { useSnackbar } from "notistack";
import dayjs from "dayjs";
import FormSpinner from "../../../../../../components/Spinners/FormSpinner";
import { vacationsRequestsService } from "../../../../../../api/vacationsRequests";
import { useParams } from "react-router-dom";
import Form from "./Form";

const NewVacationRequest = ({ handleClose, empName }) => {
  const { enqueueSnackbar } = useSnackbar();
  const queryClient = useQueryClient();

  const { mutateAsync: addNewVacationsRequest, isPending } = useMutation({
    mutationFn: vacationsRequestsService.addNewVacationsRequest,
    onSuccess: (data) => {
      handleClose();
      enqueueSnackbar(data.msg, { variant: "success" });
      queryClient.invalidateQueries({
        predicate: (query) => {
          return ["vacationsRequests", "employeeInfo"].includes(
            query.queryKey[0]
          );
        },
      });
    },
  });

  const { empCode } = useParams();

  const initialValues = {
    vacationDateRange: [null, null],
    vacationAttachment: null,
    vacationType: "",
  };

  const { data, isLoading } = useQuery({
    queryKey: ["newVacationRequestsForm", empCode],
    queryFn: () => vacationsRequestsService.getNewVacationRequestData(empCode),
  });

  if (isLoading) return <FormSpinner />;

  // const vacationTypes = data.map((item) => item.VacationsTypesName);

  const vacationTypes = {
    ar: data.map((item) => item.VacationsTypesNameArabic),
    en: data.map((item) => item.VacationsTypesName),
  };

  const handleFormSubmit = (values) => {
    const { vacationAttachment, vacationDateRange, vacationType } = values;

    const index =
      vacationTypes.ar.indexOf(vacationType) !== -1
        ? vacationTypes.ar.indexOf(vacationType)
        : vacationTypes.en.indexOf(vacationType);

    const formData = new FormData();
    formData.append("VacationAttachement", vacationAttachment);
    formData.append("Start", dayjs(vacationDateRange[0]).format("YYYY/MM/DD"));
    formData.append("End", dayjs(vacationDateRange[1]).format("YYYY/MM/DD"));
    formData.append("VacationsTypesName", vacationTypes.en[index]);
    formData.append("Employees", empCode);
    formData.append("ImageState", vacationAttachment ? "Exist" : "NotExist");

    addNewVacationsRequest(formData);
  };

  return (
    <Form
      handleClose={handleClose}
      isPending={isPending}
      empName={empName}
      vacationTypes={vacationTypes}
      handleFormSubmit={handleFormSubmit}
      initialValues={initialValues}
    />
  );
};

export default NewVacationRequest;
