import { useState } from "react";
import dayjs from "dayjs";
import { Grid, Box } from "@mui/material";
import { useQuery } from "@tanstack/react-query";
import { useParams, Link } from "react-router-dom";
import Axios from "../../../network/Axios";
import { useTranslation } from "react-i18next";
import Spinner from "../../../components/Spinners/Spinner";
import InfoCard from "../../../components/InfoPagesCard/InfoCard";
import ChipComponent from "../../../components/Chips/ChipComponent";
import FormsDataGrid from "../../../components/DataGrid/FormsDataGrid";
import StoreItemCard from "./InfoModals/StoreItemCard";
import AssignToStore from "./InfoModals/AssignToStore";
import Transfer from "./InfoModals/Transfer";
import AggregateOrSeparate from "./InfoModals/AggregateOrSeparate";
import InfoModal from "../../../components/Modals/InfoModal";
import MaterialRequestSnapShot from "../WorkOrders/InfoModals/MaterialRequestSnapShot";
import PurchaseRequestSnapShot from "../WorkOrders/InfoModals/PurchaseRequestSnapShot";
import { useSelector } from "react-redux";

const InventoryManagementInfo = () => {
  const [t] = useTranslation("global");
  const { language } = useSelector((state) => state.theme);

  const { inventoryManagementId } = useParams();
  const [assign, setAssign] = useState(false);
  const [transfer, setTransfer] = useState(false);
  const [aggregateOrSeparate, setAggregateOrSeparate] = useState(false);
  const [action, setAction] = useState(null);
  const [materialNo, setMaterialNo] = useState("");
  const [materialModal, setMaterialModal] = useState(false);
  const [purchaseNo, setPurchaseNo] = useState("");
  const [purchaseModal, setPurchaseModal] = useState(false);
  const [isEditable, setIsEditable] = useState(false);
  const [startDatePicker, setStartDatePicker] = useState(
    dayjs().subtract(1, "year").startOf("day")
  );
  const [endDatePicker, setEndDatePicker] = useState(dayjs().endOf("day"));

  const handleOpenModal = (modalType) => {
    switch (modalType) {
      case "assign":
        setAssign(true);
        break;
      case "transfer":
        setTransfer(true);
        break;
      case "aggregate":
        setAggregateOrSeparate(true);
        setAction("aggregate");
        break;
      case "separate":
        setAggregateOrSeparate(true);
        setAction("separate");
        break;
      default:
        break;
    }
  };

  const { data: inventoryInfo, isLoading } = useQuery({
    queryKey: ["inventory", "info", inventoryManagementId],
    queryFn: async () => {
      const response = await Axios.get("/InventoryManagement", {
        params: {
          SelectedNo: inventoryManagementId,
          State: "CardDetailed",
        },
      });
      return response.data;
    },
  });
  const {
    data: rows,
    isLoading: transactionLoading,
    refetch,
    isRefetching,
  } = useQuery({
    queryKey: ["transaction"],
    queryFn: async () => {
      const response = await Axios.get("/InventoryManagement", {
        params: {
          Start: dayjs(startDatePicker).format("YYYY-MM-DD"),
          End: dayjs(endDatePicker).format("YYYY-MM-DD"),
          State: "ItemTransactions",
          SelectedNo: inventoryManagementId,
        },
      });
      return response.data.result;
    },
  });

  const columns = [
    {
      field: "StoreTransitionNo",
      headerName: t("data_grid.headers.transaction_ref"),
      width: 120,
    },

    {
      field: "StoreTransitionInvoiceNo",
      headerName: t("data_grid.headers.paper_no"),
      width: 70,
    },
    {
      field: "StoreTransitionDate",
      headerName: t("data_grid.headers.transaction_date"),
      width: 120,
      renderCell: ({ value }) => {
        return value ? dayjs(value).format("YYYY/MM/DD") : "";
      },
    },
    {
      field: "StoreTransactionType",
      headerName: t("data_grid.headers.transaction_type"),
      width: 120,
    },
    {
      field: "OperationNo",
      headerName: t("data_grid.headers.operation_no"),
      width: 120,
      renderCell: (params) => {
        return (
          <Link
            style={{
              color: "#1790FF",
            }}
            onClick={() => {
              if (
                params.value.startsWith("MPR") ||
                params.value.startsWith("MAR")
              ) {
                setPurchaseNo(params.value);
                setPurchaseModal(true);
              } else {
                setMaterialNo(params.value);
                setMaterialModal(true);
              }
            }}
          >
            {params.value}
          </Link>
        );
      },
    },
    {
      field: "WorkOrderNo",
      headerName: t("data_grid.headers.order_no"),
      width: 70,
    },
    {
      field: "SiteName",
      headerName: t("data_grid.headers.site_name"),
      width: 90,
      flex: 1,
    },
    {
      field: "SiteCode",
      headerName: t("data_grid.headers.site_code"),
      width: 90,
    },
    {
      field: "StoreItemsTemplateName",
      headerName: t("data_grid.headers.item_name"),
      width: 70,
    },
    {
      field: "StoreItemsTemplateCode",
      headerName: t("data_grid.headers.item_code"),
      width: 70,
    },
    {
      field: "StoreItemsTemplateUnit",
      headerName: t("data_grid.headers.unit"),
      width: 70,
    },
    {
      field: "StoreTransactionQty",
      headerName: t("data_grid.headers.qty"),
      width: 50,
    },
    {
      field: "AggItemName",
      headerName: t("data_grid.headers.agg_name"),
      width: 70,
    },
    {
      field: "AggItemCode",
      headerName: t("data_grid.headers.agg_code"),
      width: 70,
    },
    {
      field: "FromStore",
      headerName: t("data_grid.headers.from_store"),
      width: 80,
    },
    {
      field: "FromBalanceBefore",
      headerName: t("data_grid.headers.before"),
      width: 60,
    },
    {
      field: "FromBalanceAfter",
      headerName: t("data_grid.headers.after"),
      width: 60,
    },
    {
      field: "ToStore",
      headerName: t("data_grid.headers.to_store"),
      width: 100,
    },
    {
      field: "ToBalanceBefore",
      headerName: t("data_grid.headers.before"),
      width: 70,
    },
    {
      field: "ToBalanceAfter",
      headerName: t("data_grid.headers.after"),
      width: 70,
    },
    {
      field: "MaterialsRequestOrigin",
      headerName: t("data_grid.headers.mr_origin"),
      width: 100,
    },
  ];
  const itemsColumns = [
    {
      field: "StoreItemsTemplateCode",
      headerName: t("data_grid.headers.item_code"),
      width: 120,
    },
    {
      field: "GroupName",
      headerName: t("data_grid.headers.group_name"),
      width: 140,
    },
    {
      field: "StoreItemsTemplateName",
      headerName: t("data_grid.headers.item_name"),
      width: 180,
      flex: 1,
    },
    {
      field: "StoreItemsTemplateNameEn",
      headerName: t("data_grid.headers.item_name_en"),
      width: 180,
      flex: 1,
    },
    {
      field: "StoreItemsTemplateType",
      headerName: t("data_grid.headers.item_type"),
      width: 120,
    },
    {
      field: "StoreItemsTemplateUnit",
      headerName: t("data_grid.headers.unit"),
      width: 100,
    },
    {
      field: "CompanyStoreTempleteItemsBalance",
      headerName: t("data_grid.headers.balance"),
      width: 100,
    },
  ];
  const itemsTemplates = [
    {
      field: "StoreItemsTemplateCode",
      headerName: t("data_grid.headers.item_code"),
      width: 120,
    },
    {
      field: "StoreItemsTemplateName",
      headerName: t("data_grid.headers.item_name"),
      width: 180,
      flex: 1,
    },
    {
      field: "StoreItemsTemplateUnit",
      headerName: t("data_grid.headers.unit"),
      width: 100,
    },
    {
      field: "DefinitionQty",
      headerName: t("data_grid.headers.qty"),
      width: 100,
    },
  ];

  return (
    <>
      {isLoading ? (
        <Spinner />
      ) : (
        <>
          <Box display="flex" flexDirection="column" gap={1.7} marginBottom={2}>
            <InfoCard
              title={t(
                "info_modules.inventory_management.description.store_item_details"
              )}
              editButton={inventoryInfo?.Actions.includes("Edit Details")}
              onClickEdit={() => setIsEditable(true)}
              content={
                <StoreItemCard
                  inventoryInfo={inventoryInfo}
                  handleOpenModal={handleOpenModal}
                  isEditable={isEditable}
                  setIsEditable={setIsEditable}
                />
              }
            />
            {inventoryInfo?.StoreBalance?.length > 0 && (
              <InfoCard
                title={t(
                  "info_modules.inventory_management.description.store_item_balance"
                )}
                content={
                  <Grid container spacing={1}>
                    {inventoryInfo?.StoreBalance.map((item, index) => (
                      <Grid item xs={6} key={index}>
                        <ChipComponent
                          label={
                            language === "EN"
                              ? item.CompanyStoresName
                              : item.CompanyStoresNameArabic
                          }
                          value={item.CompanyStoreTempleteItemsBalance ?? ""}
                        />
                      </Grid>
                    ))}
                  </Grid>
                }
              />
            )}
            {inventoryInfo?.Replacement?.length > 0 && (
              <InfoCard
                title={t(
                  "info_modules.inventory_management.description.item_replacements"
                )}
                content={
                  <FormsDataGrid
                    rows={inventoryInfo.Replacement || []}
                    columns={itemsColumns}
                    getRowId={(row) => row.idCompanyStoreTempleteItems}
                    height={270}
                  />
                }
              />
            )}
            {inventoryInfo?.TEmpItems?.length > 0 && (
              <InfoCard
                title={t(
                  "info_modules.inventory_management.description.template_items"
                )}
                content={
                  <FormsDataGrid
                    rows={inventoryInfo.TEmpItems || []}
                    columns={itemsTemplates}
                    getRowId={(row) => row.idStoreItemsTemplateInfoItem}
                    height={270}
                  />
                }
              />
            )}
            <InfoCard
              title={t(
                "info_modules.inventory_management.description.transaction_items"
              )}
              content={
                <FormsDataGrid
                  rows={rows || []}
                  columns={columns}
                  loading={transactionLoading}
                  getRowId={(row) => row.idStoreTransitionNo}
                  height={600}
                  reloadFunction={refetch}
                  showReloadOverlay={isRefetching}
                  initialState={{
                    columns: {
                      columnVisibilityModel: {
                        AggItemName: false,
                        AggItemCode: false,
                        StoreItemsTemplateName: false,
                        StoreItemsTemplateCode: false,
                        StoreItemsTemplateUnit: false,
                        WorkOrderNo: false,
                        MaterialsRequestOrigin: false,
                      },
                    },
                  }}
                  toolbar
                  showQuickFilter
                  showColumnsFilter
                  showExportButton
                  showDatePicker
                  setEndDatePicker={setEndDatePicker}
                  setStartDatePicker={setStartDatePicker}
                  startDatePicker={startDatePicker}
                  endDatePicker={endDatePicker}
                  showReload
                />
              }
            />
          </Box>
          <AssignToStore open={assign} handleClose={() => setAssign(false)} />
          <Transfer open={transfer} handleClose={() => setTransfer(false)} />
          <AggregateOrSeparate
            open={aggregateOrSeparate}
            handleClose={() => setAggregateOrSeparate(false)}
            action={action}
          />
          <InfoModal
            title={`${t(
              "info_modules.work_orders.description.material_request_details"
            )} - ${materialNo}`}
            content={
              <MaterialRequestSnapShot
                materialNo={materialNo}
                open={materialModal}
              />
            }
            handleClose={() => setMaterialModal(false)}
            open={materialModal}
            width="66rem"
          />
          <InfoModal
            title={`${t(
              "info_modules.material_request.description.purchase_request_details"
            )} - ${purchaseNo}`}
            content={
              <PurchaseRequestSnapShot
                purchaseNo={purchaseNo}
                open={purchaseModal}
              />
            }
            handleClose={() => setPurchaseModal(false)}
            open={purchaseModal}
            width="60rem"
          />
        </>
      )}
    </>
  );
};
export default InventoryManagementInfo;
