import { useQuery } from "@tanstack/react-query";
import Axios from "../../../../network/Axios";
import { useState } from "react";
import FormSpinner from "../../../../components/Spinners/FormSpinner";
import { Stack, Grid } from "@mui/material";
import { useTranslation } from "react-i18next";
import ChipComponent from "../../../../components/Chips/ChipComponent";
import CustomAccordion from "../../../../components/Accordion/CustomAccordion";
import ChipStatus from "../../../../components/ChipStatus";
import { formatCurrency } from "../../../../utils/globalFunctions";

const InvoicesSnapShot = ({ d6RefNo, open }) => {
  const { data, isLoading } = useQuery({
    queryKey: ["quotationInfo", d6RefNo],
    queryFn: async () => {
      const response = await Axios.get("/Quotations", {
        params: {
          SelectedNo: d6RefNo,
          State: "Card",
        },
      });
      return response.data;
    },
    enabled: open,
  });

  if (isLoading) return <FormSpinner />;

  return <InvoicesSnap data={data} />;
};

const InvoicesSnap = ({ data }) => {
  const [t] = useTranslation("global");

  // WorkOrder data
  const {
    WorkOrderD6No,
    WorkOrderD6TypeName,
    WorkOrderD6Status,
    WorkOrderD6Start,
    WorkOrderD6End,
    WUser,
    WorkOrderD6Amount,
    ProjectName,
    Survey,
    Installation,
    PowerUP,
    WorkOrderD6Supply,
    WorkOrderD6Install,
    AcceptanceDate,
  } = data.Details[0];

  const Invoices = [
    {
      label: t("data_grid.headers.d6_no"),
      value: WorkOrderD6No,
    },
    {
      label: t("data_grid.headers.d6_type"),
      value: WorkOrderD6TypeName,
    },
    {
      label: t("data_grid.headers.status"),
      value: <ChipStatus value={WorkOrderD6Status} />,
    },
    {
      label: t("data_grid.headers.start_date"),
      value: WorkOrderD6Start,
    },
    {
      label: t("data_grid.headers.end_date"),
      value: WorkOrderD6End,
    },
    {
      label: t("data_grid.headers.user"),
      value: WUser,
    },
    {
      label: t("data_grid.headers.amount"),
      value: formatCurrency(WorkOrderD6Amount),
    },
    {
      label: t("data_grid.headers.project"),
      value: ProjectName,
    },
    {
      label: t("data_grid.headers.survey"),
      value: Survey,
    },
    {
      label: t("data_grid.headers.installation"),
      value: Installation,
    },
    {
      label: t("data_grid.headers.power_up"),
      value: PowerUP,
    },
    {
      label: t("data_grid.headers.supply_invoice_no"),
      value: data?.Invoices === null ? "NA" : data?.Invoices[0]?.InvoiceNo,
    },
    {
      label: t("data_grid.headers.total_supply"),
      value: formatCurrency(WorkOrderD6Supply),
    },
    {
      label: t("data_grid.headers.install_invoice_no"),
      value: data?.Invoices === null ? "NA" : data?.Invoices[1]?.InvoiceNo,
    },
    {
      label: t("data_grid.headers.total_install"),
      value: formatCurrency(WorkOrderD6Install),
    },
    {
      label: t("data_grid.headers.acceptance_date"),
      value: AcceptanceDate,
    },
  ];

  const invoicesColumns = [
    {
      field: "PriceListSN",
      headerName: t("data_grid.headers.item_code"),
      width: 90,
    },
    {
      field: "Description",
      headerName: t("data_grid.headers.description"),
      width: 180,
      flex: 1,
    },
    {
      field: "SI",
      headerName: t("data_grid.headers.s_i"),
      width: 80,
    },
    {
      field: "Unit",
      headerName: t("data_grid.headers.unit"),
      width: 90,
    },
    {
      field: "ItemQTY",
      headerName: t("data_grid.headers.qty"),
      width: 100,
    },
    {
      field: "TheItemPrice",
      headerName: t("data_grid.headers.unit_price"),
      width: 100,
    },
    {
      field: "TotalPrice",
      headerName: t("data_grid.headers.total_price"),
      width: 100,
    },
    {
      field: "PriceListRefNo",
      headerName: t("data_grid.headers.price_list"),
      width: 120,
    },
  ];

  const [expanded, setExpanded] = useState("panel1");
  const handleChange = (panel) => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false);
  };

  return (
    <Stack spacing={1.5} p={1}>
      <Grid container spacing={1}>
        {Invoices.map(({ label, value }, index) => (
          <Grid item xs={index === Invoices.length - 1 ? 8 : 4} key={label}>
            <ChipComponent label={label} value={value} />
          </Grid>
        ))}
      </Grid>

      <CustomAccordion
        title={t("data_grid.headers.items")}
        rows={data?.Items || []}
        columns={invoicesColumns}
        getRowId={(row) => row.idPriceList}
        hideFooter
        height={190}
        expanded={expanded === "panel1"}
        onChange={handleChange("panel1")}
      />
    </Stack>
  );
};
export default InvoicesSnapShot;
