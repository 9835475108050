import {
  Autocomplete,
  Box,
  Button,
  IconButton,
  Stack,
  TextField,
  Typography,
  styled,
} from "@mui/material";
import { useMutation, useQueryClient, useQuery } from "@tanstack/react-query";
import dayjs from "dayjs";
import { useSnackbar } from "notistack";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import * as yup from "yup";
import { employeeRecordsService } from "../../api/employeeRecords";
import DeleteIcon from "@mui/icons-material/Delete";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFnsV3";
import AntSwitch from "../Switches/AntSwitch";
import useHandleForms from "../../hooks/useHandleForms";
import ButtonGroup from "./ButtonGroup/ButtonGroup";
import FormSpinner from "../Spinners/FormSpinner";
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import VisuallyHiddenInput from "../VisuallyHiddenInput";
import TextInput from "../Inputs/TextInput";

const ImagePickerBox = ({
  error,
  image,
  handleRemoveImage,
  handleImage,
  control,
}) => {
  return (
    <Box
      sx={{
        aspectRatio: "1",
        borderRadius: "50%",
        maxWidth: "10rem",
      }}
    >
      {(!image || error) && (
        <Box
          sx={{
            aspectRatio: "1",
            borderRadius: "50%",
            py: 10,
            border: "1px dashed #277da1",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "column",
            gap: "10px",
            height: "100%",
          }}
        >
          <Button
            component="label"
            role={undefined}
            variant="contained"
            sx={{
              borderRadius: "50%",
              width: "25px",
              aspectRatio: "1",
              "& span": {
                m: 0,
              },
            }}
            startIcon={<CloudUploadIcon />}
          >
            <Controller
              name="employeeImage"
              control={control}
              render={({ field }) => {
                return (
                  <VisuallyHiddenInput
                    type="file"
                    accept="image/*"
                    onChange={(e) => {
                      field.onChange(e.target.files[0]);
                      handleImage(e);
                    }}
                  />
                );
              }}
            />
          </Button>
        </Box>
      )}

      {!error && image && (
        <Box
          sx={{
            flexGrow: 1,
            position: "relative",
            overflow: "hidden",
            aspectRatio: "1",
            borderRadius: "50%",
            width: "150px",
            "&:hover": {
              "& > button": {
                opacity: 1,
              },
              "& > img": {
                filter: "brightness(0.5)",
              },
            },
          }}
        >
          <img
            src={image}
            alt="Selected"
            style={{
              width: "100%",
              height: "100%",
              objectFit: "cover",
              transition: "filter 0.3s",
            }}
          />

          <IconButton
            aria-label="delete"
            onClick={handleRemoveImage}
            sx={{
              opacity: 0,
              position: "absolute",
              left: "50%",
              top: "50%",
              transform: "translate(-50%, -50%)",
              backgroundColor: "#fff",
              "&:hover": {
                backgroundColor: "#333",
              },
            }}
          >
            <DeleteIcon color="error" />
          </IconButton>
        </Box>
      )}
    </Box>
  );
};

export const CustomTextField = styled((props) => (
  <TextField size="small" {...props} />
))(() => ({
  "& .MuiOutlinedInput-root": {
    borderRadius: "10px",
  },
}));

export const EmployeeRecordsForm = ({ onDirtyChange, onClickClose }) => {
  const currentDate = dayjs().format("YYYY/MM/DD");

  const initialValues = {
    employeeImage: null,
    arabicName: "",
    englishName: "",
    code: "",
    title: "",
    hireDate: currentDate,
    department: "",
    project: "",
    drive: false,
    status: "",
    mission: false,
    intExt: "",
    phoneNumber: "",
    email: "",
    socialInsurance: false,
    medicalInsurance: false,
  };

  const queryClient = useQueryClient();

  const [t] = useTranslation("global");

  const intExtOptions = [
    t(
      "modules.employee_records.new_form.fields.work_information.intExt.options.internal"
    ),
    t(
      "modules.employee_records.new_form.fields.work_information.intExt.options.external"
    ),
  ];

  const schema = yup.object().shape({
    intExt: yup
      .string()
      .required(t("modules.global_schema.select_value"))
      .oneOf(intExtOptions, t("modules.global_schema.invalid_value")),
    arabicName: yup
      .string()
      .matches(/^[\u0600-\u06FF\s]+$/, t("modules.global_schema.only_arabic"))
      .required(t("modules.global_schema.required")),
    englishName: yup
      .string()
      .matches(/^[A-Za-z\s]+$/, t("modules.global_schema.only_english"))
      .required(t("modules.global_schema.required")),
    code: yup.string().required(t("modules.global_schema.required")),
    title: yup.string().required(t("modules.global_schema.required")),
    department: yup.string().required(t("modules.global_schema.select_value")),
    project: yup.string().required(t("modules.global_schema.select_value")),
    hireDate: yup.date().required(t("modules.global_schema.required")),
    phoneNumber: yup
      .string()
      .required(t("modules.global_schema.required"))
      .length(
        10,
        t("modules.mobile_internet_lines.new_form.schema.phone_number_length")
      )
      .matches(
        /^(11|12|10)\d{8}$/,
        t("modules.mobile_internet_lines.new_form.schema.valid_phone_number")
      ),
    drive: yup.boolean().required(t("modules.global_schema.required")),
    mission: yup.boolean().required(t("modules.global_schema.required")),
    socialInsurance: yup
      .boolean()
      .required(t("modules.global_schema.required")),
    medicalInsurance: yup
      .boolean()
      .required(t("modules.global_schema.required")),
  });

  const handleFormSubmit = (values) => {
    const {
      employeeImage,
      arabicName,
      englishName,
      code,
      title,
      hireDate,
      department,
      project,
      drive,
      mission,
      intExt,
      phoneNumber,
      email,
      socialInsurance,
      medicalInsurance,
    } = values;

    /**
     * The API expects the intExt to be in English
     * so we need to convert it to English before sending it
     * to the API
     */
    const enInternal = t(
      "modules.employee_records.new_form.fields.work_information.intExt.options.internal",
      { lng: "en" }
    );
    const arInternal = t(
      "modules.employee_records.new_form.fields.work_information.intExt.options.internal",
      { lng: "ar" }
    );

    const intExtLabel = [enInternal, arInternal].includes(intExt)
      ? "Internal"
      : "External";

    const formData = new FormData();

    formData.append("EmpPhoto", employeeImage);
    formData.append("CompanyProjectsName", project);
    formData.append("CompanyDepartmentsName", department);
    formData.append("EmpCode", code);
    formData.append("EmpName", arabicName);
    formData.append("EmpNameEn", englishName);
    formData.append("EmpTitle", title);
    const newHireDate = dayjs(hireDate).format("YYYY-MM-DD");
    formData.append("EmpDateOfHire", newHireDate);
    formData.append("EmpPhone", phoneNumber);
    formData.append("EmpEmail", email);
    formData.append("EmpInfoDrive", drive ? "True" : "False");
    formData.append("EmpInfoMission", mission ? "True" : "False");
    formData.append("EmpStatus", "Active");
    formData.append(
      "EmpInsurance",
      socialInsurance ? "Insured" : "Not Insured"
    );
    formData.append("EmpMedical", medicalInsurance ? "Covered" : "Not Covered");
    formData.append("EmpInfoEntExt", intExtLabel);
    formData.append("ImageState", employeeImage == null ? "Remove" : "Exist");

    AddNewEmployee(formData);
  };

  const {
    handleSubmit,
    setValue,
    control,
    formState: { errors, isDirty },
  } = useForm({
    resolver: yupResolver(schema),
    mode: "onChange",
    defaultValues: initialValues,
  });

  const { handleClose } = useHandleForms({ dirty: isDirty, onDirtyChange });

  const { enqueueSnackbar } = useSnackbar();

  const { mutateAsync: AddNewEmployee, isPending } = useMutation({
    mutationFn: employeeRecordsService.AddNewEmployee,
    onSuccess: (data) => {
      handleClose();
      enqueueSnackbar(data.msg, { variant: "success" });
      queryClient.invalidateQueries({ queryKey: ["employeeRecords"] });
    },
  });

  const { data, isLoading } = useQuery({
    queryKey: ["employeeRecordsFormData"],
    queryFn: employeeRecordsService.GetEmployeeRecordsForm,
  });

  // Image handling logic
  const [employeeImage, setEmployeeImage] = useState(null);

  const handleImageChange = (event) => {
    const file = event.currentTarget.files[0];
    setValue("employeeImage", file);
    setEmployeeImage(URL.createObjectURL(file));
  };

  const handleRemoveImage = () => {
    setValue("employeeImage", null);
    setEmployeeImage(null);
  };

  const labels = {
    employeeName: t(
      "modules.employee_records.new_form.fields.employee_name.headline"
    ),
    workInformation: t(
      "modules.employee_records.new_form.fields.work_information.headline"
    ),
    contactInformation: t(
      "modules.employee_records.new_form.fields.contact_information.headline"
    ),
    insurance: t("modules.employee_records.new_form.fields.insurance.headline"),
    arabicName: t(
      "modules.employee_records.new_form.fields.employee_name.arabicName"
    ),
    englishName: t(
      "modules.employee_records.new_form.fields.employee_name.englishName"
    ),
    code: t("modules.employee_records.new_form.fields.work_information.code"),
    title: t("modules.employee_records.new_form.fields.work_information.title"),
    hireDate: t(
      "modules.employee_records.new_form.fields.work_information.date_of_hire"
    ),
    department: t(
      "modules.employee_records.new_form.fields.work_information.department"
    ),
    project: t(
      "modules.employee_records.new_form.fields.work_information.project"
    ),
    drive: t("modules.employee_records.new_form.fields.work_information.drive"),
    status: t(
      "modules.employee_records.new_form.fields.work_information.status.title"
    ),
    mission: t(
      "modules.employee_records.new_form.fields.work_information.mission"
    ),
    intExt: t(
      "modules.employee_records.new_form.fields.work_information.intExt.title"
    ),
    phoneNumber: t(
      "modules.employee_records.new_form.fields.contact_information.phoneNumber"
    ),
    email: t(
      "modules.employee_records.new_form.fields.contact_information.email"
    ),
    socialInsurance: t(
      "modules.employee_records.new_form.fields.insurance.social_insurance"
    ),
    medicalInsurance: t(
      "modules.employee_records.new_form.fields.insurance.medical_insurance"
    ),
  };

  const CompanyDepartments =
    data?.CompanyDepartments.map(
      (department) => department.CompanyDepartmentName
    ) || [];
  const CompanyProjects =
    data?.CompanyProjects.map((project) => project.CompanyProjectsName) || [];

  if (isLoading) {
    return <FormSpinner />;
  }

  return (
    <form onSubmit={handleSubmit(handleFormSubmit)} noValidate>
      <Box
        sx={{
          display: "grid",
          gridTemplateColumns: "2fr 1fr",
          gap: "16px",
        }}
      >
        <Stack spacing={3}>
          <Stack spacing={1}>
            <Typography
              sx={{
                fontSize: "14px",
                fontWeight: 600,
                marginLeft: "5px",
              }}
            >
              {labels.employeeName}
            </Typography>
            <Box
              sx={{
                display: "grid",
                gridTemplateColumns: "1fr 1fr",
                gap: "16px",
              }}
            >
              <TextInput
                name="arabicName"
                label={labels.arabicName}
                control={control}
                error={Boolean(errors.arabicName)}
                helperText={errors.arabicName?.message}
                required
              />

              <TextInput
                name="englishName"
                label={labels.englishName}
                control={control}
                error={Boolean(errors.englishName)}
                helperText={errors.englishName?.message}
                required
              />
            </Box>
          </Stack>
          <Stack spacing={1}>
            <Typography
              sx={{
                fontSize: "14px",
                fontWeight: 600,
                marginLeft: "5px",
              }}
            >
              {labels.workInformation}
            </Typography>
            <Box
              sx={{
                display: "grid",
                gridTemplateColumns: "1fr 1fr 1fr",
                gap: "16px",
              }}
            >
              <TextInput
                name="code"
                label={labels.code}
                control={control}
                error={Boolean(errors.code)}
                helperText={errors.code?.message}
                required
              />

              <TextInput
                name="title"
                label={labels.title}
                control={control}
                error={Boolean(errors.title)}
                helperText={errors.title?.message}
                required
              />

              <Controller
                control={control}
                name={`department`}
                render={({ field: { onChange, value } }) => (
                  <Autocomplete
                    disableClearable
                    size="small"
                    options={CompanyDepartments}
                    onChange={(event, item) => {
                      onChange(item);
                    }}
                    value={value || null}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label={labels.department}
                        error={!!errors.department}
                        helperText={errors.department?.message}
                        required
                      />
                    )}
                  />
                )}
              />
            </Box>
          </Stack>
          <Box
            sx={{
              display: "grid",
              gridTemplateColumns: "1fr 1fr",
              gap: "16px",
            }}
          >
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <Controller
                control={control}
                name="hireDate"
                render={({ field }) => {
                  return (
                    <DatePicker
                      label={labels.hireDate}
                      views={["year", "month", "day"]}
                      name="hireDate"
                      value={field.value}
                      inputRef={field.ref}
                      onChange={(date) => {
                        field.onChange(date);
                      }}
                      slotProps={{
                        textField: {
                          size: "small",
                          error: Boolean(errors.hireDate),
                          helperText: errors.hireDate?.message,
                          required: true,
                          sx: {
                            "& .MuiOutlinedInput-root": {
                              borderRadius: "10px",
                            },
                          },
                        },
                      }}
                    />
                  );
                }}
              />
            </LocalizationProvider>

            <Controller
              control={control}
              name={`project`}
              render={({ field: { onChange, value } }) => (
                <Autocomplete
                  disableClearable
                  size="small"
                  options={CompanyProjects}
                  onChange={(event, item) => {
                    onChange(item);
                  }}
                  value={value || null}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label={labels.project}
                      error={!!errors.project}
                      helperText={errors.project?.message}
                      required
                    />
                  )}
                />
              )}
            />
          </Box>
          <Box
            sx={{
              display: "grid",
              gridTemplateColumns: "repeat(4, 1fr)",
              gap: "16px",
            }}
          >
            <Stack direction="row" spacing={1} alignItems="center">
              <Typography>{labels.drive}:</Typography>

              <Controller
                name="drive"
                control={control}
                render={({ field }) => (
                  <AntSwitch
                    {...field}
                    checked={field.value}
                    onChange={(e) => field.onChange(e.target.checked)}
                  />
                )}
              />
            </Stack>

            <Stack direction="row" spacing={1} alignItems="center">
              <Typography>{labels.mission}:</Typography>

              <Controller
                name="mission"
                control={control}
                render={({ field }) => (
                  <AntSwitch
                    {...field}
                    checked={field.value}
                    onChange={(e) => field.onChange(e.target.checked)}
                  />
                )}
              />
            </Stack>
            <Controller
              control={control}
              name={`intExt`}
              render={({ field: { onChange, value } }) => (
                <Autocomplete
                  disableClearable
                  size="small"
                  options={intExtOptions}
                  onChange={(event, item) => {
                    onChange(item);
                  }}
                  value={value || null}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label={labels.intExt}
                      error={!!errors.intExt}
                      helperText={errors.intExt?.message}
                      required
                    />
                  )}
                />
              )}
            />
          </Box>

          <Stack spacing={1}>
            <Typography
              sx={{
                fontSize: "14px",
                fontWeight: 600,
                marginLeft: "5px",
              }}
            >
              {labels.contactInformation}
            </Typography>
            <Box
              sx={{
                display: "grid",
                gridTemplateColumns: "1fr 1fr",
                gap: "16px",
              }}
            >
              <TextInput
                name="phoneNumber"
                label={labels.phoneNumber}
                control={control}
                error={Boolean(errors.phoneNumber)}
                helperText={errors.phoneNumber?.message}
                required
              />
              <TextInput name="email" label={labels.email} control={control} />
            </Box>
          </Stack>
          <Stack spacing={1}>
            <Typography
              sx={{
                fontSize: "14px",
                fontWeight: 600,
                marginLeft: "5px",
              }}
            >
              {labels.insurance}
            </Typography>
            <Box
              sx={{
                display: "grid",
                gridTemplateColumns: "1fr 1fr",
                gap: "16px",
              }}
            >
              <Stack direction="row" spacing={1} alignItems="center">
                <Typography>{labels.socialInsurance}:</Typography>
                <Controller
                  name="socialInsurance"
                  control={control}
                  render={({ field }) => (
                    <AntSwitch
                      {...field}
                      checked={field.value}
                      onChange={(e) => field.onChange(e.target.checked)}
                    />
                  )}
                />
              </Stack>
              <Stack direction="row" spacing={1} alignItems="center">
                <Typography>{labels.medicalInsurance}:</Typography>
                <Controller
                  name="medicalInsurance"
                  control={control}
                  render={({ field }) => (
                    <AntSwitch
                      {...field}
                      checked={field.value}
                      onChange={(e) => field.onChange(e.target.checked)}
                    />
                  )}
                />
              </Stack>
            </Box>
          </Stack>
        </Stack>
        <Stack
          sx={{
            width: "100%",
          }}
          spacing={3}
          justifyContent={"center"}
          alignItems="center"
        >
          <ImagePickerBox
            image={employeeImage}
            label={t("modules.company_fleet.new_form.fields.upload_front")}
            handleImage={handleImageChange}
            handleRemoveImage={handleRemoveImage}
            control={control}
          />
        </Stack>
      </Box>
      <ButtonGroup
        isPending={isPending}
        onClickClose={onClickClose}
        saveLabel={t("modules.buttons.save")}
      />
    </form>
  );
};
