import dayjs from "dayjs";
import * as yup from "yup";
import {
  MAX_FILE_SIZE,
  carTypeOptions,
  supportedImageTypes,
} from "../../../constants/constants";
import { useSnackbar } from "notistack";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { convertImage } from "../../../utils/utils";
import { yupResolver } from "@hookform/resolvers/yup";
import { useNavigate, useParams } from "react-router-dom";
import { CompanyFleetService } from "../../../api/companyFleet";
import FormSpinner from "../../../components/Spinners/FormSpinner";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { NewFleetCarForm } from "../../../components/forms/CompanyFleet/CompanyFleetForm";

const EditCarInfo = ({ handleClose }) => {
  const { carNumber } = useParams();

  // Get Car data
  const { data, isLoading, isRefetching } = useQuery({
    queryKey: ["carEditInfo", carNumber],
    queryFn: () => CompanyFleetService.getCarEditInfo(carNumber),
  });

  if (isLoading || isRefetching) return <FormSpinner />;

  return <Form data={data} handleClose={handleClose} />;
};

const Form = ({ data, handleClose }) => {
  const navigate = useNavigate();
  const { carNumber } = useParams();

  const queryClient = useQueryClient();
  const { enqueueSnackbar } = useSnackbar();
  const [t] = useTranslation("global");

  const {
    CarsBodyNo,
    CarsColor,
    CarsInfoCarsNumbersLicenseBack,
    CarsInfoCarsNumbersLicenseFront,
    CarsLicenseEndDate,
    CarsModelName,
    CarsModelYear,
    CarsMotorNo,
    CarsNumber,
    CarsOwnership,
    CarsType,
    idCarsInfoCarsNumbers,
  } = data[0];

  const currentFrontImage =
    data[0].CarsInfoCarsNumbersLicenseFront !== null
      ? convertImage(CarsInfoCarsNumbersLicenseFront.data)
      : null;

  const currentBackImage = data[0].CarsInfoCarsNumbersLicenseBack
    ? convertImage(CarsInfoCarsNumbersLicenseBack.data)
    : null;

  const initialValues = {
    frontImage: null,
    backImage: null,
    carType: CarsType,
    plateNumber: CarsNumber,
    model: CarsModelName,
    modelYear: CarsModelYear,
    ownership: CarsOwnership,
    licenseExpireDate: CarsLicenseEndDate,
    chassisNumber: CarsBodyNo,
    motorNumber: CarsMotorNo,
    color: CarsColor,
  };

  const schema = yup.object().shape({
    frontImage: yup
      .mixed()
      .test("fileSize", t("modules.global_schema.file_size"), (value) => {
        return !value || (value && value.size <= MAX_FILE_SIZE);
      })
      .test(
        "fileType",
        t("modules.global_schema.file_type"),
        (value) => !value || (value && supportedImageTypes.includes(value.type))
      )
      .nullable(),
    backImage: yup
      .mixed()
      .test("fileSize", t("modules.global_schema.file_size"), (value) => {
        return !value || (value && value.size <= MAX_FILE_SIZE);
      })
      .test(
        "fileType",
        t("modules.global_schema.file_type"),
        (value) => !value || (value && supportedImageTypes.includes(value.type))
      )
      .nullable(),
    carType: yup
      .string()
      .required(t("modules.global_schema.select_value"))
      .oneOf(carTypeOptions, t("modules.global_schema.invalid_value")),
    plateNumber: yup.string().required(t("modules.global_schema.select_value")),
    model: yup.string().required(t("modules.global_schema.select_value")),
    modelYear: yup.date().required(t("modules.global_schema.required")),
    ownership: yup.string().required(t("modules.global_schema.select_value")),
    licenseExpireDate: yup.date().required(t("modules.global_schema.required")),
    chassisNumber: yup
      .string()
      .required(t("modules.global_schema.select_value")),
    motorNumber: yup.string().required(t("modules.global_schema.select_value")),
    color: yup.string().required(t("modules.global_schema.select_value")),
  });

  const {
    getValues,
    register,
    handleSubmit,
    setValue,
    setError,
    control,
    formState: { errors, isDirty },
  } = useForm({
    resolver: yupResolver(schema),
    mode: "onChange",
    defaultValues: initialValues,
  });

  const handleFormSubmit = (values) => {
    const {
      frontImage,
      backImage,
      plateNumber,
      model,
      modelYear,
      ownership,
      licenseExpireDate,
      carType,
      chassisNumber,
      motorNumber,
      color,
    } = values;

    const formData = new FormData();

    formData.append("CarsInfoCarsNumbersLicenseFront", frontImage);
    formData.append("CarsInfoCarsNumbersLicenseBack", backImage);
    formData.append("CarsNumber", plateNumber);
    formData.append("CarsModelName", model);
    formData.append("CarsModelYear", dayjs(modelYear).format("YYYY-MM-DD"));
    formData.append("CarsOwnership", ownership);
    formData.append(
      "CarsLicenseEndDate",
      dayjs(licenseExpireDate).format("YYYY-MM-DD")
    );
    formData.append("CarsType", carType);
    formData.append("CarsColor", color);
    formData.append("CarsMotorNo", motorNumber);
    formData.append("CarsBodyNo", chassisNumber);
    formData.append("idCarsInfoCarsNumbers", idCarsInfoCarsNumbers);
    formData.append("SelectedNo", carNumber);

    updateCarInfo(formData);
  };

  const { mutateAsync: updateCarInfo, isPending } = useMutation({
    mutationFn: CompanyFleetService.updateCarInfo,
    onSuccess: (data) => {
      handleClose();
      enqueueSnackbar(data.msg, { variant: "success" });
      queryClient.invalidateQueries({
        queryKey: ["carInfo", getValues().plateNumber],
      });
      navigate(`/companyfleet/${getValues().plateNumber}`, {
        replace: true,
      });
    },
  });

  return (
    <NewFleetCarForm
      register={register}
      handleSubmit={handleSubmit}
      setValue={setValue}
      setError={setError}
      control={control}
      errors={errors}
      isDirty={isDirty}
      isPending={isPending}
      handleFormSubmit={handleFormSubmit}
      carTypeOptions={carTypeOptions}
      initialValues={initialValues}
      onClickClose={handleClose}
      currentFrontImage={currentFrontImage}
      currentBackImage={currentBackImage}
    />
  );
};

export default EditCarInfo;
