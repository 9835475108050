import { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import {
  Box,
  Stepper,
  Step,
  StepLabel,
  TextField,
  Typography,
  Stack,
  Button,
  FormControlLabel,
  Checkbox,
  IconButton,
} from "@mui/material";
import { useQuery, useMutation, useQueryClient } from "@tanstack/react-query";
import Axios from "../../../network/Axios";
import { useSnackbar } from "notistack";
import { useFormik } from "formik";
import useHandleForms from "../../../hooks/useHandleForms";
import StepperButton from "../ButtonGroup/StepperButton";
import SelectWorkOrder from "./Steps/SelectWorkOrder";
import SelectTeam from "./Steps/SelectTeam";
import SelectCar from "./Steps/SelectCar";
import Review from "./Steps/Review";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";
import dayjs from "dayjs";
import FormSpinner from "../../Spinners/FormSpinner";
import { generateId } from "../../../utils/globalFunctions";
import useHandleRowSelection from "../MaterialRequests/hooks/useHandleRowSelection";
import useGetRowClassName from "../MaterialRequests/hooks/useGetRowClassName";
import SelectItem from "../MaterialRequests/Steps/SelectItem";
import ModifyItem from "../MaterialRequests/Steps/ModifyItem";
import useGetMaterialRequestsFormData from "../MaterialRequests/hooks/useGetMaterialRequestsFormData";
import { MissionService } from "../../../api/missions";
import { WarrantyVisitService } from "../../../api/warrantyVisits";
import InfoModal from "../../Modals/InfoModal";
import AddIcon from "@mui/icons-material/Add";

const initialDate = dayjs().startOf("day").add(8, "hour").add(0, "minute");

const MissionsForm = ({
  onDirtyChange,
  isThreeStepProcess = false,
  workOrderId,
  handleCloseFromAnotherForm,
}) => {
  const { data: missionData, isLoading: missionLoading } = useQuery({
    queryKey: ["mission", "new"],
    queryFn: async () => {
      const response = await Axios.get("/Missions", {
        params: {
          State: "New",
        },
      });
      return response.data;
    },
  });

  const { data: workOrderData, isLoading } = useQuery({
    queryKey: ["workOrders", "missions"],
    queryFn: async () => {
      const response = await Axios.get("/WorkOrders", {
        params: {
          State: "Missions",
        },
      });
      return response.data.result;
    },
  });

  const { data: empData, isLoading: loadingEmpData } = useQuery({
    queryKey: ["employeeRecords", "missions"],
    queryFn: async () => {
      const response = await Axios.get("/EmployeeRecords", {
        params: {
          State: "Mission",
        },
      });
      return response.data.result;
    },
  });

  if (missionLoading) {
    return <FormSpinner />;
  }

  return (
    <BaseForm
      {...{
        onDirtyChange,
        isThreeStepProcess,
        missionData,
        workOrderData,
        empData,
        loadingEmpData,
        isLoading,
        workOrderId,
        handleCloseFromAnotherForm,
        type: "missions",
      }}
    />
  );
};

export const BaseForm = ({
  isThreeStepProcess = false,
  missionData,
  workOrderData,
  empData,
  loadingEmpData,
  isLoading,
  workOrderId,
  handleCloseFromAnotherForm,
  onDirtyChange,
  type, // "missions" or "warrantyVisit"
}) => {
  const [t] = useTranslation("global");

  const [activeStep, setActiveStep] = useState(0);
  const [isRowSelected, setIsRowSelected] = useState(false);
  const [selectedWorkOrders, setSelectedWorkOrders] = useState([]);
  const [selectedTeam, setSelectedTeam] = useState([]);
  const [selectedCar, setSelectedCar] = useState([]);
  const [newWorkOrder, setNewWorkOrder] = useState([]);
  const [newTeam, setNewTeam] = useState([]);
  const [newCar, setNewCar] = useState([]);
  const [dateTime, setDateTime] = useState(initialDate);
  const [description, setDescription] = useState("");
  const queryClient = useQueryClient();
  const { enqueueSnackbar } = useSnackbar();

  // Should automatically request materials after save or not
  const [checked, setChecked] = useState(false);

  const [isMaterialRequestError, setIsMaterialRequestError] = useState(false);

  const workOrderRowById = [
    {
      id: generateId(),
      WorkOrderNo: workOrderId,
    },
  ];

  const { mutateAsync: mutationFunction, isPending: refetch } = useMutation({
    mutationFn:
      type === "missions"
        ? MissionService.addNewMission
        : WarrantyVisitService.addNewWarrantyVisit,
    onSuccess: (data) => {
      if (!skipBtnClicked && isMaterialRequestEnabled) addNewMaterialRequest();
      isThreeStepProcess && handleCloseFromAnotherForm();
      enqueueSnackbar(data.msg, {
        variant: "success",
      });
      queryClient.invalidateQueries({
        predicate: (query) => {
          return [type, "workOrderInfo"].includes(query.queryKey[0]);
        },
      });
      if (
        !isMaterialRequestEnabled ||
        (skipBtnClicked && isMaterialRequestEnabled)
      )
        handleClose();
    },
  });

  const {
    mutateAsync: addNewMaterialRequest,
    isPending: isPendingAddingNewMaterialRequest,
  } = useMutation({
    mutationFn: async () => {
      const modifiedRows = newRows.map((row) => ({
        StoreItemsTemplateCode: row.StoreItemsTemplateCode,
        QTY: Number(row.Quantity),
      }));

      return Axios.post("/MaterialRequests", {
        Flag: checked,
        Items: !skipBtnClicked && modifiedRows,
        State: "New",
        WorkOrders: isThreeStepProcess ? workOrderRowById : newWorkOrder,
      });
    },
    onSuccess: (data) => {
      isThreeStepProcess ? handleCloseFromAnotherForm() : handleClose();
      enqueueSnackbar(data.data.msg, { variant: "success" });
      queryClient.invalidateQueries({
        predicate: (query) => {
          return ["materialRequests", "workOrderInfo"].includes(
            query.queryKey[0]
          );
        },
      });
    },
    onError: () => {
      setIsMaterialRequestError(true);
    },
  });

  const { data: carsData } = useQuery({
    queryKey: ["companyFleet", "missionDate"],
    queryFn: async () => {
      const response = await Axios.get("/CompanyFleet", {
        params: {
          MissionDateTime: initialDate.format("YYYY-MM-DD HH:mm:ss"),
        },
      });
      return response.data.result;
    },
  });

  const [skipBtnClicked, setSkipBtnClicked] = useState(false);

  const isMaterialRequestEnabled = missionData?.MaterialRequest;

  const isLastStep = isThreeStepProcess
    ? isMaterialRequestEnabled
      ? activeStep === 3
      : activeStep === 2
    : isMaterialRequestEnabled
    ? activeStep === 4
    : activeStep === 3;

  const handleNext = async () => {
    if (!isMaterialRequestError) {
      if (!isThreeStepProcess) {
        if (activeStep === 0) {
          const selectedData = workOrderData.filter((row) =>
            selectedWorkOrders.includes(row.idWorkOrder)
          );
          setNewWorkOrder(selectedData);
        }
      }
      if (isThreeStepProcess ? activeStep === 0 : activeStep === 1) {
        const selectedData = empData.filter((row) =>
          selectedTeam.includes(row.idEmpInfo)
        );
        setNewTeam(selectedData);
      }
      if (isThreeStepProcess ? activeStep === 1 : activeStep === 2) {
        const selectedData = carsData.filter((row) =>
          selectedCar.includes(row.idCarsInfoCarsNumbers)
        );
        setNewCar(selectedData);
      }

      if (
        (isThreeStepProcess &&
          isMaterialRequestEnabled &&
          activeStep === (isThreeStepProcess ? 2 : 3)) ||
        (!isThreeStepProcess && isMaterialRequestEnabled && activeStep === 3)
      ) {
        if (selectedMaterialRequestItems.length === 0) {
          setSkipBtnClicked(true);
        } else {
          setSkipBtnClicked(false);
        }
      }

      if (
        (isThreeStepProcess &&
          isMaterialRequestEnabled &&
          activeStep === (isThreeStepProcess ? 2 : 3)) ||
        (!isThreeStepProcess && isMaterialRequestEnabled && activeStep === 3)
      ) {
        if (
          newRows.find(
            (row) => row.Quantity == 0 || row.Quantity === "" || !row.Quantity
          )
        ) {
          setApplyRowClassName(true);
          return setMessage(
            t("modules.material_request.error_messages.zero_quantity")
          );
        } else if (newRows.find((row) => row.Quantity < 0)) {
          return setMessage(
            t("modules.material_request.error_messages.negative_quantity")
          );
        } else {
          setApplyRowClassName(false);
          setMessage(null);
        }
      }
    } else {
      if (
        activeStep === 1 &&
        isMaterialRequestEnabled &&
        isMaterialRequestError
      ) {
        if (
          newRows.find(
            (row) => row.Quantity <= 0 || row.Quantity === "" || !row.Quantity
          )
        ) {
          setApplyRowClassName(true);
          setMessage("Quantity must be greater than zero ");
          return;
        } else {
          setApplyRowClassName(false);
          setMessage(null);
        }
      }
    }

    if (isLastStep) {
      return mutationFunction({
        Cars: newCar,
        Employees: newTeam,
        WorkOrders: isThreeStepProcess ? workOrderRowById : newWorkOrder,
        MissionStart: dateTime.format("YYYY-MM-DD HH:mm:ss"),
        MissionDescription: description,
      });
    }

    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    if (!isMaterialRequestError) {
      if (
        ((!isThreeStepProcess && activeStep === 5) ||
          (isThreeStepProcess && activeStep === 4)) &&
        skipBtnClicked &&
        isMaterialRequestEnabled
      ) {
        return setActiveStep((prevActiveStep) => prevActiveStep - 3);
      }
      if (
        ((!isThreeStepProcess && activeStep === 5) ||
          (isThreeStepProcess && activeStep === 4)) &&
        isMaterialRequestEnabled &&
        !skipBtnClicked
      )
        return setActiveStep((prevActiveStep) => prevActiveStep - 1);
    } else {
      if (
        (activeStep === 2 && isMaterialRequestEnabled && skipBtnClicked) ||
        (activeStep === 2 && isMaterialRequestEnabled && !skipBtnClicked)
      ) {
        return setActiveStep((prevActiveStep) => prevActiveStep - 2);
      }
    }

    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleRowSelection = (selectedItems) => {
    setIsRowSelected(selectedItems.length > 0);
  };

  const handleDeleteRow = (id, type) => {
    if (type === "team") {
      setNewTeam((prevRows) => prevRows.filter((row) => row.idEmpInfo !== id));
      setSelectedTeam((prevSelectedItems) =>
        prevSelectedItems.filter((selectedId) => selectedId !== id)
      );
    } else if (type === "car") {
      setNewCar((prevRows) =>
        prevRows.filter((row) => row.idCarsInfoCarsNumbers !== id)
      );
      setSelectedCar((prevSelectedItems) =>
        prevSelectedItems.filter((selectedId) => selectedId !== id)
      );
    }
  };

  let steps = [];

  if (!isMaterialRequestError) {
    steps = [
      t("modules.missions.steps.select_team"),
      t("modules.missions.steps.select_car"),
      t("modules.missions.steps.review"),
    ];

    if (!isThreeStepProcess) {
      steps = [
        type === "missions"
          ? t("modules.missions.steps.select_work_order")
          : t("modules.warranty_visit.steps.select_work_order"),
        ...steps,
      ];
    }

    if (missionData?.MaterialRequest) {
      steps = [
        ...steps.slice(0, -1),
        t("modules.missions.steps.select_material"),
        t("modules.missions.steps.review"),
      ];
    }
  } else {
    if (missionData?.MaterialRequest && isMaterialRequestError) {
      steps = [
        t("modules.missions.steps.select_material"),
        t("modules.missions.steps.review"),
      ];
    } else {
      steps = [t("modules.missions.steps.review")];
    }
  }

  const { handleSubmit, dirty } = useFormik({
    onSubmit: handleNext,
    validateOnChange: true,
  });

  const { handleClose } = useHandleForms({ dirty, onDirtyChange });

  useEffect(() => {
    if (isRowSelected !== dirty) {
      onDirtyChange(isRowSelected);
    }
  }, [isRowSelected, dirty, onDirtyChange]);

  // Material Request Logic
  const [message, setMessage] = useState(null);

  const { data: materialRequestItems, isLoading: isMaterialRequestLoading } =
    useGetMaterialRequestsFormData(missionData?.MaterialRequest);

  const {
    newRows,
    selectedItems: selectedMaterialRequestItems,
    handleRowSelection: handleMaterialRequestRowSelection,
    setNewRows,
    setSelectedItems: setSelectedMaterialRequestItems,
    handleDeleteRow: handleDeleteMaterialRequestRow,
  } = useHandleRowSelection();

  const { getRowClassName, setApplyRowClassName } = useGetRowClassName(newRows);

  // Handling selecting material items
  const [isMaterialsModalOpen, setIsMaterialsModalOpen] = useState(false);

  const handleOpenMaterialsModal = () => setIsMaterialsModalOpen(true);

  const handleCloseMaterialsModal = () => {
    setIsMaterialsModalOpen(false);
    setSkipBtnClicked(false);
    const selectedMaterialRequestItemsData = materialRequestItems.filter(
      (row) =>
        selectedMaterialRequestItems.includes(row.idStoreItemsTemplateInfo)
    );
    setNewRows(selectedMaterialRequestItemsData);
  };

  // Modify Items For Material Request
  const modifyItemComponent = (
    <ModifyItem
      rows={newRows}
      onClick={handleDeleteMaterialRequestRow}
      setNewRows={setNewRows}
      getRowClassName={getRowClassName}
      addMaterialsBtn={{
        addMaterialsFunction: handleOpenMaterialsModal,
      }}
    />
  );

  const selectTeamComponent = (
    <SelectTeam
      rows={empData || []}
      onRowSelection={handleRowSelection}
      selectedRows={selectedTeam}
      setSelectedRows={setSelectedTeam}
      loading={loadingEmpData}
    />
  );

  const selectCarComponent = (
    <SelectCar
      rows={carsData || []}
      selectedRows={selectedCar}
      setSelectedRows={setSelectedCar}
    />
  );

  const reviewComponent = (
    <Review
      workOrderRows={newWorkOrder}
      employeeRows={newTeam}
      carsRows={newCar}
      onClick={handleDeleteRow}
      no={missionData.MissionNo}
      dateTime={dateTime.format("YYYY-MM-DD hh:mm A")}
      description={description}
      setDescription={setDescription}
      descriptionLabel={t("modules.missions.fields.mission_description")}
      isThreeStepProcess={isThreeStepProcess}
      itemsRows={newRows}
      isMaterialRequestEnabled={isMaterialRequestEnabled}
      skipBtnClicked={skipBtnClicked}
    />
  );

  const selectWorkOrderComponent = (
    <SelectWorkOrder
      rows={workOrderData || []}
      onRowSelection={handleRowSelection}
      selectedRows={selectedWorkOrders}
      setSelectedRows={setSelectedWorkOrders}
      loading={isLoading}
      type={type}
    />
  );

  let views = {};

  if (!isMaterialRequestError) {
    if (isThreeStepProcess) {
      views = {
        0: selectTeamComponent,
        1: selectCarComponent,
      };
    } else {
      views = {
        0: selectWorkOrderComponent,
        1: selectTeamComponent,
        2: selectCarComponent,
      };
    }

    if (isMaterialRequestEnabled) {
      views = {
        ...views,
        [Object.keys(views).length]:
          selectedMaterialRequestItems.length > 0 ? (
            modifyItemComponent
          ) : (
            <AddMaterialsView handleOpenModal={handleOpenMaterialsModal} />
          ),
        [Object.keys(views).length + 1]: reviewComponent,
      };
    } else {
      views = {
        ...views,
        [Object.keys(views).length]: reviewComponent,
      };
    }
  } else {
    views = {
      0:
        selectedMaterialRequestItems.length > 0 ? (
          modifyItemComponent
        ) : (
          <AddMaterialsView handleOpenModal={handleOpenMaterialsModal} />
        ),
      1: reviewComponent,
    };
  }

  const label = isLastStep
    ? t("modules.buttons.save")
    : t("modules.buttons.next");

  return (
    <>
      <form onSubmit={handleSubmit}>
        <Stepper activeStep={activeStep}>
          {steps.map((label) => {
            return (
              <Step key={label}>
                <StepLabel>{label}</StepLabel>
              </Step>
            );
          })}
        </Stepper>
        {activeStep === 0 && (
          <Box display="flex" alignItems="center" gap="10px" marginTop="10px">
            <TextField
              label={t("data_grid.headers.mission_no")}
              sx={{ width: "100%", margin: "7px 0" }}
              value={missionData?.MissionNo}
              size="small"
              InputProps={{
                readOnly: true,
              }}
            />
            <Box>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DateTimePicker
                  label={t("modules.missions.fields.mission_start_date")}
                  value={dayjs(dateTime)}
                  format="YYYY/MM/DD hh:mm A"
                  onChange={(newValue) => {
                    setDateTime(newValue);
                  }}
                  sx={{
                    width: "30rem",
                  }}
                  slotProps={{
                    textField: {
                      size: "small",
                    },
                  }}
                />
              </LocalizationProvider>
            </Box>
          </Box>
        )}
        {views[activeStep]}

        <Stack
          direction="row"
          gap={2}
          justifyContent="flex-end"
          alignItems="flex-start"
        >
          {isLastStep && !skipBtnClicked && isMaterialRequestEnabled && (
            <FormControlLabel
              control={
                <Checkbox
                  checked={checked}
                  onChange={(event) => {
                    setChecked(event.target.checked);
                  }}
                />
              }
              label={t("modules.material_request.label.after_save")}
              sx={{
                marginBottom: 1,
                marginLeft: 1,
                flexGrow: 1,
              }}
            />
          )}
          {message &&
            newRows.length > 0 &&
            ((!isThreeStepProcess && activeStep === 3) ||
              (isThreeStepProcess && activeStep === 2)) && (
              <Typography
                color="error"
                fontSize="16px"
                fontWeight={500}
                paddingLeft="10px"
                flexGrow={1}
              >
                {message}
              </Typography>
            )}
          <StepperButton
            isPending={refetch || isPendingAddingNewMaterialRequest}
            onClick={handleNext}
            handleBack={handleBack}
            activeStep={activeStep}
            disabled={
              !isRowSelected ||
              (isLastStep && description === "") ||
              !dayjs(dateTime).isValid()
            }
            label={label}
            isLastStep={isLastStep}
          />
        </Stack>
      </form>
      <InfoModal
        open={isMaterialsModalOpen}
        handleClose={handleCloseMaterialsModal}
        title={t("modules.missions.steps.select_material")}
        width="62rem"
        content={
          <Stack spacing={3} p={2}>
            <SelectItem
              onRowSelection={handleMaterialRequestRowSelection}
              rows={materialRequestItems || []}
              loading={isMaterialRequestLoading}
              selectedRows={selectedMaterialRequestItems}
              setSelectedRows={setSelectedMaterialRequestItems}
            />
            <Button
              variant="contained"
              sx={{
                textTransform: "capitalize",
                alignSelf: "flex-end",
              }}
              onClick={handleCloseMaterialsModal}
            >
              {t("buttons.continue")}
            </Button>
          </Stack>
        }
      />
    </>
  );
};

const AddMaterialsView = ({ handleOpenModal }) => {
  const { t } = useTranslation("global");
  return (
    <Box
      sx={{
        height: 400,
        width: "100%",
        position: "relative",
      }}
    >
      <Stack
        spacing={2}
        alignItems="center"
        sx={{
          position: "absolute",
          left: "50%",
          top: "30%",
          transform: "translate(-50%, -30%)",
          width: "100%",
        }}
      >
        <Typography
          sx={{
            textAlign: "center",
          }}
        >
          {t("modules.missions.messages.add_materials")}
        </Typography>
        <IconButton
          size="small"
          sx={{
            textTransform: "capitalize",
            backgroundColor: "primary.main",
            borderRadius: 1,
            color: "#fff",
            ":hover": {
              backgroundColor: "primary.dark",
            },
          }}
          onClick={handleOpenModal}
        >
          <AddIcon />
          <Typography>{t("modules.missions.buttons.add_materials")}</Typography>
        </IconButton>
      </Stack>
    </Box>
  );
};

export default MissionsForm;
