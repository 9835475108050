import { useState } from "react";
import { Box, Tooltip } from "@mui/material";
import Axios from "../../../network/Axios";
import { useSnackbar } from "notistack";
import { useTranslation } from "react-i18next";
import { GridActionsCellItem } from "@mui/x-data-grid-pro";
import DeleteIcon from "@mui/icons-material/Delete";
import InfoModal from "../../../components/Modals/InfoModal";
import FormsDataGrid from "../../../components/DataGrid/FormsDataGrid";
import ButtonGroup from "../../../components/forms/ButtonGroup/ButtonGroup";
import { useQuery, useMutation, useQueryClient } from "@tanstack/react-query";
import ConfirmationInfoModal from "../../../components/Modals/ConfirmationInfoModal";

const AddItems = ({
  open,
  handleClose,
  setSelectedRows,
  selectedRows,
  setSelectedItems,
  onClickSave,
  isPending,
}) => {
  const [t] = useTranslation("global");

  const { data: rows, isLoading } = useQuery({
    queryKey: ["materialRequests", "new"],
    queryFn: async () => {
      const response = await Axios.get("/MaterialRequests", {
        params: {
          State: "New",
        },
      });
      return response.data.result;
    },
    enabled: open,
  });

  const columns = [
    {
      field: "StoreItemsTemplateCode",
      headerName: t("data_grid.headers.item_code"),
    },
    {
      field: "StoreItemsTemplateName",
      headerName: t("data_grid.headers.item_description"),
      flex: 1,
    },
    {
      field: "StoreItemsTemplateUnit",
      headerName: t("data_grid.headers.unit"),
      width: 100,
    },
    {
      field: "StoreItemsTemplateType",
      headerName: t("data_grid.headers.type"),
    },
    {
      field: "StoreItemsTemplateQty",
      headerName: t("data_grid.headers.item_total_balance"),
      width: 150,
    },
  ];
  return (
    <InfoModal
      open={open}
      title={t("replacement_model.add_items")}
      handleClose={handleClose}
      width="45rem"
      content={
        <Box padding={1.2}>
          <FormsDataGrid
            rows={rows || []}
            columns={columns}
            loading={isLoading}
            getRowId={(row) => row.idStoreItemsTemplateInfo}
            height={420}
            toolbar
            showQuickFilter
            checkboxSelection
            disableSelectionOnClick
            onRowSelectionModelChange={(newSelectedRows) => {
              setSelectedRows(newSelectedRows);
              const selectedData = rows.filter((row) =>
                newSelectedRows.includes(row.idStoreItemsTemplateInfo)
              );
              setSelectedItems(selectedData);
            }}
            rowSelectionModel={selectedRows}
          />
          <ButtonGroup
            onClickClose={handleClose}
            saveLabel={t("modules.buttons.save")}
            onClickSave={onClickSave}
            isPending={isPending}
          />
        </Box>
      }
    />
  );
};

const ItemsReplacements = ({ open, handleClose, title, id }) => {
  const [t] = useTranslation("global");
  const queryClient = useQueryClient();
  const { enqueueSnackbar } = useSnackbar();
  const [addItems, setAddItems] = useState(false);
  const [selectedRows, setSelectedRows] = useState([]);
  const [selectedItems, setSelectedItems] = useState([]);
  const [confirmModal, setConfirmModal] = useState(false);
  const [idToDelete, setIdToDelete] = useState(null);

  const handleDeleteItem = (id) => {
    setConfirmModal(true);
    setIdToDelete(id);
  };

  const { data, isLoading } = useQuery({
    queryKey: ["replacementsInventoryItems"],
    queryFn: async () => {
      const response = await Axios.get("/InventoryManagement", {
        params: { State: "ReplacementGroupItems", SelectedNo: id },
      });
      return response.data.ReplacementGroupItems;
    },
    enabled: open,
  });
  const columns = [
    {
      field: "StoreItemsTemplateCode",
      headerName: t("data_grid.headers.item_code"),
      width: 130,
    },
    {
      field: "StoreItemsTemplateName",
      headerName: t("data_grid.headers.item_name"),
      width: 130,
      flex: 1,
    },
    {
      field: "StoreItemsTemplateUnit",
      headerName: t("data_grid.headers.unit"),
      width: 100,
    },
    {
      field: "StoreItemsTemplateType",
      headerName: t("data_grid.headers.type"),
      width: 100,
    },
    {
      field: "StoreItemsTemplateQty",
      headerName: t("data_grid.headers.qty"),
      width: 90,
    },
    {
      field: "Actions",
      headerName: "Actions",
      type: "actions",
      getActions: (params) => [
        <Tooltip title="Delete" key="delete">
          <GridActionsCellItem
            key={params.row.StoreItemsTemplateCode}
            icon={<DeleteIcon color="error" />}
            label="Delete"
            onClick={() => handleDeleteItem(params.row.StoreItemsTemplateCode)}
          />
        </Tooltip>,
      ],
    },
  ];
  const handleCloseSave = () => {
    setAddItems(false);
    setSelectedItems([]);
    setSelectedRows([]);
  };
  const handleAddGroup = () => {
    setAddItems(true);
  };
  const items = selectedItems.map((item) => ({
    StoreItemsTemplateCode: item.StoreItemsTemplateCode,
  }));

  const { mutateAsync: addNewItem, isPending } = useMutation({
    mutationFn: async () => {
      return Axios.patch("/InventoryManagement", {
        Items: items,
        State: "AddGroupItem",
        GroupName: id,
      });
    },
    onSuccess: (data) => {
      if (!data) return;
      handleCloseSave();
      enqueueSnackbar("Added Successfully", { variant: "success" });
      queryClient.invalidateQueries({
        queryKey: ["replacementsInventoryItems"],
      });
    },
  });
  const { mutateAsync: deleteItem, isPending: pendingDelete } = useMutation({
    mutationFn: async () => {
      return Axios.patch("/InventoryManagement", {
        State: "DeleteGroupItem",
        SelectedNo: `${idToDelete}`,
      });
    },
    onSuccess: (data) => {
      if (!data) return;
      handleCloseSave();
      enqueueSnackbar("Deleted Successfully", { variant: "success" });
      queryClient.invalidateQueries({
        queryKey: ["replacementsInventoryItems"],
      });
    },
  });

  return (
    <>
      <InfoModal
        open={open}
        title={title}
        handleClose={handleClose}
        width="48rem"
        content={
          <Box padding={1.2}>
            <FormsDataGrid
              rows={data || []}
              columns={columns}
              loading={isLoading}
              getRowId={(row) => row.idMaterialsReplacementGroupsTempleteItems}
              height={420}
              toolbar
              showAdd
              showQuickFilter
              disableSelectionOnClick
              addFunction={handleAddGroup}
            />
          </Box>
        }
      />
      <AddItems
        open={addItems}
        handleClose={handleCloseSave}
        selectedRows={selectedRows}
        setSelectedRows={setSelectedRows}
        selectedItems={selectedItems}
        setSelectedItems={setSelectedItems}
        onClickSave={addNewItem}
        isPending={isPending}
      />
      <ConfirmationInfoModal
        open={confirmModal}
        handleClose={() => setConfirmModal(false)}
        onClick={deleteItem}
        description={t(
          "info_modules.material_request.confirmation.delete_item"
        )}
        isPending={pendingDelete}
        color="error"
      />
    </>
  );
};

export default ItemsReplacements;
