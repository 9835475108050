import { Box, Grid } from "@mui/material";
import InfoCard from "../../../../../components/InfoPagesCard/InfoCard";
import ChipComponent from "../../../../../components/Chips/ChipComponent";
import InfoModal from "../../../../../components/Modals/InfoModal";
import { useState } from "react";
import EditNationalID from "./EditNationalID";
import ImageModal from "../../../../../components/Modals/ImageModal";
import { useQuery } from "@tanstack/react-query";
import { employeeRecordsService } from "../../../../../api/employeeRecords";
import { useParams } from "react-router-dom";
import { combineImages, convertImage } from "../../../../../utils/utils";
import { useTranslation } from "react-i18next";
import { useLabels } from "./hooks/useLabels";

const NationalID = ({ data }) => {
  const [t] = useTranslation("global");

  const { empCode } = useParams();

  const {
    EmpAddress,
    EmpDateOfBirth,
    EmpEffictiveDate,
    EmpGender,
    EmpJob,
    EmpNationalID,
    EmpReligion,
    EmpSocialStatus,
  } = data || {
    EmpAddress: "NA",
    EmpDateOfBirth: "NA",
    EmpEffictiveDate: "NA",
    EmpGender: "NA",
    EmpJob: "NA",
    EmpNationalID: "NA",
    EmpReligion: "NA",
    EmpSocialStatus: "NA",
  };

  const labels = useLabels();

  const nationalIdDetails = [
    {
      label: labels.nationalID,
      value: EmpNationalID,
    },
    {
      label: labels.date_of_birth,
      value: EmpDateOfBirth,
    },
    {
      label: labels.effective_date,
      value: EmpEffictiveDate,
    },
    {
      label: labels.gender,
      value: EmpGender,
    },
    {
      label: labels.religion,
      value: EmpReligion,
    },
    {
      label: labels.marital_status,
      value: EmpSocialStatus,
    },
    {
      label: labels.job_title,
      value: EmpJob,
    },
    {
      label: labels.home_address,
      value: EmpAddress,
    },
  ];

  // Handling the edit modal
  const [isModalOpen, setIsModalOpen] = useState(false);

  const handleOpenModal = () => setIsModalOpen(true);
  const handleCloseModal = () => setIsModalOpen(false);

  // Handling the image modal
  const [isImageModalOpen, setIsImageModalOpen] = useState(false);
  const [image, setImage] = useState(null);

  const handleOpenImageModal = () => setIsImageModalOpen(true);
  const handleCloseImageModal = () => setIsImageModalOpen(false);

  const [isImageLoading, setIsImageLoading] = useState(false);

  const { refetch } = useQuery({
    queryKey: ["nationalIdFormData", empCode],
    queryFn: () => employeeRecordsService.getNationalIdFormData(empCode),
    enabled: false,
  });

  return (
    <>
      <InfoCard
        viewButton={data}
        onClickView={() => {
          handleOpenImageModal();
          setIsImageLoading(true);
          refetch().then((data) => {
            const frontImage = data.data.NationalID[0].EmpNationalIDPhotoFront;

            const backImage = data.data.NationalID[0].EmpNationalIDPhotoBack;

            if (frontImage === null && backImage === null) {
              setImage(null);
              setIsImageLoading(false);
              return;
            }
            const image1 = convertImage(frontImage.data);

            const image2 = convertImage(backImage.data);

            const imageOneInstance = new Image();
            const imageTwoInstance = new Image();

            imageOneInstance.src = image1;
            imageTwoInstance.src = image2;

            combineImages(image1, image2)
              .then((b64) => setImage(b64))
              .then(() => setIsImageLoading(false));
          });
        }}
        editButton
        onClickEdit={handleOpenModal}
        title={t("info_modules.employee_records.cards.national_id.title")}
        content={
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: "1rem",
            }}
          >
            <Grid container spacing={1}>
              {nationalIdDetails.map((property) => (
                <Grid item xs={4} key={property.label}>
                  <ChipComponent
                    label={property.label}
                    value={property.value}
                  />
                </Grid>
              ))}
            </Grid>
          </Box>
        }
      />
      <InfoModal
        open={isModalOpen}
        title={t("info_modules.employee_records.cards.national_id.edit")}
        content={<EditNationalID handleClose={handleCloseModal} />}
        handleClose={handleCloseModal}
        width="40rem"
      />
      <ImageModal
        image={image}
        image2
        open={isImageModalOpen}
        handleClose={handleCloseImageModal}
        title={t(
          "info_modules.employee_records.cards.national_id.image_titles.national_id_documents"
        )}
        loading={isImageLoading}
        saveLabel={`${empCode.replace("/", "-")}-NationalID`}
      />
    </>
  );
};

export default NationalID;
