// dateRangeSlice.js
import { createSlice } from "@reduxjs/toolkit";
import dayjs from "dayjs";

const startDateVacation = dayjs().startOf("year").startOf("day");
const endDateVacation = dayjs().endOf("year").endOf("day");

const initialState = {
  startDateVacation,
  endDateVacation,
};

const dateRangeVacationSlice = createSlice({
  name: "dateRangeVacation",
  initialState,
  reducers: {
    setStartDateVacation(state, action) {
      state.startDateVacation = action.payload;
    },
    setEndDateVacation(state, action) {
      state.endDateVacation = action.payload;
    },
  },
});

export const { setStartDateVacation, setEndDateVacation } =
  dateRangeVacationSlice.actions;
export default dateRangeVacationSlice.reducer;
