import Axios from "../network/Axios";

const AddNewPurchaseRequest = async (data) => {
  const res = await Axios.post("/PurchaseRequests", data);

  return res.data;
};

const GetPurchaseRequests = async () => {
  const response = await Axios.get("/PurchaseRequests");
  return response.data.result;
};

const GetPurchaseRequestsNewFormData = async () => {
  const response = await Axios.get("/PurchaseRequests", {
    params: {
      State: "New",
    },
  });
  return response.data.result;
};

export const PurchaseRequestsService = {
  AddNewPurchaseRequest,
  GetPurchaseRequests,
  GetPurchaseRequestsNewFormData,
};
