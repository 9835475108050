import { useState } from "react";
import { Box, Grid, Button, TextField } from "@mui/material";
import { useTranslation } from "react-i18next";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";
import SelectEmployee from "../SelectEmployee";
import SelectCar from "../SelectCar";

const ModifyItems = ({
  setFieldValue,
  handleSubmit,
  values,
  selectedEmp,
  setSelectedEmp,
  selectedItemsEmp,
  setSelectedItemsEmp,
  selectedItemsCar,
  setSelectedItemsCar,
  selectedCar,
  setSelectedCar,
  errors,
  touched,
  handleBlur,
}) => {
  const [t] = useTranslation("global");
  const [employeeModal, setEmployeeModal] = useState(false);
  const [carModal, setCarModal] = useState(false);

  return (
    <Box padding="12px 10px">
      <form onSubmit={handleSubmit}>
        <Grid container spacing={1.2}>
          <Grid item xs={6}>
            <TextField
              label={t("info_modules.material_request.description.paper_no")}
              size="small"
              fullWidth
              value={values.paperNo}
              onChange={(e) => setFieldValue("paperNo", e.target.value)}
            />
          </Grid>
          <Grid item xs={6}>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DateTimePicker
                value={values.date}
                label={t("info_modules.material_request.description.date")}
                onChange={(newValue) => {
                  setFieldValue("date", newValue);
                }}
                sx={{
                  width: "31rem",
                }}
                format="YYYY/MM/DD"
                slotProps={{
                  textField: {
                    size: "small",
                  },
                }}
              />
            </LocalizationProvider>
          </Grid>
          <Grid item xs={6}>
            <Box display="flex" alignItems="center">
              <TextField
                label={t(
                  "info_modules.material_request.description.emp_name_required"
                )}
                size="small"
                fullWidth
                value={values.employeeName}
                onBlur={handleBlur}
                onChange={(e) => setFieldValue("employeeName", e.target.value)}
                error={!!touched.employeeName && !!errors.employeeName}
                helperText={touched.employeeName && errors.employeeName}
                InputProps={{
                  readOnly: true,
                }}
              />

              <Button
                variant="contained"
                onClick={() => setEmployeeModal(true)}
                sx={{ padding: "7px" }}
              >
                {t("info_modules.material_request.buttons.select")}
              </Button>
            </Box>
          </Grid>
          <Grid item xs={6}>
            <Box display="flex" alignItems="center">
              <TextField
                label={t(
                  "info_modules.material_request.description.car_name_required"
                )}
                size="small"
                fullWidth
                value={values.carName}
                onBlur={handleBlur}
                onChange={(e) => setFieldValue("carName", e.target.value)}
                error={!!touched.carName && !!errors.carName}
                helperText={touched.carName && errors.carName}
                InputProps={{
                  readOnly: true,
                }}
              />
              <Button
                variant="contained"
                onClick={() => setCarModal(true)}
                sx={{ padding: "7px" }}
              >
                {t("info_modules.material_request.buttons.select")}
              </Button>
            </Box>
          </Grid>
          <Grid item xs={6}>
            <TextField
              label={t("info_modules.material_request.description.destination")}
              size="small"
              fullWidth
              value={values.destination}
              onChange={(e) => setFieldValue("destination", e.target.value)}
            />
          </Grid>
        </Grid>
        <SelectEmployee
          open={employeeModal}
          handleClose={() => setEmployeeModal(false)}
          selectedEmp={selectedEmp}
          setSelectedEmp={setSelectedEmp}
          selectedItemsEmp={selectedItemsEmp}
          setSelectedItemsEmp={setSelectedItemsEmp}
          setFieldValue={setFieldValue}
        />
        <SelectCar
          open={carModal}
          handleClose={() => setCarModal(false)}
          selectedCar={selectedCar}
          setSelectedCar={setSelectedCar}
          selectedItemsCar={selectedItemsCar}
          setSelectedItemsCar={setSelectedItemsCar}
          setFieldValue={setFieldValue}
        />
      </form>
    </Box>
  );
};
export default ModifyItems;
