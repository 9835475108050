import { useEffect, useState } from "react";
import { useSnackbar } from "notistack";
import { TextField, Box } from "@mui/material";
import { useTranslation } from "react-i18next";
import InfoModal from "../../../components/Modals/InfoModal";
import { useQueryClient, useMutation } from "@tanstack/react-query";
import ButtonGroup from "../../../components/forms/ButtonGroup/ButtonGroup";
import { AccountsProjectsService } from "../../../api/accounts&projects";

const Edit = ({ handleClose, open, label, title, value }) => {
  const [t] = useTranslation("global");
  const [editedValue, setEditedValue] = useState(value);
  const queryClient = useQueryClient();
  const { enqueueSnackbar } = useSnackbar();

  const handleCloseSave = () => {
    handleClose();
    setEditedValue("");
  };
  useEffect(() => {
    setEditedValue(value);
  }, [value]);

  const { mutateAsync: editNames, isLoading: isPending } = useMutation({
    mutationFn: async () => {
      if (title === t("modules.accounts_projects.modals.edit_account")) {
        return AccountsProjectsService.editAccount({
          CompanyAccountName: editedValue,
          SelectedNo: value,
        });
      } else if (title === t("modules.accounts_projects.modals.edit_project")) {
        return AccountsProjectsService.editProject({
          CompanyProjectsName: editedValue,
          SelectedNo: value,
        });
      } else if (
        title === t("modules.accounts_projects.modals.edit_sub_project")
      ) {
        return AccountsProjectsService.editSubProject({
          SubProjectsName: editedValue,
          SelectedNo: value,
        });
      }
    },
    onSuccess: (data) => {
      if (!data) return;
      handleCloseSave();
      enqueueSnackbar(data.msg, { variant: "success" });
      queryClient.invalidateQueries({ queryKey: ["accountsProjects"] });
    },
  });

  return (
    <InfoModal
      open={open}
      handleClose={handleCloseSave}
      title={title}
      width="30rem"
      content={
        <Box sx={{ margin: 1 }}>
          <TextField
            fullWidth
            label={label}
            size="small"
            variant="outlined"
            value={editedValue}
            onChange={(e) => setEditedValue(e.target.value)}
          />
          <ButtonGroup
            isPending={isPending}
            onClickClose={handleCloseSave}
            onClickSave={editNames}
            saveLabel={t("modules.buttons.save")}
          />
        </Box>
      }
    />
  );
};

export default Edit;
