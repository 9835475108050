import { Box, TextField, Chip, Autocomplete } from "@mui/material";
import { useTranslation } from "react-i18next";
import ButtonGroup from "../../../../components/forms/ButtonGroup/ButtonGroup";
import { useMutation, useQueryClient, useQuery } from "@tanstack/react-query";
import { useParams } from "react-router-dom";
import Axios from "../../../../network/Axios";
import { useSnackbar } from "notistack";
import InfoModal from "../../../../components/Modals/InfoModal";
import FormSpinner from "../../../../components/Spinners/FormSpinner";
import { useFormik } from "formik";
import * as Yup from "yup";

const AggregateOrSeparate = ({ open, handleClose, action }) => {
  const [t] = useTranslation("global");
  const queryClient = useQueryClient();
  const { inventoryManagementId } = useParams();
  const { enqueueSnackbar } = useSnackbar();

  const validationSchema = Yup.object().shape({
    quantity: Yup.number()
      .required("Quantity Required")
      .min(1, "Must be more than 0"),
  });

  const initialValues = {
    toStore: "",
    quantity: "",
  };
  const {
    handleSubmit,
    values,
    resetForm,
    errors,
    touched,
    handleBlur,
    setFieldValue,
  } = useFormik({
    initialValues,
    validationSchema,
    onSubmit: () => {
      if (Object.keys(errors).length !== 0) {
        return;
      } else {
        aggregateOrSeparate();
      }
    },
  });

  const handleCloseSave = () => {
    handleClose();
    resetForm();
  };

  const { data: stores, isLoading } = useQuery({
    queryKey: ["inventory", "stores"],
    queryFn: async () => {
      const response = await Axios.get("/InventoryManagement", {
        params: {
          State: "ItemStoreTransafer",
        },
      });
      return response.data.CompanyStoresName;
    },
    enabled: open,
  });

  const { mutateAsync: aggregateOrSeparate, isPending } = useMutation({
    mutationFn: async () => {
      return Axios.patch("/InventoryManagement", {
        QTY: values.quantity,
        SelectedNo: inventoryManagementId,
        State: action === "aggregate" ? "Aggregate" : "Separate",
        ToStore: values.toStore,
      });
    },
    onSuccess: (data) => {
      if (!data) return;
      enqueueSnackbar(
        action === "aggregate"
          ? "Item Aggregated Successfully"
          : "Item Separated Successfully",
        {
          variant: "success",
        }
      );
      queryClient.invalidateQueries({
        queryKey: ["inventory", "info"],
      });
      handleCloseSave();
    },
  });

  return (
    <InfoModal
      open={open}
      handleClose={handleCloseSave}
      title={
        action === "aggregate"
          ? t("info_modules.inventory_management.description.aggregate_item")
          : t("info_modules.inventory_management.description.separate_item")
      }
      width="28rem"
      content={
        <form onSubmit={handleSubmit}>
          <Box padding={1} display="flex" flexDirection="column" gap={1.2}>
            {isLoading ? (
              <Box paddingBottom="60px">
                <FormSpinner />
              </Box>
            ) : (
              <>
                <Chip
                  label={inventoryManagementId}
                  sx={{ borderRadius: 1, fontSize: "0.9rem" }}
                />
                <Autocomplete
                  id="to-store"
                  options={stores || []}
                  getOptionLabel={(option) => option.CompanyStoresName}
                  sx={{ width: "27rem" }}
                  size="small"
                  onChange={(event, value) =>
                    setFieldValue(
                      "toStore",
                      value ? value.CompanyStoresName : ""
                    )
                  }
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label={t(
                        "info_modules.inventory_management.description.to_store"
                      )}
                    />
                  )}
                />

                <TextField
                  label={t("data_grid.headers.quantity")}
                  variant="outlined"
                  size="small"
                  value={values.quantity}
                  onChange={(e) => setFieldValue("quantity", e.target.value)}
                  onBlur={handleBlur}
                  error={!!touched.quantity && !!errors.quantity}
                  helperText={touched.quantity ? errors.quantity : ""}
                />
                <ButtonGroup
                  onClickClose={handleClose}
                  isPending={isPending}
                  onClickSave={handleSubmit}
                  saveLabel={t("modules.buttons.save")}
                />
              </>
            )}
          </Box>
        </form>
      }
    />
  );
};

export default AggregateOrSeparate;
