import { Warning } from "@mui/icons-material";
import {
  Backdrop,
  Button,
  Fade,
  Modal,
  Stack,
  Typography,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { clearErrorModalState } from "../../store/slices/authSlice";
import { useTranslation } from "react-i18next";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  backgroundColor: "background.default",
  boxShadow:
    "rgba(145, 158, 171, 0.24) 0px 0px 2px 0px, rgba(145, 158, 171, 0.24) -20px 20px 40px -4px",
  borderRadius: "10px",
  p: 3,
  "&:focus-visible": {
    outline: "none",
  },
};

const ErrorModal = () => {
  const [t] = useTranslation("global");
  const dispatch = useDispatch();

  const { state, message, type } = useSelector(
    (state) => state.auth.errorModal
  );

  const modalTheme = type === "warning" ? "warning" : "error";

  return (
    <Modal
      aria-labelledby="error-modal"
      aria-describedby="error-modal-description"
      open={state}
      closeAfterTransition
      slots={{ backdrop: Backdrop }}
      slotProps={{
        backdrop: {
          timeout: 500,
        },
      }}
    >
      <Fade in={state}>
        <Stack sx={style} spacing={3}>
          <Stack direction={"row"} spacing={2}>
            <Warning color={modalTheme} sx={{ alignSelf: "center" }} />
            <Typography id="transition-modal-description">{message}</Typography>
          </Stack>
          <Button
            onClick={() => dispatch(clearErrorModalState())}
            variant="contained"
            color={modalTheme}
            sx={{ alignSelf: "flex-end", px: 4, fontSize: 16 }}
          >
            {t("buttons.ok")}
          </Button>
        </Stack>
      </Fade>
    </Modal>
  );
};

export default ErrorModal;
