import { useState } from "react";
import PopUpsButtons from "../../../../components/forms/ButtonGroup/PopUpsButtons";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { Box, Chip, TextField, Autocomplete } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import Axios from "../../../../network/Axios";
import { useSnackbar } from "notistack";

const EditMail = ({ setAnchor, label, mailType, selectedId }) => {
  const { userId } = useParams();
  const { t } = useTranslation("global");
  const queryClient = useQueryClient();
  const { enqueueSnackbar } = useSnackbar();
  const [type, setType] = useState(mailType);

  const options = ["TO", "CC", "BCC"];

  const handleTypeChange = (event, newValue) => {
    setType(newValue);
  };

  const { mutateAsync: editMail, isPending } = useMutation({
    mutationFn: async () => {
      return Axios.patch("/UsersManagement", {
        SelectedNo: userId,
        State: "UpdateTOCCBCC",
        TOCCBCC: type,
        idMailServicesMailingList: selectedId.idMailServicesMailingList,
      });
    },
    onSuccess: (data) => {
      if (!data) return;
      enqueueSnackbar("Edited Successfully", {
        variant: "success",
      });
      queryClient.invalidateQueries({
        queryKey: ["usersManagement", "info"],
      });
      setAnchor(null);
    },
  });

  return (
    <Box display="flex" flexDirection="column" justifyContent="center">
      <Chip
        size="small"
        label={label}
        sx={{ borderRadius: 1, marginBottom: 1.8 }}
      />
      <Autocomplete
        disablePortal
        id="combo-box-demo"
        options={options}
        getOptionLabel={(option) => option}
        value={type}
        sx={{ width: "17rem" }}
        size="small"
        onChange={handleTypeChange}
        renderInput={(params) => (
          <TextField {...params} label={t("data_grid.headers.type")} />
        )}
      />
      <PopUpsButtons
        saveLabel={t("modules.buttons.save")}
        onClickSave={editMail}
        onClickClose={() => {
          setAnchor(null);
          setType(mailType);
        }}
        isPending={isPending}
      />
    </Box>
  );
};

export default EditMail;
