import { useTranslation } from "react-i18next";

export const useLabels = () => {
  const [t] = useTranslation("global");

  const labels = {
    employeeName: t(
      "modules.employee_records.new_form.fields.employee_name.headline"
    ),
    workInformation: t(
      "modules.employee_records.new_form.fields.work_information.headline"
    ),
    contactInformation: t(
      "modules.employee_records.new_form.fields.contact_information.headline"
    ),
    insurance: t("modules.employee_records.new_form.fields.insurance.headline"),
    arabicName: t(
      "modules.employee_records.new_form.fields.employee_name.arabicName"
    ),
    englishName: t(
      "modules.employee_records.new_form.fields.employee_name.englishName"
    ),
    code: t("modules.employee_records.new_form.fields.work_information.code"),
    title: t("modules.employee_records.new_form.fields.work_information.title"),
    hireDate: t(
      "modules.employee_records.new_form.fields.work_information.date_of_hire"
    ),
    resignDate: t(
      "modules.employee_records.new_form.fields.work_information.date_of_resign"
    ),
    department: t(
      "modules.employee_records.new_form.fields.work_information.department"
    ),
    project: t(
      "modules.employee_records.new_form.fields.work_information.project"
    ),
    drive: t("modules.employee_records.new_form.fields.work_information.drive"),
    status: t(
      "modules.employee_records.new_form.fields.work_information.status.title"
    ),
    mission: t(
      "modules.employee_records.new_form.fields.work_information.mission"
    ),
    intExt: t(
      "modules.employee_records.new_form.fields.work_information.intExt.title"
    ),
    phoneNumber: t(
      "modules.employee_records.new_form.fields.contact_information.phoneNumber"
    ),
    email: t(
      "modules.employee_records.new_form.fields.contact_information.email"
    ),
    socialInsurance: t(
      "modules.employee_records.new_form.fields.insurance.social_insurance"
    ),
    medicalInsurance: t(
      "modules.employee_records.new_form.fields.insurance.medical_insurance"
    ),
  };

  return labels;
};
