export const statuses = {
  "In Progress": "data_grid.tabs.in_progress",
  Approved: "data_grid.tabs.approved",
  Hold: "data_grid.tabs.hold",
  Cancelled: "data_grid.tabs.cancelled",
  "Approval Check": "data_grid.tabs.approval_check",
  "Waiting Approval": "data_grid.tabs.waiting_approval",
  "Work Complete": "data_grid.tabs.work_complete",
  "Complete With Reserves": "data_grid.tabs.complete_with_reserves",
  Pending: "data_grid.tabs.pending",
  Sent: "data_grid.tabs.sent",
  Issued: "data_grid.tabs.issued",
  "Partially Issued": "data_grid.tabs.partially_issued",
  "Partially Added": "data_grid.tabs.partially_added",
  Added: "data_grid.tabs.added",
  Rejected: "data_grid.tabs.rejected",
  Requested: "data_grid.tabs.requested",
  "Not Approved": "data_grid.tabs.not_approved",
  Assigned: "data_grid.tabs.assigned",
  "Not Assigned": "data_grid.tabs.not_assigned",
  Terminated: "data_grid.tabs.terminated",
  Submitted: "data_grid.tabs.submitted",
  Invoiced: "data_grid.tabs.invoiced",
  Created: "data_grid.tabs.created",
  Done: "data_grid.tabs.done",
  "N/A": "data_grid.tabs.n_a",
  Open: "data_grid.tabs.open",
  Closed: "data_grid.tabs.closed",
  NA: "data_grid.tabs.na",
  Active: "data_grid.tabs.active",
  Inactive: "data_grid.tabs.inactive",
  Deactive: "data_grid.tabs.deactive",
  Deactivated: "data_grid.tabs.deactive",
  Regular: "data_grid.tabs.regular",
  Absence: "data_grid.tabs.absence",
  "Instead Of": "data_grid.tabs.instead_of",
  "Sick Leave": "data_grid.tabs.sick_leave",
  Success: "data_grid.tabs.success",
  Failed: "data_grid.tabs.failed",
  Internal: "data_grid.tabs.internal",
  External: "data_grid.tabs.external",
  Insured: "data_grid.tabs.insured",
  "Not Insured": "data_grid.tabs.not_insured",
  Covered: "data_grid.tabs.covered",
  "Not Covered": "data_grid.tabs.not_covered",
  Automatic: "data_grid.tabs.automatic",
  Mission: "data_grid.tabs.mission",
  Signature: "data_grid.tabs.signature",
  Vacation: "data_grid.tabs.vacation",
  Formal: "data_grid.tabs.formal",
  Casual: "data_grid.tabs.casual",
  Manual: "data_grid.tabs.manual",
  Adding: "data_grid.tabs.adding",
  Issue: "data_grid.tabs.issue",
  Return: "data_grid.tabs.return",
  Returned: "data_grid.tabs.returned",
  Error: "data_grid.tabs.error",
  Expired: "data_grid.tabs.expired",
  Valid: "data_grid.tabs.valid",
  "Statement Phase": "data_grid.tabs.statement_phase",
  "Submitted for Payment": "data_grid.tabs.submitted_for_payment",
  "Invoice Phase": "data_grid.tabs.invoice_phase",
  Signed: "data_grid.tabs.signed",
  "Technical Approval": "data_grid.tabs.technical_approval",
  "Price Confirmation": "data_grid.tabs.price_confirmation",
  "Technical Approved": "data_grid.tabs.technical_approved",
  "Ready For Invoice": "data_grid.tabs.ready_for_invoice",
};
const baseColors = {
  blue: { backgroundColor: "#4dabf5", dark: "#1976d2" },
  orange: { backgroundColor: "#ff6d00", dark: "#ef6c00" },
  green: { backgroundColor: "#64dd17", dark: "#00c853" },
  yellow: { backgroundColor: "#ffff00", dark: "#c0ca33" },
  purple: { backgroundColor: "#e040fb", dark: "#aa00ff" },
  grey: { backgroundColor: "#cfd8dc" },
  red: { backgroundColor: "red", dark: "red" },
};

const statusColorMap = {
  blue: ["Formal", "Internal", "Partially Added", "Created", "Manual"],
  orange: [
    "In Progress",
    "Waiting Approval",
    "Approval Check",
    "Instead Of",
    "Vacation",
    "Sent",
    "Partially Issued",
    "Invoice Phase",
  ],
  green: [
    "Complete With Reserves",
    "Success",
    "Automatic",
    "Active",
    "Closed",
    "Submitted",
    "Work Complete",
    "Approved",
    "Issued",
    "Done",
    "Adding",
    "Issue",
    "Regular",
    "Assigned",
    "Insured",
    "Covered",
    "Success",
    "Valid",
    "Submitted for Payment",
    "Signed",
    "Invoiced",
  ],
  yellow: [
    "Added",
    "Open",
    "Signature",
    "Casual",
    "Statement Phase",
    "Technical Approval",
    "Price Confirmation",
    "Returned",
    "Technical Approved",
    "Ready For Invoice",
  ],
  purple: ["Requested", "Mission", "Sick Leave", "External"],
  grey: ["Hold", "NA", "Pending", "All"],
  red: [
    "Cancelled",
    "Rejected",
    "Not Approved",
    "Terminated",
    "Return",
    "N/A",
    "Inactive",
    "Deactive",
    "Deactivated",
    "Absence",
    "Failed",
    "Not Insured",
    "Not Covered",
    "Not Assigned",
    "Error",
    "Expired",
  ],
};
export const statusColors = Object.entries(statusColorMap).flatMap(
  ([color, statuses]) =>
    statuses.map((status) => ({ name: status, ...baseColors[color] }))
);
