import FormsDataGrid from "../../../DataGrid/FormsDataGrid";
import { Autocomplete, Box, TextField, Tooltip } from "@mui/material";
import { useMutation } from "@tanstack/react-query";
import { useTranslation } from "react-i18next";
import Axios from "../../../../network/Axios";

const SelectItem = ({
  comboBoxData,
  rows,
  setSelectedNo,
  selectedNo,
  type,
  handleSelectionModel,
  selectionModel,
  setRows,
}) => {
  const [t] = useTranslation("global");
  const priceList = comboBoxData?.data?.PriceListRefNo || comboBoxData;

  const { mutateAsync: getItems, isPending } = useMutation({
    mutationFn: async () => {
      return Axios.get("/Quotations", {
        params: {
          SelectedNo: selectedNo,
          State: "PriceListItems",
        },
      });
    },
    onSuccess: (data) => {
      if (!data) return;
      setRows(data.data.result);
    },
  });

  const baseColumns = [
    {
      field: "PriceListSN",
      headerName: t("data_grid.headers.code"),
    },
    {
      field: "Description",
      headerName: t("data_grid.headers.description"),
      width: 210,
      flex: 1,
      renderCell: ({ value }) => {
        return (
          <Tooltip title={value} arrow>
            <div
              style={{
                overflow: "hidden",
                textOverflow: "ellipsis",
                whiteSpace: "nowrap",
              }}
            >
              {value}
            </div>
          </Tooltip>
        );
      },
    },
    {
      field: "Unit",
      headerName: t("data_grid.headers.unit"),
    },
    {
      field: "SI",
      headerName: t("data_grid.headers.s_i"),
    },
  ];

  const columns =
    type === "acceptance"
      ? baseColumns
      : [
          ...baseColumns,
          {
            field: "Price",
            headerName: t("data_grid.headers.unit_price"),
          },
        ];

  const handleSelectionChange = (value) => {
    setSelectedNo(value?.PriceListRefNo);
    getItems();
  };

  return (
    <Box sx={{ margin: "12px 1px" }}>
      <Autocomplete
        disableClearable
        options={priceList || []}
        getOptionLabel={(option) => option.PriceListRefNo}
        sx={{ width: "100%", margin: "8px 0" }}
        size="small"
        value={
          (priceList || []).find(
            (option) => option.PriceListRefNo === selectedNo
          ) || null
        }
        onChange={(_, value) => handleSelectionChange(value)}
        renderInput={(params) => (
          <TextField
            {...params}
            label={t("modules.acceptance.labels.select_price_list")}
          />
        )}
      />
      <FormsDataGrid
        rows={rows}
        columns={columns}
        loading={isPending}
        getRowId={(row) => row.idPriceList}
        height={380}
        showQuickFilter
        checkboxSelection
        disableSelectionOnClick
        toolbar
        onRowSelectionModelChange={handleSelectionModel}
        rowSelectionModel={selectionModel}
      />
    </Box>
  );
};
export default SelectItem;
