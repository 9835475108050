import Axios from "../network/Axios";

const ENDPOINT = "/Missions";

const getMissionInfo = async (missionId) => {
  const response = await Axios.get(ENDPOINT, {
    params: {
      SelectedNo: missionId,
      State: "Card",
    },
  });
  return response.data;
};

const addNewMission = async (mission) => {
  const response = await Axios.post(ENDPOINT, mission);
  return response.data;
};

export const MissionService = {
  getMissionInfo,
  addNewMission,
};
