// Maximum file size in bytes
export const MAX_FILE_SIZE = 5 * 1_000_000; // 2 MB

export const supportedImageTypes = [
  "image/png",
  "image/jpeg",
  "image/jpg",
  "image/webp",
];

export const carTypeOptions = ["أيجار", "ملاكي", "نص نقل", "نقل"];
