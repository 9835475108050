import { Box, Button, Stack, Tooltip } from "@mui/material";
import { useQuery } from "@tanstack/react-query";
import FormsDataGrid from "../../../components/DataGrid/FormsDataGrid";
import InfoModal from "../../../components/Modals/InfoModal";
import FormSpinner from "../../../components/Spinners/FormSpinner";
import { useTranslation } from "react-i18next";
import { DashboardService } from "../../../api/dashboard";
import { GridActionsCellItem } from "@mui/x-data-grid";
import DeleteIcon from "@mui/icons-material/Delete";
import Edit from "@mui/icons-material/Edit";
import NewChart from "./NewChart";
import { useState } from "react";

export const DashboardChartsModal = ({ open, onClose }) => {
  const { t } = useTranslation("global");
  return (
    <InfoModal
      open={open}
      handleClose={onClose}
      title="Dashboard Charts"
      content={<DashboardCharts onClose={onClose} />}
      width="60rem"
    />
  );
};

const DashboardCharts = ({ onClose }) => {
  const { t } = useTranslation("global");

  // Handle new chart modal
  const [openNewChartModal, setOpenNewChartModal] = useState(false);

  const handleOpenNewChartModal = () => setOpenNewChartModal(true);
  const handleCloseNewChartModal = () => setOpenNewChartModal(false);

  const columns = [
    {
      field: "DashboardFeature",
      headerName: "Dashboard Feature",
      flex: 1,
    },
    {
      field: "Actions",
      type: "actions",
      headerName: t("data_grid.headers.actions"),
      width: 105,
      getActions: (params) => [
        <Tooltip title={t("tooltip.edit")} key="edit">
          <GridActionsCellItem
            icon={<Edit />}
            label="Edit"
            // onClick={() => {
            //   if (params.row.Type === "Vacation") {
            //     setVacationID(params.row.SelectedID);
            //     handleOpenModal();
            //   } else {
            //     setSignatureID(params.row.SelectedID);
            //     handleOpenSignatureModal();
            //   }
            // }}
            // disabled={params.row.Type === "Mission"}
          />
        </Tooltip>,
        <Tooltip title={t("tooltip.delete")} key="delete">
          <GridActionsCellItem
            icon={
              <DeleteIcon
                color={params.row.Type === "Mission" ? "disabled" : "error"}
              />
            }
            label="Delete"
            disabled={params.row.Type === "Mission"}
            // onClick={() => {
            //   setOpen(
            //     t("info_modules.employee_records.confirmation.delete_record"),
            //     () => () => {
            //       if (params.row.Type === "Vacation") {
            //         deleteVacationRecord(params.row.SelectedID);
            //       } else if (params.row.Type === "Signature") {
            //         deleteSignatureRecord(params.row.SelectedID);
            //       }
            //     },
            //     "error"
            //   );
            // }}
          />
        </Tooltip>,
      ],
    },
  ];

  const { data, isLoading } = useQuery({
    queryKey: ["dashboardCharts"],
    queryFn: DashboardService.getDashboardChartData,
  });

  if (isLoading) return <FormSpinner />;

  return (
    <>
      <Stack
        spacing={2}
        sx={{
          padding: 2,
        }}
      >
        <Button
          variant="contained"
          sx={{
            alignSelf: "center",
            textTransform: "capitalize",
          }}
          onClick={handleOpenNewChartModal}
        >
          {t("buttons.add")}
        </Button>
        <Box
          sx={{
            display: "grid",
            gridTemplateColumns: "1fr 1fr",
            gap: "1rem",
          }}
        >
          <FormsDataGrid
            columns={columns}
            rows={data}
            height={400}
            toolbar
            showQuickFilter
            getRowId={(row) => row.idDashboard}
            disableMultipleRowSelection
          />
        </Box>
      </Stack>
      <InfoModal
        open={openNewChartModal}
        handleClose={handleCloseNewChartModal}
        title="New Chart"
        content={<NewChart onClose={handleCloseNewChartModal} />}
        width="65rem"
      />
    </>
  );
};
