import { useState } from "react";
import { Box } from "@mui/material";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import { Edit } from "@mui/icons-material";
import AddIcon from "@mui/icons-material/Add";
import { useTranslation } from "react-i18next";
import { useQuery } from "@tanstack/react-query";
import Spinner from "../components/Spinners/Spinner";
import useOpenNewForm from "../hooks/useOpenNewForm";
import InfoModal from "../components/Modals/InfoModal";
import InventoryIcon from "@mui/icons-material/Inventory";
import { GridActionsCellItem } from "@mui/x-data-grid-pro";
import FormSpinner from "../components/Spinners/FormSpinner";
import FormsDataGrid from "../components/DataGrid/FormsDataGrid";
import CustomDataGrid from "../components/DataGrid/CustomDataGrid";
import { InventoryManagementService } from "../api/inventoryManagement";
import { useConfirmationDialog } from "../contexts/ConfirmationDialogContext";
import NewStore from "../components/forms/InventoryManagement/New Store/NewStore";
import ReplacementsGroups from "../components/forms/InventoryManagement/ReplacementsGroups";

const InventoryManagement = () => {
  const [stores, setStores] = useState(false);
  const [editStore, setEditStore] = useState(false);
  const [storeName, setStoreName] = useState("");
  const [replacements, setReplacements] = useState(false);
  const { newForm, language } = useSelector((state) => state.theme);

  // Handling new form logic
  const { handleOpen } = useOpenNewForm();
  const { setOpen } = useConfirmationDialog();

  const [t] = useTranslation("global");

  const columns = [
    {
      field: "StoreItemsTemplateCode",
      headerName: t("data_grid.headers.item_code"),
      width: 130,
      renderCell: (params) => {
        return (
          <Link
            style={{
              color: "#1790FF",
            }}
            to={`/inventorymanagement/${params.value}`}
          >
            {params.value}
          </Link>
        );
      },
    },
    {
      field: "StoreItemsTemplateName",
      headerName: t("data_grid.headers.item_name"),
      width: 120,
      flex: 1,
    },
    {
      field: "StoreItemsTemplateNameEn",
      headerName: t("data_grid.headers.item_name_en"),
      width: 120,
      flex: 1,
    },
    {
      field: "StoreItemsTemplateType",
      headerName: t("data_grid.headers.type"),
      width: 90,
    },
    {
      field: "StoreItemsTemplateDescription",
      headerName: t("data_grid.headers.description"),
      width: 110,
      flex: 1,
    },
    {
      field: "StoreItemsTemplateUnit",
      headerName: t("data_grid.headers.unit"),
      width: 120,
    },
    {
      field: "StoreItemsTemplateQty",
      headerName: t("data_grid.headers.item_total_balance"),
      width: 120,
    },
    {
      field: "StoreItemsTemplateIssueFreq",
      headerName: t("data_grid.headers.issue_transactions"),
      width: 140,
    },
  ];

  const [openNewStore, setOpenNewStore] = useState(false);

  const handleOpenNewStore = () => setOpenNewStore(true);
  const handleCloseNewStore = () => setOpenNewStore(false);

  const actions = ["new item", "new store"];

  const actionsConfig = {
    "new item": {
      label: t("data_grid.buttons.item"),
      icon: <AddIcon sx={{ width: "1.1rem", height: "1.1rem" }} />,
      onClick: () => {
        if (newForm === "inventory_management" || newForm === null) {
          handleOpen("inventory_management");
        } else {
          setOpen(t("modules.new_form_confirmation_message"), () => () => {
            handleOpen("inventory_management");
          });
        }
      },
    },
    "new store": {
      label: t("data_grid.buttons.store"),
      icon: <InventoryIcon sx={{ width: "1.1rem", height: "1.1rem" }} />,
      onClick: handleOpenNewStore,
    },
  };

  const storesColumns = [
    {
      field:
        language === "EN" ? "CompanyStoresName" : "CompanyStoresNameArabic",
      headerName: t("data_grid.headers.store_name"),
      width: 100,
    },
    {
      field: "CompanyStoresLocation",
      headerName: t("data_grid.headers.location"),
      width: 100,
    },
    {
      field: "CompanyStoresDiscription",
      headerName: t("data_grid.headers.description"),
      width: 100,
      flex: 1,
    },
    {
      field: "Actions",
      headerName: t("data_grid.headers.actions"),
      type: "actions",
      width: 70,
      getActions: (params) => [
        <GridActionsCellItem
          key={params.row.idCompanyStores}
          icon={<Edit />}
          label="Edit"
          onClick={() => {
            setStoreName(params.row.CompanyStoresName);
            setEditStore(true);
          }}
        />,
      ],
    },
  ];

  const { data: storesRows, isLoading: loadingStores } = useQuery({
    queryKey: ["inventoryManagementStores"],
    queryFn: InventoryManagementService.getStores,
    enabled: stores,
  });

  const { data: editStoreData, isLoading: isEditingStore } = useQuery({
    queryKey: ["inventoryManagementEditStore", storeName],
    queryFn: () => InventoryManagementService.getStoresEdit(storeName),
    enabled: editStore,
  });

  const {
    data: rows,
    isLoading,
    refetch,
    isRefetching,
  } = useQuery({
    queryKey: ["inventoryManagement"],
    queryFn: InventoryManagementService.GetInventoryManagement,
  });

  if (isLoading) {
    return <Spinner />;
  }
  const {
    CompanyStoresNameArabic,
    CompanyStoresName,
    CompanyStoresLocation,
    CompanyStoresDiscription,
  } = editStoreData?.ItemInfo[0] || {};

  return (
    <>
      <CustomDataGrid
        rows={rows}
        columns={columns}
        loading={isLoading}
        getRowId={(row) => row.StoreItemsTemplateCode}
        disableStatusTabs
        showReloadOverlay={isRefetching}
        reloadFunction={refetch}
        newButtonList={{
          actions,
          actionsConfig,
        }}
        replacementButton
        storesButton
        replacementFunction={() => setReplacements(true)}
        storesFunction={() => setStores(true)}
      />
      <ReplacementsGroups
        open={replacements}
        handleClose={() => setReplacements(false)}
      />
      <InfoModal
        title={t("modules.inventory_management.new_store_form.title")}
        open={openNewStore}
        handleClose={handleCloseNewStore}
        content={<NewStore onClose={handleCloseNewStore} />}
        width="30rem"
      />
      <InfoModal
        title={t("data_grid.buttons.stores")}
        open={stores}
        handleClose={() => setStores(false)}
        content={
          <Box margin={2}>
            {loadingStores ? (
              <FormSpinner />
            ) : (
              <FormsDataGrid
                rows={storesRows}
                columns={storesColumns}
                getRowId={(row) => row.CompanyStoresName}
              />
            )}
          </Box>
        }
        width="42rem"
      />
      <InfoModal
        title={t(
          "modules.inventory_management.new_store_form.fields.edit_store"
        )}
        open={editStore}
        handleClose={() => setEditStore(false)}
        content={
          isEditingStore ? (
            <FormSpinner />
          ) : (
            <NewStore
              storeNameAR={CompanyStoresNameArabic}
              storeNameEN={CompanyStoresName}
              location={CompanyStoresLocation}
              description={CompanyStoresDiscription}
              onClose={() => setEditStore(false)}
              storeName={storeName}
            />
          )
        }
        width="30rem"
      />
    </>
  );
};

export default InventoryManagement;
