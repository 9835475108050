import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  filterItems: [],
  quickFilterValue: "",
};

const filterSlice = createSlice({
  name: "filters",
  initialState,
  reducers: {
    setFilterItems(state, action) {
      state.filterItems = action.payload;
    },
    setQuickFilterValue(state, action) {
      state.quickFilterValue = action.payload;
    },
  },
});

export const { setFilterItems, setQuickFilterValue } = filterSlice.actions;

export default filterSlice.reducer;
