import { Box, Chip } from "@mui/material";
import { statusColors, statuses } from "../pages/statuses";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

const ChipStatus = ({ value, fontSize }) => {
  const [t] = useTranslation("global");
  const theme = useSelector((state) => state.theme.colorTheme);

  const backgroundColor = statusColors.find(
    (status) => status.name === value
  )?.backgroundColor;
  const dark = statusColors.find((status) => status.name === value)?.dark;

  return (
    <Box width="100%" textAlign="center">
      <Chip
        size="small"
        variant="filled"
        label={t(statuses[value])}
        sx={{
          backgroundColor: `rgba(147, 147, 147, 0.15)`, // Add opacity to the background color
          color: theme === "dark" ? `${backgroundColor}` : `${dark}`,
          fontWeight: 700,
          width: "100%",
          fontSize,
        }}
      />
    </Box>
  );
};
export default ChipStatus;
