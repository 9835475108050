import { useState } from "react";
import PropTypes from "prop-types";
import { useSnackbar } from "notistack";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import Axios from "../../../../network/Axios";
import { Box, Tabs, Tab, Tooltip } from "@mui/material";
import ChipStatus from "../../../../components/ChipStatus";
import FormSpinner from "../../../../components/Spinners/FormSpinner";
import InfoModal from "../../../../components/Modals/InfoModal";
import FormsDataGrid from "../../../../components/DataGrid/FormsDataGrid";
import { useQuery, useMutation, useQueryClient } from "@tanstack/react-query";
import WorkOrdersSnapShot from "./WorkOrdersSnapShot";
import ButtonGroup from "../../../../components/forms/ButtonGroup/ButtonGroup";
import MissionSnapshot from "../../EmployeeRecords/cards/Attendance/TimeLine/MissionSnapshot";
import InvoicesSnapShot from "../../ClientInvoices/InfoModals/InvoicesSnapShot";
import MaterialRequestSnapShot from "./MaterialRequestSnapShot";

function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ p: 1.4 }}>{children}</Box>}
    </div>
  );
}

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

function useSelection() {
  const [selection, setSelection] = useState({
    selectedRowIds: [],
    selectedItems: [],
  });
  return [selection, setSelection];
}

const MigrateModal = ({ open, handleClose, workOrderId, workOrderInfo }) => {
  const [t] = useTranslation("global");
  const [value, setValue] = useState(0);
  const queryClient = useQueryClient();
  const { enqueueSnackbar } = useSnackbar();
  const [selectionWorkOrders, setSelectionWorkOrders] = useSelection();
  const [selectionMissions, setSelectionMissions] = useSelection();
  const [selectionWarrantyVisit, setSelectionWarrantyVisit] = useSelection();
  const [selectionMaterial, setSelectionMaterial] = useSelection();
  const [selectionQuotations, setSelectionQuotations] = useSelection();
  const [missionNo, setMissionNo] = useState("");
  const [missionModal, setMissionModal] = useState(false);
  const [quotationNo, setQuotationNo] = useState("");
  const [quotationModal, setQuotationModal] = useState(false);
  const [workOrderNo, setWorkOrderNo] = useState("");
  const [workOrderModal, setWorkOrderModal] = useState(false);
  const [materialNo, setMaterialNo] = useState("");
  const [materialModal, setMaterialModal] = useState(false);

  function createSelectionHandler(setSelectionFunction, data, idField) {
    return function (newSelectedRows) {
      const updatedSelection = {
        selectedRowIds: newSelectedRows,
        selectedItems: data.filter((row) =>
          newSelectedRows.includes(row[idField])
        ),
      };
      setSelectionFunction(updatedSelection);
    };
  }
  const { data, isLoading } = useQuery({
    queryKey: ["workOrderMigrate"],
    queryFn: async () => {
      const response = await Axios.get("/WorkOrders", {
        params: {
          State: "Migration",
          SelectedNo: workOrderId,
        },
      });
      return response.data.result;
    },
    enabled: open,
  });

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleCloseSave = () => {
    handleClose();
    setSelectionMaterial({ selectedRowIds: [], selectedItems: [] });
    setSelectionMissions({ selectedRowIds: [], selectedItems: [] });
    setSelectionQuotations({ selectedRowIds: [], selectedItems: [] });
    setSelectionWarrantyVisit({ selectedRowIds: [], selectedItems: [] });
    setSelectionWorkOrders({ selectedRowIds: [], selectedItems: [] });
    setValue(0);
  };
  const { mutateAsync: migrate, isPending } = useMutation({
    mutationFn: async () => {
      return Axios.put("/WorkOrders", {
        MaterialRequestGrid: selectionMaterial.selectedItems.map(
          (item) => item.MaterialsRequestNo
        ),
        MissionsGrid: selectionMissions.selectedItems.map(
          (item) => item.MissionNo
        ),
        QuotationsGrid: selectionQuotations.selectedItems.map(
          (item) => item.WorkOrderD6No
        ),
        SelectedNo: workOrderId,
        State: "Migration",
        WarrantyVisitGrid: selectionWarrantyVisit.selectedItems.map(
          (item) => item.MissionNo
        ),
        WorkOrderNo:
          selectionWorkOrders.selectedItems[0].WorkOrderNo.toString(),
      });
    },
    onSuccess: (data) => {
      if (!data) return;
      handleCloseSave();
      enqueueSnackbar("Work Order Migrated Successfully", {
        variant: "success",
      });
      queryClient.invalidateQueries({
        queryKey: ["workOrderInfo"],
      });
    },
  });

  const workOrderColumns = [
    {
      field: "WorkOrderNo",
      headerName: t("data_grid.headers.work_order_no"),
      width: 130,
      renderCell: (params) => {
        return (
          <Link
            style={{
              color: "#1790FF",
            }}
            onClick={() => {
              setWorkOrderNo(params.value);
              setWorkOrderModal(true);
            }}
          >
            {params.value}
          </Link>
        );
      },
    },
    {
      field: "SiteName",
      headerName: t("data_grid.headers.site_name"),
      flex: 1,
    },
    {
      field: "SiteCode",
      headerName: t("data_grid.headers.site_code"),
      width: 110,
    },
    {
      field: "WorkOrderDiscription",
      headerName: t("data_grid.headers.description"),
      flex: 1,
      renderCell: ({ value }) => {
        return (
          <Tooltip title={value} arrow>
            <div
              style={{
                overflow: "hidden",
                textOverflow: "ellipsis",
                whiteSpace: "nowrap",
              }}
            >
              {value}
            </div>
          </Tooltip>
        );
      },
    },
    {
      field: "WorkOrderDate",
      headerName: t("data_grid.headers.date"),
      width: 100,
    },
    {
      field: "ERPUserNickName",
      headerName: t("data_grid.headers.created_by"),
      width: 120,
    },
    {
      field: "WorkOrderStatus",
      headerName: t("data_grid.headers.status"),
      width: 140,
      renderCell: (params) => <ChipStatus value={params.value} />,
    },
  ];
  const missionsColumns = [
    {
      field: "MissionNo",
      headerName: t("data_grid.headers.ref_no"),
      width: 120,
      renderCell: (params) => {
        return (
          <Link
            style={{
              color: "#1790FF",
            }}
            onClick={() => {
              setMissionNo(params.value);
              setMissionModal(true);
            }}
          >
            {params.value}
          </Link>
        );
      },
    },
    {
      field: "MUser",
      headerName: t("data_grid.headers.mission_by"),
      flex: 1,
    },
    {
      field: "WUser",
      headerName: t("data_grid.headers.order_by"),
      flex: 1,
    },
    {
      field: "MissionStart",
      headerName: t("data_grid.headers.start_date"),
      width: 140,
    },
    {
      field: "MissionEnd",
      headerName: t("data_grid.headers.end_date"),
      width: 140,
    },
    {
      field: "Status",
      headerName: t("data_grid.headers.status"),
      width: 120,
      renderCell: (params) => <ChipStatus value={params.value} />,
    },
  ];
  const materialRequestsColumns = [
    {
      field: "MaterialsRequestNo",
      headerName: t("data_grid.headers.ref_no"),
      width: 120,
      renderCell: (params) => {
        return (
          <Link
            style={{
              color: "#1790FF",
            }}
            onClick={() => {
              setMaterialNo(params.value);
              setMaterialModal(true);
            }}
          >
            {params.value}
          </Link>
        );
      },
    },
    {
      field: "ERPUserNickName",
      headerName: t("data_grid.headers.created_by"),
      width: 100,
      flex: 1,
    },
    {
      field: "IssuedOn",
      headerName: t("data_grid.headers.issued_on"),
      width: 145,
    },
    {
      field: "SentOn",
      headerName: t("data_grid.headers.sent_on"),
      width: 145,
    },
    {
      field: "MaterialsRequestStatus",
      headerName: t("data_grid.headers.status"),
      width: 140,
      renderCell: (params) => <ChipStatus value={params.value} />,
    },
  ];
  const acceptance = [
    {
      field: "WorkOrderD6No",
      headerName: t("data_grid.headers.d6_no"),
      width: 130,
      renderCell: (params) => {
        return (
          <Link
            style={{
              color: "#1790FF",
            }}
            onClick={() => {
              setQuotationNo(params.value);
              setQuotationModal(true);
            }}
          >
            {params.value}
          </Link>
        );
      },
    },
    {
      field: "WorkOrderD6TypeName",
      headerName: t("data_grid.headers.type"),
      width: 180,
      flex: 1,
    },
    {
      field: "WorkOrderD6Start",
      headerName: t("data_grid.headers.start_date"),
      width: 90,
    },
    {
      field: "WorkOrderD6End",
      headerName: t("data_grid.headers.end_date"),
      width: 100,
    },
    {
      field: "AcceptanceDate",
      headerName: t("data_grid.headers.acceptance_date"),
      width: 140,
    },
    {
      field: "ERPUserNickName",
      headerName: t("data_grid.headers.user"),
      width: 100,
      flex: 1,
    },
    {
      field: "WorkOrderD6Amount",
      headerName: t("data_grid.headers.amount"),
      width: 120,
    },
    {
      field: "WorkOrderD6Status",
      headerName: t("data_grid.headers.status"),
      width: 140,
      renderCell: (params) => <ChipStatus value={params.value} />,
    },
  ];

  const isButtonDisabled = !(
    selectionWorkOrders.selectedRowIds.length > 0 &&
    (selectionMissions.selectedRowIds.length > 0 ||
      selectionWarrantyVisit.selectedRowIds.length > 0 ||
      selectionMaterial.selectedRowIds.length > 0 ||
      selectionQuotations.selectedRowIds.length > 0)
  );

  return (
    <InfoModal
      open={open}
      handleClose={handleCloseSave}
      title={t("info_modules.work_orders.description.work_order_migration")}
      width="66rem"
      content={
        <>
          {isLoading ? (
            <Box padding="6rem 20rem 10rem 20rem">
              <FormSpinner />
            </Box>
          ) : (
            <Box sx={{ width: "100%" }}>
              <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                <Tabs
                  value={value}
                  onChange={handleChange}
                  aria-label="basic tabs example"
                >
                  <Tab
                    label={t(
                      "info_modules.work_orders.description.select_work_order"
                    )}
                    {...a11yProps(0)}
                  />
                  <Tab
                    label={t("info_modules.work_orders.description.missions")}
                    {...a11yProps(1)}
                    disabled={workOrderInfo.Missions.length === 0}
                  />
                  <Tab
                    label={t(
                      "info_modules.work_orders.description.warranty_visit"
                    )}
                    {...a11yProps(2)}
                    disabled={workOrderInfo.WarrantyVisit.length === 0}
                  />
                  <Tab
                    label={t(
                      "info_modules.work_orders.description.material_request"
                    )}
                    {...a11yProps(3)}
                    disabled={workOrderInfo.MaterialRequest.length === 0}
                  />
                  <Tab
                    label={t("info_modules.work_orders.description.quotations")}
                    {...a11yProps(4)}
                    disabled={workOrderInfo.WorkOrdersD6.length === 0}
                  />
                </Tabs>
              </Box>
              <CustomTabPanel value={value} index={0}>
                <FormsDataGrid
                  rows={data || []}
                  columns={workOrderColumns}
                  getRowId={(row) => row.idWorkOrder}
                  height={380}
                  showQuickFilter
                  toolbar
                  checkboxSelection
                  disableSelectionOnClick
                  onRowSelectionModelChange={(newSelectedRows) => {
                    const lastSelectedRow =
                      newSelectedRows.length > 0
                        ? [newSelectedRows[newSelectedRows.length - 1]]
                        : [];
                    const updatedSelection = {
                      selectedRowIds: lastSelectedRow,
                      selectedItems: data.filter((row) =>
                        lastSelectedRow.includes(row.idWorkOrder)
                      ),
                    };
                    setSelectionWorkOrders(updatedSelection);
                  }}
                  rowSelectionModel={selectionWorkOrders.selectedRowIds}
                />
              </CustomTabPanel>
              <CustomTabPanel value={value} index={1}>
                <FormsDataGrid
                  rows={workOrderInfo.Missions || []}
                  columns={missionsColumns}
                  getRowId={(row) => row.idMissions}
                  height={380}
                  showQuickFilter
                  toolbar
                  checkboxSelection
                  disableSelectionOnClick
                  onRowSelectionModelChange={createSelectionHandler(
                    setSelectionMissions,
                    workOrderInfo.Missions,
                    "idMissions"
                  )}
                  rowSelectionModel={selectionMissions.selectedRowIds}
                />
              </CustomTabPanel>
              <CustomTabPanel value={value} index={2}>
                <FormsDataGrid
                  rows={workOrderInfo.WarrantyVisit || []}
                  columns={missionsColumns}
                  getRowId={(row) => row.idMissions}
                  height={380}
                  showQuickFilter
                  toolbar
                  checkboxSelection
                  disableSelectionOnClick
                  onRowSelectionModelChange={createSelectionHandler(
                    setSelectionWarrantyVisit,
                    workOrderInfo.WarrantyVisit,
                    "idMissions"
                  )}
                  rowSelectionModel={selectionWarrantyVisit.selectedRowIds}
                />
              </CustomTabPanel>
              <CustomTabPanel value={value} index={3}>
                <FormsDataGrid
                  rows={workOrderInfo.MaterialRequest || []}
                  columns={materialRequestsColumns}
                  getRowId={(row) => row.MaterialsRequestNo}
                  height={380}
                  showQuickFilter
                  toolbar
                  checkboxSelection
                  disableSelectionOnClick
                  onRowSelectionModelChange={createSelectionHandler(
                    setSelectionMaterial,
                    workOrderInfo.MaterialRequest,
                    "MaterialsRequestNo"
                  )}
                  rowSelectionModel={selectionMaterial.selectedRowIds}
                />
              </CustomTabPanel>
              <CustomTabPanel value={value} index={4}>
                <FormsDataGrid
                  rows={workOrderInfo.WorkOrdersD6 || []}
                  columns={acceptance}
                  getRowId={(row) => row.WorkOrderD6No}
                  height={380}
                  showQuickFilter
                  toolbar
                  checkboxSelection
                  disableSelectionOnClick
                  onRowSelectionModelChange={createSelectionHandler(
                    setSelectionQuotations,
                    workOrderInfo.WorkOrdersD6,
                    "WorkOrderD6No"
                  )}
                  rowSelectionModel={selectionQuotations.selectedRowIds}
                />
              </CustomTabPanel>
            </Box>
          )}
          <ButtonGroup
            onClickSave={migrate}
            isPending={isPending}
            onClickClose={handleCloseSave}
            saveLabel={t("modules.buttons.save")}
            disabled={isButtonDisabled}
          />
          <InfoModal
            title={`${t(
              "info_modules.work_orders.description.mission_details"
            )} - ${missionNo}`}
            content={
              <MissionSnapshot missionRefNo={missionNo} documentEdit={true} />
            }
            handleClose={() => setMissionModal(false)}
            open={missionModal}
            width="60rem"
          />
          <InfoModal
            title={`${t(
              "info_modules.work_orders.description.quotation_details"
            )} - ${quotationNo}`}
            content={
              <InvoicesSnapShot d6RefNo={quotationNo} open={quotationModal} />
            }
            handleClose={() => setQuotationModal(false)}
            open={quotationModal}
            width="60rem"
          />
          <InfoModal
            title={`${t(
              "info_modules.work_orders.description.work_order_details"
            )} - ${workOrderNo}`}
            content={
              <WorkOrdersSnapShot
                workOrderNo={workOrderNo}
                open={workOrderModal}
              />
            }
            handleClose={() => setWorkOrderModal(false)}
            open={workOrderModal}
            width="60rem"
          />
          <InfoModal
            title={`${t(
              "info_modules.work_orders.description.material_request_details"
            )} - ${materialNo}`}
            content={
              <MaterialRequestSnapShot
                materialNo={materialNo}
                open={materialModal}
              />
            }
            handleClose={() => setMaterialModal(false)}
            open={materialModal}
            width="66rem"
          />
        </>
      }
    />
  );
};
export default MigrateModal;
