import { createSlice } from "@reduxjs/toolkit";

const storedUserPriv = localStorage.getItem("userPriv");
let parsedUserPriv = [];

try {
  parsedUserPriv = JSON.parse(storedUserPriv) || [];
} catch (error) {
  console.error("Error parsing userPriv:", error);
}

const initialState = {
  accessToken: localStorage.getItem("token") || null,
  refreshToken: localStorage.getItem("refreshToken") || null,
  userPriv: parsedUserPriv,
  nickName: localStorage.getItem("NickName") || null,
  errorModal: { type: "warning", state: false, message: "" },
};

const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    setTokens: (state, action) => {
      state.accessToken = action.payload.accessToken;
      state.refreshToken = action.payload.refreshToken;
      state.userPriv = action.payload.userPriv;
      state.nickName = action.payload.nickName;
    },
    clearTokens: (state) => {
      state.accessToken = null;
      state.refreshToken = null;
      state.userPriv = [];
      state.nickName = null;

      localStorage.clear();
    },
    setErrorModalState: (state, action) => {
      state.errorModal.state = true;
      state.errorModal.message = action.payload.message;
      state.errorModal.type = action.payload.type;
    },
    clearErrorModalState: (state) => {
      state.errorModal.state = false;
      state.errorModal.message = "";
      state.errorModal.type = "";
    },
  },
});

export const {
  setTokens,
  clearTokens,
  refreshAccessToken,
  setErrorModalState,
  clearErrorModalState,
} = authSlice.actions;

export default authSlice.reducer;
