import dayjs from "dayjs";
import { useState, useEffect } from "react";
import { Box, Stack, Typography, Button } from "@mui/material";
import LoadingButton from "@mui/lab/LoadingButton";
import { useMutation } from "@tanstack/react-query";
import StorageIcon from "@mui/icons-material/Storage";
import CloudIcon from "@mui/icons-material/Cloud";
import KeyboardDoubleArrowRightIcon from "@mui/icons-material/KeyboardDoubleArrowRight";
import KeyboardDoubleArrowLeftIcon from "@mui/icons-material/KeyboardDoubleArrowLeft";
import { filesFoldersService } from "../../../../api/filesManager";
import FormSpinner from "../../../../components/Spinners/FormSpinner";
import FormsDataGrid from "../../../../components/DataGrid/FormsDataGrid";
import ConfirmationInfoModal from "../../../../components/Modals/ConfirmationInfoModal";
import ReplayIcon from "@mui/icons-material/Replay";
import AntSwitch from "../../../../components/Switches/AntSwitch";

const Step1 = ({
  handleMigrationDestination,
  migrationDestination,
  data,
  isLoading,
  dbRows,
  cloudRows,
  refetch,
  loading,
  setLoading,
  totalDbCount,
  totalCloudCount,
  setTotalDbCount,
  setTotalCloudCount,
}) => {
  const date = dayjs(data?.LastDate);
  const formattedDate = date.format("YYYY-MM-DD hh:mm a");
  const [openConfirmation, setOpenConfirmation] = useState(false);

  const db_columns = [
    { field: "tableName", headerName: "Table", flex: 1 },
    { field: "columnName", headerName: "Column", flex: 1 },
    { field: "count", headerName: "Count" },
  ];

  const cloud_columns = [
    { field: "tableName", headerName: "Table", flex: 1 },
    { field: "count", headerName: "Count" },
  ];

  useEffect(() => {
    if (data?.GatheringData === false) {
      setLoading(false);
    }
  }, [data?.GatheringData, setLoading]);

  const { mutateAsync: updateData, isPending: isUpdateData } = useMutation({
    mutationFn: filesFoldersService.updateData,
    onSuccess: (data) => {
      if (!data) return;
      if (data.msg === "Data Gathering Started") {
        refetch();
        setLoading(true);
        setTotalDbCount(0);
        setTotalCloudCount(0);
      } else {
        setLoading(false);
      }
    },
    onError: () => {
      setLoading(false);
    },
  });

  const handleUpdateData = async () => {
    setLoading(true);
    await updateData();
    setOpenConfirmation(false);
  };

  if (isLoading) return <FormSpinner />;

  return (
    <Stack
      spacing={3}
      sx={{
        paddingBottom: "1rem",
      }}
    >
      <Typography
        sx={{ fontSize: "0.9rem", display: "flex", justifyContent: "flex-end" }}
      >
        {`Last update: ${formattedDate}`}
      </Typography>
      <Box
        sx={{
          display: "flex",
          gap: "10px",
          alignItems: "center",
        }}
      >
        <Stack spacing={2} width="55%">
          <Box display="flex" gap={16}>
            <Stack direction="row" alignItems="center" spacing={1}>
              <StorageIcon />
              <Typography variant="body1">Database</Typography>
            </Stack>
            <Typography variant="body1" fontWeight="500">
              Files: {totalDbCount}
            </Typography>
          </Box>
          <FormsDataGrid
            rows={data?.GatheringData === true ? [] : dbRows || []}
            columns={db_columns}
            height={300}
            hideFooter
            getRowId={(row) => row.id}
          />
        </Stack>
        <Stack spacing={2} width="45%">
          <Box display="flex" gap={13}>
            <Stack direction="row" alignItems="center" spacing={1}>
              <CloudIcon />
              <Typography variant="body1">Cloud</Typography>
            </Stack>
            <Typography variant="body1" fontWeight="500">
              Files: {totalCloudCount}
            </Typography>
          </Box>
          <FormsDataGrid
            rows={data?.GatheringData === true ? [] : cloudRows || []}
            columns={cloud_columns}
            height={300}
            hideFooter
            getRowId={(row) => row.id}
          />
        </Stack>
      </Box>
      <Stack
        direction="row"
        spacing={2}
        alignItems="center"
        justifyContent="space-between"
      >
        <Stack direction="row" spacing={2} alignItems="center">
          <Button
            variant="outlined"
            onClick={handleMigrationDestination}
            sx={{
              display: "flex",
              gap: "1rem",
            }}
          >
            <StorageIcon color="primary" />
            {migrationDestination === "cloud" ? (
              <KeyboardDoubleArrowRightIcon color="primary" />
            ) : (
              <KeyboardDoubleArrowLeftIcon color="primary" />
            )}
            <CloudIcon color="primary" />
          </Button>

          {migrationDestination === "cloud" ? (
            <Typography
              sx={{
                color: "primary.main",
              }}
            >
              Copy from database to cloud
            </Typography>
          ) : (
            <Typography
              sx={{
                color: "warning.main",
              }}
            >
              Copy from cloud to database
            </Typography>
          )}
        </Stack>
        <Box display="flex" alignItems="center" gap={13}>
          <LoadingButton
            variant={loading ? "contained" : "outlined"}
            onClick={() => setOpenConfirmation(true)}
            loading={loading || data?.GatheringData === true}
            disabled={data?.GatheringData === true}
            loadingPosition="start"
            startIcon={<ReplayIcon />}
            sx={{
              display: "flex",
              padding: "6px 8px",
              color: loading ? "#757575" : "primary",
              textTransform: "capitalize",
              border: 2.3,
              ":hover": {
                border: 2.3,
              },
            }}
          >
            Update data
          </LoadingButton>
        </Box>
      </Stack>
      <ConfirmationInfoModal
        open={openConfirmation}
        handleClose={() => setOpenConfirmation(false)}
        onClick={handleUpdateData}
        description={
          "Are you sure you want to update the data , this process take a long time?"
        }
        isPending={isUpdateData}
        color="error"
      />
    </Stack>
  );
};

export default Step1;
