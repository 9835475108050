import { useMemo } from "react";
import {
  Route,
  createBrowserRouter,
  createRoutesFromElements,
  RouterProvider,
} from "react-router-dom";
import PrivateRoute from "../src/network/PrivateRoute";
import { CssBaseline, createTheme } from "@mui/material";
import Login from "../src/pages/Login/Login";
import RootLayout from "../src/components/Layouts/RootLayout";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { CacheProvider, ThemeProvider } from "@emotion/react";
import { grey } from "@mui/material/colors";
import { useSelector } from "react-redux";
import createCache from "@emotion/cache";
import { prefixer } from "stylis";
import rtlPlugin from "stylis-plugin-rtl";
import { enUS, arEG } from "@mui/material/locale";
import { enUS as DataGridEn, arSD as DataGridAr } from "@mui/x-data-grid-pro";
import NotFound404 from "./pages/NotFound404/NotFound404";
import WithTitle from "./components/HOC/WithTitle";
import { routes } from "./routeConfig";
import { ErrorBoundary } from "react-error-boundary";
import FallBackError from "./components/FallBackError";
import { ConfirmationDialogProvider } from "./contexts/ConfirmationDialogContext";
import ClearLocalStorage from "./pages/ClearLocalStorage";

const router = createBrowserRouter(
  createRoutesFromElements(
    <>
      <Route element={<RootLayout />}>
        <Route element={<PrivateRoute />}>
          {routes.map(({ title, component, path }) => (
            <Route
              key={path}
              element={
                <ErrorBoundary FallbackComponent={FallBackError}>
                  <WithTitle component={component} title={title} />
                </ErrorBoundary>
              }
              path={path}
            />
          ))}
        </Route>
      </Route>
      <Route>
        <Route path="/login" element={<Login />} />
        <Route path="/clear" element={<ClearLocalStorage />} />
        <Route path="*" element={<NotFound404 />} />
      </Route>
    </>
  )
);

export const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
    },
  },
});

// Create rtl cache
const rtlCache = createCache({
  key: "muirtl",
  stylisPlugins: [prefixer, rtlPlugin],
});

// Create ltr cache
const ltrCache = createCache({
  key: "mui",
});

function App() {
  const { colorTheme: mode, language } = useSelector((state) => state.theme); // light | dark

  const getDesignTokens = (mode) => ({
    typography: {
      fontFamily: [
        "-apple-system",
        "BlinkMacSystemFont",
        '"Segoe UI"',
        "Roboto",
        '"Helvetica Neue"',
        "Arial",
        "sans-serif",
        '"Apple Color Emoji"',
        '"Segoe UI Emoji"',
        '"Segoe UI Symbol"',
      ].join(","),
    },
    direction: language === "AR" ? "rtl" : "ltr",
    palette: {
      mode,
      primary: {
        main: "#1790FF",
      },
      secondary: {
        ...(mode === "light"
          ? {
              main: grey[300],
            }
          : {
              main: grey[800],
            }),
      },
      error: {
        main: "#ff4d6d",
      },
      success: {
        main: "#06d6a0",
        secondary: "#25a244",
      },
      info: {
        main: "#775DD0",
      },
      warning: {
        main: "#FEB019",
      },
      ...(mode === "dark"
        ? {
            background: {
              main: "#121212",
              default: grey[900],
              paper: grey[900],
              card: "#1E1E1E",
              innerCard: "#121212",
              sidebarMenuHover: "#353535",
              notificationsPanel: "rgba(0, 0, 0, 0.9)",
              notificationsPanelDropShadowColor: "rgba(0, 0, 0, 0.5)",
              notificationsCardHover: "rgba(0, 0, 0, 0.7)",
              notificationsDescription: "#4a4a49",
              formHeader: "#333",
              headerBg: "#4a4a49",
              headerCard: "#4a4a49",
              headerGrid: "#3e4750",
            },
            boxShadow: {
              boxes: "0 4px 8px rgba(255, 255, 255, 0.15)",
            },
          }
        : {
            background: {
              main: "#FAFAFB",
              default: "#fff",
              paper: "#fff",
              card: "#fff",
              innerCard: "#fff",
              sidebarMenuHover: "#E6F4FF",
              notificationsPanel: "rgba(255, 255, 255, 0.9)",
              notificationsPanelDropShadowColor: "rgba(145, 158, 171, 0.24)",
              notificationsCardHover: "rgba(0, 0, 0, 0.02)",
              notificationsDescription: "#F3F3F3",
              formHeader: "#616161",
              headerBg: "#F3F3F4",
              headerCard: "#9f9f9f",
              headerGrid: "#dcdddf",
            },
            boxShadow: {
              boxes: "0 4px 8px rgba(0, 0, 0, 0.15)",
            },
          }),
      text: {
        ...(mode === "light"
          ? {
              primary: grey[700],
              secondary: grey[800],
            }
          : {
              primary: grey[300],
              secondary: grey[500],
            }),
      },
      boxShadow: {
        main:
          mode === "dark"
            ? "0 0px 5px rgba(255, 255, 255, 0.1)"
            : "0 4px 8px rgba(0, 0, 0, 0.15)",

        icon:
          mode === "dark"
            ? "0 0px 6px rgba(255, 255, 255, 0.25)"
            : "0 4px 8px rgba(0, 0, 0, 0.15)",
      },
    },
    components: {
      MuiButton: {
        defaultProps: {
          disableElevation: true,
        },
      },
      MuiPaper: {
        styleOverrides: {
          root: {
            boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
          },
        },
      },
    },
  });

  // Update the theme only if the mode changes
  const theme = useMemo(
    () =>
      createTheme(
        getDesignTokens(mode),
        language === "EN" ? DataGridEn : DataGridAr,
        language === "EN" ? enUS : arEG
      ),
    [mode, language]
  );

  return (
    <QueryClientProvider client={queryClient}>
      <CacheProvider value={language === "AR" ? rtlCache : ltrCache}>
        <ThemeProvider theme={theme}>
          <ConfirmationDialogProvider>
            <CssBaseline />
            <RouterProvider router={router} />
          </ConfirmationDialogProvider>
        </ThemeProvider>
      </CacheProvider>
    </QueryClientProvider>
  );
}
export default App;
