import CompletedWorkWithoutAcceptance from "./Charts/CompletedWorkWithoutAcceptance";
import FinalAcceptancesThatNeedCreation from "./Charts/FinalAcceptancesThatNeedCreation";
import { useTranslation } from "react-i18next";
import DashboardAccordion from "../../DashboardAccordion";
import { renderIfDataExists } from "../common";
import AcceptanceProgress from "./Charts/AcceptanceProgress";

const AcceptanceSection = ({ data }) => {
  const {
    CompleteWorkWithoutAcceptancePerProject,
    CompleteWorkWithoutAcceptancePerTeamLeader,
    acceptanceProgressPerProject,
    acceptanceProgressPerUser,
    FinalAcceptanceNeedCreationPerProject,
    FinalAcceptanceNeedCreationPerUser,
  } = data;

  const { t } = useTranslation("global");

  return (
    <DashboardAccordion title={t("main_cards.titles.acceptance")}>
      {renderIfDataExists(AcceptanceProgress, {
        acceptanceProgressPerProject,
        acceptanceProgressPerUser,
      })}
      {renderIfDataExists(CompletedWorkWithoutAcceptance, {
        CompleteWorkWithoutAcceptancePerProject,
        CompleteWorkWithoutAcceptancePerTeamLeader,
      })}
      {renderIfDataExists(FinalAcceptancesThatNeedCreation, {
        FinalAcceptanceNeedCreationPerProject,
        FinalAcceptanceNeedCreationPerUser,
      })}
    </DashboardAccordion>
  );
};

export default AcceptanceSection;
