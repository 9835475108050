import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useMutation } from "@tanstack/react-query";
import { useSnackbar } from "notistack";
import { Box, Step, StepLabel, Stepper } from "@mui/material";
import SelectWorkOrder from "./steps/SelectWorkOrder";
import Review from "./steps/Review";
import StepperButton from "../ButtonGroup/StepperButton";
import { FinalAcceptanceCreationWindow } from "../../../pages/InformationPages/Quotation/InfoModals/CreateFinalAcceptance";
import Axios from "../../../network/Axios";

const CreateFinalAcceptance = ({ handleClose }) => {
  const [t] = useTranslation("global");
  const { enqueueSnackbar } = useSnackbar();

  const [selectedWorkOrders, setSelectedWorkOrders] = useState([]);
  const [workOrderData, setWorkOrderData] = useState({});

  // Creation window logic
  const [openCreationWindow, setOpenCreationWindow] = useState(false);

  const handleOpenCreationWindow = () => setOpenCreationWindow(true);
  const handleCloseCreationWindow = () => setOpenCreationWindow(false);

  const [activeStep, setActiveStep] = useState(0);

  const handleNext = () => {
    if (activeStep === 1) {
      return handleOpenCreationWindow();
    }
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const { mutateAsync: createFinalAcceptance, isPending } = useMutation({
    mutationFn: async () => {
      return Axios.patch("/Quotations", {
        State: "CreateFinalAcceptance",
        SelectedNo: workOrderData.WorkOrderD6No,
      });
    },
    onSuccess: (data) => {
      enqueueSnackbar(data.data.msg, {
        variant: "success",
      });
      handleClose();
      handleCloseCreationWindow();
    },
  });

  const views = {
    0: (
      <SelectWorkOrder
        selectedRows={selectedWorkOrders}
        setSelectedRows={setSelectedWorkOrders}
        setWorkOrderData={setWorkOrderData}
      />
    ),
    1: (
      <Review
        workOrderNo={selectedWorkOrders[0]}
        workOrderData={workOrderData}
      />
    ),
  };

  const isNextBtnDisabled = activeStep === 0 && selectedWorkOrders.length === 0;

  const steps = [t("steps.select_work_order"), t("steps.review")];

  return (
    <>
      <Box
        sx={{
          width: "100%",
          display: "flex",
          flexDirection: "column",
          gap: 2,
          padding: "1rem",
        }}
      >
        <Stepper activeStep={activeStep}>
          {steps.map((label) => {
            return (
              <Step key={label}>
                <StepLabel>{label}</StepLabel>
              </Step>
            );
          })}
        </Stepper>
        {views[activeStep]}
        <StepperButton
          onClick={handleNext}
          handleBack={handleBack}
          activeStep={activeStep}
          disabled={isNextBtnDisabled}
          label={
            activeStep === steps.length - 1
              ? t("buttons.create")
              : t("modules.buttons.next")
          }
          isLastStep={activeStep === steps.length - 1}
        />
      </Box>
      <FinalAcceptanceCreationWindow
        createFinalAcceptance={createFinalAcceptance}
        open={openCreationWindow}
        isPending={isPending}
        onClose={handleCloseCreationWindow}
      />
    </>
  );
};

export default CreateFinalAcceptance;
