import { useState } from "react";
import { Box, TextField, Link, Stack } from "@mui/material";
import Axios from "../../../network/Axios";
import { useSnackbar } from "notistack";
import { useTranslation } from "react-i18next";
import ItemsReplacements from "./ItemsReplacements";
import InfoModal from "../../../components/Modals/InfoModal";
import FormsDataGrid from "../../../components/DataGrid/FormsDataGrid";
import ButtonGroup from "../../../components/forms/ButtonGroup/ButtonGroup";
import { useQuery, useMutation, useQueryClient } from "@tanstack/react-query";
import { GridActionsCellItem } from "@mui/x-data-grid";
import Edit from "@mui/icons-material/Edit";
import PopUpsButtons from "../ButtonGroup/PopUpsButtons";
import { InventoryManagementService } from "../../../api/inventoryManagement";

const AddGroup = ({ open, handleClose }) => {
  const [groupName, setGroupName] = useState("");
  const [t] = useTranslation("global");
  const queryClient = useQueryClient();
  const { enqueueSnackbar } = useSnackbar();

  const handleCloseGroup = () => {
    handleClose();
    setGroupName("");
  };

  const { mutateAsync: addGroupName, isPending } = useMutation({
    mutationFn: async () => {
      return Axios.patch("/InventoryManagement", {
        State: "AddNewGroup",
        GroupName: groupName,
      });
    },
    onSuccess: (data) => {
      if (!data) return;
      handleCloseGroup();
      enqueueSnackbar("Added Successfully", { variant: "success" });
      queryClient.invalidateQueries({
        queryKey: ["replacementsInventory"],
      });
    },
  });

  return (
    <InfoModal
      open={open}
      handleClose={handleCloseGroup}
      title={t("replacement_model.add_group")}
      width="26rem"
      content={
        <Box padding="15px 10px">
          <TextField
            value={groupName}
            onChange={(e) => setGroupName(e.target.value)}
            label={t("replacement_model.group_name")}
            fullWidth
            size="small"
          />
          <ButtonGroup
            onClickClose={handleCloseGroup}
            onClickSave={addGroupName}
            isPending={isPending}
            saveLabel={t("modules.buttons.save")}
          />
        </Box>
      }
    />
  );
};

const ReplacementsGroups = ({ open, handleClose }) => {
  const [t] = useTranslation("global");
  const [isAddGroupOpen, setIsAddGroupOpen] = useState(false);
  const [items, setItems] = useState({ open: false, groupName: "", id: null });

  const { data, isLoading } = useQuery({
    queryKey: ["replacementsInventory"],
    queryFn: InventoryManagementService.getReplacementsInventory,
    enabled: open,
  });

  // Handling popup
  const [groupNameModal, setGroupNameModal] = useState(false);

  const handleOpenGroupNameModal = () => setGroupNameModal(true);
  const handleCloseGroupNameModal = () => setGroupNameModal(false);

  const queryClient = useQueryClient();
  const { enqueueSnackbar } = useSnackbar();

  const { mutateAsync: editGroupName, isPending } = useMutation({
    mutationFn: InventoryManagementService.editGroupName,
    onSuccess: (data) => {
      handleCloseGroupNameModal();
      enqueueSnackbar(data.msg, { variant: "success" });
      queryClient.invalidateQueries({ queryKey: ["replacementsInventory"] });
    },
  });

  const [selectedGroup, setSelectedGroup] = useState(null);

  const columns = [
    {
      field: "GroupName",
      headerName: t("data_grid.headers.group_name"),
      width: 160,
      flex: 1,
      renderCell: (params) => (
        <Link
          underline="always"
          component="button"
          onClick={() => {
            setItems({
              open: true,
              groupName: params.value,
              id: params.row.idMaterialsReplacementGroups,
            });
          }}
          sx={{
            fontSize: "13px",
            fontWeight: 600,
          }}
        >
          {params.value}
        </Link>
      ),
    },
    {
      field: "ItemCount",
      headerName: t("data_grid.headers.items"),
      width: 160,
      flex: 1,
    },
    {
      field: "ERPUserNickName",
      headerName: t("data_grid.headers.created_by"),
      width: 130,
      flex: 1,
    },
    {
      field: "Actions",
      headerName: t("data_grid.headers.actions"),
      type: "actions",
      getActions: (params) => [
        <GridActionsCellItem
          key={params.row.idEmpInfo}
          icon={<Edit />}
          label="Edit"
          onClick={() => {
            const { GroupName } = params.row;

            setGroupName(GroupName);

            setSelectedGroup(GroupName);

            handleOpenGroupNameModal();
          }}
        />,
      ],
    },
  ];

  const handleAddGroup = () => {
    setIsAddGroupOpen(true);
  };

  const [groupName, setGroupName] = useState("");

  return (
    <>
      <InfoModal
        open={open}
        title={t("replacement_model.group_names")}
        handleClose={handleClose}
        width="56rem"
        content={
          <Box padding={1.2}>
            <FormsDataGrid
              rows={data || []}
              columns={columns}
              loading={isLoading}
              getRowId={(row) => row.idMaterialsReplacementGroups}
              height={420}
              toolbar
              showAdd
              showQuickFilter
              disableSelectionOnClick
              addFunction={handleAddGroup}
            />
          </Box>
        }
      />
      <AddGroup
        open={isAddGroupOpen}
        handleClose={() => setIsAddGroupOpen(false)}
      />
      <ItemsReplacements
        open={items.open}
        handleClose={() => setItems({ open: false, groupName: "", id: null })}
        title={items.groupName}
        id={items.groupName}
      />

      <InfoModal
        open={groupNameModal}
        title={t("replacement_model.edit_group_name")}
        handleClose={handleCloseGroupNameModal}
        width="30rem"
        content={
          <Stack spacing={1.5} p={2}>
            <TextField
              value={groupName}
              onChange={(e) => setGroupName(e.target.value)}
              size="small"
              label={t("replacement_model.group_name")}
              error={groupName.length === 0}
              required
            />
            <PopUpsButtons
              onClickClose={handleCloseGroupNameModal}
              onClickSave={() =>
                editGroupName({
                  GroupName: groupName,
                  SelectedNo: selectedGroup,
                })
              }
              isPending={isPending}
              saveLabel={t("modules.buttons.save")}
            />
          </Stack>
        }
      />
    </>
  );
};

export default ReplacementsGroups;
