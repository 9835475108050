import { useQuery } from "@tanstack/react-query";
import FormsDataGrid from "../../../DataGrid/FormsDataGrid";
import Axios from "../../../../network/Axios";
import FormSpinner from "../../../Spinners/FormSpinner";
import { useTranslation } from "react-i18next";
import ChipStatus from "../../../ChipStatus";
import dayjs from "dayjs";

const SelectWorkOrder = ({
  selectedRows,
  setSelectedRows,
  setWorkOrderData,
}) => {
  const { t } = useTranslation("global");
  const { data, isLoading, isRefetching } = useQuery({
    queryKey: ["WorkOrdersWithOutFinal"],
    queryFn: async () => {
      const response = await Axios.get("/Quotations", {
        params: {
          State: "WorkOrdersWithOutFinal",
        },
      });
      return response.data.result;
    },
  });

  const columns = [
    {
      field: "WorkOrderNo",
      headerName: t("data_grid.headers.ref_no"),
      width: 120,
    },
    {
      field: "WorkOrderD6TypeName",
      headerName: t("data_grid.headers.d6_type"),
      width: 120,
    },
    {
      field: "SiteName",
      headerName: t("data_grid.headers.site_name"),
      flex: 1,
    },
    {
      field: "SiteCode",
      headerName: t("data_grid.headers.site_code"),
      width: 150,
    },
    {
      field: "WorkOrderD6Start",
      headerName: t("data_grid.headers.start_date"),
      valueGetter: ({ value }) => (value ? new Date(value) : null),
      renderCell: ({ value }) => {
        return value ? dayjs(value).format("YYYY/MM/DD") : "";
      },
    },
    {
      field: "WorkOrderD6End",
      headerName: t("data_grid.headers.end_date"),
      valueGetter: ({ value }) => (value ? new Date(value) : null),
      renderCell: ({ value }) => {
        return value ? dayjs(value).format("YYYY/MM/DD") : "";
      },
    },
    {
      field: "WorkOrderD6Status",
      headerName: t("data_grid.headers.status"),
      width: 150,
      renderCell: ({ value }) => {
        return <ChipStatus value={value} />;
      },
    },
  ];

  if (isLoading || isRefetching) return <FormSpinner />;

  return (
    <FormsDataGrid
      rows={data}
      columns={columns}
      loading={isLoading}
      getRowId={(row) => row.WorkOrderNo}
      height={310}
      showQuickFilter
      checkboxSelection
      toolbar
      disableSelectionOnClick
      disableMultipleRowSelection
      onRowSelectionModelChange={(newSelectedRows) => {
        // If newSelectedRows array has more than one item, keep only the second item
        if (newSelectedRows.length > 1) {
          newSelectedRows = [newSelectedRows[1]];
        }

        // Disable the "Select All" checkbox
        const updatedSelectionModel =
          newSelectedRows.length === 1 ? newSelectedRows : [];

        const workOrderData = data.filter(
          (row) => row.WorkOrderNo === newSelectedRows[0]
        )[0];

        setWorkOrderData(workOrderData);

        setSelectedRows(updatedSelectionModel);
      }}
      rowSelectionModel={selectedRows}
      sx={{
        "& .MuiDataGrid-columnHeaderCheckbox": {
          pointerEvents: "none",
        },
      }}
    />
  );
};

export default SelectWorkOrder;
