import { useQuery } from "@tanstack/react-query";
import { useTranslation } from "react-i18next";
import { PurchaseRequestsService } from "../../../../api/purchaseRequests";
import FormSpinner from "../../../Spinners/FormSpinner";
import FormsDataGrid from "../../../DataGrid/FormsDataGrid";

const SelectItems = ({ dispatch, selectedRows }) => {
  const [t] = useTranslation("global");

  const columns = [
    {
      field: "StoreItemsTemplateCode",
      headerName: t("data_grid.headers.item_code"),
    },

    {
      field: "StoreItemsTemplateName",
      headerName: t("data_grid.headers.item_description"),
      flex: 1,
    },
    {
      field: "StoreItemsTemplateType",
      headerName: t("data_grid.headers.type"),
    },
    {
      field: "StoreItemsTemplateUnit",
      headerName: t("data_grid.headers.unit"),
    },
    {
      field: "StoreItemsTemplateQty",
      headerName: t("data_grid.headers.item_total_balance"),
    },
  ];

  const { data: rows, isLoading } = useQuery({
    queryKey: ["purchaseRequestsNewForm"],
    queryFn: PurchaseRequestsService.GetPurchaseRequestsNewFormData,
  });

  if (isLoading) {
    return <FormSpinner />;
  }

  return (
    <FormsDataGrid
      height={320}
      rows={rows}
      columns={columns}
      toolbar
      showQuickFilter
      loading={isLoading}
      getRowId={(row) => row.StoreItemsTemplateCode}
      showReloadOverlay={isLoading}
      disableColumnMenu
      checkboxSelection
      disableSelectionOnClick
      rowSelectionModel={selectedRows.map((row) => row.StoreItemsTemplateCode)}
      onRowSelectionModelChange={(rowSelectionModel) => {
        const newRows = rows.filter((row) =>
          rowSelectionModel.includes(row.StoreItemsTemplateCode)
        );
        dispatch({ type: "rows", payload: newRows });
      }}
    />
  );
};

export default SelectItems;
