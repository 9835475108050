import { useQuery } from "@tanstack/react-query";
import Axios from "../../../../network/Axios";
import { useState } from "react";
import FormSpinner from "../../../../components/Spinners/FormSpinner";
import { Stack, Grid } from "@mui/material";
import { useTranslation } from "react-i18next";
import ChipComponent from "../../../../components/Chips/ChipComponent";
import CustomAccordion from "../../../../components/Accordion/CustomAccordion";
import ChipStatus from "../../../../components/ChipStatus";

const PurchaseRequestSnapShot = ({ purchaseNo, open }) => {
  const { data, isLoading } = useQuery({
    queryKey: ["purchaseCard", purchaseNo],
    queryFn: async () => {
      const response = await Axios.get("/PurchaseRequests", {
        params: {
          SelectedNo: purchaseNo,
          State: "CardDetailed",
        },
      });
      return response.data;
    },
    enabled: open,
  });

  if (isLoading) return <FormSpinner />;

  return <PurchaseSnap data={data} />;
};

const PurchaseSnap = ({ data }) => {
  const [t] = useTranslation("global");

  const {
    MaterialsPurchaseRequestNo,
    ActionDate,
    ERPUserNickName,
    MaterialsPurchaseRequestStatus,
  } = data.result[0];

  const materialRequests = [
    {
      label: t("data_grid.headers.request_no"),
      value: MaterialsPurchaseRequestNo,
    },
    {
      label: t("data_grid.headers.request_date"),
      value: ActionDate,
    },
    {
      label: t("data_grid.headers.requestor"),
      value: ERPUserNickName,
    },
    {
      label: t("data_grid.headers.status"),
      value: <ChipStatus value={MaterialsPurchaseRequestStatus} />,
    },
  ];

  const requestedItems = [
    {
      field: "StoreItemsTemplateCode",
      headerName: t("data_grid.headers.item_code"),
      width: 90,
    },
    {
      field: "StoreItemsTemplateName",
      headerName: t("data_grid.headers.item_name"),
      width: 180,
      flex: 1,
    },
    {
      field: "StoreItemsTemplateUnit",
      headerName: t("data_grid.headers.unit"),
      width: 80,
    },
    {
      field: "MaterialsPurchaseRequestItemsQty",
      headerName: t("data_grid.headers.requested_qty"),
      width: 110,
    },
    {
      field: "MaterialsPurchaseRequestIssuedQty",
      headerName: t("data_grid.headers.added_qty"),
      width: 100,
    },
  ];

  const [expanded, setExpanded] = useState("panel1");
  const handleChange = (panel) => (_, newExpanded) => {
    setExpanded(newExpanded ? panel : false);
  };

  return (
    <Stack spacing={1.2} p={1}>
      <Grid container spacing={1}>
        {materialRequests.map(({ label, value }) => (
          <Grid item xs={4} key={label}>
            <ChipComponent label={label} value={value} />
          </Grid>
        ))}
      </Grid>

      <CustomAccordion
        title={t("data_grid.headers.items")}
        rows={data?.ItemsDetailed || []}
        columns={requestedItems}
        getRowId={(row) => row.idMaterialsPurchaseRequestItems}
        hideFooter
        height={190}
        expanded={expanded === "panel1"}
        onChange={handleChange("panel1")}
      />
    </Stack>
  );
};
export default PurchaseRequestSnapShot;
