// dateRangeSlice.js
import { createSlice } from "@reduxjs/toolkit";
import dayjs from "dayjs";

const startDateAttendance = dayjs()
  .subtract(1, "month")
  .set("date", 25)
  .startOf("day");
const endDateAttendance = dayjs().set("date", 24).startOf("day");

const initialState = {
  startDateAttendance,
  endDateAttendance,
};

const dateRangeAttendanceSlice = createSlice({
  name: "dateRangeAttendance",
  initialState,
  reducers: {
    setStartDateAttendance(state, action) {
      state.startDateAttendance = action.payload;
    },
    setEndDateAttendance(state, action) {
      state.endDateAttendance = action.payload;
    },
  },
});

export const { setStartDateAttendance, setEndDateAttendance } =
  dateRangeAttendanceSlice.actions;
export default dateRangeAttendanceSlice.reducer;
