import { useState } from "react";
import { useSnackbar } from "notistack";
import { useTranslation } from "react-i18next";
import { Box, Chip, TextField } from "@mui/material";
import ButtonGroup from "../../../../components/forms/ButtonGroup/ButtonGroup";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import InfoModal from "../../../../components/Modals/InfoModal";
import { clientInvoicesService } from "../../../../api/clientInvoices";

const ChangeGovInv = ({
  open,
  handleClose,
  clientInvoiceId,
  clientInvoicesInfo,
}) => {
  const [t] = useTranslation("global");
  const queryClient = useQueryClient();
  const { enqueueSnackbar } = useSnackbar();

  const initialGovInvNo = clientInvoicesInfo?.Details[0]?.GovernmentInvoice;

  const [govInvNo, setGovInvNo] = useState(initialGovInvNo);

  const { mutateAsync: changeGovInv, isPending } = useMutation({
    mutationFn: clientInvoicesService.patchClientInvoices,
    onSuccess: (data) => {
      enqueueSnackbar(data.msg, {
        variant: "success",
      });
      queryClient.invalidateQueries(["clientInvoice"]);

      handleClose();
    },
  });

  return (
    <InfoModal
      open={open}
      handleClose={() => {
        handleClose();
      }}
      title={t("info_modules.client_invoices.description.change_gov_invoice")}
      width="28rem"
      content={
        <Box padding={1} display="flex" flexDirection="column" gap={1.6}>
          <Chip
            label={clientInvoiceId}
            sx={{ borderRadius: 1, fontSize: "0.9rem" }}
          />
          <TextField
            label={t(
              "info_modules.client_invoices.description.government_invoice"
            )}
            variant="outlined"
            size="small"
            value={govInvNo}
            onChange={(e) => setGovInvNo(e.target.value)}
            error={govInvNo === ""}
            helperText={
              govInvNo === "" ? t("modules.global_schema.invalid_value") : ""
            }
          />
          <ButtonGroup
            onClickClose={handleClose}
            isPending={isPending}
            onClickSave={() => {
              changeGovInv({
                SelectedNo: clientInvoiceId,
                GovernmentInvoice: govInvNo,
                State: "GovInvoice",
              });
            }}
            saveLabel={t("modules.buttons.save")}
          />
        </Box>
      }
    />
  );
};

export default ChangeGovInv;
