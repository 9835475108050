import { useState } from "react";
import { Box } from "@mui/material";
import * as yup from "yup";
import ButtonGroup from "../../../../components/forms/ButtonGroup/ButtonGroup";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useSnackbar } from "notistack";
import InfoModal from "../../../../components/Modals/InfoModal";
import { useTranslation } from "react-i18next";
import Axios from "../../../../network/Axios";
import { useParams } from "react-router-dom";
import BasicDatePicker from "../../../../components/DateAndTime/DatePicker";
import ImagePickerBox from "../../../../components/forms/CompanyFleet/ImagePickerBox";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import dayjs from "dayjs";
import { MAX_FILE_SIZE } from "../../../../constants/constants";

const ApprovalDocument = ({ open, handleClose }) => {
  const [t] = useTranslation("global");
  const { quotationId } = useParams();
  const queryClient = useQueryClient();
  const { enqueueSnackbar } = useSnackbar();
  const [approvalDate, setApprovalDate] = useState(dayjs());
  const [selectedImage, setSelectedImage] = useState(null);

  const initialValues = {
    image: "",
    date: approvalDate,
  };

  const schema = yup.object().shape({
    image: yup
      .mixed()
      .test("fileSize", t("modules.global_schema.file_size"), (value) => {
        return !value || (value && value.size <= MAX_FILE_SIZE);
      })
      .test(
        "fileType",
        t("modules.global_schema.file_type"),
        (value) =>
          !value || (value && ["image/jpeg", "image/png"].includes(value.type))
      )
      .required(t("modules.global_schema.required")),
  });
  const { mutateAsync: handleApproval, isPending: pendingApproval } =
    useMutation({
      mutationFn: async (formData) => {
        return Axios.patch("/Quotations", formData);
      },
      onSuccess: (data) => {
        if (!data) return;
        enqueueSnackbar("Approval Check Successfully", {
          variant: "success",
        });
        handleClose();
        queryClient.invalidateQueries({
          queryKey: ["quotation", "info"],
        });
      },
    });
  const {
    handleSubmit,
    setValue,
    setError,
    control,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
    mode: "onChange",
    defaultValues: initialValues,
  });
  const handleImage = (event) => {
    setError("image", null);
    const file = event.currentTarget.files[0];
    if (!file) return;
    setSelectedImage(URL.createObjectURL(file));
    setValue("image", file);
  };

  const handleRemoveImage = () => {
    setValue("image", null);
    setSelectedImage(null);
  };
  const handleFormSubmit = (values) => {
    const { image } = values;
    const formData = new FormData();

    formData.append("AcceptanceAttachement", image);
    formData.append("AcceptanceDate", approvalDate.format("YYYY-MM-DD"));
    formData.append("SelectedNo", quotationId);
    formData.append("State", "Approval Check");

    handleApproval(formData);
  };

  const handleCloseAll = () => {
    handleClose();
    setSelectedImage(null);
    setApprovalDate(dayjs());
  };

  return (
    <InfoModal
      open={open}
      handleClose={handleCloseAll}
      title={t("info_modules.quotation.description.upload_approval_document")}
      content={
        <form onSubmit={handleSubmit(handleFormSubmit)} noValidate>
          <Box
            padding="15px 10px"
            display="flex"
            gap="0.8rem"
            flexDirection="column"
          >
            <BasicDatePicker
              label={t("info_modules.quotation.description.approval_date")}
              dateChange={approvalDate}
              handleDateChange={(date) => {
                setApprovalDate(date);
              }}
              width="27rem"
            />
            <Box display="flex" justifyContent="center">
              <ImagePickerBox
                error={errors.image?.message}
                image={selectedImage}
                label={t(
                  "info_modules.quotation.description.upload_approval_document"
                )}
                handleImage={handleImage}
                handleRemoveImage={handleRemoveImage}
                name="approvalDocument"
                control={control}
                maxWidth="27rem"
                height="20rem"
              />
            </Box>

            <ButtonGroup
              saveLabel={t("modules.buttons.save")}
              isPending={pendingApproval}
              onClickClose={handleCloseAll}
            />
          </Box>
        </form>
      }
    />
  );
};
export default ApprovalDocument;
