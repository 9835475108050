import { Box } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useQuery } from "@tanstack/react-query";
import Axios from "../../../../../network/Axios";
import InfoModal from "../../../../../components/Modals/InfoModal";
import FormsDataGrid from "../../../../../components/DataGrid/FormsDataGrid";
import ButtonGroup from "../../../../../components/forms/ButtonGroup/ButtonGroup";

const ReplaceItems = ({
  open,
  handleClose,
  selectedNo,
  selectedCode,
  selectItems,
  setSelectItems,
  setNewSelectedRows,
  newSelectedRows,
  handleReplaceSelection,
}) => {
  const [t] = useTranslation("global");

  const { data, isLoading } = useQuery({
    queryKey: ["inventory", "replace"],
    queryFn: async () => {
      const response = await Axios.get("/InventoryManagement", {
        params: {
          SelectedNo: selectedCode,
          ToStore: selectedNo,
          State: "Replacement",
        },
      });
      return response.data.Items;
    },
    enabled: open,
  });

  const handleRowSelectionModelChange = (selectedItems) => {
    if (selectedItems.length > 1) {
      const lastSelected = selectedItems[selectedItems.length - 1];
      selectedItems = [lastSelected];
    }
    const selectedData = data.filter((row) =>
      selectedItems.includes(row.idCompanyStoreTempleteItems)
    );
    setSelectItems(selectedItems);
    setNewSelectedRows(selectedData);
  };

  const columns = [
    {
      field: "StoreItemsTemplateCode",
      headerName: t("data_grid.headers.code"),
      width: 140,
    },
    {
      field: "StoreItemsTemplateName",
      headerName: t("data_grid.headers.name"),
      width: 70,
    },
    {
      field: "StoreItemsTemplateUnit",
      headerName: t("data_grid.headers.unit"),
      width: 200,
    },
    {
      field: "StoreItemsTemplateType",
      headerName: t("data_grid.headers.type"),
      width: 130,
    },
    {
      field: "CompanyStoreTempleteItemsBalance",
      headerName: t("data_grid.headers.balance"),
      width: 130,
    },
  ];
  return (
    <InfoModal
      open={open}
      handleClose={handleClose}
      title={t("info_modules.material_request.description.select_items")}
      content={
        <Box padding={1.2}>
          <FormsDataGrid
            rows={data || []}
            columns={columns}
            loading={isLoading}
            getRowId={(row) => row.idCompanyStoreTempleteItems}
            height={380}
            showQuickFilter
            toolbar
            checkboxSelection
            disableSelectionOnClick
            onRowSelectionModelChange={handleRowSelectionModelChange}
            rowSelectionModel={selectItems}
          />
          <ButtonGroup
            onClickClose={() => {
              handleClose();
              setSelectItems([]);
            }}
            onClickSave={() => handleReplaceSelection(newSelectedRows)}
            saveLabel={t("modules.buttons.select")}
          />
        </Box>
      }
    />
  );
};

export default ReplaceItems;
