import {
  Box,
  TextField,
  Chip,
  FormControl,
  Radio,
  RadioGroup,
  FormControlLabel,
  FormLabel,
  Typography,
} from "@mui/material";
import * as Yup from "yup";
import { useState } from "react";
import { useFormik } from "formik";
import { useSnackbar } from "notistack";
import Axios from "../../network/Axios";
import { useTranslation } from "react-i18next";
import InfoModal from "../../components/Modals/InfoModal";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import ButtonGroup from "../../components/forms/ButtonGroup/ButtonGroup";

const ChangeRequestForm = ({
  open,
  handleClose,
  requestId,
  apiEndpoint,
  queryKey,
  titleTranslationKey,
  radioBtnLabel1,
  radioBtnLabel2,
  successMessage,
  width,
}) => {
  const [t] = useTranslation("global");
  const queryClient = useQueryClient();
  const { enqueueSnackbar } = useSnackbar();

  const validationSchema = Yup.object().shape({
    description: Yup.string()
      .required(t("modules.global_schema.required"))
      .min(25, t("modules.global_schema.min_length", { length: 25 })),
    type: Yup.string().required(t("modules.global_schema.required")),
  });

  const initialValues = {
    description: "",
    type: "Edit",
  };

  const {
    handleSubmit,
    values,
    resetForm,
    errors,
    touched,
    handleBlur,
    setFieldValue,
  } = useFormik({
    initialValues,
    validationSchema,
    enableReinitialize: true,
    onSubmit: () => {
      if (Object.keys(errors).length !== 0) {
        return;
      } else {
        changeRequest();
      }
    },
  });

  const handleCloseForm = () => {
    handleClose();
    resetForm();
  };

  const { mutateAsync: changeRequest, isPending } = useMutation({
    mutationFn: async () => {
      return Axios.post(apiEndpoint, {
        SelectedNo: requestId,
        [apiEndpoint === "/MaterialChangeRequests"
          ? "ChangeRequestDiscription"
          : "Description"]: values.description,
        Type: values.type,
      });
    },
    onSuccess: (data) => {
      if (!data) return;
      enqueueSnackbar(t(successMessage), {
        variant: "success",
      });
      queryClient.invalidateQueries({ queryKey });
      handleCloseForm();
    },
  });

  const handleRadioChange = (event) => {
    setFieldValue("type", event.target.value);
  };

  return (
    <InfoModal
      open={open}
      handleClose={handleCloseForm}
      title={t(titleTranslationKey)}
      width={width}
      content={
        <form onSubmit={handleSubmit}>
          <Box padding={1.2} display="flex" flexDirection="column" gap={1.2}>
            <Chip
              label={requestId}
              sx={{ borderRadius: 1, fontSize: "0.9rem" }}
            />
            <FormControl>
              <FormLabel
                id="demo-radio-buttons-group-label"
                sx={{ marginLeft: "5px" }}
              >
                {t("info_modules.missions.fields.radio_button_description")}
              </FormLabel>
              <RadioGroup
                aria-labelledby="demo-radio-buttons-group-label"
                name="radio-buttons-group"
                value={values.type}
                onChange={handleRadioChange}
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  gap: "5rem",
                  marginLeft: "5px",
                }}
              >
                <FormControlLabel
                  value="Edit"
                  control={<Radio />}
                  label={radioBtnLabel1}
                />
                <FormControlLabel
                  value="Cancel"
                  control={<Radio />}
                  label={radioBtnLabel2}
                />
              </RadioGroup>
            </FormControl>
            <TextField
              label={t("info_modules.missions.fields.change_request")}
              fullWidth
              value={values.description}
              onChange={(event) => {
                setFieldValue("description", event.target.value);
              }}
              multiline
              rows={4}
              onBlur={handleBlur}
              error={errors.description && touched.description}
            />
            <Box display="flex" justifyContent="space-between">
              <Typography variant="caption" color="error">
                {errors.description &&
                  touched.description &&
                  errors.description}
              </Typography>
              <Typography
                variant="caption"
                color="textSecondary"
                sx={{ marginRight: "5px", fontWeight: 500 }}
              >
                {`${values.description.length}/25`}
              </Typography>
            </Box>
            <ButtonGroup
              onClickClose={handleCloseForm}
              isPending={isPending}
              saveLabel={t("modules.buttons.save")}
              disabled={!values.description || isPending}
            />
          </Box>
        </form>
      }
    />
  );
};

export default ChangeRequestForm;
