import dayjs from "dayjs";
import { useState } from "react";
import { Box } from "@mui/material";
import { useSelector } from "react-redux";
import { useQuery } from "@tanstack/react-query";
import Delete from "@mui/icons-material/Delete";
import ChipStatus from "../../components/ChipStatus";
import { filesFoldersService } from "../../api/filesManager";
import FormSpinner from "../../components/Spinners/FormSpinner";
import FormsDataGrid from "../../components/DataGrid/FormsDataGrid";
import UploadOutlinedIcon from "@mui/icons-material/UploadOutlined";
import DownloadOutlinedIcon from "@mui/icons-material/DownloadOutlined";
import FolderOpenOutlinedIcon from "@mui/icons-material/FolderOpenOutlined";
import CreateNewFolderOutlinedIcon from "@mui/icons-material/CreateNewFolderOutlined";

const LogModal = () => {
  const theme = useSelector((state) => state.theme.colorTheme);
  const [startDatePicker, setStartDatePicker] = useState(
    dayjs()
      .subtract(1, "month")
      .startOf("day")
      .set("hour", dayjs().hour())
      .set("minute", dayjs().minute())
  );
  const [endDatePicker, setEndDatePicker] = useState(
    dayjs()
      .startOf("day")
      .set("hour", dayjs().hour())
      .set("minute", dayjs().minute())
  );

  const { data, isLoading, refetch, isRefetching } = useQuery({
    queryKey: ["logs"],
    queryFn: () =>
      filesFoldersService.getLogsByDate(
        dayjs(startDatePicker).format("YYYY-MM-DD HH:mm:ss"),
        dayjs(endDatePicker).format("YYYY-MM-DD HH:mm:ss")
      ),
  });

  const columns = [
    {
      field: "Icon",
      headerName: "",
      width: 20,
      renderCell: (params) => {
        switch (params.row.ActionName) {
          case "Remove Image From Database":
            return (
              <img
                src={theme === "dark" ? "/database.png" : "databaseDark.png"}
                width="16px"
                height="16px"
                alt="databaseDelete"
              />
            );
          case "Upload File":
            return <UploadOutlinedIcon fontSize="small" />;
          case "Download File":
            return <DownloadOutlinedIcon fontSize="small" />;
          case "Read Directory":
            return <FolderOpenOutlinedIcon fontSize="small" />;
          case "Delete File":
            return <Delete fontSize="small" />;
          case "Create Directory":
            return <CreateNewFolderOutlinedIcon fontSize="small" />;
          default:
            return null;
        }
      },
    },
    { field: "ActionName", headerName: "Action", width: "140" },
    { field: "ServiceName", headerName: "Service", width: "80" },
    { field: "FileName", headerName: "File", flex: 1 },
    { field: "ErrorDetails", headerName: "Error", flex: 1 },
    {
      field: "ActionTime",
      headerName: "Date",
      renderCell: (params) => {
        const date = dayjs(params.value);
        const formattedDate = date.format("YYYY-MM-DD hh:mm:ss a");
        return `${formattedDate}`;
      },
      width: "150",
    },
    {
      field: "Result",
      headerName: "Result",
      width: "130",
      renderCell: (params) => <ChipStatus value={params.value} />,
    },
  ];

  return (
    <Box margin={2}>
      {isLoading ? (
        <FormSpinner />
      ) : (
        <FormsDataGrid
          rows={data?.result || []}
          columns={columns}
          height={410}
          getRowId={(row) => row.idCloudStorageLog}
          toolbar
          showFilters
          showQuickFilter
          showReload
          showDatePicker
          showDateTimePicker
          setEndDatePicker={setEndDatePicker}
          setStartDatePicker={setStartDatePicker}
          startDatePicker={startDatePicker}
          endDatePicker={endDatePicker}
          showReloadOverlay={isRefetching}
          reloadFunction={refetch}
          showExportButton
        />
      )}
    </Box>
  );
};
export default LogModal;
