import { Typography, Box } from "@mui/material";

const EmptyFolder = () => {
  return (
    <Box display="flex" flexDirection="column" alignItems="center">
      <img
        src="/empty-folder.png"
        alt="empty folder"
        style={{
          width: "30%",
          height: "85%",
          marginTop: "-2%",
        }}
      />
      <Typography sx={{ fontWeight: 500, fontSize: "26px" }}>
        This folder is empty!
      </Typography>
    </Box>
  );
};

export default EmptyFolder;
