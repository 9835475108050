import { useCallback, useEffect, useMemo } from "react";
import {
  Box,
  Chip,
  Stack,
  Tab,
  Tabs,
  Typography,
  TextField,
  InputAdornment,
} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import {
  DataGridPro,
  useGridApiContext,
  GridToolbarColumnsButton,
  GridToolbarContainer,
  GridToolbarFilterButton,
  GridToolbarQuickFilter,
  useGridApiRef,
  gridFilteredSortedRowIdsSelector,
  useGridSelector,
  GridPagination,
  gridRowSelectionStateSelector,
} from "@mui/x-data-grid-pro";
import { RemoveRedEye } from "@mui/icons-material";
import { useSelector, useDispatch } from "react-redux";
import CustomNoRowsOverlay from "./NoDataOverlay";
import { useTranslation } from "react-i18next";
import IconButton from "@mui/material/IconButton";
import RefreshIcon from "@mui/icons-material/Refresh";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DateRangePicker } from "@mui/x-date-pickers-pro/DateRangePicker";
import { setStartDate, setEndDate } from "../../store/slices/dateRangeSlice";
import {
  setStartDateAttendance,
  setEndDateAttendance,
} from "../../store/slices/dateRangeAttendanceSlice";
import {
  setStartDateVacation,
  setEndDateVacation,
} from "../../store/slices/dateRangeVacationsSlice";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import {
  setMailServiceEndDate,
  setMailServiceStartDate,
} from "../../store/slices/dateRangeMailServiceSlice";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import CachedIcon from "@mui/icons-material/Cached";
import ManageAccountsIcon from "@mui/icons-material/ManageAccounts";
import GroupsIcon from "@mui/icons-material/Groups";
import { useLocation, useSearchParams } from "react-router-dom";
import DownloadingIcon from "@mui/icons-material/Downloading";
import SettingsIcon from "@mui/icons-material/Settings";
import { useMutation } from "@tanstack/react-query";
import { ColumnsConfigService } from "../../api/datagrid";
import { setColumnsConfig } from "../../store/slices/themeSlice";
import { GridToolbarExport } from "../DataGrid/FormsDataGrid";
import ActionMenuButton from "../forms/ButtonGroup/ActionMenuButton";
import InventoryIcon from "@mui/icons-material/Inventory";
import AssignmentIcon from "@mui/icons-material/Assignment";

const NewList = ({ newButtonList }) => {
  const [t] = useTranslation("global");

  const { actions, actionsConfig } = newButtonList;

  return (
    <ActionMenuButton
      title={t("data_grid.buttons.new")}
      actions={actions}
      actionsConfig={actionsConfig}
      padding="1.8px 5px"
      textTransform="capitalize"
      borderRadius="7px"
      isMainDataGrid
    />
  );
};

const ActionsButtonList = ({ actionsList }) => {
  const [t] = useTranslation("global");

  const { actions, actionsConfig } = actionsList;

  return (
    <ActionMenuButton
      title={t("data_grid.buttons.actions")}
      actions={actions}
      actionsConfig={actionsConfig}
      padding="1.8px 5px"
      textTransform="capitalize"
      borderRadius="7px"
      isMainDataGrid
    />
  );
};

const StatusTabs = ({
  statusNames = [],
  statusColors = [],
  rows = [],
  filterField,
  defaultFilterValue,
}) => {
  const [t] = useTranslation("global");
  const apiRef = useGridApiContext();
  const allCount = rows.length;
  const theme = useSelector((state) => state.theme.colorTheme);

  const getCount = useCallback(
    (status) => {
      return rows.filter((item) => item[filterField] == status).length;
    },
    [filterField, rows]
  );

  const statusCounts = useMemo(
    () =>
      statusNames.map((status) => ({
        displayName: status.displayName,
        name: status.name,
        count: getCount(status.name),
        color: statusColors.find((s) => s.name === status)?.color,
        backgroundColor: statusColors.find((s) => s.name === status.name)
          ?.backgroundColor,
        dark: statusColors.find((s) => s.name === status.name)?.dark,
      })),
    [statusNames, statusColors, getCount]
  );

  const missionsStatusList = [
    {
      id: 1,
      name: "All",
      backgroundColor: "#cfd8dc",
      color: "white",
      count: allCount,
      displayName: t("data_grid.tabs.all"),
    },
    ...statusCounts.map((status) => ({
      id: status.name,
      name: status.name,
      backgroundColor: status.backgroundColor,
      color: "white",
      count: status.count,
      displayName: status.displayName,
      dark: status.dark,
    })),
  ];

  // Tabs state
  const [tabValue, setTabValue] = useSearchParams(
    defaultFilterValue ? "?tab=" + defaultFilterValue : "?tab=All"
  );

  useEffect(() => {
    if (defaultFilterValue) {
      apiRef.current.upsertFilterItem({
        field: filterField,
        operator: "equals",
        value: tabValue.get("tab") === "All" ? "" : tabValue.get("tab"),
        id: 2,
      });
    }
  }, []);

  // Tabs logic
  const handleTabs = (_, newValue) => {
    setTabValue("?tab=" + newValue);
    apiRef.current.upsertFilterItem({
      field: filterField,
      operator: "equals",
      value: newValue === "All" ? "" : newValue,
      id: 2,
    });
    // Check if state exists in local storage
    if (localStorage.getItem("dataGridState")) {
      const dataGridState = JSON.parse(localStorage.getItem("dataGridState"));
      localStorage.setItem(
        "dataGridState",
        JSON.stringify({
          ...dataGridState,
          filterModel: {
            ...dataGridState.filter.filterModel,
            items: [
              ...dataGridState.filter.filterModel.items.filter(
                (item) => item.field !== filterField
              ),
              {
                field: filterField,
                operatorValue: "equals",
                value: newValue === "All" ? "" : newValue,
                id: 2,
              },
            ],
          },
        })
      );
    }
  };

  const { pathname } = useLocation();

  const tab = tabValue.get("tab");
  const storedState = localStorage.getItem("dataGridState");
  const parsedState = storedState ? JSON.parse(storedState) : null;
  const filterItems = parsedState ? parsedState.filter.filterModel.items : [];
  const filterItem = filterItems.find((item) => item.field === filterField);
  const filterValue = filterItem ? filterItem.value : "";

  let result;

  if (!tab && storedState) {
    if (pathname === parsedState.module) {
      if (filterItems.length > 0) {
        if (filterValue === "") {
          result = "All";
        } else {
          result = filterValue;
        }
      } else {
        result = "All";
      }
    } else {
      result = "All";
    }
  } else if (tab === null) {
    result = "All";
  } else {
    result = tab;
  }

  return (
    <Tabs
      value={result}
      onChange={handleTabs}
      aria-label="basic tabs example"
      sx={{ borderBottom: "1px solid lightgrey", width: "100%" }}
    >
      {missionsStatusList.map(
        ({ id, backgroundColor, name, count, displayName, dark }) => {
          return (
            <Tab
              sx={{
                px: 1.25,
              }}
              key={id}
              value={name}
              label={
                <Stack direction={"row"} spacing={1} alignItems={"center"}>
                  <Typography fontWeight={500} textTransform={"capitalize"}>
                    {displayName}
                  </Typography>
                  <Chip
                    label={count}
                    sx={{
                      backgroundColor: `rgba(147, 147, 147, 0.15)`,
                      color:
                        theme === "dark" ? `${backgroundColor}` : `${dark}`,
                      fontWeight: 700,
                    }}
                    size="small"
                  />
                </Stack>
              }
              disableRipple
            />
          );
        }
      )}
    </Tabs>
  );
};

const DataGridButton = ({ onClick, text, icon, loading, ariaLabel }) => {
  const theme = useSelector((state) => state.theme.colorTheme);
  const [t] = useTranslation("global");
  return (
    <IconButton
      onClick={onClick}
      disabled={loading}
      aria-label={ariaLabel}
      sx={{
        borderRadius: 3,
        padding: "4px 6px",
        textTransform: "capitalize",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        gap: "2px",
        margin: "0 1px",
        ":hover": {
          backgroundColor:
            theme === "dark"
              ? "rgba(34, 85, 153, 0.1)"
              : "rgba(80, 152, 236, 0.05)",
        },
      }}
    >
      {icon}
      <Typography color="primary" fontSize="13px" fontWeight="600">
        {t(text)}
      </Typography>
    </IconButton>
  );
};

const getFilteredRows = ({ apiRef }) =>
  gridFilteredSortedRowIdsSelector(apiRef);

const CustomToolbar = ({
  statusNames = [],
  statusColors = [],
  rows = [],
  filterField,
  disableStatusTabs,
  reloadFunction,
  newFunction,
  showDatePicker,
  searchValueCustom,
  builtInSearch,
  customSearchOnChange,
  customSearchValue,
  attendanceDate,
  vacationDate,
  mailServiceDate,
  newButton,
  newButtonList,
  replacementButton,
  replacementFunction,
  userRolesBtn,
  userRolesFunction,
  dataGroupsBtn,
  dataGroupsFunction,
  bulkExportBtn,
  bulkExportFunction,
  bulkExportButtonLabel,
  settingsBtn,
  storesButton,
  storesFunction,
  accountsButton,
  accountsFunction,
  projectsButton,
  projectsFunction,
  settingsFunction,
  actionsList,
  dashBoardBtn,
  defaultFilterValue,
}) => {
  const [t] = useTranslation("global");
  const dispatch = useDispatch();
  const theme = useSelector((state) => state.theme.colorTheme);
  const startDate = useSelector((state) => state.dateRange.startDate);
  const endDate = useSelector((state) => state.dateRange.endDate);

  const dataGridButtons = [
    {
      enabled: newButton,
      text: "data_grid.buttons.new",
      icon: (
        <AddCircleOutlineIcon color="primary" style={{ fontSize: "18px" }} />
      ),
      loading: false,
      ariaLabel: "new",
    },
    {
      text: "modules.users_management.user_roles.title",
      icon: <ManageAccountsIcon color="primary" style={{ fontSize: "18px" }} />,
      loading: false,
      ariaLabel: "user roles",
      enabled: userRolesBtn,
    },
    {
      text: "modules.users_management.data_groups.title",
      icon: <GroupsIcon color="primary" style={{ fontSize: "18px" }} />,
      loading: false,
      ariaLabel: "data groups",
      enabled: dataGroupsBtn,
    },
    {
      text: bulkExportButtonLabel,
      icon: <DownloadingIcon color="primary" style={{ fontSize: "18px" }} />,
      ariaLabel: "bulk export",
      enabled: bulkExportBtn,
    },
    {
      text: "data_grid.buttons.settings",
      icon: <SettingsIcon color="primary" style={{ fontSize: "18px" }} />,
      loading: false,
      ariaLabel: "settings",
      enabled: settingsBtn,
    },
    {
      text: "data_grid.buttons.stores",
      icon: <InventoryIcon color="primary" style={{ fontSize: "16px" }} />,
      loading: false,
      ariaLabel: "stores",
      enabled: storesButton,
    },
    {
      text: "data_grid.buttons.accounts",
      icon: <ManageAccountsIcon color="primary" style={{ fontSize: "18px" }} />,
      loading: false,
      ariaLabel: "accounts",
      enabled: accountsButton,
    },
    {
      text: "data_grid.buttons.projects",
      icon: <AssignmentIcon color="primary" style={{ fontSize: "18px" }} />,
      loading: false,
      ariaLabel: "projects",
      enabled: projectsButton,
    },
    {
      text: "Dashboard",
      icon: <AssignmentIcon color="primary" style={{ fontSize: "18px" }} />,
      loading: false,
      ariaLabel: "dashboard",
      enabled: dashBoardBtn,
    },
  ];

  const { startDateAttendance, endDateAttendance } = useSelector(
    (state) => state.dateRangeAttendance
  );
  const { startDateVacation, endDateVacation } = useSelector(
    (state) => state.dateRangeVacation
  );
  const { mailServiceStartDate, mailServiceEndDate } = useSelector(
    (state) => state.dateRangeMailService
  );

  let defaultDates;

  if (vacationDate) {
    defaultDates = [startDateVacation, endDateVacation];
  } else if (attendanceDate) {
    defaultDates = [startDateAttendance, endDateAttendance];
  } else if (mailServiceDate) {
    defaultDates = [mailServiceStartDate, mailServiceEndDate];
  } else {
    defaultDates = [startDate, endDate];
  }
  function getButtonClickHandler(
    text,
    {
      newFunction,
      bulkExportFunction,
      userRolesFunction,
      dataGroupsFunction,
      settingsFunction,
      replacementFunction,
      storesFunction,
      accountsFunction,
      projectsFunction,
    }
  ) {
    switch (text) {
      case "data_grid.buttons.new":
        return newFunction;
      case bulkExportButtonLabel:
        return bulkExportFunction;
      case "modules.users_management.user_roles.title":
        return userRolesFunction;
      case "modules.users_management.data_groups.title":
        return dataGroupsFunction;
      case "data_grid.buttons.settings":
        return settingsFunction;
      case "data_grid.buttons.stores":
        return storesFunction;
      case "data_grid.buttons.accounts":
        return accountsFunction;
      case "data_grid.buttons.projects":
        return projectsFunction;
      case "Dashboard":
        return dashBoardBtn.function;
      default:
        return replacementFunction;
    }
  }

  return (
    <GridToolbarContainer
      sx={{
        p: 0,
        display: "flex",
        gap: 0,
        flexDirection: "column",
        alignItems: "flex-start",
      }}
    >
      {!disableStatusTabs && (
        <StatusTabs
          rows={rows}
          statusColors={statusColors}
          statusNames={statusNames}
          filterField={filterField}
          defaultFilterValue={defaultFilterValue}
        />
      )}
      <Stack
        direction={"row"}
        p={0.7}
        spacing={1}
        alignItems={"center"}
        justifyContent={"flex-end"}
        width={"100%"}
        useFlexGap
      >
        {!builtInSearch && <GridToolbarQuickFilter />}
        {searchValueCustom && (
          <TextField
            id="standard-basic"
            placeholder="Search..."
            variant="standard"
            value={customSearchValue}
            onChange={customSearchOnChange}
            size="small"
            name="search"
            autoFocus
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon sx={{ fontSize: "1.1rem" }} />
                </InputAdornment>
              ),
            }}
          />
        )}
        <Box
          display="flex"
          alignItems="end"
          justifyContent="flex-end"
          sx={{ marginLeft: "auto" }}
          gap={0.8}
        >
          {showDatePicker && (
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DemoContainer components={["DateRangePicker"]}>
                <DateRangePicker
                  defaultValue={defaultDates}
                  localeText={{
                    start: t(
                      "modules.vacation_requests.new_form.fields.start_date"
                    ),
                    end: t(
                      "modules.vacation_requests.new_form.fields.end_date"
                    ),
                  }}
                  format="YYYY/MM/DD"
                  value={defaultDates}
                  onAccept={(newValue) => {
                    if (vacationDate) {
                      dispatch(setStartDateVacation(newValue[0]));
                      dispatch(setEndDateVacation(newValue[1]));
                    } else if (attendanceDate) {
                      dispatch(setStartDateAttendance(newValue[0]));
                      dispatch(setEndDateAttendance(newValue[1]));
                    } else if (mailServiceDate) {
                      dispatch(setMailServiceStartDate(newValue[0]));
                      dispatch(setMailServiceEndDate(newValue[1]));
                    } else {
                      dispatch(setStartDate(newValue[0]));
                      dispatch(setEndDate(newValue[1]));
                    }
                  }}
                  sx={{
                    "& .MuiInputBase-input": {
                      height: "12px",
                      fontSize: "0.85rem",
                      fontWeight: 600,
                      width: "110px",
                    },
                  }}
                  slotProps={{
                    textField: {
                      size: "small",
                    },
                  }}
                />
              </DemoContainer>
            </LocalizationProvider>
          )}
          {newButtonList && <NewList newButtonList={newButtonList} />}
          {actionsList && <ActionsButtonList actionsList={actionsList} />}
          {dataGridButtons
            .filter((button) => button.enabled)
            .map(({ text, icon, loading, ariaLabel }) => (
              <Box
                sx={{
                  border: "2px solid #2196f3",
                  borderRadius: "7px",
                  display: "flex",
                  justifyContent: "center",
                }}
                key={text}
              >
                <DataGridButton
                  onClick={getButtonClickHandler(text, {
                    newFunction,
                    bulkExportFunction,
                    userRolesFunction,
                    dataGroupsFunction,
                    settingsFunction,
                    replacementFunction,
                    storesFunction,
                    accountsFunction,
                    projectsFunction,
                  })}
                  text={text}
                  icon={icon}
                  loading={loading}
                  ariaLabel={ariaLabel}
                />
              </Box>
            ))}
          {replacementButton && (
            <Box
              sx={{
                border: "2px solid #2196f3",
                borderRadius: "7px",
                display: "flex",
                justifyContent: "center",
              }}
            >
              <IconButton
                onClick={replacementFunction}
                aria-label="replacements"
                sx={{
                  borderRadius: 3,
                  padding: "4px 6px",
                  textTransform: "capitalize",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  gap: "2px",
                  margin: "0 1px",
                  ":hover": {
                    backgroundColor:
                      theme === "dark"
                        ? "rgba(34, 85, 153, 0.1)"
                        : "rgba(80, 152, 236, 0.05)",
                  },
                }}
              >
                <CachedIcon color="primary" style={{ fontSize: "18px" }} />
                <Typography color="primary" fontSize="13px" fontWeight="600">
                  {t("data_grid.buttons.replacement_groups")}
                </Typography>
              </IconButton>
            </Box>
          )}
          <Box
            sx={{
              border: "2px solid #2196f3",
              borderRadius: "7px",
              display: "flex",
              justifyContent: "center",
            }}
          >
            <IconButton
              onClick={reloadFunction}
              aria-label="reload"
              sx={{
                borderRadius: 3,
                padding: "4px 6px",
                textTransform: "capitalize",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                gap: "2px",
                margin: "0 1px",
                ":hover": {
                  backgroundColor:
                    theme === "dark"
                      ? "rgba(34, 85, 153, 0.1)"
                      : "rgba(80, 152, 236, 0.05)",
                },
              }}
            >
              <RefreshIcon color="primary" style={{ fontSize: "18px" }} />
              <Typography color="primary" fontSize="13px" fontWeight="600">
                {t("data_grid.buttons.reload")}
              </Typography>
            </IconButton>
          </Box>

          <GridToolbarColumnsButton
            startIcon={<RemoveRedEye />}
            sx={{
              padding: "2.4px 5px",
              textTransform: "capitalize",
              borderRadius: "7px",
              border: "2px solid #2196f3",
              "& .MuiButton-startIcon": {
                marginRight: "5px",
                marginLeft: 0,
              },
            }}
          />
          <GridToolbarFilterButton
            sx={{
              padding: "1.8px 5px",
              textTransform: "capitalize",
              borderRadius: "7px",
              border: "2px solid #2196f3",
              "& .MuiButton-startIcon": {
                marginRight: "5px",
                marginLeft: 0,
                fontSize: "16px",
              },
            }}
          />
          <ActionMenuButton
            title={t("data_grid.buttons.export")}
            padding="1.7px 4.5px"
            textTransform="capitalize"
            borderRadius="7px"
            customContent={
              <GridToolbarExport
                csvOptions={{
                  allColumns: true,
                  getRowsToExport: getFilteredRows,
                  utf8WithBom: true,
                }}
              />
            }
          />
        </Box>
      </Stack>
    </GridToolbarContainer>
  );
};

function CustomFooter() {
  const { t } = useTranslation("global");
  const apiRef = useGridApiContext();
  const rowCount = useGridSelector(apiRef, gridFilteredSortedRowIdsSelector); // Get the count of filtered rows
  const selectedRowCount = useGridSelector(
    apiRef,
    gridRowSelectionStateSelector
  ); // Get the count of selected rows

  return (
    <Box
      display="flex"
      justifyContent="space-between"
      alignItems="center"
      padding={1}
    >
      <Stack direction="row" spacing={2} sx={{ marginLeft: 2 }}>
        {selectedRowCount.length > 0 && (
          <Typography variant="body2">
            {t("data_grid.footer.selected_rows") +
              " " +
              selectedRowCount.length}
          </Typography>
        )}

        <Typography variant="body2">
          {t("data_grid.footer.total_rows") + " " + rowCount.length}
        </Typography>
      </Stack>
      <GridPagination />
    </Box>
  );
}

export default function CustomDataGrid({
  rows,
  columns,
  getRowId,
  statusNames,
  statusColors,
  filterField,
  reloadFunction,
  newFunction,
  showDatePicker,
  builtInSearch,
  searchValueCustom,
  customSearchValue,
  customSearchOnChange,
  attendanceDate,
  vacationDate,
  mailServiceDate,
  showReloadOverlay,
  disableStatusTabs,
  newButton,
  newButtonList,
  replacementButton,
  replacementFunction,
  userRolesBtn,
  userRolesFunction,
  dataGroupsBtn,
  dataGroupsFunction,
  newUserRolesFunction,
  newDataGroupsFunction,
  enableRowSelection,
  rowSelectionModel,
  onRowSelectionModelChange,
  bulkExportBtn,
  bulkExportFunction,
  initialState,
  settingsBtn,
  storesButton,
  accountsButton,
  accountsFunction,
  projectsButton,
  projectsFunction,
  storesFunction,
  settingsFunction,
  filterModel,
  onFilterModelChange,
  bulkExportButtonLabel,
  getDetailPanelHeight,
  getDetailPanelContent,
  actionsList,
  dashBoardBtn,
  defaultFilterValue,
}) {
  const { colorTheme: theme, columnsConfig } = useSelector(
    (state) => state.theme
  );

  const apiRef = useGridApiRef();

  const { pathname } = useLocation();

  const { mutateAsync: updateColumnsVisibilityModel } = useMutation({
    mutationFn: ColumnsConfigService.updateColumnsVisibilityModel,
  });

  const saveSnapshot = useCallback(() => {
    if (apiRef?.current?.exportState) {
      const currentState = apiRef.current.exportState();
      localStorage.setItem(
        "dataGridState",
        JSON.stringify({ ...currentState, module: pathname })
      );
    }
  }, [apiRef, pathname]);

  useEffect(() => {
    const stateFromLocalStorage = localStorage.getItem("dataGridState");
    if (stateFromLocalStorage) {
      const { module } = JSON.parse(stateFromLocalStorage);
      if (module !== pathname) {
        localStorage.removeItem("dataGridState");
      } else {
        const state = JSON.parse(stateFromLocalStorage);
        apiRef.current.restoreState(state);
      }
    }
  }, [apiRef, pathname]);

  const dispatch = useDispatch();

  return (
    <Box
      sx={{
        height: "86vh",
        width: "100%",
        display: "grid",
        gridTemplateColumns: "1fr",
      }}
    >
      <DataGridPro
        apiRef={apiRef}
        initialState={
          initialState
            ? {
                ...initialState,
                columns: {
                  columnVisibilityModel: {
                    ...initialState.columns.columnVisibilityModel,
                    ...columnsConfig[pathname.replace("/", "")],
                  },
                },
              }
            : {
                columns: {
                  columnVisibilityModel:
                    columnsConfig[pathname.replace("/", "")] || {},
                },
              }
        }
        onFilterModelChange={saveSnapshot}
        onColumnVisibilityModelChange={(model) => {
          saveSnapshot();

          const modifiedModel = {
            ...columnsConfig,
            [pathname.replace("/", "")]: model,
          };

          updateColumnsVisibilityModel(modifiedModel);
          dispatch(setColumnsConfig(modifiedModel));
        }}
        onSortModelChange={saveSnapshot}
        slotProps={{
          columnsPanel: {
            disableHideAllButton: true,
            disableShowAllButton: true,
          },
          basePopper: {
            placement: "bottom-end",
          },
        }}
        slots={{
          footer: CustomFooter,
          toolbar: () => (
            <CustomToolbar
              statusNames={statusNames}
              statusColors={statusColors}
              rows={rows}
              filterField={filterField}
              reloadFunction={reloadFunction}
              newFunction={newFunction}
              showDatePicker={showDatePicker}
              builtInSearch={builtInSearch}
              searchValueCustom={searchValueCustom}
              customSearchValue={customSearchValue}
              customSearchOnChange={customSearchOnChange}
              attendanceDate={attendanceDate}
              vacationDate={vacationDate}
              mailServiceDate={mailServiceDate}
              disableStatusTabs={disableStatusTabs}
              newButton={newButton}
              newButtonList={newButtonList}
              replacementButton={replacementButton}
              replacementFunction={replacementFunction}
              userRolesBtn={userRolesBtn}
              userRolesFunction={userRolesFunction}
              dataGroupsBtn={dataGroupsBtn}
              dataGroupsFunction={dataGroupsFunction}
              newUserRolesFunction={newUserRolesFunction}
              newDataGroupsFunction={newDataGroupsFunction}
              bulkExportBtn={bulkExportBtn}
              bulkExportFunction={bulkExportFunction}
              settingsBtn={settingsBtn}
              storesButton={storesButton}
              accountsButton={accountsButton}
              accountsFunction={accountsFunction}
              projectsButton={projectsButton}
              projectsFunction={projectsFunction}
              settingsFunction={settingsFunction}
              storesFunction={storesFunction}
              filterModel={filterModel}
              onFilterModelChange={onFilterModelChange}
              bulkExportButtonLabel={bulkExportButtonLabel}
              actionsList={actionsList}
              dashBoardBtn={dashBoardBtn}
              defaultFilterValue={defaultFilterValue}
            />
          ),
          noResultsOverlay: CustomNoRowsOverlay,
        }}
        pagination
        rows={rows}
        columns={columns}
        loading={showReloadOverlay}
        disableRowSelectionOnClick
        checkboxSelection={enableRowSelection}
        onRowSelectionModelChange={onRowSelectionModelChange}
        rowSelectionModel={rowSelectionModel}
        getRowId={getRowId}
        rowHeight={34}
        columnHeaderHeight={35}
        getDetailPanelHeight={getDetailPanelHeight}
        getDetailPanelContent={getDetailPanelContent}
        sx={{
          border: "none",
          backgroundColor: "background.card",
          fontFamily: "sans-serif",
          transition: "box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
          boxShadow:
            "rgba(145, 158, 171, 0.2) 0px 0px 2px 0px, rgba(145, 158, 171, 0.12) 0px 12px 24px -4px",
          borderRadius: "16px",
          ".MuiDataGrid-columnHeaders": {
            backgroundColor: theme === "dark" ? "#3e4750" : "#787e85",
            color: "#ffffff",
            display: "flex",
            justifyContent: "flex-start",
          },
          ".MuiDataGrid-columnHeaderTitleContainer": {
            display: "flex",
            justifyContent: "center",
            fontSize: "12px",
          },
          "& .MuiDataGrid-cell": {
            display: "flex",
            justifyContent: "center",
            borderColor: theme === "dark" ? "#666666" : "#cccccc",
            fontSize: "12px",
          },
          "&.css-1vq2q2m-MuiPopper-root-MuiDataGrid-panel": {
            backgroundColor: "red",
          },
          "--DataGrid-overlayHeight": "300px",
        }}
      />
    </Box>
  );
}
