import { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Stepper, Step, StepLabel, Tooltip } from "@mui/material";
import SelectWorkOrder from "./Steps/SelectWorkOrder";
import SelectItems from "./Steps/SelectItems";
import ModifyItem from "./Steps/ModifyItems";
import { useQuery, useMutation, useQueryClient } from "@tanstack/react-query";
import Axios from "../../../network/Axios";
import { useSnackbar } from "notistack";
import QuotationDetails from "./Steps/QuotationDetails";
import dayjs from "dayjs";
import Review from "./Steps/Review";
import { useFormik } from "formik";
import * as yup from "yup";
import useHandleForms from "../../../hooks/useHandleForms";
import StepperButton from "../ButtonGroup/StepperButton";
import FormSpinner from "../../Spinners/FormSpinner";
import { generateId } from "../../../utils/globalFunctions";

const AcceptanceQuotationsForm = ({
  onDirtyChange,
  isThreeStepProcess = false,
  workOrderId,
  handleCloseFromAnotherForm,
  d6Type,
  type,
}) => {
  const [t] = useTranslation("global");
  const { enqueueSnackbar } = useSnackbar();
  const [activeStep, setActiveStep] = useState(0);
  const [selectedWorkOrders, setSelectedWorkOrders] = useState([]);
  const [D6Types, setD6Types] = useState([]);
  const [selectedItems, setSelectedItems] = useState([]);
  const [newWorkOrder, setNewWorkOrder] = useState([]);
  const [newPriceList, setNewPriceList] = useState([]);
  const [rows, setRows] = useState([]);
  const [selectedNo, setSelectedNo] = useState([]);
  const [startDate, setStartDate] = useState(dayjs());
  const [endDate, setEndDate] = useState(dayjs());
  const [totalAmount, setTotalAmount] = useState(0);
  const [totalInstall, setTotalInstall] = useState(0);
  const [totalSupply, setTotalSupply] = useState(0);
  const [isNextDisabled, setNextDisabled] = useState(false);
  const [selectionModel, setSelectionModel] = useState([]);

  const handleSelectionModel = (ids) => {
    setSelectionModel((prev) => {
      if (ids.length && ids.length < prev.length) {
        const removedId = prev.filter((id) => !ids.includes(id));
        const newSelectedItems = selectedItems.filter(
          (item) => item.idPriceList !== removedId[0]
        );
        setSelectedItems(newSelectedItems);
      }

      return ids;
    });

    const newSelectedRows = rows.filter((row) => ids.includes(row.idPriceList));

    setSelectedItems((prev) => {
      const data = [...prev, ...newSelectedRows];

      const uniqueArray = [
        ...new Map(data.map((item) => [item.idPriceList, item])).values(),
      ];

      return uniqueArray;
    });
  };

  const queryClient = useQueryClient();

  const { data: formData, isLoading } = useQuery({
    queryKey: ["workOrdersQuotations"],
    queryFn: async () => {
      const response = await Axios.get("/WorkOrders", {
        params: {
          State: "Quotations",
        },
      });
      return response.data.result;
    },
  });

  const initialValues = {
    survey: "",
    powerUp: "",
    installation: "",
    projectName: "",
    selectedD6: "",
    acquisitionContractor: d6Type?.AcquisitionContractor || "",
    constructionContractor: d6Type?.ConstructionContractor || "",
    supportType: d6Type?.SupportType || "",
    supportHeight: d6Type?.SupportHeight || "",
    numberOfSectors: d6Type?.NumberOfSectors || "",
    equipmentRoom: d6Type?.EquipmentRoom || "",
    firstRunPower: d6Type?.FirstRunPower || "",
    sharingPowerSource: d6Type?.SharingPowerSource || "",
    consultant: d6Type?.Consultant || "",
    sharingStatus: d6Type?.SharingStatus || "",
  };

  const schema = yup.object().shape({
    selectedD6: yup.string().required(t("modules.global_schema.required")),
    ...(isThreeStepProcess && {
      acquisitionContractor: yup
        .string()
        .required(t("modules.global_schema.required")),
      constructionContractor: yup
        .string()
        .required(t("modules.global_schema.required")),
      supportType: yup.string().required(t("modules.global_schema.required")),
      supportHeight: yup.string().required(t("modules.global_schema.required")),
      numberOfSectors: yup
        .string()
        .required(t("modules.global_schema.required")),
      equipmentRoom: yup.string().required(t("modules.global_schema.required")),
      firstRunPower: yup.string().required(t("modules.global_schema.required")),
      sharingPowerSource: yup
        .string()
        .required(t("modules.global_schema.required")),
      consultant: yup.string().required(t("modules.global_schema.required")),
      sharingStatus: yup.string().required(t("modules.global_schema.required")),
    }),
  });

  const workOrders = newWorkOrder.map((order) => ({
    WorkOrderNo: order.WorkOrderNo,
  }));

  const items = newPriceList.map((item) => ({
    idPriceList: item.idPriceList,
    ItemQTY: Number(item.Quantity),
    SI: item.SI,
  }));

  const { data: comoBoxData } = useQuery({
    queryKey: ["quotation", "new"],
    queryFn: async () => {
      const response = await Axios.get("/Quotations", {
        params: { State: "New" },
      });
      return response;
    },
  });

  const { mutateAsync: getD6Types } = useMutation({
    mutationFn: async (subProjectsName) => {
      return Axios.get("/Quotations", {
        params: {
          State: "WorkOrderD6Types",
          SubProjectsName: subProjectsName,
        },
      });
    },
    onSuccess: (data) => {
      if (!data) return;
      setD6Types(data.data.result);
    },
  });
  const workOrderRowById = [
    {
      id: generateId(),
      WorkOrderNo: workOrderId,
    },
  ];

  const { mutateAsync: addNewQuotation, isPending: refetch } = useMutation({
    mutationFn: async () => {
      let anotherResponse;
      if (isThreeStepProcess) {
        anotherResponse = await Axios.put("/WorkOrders", {
          State: "EditExtraDetails",
          AcquisitionContractor: acquisitionContractor,
          ConstructionContractor: constructionContractor,
          SupportType: supportType,
          SupportHeight: supportHeight,
          NumberOfSectors: numberOfSectors,
          EquipmentRoom: equipmentRoom,
          FirstRunPower: firstRunPower,
          SharingPowerSource: sharingPowerSource,
          Consultant: consultant,
          SharingStatus: sharingStatus,
          SelectedNo: workOrderId,
        });
        if (anotherResponse?.status !== 200) {
          throw new Error("WorkOrder update failed");
        }
      }
      const quotationResponse = await Axios.post("/Quotations", {
        WorkOrderD6End: endDate.format("YYYY-MM-DD"),
        WorkOrderD6Start: startDate.format("YYYY-MM-DD"),
        WorkOrderD6TypeName: selectedD6,
        Items: items,
        State: "New",
        WorkOrders: isThreeStepProcess ? workOrderRowById : workOrders,
        Survey: survey,
        PowerUp: powerUp,
        Installation: installation,
        ProjectName: projectName,
      });

      return { quotationResponse, anotherResponse };
    },
    onSuccess: (data) => {
      if (!data) return;
      isThreeStepProcess ? handleCloseFromAnotherForm() : handleClose();
      enqueueSnackbar("WorkOrderD6 Created Successfully", {
        variant: "success",
      });
      queryClient.invalidateQueries({
        predicate: (query) => {
          return ["quotations", "workOrderInfo"].includes(query.queryKey[0]);
        },
      });
    },
  });

  const handleNext = async () => {
    if (activeStep === 0) {
      const selectedData = formData.filter((row) =>
        selectedWorkOrders.includes(row.idWorkOrder)
      );
      setNewWorkOrder(selectedData);
      getD6Types(
        isThreeStepProcess
          ? d6Type?.SubProjectsName
          : selectedData[0].SubProjectsName
      );
    }

    if (isThreeStepProcess ? activeStep === 0 : activeStep === 1) {
      setNewPriceList(selectedItems);
    }
    if (isThreeStepProcess ? activeStep === 1 : activeStep === 2) {
      if (newPriceList.length === 0) setNextDisabled(true);
      if (
        newPriceList.find(
          (row) => row.Quantity <= 0 || row.Quantity == "" || !row.Quantity
        )
      ) {
        enqueueSnackbar(t("modules.error_messages.zero_or_negative_quantity"), {
          variant: "error",
        });
        return;
      }
    }
    if (isThreeStepProcess ? activeStep === 2 : activeStep === 3) {
      const data = await validateForm();
      if (Object.keys(data).length > 0) return;
    }
    if (isThreeStepProcess ? activeStep === 3 : activeStep === 4) {
      return addNewQuotation();
    }
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleDeleteRow = (id) => {
    setNewPriceList((prevRows) =>
      prevRows.filter((row) => row.idPriceList !== id)
    );

    setSelectedItems((prevRows) =>
      prevRows.filter((row) => row.idPriceList !== id)
    );

    const newIds = selectionModel.filter((selectedId) => selectedId !== id);

    setSelectionModel(newIds);
  };

  let steps = [
    t("modules.material_request.steps.select_item"),
    t("modules.material_request.steps.modify_item"),
    type === "acceptance"
      ? t("modules.acceptance.steps.quotation_details")
      : t("modules.quotation.steps.quotation_details"),
    t("modules.material_request.steps.review"),
  ];
  if (!isThreeStepProcess) {
    steps = [t("modules.missions.steps.select_work_order"), ...steps];
  }
  const {
    errors,
    handleChange,
    values,
    setFieldValue,
    validateForm,
    handleSubmit,
    dirty,
    touched,
  } = useFormik({
    initialValues,
    validationSchema: schema,
    onSubmit: handleNext,
    validateOnChange: true,
  });

  const { handleClose } = useHandleForms({ dirty, onDirtyChange });
  const {
    survey,
    powerUp,
    installation,
    projectName,
    selectedD6,
    acquisitionContractor,
    constructionContractor,
    supportType,
    supportHeight,
    numberOfSectors,
    equipmentRoom,
    firstRunPower,
    sharingPowerSource,
    consultant,
    sharingStatus,
  } = values;

  const isRowSelected =
    selectedWorkOrders.length > 0 || selectedItems.length > 0;

  useEffect(() => {
    if (isRowSelected !== dirty) {
      onDirtyChange(isRowSelected);
    }
  }, [isRowSelected, dirty, onDirtyChange]);

  if (isLoading) {
    return <FormSpinner />;
  }

  const priceListColumns = [
    {
      field: "PriceListSN",
      headerName: t("data_grid.headers.code"),
    },
    {
      field: "Description",
      headerName: t("data_grid.headers.description"),
      width: 210,
      flex: 1,
      renderCell: ({ value }) => {
        return (
          <Tooltip title={value} arrow>
            <div
              style={{
                overflow: "hidden",
                textOverflow: "ellipsis",
                whiteSpace: "nowrap",
              }}
            >
              {value}
            </div>
          </Tooltip>
        );
      },
    },
    {
      field: "Unit",
      headerName: t("data_grid.headers.unit"),
    },
    {
      field: "SI",
      headerName: t("data_grid.headers.s_i"),
    },
    {
      field: "Price",
      headerName: t("data_grid.headers.unit_price"),
    },
  ];

  const selectWorkOrderComponent = (
    <SelectWorkOrder
      rows={formData}
      selectedRows={selectedWorkOrders}
      setSelectedRows={setSelectedWorkOrders}
      setNextDisabled={setNextDisabled}
    />
  );
  const selectItemsComponent = (
    <SelectItems
      comboBoxData={comoBoxData}
      rows={rows}
      setSelectedNo={setSelectedNo}
      selectedNo={selectedNo}
      pricesListColumns={priceListColumns}
      type={type}
      handleSelectionModel={handleSelectionModel}
      selectionModel={selectionModel}
      setRows={setRows}
    />
  );
  const modifyItemComponent = (
    <ModifyItem
      setNewRows={setNewPriceList}
      rows={newPriceList}
      onClick={handleDeleteRow}
      totalAmount={totalAmount}
      totalInstall={totalInstall}
      totalSupply={totalSupply}
      setTotalAmount={setTotalAmount}
      setTotalInstall={setTotalInstall}
      setTotalSupply={setTotalSupply}
      type={type}
    />
  );
  const quotationDetailsComponent = (
    <QuotationDetails
      startDate={startDate}
      endDate={endDate}
      setStartDate={setStartDate}
      setEndDate={setEndDate}
      comboBoxData={D6Types}
      errors={errors}
      handleChange={handleChange}
      setFieldValue={setFieldValue}
      touched={touched}
      values={values}
      handleSubmit={handleSubmit}
      d6Type={d6Type}
      isThreeStepProcess={isThreeStepProcess}
    />
  );

  const reviewComponent = (
    <Review
      workOrderRows={newWorkOrder}
      itemsRows={newPriceList}
      startDate={startDate.format("YYYY/MM/DD")}
      endDate={endDate.format("YYYY/MM/DD")}
      selectedD6={values.selectedD6}
      totalAmount={totalAmount}
      totalInstall={totalInstall}
      totalSupply={totalSupply}
      isThreeStepProcess={isThreeStepProcess}
      type={type}
    />
  );
  const views = isThreeStepProcess
    ? {
        0: selectItemsComponent,
        1: modifyItemComponent,
        2: quotationDetailsComponent,
        3: reviewComponent,
      }
    : {
        0: selectWorkOrderComponent,
        1: selectItemsComponent,
        2: modifyItemComponent,
        3: quotationDetailsComponent,
        4: reviewComponent,
      };
  const isLastStep = isThreeStepProcess ? activeStep === 3 : activeStep === 4;

  const label = isLastStep
    ? t("modules.buttons.save")
    : t("modules.buttons.next");

  // Handle Next Button Disabled
  const isSelectionRequired = isThreeStepProcess
    ? activeStep === 0 && !selectionModel.length
    : activeStep === 1 && !selectionModel.length;

  const isWorkOrderRequired =
    !isThreeStepProcess && activeStep === 0 && !selectedWorkOrders.length;

  const isItemSelectionRequired = isThreeStepProcess
    ? activeStep === 1 && !selectedItems.length
    : activeStep === 2 && !selectedItems.length;

  const isPriceListRequired = isLastStep && !newPriceList.length;

  const isNextBtnDisabled =
    isSelectionRequired ||
    isWorkOrderRequired ||
    isNextDisabled ||
    isItemSelectionRequired ||
    isPriceListRequired;

  return (
    <form onSubmit={handleSubmit}>
      <Stepper activeStep={activeStep}>
        {steps.map((label) => {
          return (
            <Step key={label}>
              <StepLabel>{label}</StepLabel>
            </Step>
          );
        })}
      </Stepper>

      {views[activeStep]}
      <StepperButton
        isPending={refetch}
        onClick={handleNext}
        handleBack={handleBack}
        activeStep={activeStep}
        disabled={isNextBtnDisabled}
        label={label}
        isLastStep={isLastStep}
      />
    </form>
  );
};
export default AcceptanceQuotationsForm;
