import { LoadingButton } from "@mui/lab";
import { Box, Button } from "@mui/material";
import { useTranslation } from "react-i18next";

const ButtonGroup = ({
  isPending,
  onClickClose,
  onClickSave,
  saveLabel,
  cancelLabel,
  disabled,
  backgroundColor,
  backgroundColorHover,
  sx,
}) => {
  const [t] = useTranslation("global");

  return (
    <Box
      display="flex"
      gap="10px"
      justifyContent="flex-end"
      margin="13px 12px"
      sx={{ ...sx }}
    >
      <LoadingButton
        type={"submit"}
        onClick={onClickSave}
        loading={isPending}
        disabled={isPending || disabled}
        sx={{
          backgroundColor: backgroundColor || "primary.main",
          "&:hover": {
            backgroundColor: backgroundColorHover || "primary.dark",
          },
          color: "white",
          fontWeight: 500,
        }}
        variant="contained"
      >
        {saveLabel}
      </LoadingButton>
      <Button
        onClick={onClickClose}
        sx={{
          backgroundColor: "error.main",
          "&:hover": {
            backgroundColor: "rgb(255 77 109 / 67%)",
          },
          color: "white",
          fontWeight: 500,
        }}
      >
        {cancelLabel || t("modules.buttons.cancel")}
      </Button>
    </Box>
  );
};

export default ButtonGroup;
