import { useState } from "react";
import { Box, Stack, Typography } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import VisibilityIcon from "@mui/icons-material/Visibility";
import IconButton from "@mui/material/IconButton";
import ImageModal from "../../../../components/Modals/ImageModal";
import { convertImage } from "../../../../utils/utils";

const EmployeeInfoElement = ({ icon, data }) => {
  return (
    <Stack direction="row" spacing={1} alignItems="center">
      {icon}
      <Typography
        sx={{
          fontSize: 12,
        }}
      >
        {data}
      </Typography>
    </Stack>
  );
};

const EmployeeDetails = ({ data, employeeInfoList }) => {
  const { EmpPhoto } = data;

  const convertedImage =
    EmpPhoto === null ? "/person.png" : convertImage(EmpPhoto.data);

  // Handle image modal
  const [isImageModalOpen, setIsImageModalOpen] = useState(false);
  const handleCloseImageModal = () => setIsImageModalOpen(false);
  const handleOpenImageModal = () => setIsImageModalOpen(true);
  const themePalette = useTheme();

  return (
    <Box
      sx={{
        backgroundColor: "background.card",
        width: "100%",
        height: "100%",
        padding: "10px 10px",
        borderRadius: 2,
        boxShadow: themePalette.palette.boxShadow.main,
      }}
    >
      <Stack spacing={1.2} justifyContent="space-between">
        <Box
          sx={{
            width: "100px",
            aspectRatio: "1/1",
            borderRadius: "50%",
            overflow: "hidden",
            alignSelf: "center",
            position: "relative",
          }}
        >
          <img
            src={convertedImage}
            alt="Employee"
            style={{
              width: "100%",
              height: "100%",
              objectFit: "cover",
            }}
          />
          <Box
            sx={{
              position: "absolute",
              top: 0,
              left: 0,
              width: "100%",
              height: "100%",
              backgroundColor: "rgba(0, 0, 0, 0.5)",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              opacity: 0,
              transition: "opacity 0.3s",
              "&:hover": {
                opacity: 1,
              },
            }}
          >
            <IconButton
              aria-label="show image modal"
              onClick={handleOpenImageModal}
              sx={{
                backgroundColor: "#fff",
                "&:hover": {
                  backgroundColor: "#333",
                },
              }}
            >
              <VisibilityIcon color="primary" />
            </IconButton>
          </Box>
        </Box>
        <Stack spacing={1.2}>
          {employeeInfoList.map(({ id, icon, data }) => (
            <EmployeeInfoElement key={id} icon={icon} data={data} />
          ))}
        </Stack>
      </Stack>
      <ImageModal
        open={isImageModalOpen}
        handleClose={handleCloseImageModal}
        image={convertedImage}
      />
    </Box>
  );
};

export default EmployeeDetails;
