import { useEffect } from "react";
import FormsDataGrid from "../../../DataGrid/FormsDataGrid";
import { Box, Tabs, Tab } from "@mui/material";
import { useTranslation } from "react-i18next";
import ImageModal from "../../../Modals/ImageModal";
import useImagePopup from "../../../../hooks/useImagePopup";
import Image from "@mui/icons-material/Image";
import Axios from "../../../../network/Axios";
import { GridActionsCellItem } from "@mui/x-data-grid-pro";
import ChipComponent from "../../../Chips/ChipComponent";
import { formatCurrency } from "../../../../utils/globalFunctions";

const SelectWorkOrder = ({
  rows,
  onRowSelection,
  selectedRows,
  setTotalInstall,
  setTotalSupply,
  totalInstall,
  totalSupply,
  setSelectedWorkOrders,
  currentTab,
  setCurrentTab,
}) => {
  useEffect(() => {
    onRowSelection(selectedRows);
  }, [selectedRows, onRowSelection]);

  useEffect(() => {
    let totalInstallSum = 0.0;
    let totalSupplySum = 0.0;
    let totalSupplyFormatted = "EGP 0.0";
    let totalInstallFormatted = "EGP 0.0";
    selectedRows.forEach((rowId) => {
      const row = rows.find((r) => r.idWorkOrderD6 === rowId);
      if (row) {
        const supplyValue = parseFloat(row.WorkOrderD6Supply.replace(/,/g, ""));
        const installValue = parseFloat(
          row.WorkOrderD6Install.replace(/,/g, "")
        );
        totalSupplySum += supplyValue;
        totalInstallSum += installValue;
        totalSupplyFormatted = formatCurrency(totalSupplySum);
        totalInstallFormatted = formatCurrency(totalInstallSum);
      }
    });
    setTotalSupply(totalSupplyFormatted);
    setTotalInstall(totalInstallFormatted);
  }, [selectedRows, rows]);

  const [t] = useTranslation("global");

  const fetchData = async (id) => {
    try {
      const response = await Axios.get("/Quotations", {
        params: {
          SelectedNo: id,
          State: "Document",
        },
      });
      return response.data.result;
    } catch (error) {
      console.log(error);
    }
  };

  const {
    isImageLoading,
    isImageModalOpen,
    image,
    handleImageModalOpen,
    handleCloseImageModal,
  } = useImagePopup(fetchData, "AcceptanceAttachement");

  const columns = [
    {
      field: "WorkOrderD6No",
      headerName: t("data_grid.headers.ref_no"),
      width: 150,
    },
    {
      field: "SiteName",
      headerName: t("data_grid.headers.site_name"),
      width: 100,
      flex: 1,
    },
    {
      field: "SiteCode",
      headerName: t("data_grid.headers.site_code"),
      width: 110,
      flex: 1,
    },
    {
      field: "CompanyProjectsName",
      headerName: t("data_grid.headers.project"),
      width: 110,
    },
    {
      field: "WorkOrderD6Amount",
      headerName: t("data_grid.headers.amount"),
      width: 90,
    },
    {
      field: "WorkOrderD6Supply",
      headerName: t("data_grid.headers.supply"),
      width: 90,
    },
    {
      field: "WorkOrderD6Install",
      headerName: t("data_grid.headers.install"),
      width: 90,
    },
    {
      field: "ERPUserNickName",
      headerName: t("data_grid.headers.created_by"),
      width: 110,
    },
    {
      field: "AcceptanceDate",
      headerName: t("data_grid.headers.date"),
      width: 100,
      valueFormatter: (params) => {
        const date = new Date(params.value);
        return date.toISOString().split("T")[0];
      },
    },
    {
      field: "RequestorsName",
      headerName: t("data_grid.headers.requestor"),
      width: 100,
    },
    {
      field: "TeamLeadersName",
      headerName: t("data_grid.headers.team_leader"),
      width: 100,
    },
    {
      field: "Actions",
      type: "actions",
      headerName: t("data_grid.headers.actions"),
      width: 70,
      getActions: (params) => [
        <GridActionsCellItem
          key="image"
          icon={<Image />}
          label="Image"
          onClick={() => {
            handleImageModalOpen(params.row.WorkOrderD6No);
          }}
        />,
      ],
    },
  ];

  const handleRowSelectionModelChange = (newSelectedRows) => {
    setSelectedWorkOrders(newSelectedRows);
  };

  const initialColumnVisibilityModel = {
    RequestorsName: false,
    TeamLeadersName: false,
  };
  const handleTabChange = (event, newValue) => {
    setCurrentTab(newValue);
  };
  return (
    <>
      <Tabs value={currentTab} onChange={handleTabChange} variant="fullWidth">
        <Tab label="Supply & Install" />
        <Tab label="Supply" />
        <Tab label="Install" />
      </Tabs>
      <Box sx={{ margin: "12px 1px" }}>
        <FormsDataGrid
          rows={rows}
          columns={columns}
          getRowId={(row) => row.idWorkOrderD6}
          height={380}
          showQuickFilter
          showColumnsFilter
          toolbar
          checkboxSelection
          disableSelectionOnClick
          onRowSelectionModelChange={handleRowSelectionModelChange}
          rowSelectionModel={selectedRows}
          initialState={{
            columns: {
              columnVisibilityModel: initialColumnVisibilityModel,
            },
          }}
        />
        <ImageModal
          open={isImageModalOpen}
          handleClose={handleCloseImageModal}
          image={image}
          loading={isImageLoading}
        />
        <Box
          display="flex"
          alignItems="center"
          paddingTop="15px"
          width="38rem"
          justifyContent="space-between"
        >
          {(currentTab === 0 || currentTab === 1) && (
            <ChipComponent
              label={t("modules.acceptance.labels.total_supply")}
              value={totalSupply}
              extraStyles={{
                display: "grid",
                gridTemplateColumns: "1fr 1fr",
              }}
            />
          )}
          {(currentTab === 0 || currentTab === 2) && (
            <ChipComponent
              label={t("modules.acceptance.labels.total_install")}
              value={totalInstall}
              extraStyles={{
                display: "grid",
                gridTemplateColumns: "1fr 1fr",
              }}
            />
          )}
        </Box>
      </Box>
    </>
  );
};
export default SelectWorkOrder;
