import FormsDataGrid from "../../../../../../components/DataGrid/FormsDataGrid";
import { Autocomplete, Box, TextField } from "@mui/material";
import { useTranslation } from "react-i18next";

const SelectItems = ({
  comboBoxData,
  rows,
  selectedNo,
  setSelectedNo,
  getItems,
  loadingItems,
  handleQuantity,
}) => {
  const [t] = useTranslation("global");

  const columns = [
    {
      field: "StoreItemsTemplateCode",
      headerName: t("data_grid.headers.code"),
    },
    {
      field: "StoreItemsTemplateName",
      headerName: t("data_grid.headers.name"),
      width: 210,
    },
    {
      field: "StoreItemsTemplateUnit",
      headerName: t("data_grid.headers.unit"),
    },
    {
      field: "StoreItemsTemplateType",
      headerName: t("data_grid.headers.type"),
    },
    {
      field: "CompanyStoreTempleteItemsBalance",
      headerName: t("data_grid.headers.balance"),
      valueGetter: (params) =>
        params.row.CompanyStoreTempleteItemsBalance === null
          ? "N/A"
          : params.row.CompanyStoreTempleteItemsBalance,
    },
    {
      field: "MaterialsPurchaseRequestItemsQty",
      headerName: t("data_grid.headers.requested_qty"),
    },
    {
      field: "MaterialsPurchaseRequestIssuedQty",
      headerName: t("data_grid.headers.added_qty"),
      valueGetter: (params) =>
        params.row.MaterialsPurchaseRequestIssuedQty === null
          ? 0
          : params.row.MaterialsPurchaseRequestIssuedQty,
    },
    {
      field: "QTY",
      headerName: t("data_grid.headers.qty"),
      type: "number",
      valueGetter: (params) => params.row.QTY || 0,
      editable: (params) =>
        params.row.CompanyStoreTempleteItemsBalance !== null,
    },
  ];
  const handleSelectionChange = (value) => {
    if (!value) return;
    setSelectedNo(value?.CompanyStoresName);
    getItems();
  };

  return (
    <Box sx={{ margin: "12px 1px" }}>
      <Autocomplete
        id="combo-box-demo"
        options={comboBoxData}
        getOptionLabel={(option) => option.CompanyStoresName}
        sx={{ width: "100%", margin: "8px 0" }}
        size="small"
        value={
          comboBoxData.find(
            (option) => option.CompanyStoresName === selectedNo
          ) || null
        }
        onChange={(event, value) => handleSelectionChange(value)}
        renderInput={(params) => (
          <TextField
            {...params}
            label={t("info_modules.material_request.description.store")}
          />
        )}
      />
      <FormsDataGrid
        rows={rows || []}
        columns={columns}
        getRowId={(row) => row.idMaterialsPurchaseRequestItems}
        disableColumnMenu
        loading={loadingItems}
        onCellEditStop={handleQuantity}
        // getRowClassName={getRowClassName}
        height={300}
      />
    </Box>
  );
};
export default SelectItems;
