import ToggleButton from "@mui/material/ToggleButton";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";
import FolderIcon from "@mui/icons-material/Folder";
import InsertDriveFileIcon from "@mui/icons-material/InsertDriveFile";
import { Stack, Typography } from "@mui/material";
import AntSwitch from "../../../components/Switches/AntSwitch";
import { useTranslation } from "react-i18next";

export default function ToggleButtons({
  handleView,
  currentView,
  isViewEnabled,
  handleViewEnabled,
}) {
  const { t } = useTranslation("global");
  return (
    <Stack direction="row" spacing={2} alignItems="center">
      <Typography> {t("info_modules.next_cloud.labels.view")}:</Typography>
      <ToggleButtonGroup
        disabled={!isViewEnabled}
        color="primary"
        value={currentView}
        exclusive
        onChange={handleView}
        aria-label="directory file view"
        size="small"
      >
        <ToggleButton value="directory" aria-label="directory">
          <FolderIcon />
        </ToggleButton>
        <ToggleButton value="file" aria-label="file">
          <InsertDriveFileIcon />
        </ToggleButton>
      </ToggleButtonGroup>

      <AntSwitch
        onChange={(e, checked) => {
          handleViewEnabled(checked);
        }}
        checked={isViewEnabled}
      />
    </Stack>
  );
}
