import { Box, IconButton, Stack, Tooltip } from "@mui/material";
import { useSelector } from "react-redux";
import { CardHeader } from "../../components/common";
import ReactApexChart from "react-apexcharts";
import InfoIcon from "@mui/icons-material/Info";
import ViewTimelineIcon from "@mui/icons-material/ViewTimeline";
import InfoModal from "../../../../../components/Modals/InfoModal";
import { useEffect, useMemo, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import TimeLine from "./TimeLine/TimeLine";
import { grey } from "@mui/material/colors";
import { useTranslation } from "react-i18next";

const AttendanceChart = ({ data }) => {
  const [t] = useTranslation("global");
  const theme = useSelector((state) => state.theme.colorTheme); // light | dark

  const navigate = useNavigate();

  const { empCode } = useParams();

  const months = data.map((item) => item.Month);

  const options = {
    xaxis: {
      categories: months,
      labels: {
        style: {
          fontSize: "11px",
          colors: theme === "dark" ? grey[300] : grey[700],
        },
        show: true,
        rotateAlways: true,
      },
    },
    dataLabels: {
      enabled: false,
    },
    tooltip: {
      shared: true,
      intersect: false,
      theme,
    },
    legend: {
      labels: {
        colors: theme === "dark" ? grey[300] : grey[700],
      },
    },
    yaxis: {
      labels: {
        style: {
          fontSize: "10px",
          colors: theme === "dark" ? grey[300] : grey[700],
        },
      },
    },
  };

  const series = useMemo(
    () => [
      {
        name: "Attendance",
        data: data.map((item) => item.Attendance),
      },
      {
        name: "Missions",
        data: data.map((item) => item.Missions),
      },
      {
        name: "DriverMandays",
        data: data.map((item) => item.DriverMandays),
      },
      {
        name: "EmployeeMandays",
        data: data.map((item) => item.EmployeeMandays),
      },
      {
        name: "Vacation",
        data: data.map((item) => item.Vacation),
      },
    ],
    [data]
  );

  const [customSeries, setCustomSeries] = useState(series);

  useEffect(() => {
    setCustomSeries(series);
  }, [theme, series]);

  // Handle Modal
  const [isModalOpen, setIsModalOpen] = useState(false);
  const handleCloseModal = () => setIsModalOpen(false);
  const handleOpenModal = () => setIsModalOpen(true);

  return (
    <>
      <Stack
        spacing={3}
        sx={{
          backgroundColor: "background.default",
          padding: "1rem",
          borderRadius: 2,
          border: "1px solid",
          borderColor: "secondary.main",
        }}
      >
        <Stack direction="row" spacing={1} alignItems="center">
          <CardHeader>
            {t("info_modules.employee_records.cards.attendance.title")}
          </CardHeader>
          <Stack direction="row" alignItems="center">
            <Tooltip
              title={t("info_modules.employee_records.tooltips.view_timeline")}
              placement="top"
            >
              <IconButton
                size="small"
                aria-label="timeline"
                onClick={handleOpenModal}
              >
                <ViewTimelineIcon color="warning" />
              </IconButton>
            </Tooltip>

            <Tooltip
              title={t("info_modules.employee_records.tooltips.view_details")}
              placement="top"
            >
              <IconButton
                size="small"
                aria-label="details"
                onClick={() =>
                  navigate(
                    `/employeerecords/${empCode.replace(
                      "/",
                      "%2F"
                    )}/EmployeeHistory`
                  )
                }
              >
                <InfoIcon color="info" />
              </IconButton>
            </Tooltip>
          </Stack>
        </Stack>
        <Box
          component="div"
          dir="ltr"
          sx={{
            "& .apexcharts-toolbar > .apexcharts-menu.apexcharts-menu-open": {
              backgroundColor: theme === "dark" ? "#333" : "#fff",
              color: theme === "dark" ? "#fff" : "#333",
            },
            "& .apexcharts-theme-light .apexcharts-menu-item:hover": {
              background: theme === "dark" ? "#444" : "#f5f5f5",
            },
          }}
        >
          <ReactApexChart
            options={options}
            series={customSeries}
            type="bar"
            height={200}
          />
        </Box>
      </Stack>
      <InfoModal
        title={t(
          "info_modules.employee_records.cards.attendance.timeline.title"
        )}
        content={<TimeLine />}
        handleClose={handleCloseModal}
        open={isModalOpen}
        width="60rem"
      />
    </>
  );
};

export default AttendanceChart;
