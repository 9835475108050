import Axios from "../network/Axios";
import { Tooltip } from "@mui/material";
import { useQuery } from "@tanstack/react-query";
import DateFormatGrid from "../components/DateFormatGrid";
import CustomDataGrid from "../components/DataGrid/CustomDataGrid";
import { useTranslation } from "react-i18next";
import { statusColors } from "./statuses";
import Spinner from "../components/Spinners/Spinner";
import Info from "@mui/icons-material/Info";
import Check from "@mui/icons-material/Check";
import Clear from "@mui/icons-material/Clear";
import { GridActionsCellItem } from "@mui/x-data-grid-pro";
import ChipStatus from "../components/ChipStatus";
import { useState } from "react";
import InfoDialog from "../components/Dialogs/InfoDialog";
import ConfirmationModal from "../components/Modals/ConfirmationModal";
import useChangeRequest from "../components/Notifications/hooks/useChangeRequest";
import { Link } from "react-router-dom";

const MaterialChangeRequest = () => {
  const [t] = useTranslation("global");

  const statusNames = [
    { name: "Approved", displayName: t("data_grid.tabs.approved") },
    {
      name: "Not Approved",
      displayName: t("data_grid.tabs.not_approved"),
    },
    {
      name: "In Progress",
      displayName: t("data_grid.tabs.in_progress"),
    },
  ];

  const [openInfoDialog, setOpenInfoDialog] = useState(false);
  const [description, setDescription] = useState("");

  const handleOpenInfoDialog = () => {
    setOpenInfoDialog(true);
  };

  const handleCloseInfoDialog = () => {
    setOpenInfoDialog(false);
  };

  const {
    confirmationModalState,
    confirmationModalMessage,
    handleOpenConfirmationModal,
    handleCloseConfirmationModal,
    updateChangeStatus,
    confirmFunction,
    isPending,
  } = useChangeRequest();

  const columns = [
    {
      field: "ChangeRequestNo",
      headerName: t("data_grid.headers.ref_no"),
      width: 130,
      hideable: false,
    },
    {
      field: "MaterialsRequestNo",
      headerName: t("data_grid.headers.material_request_no"),
      width: 130,
      renderCell: (params) => {
        return (
          <Link
            style={{
              color: "#1790FF",
            }}
            to={`/materialrequests/${params.value}`}
          >
            {params.value}
          </Link>
        );
      },
    },
    {
      field: "ChangeRequestType",
      headerName: t("data_grid.headers.request_type"),
      width: 90,
    },
    {
      field: "SiteName",
      headerName: t("data_grid.headers.site_name"),
      width: 70,
      flex: 1,
    },
    {
      field: "RequestDate",
      headerName: t("data_grid.headers.request_date"),
      width: 140,
      type: "date",
      valueGetter: ({ value }) => (value ? new Date(value) : null),
      renderCell: ({ value }) => {
        return <> {value ? <DateFormatGrid value={value} /> : <> </>}</>;
      },
    },
    {
      field: "ApprovalDate",
      headerName: t("data_grid.headers.approval_date"),
      width: 140,
      type: "date",
      valueGetter: ({ value }) => (value ? new Date(value) : null),
      renderCell: ({ value }) => {
        return <> {value ? <DateFormatGrid value={value} /> : <> </>}</>;
      },
    },
    {
      field: "ERPUserNickName",
      headerName: t("data_grid.headers.requested_by"),
      width: 140,
      flex: 1,
    },
    {
      field: "MaterialsRequestStatus",
      headerName: t("data_grid.headers.mr_status"),
      width: 130,
      renderCell: ({ value }) => {
        return <ChipStatus value={value} />;
      },
    },
    {
      field: "ChangeRequestStatus",
      headerName: t("data_grid.headers.status"),
      width: 130,
      renderCell: ({ value }) => {
        return <ChipStatus value={value} />;
      },
    },
    {
      field: "Actions",
      type: "actions",
      headerName: t("data_grid.headers.actions"),
      getActions: (params) => [
        <Tooltip title={t("tooltip.info")} key="info">
          <GridActionsCellItem
            icon={<Info />}
            label="Info"
            onClick={() => {
              setDescription(params.row.ChangeRequestDiscription);
              handleOpenInfoDialog();
            }}
          />
        </Tooltip>,
        <Tooltip title={t("tooltip.approve")} key="approve">
          <GridActionsCellItem
            sx={{
              display:
                params.row.ChangeRequestStatus === "In Progress"
                  ? "block"
                  : "none",
            }}
            icon={<Check color="success" />}
            label="Approve"
            onClick={() =>
              handleOpenConfirmationModal(
                t("confirmation_modal.message.approve"),
                () =>
                  updateChangeStatus({
                    ChangeRequestStatus: "Approved",
                    SelectedID: params.row.idMaterialsRequestChangeRequests,
                    type: "Materials",
                  })
              )
            }
          />
        </Tooltip>,
        <Tooltip title={t("tooltip.reject")} key="reject">
          <GridActionsCellItem
            sx={{
              display:
                params.row.ChangeRequestStatus === "In Progress"
                  ? "block"
                  : "none",
            }}
            icon={<Clear color="error" />}
            label="Reject"
            onClick={() =>
              handleOpenConfirmationModal(
                t("confirmation_modal.message.reject"),
                () =>
                  updateChangeStatus({
                    ChangeRequestStatus: "Not Approved",
                    SelectedID: params.row.idMaterialsRequestChangeRequests,
                    type: "Materials",
                  })
              )
            }
          />
        </Tooltip>,
      ],
    },
  ];

  const {
    data: rows,
    isLoading,
    refetch,
    isRefetching,
  } = useQuery({
    queryKey: ["materialChangeRequests"],
    queryFn: async () => {
      const response = await Axios.get("/MaterialChangeRequests");
      return response.data.result;
    },
  });

  if (isLoading) {
    return <Spinner />;
  }

  return (
    <>
      <CustomDataGrid
        rows={rows}
        columns={columns}
        loading={isLoading}
        getRowId={(row) => row.idMaterialsRequestChangeRequests}
        statusColors={statusColors}
        statusNames={statusNames}
        filterField="ChangeRequestStatus"
        showReloadOverlay={isRefetching}
        reloadFunction={refetch}
      />
      <InfoDialog
        open={openInfoDialog}
        description={description}
        handleClose={handleCloseInfoDialog}
        title={t("modules.material_change_requests.description")}
      />
      <ConfirmationModal
        open={confirmationModalState}
        handleClose={handleCloseConfirmationModal}
        confirmFunction={confirmFunction}
        confirmModalMessage={confirmationModalMessage}
        isLoading={isPending}
      />
    </>
  );
};

export default MaterialChangeRequest;
