import Axios from "../network/Axios";

const AddNewDeliveryNoteItem = async (data) => {
  const res = await Axios.post("/DeliveryNoteItems", data);
  return res.data;
};

const GetDeliveryNoteItems = async () => {
  const response = await Axios.get("/DeliveryNoteItems");
  return response.data.result;
};

export const DeliveryNoteItemsService = {
  GetDeliveryNoteItems,
  AddNewDeliveryNoteItem,
};
