import { Box, TextField, Chip } from "@mui/material";
import { AccountsProjectsService } from "../../../api/accounts&projects";
import FormSpinner from "../../../components/Spinners/FormSpinner";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useTranslation } from "react-i18next";
import ButtonGroup from "../../../components/forms/ButtonGroup/ButtonGroup";
import { useQueryClient, useMutation, useQuery } from "@tanstack/react-query";
import { useSnackbar } from "notistack";
import InfoModal from "../../../components/Modals/InfoModal";

const NewProject = ({ open, handleClose, accountName }) => {
  const [t] = useTranslation("global");
  const queryClient = useQueryClient();
  const { enqueueSnackbar } = useSnackbar();

  const { data, isLoading } = useQuery({
    queryKey: ["accountsProjectsUpdate"],
    queryFn: AccountsProjectsService.getAccountsProjects,
    enabled: open,
  });
  const validationSchema = Yup.object().shape({
    projectName: Yup.string().required("Required"),
  });

  const initialValues = {
    projectName: "",
  };
  const handleCloseSave = () => {
    handleClose();
    resetForm();
  };
  const { mutateAsync: newProject, isPending } = useMutation({
    mutationFn: async () => {
      return await AccountsProjectsService.newProject({
        CompanyAccountName: accountName,
        CompanyProjectsName: values.projectName,
      });
    },
    onSuccess: (data) => {
      if (!data) return;
      handleCloseSave();
      enqueueSnackbar(data.msg, { variant: "success" });
      queryClient.invalidateQueries({ queryKey: ["accountsProjects"] });
    },
  });
  const {
    handleSubmit,
    values,
    resetForm,
    errors,
    touched,
    handleChange,
    setFieldValue,
  } = useFormik({
    initialValues,
    validationSchema,
    onSubmit: () => {
      newProject();
    },
    enableReinitialize: true,
  });
  return (
    <InfoModal
      open={open}
      handleClose={handleCloseSave}
      title={t("modules.accounts_projects.modals.new_project")}
      width="30rem"
      content={
        <form onSubmit={handleSubmit}>
          {isLoading ? (
            <FormSpinner />
          ) : (
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                gap: 1,
                margin: 1,
              }}
            >
              <Chip
                label={accountName}
                size="small"
                sx={{
                  borderRadius: 2,
                }}
              />
              <TextField
                fullWidth
                label={t("modules.accounts_projects.modals.project_name")}
                size="small"
                variant="outlined"
                value={values.projectName}
                onChange={handleChange("projectName")}
                name="projectName"
                error={Boolean(errors.projectName && touched.projectName)}
                helperText={
                  touched.projectName ? errors.projectName : undefined
                }
              />

              <ButtonGroup
                isPending={isPending}
                onClickClose={handleCloseSave}
                saveLabel={t("modules.buttons.save")}
              />
            </Box>
          )}
        </form>
      }
    />
  );
};
export default NewProject;
