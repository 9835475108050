import { Outlet, Navigate, useLocation } from "react-router-dom";
import { useSelector } from "react-redux";

const PrivateRoute = () => {
  const { pathname } = useLocation();

  const accessToken = useSelector((state) => state.auth.accessToken);

  return accessToken ? (
    <Outlet />
  ) : (
    <Navigate to="/login" state={{ from: pathname }} />
  );
};

export default PrivateRoute;
