import dayjs from "dayjs";
import { useState } from "react";
import { Tooltip } from "@mui/material";
import Axios from "../network/Axios";
import { useSnackbar } from "notistack";
import { statusColors } from "./statuses";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import CustomDataGrid from "../components/DataGrid/CustomDataGrid";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import Spinner from "../components/Spinners/Spinner";
import DeleteIcon from "@mui/icons-material/Delete";
import Edit from "@mui/icons-material/Edit";
import Image from "@mui/icons-material/Image";
import { GridActionsCellItem } from "@mui/x-data-grid-pro";
import ChipStatus from "../components/ChipStatus";
import useOpenNewForm from "../hooks/useOpenNewForm";
import DateFormatGrid from "../components/DateFormatGrid";
import InfoModal from "../components/Modals/InfoModal";
import { useConfirmationDialog } from "../contexts/ConfirmationDialogContext";
import ImageModal from "../components/Modals/ImageModal";
import { vacationsRequestsService } from "../api/vacationsRequests";
import { attendanceService } from "../api/attendance";
import useEmployeeImagePopup from "../hooks/useEmployeeImagePopup";
import { Link } from "react-router-dom";
import EditAttendance from "./InformationPages/EmployeeRecords/EmployeeHistory/EditAttendance";
import MissionSnapshot from "./InformationPages/EmployeeRecords/cards/Attendance/TimeLine/MissionSnapshot";
import EditVacationRequest from "./InformationPages/EmployeeRecords/cards/Vacations/NewEditForm/EditVacationRequest";

const fetchVacationImage = async (id) => {
  try {
    const response = await Axios.get("/VacationRequests", {
      params: {
        SelectedID: id,
      },
    });
    return response.data.result;
  } catch (error) {
    console.log(error);
  }
};

const Attendance = () => {
  const [t] = useTranslation("global");
  const { enqueueSnackbar } = useSnackbar();
  const queryClient = useQueryClient();
  const [openMissionCard, setOpenMissionCard] = useState(false);
  const [missionNo, setMissionNo] = useState("");

  const { setOpen } = useConfirmationDialog();

  // Handling the vacation edit modal
  const [isModalOpen, setIsModalOpen] = useState(false);

  const handleOpenModal = () => setIsModalOpen(true);
  const handleCloseModal = () => setIsModalOpen(false);

  // VacationID state
  const [vacationID, setVacationID] = useState(null);

  // Handling the signature edit modal
  const [isSignatureModalOpen, setIsSignatureModalOpen] = useState(false);

  const handleOpenSignatureModal = () => setIsSignatureModalOpen(true);
  const handleCloseSignatureModal = () => setIsSignatureModalOpen(false);

  // SignatureID state
  const [signatureID, setSignatureID] = useState(null);

  const imagePopupDetails = {
    Vacation: {
      function: fetchVacationImage,
      type: "VacationAttachement",
    },
    Signature: {
      function: attendanceService.getAttendanceEditData,
      type: "PermissionAttachement",
    },
  };

  const { newForm } = useSelector((state) => state.theme);

  // Handling new form logic
  const { handleOpen } = useOpenNewForm();

  const { startDateAttendance, endDateAttendance } = useSelector(
    (state) => state.dateRangeAttendance
  );

  const statusNames = [
    { name: "Mission", displayName: t("data_grid.tabs.mission") },
    { name: "Signature", displayName: t("data_grid.tabs.signature") },
    { name: "Vacation", displayName: t("data_grid.tabs.vacation") },
  ];

  const columns = [
    {
      field: "Type",
      headerName: t("data_grid.headers.type"),
      width: 100,
      renderCell: ({ value }) => {
        return <ChipStatus value={value} />;
      },
    },
    {
      field: "RefNo",
      headerName: t("data_grid.headers.ref_no"),
      width: 120,
      hideable: false,
      renderCell: (params) => {
        const refNo = params.value;
        if (params.row.Type === "Mission") {
          return (
            <Link
              onClick={() => {
                setMissionNo(refNo);
                setOpenMissionCard(true);
              }}
              style={{
                color: "#1790FF",
              }}
            >
              {refNo}
            </Link>
          );
        } else if (params.row.Type === "Vacation") {
          return <ChipStatus value={refNo} />;
        } else {
          return <span>{refNo}</span>;
        }
      },
    },
    {
      field: "EmpName",
      headerName: t("data_grid.headers.employee_name_ar"),
      width: 90,
      flex: 1,
    },
    {
      field: "EmpNameEn",
      headerName: t("data_grid.headers.employee_name_en"),
      width: 90,
    },

    {
      field: "CarsNumber",
      headerName: t("data_grid.headers.car_number"),
      width: 80,
    },
    {
      field: "Start",
      headerName: t("data_grid.headers.start_date"),
      width: 135,
      type: "date",
      valueGetter: ({ value }) => (value ? new Date(value) : null),
      renderCell: ({ value }) => {
        return <> {value ? <DateFormatGrid value={value} /> : <> </>}</>;
      },
    },
    {
      field: "End",
      headerName: t("data_grid.headers.end_date"),
      width: 135,
      type: "date",
      valueGetter: ({ value }) => (value ? new Date(value) : null),
      renderCell: ({ value }) => {
        return <> {value ? <DateFormatGrid value={value} /> : <> </>}</>;
      },
    },
    {
      field: "SiteName",
      headerName: t("data_grid.headers.site_name"),
      width: 100,
      flex: 1,
    },

    {
      field: "Area",
      headerName: t("data_grid.headers.area"),
      width: 90,
    },
    {
      field: "Status",
      headerName: t("data_grid.headers.status"),
      width: 110,
      renderCell: ({ value }) => {
        return <ChipStatus value={value} />;
      },
    },
    {
      field: "Actions",
      type: "actions",
      headerName: t("data_grid.headers.actions"),
      width: 105,
      getActions: (params) => [
        <Tooltip title={t("tooltip.view")} key="image">
          <GridActionsCellItem
            icon={<Image />}
            label="Image"
            onClick={() => {
              handleImageModalOpen(
                imagePopupDetails[params.row.Type].function,
                imagePopupDetails[params.row.Type].type,
                params.row.SelectedID
              );
            }}
            disabled={params.row.AttachementStatus !== "True"}
          />
        </Tooltip>,
        <Tooltip title={t("tooltip.edit")} key="edit">
          <GridActionsCellItem
            icon={<Edit />}
            label="Edit"
            onClick={() => {
              if (params.row.Type === "Vacation") {
                setVacationID(params.row.SelectedID);
                handleOpenModal();
              } else {
                setSignatureID(params.row.SelectedID);
                handleOpenSignatureModal();
              }
            }}
            disabled={params.row.Type === "Mission"}
          />
        </Tooltip>,
        <Tooltip title={t("tooltip.delete")} key="delete">
          <GridActionsCellItem
            icon={
              <DeleteIcon
                color={params.row.Type === "Mission" ? "disabled" : "error"}
              />
            }
            label="Delete"
            disabled={params.row.Type === "Mission"}
            onClick={() => {
              setOpen(
                t("info_modules.employee_records.confirmation.delete_record"),
                () => () => {
                  if (params.row.Type === "Vacation") {
                    deleteVacationRecord(params.row.SelectedID);
                  } else if (params.row.Type === "Signature") {
                    deleteSignatureRecord(params.row.SelectedID);
                  }
                },
                "error"
              );
            }}
          />
        </Tooltip>,
      ],
    },
    {
      field: "EmpCode",
      headerName: t("data_grid.headers.emp_code"),
      width: 90,
      renderCell: (params) => {
        return (
          <Link
            style={{
              color: "#1790FF",
            }}
            to={`/employeerecords/${params.value.replace("/", "%2F")}`}
            target="_blank"
          >
            {params.value}
          </Link>
        );
      },
    },

    {
      field: "EmpTitle",
      headerName: t("data_grid.headers.emp_title"),
      width: 70,
    },

    {
      field: "CarsType",
      headerName: t("data_grid.headers.car_type"),
      width: 90,
    },

    {
      field: "AttachementStatus",
      headerName: t("data_grid.headers.document_no"),
      width: 70,
    },
  ];

  const { mutateAsync: deleteVacationRecord } = useMutation({
    mutationFn: vacationsRequestsService.deleteVacationRecord,
    onSuccess: (data) => {
      enqueueSnackbar(data.msg, { variant: "success" });
      queryClient.invalidateQueries({
        predicate: (query) => {
          return ["employeeHistory", "attendance"].includes(query.queryKey[0]);
        },
      });
    },
  });

  const { mutateAsync: deleteSignatureRecord } = useMutation({
    mutationFn: attendanceService.deleteSignatureRecord,
    onSuccess: (data) => {
      enqueueSnackbar(data.msg, { variant: "success" });
      queryClient.invalidateQueries({
        predicate: (query) => {
          return ["employeeHistory", "attendance"].includes(query.queryKey[0]);
        },
      });
    },
  });

  const {
    isImageLoading,
    isImageModalOpen,
    image,
    handleImageModalOpen,
    handleCloseImageModal,
  } = useEmployeeImagePopup();

  const {
    data: rows,
    isLoading,
    refetch,
    isRefetching,
  } = useQuery({
    queryKey: ["attendance"],
    queryFn: async () => {
      const response = await Axios.get("/Attendance", {
        params: {
          Start: dayjs(startDateAttendance).format("YYYY-MM-DD"),
          End: dayjs(endDateAttendance).format("YYYY-MM-DD"),
        },
      });
      return response.data.result.map((row, index) => ({
        id: index + 1,
        ...row,
      }));
    },
  });

  if (isLoading) {
    return <Spinner />;
  }

  return (
    <>
      <CustomDataGrid
        rows={rows}
        columns={columns}
        loading={isLoading}
        getRowId={(row) => row.id}
        statusColors={statusColors}
        statusNames={statusNames}
        filterField="Type"
        showDatePicker
        showReloadOverlay={isRefetching}
        reloadFunction={refetch}
        attendanceDate
        vacationDateDate={false}
        mailServiceDate={false}
        initialState={{
          columns: {
            columnVisibilityModel: {
              EmpCode: false,
              EmpNameEn: false,
              EmpTitle: false,
              CarsType: false,
              AttachementStatus: false,
            },
          },
        }}
        enableActions
        newButton
        newFunction={() => {
          if (newForm === "attendance" || newForm === null) {
            handleOpen("attendance");
          } else {
            setOpen(t("modules.new_form_confirmation_message"), () => () => {
              handleOpen("attendance");
            });
          }
        }}
      />
      <ImageModal
        open={isImageModalOpen}
        handleClose={handleCloseImageModal}
        image={image}
        loading={isImageLoading}
      />
      <InfoModal
        open={isModalOpen}
        title={t(
          "info_modules.employee_records.cards.vacations.edit_form.title"
        )}
        content={
          <EditVacationRequest
            handleClose={handleCloseModal}
            vacationID={vacationID}
          />
        }
        handleClose={handleCloseModal}
        width="30rem"
      />
      <InfoModal
        open={isSignatureModalOpen}
        title={t(
          "info_modules.employee_records.cards.attendance.edit_form.title"
        )}
        content={
          <EditAttendance
            handleClose={handleCloseSignatureModal}
            signatureID={signatureID}
          />
        }
        handleClose={handleCloseSignatureModal}
        width="30rem"
      />
      <InfoModal
        open={openMissionCard}
        handleClose={() => setOpenMissionCard(false)}
        title={`${
          missionNo.startsWith("W")
            ? `${t(
                "info_modules.work_orders.description.warranty_visit_details"
              )}`
            : `${t("info_modules.work_orders.description.mission_details")}`
        } - ${missionNo}`}
        content={<MissionSnapshot missionRefNo={missionNo} />}
        width="65rem"
      />
    </>
  );
};

export default Attendance;
