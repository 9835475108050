import { useState } from "react";
import { Box } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { useQuery, useMutation, useQueryClient } from "@tanstack/react-query";
import Axios from "../../../../network/Axios";
import InfoModal from "../../../../components/Modals/InfoModal";
import FormsDataGrid from "../../../../components/DataGrid/FormsDataGrid";
import ButtonGroup from "../../../../components/forms/ButtonGroup/ButtonGroup";
import ConfirmationInfoModal from "../../../../components/Modals/ConfirmationInfoModal";
import { useSnackbar } from "notistack";
import { generateId } from "../../../../utils/globalFunctions";
import dayjs from "dayjs";

const AddCars = ({ open, handleClose, missionStart, missionEnd }) => {
  const { missionId } = useParams();
  const [t] = useTranslation("global");
  const queryClient = useQueryClient();
  const { enqueueSnackbar } = useSnackbar();
  const [selectedRows, setSelectedRows] = useState([]);
  const [selectedData, setSelectedData] = useState([]);
  const [confirmModal, setConfirmModal] = useState(false);

  const handleOpenConfirmModal = () => {
    setConfirmModal(true);
  };

  const initialDate = dayjs().startOf("day").add(8, "hour").add(0, "minute");

  const { data, isLoading } = useQuery({
    queryKey: ["companyFleet", "editAdd"],
    queryFn: async () => {
      const response = await Axios.get("/CompanyFleet", {
        params: {
          MissionDateTime: initialDate.format("YYYY-MM-DD HH:mm:ss"),
        },
      });
      return response.data.result;
    },
    enabled: open,
  });
  const { mutateAsync: addCar, isPending: add } = useMutation({
    mutationFn: async () => {
      const cars = selectedData.map((car) => ({
        CarsNumber: car.CarsNumber,
        CarsType: car.CarsType,
        EmpName: car.EmpName,
        MissionCarEnd: missionEnd,
        MissionCarStart: missionStart,
        id: generateId(),
        idEmpInfoCars: car.idEmpInfoCars,
        idMissionCars: null,
      }));

      return Axios.put("/Missions", {
        State: "AddCar",
        Cars: cars,
        SelectedNo: missionId,
      });
    },
    onSuccess: (data) => {
      if (!data) return;
      enqueueSnackbar(t("modules.error_messages.added_successfully"), {
        variant: "success",
      });
      handleCloseCheck();
      setConfirmModal(false);
      queryClient.invalidateQueries({
        queryKey: ["missionsInfo", missionId],
      });
    },
  });
  const handleRowSelectionModelChange = (newSelectedRows) => {
    const selectedData = data.filter((row) =>
      newSelectedRows.includes(row.idEmpInfoCars)
    );
    setSelectedData(selectedData);
    setSelectedRows(newSelectedRows);
  };
  const handleCloseCheck = () => {
    handleClose();
    setSelectedRows([]);
  };
  const columns = [
    {
      field: "CarsNumber",
      headerName: t("data_grid.headers.cars"),
      width: 140,
    },
    {
      field: "CarsType",
      headerName: t("data_grid.headers.type"),
      width: 70,
    },
    {
      field: "EmpName",
      headerName: t("data_grid.headers.driver"),
      width: 200,
    },
    {
      field: "EmpTitle",
      headerName: t("data_grid.headers.title"),
      width: 130,
    },
  ];
  return (
    <>
      <InfoModal
        open={open}
        handleClose={handleCloseCheck}
        title={t("info_modules.missions.description.add_cars")}
        content={
          <Box padding={1.2}>
            <FormsDataGrid
              rows={data || []}
              columns={columns}
              loading={isLoading}
              getRowId={(row) => row.idEmpInfoCars}
              height={380}
              showQuickFilter
              toolbar
              checkboxSelection
              disableSelectionOnClick
              onRowSelectionModelChange={handleRowSelectionModelChange}
              rowSelectionModel={selectedRows}
            />
            <ButtonGroup
              onClickClose={handleCloseCheck}
              onClickSave={handleOpenConfirmModal}
              saveLabel={t("modules.buttons.select")}
            />
          </Box>
        }
      />
      <ConfirmationInfoModal
        open={confirmModal}
        handleClose={() => {
          setConfirmModal(false);
        }}
        onClick={() => {
          addCar();
        }}
        description={t(
          "info_modules.missions.description.add_car_confirmation"
        )}
        isPending={add}
        color="error"
      />
    </>
  );
};

export default AddCars;
