import { Stack, Typography } from "@mui/material";
import AntSwitch from "../Switches/AntSwitch";

const ChartSwitch = ({
  isSwitchChecked,
  handleSwitchState,
  leftLabel,
  rightLabel,
}) => {
  return (
    <Stack direction="row" spacing={1} sx={{ alignItems: "center" }}>
      <Typography fontSize={12}>{leftLabel}</Typography>
      <AntSwitch
        switchSize={1}
        checked={isSwitchChecked}
        onChange={handleSwitchState}
      />
      <Typography fontSize={12}>{rightLabel}</Typography>
    </Stack>
  );
};

export default ChartSwitch;
