import {
  StyledTextField,
  autoCompleteStyles,
} from "../../../../utils/globalFunctions";
import dayjs from "dayjs";
import * as Yup from "yup";
import download from "downloadjs";
import { useFormik } from "formik";
import { useSnackbar } from "notistack";
import { useState, useMemo } from "react";
import Axios from "../../../../network/Axios";
import AddIcon from "@mui/icons-material/Add";
import { useTranslation } from "react-i18next";
import PauseIcon from "@mui/icons-material/Pause";
import CloseIcon from "@mui/icons-material/Close";
import AddTaskIcon from "@mui/icons-material/AddTask";
import ChipStatus from "../../../../components/ChipStatus";
import { WorkOrdersService } from "../../../../api/workOrders";
import DoneOutlinedIcon from "@mui/icons-material/DoneOutlined";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { Box, Grid, Autocomplete, TextField } from "@mui/material";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";
import RefreshOutlinedIcon from "@mui/icons-material/RefreshOutlined";
import ChipComponent from "../../../../components/Chips/ChipComponent";
import MoveUpOutlinedIcon from "@mui/icons-material/MoveToInboxOutlined";
import { useMutation, useQueryClient, useQuery } from "@tanstack/react-query";
import ButtonGroup from "../../../../components/forms/ButtonGroup/ButtonGroup";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import ActionButton from "../../../../components/forms/ButtonGroup/ActionButton";
import { useConfirmationDialog } from "../../../../contexts/ConfirmationDialogContext";
import ConfirmationInfoModal from "../../../../components/Modals/ConfirmationInfoModal";
import ActionMenuButton from "../../../../components/forms/ButtonGroup/ActionMenuButton";

const WorkOrderDetails = ({
  workOrderInfo,
  handleOpenModal,
  workOrderId,
  isEditable,
  handleCloseEditMode,
  data,
}) => {
  const [t] = useTranslation("global");
  const { setOpen } = useConfirmationDialog();
  const queryClient = useQueryClient();
  const { enqueueSnackbar } = useSnackbar();
  const [confirmModal, setConfirmModal] = useState(false);
  const [isCancel, setIsCancel] = useState(false);

  const validationSchema = Yup.object().shape({
    siteName: Yup.string().required("Required"),
    candidate: Yup.string().required("Required"),
    phase: Yup.string().required("Required"),
    date: Yup.string().required("Required"),
    subProject: Yup.string().required("Required"),
    project: Yup.string().required("Required"),
    requestor: Yup.string().required("Required"),
    teamLeader: Yup.string().required("Required"),
    acquisitionContractor: Yup.string().required("Required"),
    constructionContractor: Yup.string().required("Required"),
    supportType: Yup.string().required("Required"),
    supportHeight: Yup.string().required("Required"),
    numberOfSectors: Yup.string().required("Required"),
    equipmentRoom: Yup.string().required("Required"),
    firstRunPower: Yup.string().required("Required"),
    sharingPowerSource: Yup.string().required("Required"),
    consultant: Yup.string().required("Required"),
    sharingStatus: Yup.string().required("Required"),
    description: Yup.string().required("Required"),
  });

  const initialValues = useMemo(
    () => ({
      siteName: data?.Details[0]?.SiteName || "",
      candidate: data?.Details[0]?.WorkOrderCandidate || "",
      phase: data?.Details[0]?.WorkOrderPhase || "",
      date: dayjs(data?.Details[0]?.WorkOrderDate),
      subProject: data?.Details[0]?.SubProjectsName || "",
      project: data?.Details[0]?.CompanyProjectsName || "",
      requestor: data?.Details[0]?.RequestorsName || "",
      teamLeader: data?.Details[0]?.TeamLeadersName || "",
      description: data?.Details[0]?.WorkOrderDiscription || "",
      acquisitionContractor: data?.Details[0]?.AcquisitionContractor || "",
      constructionContractor: data?.Details[0]?.ConstructionContractor || "",
      supportType: data?.Details[0]?.SupportType || "",
      supportHeight: data?.Details[0]?.SupportHeight || "",
      numberOfSectors: data?.Details[0]?.NumberOfSectors || "",
      equipmentRoom: data?.Details[0]?.EquipmentRoom || "",
      firstRunPower: data?.Details[0]?.FirstRunPower || "",
      sharingPowerSource: data?.Details[0]?.SharingPowerSource || "",
      consultant: data?.Details[0]?.Consultant || "",
      sharingStatus: data?.Details[0]?.SharingStatus || "",
    }),
    [data]
  );

  const {
    handleSubmit,
    values,
    resetForm,
    dirty,
    errors,
    touched,
    handleBlur,
    handleChange,
    setFieldValue,
  } = useFormik({
    initialValues,
    validationSchema,
    onSubmit: () => {
      if (isCancel) {
        setConfirmModal(false);
      } else {
        setConfirmModal(true);
      }
    },
    enableReinitialize: true,
  });

  const {
    WorkOrderNo,
    Typology,
    SiteName,
    SiteCode,
    SiteType,
    WorkOrderDate,
    WorkOrderDiscription,
    WorkOrderStatus,
    WorkOrderHoldReason,
    RejectedByAuthorities,
    RequestorsName,
    CompanyProjectsName,
    SubProjectsName,
    TeamLeadersName,
    ERPUserNickName,
    Latitude,
    Longitude,
    GovernoratesName,
    SiteAddress,
    WorkOrderCandidate,
    WorkOrderPhase,
    MissionsStarted,
    MissionsEnded,
    AcquisitionContractor,
    ConstructionContractor,
    SupportType,
    SupportHeight,
    NumberOfSectors,
    EquipmentRoom,
    FirstRunPower,
    SharingPowerSource,
    Consultant,
    SharingStatus,
  } = workOrderInfo.result[0];

  const {
    requestor,
    teamLeader,
    subProject,
    project,
    candidate,
    description,
    date,
    phase,
    acquisitionContractor,
    constructionContractor,
    supportType,
    supportHeight,
    numberOfSectors,
    equipmentRoom,
    firstRunPower,
    sharingPowerSource,
    consultant,
    sharingStatus,
  } = values;

  const {
    data: subProjectsData,
    isLoading: isLoadingSubProjects,
    isRefetching: isRefetchingSubProjects,
  } = useQuery({
    queryKey: ["newWorkOrdersSubProjects", values.project],
    queryFn: () =>
      WorkOrdersService.getNewWorkOrdersSubProjects(values.project),
    enabled: Boolean(values.project),
  });
  const subProjects = subProjectsData?.map(
    (project) => project.SubProjectsName
  );

  const { mutateAsync: exportPDF } = useMutation({
    mutationFn: async () => {
      const response = await WorkOrdersService.exportWorkOrders([workOrderId]);
      return response;
    },
    onSuccess: (response) => {
      download(response, "Work_Orders.pdf", "application/pdf");
    },
    onError: () => {
      enqueueSnackbar("Failed to export PDF", { variant: "error" });
    },
  });

  const { mutateAsync: editWorkOrder, isPending: pendingWorkOrder } =
    useMutation({
      mutationFn: async () => {
        return Axios.put("/WorkOrders", {
          RequestorName: requestor,
          SelectedNo: workOrderId,
          State: "Edit",
          SubProjectName: subProject,
          TeamLeaderName: teamLeader,
          WorkOrderCandidate: candidate,
          WorkOrderDate: date.format("YYYY/MM/DD hh:mm:00"),
          WorkOrderDiscription: description,
          WorkOrderPhase: phase,
          AcquisitionContractor: acquisitionContractor,
          ConstructionContractor: constructionContractor,
          SupportType: supportType,
          SupportHeight: supportHeight,
          NumberOfSectors: numberOfSectors,
          EquipmentRoom: equipmentRoom,
          FirstRunPower: firstRunPower,
          SharingPowerSource: sharingPowerSource,
          Consultant: consultant,
          SharingStatus: sharingStatus,
        });
      },
      onSuccess: (data) => {
        enqueueSnackbar(data.data.msg, {
          variant: "success",
        });
        queryClient.invalidateQueries({
          queryKey: ["workOrderInfo"],
        });
        handleCloseEditMode();
        resetForm();
      },
    });

  const workOrderProperty = [
    {
      label: t("data_grid.headers.work_order_no"),
      value: WorkOrderNo,
    },
    {
      label: t("data_grid.headers.received_date"),
      value: isEditable ? (
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <DateTimePicker
            value={date}
            onChange={(newValue) => {
              setFieldValue("date", newValue);
            }}
            format="YYYY/MM/DD"
            sx={{
              "& .MuiInputBase-input": {
                height: "10px",
                fontSize: "0.85rem",
                fontWeight: 600,
              },
              "& .MuiSvgIcon-root": {
                width: "0.8em",
                height: "0.8em",
              },
            }}
            slotProps={{
              textField: {
                size: "small",
              },
            }}
          />
        </LocalizationProvider>
      ) : (
        WorkOrderDate
      ),
    },
    {
      label: t("data_grid.headers.status"),
      value: <ChipStatus value={WorkOrderStatus} />,
    },
    {
      label: t("data_grid.headers.site_name"),
      value: SiteName,
    },
    {
      label: t("data_grid.headers.type"),
      value: SiteType,
    },
    {
      label: t("data_grid.headers.user"),
      value: ERPUserNickName,
    },
    {
      label: t("data_grid.headers.site_code"),
      value: SiteCode,
    },

    {
      label: t("data_grid.headers.hold_reason"),
      value: WorkOrderHoldReason,
    },
    {
      label: t("data_grid.headers.requestor"),
      value: isEditable ? (
        <Autocomplete
          disablePortal
          id="combo-box-demo"
          options={data?.Requestors || []}
          getOptionLabel={(option) => option.RequestorsName}
          sx={autoCompleteStyles}
          size="small"
          onChange={(event, newValue) => {
            setFieldValue("requestor", newValue ? newValue.RequestorsName : "");
          }}
          value={
            data?.Requestors.find(
              (option) => option.RequestorsName === requestor
            ) || null
          }
          renderInput={(params) => (
            <StyledTextField
              {...params}
              onBlur={handleBlur}
              error={!!touched.requestor && !!errors.requestor}
              label={touched.requestor && errors.requestor}
            />
          )}
        />
      ) : (
        RequestorsName
      ),
    },
    {
      label: t("data_grid.headers.team_leader"),
      value: isEditable ? (
        <Autocomplete
          disablePortal
          id="combo-box-demo"
          options={data?.TeamLeaders || []}
          getOptionLabel={(option) => option.TeamLeadersName}
          sx={autoCompleteStyles}
          size="small"
          onChange={(event, newValue) => {
            setFieldValue(
              "teamLeader",
              newValue ? newValue.TeamLeadersName : ""
            );
          }}
          value={
            data?.TeamLeaders.find(
              (option) => option.TeamLeadersName === teamLeader
            ) || null
          }
          renderInput={(params) => (
            <StyledTextField
              {...params}
              onBlur={handleBlur}
              error={!!touched.teamLeader && !!errors.teamLeader}
              label={touched.teamLeader && errors.teamLeader}
            />
          )}
        />
      ) : (
        TeamLeadersName
      ),
    },
    {
      label: t("data_grid.headers.sub_project"),
      value: isEditable ? (
        <Autocomplete
          disabled={isLoadingSubProjects || isRefetchingSubProjects}
          disableClearable
          options={subProjects || []}
          sx={autoCompleteStyles}
          size="small"
          onChange={(_, newValue) => {
            setFieldValue("subProject", newValue);
          }}
          value={values.subProject}
          renderInput={(params) => (
            <StyledTextField
              {...params}
              onBlur={handleBlur}
              error={!!touched.subProject && !!errors.subProject}
              label={touched.subProject && errors.subProject}
            />
          )}
        />
      ) : (
        SubProjectsName
      ),
    },
    {
      label: t("data_grid.headers.candidate"),
      value: isEditable ? (
        <StyledTextField
          name="candidate"
          value={candidate}
          onChange={handleChange("candidate")}
        />
      ) : (
        WorkOrderCandidate
      ),
    },
    {
      label: t("data_grid.headers.phase"),
      value: isEditable ? (
        <StyledTextField
          name="phase"
          value={phase}
          onChange={handleChange("phase")}
        />
      ) : (
        WorkOrderPhase
      ),
    },
    {
      label: t("data_grid.headers.typology"),
      value: Typology,
    },
    {
      label: t("data_grid.headers.authorities"),
      value: RejectedByAuthorities,
    },
    {
      label: t("data_grid.headers.project"),
      value: isEditable ? (
        <Autocomplete
          disablePortal
          id="combo-box-demo"
          options={data?.CompanyProjects || []}
          getOptionLabel={(option) => option.CompanyProjectsName}
          sx={autoCompleteStyles}
          size="small"
          onChange={(event, newValue) => {
            setFieldValue(
              "project",
              newValue ? newValue.CompanyProjectsName : ""
            );
            setFieldValue("subProject", "");
          }}
          value={
            data?.CompanyProjects.find(
              (option) => option.CompanyProjectsName === project
            ) || null
          }
          renderInput={(params) => (
            <StyledTextField
              {...params}
              onBlur={handleBlur}
              error={!!touched.project && !!errors.project}
              label={touched.project && errors.project}
            />
          )}
        />
      ) : (
        CompanyProjectsName
      ),
    },
    {
      label: t("data_grid.headers.latitude"),
      value: Latitude,
    },
    {
      label: t("data_grid.headers.longitude"),
      value: Longitude,
    },
    {
      label: t("data_grid.headers.area"),
      value: GovernoratesName,
    },
    {
      label: t("data_grid.headers.missions_started"),
      value: MissionsStarted,
    },
    {
      label: t("data_grid.headers.missions_ended"),
      value: MissionsEnded,
    },
    {
      label: t("data_grid.headers.acquisition_contractor"),
      value: isEditable ? (
        <StyledTextField
          name="acquisitionContractor"
          value={acquisitionContractor}
          onChange={handleChange("acquisitionContractor")}
        />
      ) : (
        AcquisitionContractor
      ),
    },
    {
      label: t("data_grid.headers.construction_contractor"),
      value: isEditable ? (
        <StyledTextField
          name="constructionContractor"
          value={constructionContractor}
          onChange={handleChange("constructionContractor")}
        />
      ) : (
        ConstructionContractor
      ),
    },
    {
      label: t("data_grid.headers.support_type"),
      value: isEditable ? (
        <StyledTextField
          name="supportType"
          value={supportType}
          onChange={handleChange("supportType")}
        />
      ) : (
        SupportType
      ),
    },
    {
      label: t("data_grid.headers.support_height"),
      value: isEditable ? (
        <StyledTextField
          name="supportHeight"
          value={supportHeight}
          onChange={handleChange("supportHeight")}
        />
      ) : (
        SupportHeight
      ),
    },
    {
      label: t("data_grid.headers.number_of_sectors"),
      value: isEditable ? (
        <StyledTextField
          name="numberOfSectors"
          value={numberOfSectors}
          onChange={handleChange("numberOfSectors")}
        />
      ) : (
        NumberOfSectors
      ),
    },
    {
      label: t("data_grid.headers.equipment_room"),
      value: isEditable ? (
        <StyledTextField
          name="equipmentRoom"
          value={equipmentRoom}
          onChange={handleChange("equipmentRoom")}
        />
      ) : (
        EquipmentRoom
      ),
    },
    {
      label: t("data_grid.headers.first_run_power"),
      value: isEditable ? (
        <StyledTextField
          name="firstRunPower"
          value={firstRunPower}
          onChange={handleChange("firstRunPower")}
        />
      ) : (
        FirstRunPower
      ),
    },
    {
      label: t("data_grid.headers.sharing_power_source"),
      value: isEditable ? (
        <StyledTextField
          name="sharingPowerSource"
          value={sharingPowerSource}
          onChange={handleChange("sharingPowerSource")}
        />
      ) : (
        SharingPowerSource
      ),
    },
    {
      label: t("data_grid.headers.consultant"),
      value: isEditable ? (
        <StyledTextField
          name="consultant"
          value={consultant}
          onChange={handleChange("consultant")}
        />
      ) : (
        Consultant
      ),
    },
    {
      label: t("data_grid.headers.sharing_status"),
      value: isEditable ? (
        <StyledTextField
          name="sharingStatus"
          value={sharingStatus}
          onChange={handleChange("sharingStatus")}
        />
      ) : (
        SharingStatus
      ),
    },
  ];

  const useWorkOrders = (Status, successMessage) => {
    const { mutateAsync, isPending } = useMutation({
      mutationFn: async () => {
        return Axios.patch("/WorkOrders", {
          SelectedNo: workOrderId,
          Status: Status,
        });
      },
      onSuccess: () => {
        enqueueSnackbar(successMessage, {
          variant: "success",
        });
        queryClient.invalidateQueries({
          queryKey: ["workOrderInfo"],
        });
      },
    });

    return { mutateAsync, isPending };
  };
  const { mutateAsync: handleWithReserves, isPending: pendingWithReserves } =
    useWorkOrders(
      "Complete With Reserves",
      "Complete With Reserves Successfully"
    );
  const { mutateAsync: handleComplete, isPending: pendingComplete } =
    useWorkOrders("Work Complete", "Work Complete Successfully");

  const { mutateAsync: handleCancel, isPending: pendingCancel } = useWorkOrders(
    "Cancelled",
    "Work Order Cancelled Successfully"
  );
  const { mutateAsync: handleInProgress, isPending: pendingInProgress } =
    useWorkOrders("In Progress", "Changed to In Progress Successfully");

  const {
    mutateAsync: handleEnableDisableAcceptance,
    isPending: pendingEnableDisableAcceptance,
  } = useMutation({
    mutationFn: WorkOrdersService.putWorkOrder,
    onSuccess: (data) => {
      enqueueSnackbar(data.msg, {
        variant: "success",
      });
      queryClient.invalidateQueries({
        queryKey: ["workOrderInfo"],
      });
    },
  });

  const actions = workOrderInfo.Actions || [];

  const preferredOrderActions = [
    "Migrate Work Order",
    "Change Site",
    "Change To Hold",
    "Change Hold Reasons",
    "Change To Cancelled",
    "Change To In Progress",
    "Change To Complete With Reserves",
    "Change To Work Complete",
    "Export PDF",
    "Enable Acceptance",
    "Disable Acceptance",
  ];

  const newActions = actions.filter(
    (action) =>
      action.toLowerCase().includes("new") && action !== "Edit Details"
  );

  const reorderedActions = preferredOrderActions.filter((action) =>
    actions.includes(action)
  );

  const newButtonsConfig = {
    "New Mission": {
      label:
        newActions.length > 1
          ? t("info_modules.work_orders.buttons.mission")
          : t("info_modules.work_orders.buttons.new_mission"),
      onClick: () => handleOpenModal("newMission"),
    },
    "New Materials Request": {
      label:
        newActions.length > 1
          ? t("info_modules.work_orders.buttons.material_request")
          : t("info_modules.work_orders.buttons.new_material_request"),
      onClick: () => handleOpenModal("newMaterialRequest"),
    },
    "New Acceptance": {
      label:
        newActions.length > 1
          ? t("info_modules.work_orders.buttons.acceptance")
          : t("info_modules.work_orders.buttons.new_acceptance"),
      onClick: () => handleOpenModal("newAcceptance"),
    },
    "New Warranty Visit": {
      label:
        newActions.length > 1
          ? t("info_modules.work_orders.buttons.warranty_visit")
          : t("info_modules.work_orders.buttons.new_warranty_visit"),
      onClick: () => handleOpenModal("newWarrantyVisit"),
    },
  };

  const actionsConfig = {
    "Migrate Work Order": {
      label: t("info_modules.work_orders.buttons.move_data_to_another_record"),
      onClick: () => handleOpenModal("migrate"),
      icon: <MoveUpOutlinedIcon sx={{ width: "1.1rem", height: "1.1rem" }} />,
    },
    "Change Site": {
      label: t("info_modules.work_orders.buttons.change_site"),
      icon: <RefreshOutlinedIcon sx={{ width: "1.1rem", height: "1.1rem" }} />,
      onClick: () => handleOpenModal("changeSite"),
    },

    "Change To Hold": {
      label: t("info_modules.work_orders.buttons.put_work_order_in_hold"),
      icon: <PauseIcon sx={{ width: "1.1rem", height: "1.1rem" }} />,
      onClick: () => handleOpenModal("hold"),
    },
    "Change Hold Reasons": {
      label: t("info_modules.work_orders.buttons.change_hold_reason"),
      icon: <PauseIcon sx={{ width: "1.1rem", height: "1.1rem" }} />,
      onClick: () => handleOpenModal("hold"),
    },
    "Change To Cancelled": {
      label: t("info_modules.work_orders.buttons.cancel_work_order"),
      icon: <CloseIcon sx={{ width: "1.1rem", height: "1.1rem" }} />,
      onClick: () => {
        setOpen(t("info_modules.work_orders.confirmation.cancel"), () => () => {
          handleCancel();
        });
      },
      isPending: pendingCancel,
    },
    "Export PDF": {
      label: t("info_modules.work_orders.buttons.export_pdf"),
      icon: <AddTaskIcon sx={{ width: "1.1rem", height: "1.1rem" }} />,
      onClick: () => exportPDF(),
    },
    "Enable Acceptance": {
      label: t("info_modules.work_orders.buttons.enable_acceptance"),
      onClick: () => {
        setOpen(
          t("info_modules.work_orders.confirmation.enable_acceptance"),
          () => () => {
            handleEnableDisableAcceptance({
              workOrderId,
              Status: "EnableAcceptance",
            });
          }
        );
      },
    },
    "Disable Acceptance": {
      label: t("info_modules.work_orders.buttons.disable_acceptance"),
      onClick: () => {
        setOpen(
          t("info_modules.work_orders.confirmation.disable_acceptance"),
          () => () => {
            handleEnableDisableAcceptance({
              workOrderId,
              Status: "DisableAcceptance",
            });
          }
        );
      },
    },
  };

  const subMenuConfig = {
    "Change To In Progress": {
      label: t("info_modules.work_orders.buttons.change_to_in_progress"),
      icon: <RefreshOutlinedIcon sx={{ width: "1.1rem", height: "1.1rem" }} />,
      onClick: () => {
        setOpen(
          t("info_modules.work_orders.confirmation.in_progress"),
          () => () => {
            handleInProgress();
          }
        );
      },
      isPending: pendingInProgress,
    },
    "Change To Work Complete": {
      label: t("info_modules.work_orders.buttons.work_complete"),
      icon: <DoneOutlinedIcon sx={{ width: "1.1rem", height: "1.1rem" }} />,
      onClick: () => {
        setOpen(
          t("info_modules.work_orders.confirmation.work_complete"),
          () => () => {
            handleComplete();
          }
        );
      },
      isPending: pendingComplete,
    },
    "Change To Complete With Reserves": {
      label: t("info_modules.work_orders.buttons.complete_with_reserves"),
      icon: <AddTaskIcon sx={{ width: "1.1rem", height: "1.1rem" }} />,
      onClick: () => {
        setOpen(
          t("info_modules.work_orders.confirmation.complete_with_reserves"),
          () => () => {
            handleWithReserves();
          }
        );
      },
      isPending: pendingWithReserves,
    },
  };
  return (
    <Box>
      <form onSubmit={handleSubmit}>
        {!isEditable && (
          <Box
            display="flex"
            gap="10px"
            justifyContent="flex-end"
            margin="12px"
            sx={{ flexWrap: "wrap !important" }}
          >
            {newActions.length > 0 && (
              <Box display="flex" justifyContent="flex-end" marginBottom="4px">
                {newActions.length > 1 ? (
                  <ActionMenuButton
                    actions={newActions}
                    actionsConfig={newButtonsConfig}
                    title={t("data_grid.buttons.new")}
                  />
                ) : (
                  <ActionButton
                    icon={
                      <AddIcon sx={{ width: "1.1rem", height: "1.1rem" }} />
                    }
                    text={newButtonsConfig[newActions[0]].label}
                    onClick={newButtonsConfig[newActions[0]].onClick}
                    color={newButtonsConfig[newActions[0]].color}
                  />
                )}
              </Box>
            )}

            {reorderedActions.length === 1
              ? (() => {
                  const action = reorderedActions[0];
                  const config = actionsConfig[action] || subMenuConfig[action];
                  return (
                    <ActionButton
                      icon={config.icon}
                      text={config.label}
                      onClick={config.onClick}
                      color={config.color}
                      isPending={config.isPending}
                    />
                  );
                })()
              : Object.keys(actionsConfig && subMenuConfig).some((ele) =>
                  reorderedActions.includes(ele)
                ) && (
                  <ActionMenuButton
                    actions={reorderedActions}
                    actionsConfig={actionsConfig}
                    subMenuConfig={subMenuConfig}
                    subMenuTitle={t(
                      "info_modules.work_orders.buttons.change_status_to"
                    )}
                    showSubMenu
                    showSubMenuItem
                  />
                )}
          </Box>
        )}

        <Grid container spacing={1}>
          {workOrderProperty.map((property, index) => (
            <Grid item xs={4} key={index}>
              <ChipComponent label={property.label} value={property.value} />
            </Grid>
          ))}
          <Grid item xs={12}>
            <ChipComponent
              label={t("data_grid.headers.site_address")}
              value={SiteAddress}
            />
          </Grid>
          <Grid item xs={12}>
            <ChipComponent
              extraStyles={{
                width: "100%",
                "& div:last-child": {
                  width: "100%",
                },
              }}
              label={t("data_grid.headers.description")}
              value={
                isEditable ? (
                  <TextField
                    value={description}
                    name="description"
                    onChange={handleChange("description")}
                    multiline
                    rows={2}
                    sx={{
                      "& .MuiInputBase-input": {
                        fontSize: "0.85rem",
                        fontWeight: 600,
                        width: "100%",
                      },
                    }}
                  />
                ) : (
                  WorkOrderDiscription
                )
              }
            />
          </Grid>
        </Grid>
        {isEditable && (
          <ButtonGroup
            onClickSave={() => {
              setIsCancel(false);
              if (dirty && Object.keys(errors).length === 0) {
                setConfirmModal(true);
              }
            }}
            onClickClose={() => {
              if (dirty) {
                setIsCancel(true);
                setConfirmModal(true);
              } else {
                handleCloseEditMode();
              }
            }}
            saveLabel={t("modules.buttons.save")}
          />
        )}
      </form>

      <ConfirmationInfoModal
        open={confirmModal}
        handleClose={() => {
          setConfirmModal(false);
        }}
        onClick={() =>
          isCancel
            ? (handleCloseEditMode(), setConfirmModal(false), resetForm())
            : editWorkOrder()
        }
        description={
          isCancel
            ? t("info_modules.missions.description.cancel_without_saving")
            : t("info_modules.work_orders.confirmation.edit_details")
        }
        isPending={isCancel ? null : pendingWorkOrder}
      />
    </Box>
  );
};
export default WorkOrderDetails;
