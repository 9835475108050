import { useState } from "react";
import PasswordStrengthBar from "./PasswordStrengthBar";
import PasswordField from "./PasswordField";
import * as yup from "yup";
import Axios from "../../network/Axios";
import { Typography, Box } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useFormik } from "formik";
import ButtonGroup from "./ButtonGroup/ButtonGroup";

const ChangePasswordForm = ({ onClickClose }) => {
  const [t] = useTranslation("global");

  const initialValues = {
    oldPassword: "",
    newPassword: "",
    confirmPassword: "",
  };
  const passwordSchema = yup.object().shape({
    oldPassword: yup.string().required(t("change_password_form.required")),
    newPassword: yup.string().required(t("change_password_form.required")),
    confirmPassword: yup
      .string()
      .required(t("change_password_form.required"))
      .test(
        "passwords-match",
        t("change_password_form.passwords_match"),
        function (value, { parent }) {
          return value === parent.newPassword;
        }
      ),
  });

  const [strength, setStrength] = useState("");
  const [confirmError, setConfirmError] = useState("");

  const handleFormSubmit = async (values) => {
    await Axios.patch("/ChangePassword", {
      NewPassword: values.newPassword,
      Password: values.oldPassword,
    });
  };

  const getPasswordStrength = async (password) => {
    try {
      const response = await Axios.get("/PasswordStrengthCheck", {
        params: {
          NewPassword: password,
        },
      });
      const strength = response.data?.msg;
      switch (strength) {
        case "Too weak":
          setStrength("too weak");
          break;
        case "Weak":
          setStrength("weak");
          break;
        case "Medium":
          setStrength("medium");
          break;
        case "Strong":
          setStrength("strong");
          break;
        default:
          setStrength("too weak");
      }
    } catch (error) {
      console.error("Error getting password strength:", error);
      setStrength("too weak");
    }
  };
  const handleConfirmChange = (event, values) => {
    const confirmValue = event.target.value;
    setConfirmError(
      values.newPassword !== confirmValue ? "Passwords must match" : ""
    );
  };

  const { values, errors, touched, handleBlur, handleChange, handleSubmit } =
    useFormik({
      initialValues,
      validationSchema: passwordSchema,
      onSubmit: handleFormSubmit,
    });

  return (
    <form onSubmit={handleSubmit}>
      <Box display="flex" flexDirection="column" gap="12px">
        <PasswordField
          label={t("change_password_form.old_password")}
          name="oldPassword"
          value={values.oldPassword}
          onBlur={handleBlur}
          onChange={handleChange}
          error={!!touched.oldPassword && !!errors.oldPassword}
          helperText={touched.oldPassword && errors.oldPassword}
        />
        <PasswordField
          label={t("change_password_form.new_password")}
          name="newPassword"
          value={values.newPassword}
          onBlur={handleBlur}
          onChange={(event) => {
            const newPassword = event.target.value;
            handleChange(event);
            getPasswordStrength(newPassword);
            handleConfirmChange(event, values);
          }}
          error={!!touched.newPassword && !!errors.newPassword}
          helperText={touched.newPassword && errors.newPassword}
        />
        <PasswordStrengthBar strength={strength} />
        <PasswordField
          label={t("change_password_form.confirm_password")}
          name="confirmPassword"
          value={values.confirmPassword}
          onBlur={handleBlur}
          onChange={(event) => {
            handleChange(event);
            handleConfirmChange(event, values);
          }}
          error={
            !!touched.confirmPassword &&
            (!!errors.confirmPassword || !!confirmError)
          }
          helperText={
            touched.confirmPassword && (errors.confirmPassword || confirmError)
          }
        />
        <Box>
          <Typography sx={{ fontSize: "15px", fontWeight: 500 }}>
            {t("change_password_form.tips.headline")}
          </Typography>

          <ul
            style={{
              padding: "0px 25px",
              fontSize: "15px",
              display: "flex",
              flexDirection: "column",
              gap: "6px",
            }}
          >
            <li> {t("change_password_form.tips.uppercase")}</li>
            <li>{t("change_password_form.tips.lowercase")}</li>
            <li> {t("change_password_form.tips.numbers")}</li>
            <li>{t("change_password_form.tips.special_characters")}</li>
          </ul>
        </Box>
      </Box>
      <ButtonGroup
        onClickClose={onClickClose}
        saveLabel={t("modules.buttons.save")}
      />
    </form>
  );
};

export default ChangePasswordForm;
