import { Chip, Stack, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import FormsDataGrid from "../../../DataGrid/FormsDataGrid";

const columns = [
  {
    field: "SN",
    headerName: "SN",
  },
  {
    field: "Description",
    headerName: "Description",
    flex: 1,
  },
  {
    field: "Unit",
    headerName: "Unit",
  },
  {
    field: "Type",
    headerName: "Type",
  },
  {
    field: "Value",
    headerName: "Value",
  },
];

const Save = ({ rows, values }) => {
  const [t] = useTranslation("global");

  const { PriceListRefNo, PriceListRefYear, CompanyAccountName } = values;

  return (
    <Stack alignItems={"stretch"} spacing={3} py={5}>
      <Stack direction={"row"} spacing={20}>
        <Stack direction={"row"} spacing={1}>
          <Typography variant={"body1"}>
            {t("modules.prices_list.new_form.fields.price_list_name")}:
          </Typography>
          <Chip label={PriceListRefNo} />
        </Stack>
        <Stack direction={"row"} spacing={1}>
          <Typography variant={"body1"}>
            {t("modules.prices_list.new_form.fields.price_list_year")}:
          </Typography>
          <Chip label={PriceListRefYear} />
        </Stack>
        <Stack direction={"row"} spacing={1}>
          <Typography variant={"body1"}>
            {t("modules.prices_list.new_form.fields.account_name")}:
          </Typography>
          <Chip label={CompanyAccountName} />
        </Stack>
      </Stack>
      <FormsDataGrid
        rows={rows}
        columns={columns}
        getRowId={(row) => row.SN}
        height={300}
      />
    </Stack>
  );
};

export default Save;
