import { useTheme } from "@mui/material/styles";
import OutlinedInput from "@mui/material/OutlinedInput";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import { Controller } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { FormHelperText } from "@mui/material";
import { useState } from "react";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

function getStyles(name, personName, theme) {
  return {
    fontWeight:
      personName.indexOf(name) === -1
        ? theme.typography.fontWeightRegular
        : theme.typography.fontWeightMedium,
  };
}

export default function MultipleSelectChip({
  stores,
  control,
  errors,
  values,
}) {
  const [t] = useTranslation("global");

  let options = stores.map((item) => item.CompanyStoresName);

  options = [
    ...options,
    t("modules.users_management.new_form.labels.no_store"),
  ];

  const theme = useTheme();
  const [storeName, setStoreName] = useState(
    values.map((item) => item.CompanyStoresName)
  );

  return (
    <div>
      <FormControl
        variant="outlined"
        sx={{ width: "100%" }}
        error={errors.stores}
        required
      >
        <InputLabel id="demo-multiple-name-label" size="small">
          {t("modules.users_management.new_form.labels.stores")}
        </InputLabel>
        <Controller
          control={control}
          name={`stores`}
          render={({ field: { onChange } }) => (
            <Select
              labelId="demo-multiple-chip-label"
              size="small"
              options={options}
              onChange={(event) => {
                const {
                  target: { value },
                } = event;

                setStoreName(
                  // On autofill we get a stringified value.
                  typeof value === "string" ? value.split(",") : value
                );

                const doesValueEqualNoStore = value.some(
                  (item) =>
                    stores.find((store) => store.CompanyStoresName === item) ===
                    undefined
                );

                const values = doesValueEqualNoStore
                  ? [
                      {
                        id: -1,
                      },
                    ]
                  : value.map((item) => ({
                      id: stores.find(
                        (store) => store.CompanyStoresName === item
                      ).idCompanyStores,
                      CompanyStoresName: item,
                    }));

                onChange(values);
              }}
              value={storeName}
              multiple
              input={
                <OutlinedInput
                  error={!!errors.stores}
                  label={t("modules.users_management.new_form.labels.stores")}
                />
              }
              MenuProps={MenuProps}
            >
              {options.map((name) => (
                <MenuItem
                  key={name}
                  value={name}
                  style={getStyles(name, storeName, theme)}
                  disabled={
                    (storeName.length === 1 &&
                      storeName.includes(
                        t("modules.users_management.new_form.labels.no_store")
                      ) &&
                      name !==
                        t(
                          "modules.users_management.new_form.labels.no_store"
                        )) ||
                    (name ===
                      t("modules.users_management.new_form.labels.no_store") &&
                      storeName.length > 0 &&
                      !storeName.includes(
                        t("modules.users_management.new_form.labels.no_store")
                      ))
                  }
                >
                  {name}
                </MenuItem>
              ))}
            </Select>
          )}
        />
        {errors.stores && (
          <FormHelperText>{errors.stores.message}</FormHelperText>
        )}
      </FormControl>
    </div>
  );
}
