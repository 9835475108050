import { Box, Stack, Typography, styled } from "@mui/material";
import { useTranslation } from "react-i18next";

export const CardHeader = styled(Typography)(() => ({
  fontSize: 14,
  userSelect: "none",
  fontWeight: 600,
}));

export const NoVacationData = () => {
  const [t] = useTranslation("global");

  return (
    <Stack spacing={2} justifyContent="center" alignItems="center">
      <Box
        sx={{
          width: "100px",
          aspectRatio: "1/1",
          pointerEvents: "none",
          userSelect: "none",
        }}
      >
        <img
          src="/island.svg"
          alt="island"
          style={{
            width: "100%",
            height: "100%",
            objectFit: "contain",
            filter: "grayscale(100%)",
          }}
        />
      </Box>
      <Typography>
        {t("info_modules.employee_records.cards.vacations.labels.no_vacations")}
      </Typography>
    </Stack>
  );
};
