import { Box, Chip } from "@mui/material";

const ChipComponent = ({ label, value, extraStyles }) => {
  return (
    <Box display="flex" gap="10px" sx={extraStyles}>
      <Chip
        size="small"
        variant="outlined"
        label={label}
        style={{
          borderWidth: 2,
          width: "8.5rem",
          borderRadius: 5,
          fontWeight: 500,
          flexShrink: 0,
          flexBasis: "10.5rem",
        }}
      />
      <Box sx={{ fontSize: 13, fontWeight: 500 }}>{value}</Box>
    </Box>
  );
};
export default ChipComponent;
