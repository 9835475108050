import { useState, useEffect } from "react";
import { Box, Checkbox } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { useQuery, useMutation, useQueryClient } from "@tanstack/react-query";
import Axios from "../../../../network/Axios";
import InfoModal from "../../../../components/Modals/InfoModal";
import FormsDataGrid from "../../../../components/DataGrid/FormsDataGrid";
import ButtonGroup from "../../../../components/forms/ButtonGroup/ButtonGroup";
import ConfirmationInfoModal from "../../../../components/Modals/ConfirmationInfoModal";
import { useSnackbar } from "notistack";

const AddMail = ({ open, handleClose }) => {
  const { userId } = useParams();
  const [t] = useTranslation("global");
  const queryClient = useQueryClient();
  const { enqueueSnackbar } = useSnackbar();
  const [selectedRows, setSelectedRows] = useState([]);
  const [selectedData, setSelectedData] = useState([]);
  const [confirmModal, setConfirmModal] = useState(false);
  const [rowData, setRowData] = useState([]);

  const handleOpenConfirmModal = () => {
    setConfirmModal(true);
  };

  const { data, isLoading } = useQuery({
    queryKey: ["mail", "add"],
    queryFn: async () => {
      const response = await Axios.get("/UsersManagement", {
        params: {
          SelectedNo: userId,
          State: "MailServices",
        },
      });
      return response.data.MailServices;
    },
    enabled: open,
  });
  const { mutateAsync: addMail, isPending: add } = useMutation({
    mutationFn: async () => {
      const mails = selectedData.map((mail) => ({
        BCC: mail.BCC,
        CC: mail.CC,
        MailServicesName: mail.MailServicesName,
        TO: mail.TO,
        id: mail.idMailServices,
      }));

      return Axios.patch("/UsersManagement", {
        State: "UpdateMailServicesList",
        MailServices: mails,
        SelectedNo: userId,
      });
    },
    onSuccess: (data) => {
      if (!data) return;
      enqueueSnackbar(t("Mail Services Name Updated Successfully"), {
        variant: "success",
      });
      handleCloseCheck();
      setConfirmModal(false);
      queryClient.invalidateQueries({
        queryKey: ["usersManagement", "info"],
      });
    },
  });
  useEffect(() => {
    if (data) {
      setRowData(
        data.map((row) => ({
          ...row,
          TO: Boolean(row.TO),
          BCC: Boolean(row.BCC),
          CC: Boolean(row.CC),
        }))
      );
    }
  }, [data]);

  const handleRowSelectionModelChange = (newSelectedRows) => {
    const selectedRowData = rowData.filter((row) =>
      newSelectedRows.includes(row.idMailServices)
    );
    setSelectedRows(newSelectedRows);
    setSelectedData(selectedRowData);
  };

  const handleCloseCheck = () => {
    handleClose();
    setSelectedRows([]);
    setRowData(data.map((row) => ({ ...row })));
  };
  const handleCheckboxChange = (event, id, field) => {
    event.stopPropagation(); // Prevent the default behavior of the checkbox
    // Find the index of the row with the given id
    const index = rowData.findIndex((row) => row.idMailServices === id);
    if (index !== -1) {
      // Copy the rowData array
      const newData = [...rowData];
      // Set the clicked field to true and all other fields to false
      newData[index].TO = field === "TO";
      newData[index].CC = field === "CC";
      newData[index].BCC = field === "BCC";
      // Update the rowData state
      setRowData(newData);
    }
  };

  const columns = [
    {
      field: "MailServicesName",
      headerName: t("data_grid.headers.mail_services_names"),
      width: 400,
    },
    {
      field: "TO",
      headerName: "TO",
      editable: true,
      type: "boolean",
      width: 100,
      renderCell: (params) => (
        <Checkbox
          checked={params.row.TO || false}
          onClick={(event) =>
            handleCheckboxChange(event, params.row.idMailServices, "TO")
          }
        />
      ),
    },
    {
      field: "CC",
      headerName: "CC",
      editable: true,
      type: "boolean",
      width: 100,
      renderCell: (params) => (
        <Checkbox
          checked={params.row.CC || false}
          onClick={(event) =>
            handleCheckboxChange(event, params.row.idMailServices, "CC")
          }
        />
      ),
    },
    {
      field: "BCC",
      headerName: "BCC",
      editable: true,
      type: "boolean",
      width: 100,
      renderCell: (params) => (
        <Checkbox
          checked={params.row.BCC || false}
          onClick={(event) =>
            handleCheckboxChange(event, params.row.idMailServices, "BCC")
          }
        />
      ),
    },
  ];

  return (
    <>
      <InfoModal
        open={open}
        handleClose={handleCloseCheck}
        title={t("info_modules.users_management.description.add_mail")}
        content={
          <Box padding="20px 10px">
            <FormsDataGrid
              rows={rowData}
              columns={columns}
              loading={isLoading}
              getRowId={(row) => row.idMailServices}
              height={380}
              checkboxSelection
              disableSelectionOnClick
              onRowSelectionModelChange={handleRowSelectionModelChange}
              rowSelectionModel={selectedRows}
            />
            <ButtonGroup
              onClickClose={handleCloseCheck}
              onClickSave={handleOpenConfirmModal}
              saveLabel={t("modules.buttons.select")}
            />
          </Box>
        }
      />
      <ConfirmationInfoModal
        open={confirmModal}
        onClick={addMail}
        isPending={add}
        handleClose={() => {
          setConfirmModal(false);
        }}
        description={t("info_modules.users_management.confirmation.add_mail")}
        color="error"
      />
    </>
  );
};

export default AddMail;
