import { useDispatch } from "react-redux";
import { setNewForm, setSideDrawerState } from "../store/slices/themeSlice";

const useOpenNewForm = () => {
  const dispatch = useDispatch();

  const handleOpen = (formName) => {
    dispatch(setNewForm(formName));
    dispatch(setSideDrawerState());
  };
  return { handleOpen };
};

export default useOpenNewForm;
