import { CardHeader, NoVacationData } from "../../components/common";
import ReactApexChart from "react-apexcharts";
import { useState } from "react";
import ArrowCircleLeftIcon from "@mui/icons-material/ArrowCircleLeft";
import ArrowCircleRightIcon from "@mui/icons-material/ArrowCircleRight";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import InfoIcon from "@mui/icons-material/Info";
import { IconButton, Tooltip, Typography, Stack, Box } from "@mui/material";
import InfoModal from "../../../../../components/Modals/InfoModal";
import NewVacationRequest from "./NewEditForm/NewVacationRequest";
import VacationsList from "./VacationsList";
import dayjs from "dayjs";
import { useSelector } from "react-redux";
import { grey } from "@mui/material/colors";
import { useTranslation } from "react-i18next";

const VacationChart = ({ data, empName }) => {
  const [t] = useTranslation("global");

  const { colorTheme: theme, language } = useSelector((state) => state.theme); // light | dark

  // Function to transform the data
  function transformData(data) {
    const transformedData = {};

    data.forEach((item) => {
      const year = item.Year.toString();
      if (!transformedData[year]) {
        transformedData[year] = {
          vacationTypes: [],
          vacationTypesAR: [],
          total: [],
        };
      }
      transformedData[year].vacationTypes.push(item.VacationsTypesName);
      transformedData[year].vacationTypesAR.push(item.VacationsTypesNameArabic);
      transformedData[year].total.push(item.Total);
    });

    return transformedData;
  }

  const transformedData = transformData(data);

  const [currentYear, setCurrentYear] = useState(
    Object.keys(transformedData).at(-1) || dayjs().year()
  );

  const incrementYear = () => {
    const years = Object.keys(transformedData);
    const currentIndex = years.indexOf(currentYear);
    if (currentIndex < years.length - 1) {
      setCurrentYear(years[currentIndex + 1]);
    }
  };

  const decrementYear = () => {
    const years = Object.keys(transformedData);
    const currentIndex = years.indexOf(currentYear);
    if (currentIndex > 0) {
      setCurrentYear(years[currentIndex - 1]);
    }
  };

  const vacationTypes = transformedData[currentYear]?.vacationTypes || [];
  const vacationTypesAR = transformedData[currentYear]?.vacationTypesAR || [];
  const series = transformedData[currentYear]?.total || [];
  const options = {
    labels: language === "AR" ? vacationTypesAR : vacationTypes,
    stroke: {
      show: false,
    },
    legend: {
      position: "bottom",
      labels: {
        colors: theme === "dark" ? grey[300] : grey[700],
      },
    },
    dataLabels: {
      enabled: false,
    },
  };

  // Handling the new vacation modal
  const [isModalOpen, setIsModalOpen] = useState(false);

  const handleOpenModal = () => setIsModalOpen(true);
  const handleCloseModal = () => setIsModalOpen(false);

  // Handling vacations details modal
  const [isDetailsModalOpen, setIsDetailsModalOpen] = useState(false);

  const handleOpenDetailsModal = () => setIsDetailsModalOpen(true);
  const handleCloseDetailsModal = () => setIsDetailsModalOpen(false);

  return (
    <>
      <Stack
        spacing={3}
        sx={{
          backgroundColor: "background.default",
          padding: "1rem",
          borderRadius: 2,
          border: "1px solid",
          borderColor: "secondary.main",
        }}
      >
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
        >
          <Stack direction="row" alignItems="center" spacing={1}>
            <CardHeader>
              {t("info_modules.employee_records.cards.vacations.title")}
            </CardHeader>
            <Stack direction="row" alignItems="center">
              <Tooltip
                title={t("info_modules.employee_records.tooltips.add_vacation")}
                placement="top"
              >
                <IconButton
                  size="small"
                  aria-label="add vacation"
                  onClick={handleOpenModal}
                >
                  <AddCircleIcon color="success" />
                </IconButton>
              </Tooltip>

              {data.length > 0 && (
                <Tooltip
                  title={t(
                    "info_modules.employee_records.tooltips.view_details"
                  )}
                  placement="top"
                >
                  <IconButton
                    size="small"
                    aria-label="details"
                    onClick={handleOpenDetailsModal}
                  >
                    <InfoIcon color="info" />
                  </IconButton>
                </Tooltip>
              )}
            </Stack>
          </Stack>

          {data.length > 0 && (
            <Stack
              direction="row"
              alignItems="center"
              alignSelf="flex-end"
              component="div"
              dir="ltr"
            >
              <IconButton
                size="small"
                aria-label="arrow left"
                onClick={decrementYear}
                disabled={Object.keys(transformedData)[0] === currentYear}
              >
                <ArrowCircleLeftIcon />
              </IconButton>

              <Typography
                sx={{
                  fontSize: 14,
                  userSelect: "none",
                }}
              >
                {currentYear}
              </Typography>

              <IconButton
                size="small"
                aria-label="arrow right"
                onClick={incrementYear}
                disabled={
                  Object.keys(transformedData)[
                    Object.keys(transformedData).length - 1
                  ] === currentYear
                }
              >
                <ArrowCircleRightIcon />
              </IconButton>
            </Stack>
          )}
        </Stack>

        {data.length === 0 ? (
          <Box
            sx={{
              display: "grid",
              placeItems: "center",
              flexGrow: 1,
            }}
          >
            <NoVacationData />
          </Box>
        ) : (
          <Box component="div" dir="ltr">
            <ReactApexChart options={options} series={series} type="pie" />
          </Box>
        )}
      </Stack>
      <InfoModal
        open={isModalOpen}
        title={t(
          "info_modules.employee_records.cards.vacations.new_form.title"
        )}
        content={
          <NewVacationRequest
            handleClose={handleCloseModal}
            empName={empName}
          />
        }
        handleClose={handleCloseModal}
        width="30rem"
      />
      <InfoModal
        open={isDetailsModalOpen}
        title={`${t(
          "info_modules.employee_records.cards.vacations.vacations_list.title"
        )} - ${currentYear}`}
        content={<VacationsList year={currentYear} empName={empName} />}
        handleClose={handleCloseDetailsModal}
        width="40rem"
      />
    </>
  );
};

export default VacationChart;
