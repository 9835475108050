import { useEffect } from "react";
import FormsDataGrid from "../../../../../../components/DataGrid/FormsDataGrid";
import { Box } from "@mui/material";
import { useTranslation } from "react-i18next";

const Select = ({ rows, onRowSelection, selectedRows, setSelectedRows }) => {
  useEffect(() => {
    onRowSelection(selectedRows);
  }, [selectedRows, onRowSelection]);

  const [t] = useTranslation("global");

  const columns = [
    {
      field: "EmpCode",
      headerName: t("data_grid.headers.emp_code"),
      width: 200,
    },
    {
      field: "EmpName",
      headerName: t("data_grid.headers.name"),
      width: 210,
      flex: 1,
    },
    {
      field: "EmpTitle",
      headerName: t("data_grid.headers.title"),
      width: 200,
    },
  ];

  const handleRowSelectionModelChange = (newSelectedRows) => {
    if (newSelectedRows.length > 0) {
      setSelectedRows([newSelectedRows[newSelectedRows.length - 1]]);
    } else {
      setSelectedRows([]);
    }
  };

  return (
    <Box sx={{ margin: "12px 1px" }}>
      <FormsDataGrid
        rows={rows}
        columns={columns}
        getRowId={(row) => row.idEmpInfo}
        height={380}
        showQuickFilter
        checkboxSelection
        toolbar
        disableSelectionOnClick
        onRowSelectionModelChange={handleRowSelectionModelChange}
        rowSelectionModel={selectedRows}
      />
    </Box>
  );
};
export default Select;
