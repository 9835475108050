import * as yup from "yup";
import { useState, useEffect } from "react";
import { useFormik } from "formik";
import { useSnackbar } from "notistack";
import Axios from "../../network/Axios";
import { useTranslation } from "react-i18next";
import {
  Autocomplete,
  Box,
  TextField,
  Typography,
  InputAdornment,
} from "@mui/material";
import InfoModal from "../../components/Modals/InfoModal";
import AntSwitch from "../../components/Switches/AntSwitch";
import FormSpinner from "../../components/Spinners/FormSpinner";
import ButtonGroup from "../../components/forms/ButtonGroup/ButtonGroup";
import { useMutation, useQueryClient, useQuery } from "@tanstack/react-query";
import CloudQueueIcon from "@mui/icons-material/CloudQueue";
import { useSelector } from "react-redux";

const SettingsModal = ({ open, handleClose }) => {
  const [t] = useTranslation("global");
  const queryClient = useQueryClient();
  const { enqueueSnackbar } = useSnackbar();
  const theme = useSelector((state) => state.theme.colorTheme);

  // Fetch settings data
  const { data: settings, isLoading } = useQuery({
    queryKey: ["nextCloudSettings"],
    queryFn: async () => {
      const response = await Axios.get("/CloudStorageNew", {
        params: {
          action: "Settings",
        },
      });
      return response.data;
    },
    enabled: open,
  });

  // Extract NextCloud, AmazonS3, and CloudService parameters
  const nextCloudParams =
    settings?.NextCloud?.reduce((acc, item) => {
      acc[item.NextCloudServiceParameterName] = item.NextCloudServiceValue;
      return acc;
    }, {}) || {};

  const amazonS3Params =
    settings?.AmazonS3?.reduce((acc, item) => {
      acc[item.S3StorageParameterName] = item.S3StorageValue;
      return acc;
    }, {}) || {};

  const generalParams =
    settings?.CloudService?.reduce((acc, item) => {
      acc[item.CloudStorageParameterName] = item.CloudStorageValue;
      return acc;
    }, {}) || {};

  // Initialize the state for shared status and src fields
  const [status, setStatus] = useState(generalParams["Active"] === "True");
  const [src, setSrc] = useState(
    generalParams["DeleteFromDatabase"] === "True"
  );
  const [changeService, setChangeService] = useState("NextCloud");

  useEffect(() => {
    if (settings) {
      setStatus(generalParams["Active"] === "True");
      setSrc(generalParams["DeleteFromDatabase"] === "True");
      setChangeService(
        generalParams["CloudService"] === "NextCloud" ? "NextCloud" : "AmazonS3"
      );
    }
  }, [settings, open]);

  const initialState = {
    url:
      changeService === "NextCloud"
        ? nextCloudParams["NextCloudLink"] || ""
        : amazonS3Params["BucketName"] || "",
    user:
      changeService === "NextCloud"
        ? nextCloudParams["NextCloudUser"] || ""
        : amazonS3Params["AWSRegion"] || "",
    password:
      changeService === "NextCloud"
        ? nextCloudParams["NextCloudPass"] || ""
        : amazonS3Params["AccessKeyID"] || "",
    homeDirectory:
      changeService === "NextCloud"
        ? nextCloudParams["NextCloudDirectory"] || ""
        : amazonS3Params["SecretAccessKey"] || "",
    status: status,
    src: src,
  };

  const schema = yup.object().shape({
    url: yup.string().required(t("modules.global_schema.required")),
    user: yup.string().required(t("modules.global_schema.required")),
    password: yup.string().required(t("modules.global_schema.required")),
    homeDirectory: yup.string().required(t("modules.global_schema.required")),
    status: yup.boolean(),
    src: yup.boolean(),
  });

  const {
    errors,
    handleChange,
    values,
    setFieldValue,
    handleSubmit,
    resetForm,
    touched,
    handleBlur,
  } = useFormik({
    initialValues: initialState,
    validationSchema: schema,
    enableReinitialize: true,
    onSubmit: () => {
      if (Object.keys(errors).length !== 0) {
        return;
      } else {
        changeSetting();
      }
    },
    validateOnChange: true,
  });

  const handleCloseSave = () => {
    resetForm();
    handleClose();
  };

  const { mutateAsync: changeSetting, isPending } = useMutation({
    mutationFn: async () => {
      const payload =
        changeService === "NextCloud"
          ? {
              CloudService: "NextCloud",
              NextCloudLink: values.url,
              NextCloudUser: values.user,
              NextCloudPass: values.password,
              NextCloudDirectory: values.homeDirectory,
              Active: values.status === true ? "True" : "False",
              DeleteFromDatabase: values.src === true ? "True" : "False",
            }
          : {
              CloudService: "AmazonS3",
              BucketName: values.url,
              AWSRegion: values.user,
              AccessKeyID: values.password,
              SecretAccessKey: values.homeDirectory,
              Active: values.status === true ? "True" : "False",
              DeleteFromDatabase: values.src === true ? "True" : "False",
            };

      return Axios.put("/CloudStorageNew", { ...payload, action: "Settings" });
    },
    onSuccess: (data) => {
      if (!data) return;
      enqueueSnackbar("Settings Changed Successfully", {
        variant: "success",
      });
      queryClient.invalidateQueries({
        queryKey: ["filesAndFolders"],
      });
      queryClient.invalidateQueries({
        queryKey: ["nextCloudSettings"],
      });

      // Update shared status and src values
      setStatus(values.status);
      setSrc(values.src);

      handleCloseSave();
    },
  });

  return (
    <InfoModal
      open={open}
      handleClose={handleCloseSave}
      title={t("info_modules.next_cloud.cloud_settings")}
      width="33rem"
      content={
        isLoading ? (
          <FormSpinner />
        ) : (
          <form onSubmit={handleSubmit}>
            <Box margin="10px" display="flex" flexDirection="column" gap="10px">
              <Autocomplete
                sx={{ display: "flex", alignItems: "center" }}
                options={["NextCloud", "AmazonS3"]}
                onChange={(e, value) => setChangeService(value)}
                value={changeService}
                disableClearable
                renderOption={(props, option) => (
                  <Box
                    component="li"
                    sx={{ display: "flex", alignItems: "center", gap: 1 }}
                    {...props}
                  >
                    {option === "AmazonS3" ? (
                      <CloudQueueIcon fontSize="small" />
                    ) : (
                      <img
                        src={
                          theme === "dark"
                            ? "/NextCloudIconDark.png"
                            : "/NextCloudIconLight.png"
                        }
                        width="20px"
                        height="20px"
                        alt="NextCloudIcon"
                      />
                    )}
                    {option}
                  </Box>
                )}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label={t("info_modules.next_cloud.service")}
                    variant="outlined"
                    size="small"
                    disabled
                    sx={{
                      "& .MuiSvgIcon-root": {
                        marginLeft: "10px",
                      },
                    }}
                    InputProps={{
                      ...params.InputProps,
                      startAdornment: (
                        <InputAdornment position="start">
                          {changeService === "AmazonS3" ? (
                            <CloudQueueIcon fontSize="16px" />
                          ) : (
                            <img
                              src={
                                theme === "dark"
                                  ? "/NextCloudIconDark.png"
                                  : "/NextCloudIconLight.png"
                              }
                              width="20px"
                              height="20px"
                              style={{ marginLeft: "10px" }}
                              alt="NextCloudIcon"
                            />
                          )}
                        </InputAdornment>
                      ),
                    }}
                  />
                )}
              />

              <TextField
                label={
                  changeService === "AmazonS3"
                    ? t("info_modules.next_cloud.bucket_name")
                    : t("info_modules.next_cloud.url")
                }
                variant="outlined"
                size="small"
                value={values.url}
                onChange={handleChange("url")}
                onBlur={handleBlur}
                error={!!touched.url && !!errors.url}
                helperText={touched.url ? errors.url : ""}
              />

              <TextField
                label={
                  changeService === "AmazonS3"
                    ? t("info_modules.next_cloud.aws_region")
                    : t("info_modules.next_cloud.user")
                }
                variant="outlined"
                size="small"
                value={values.user}
                onChange={handleChange("user")}
                onBlur={handleBlur}
                error={!!touched.user && !!errors.user}
                helperText={touched.user ? errors.user : ""}
              />

              <TextField
                label={
                  changeService === "AmazonS3"
                    ? t("info_modules.next_cloud.access_key_id")
                    : t("info_modules.next_cloud.password")
                }
                variant="outlined"
                size="small"
                value={values.password}
                onChange={handleChange("password")}
                onBlur={handleBlur}
                error={!!touched.password && !!errors.password}
                helperText={touched.password ? errors.password : ""}
              />

              <TextField
                label={
                  changeService === "AmazonS3"
                    ? t("info_modules.next_cloud.secret_access_key")
                    : t("info_modules.next_cloud.home_directory")
                }
                variant="outlined"
                size="small"
                value={values.homeDirectory}
                onChange={handleChange("homeDirectory")}
                onBlur={handleBlur}
                error={!!touched.homeDirectory && !!errors.homeDirectory}
                helperText={touched.homeDirectory ? errors.homeDirectory : ""}
              />

              <Box display="flex" gap={7}>
                <Box
                  sx={{
                    display: "flex",
                    gap: "10px",
                    alignItems: "center",
                  }}
                >
                  <Typography
                    sx={{
                      fontSize: "16px",
                      fontWeight: 600,
                      alignSelf: "center",
                    }}
                  >
                    {t("info_modules.next_cloud.status")}
                  </Typography>
                  <AntSwitch
                    checked={values.status}
                    onChange={(e) => {
                      setFieldValue("status", e.target.checked);
                      setStatus(e.target.checked); // Sync with global state
                    }}
                  />
                  <Typography
                    color={values.status ? "green" : "red"}
                    sx={{
                      fontSize: "16px",
                      fontWeight: 600,
                    }}
                  >
                    {values.status ? "Active" : "Inactive"}
                  </Typography>
                </Box>
                <Box display="flex" gap="10px" alignItems="center">
                  <AntSwitch
                    checked={values.src}
                    onChange={(e) => {
                      setFieldValue("src", e.target.checked);
                      setSrc(e.target.checked); // Sync with global state
                    }}
                  />
                  <Typography
                    sx={{
                      fontSize: "16px",
                      fontWeight: 600,
                      alignSelf: "center",
                    }}
                  >
                    Delete from Database
                  </Typography>
                </Box>
              </Box>
            </Box>
            <ButtonGroup
              onClickClose={handleCloseSave}
              isPending={isPending}
              onClickSave={handleSubmit}
              saveLabel={t("modules.buttons.save")}
            />
          </form>
        )
      }
    />
  );
};

export default SettingsModal;
