import { useEffect, useState } from "react";
import FormsDataGrid from "../../../DataGrid/FormsDataGrid";
import ChipStatus from "../../../ChipStatus";
import { Box, TextField } from "@mui/material";
import { useTranslation } from "react-i18next";

const SelectWorkOrder = ({
  rows,
  onRowSelection,
  selectedRows,
  setSelectedRows,
}) => {
  useEffect(() => {
    onRowSelection(selectedRows);
  }, [selectedRows, onRowSelection]);
  const [t] = useTranslation("global");
  const [workOrderDescription, setWorkOrderDescription] = useState("");

  const handleOnCellClick = (params) => {
    setWorkOrderDescription(params.row.WorkOrderDiscription);
  };
  const columns = [
    {
      field: "WorkOrderNo",
      headerName: t("data_grid.headers.ref_no"),
      width: 120,
    },
    {
      field: "SiteName",
      headerName: t("data_grid.headers.site_name"),
      width: 120,
      flex: 1,
    },
    {
      field: "SiteCode",
      headerName: t("data_grid.headers.site_code"),
      width: 120,
      flex: 1,
    },
    {
      field: "WorkOrderDate",
      headerName: t("data_grid.headers.date"),
    },
    {
      field: "ERPUserNickName",
      headerName: t("data_grid.headers.created_by"),
      width: 160,
    },
    {
      field: "WorkOrderStatus",
      headerName: t("data_grid.headers.status"),
      renderCell: ({ value }) => {
        return <ChipStatus value={value} />;
      },
    },
  ];

  return (
    <Box sx={{ margin: "12px 1px" }}>
      <FormsDataGrid
        rows={rows}
        columns={columns}
        getRowId={(row) => row.idWorkOrder}
        height={320}
        showQuickFilter
        checkboxSelection
        toolbar
        disableSelectionOnClick
        onRowSelectionModelChange={(newSelectedRows) => {
          setSelectedRows(newSelectedRows);
        }}
        rowSelectionModel={selectedRows}
        onCellClick={handleOnCellClick}
      />
      <TextField
        label={t("modules.material_request.label.description")}
        sx={{ width: "100%", marginTop: "10px" }}
        multiline
        rows={2}
        value={workOrderDescription}
      />
    </Box>
  );
};
export default SelectWorkOrder;
