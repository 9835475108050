import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { getFinalArray, sortDataGroups } from "./utils";
import { useState } from "react";
import { useSnackbar } from "notistack";
import FormSpinner from "../../../components/Spinners/FormSpinner";
import { ERPSystemRolesService } from "../../../api/ERPSystemRoles";
import { DataGroupsPrivilegesTree } from "./common";

const NewDataGroup = ({ onClose }) => {
  const { data, isLoading, isRefetching } = useQuery({
    queryKey: ["newDataGroupList"],
    queryFn: ERPSystemRolesService.getNewDataGroupList,
  });

  if (isLoading || isRefetching) return <FormSpinner />;

  return <NewDataGroupData data={data.SubProjects} onClose={onClose} />;
};

const NewDataGroupData = ({ data, onClose }) => {
  const { enqueueSnackbar } = useSnackbar();
  const queryClient = useQueryClient();

  // Handle data group state
  const [currentDataGroupName, setCurrentDataGroupName] = useState("");

  // Handle errors
  const [error, setError] = useState({
    error: false,
    message: "",
  });

  // Handle is touched
  const [isTouched, setIsTouched] = useState(false);

  const handleDataGroupNameChange = (event) => {
    setIsTouched(true);
    setCurrentDataGroupName(event.target.value);
  };

  const [selectedItems, setSelectedItems] = useState([]);

  const handleSelectedItemsChange = (event, ids) => {
    setSelectedItems(ids);
  };

  const sortedDataGroups = sortDataGroups(data);

  const handleSubmit = () => {
    if (!currentDataGroupName) {
      setIsTouched(true);
      setError({ error: true, message: "Data group name is required" });
      return;
    }

    const outputArray = getFinalArray(selectedItems, data); // Get the final array to be sent to the API

    addNewDataGroup({
      GroupsName: currentDataGroupName,
      DataGroups: outputArray,
    });
  };

  const { mutateAsync: addNewDataGroup, isPending } = useMutation({
    mutationFn: ERPSystemRolesService.addNewDataGroup,
    onSuccess: (data) => {
      onClose();
      enqueueSnackbar(data.msg, { variant: "success" });
      queryClient.invalidateQueries({ queryKey: ["dataGroups"] });
    },
  });

  return (
    <DataGroupsPrivilegesTree
      currentDataGroupName={currentDataGroupName}
      handleDataGroupNameChange={handleDataGroupNameChange}
      selectedItems={selectedItems}
      handleSelectedItemsChange={handleSelectedItemsChange}
      sortedDataGroups={sortedDataGroups}
      data={data}
      isNewUserRole
      isPending={isPending}
      handleSubmit={handleSubmit}
      onClose={onClose}
      error={error}
      isTouched={isTouched}
      isNewDataGroup
    />
  );
};

export default NewDataGroup;
