import i18n from "i18next";
import global_en from "../translations/en/globals.json";
import global_ar from "../translations/ar/globals.json";

i18n.init({
  fallbackLng: "en",
  interpolation: {
    escapeValue: true,
  },
  resources: {
    en: {
      global: global_en,
    },
    ar: {
      global: global_ar,
    },
  },
});

export default i18n;
