import { Box, Button, Typography } from "@mui/material";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import { Controller } from "react-hook-form";
import VisuallyHiddenInput from "../../../../components/VisuallyHiddenInput";

const ImagePickerBox = ({ error, image, handleImage, control, name }) => {
  return (
    <Box
      sx={{
        display: "grid",
        placeItems: "center",
        flexGrow: 1,
      }}
    >
      {(!image || error) && (
        <Box
          sx={{
            py: 5,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "column",
            gap: "10px",
            height: "100%",
          }}
        >
          <Button
            component="label"
            role={undefined}
            variant="contained"
            sx={{
              borderRadius: "50%",
              width: "25px",
              aspectRatio: "1",
              "& span": {
                m: 0,
              },
            }}
            startIcon={<CloudUploadIcon />}
          >
            <Controller
              name={name}
              control={control}
              render={({ field }) => {
                return (
                  <VisuallyHiddenInput
                    type="file"
                    accept="image/*"
                    onChange={(e) => {
                      field.onChange(e.target.files[0]);
                      handleImage(e);
                    }}
                  />
                );
              }}
            />
          </Button>
          {error && (
            <Typography
              color="red"
              sx={{
                backgroundColor: "#ffdfdf",
                p: 1,
                borderRadius: "5px",
                fontSize: "14px",
              }}
            >
              {error.message}
            </Typography>
          )}
        </Box>
      )}

      {!error && image && (
        <Box
          sx={{
            flexGrow: 1,
            position: "relative",
            overflow: "hidden",
            "&:hover": {
              "& > label": {
                opacity: 1,
              },
              "& > img": {
                filter: "brightness(0.5)",
              },
            },
          }}
        >
          <img
            src={image}
            alt="Selected"
            style={{
              width: "100%",
              height: "100%",
              objectFit: "cover",
              maxHeight: "10rem",
              transition: "filter 0.3s",
            }}
          />
          <Button
            component="label"
            role={undefined}
            variant="contained"
            sx={{
              opacity: 0,
              position: "absolute",
              left: "50%",
              top: "50%",
              transform: "translate(-50%, -50%)",
              borderRadius: "50%",
              width: "25px",
              aspectRatio: "1",
              "& span": {
                m: 0,
              },
            }}
            startIcon={<CloudUploadIcon />}
          >
            <Controller
              name={name}
              control={control}
              render={({ field }) => {
                return (
                  <VisuallyHiddenInput
                    type="file"
                    accept="image/*"
                    onChange={(e) => {
                      field.onChange(e.target.files[0]);
                      handleImage(e);
                    }}
                  />
                );
              }}
            />
          </Button>
        </Box>
      )}
    </Box>
  );
};

export default ImagePickerBox;
