import StatementsInSignaturePhase from "./Charts/StatementsInSignaturePhase";
import { useTranslation } from "react-i18next";
import DashboardAccordion from "../../DashboardAccordion";
import InvoicesProgress from "./Charts/InvoicesProgress";
import AcceptedWorkNeedInvoicing from "./Charts/AcceptedWorkNeedInvoicing";
import { renderIfDataExists } from "../common";
import OpenNAPOs from "./Charts/OpenNAPOs";
import AllPOs from "./Charts/AllPOs";

const FinancialSection = ({ data }) => {
  const { t } = useTranslation("global");
  const {
    invoicesSignatureAmount,
    invoicesSignatureCount,
    allPO,
    invoicesPerAmount,
    invoicesPerCount,
    acceptedWorkNotInvoicedCountPerProject,
    acceptedWorkNotInvoicedCountPerUser,
    openNAPOs,
  } = data;

  return (
    <DashboardAccordion title={t("main_cards.titles.financial")}>
      {renderIfDataExists(StatementsInSignaturePhase, {
        invoicesSignatureAmount,
        invoicesSignatureCount,
      })}
      {renderIfDataExists(InvoicesProgress, {
        invoicesPerAmount,
        invoicesPerCount,
      })}
      {renderIfDataExists(AllPOs, {
        allPO,
      })}
      {renderIfDataExists(OpenNAPOs, {
        openNAPOs,
      })}
      {renderIfDataExists(AcceptedWorkNeedInvoicing, {
        acceptedWorkNotInvoicedCountPerProject,
        acceptedWorkNotInvoicedCountPerUser,
      })}
    </DashboardAccordion>
  );
};

export default FinancialSection;
