import FormSpinner from "../../../../../components/Spinners/FormSpinner";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { employeeRecordsService } from "../../../../../api/employeeRecords";
import { useParams } from "react-router-dom";
import { Autocomplete, Box, Stack, TextField, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useSnackbar } from "notistack";
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import dayjs from "dayjs";
import { useState } from "react";
import ButtonGroup from "../../../../../components/forms/ButtonGroup/ButtonGroup";
import {
  MAX_FILE_SIZE,
  supportedImageTypes,
} from "../../../../../constants/constants";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFnsV3";
import ImagePickerBox from "../../components/ImagePickerBox";
import { convertImage } from "../../../../../utils/utils";
import { useLabels } from "./hooks/useLabels";

const currentDate = dayjs().format("YYYY/MM/DD");

const gender = ["Male", "Female"];
const socialStatus = ["Single", "Married"];
const religion = ["Muslim", "Christian"];

const EditNationalID = ({ handleClose }) => {
  const { empCode } = useParams();
  const { data, isLoading, isRefetching } = useQuery({
    queryKey: ["nationalIdFormData", empCode],
    queryFn: () => employeeRecordsService.getNationalIdFormData(empCode),
  });

  if (isLoading || isRefetching) return <FormSpinner />;

  return <Form data={data.NationalID[0]} handleClose={handleClose} />;
};

// Extract data from national ID number like birth date and gender
const nationalIdPattern = /^([23])(\d{2})(\d{2})(\d{2}).*(\d)/;

const extractDataFromNationalID = (idNumber) => {
  const match = idNumber.match(nationalIdPattern);
  if (match) {
    const centuryDigit = match[1];
    const year = match[2];
    const month = match[3];
    const day = match[4];
    const genderDigit = idNumber[12]; // 13th digit is at index 12

    const century = centuryDigit === "2" ? "19" : "20";
    const birthYear = century + year;
    const birthDate = `${birthYear}-${month}-${day}`;
    const gender = parseInt(genderDigit) % 2 !== 0 ? "Male" : "Female";

    return {
      birthDate,
      gender,
    };
  } else {
    return "Invalid ID number";
  }
};

const Form = ({ data, handleClose }) => {
  const { empCode } = useParams();
  const queryClient = useQueryClient();
  const [t] = useTranslation("global");
  const { enqueueSnackbar } = useSnackbar();

  const labels = useLabels();

  const currentFrontImage = data?.EmpNationalIDPhotoFront
  ? convertImage(data?.EmpNationalIDPhotoFront.data)
  : null;

const currentBackImage = data?.EmpNationalIDPhotoBack
  ? convertImage(data?.EmpNationalIDPhotoBack.data)
  : null;
  
  const {
    EmpAddress,
    EmpDateOfBirth,
    EmpEffictiveDate,
    EmpGender,
    EmpJob,
    EmpNationalID,
    EmpReligion,
    EmpSocialStatus,
  } = data || {
    EmpAddress: "",
    EmpDateOfBirth: currentDate,
    EmpEffictiveDate: currentDate,
    EmpGender: "",
    EmpJob: "",
    EmpNationalID: "",
    EmpReligion: "",
    EmpSocialStatus: "",
  };

  const initialValues = {
    nationalID: EmpNationalID,
    frontImage: null,
    backImage: null,
    gender: EmpGender,
    socialStatus: EmpSocialStatus,
    religion: EmpReligion,
    birthDate: EmpDateOfBirth,
    expireDate: EmpEffictiveDate,
    jobTitle: EmpJob,
    address: EmpAddress,
  };

  const schema = yup.object().shape({
    nationalID: yup
      .string()
      .test("isValidID", "Not valid ID", (value) => {
        return !value || (value && nationalIdPattern.test(value));
      })
      .required(t("modules.global_schema.required")),
    frontImage: yup
      .mixed()
      .test("fileSize", t("modules.global_schema.file_size"), (value) => {
        return !value || (value && value.size <= MAX_FILE_SIZE);
      })
      .test(
        "fileType",
        t("modules.global_schema.file_type"),
        (value) => !value || (value && supportedImageTypes.includes(value.type))
      )
      .nullable(),
    backImage: yup
      .mixed()
      .test("fileSize", t("modules.global_schema.file_size"), (value) => {
        return !value || (value && value.size <= MAX_FILE_SIZE);
      })
      .test(
        "fileType",
        t("modules.global_schema.file_type"),
        (value) => !value || (value && supportedImageTypes.includes(value.type))
      )
      .nullable(),
    gender: yup
      .string()
      .required(t("modules.global_schema.select_value"))
      .oneOf(gender, t("modules.global_schema.invalid_value")),
    socialStatus: yup
      .string()
      .required(t("modules.global_schema.select_value"))
      .oneOf(socialStatus, t("modules.global_schema.invalid_value")),
    religion: yup
      .string()
      .required(t("modules.global_schema.select_value"))
      .oneOf(religion, t("modules.global_schema.invalid_value")),
    birthDate: yup.date().required(t("modules.global_schema.required")),
    expireDate: yup.date().required(t("modules.global_schema.required")),
    jobTitle: yup.string().required(t("modules.global_schema.required")),
    address: yup.string().required(t("modules.global_schema.required")),
  });

  const {
    register,
    handleSubmit,
    setValue,
    setError,
    control,
    getValues,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
    mode: "onChange",
    defaultValues: initialValues,
  });

  const handleFormSubmit = (values) => {
    const {
      frontImage,
      backImage,
      gender,
      socialStatus,
      religion,
      birthDate,
      expireDate,
      jobTitle,
      address,
      nationalID,
    } = values;

    if (!currentFrontImage && getValues().frontImage === null) {
      return setError("frontImage", {
        message: t("modules.global_schema.required"),
        type: "required",
      });
    } else if (!currentBackImage && getValues().backImage === null) {
      return setError("backImage", {
        message: t("modules.global_schema.required"),
        type: "required",
      });
    }

    const formData = new FormData();

    formData.append("EmpNationalIDPhotoFront", frontImage);
    formData.append("EmpNationalIDPhotoBack", backImage);
    formData.append("EmpDateOfBirth", dayjs(birthDate).format("YYYY-MM-DD"));
    formData.append("EmpEffictiveDate", dayjs(expireDate).format("YYYY-MM-DD"));
    formData.append("EmpGender", gender);
    formData.append("EmpReligion", religion);
    formData.append("EmpJob", jobTitle);
    formData.append("EmpAddress", address);
    formData.append("EmpSocialStatus", socialStatus);
    formData.append("EmpNationalID", nationalID);
    formData.append("SelectedNo", empCode);
    formData.append("State", "NationalID");

    editNationalID(formData);
  };

  const { mutateAsync: editNationalID, isPending } = useMutation({
    mutationFn: employeeRecordsService.addNewEmployeeDocument,
    onSuccess: (data) => {
      handleClose();
      enqueueSnackbar(data.msg, { variant: "success" });
      queryClient.invalidateQueries({ queryKey: ["employeeInfo"] });
    },
  });

  // Images handling logic
  const [selectedFrontImage, setSelectedFrontImage] = useState(null);
  const [selectedBackImage, setSelectedBackImage] = useState(null);

  const handleImageFrontChange = (event) => {
    setError("frontImage", null);
    const file = event.currentTarget.files[0];
    if (!file) return;
    setSelectedFrontImage(URL.createObjectURL(file));
  };

  const handleImageBackChange = (event) => {
    setError("backImage", null);
    const file = event.currentTarget.files[0];
    if (!file) return;
    setValue("backImage", file);
    setSelectedBackImage(URL.createObjectURL(file));
  };

  const [isArabic, setIsArabic] = useState(false);

  const handleChange = (event) => {
    const inputValue = event.target.value;

    // Check if the input contains any Arabic characters
    const isArabicInput = /[\u0600-\u06FF]/.test(inputValue);
    setIsArabic(isArabicInput);
  };

  return (
    <form onSubmit={handleSubmit(handleFormSubmit)} noValidate>
      <Stack spacing={2} p={2}>
        <Box
          sx={{
            display: "grid",
            gridTemplateColumns: "1fr 1fr",
            gap: "10px",
            justifyItems: "center",
          }}
        >
          <Stack spacing={1}>
            <Typography
              sx={{
                fontSize: "14px",
                fontStyle: "italic",
              }}
            >
              * {t("modules.company_fleet.new_form.fields.upload_front")}
            </Typography>
            <ImagePickerBox
              error={errors.frontImage}
              image={selectedFrontImage || currentFrontImage}
              handleImage={handleImageFrontChange}
              control={control}
              name="frontImage"
            />
          </Stack>
          <Stack spacing={1}>
            <Typography
              sx={{
                fontSize: "14px",
                fontStyle: "italic",
              }}
            >
              * {t("modules.company_fleet.new_form.fields.upload_back")}
            </Typography>
            <ImagePickerBox
              error={errors.backImage}
              image={selectedBackImage || currentBackImage}
              handleImage={handleImageBackChange}
              control={control}
              name="backImage"
            />
          </Stack>
        </Box>
        <Box
          sx={{
            display: "grid",
            gridTemplateColumns: "1fr 1fr 1fr",
            gap: "16px",
          }}
        >
          <TextField
            size="small"
            label={labels.nationalID}
            error={Boolean(errors.nationalID)}
            helperText={errors.nationalID?.message}
            {...register("nationalID", {
              onChange: (e) => {
                if (e.target.value.length !== 14) return;
                setValue(
                  "birthDate",
                  extractDataFromNationalID(e.target.value).birthDate
                );
                setValue(
                  "gender",
                  extractDataFromNationalID(e.target.value).gender
                );
              },
            })}
            required
          />
          <Controller
            control={control}
            name="gender"
            render={({ field: { onChange, value } }) => (
              <Autocomplete
                disabled
                disableClearable
                size="small"
                options={gender}
                onChange={(event, item) => {
                  onChange(item);
                }}
                value={value || null}
                renderInput={(params) => (
                  <TextField {...params} label={labels.gender} />
                )}
              />
            )}
          />
          <Controller
            control={control}
            name="socialStatus"
            render={({ field: { onChange, value } }) => (
              <Autocomplete
                disableClearable
                size="small"
                options={socialStatus}
                onChange={(event, item) => {
                  onChange(item);
                }}
                value={value || null}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label={labels.marital_status}
                    error={!!errors.socialStatus}
                    helperText={errors.socialStatus?.message}
                    required
                  />
                )}
              />
            )}
          />
          <Controller
            control={control}
            name="religion"
            render={({ field: { onChange, value } }) => (
              <Autocomplete
                disableClearable
                size="small"
                options={religion}
                onChange={(event, item) => {
                  onChange(item);
                }}
                value={value || null}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label={labels.religion}
                    error={!!errors.religion}
                    helperText={errors.religion?.message}
                    required
                  />
                )}
              />
            )}
          />
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <Controller
              control={control}
              name="birthDate"
              render={({ field }) => {
                return (
                  <DatePicker
                    label={labels.date_of_birth}
                    views={["year", "month", "day"]}
                    name="birthDate"
                    format="yyyy/MM/dd"
                    disableFuture
                    value={field.value}
                    inputRef={field.ref}
                    onChange={(date) => {
                      field.onChange(date);
                    }}
                    slotProps={{
                      textField: {
                        error: Boolean(errors.birthDate),
                        helperText: errors.birthDate?.message,
                        required: true,
                        size: "small",
                      },
                    }}
                  />
                );
              }}
            />
          </LocalizationProvider>
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <Controller
              control={control}
              name="expireDate"
              render={({ field }) => {
                return (
                  <DatePicker
                    label={labels.effective_date}
                    views={["year", "month", "day"]}
                    name="expireDate"
                    format="yyyy/MM/dd"
                    value={field.value}
                    inputRef={field.ref}
                    onChange={(date) => {
                      field.onChange(date);
                    }}
                    slotProps={{
                      textField: {
                        error: Boolean(errors.expireDate),
                        helperText: errors.expireDate?.message,
                        required: true,
                        size: "small",
                      },
                    }}
                  />
                );
              }}
            />
          </LocalizationProvider>
          <TextField
            size="small"
            label={labels.job_title}
            error={Boolean(errors.jobTitle)}
            helperText={errors.jobTitle?.message}
            {...register("jobTitle", {
              onChange: handleChange,
            })}
            required
            inputProps={{
              style: {
                direction: isArabic ? "rtl" : "ltr",
                unicodeBidi: "plaintext",
              },
            }}
          />
          <TextField
            size="small"
            label={labels.home_address}
            error={Boolean(errors.address)}
            helperText={errors.address?.message}
            {...register("address", {
              onChange: handleChange,
            })}
            required
            sx={{ gridColumn: "span 2" }}
            inputProps={{
              style: {
                direction: isArabic ? "rtl" : "ltr",
                unicodeBidi: "plaintext",
              },
            }}
          />
        </Box>
      </Stack>

      <ButtonGroup
        isPending={isPending}
        onClickClose={handleClose}
        saveLabel={t("modules.buttons.save")}
      />
    </form>
  );
};

export default EditNationalID;
