import { createContext, useState, useContext } from "react";
import ConfirmationDialog from "../components/ConfirmationDialog";

const ConfirmationDialogContext = createContext({
  open: false,
  setOpen: () => {},
  onConfirm: () => {},
  onCancel: () => {},
  message: "",
  color: "primary",
  cancelColor: "secondary",
  cancelLabel: "confirmation_modal.buttons.cancel",
});

export const ConfirmationDialogProvider = ({ children }) => {
  const [open, setOpen] = useState(false);
  const [message, setMessage] = useState("");
  const [onConfirm, setOnConfirm] = useState(() => {});
  const [color, setColor] = useState("primary");
  const [cancelColor, setCancelColor] = useState("secondary");
  const [cancelLabel, setCancelLabel] = useState(
    "confirmation_modal.buttons.cancel"
  );

  const openDialog = (
    message,
    onConfirmCb,
    color,
    cancelColor,
    cancelLabel
  ) => {
    setMessage(message);
    setOnConfirm(onConfirmCb);
    setOpen(true);
    setColor(color);
    setCancelColor(cancelColor);
    setCancelLabel(cancelLabel);
  };

  const closeDialog = () => {
    setOpen(false);
  };

  const value = {
    open,
    setOpen: openDialog, // Combine opening logic with state update
    onConfirm,
    message,
    closeDialog, // Provide a close function for convenience
    color,
    cancelColor,
    cancelLabel,
  };

  return (
    <>
      <ConfirmationDialogContext.Provider value={value}>
        {children}
      </ConfirmationDialogContext.Provider>
      <ConfirmationDialog
        open={open}
        closeDialog={closeDialog}
        onConfirm={onConfirm}
        message={message}
        color={color}
        cancelColor={cancelColor}
        cancelLabel={cancelLabel}
      />
    </>
  );
};

export const useConfirmationDialog = () => {
  const context = useContext(ConfirmationDialogContext);
  if (!context) {
    throw new Error(
      "useConfirmationDialog must be used within a ConfirmationDialogProvider"
    );
  }
  return context;
};
