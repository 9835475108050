import Box from "@mui/material/Box";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import { useReducer, useState } from "react";
import Modify from "./Steps/Modify";
import Upload from "./Steps/Upload";
import Save from "./Steps/Save";
import { useTranslation } from "react-i18next";
import dayjs from "dayjs";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { useSnackbar } from "notistack";
import { priceListsService } from "../../../api/priceLists";
import * as yup from "yup";
import StepperButton from "../ButtonGroup/StepperButton";
import useHandleForms from "../../../hooks/useHandleForms";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import FormSpinner from "../../Spinners/FormSpinner";

export default function PriceListsForm({ onDirtyChange }) {
  const [activeStep, setActiveStep] = useState(0);

  const handleNext = async () => {
    if (activeStep === 0) setActiveStep((prevActiveStep) => prevActiveStep + 1);
    const { rows } = state;

    const {
      IState,
      ISuffex,
      PriceListRefNo,
      SState,
      SSuffiex,
      PriceListRefYear,
      CompanyAccountName,
    } = getValues();
    if (activeStep === 1) {
      const areValid = await trigger();
      if (!areValid) return;

      CheckPriceList({
        IState,
        ISuffex,
        PriceList: JSON.stringify({ Rows: rows }),
        PriceListRefNo,
        SState,
        SSuffiex,
        State: "CheckPriceList",
        PriceListRefYear: parseInt(PriceListRefYear),
      });
    } else if (activeStep === 2) {
      AddNewPriceList({
        PriceList: JSON.stringify({ ModifyRows: rows }),
        PriceListRefNo,
        PriceListRefYear: parseInt(PriceListRefYear),
        CompanyAccountName,
        State: "New",
      });
    }
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const [t] = useTranslation("global");

  const steps = [
    t("modules.prices_list.new_form.steps.modify"),
    t("modules.prices_list.new_form.steps.upload"),
    t("modules.prices_list.new_form.steps.save"),
  ];

  const initialValues = {
    rows: [],
    modifiedRows: [],
    columns: [],
    error: null,
    IState: true,
    ISuffex: "I",
    PriceList: "",
    PriceListRefNo: "",
    SState: false,
    SSuffiex: "",
    PriceListRefYear: dayjs().format("YYYY"),
    State: "CheckPriceList",
    CompanyAccountName: "",
  };

  const schema = yup.object().shape({
    PriceListRefNo: yup
      .string()
      .required(t("modules.global_schema.select_value")),
    IState: yup.boolean(),
    ISuffex: yup.string(),
    SState: yup.boolean(),
    SSuffiex: yup.string(),
    PriceListRefYear: yup.date(),
    PriceList: yup
      .mixed()
      .test("requiredFile", t("modules.global_schema.required"), (value) => {
        return value;
      }),
    CompanyAccountName: yup
      .string()
      .required(t("modules.global_schema.required")),
  });

  const {
    register,
    handleSubmit,
    setValue,
    setError,
    control,
    formState: { errors, isDirty },
    getValues,
    trigger,
    resetField,
    clearErrors,
    watch,
  } = useForm({
    resolver: yupResolver(schema),
    mode: "onChange",
    defaultValues: initialValues,
  });

  const { handleClose } = useHandleForms({ dirty: isDirty, onDirtyChange });

  function reducer(state, action) {
    switch (action.type) {
      case "PriceList":
        return {
          ...state,
          PriceList: JSON.stringify(action.payload),
        };
      case "rows":
      case "modifiedRows":
      case "columns":
      case "error":
        return {
          ...state,
          [action.type]: action.payload,
        };
      default:
        throw new Error("Unknown action type: " + action.type);
    }
  }

  const [state, dispatch] = useReducer(reducer, initialValues);

  const queryClient = useQueryClient();

  const { enqueueSnackbar } = useSnackbar();

  const { mutateAsync: CheckPriceList, isPending: isCheckingPriceList } =
    useMutation({
      mutationFn: priceListsService.CheckPriceList,
      onSuccess: (data) => {
        const newRows = data.dataGrid.map((row, index) => ({
          ...row,
          id: index,
        }));
        dispatch({ type: "modifiedRows", payload: newRows });
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
      },
    });

  const { mutateAsync: AddNewPriceList, isPending: isAddingNewPriceList } =
    useMutation({
      mutationFn: priceListsService.AddNewPriceList,
      onSuccess: (data) => {
        handleClose();
        enqueueSnackbar(data.msg, { variant: "success" });
        queryClient.invalidateQueries({ queryKey: ["priceLists"] });
      },
    });

  const { data, isLoading } = useQuery({
    queryKey: ["getNewPriceListsForm"],
    queryFn: priceListsService.getNewForm,
  });

  if (isLoading) return <FormSpinner />;

  const views = {
    0: <Modify />,
    1: (
      <Upload
        dispatch={dispatch}
        state={state}
        handleSubmit={handleSubmit}
        errors={errors}
        control={control}
        register={register}
        setError={setError}
        setValue={setValue}
        clearErrors={clearErrors}
        watch={watch}
        resetField={resetField}
        companyAccounts={data}
      />
    ),
    2: (
      <Save
        handleClose={handleClose}
        values={getValues()}
        rows={state.modifiedRows}
      />
    ),
  };

  return (
    <Box sx={{ width: "100%" }}>
      <Stepper activeStep={activeStep}>
        {steps.map((label) => {
          return (
            <Step key={label}>
              <StepLabel>{label}</StepLabel>
            </Step>
          );
        })}
      </Stepper>
      {views[activeStep]}
      <StepperButton
        isPending={isCheckingPriceList || isAddingNewPriceList}
        onClick={handleNext}
        handleBack={handleBack}
        activeStep={activeStep}
        disabled={isCheckingPriceList || isAddingNewPriceList}
        label={
          activeStep === steps.length - 1
            ? t("modules.buttons.save")
            : t("modules.buttons.next")
        }
        isLastStep={activeStep === steps.length - 1}
      />
    </Box>
  );
}
