import Step1 from "./Steps/Step1";
import { Box, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { useQuery, useMutation } from "@tanstack/react-query";
import { filesFoldersService } from "../../../api/filesManager";
import InProgressMigration from "./InProgressMigration";
import FormSpinner from "../../../components/Spinners/FormSpinner";
import LoadingButton from "@mui/lab/LoadingButton";
import { useConfirmationDialog } from "../../../contexts/ConfirmationDialogContext";

const Migration = ({ loading, setLoading }) => {
  const { setOpen } = useConfirmationDialog();

  const [migrationDestination, setMigrationDestination] = useState("cloud"); // cloud or database
  const [cloudRows, setCloudRows] = useState([]);
  const [dbRows, setDbRows] = useState([]);
  const [isMigrationStarted, setIsMigrationStarted] = useState(false);
  const [checked, setChecked] = useState(false);
  const [totalDbCount, setTotalDbCount] = useState(0);
  const [totalCloudCount, setTotalCloudCount] = useState(0);

  const handleMigrationDestination = () => {
    setMigrationDestination((prev) =>
      prev === "cloud" ? "database" : "cloud"
    );
  };

  const { data, isLoading, refetch, isSuccess } = useQuery({
    queryKey: ["dbAndCloudData"],
    queryFn: filesFoldersService.getDBAndCloudData,
  });

  const { mutateAsync: migrationStart, isLoading: isMigrationStart } =
    useMutation({
      mutationFn: () =>
        filesFoldersService.migrationStart(
          migrationDestination === "cloud"
            ? "DataBase To NextCloud"
            : "NextCloud To DataBase",
          checked
        ),
      onSuccess: () => {
        refetchProgress();
        refetch();
        setIsMigrationStarted(true);
      },
    });

  const {
    data: migrationProgress,
    isLoading: isMigrationProgress,
    refetch: refetchProgress,
    isSuccess: isProgressSuccess,
  } = useQuery({
    queryKey: ["migrationProgress"],
    queryFn: filesFoldersService.getMigrateStatus,
  });

  useEffect(() => {
    if (isSuccess) {
      if (loading === true) {
        const intervalId = setInterval(() => {
          refetch();
        }, 5000);
        return () => clearInterval(intervalId);
      }
      setTotalDbCount(
        data?.DB?.reduce((acc, curr) => acc + curr.count, 0) || 0
      );
      setTotalCloudCount(
        data?.NextCloud?.reduce((acc, curr) => acc + curr.count, 0) || 0
      );
      setCloudRows(data?.NextCloud || []);
      setDbRows(data?.DB || []);
    }
  }, [isSuccess, loading, refetch, data]);

  useEffect(() => {
    if (isProgressSuccess && migrationProgress?.InProgress) {
      setIsMigrationStarted(true);
    }
  }, [isProgressSuccess, migrationProgress]);

  const handleStartClick = () => {
    migrationStart();
  };

  return (
    <>
      {isLoading || isMigrationProgress ? (
        <FormSpinner />
      ) : (
        <>
          {migrationProgress?.InProgress || isMigrationStarted ? (
            <InProgressMigration
              progressValue={migrationProgress}
              refetch={refetchProgress}
              setIsMigrationStarted={setIsMigrationStarted}
            />
          ) : (
            <Box
              sx={{
                padding: "1rem",
              }}
            >
              <Step1
                handleMigrationDestination={handleMigrationDestination}
                migrationDestination={migrationDestination}
                data={data}
                isLoading={isLoading}
                dbRows={dbRows}
                cloudRows={cloudRows}
                refetch={refetch}
                loading={loading}
                setLoading={setLoading}
                checked={checked}
                setChecked={setChecked}
                totalDbCount={totalDbCount}
                setTotalDbCount={setTotalDbCount}
                totalCloudCount={totalCloudCount}
                setTotalCloudCount={setTotalCloudCount}
              />
              <Box display="flex" gap="10px" justifyContent="space-between">
                <Typography
                  sx={{
                    color:
                      migrationProgress.Delete === "True" ? "red" : "#00e676",
                    fontWeight: "500",
                  }}
                >
                  {migrationProgress.Delete === "True"
                    ? "Images Will Be Deleted From Database In The Migration"
                    : "Images Will Only Be Copied From Database In The Migration"}
                </Typography>
                <LoadingButton
                  variant="outlined"
                  onClick={() => {
                    setOpen(
                      "Are you sure you want to start migration?",
                      () => () => {
                        handleStartClick();
                      }
                    );
                  }}
                  loading={isMigrationStart}
                  disabled={loading || totalDbCount === 0}
                  color="success"
                  sx={{
                    border: 2.3,
                    ":hover": {
                      border: 2.3,
                    },
                  }}
                >
                  Start
                </LoadingButton>
              </Box>
            </Box>
          )}
        </>
      )}
    </>
  );
};

export default Migration;
