import React, { useState, useEffect } from "react";
import { styled } from "@mui/material/styles";
import Box from "@mui/material/Box";
import LinearProgress, {
  linearProgressClasses,
} from "@mui/material/LinearProgress";
import { Typography, Button } from "@mui/material";
import StorageIcon from "@mui/icons-material/Storage";
import CloudIcon from "@mui/icons-material/Cloud";
import { filesFoldersService } from "../../../api/filesManager";
import { useMutation } from "@tanstack/react-query";
import ConfirmationInfoModal from "../../../components/Modals/ConfirmationInfoModal";
import KeyboardDoubleArrowRightIcon from "@mui/icons-material/KeyboardDoubleArrowRight";

const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
  height: 10,
  width: "90%",
  borderRadius: 5,
  [`&.${linearProgressClasses.colorPrimary}`]: {
    backgroundColor:
      theme.palette.grey[theme.palette.mode === "light" ? 200 : 800],
  },
  [`& .${linearProgressClasses.bar}`]: {
    borderRadius: 5,
    backgroundColor: theme.palette.mode === "light" ? "#1a90ff" : "#308fe8",
  },
}));

const AnimatedArrowIcon = styled(KeyboardDoubleArrowRightIcon)(({ theme }) => ({
  animation: "blink 1s infinite",
  "@keyframes blink": {
    "0%": { opacity: 1 },
    "50%": { opacity: 0 },
    "100%": { opacity: 1 },
  },
}));

function InProgressMigration({
  progressValue,
  refetch,
  setIsMigrationStarted,
}) {
  const progress = Math.round(Number(progressValue?.Progress) || 0);
  const [value, setValue] = useState(progress);
  const [confirmationCancel, setConfirmationCancel] = useState(false);

  useEffect(() => {
    const interval = setInterval(() => {
      refetch();
    }, 5000);
    setValue(progress || 0);

    return () => {
      clearInterval(interval);
    };
  }, [setValue, progress, refetch]);

  const { mutateAsync: migrationCancel, isPending: isMigrationCancel } =
    useMutation({
      mutationFn: filesFoldersService.cancelMigration,
      onSuccess: () => {
        setIsMigrationStarted(false);
      },
    });

  return (
    <>
      <Box
        display="flex"
        alignItems="center"
        flexDirection="column"
        gap={1}
        my={2}
      >
        <Typography
          sx={{ fontWeight: 500, fontSize: "16px", marginBottom: "15px" }}
        >
          {progressValue?.Action} Migration
        </Typography>
        <Box
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          width="89%"
        >
          {progressValue?.Action === "DataBase To NextCloud" ? (
            <StorageIcon />
          ) : (
            <CloudIcon />
          )}
          <AnimatedArrowIcon />
          {progressValue?.Action === "DataBase To NextCloud" ? (
            <CloudIcon />
          ) : (
            <StorageIcon />
          )}
        </Box>
        <BorderLinearProgress variant="determinate" value={value} />
        <Typography sx={{ fontWeight: 500, fontSize: "16px" }}>
          Progress: {value}%
        </Typography>
      </Box>
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        margin={2}
      >
        <Typography variant="body1">
          Current File: {progressValue?.CurrentFile}
        </Typography>
        <Button
          color="error"
          variant="outlined"
          onClick={() => setConfirmationCancel(true)}
          sx={{
            border: 2.3,
            ":hover": {
              border: 2.3,
            },
          }}
        >
          Cancel
        </Button>
      </Box>
      <ConfirmationInfoModal
        open={confirmationCancel}
        handleClose={() => setConfirmationCancel(false)}
        onClick={() => {
          migrationCancel();
        }}
        description={"Are you sure you want to cancel the migration?"}
        isPending={isMigrationCancel}
        color="error"
      />
    </>
  );
}

export default InProgressMigration;
