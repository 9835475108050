import { Stack, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import AntSwitch from "../../../Switches/AntSwitch";
import FormsDataGrid from "../../../DataGrid/FormsDataGrid";

const Save = ({ state, selectedRows, dispatch }) => {
  const [t] = useTranslation("global");

  const columns = [
    {
      field: "StoreItemsTemplateCode",
      headerName: t("data_grid.headers.item_code"),
    },

    {
      field: "StoreItemsTemplateName",
      headerName: t("data_grid.headers.item_description"),
      flex: 1,
    },
    {
      field: "StoreItemsTemplateType",
      headerName: t("data_grid.headers.type"),
    },
    {
      field: "StoreItemsTemplateUnit",
      headerName: t("data_grid.headers.unit"),
    },
    {
      field: "StoreItemsTemplateQty",
      headerName: t("data_grid.headers.item_total_balance"),
    },
    {
      field: "QTY",
      headerName: t("data_grid.headers.quantity"),
    },
  ];

  return (
    <Stack spacing={3}>
      <Typography variant="h6" color={"primary"}>
        {t("modules.purchase_requests.new_form.details")}
      </Typography>
      <Stack direction="row" spacing={1} alignItems="center">
        <Typography>
          {t("modules.purchase_requests.new_form.fields.materials_add_request")}
          :
        </Typography>
        <AntSwitch
          name="materials_add_request"
          onChange={(e, checked) => {
            dispatch({
              type: "materials_add_request",
              payload: checked,
            });
          }}
          checked={state.materials_add_request}
        />
      </Stack>

      <FormsDataGrid
        rows={selectedRows}
        columns={columns}
        getRowId={(row) => row.StoreItemsTemplateCode}
        disableColumnMenu
        height={300}
      />
    </Stack>
  );
};

export default Save;
