import dayjs from "dayjs";
import { useState } from "react";
import download from "downloadjs";
import { Link } from "react-router-dom";
import { statusColors } from "./statuses";
import { useSelector } from "react-redux";
import { Backdrop, Box } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useQuery } from "@tanstack/react-query";
import ChipStatus from "../components/ChipStatus";
import Spinner from "../components/Spinners/Spinner";
import useOpenNewForm from "../hooks/useOpenNewForm";
import { WorkOrdersService } from "../api/workOrders";
import DateFormatGrid from "../components/DateFormatGrid";
import FormSpinner from "../components/Spinners/FormSpinner";
import CustomDataGrid from "../components/DataGrid/CustomDataGrid";
import ProgressBarCell from "../components/ProgressBar/ProgressBar";
import { useConfirmationDialog } from "../contexts/ConfirmationDialogContext";

const WorkOrders = () => {
  const [t] = useTranslation("global");

  const { newForm } = useSelector((state) => state.theme);
  const [isBulkExportClicked, setIsBulkExportClicked] = useState(false);
  const [selectedRows, setSelectedRows] = useState([]);
  const [selectedWorkOrderNos, setSelectedWorkOrderNos] = useState([]);
  const [isExportClicked, setIsExportClicked] = useState(false);

  const exportButtonLabel =
    selectedRows.length > 0
      ? "data_grid.buttons.export_selected"
      : "data_grid.buttons.bulk_export";

  const statusNames = [
    { name: "Hold", displayName: t("data_grid.tabs.hold") },
    { name: "Pending", displayName: t("data_grid.tabs.pending") },
    { name: "In Progress", displayName: t("data_grid.tabs.in_progress") },
    {
      name: "Complete With Reserves",
      displayName: t("data_grid.tabs.complete_with_reserves"),
    },
    {
      name: "Work Complete",
      displayName: t("data_grid.tabs.work_complete"),
    },
    { name: "Cancelled", displayName: t("data_grid.tabs.cancelled") },
  ];

  const columns = [
    {
      field: "WorkOrderNo",
      headerName: t("data_grid.headers.ref_no"),
      width: 130,
      hideable: false,
      renderCell: (params) => {
        return (
          <Link
            style={{
              color: "#1790FF",
            }}
            to={`/workorders/${params.value}`}
          >
            {params.value}
          </Link>
        );
      },
    },
    {
      field: "SiteName",
      headerName: t("data_grid.headers.site_name"),
      width: 70,
      flex: 1,
    },
    {
      field: "SiteCode",
      headerName: t("data_grid.headers.site_code"),
      width: 135,
    },
    {
      field: "SiteType",
      headerName: t("data_grid.headers.site_type"),
    },
    {
      field: "WorkOrderDate",
      headerName: t("data_grid.headers.received_date"),
      width: 120,
      type: "date",
      valueGetter: ({ value }) => (value ? new Date(value) : null),
      renderCell: ({ value }) => {
        return value ? dayjs(value).format("YYYY/MM/DD") : "";
      },
    },
    {
      field: "MissionsStarted",
      headerName: t("data_grid.headers.missions_started"),
      width: 120,
      type: "date",
      valueGetter: ({ value }) => (value ? new Date(value) : null),
      renderCell: ({ value }) => {
        return <> {value ? <DateFormatGrid value={value} /> : <> </>}</>;
      },
    },
    {
      field: "MissionsEnded",
      headerName: t("data_grid.headers.missions_ended"),
      width: 120,
      type: "date",
      valueGetter: ({ value }) => (value ? new Date(value) : null),
      renderCell: ({ value }) => {
        return <> {value ? <DateFormatGrid value={value} /> : <> </>}</>;
      },
    },
    {
      field: "TeamLeadersName",
      headerName: t("data_grid.headers.team_leader"),
      width: 125,
    },
    {
      field: "ERPUserNickName",
      headerName: t("data_grid.headers.created_by"),
      width: 125,
    },
    {
      field: "LastUpdateDate",
      headerName: t("data_grid.headers.last_update"),
      width: 115,
      type: "date",
      valueGetter: ({ value }) => (value ? new Date(value) : null),
      renderCell: ({ value }) => {
        return value ? dayjs(value).format("YYYY/MM/DD") : "";
      },
    },
    {
      field: "D6Count",
      headerName: t("data_grid.headers.d6_count"),
      width: 70,
    },
    {
      field: "AcquisitionContractor",
      headerName: t("data_grid.headers.acquisition_contractor"),
      width: 100,
    },
    {
      field: "ConstructionContractor",
      headerName: t("data_grid.headers.construction_contractor"),
      width: 100,
    },
    {
      field: "SupportType",
      headerName: t("data_grid.headers.support_type"),
      width: 100,
    },
    {
      field: "SupportHeight",
      headerName: t("data_grid.headers.support_height"),
      width: 100,
    },
    {
      field: "NumberOfSectors",
      headerName: t("data_grid.headers.number_of_sectors"),
      width: 100,
    },
    {
      field: "EquipmentRoom",
      headerName: t("data_grid.headers.equipment_room"),
      width: 100,
    },
    {
      field: "FirstRunPower",
      headerName: t("data_grid.headers.first_run_power"),
      width: 100,
    },
    {
      field: "SharingPowerSource",
      headerName: t("data_grid.headers.sharing_power_source"),
      width: 100,
    },
    {
      field: "Consultant",
      headerName: t("data_grid.headers.consultant"),
      width: 100,
    },
    {
      field: "SharingStatus",
      headerName: t("data_grid.headers.sharing_status"),
      width: 100,
    },

    {
      field: "GovernoratesName",
      headerName: t("data_grid.headers.governorate_name"),
      width: 120,
    },
    {
      field: "RequestorsName",
      headerName: t("data_grid.headers.requested_by"),
      width: 120,
    },
    {
      field: "SubProjectsName",
      headerName: t("data_grid.headers.sub_project"),
      width: 120,
    },
    {
      field: "Progress",
      headerName: t("data_grid.headers.acceptance_progress"),
      width: 130,
      valueGetter: (params) => {
        return params.row.D6Count == 0 ? "Not Started" : params.value;
      },
      renderCell: (params) => {
        return params.row.D6Count == 0
          ? "Not Started"
          : ProgressBarCell(params.value, params.row.WorkOrderStatus);
      },
    },
    {
      field: "WorkOrderStatus",
      headerName: t("data_grid.headers.wo_status"),
      headerAlign: "center",
      width: 185,
      renderCell: ({ value }) => {
        return <ChipStatus value={value} />;
      },
    },
  ];
  const { refetch: refetchInvoicesFile } = useQuery({
    queryKey: ["workOrdersFile"],
    queryFn: () => WorkOrdersService.exportWorkOrders(selectedWorkOrderNos),
    enabled: false,
  });

  const {
    data: rows,
    isLoading,
    refetch,
    isRefetching,
  } = useQuery({
    queryKey: ["workOrders"],
    queryFn: WorkOrdersService.getAllWorkOrders,
  });

  // Handling new form logic
  const { handleOpen } = useOpenNewForm();
  const { setOpen } = useConfirmationDialog();

  if (isLoading) {
    return <Spinner />;
  }
  const handleRowSelection = (newSelectedRows) => {
    setSelectedRows(newSelectedRows);
    const selectedData = rows.result.filter((row) =>
      newSelectedRows.includes(row.idWorkOrder)
    );
    const workOrderNos = selectedData.map((row) => row.WorkOrderNo);
    setSelectedWorkOrderNos(workOrderNos);
  };

  const handleBulkExport = () => {
    if (!isBulkExportClicked) {
      setIsBulkExportClicked(true);
      return;
    }

    if (selectedRows.length === 0) {
      setIsBulkExportClicked(false);
      return;
    }

    setIsExportClicked(true);

    refetchInvoicesFile({
      queryFn: () => WorkOrdersService.exportWorkOrders(selectedWorkOrderNos),
    })
      .then((response) => {
        download(response.data, "Work Orders", "application/pdf");
      })
      .catch((error) => {
        console.error("Error exporting work orders:", error);
      })
      .finally(() => {
        setIsExportClicked(false);
      });
  };

  return (
    <>
      <CustomDataGrid
        rows={rows.result || []}
        columns={columns}
        loading={isLoading}
        getRowId={(row) => row.idWorkOrder}
        statusColors={statusColors}
        statusNames={statusNames}
        filterField="WorkOrderStatus"
        reloadFunction={refetch}
        showReloadOverlay={isRefetching}
        bulkExportBtn={rows.Actions.includes("Bulk Export")}
        bulkExportFunction={() => handleBulkExport()}
        enableRowSelection={isBulkExportClicked}
        rowSelectionModel={selectedRows}
        onRowSelectionModelChange={handleRowSelection}
        bulkExportButtonLabel={exportButtonLabel}
        newButton
        newFunction={() => {
          if (newForm === "work_orders" || newForm === null) {
            handleOpen("work_orders");
          } else {
            setOpen(t("modules.new_form_confirmation_message"), () => () => {
              handleOpen("work_orders");
            });
          }
        }}
        initialState={{
          columns: {
            columnVisibilityModel: {
              GovernoratesName: false,
              SubProjectsName: false,
              SiteType: false,
              RequestorsName: false,
              LastUpdateDate: false,
              MissionsStarted: false,
              MissionsEnded: false,
              AcquisitionContractor: false,
              ConstructionContractor: false,
              SupportType: false,
              SupportHeight: false,
              NumberOfSectors: false,
              EquipmentRoom: false,
              FirstRunPower: false,
              SharingPowerSource: false,
              Consultant: false,
              SharingStatus: false,
            },
          },
        }}
      />
      <Backdrop open={isExportClicked} style={{ zIndex: 1000 }}>
        <Box marginLeft={20}>
          <FormSpinner />
        </Box>
      </Backdrop>
    </>
  );
};

export default WorkOrders;
