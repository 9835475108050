import QuotationInfo from "./QuotationInfo";
import { useQuery } from "@tanstack/react-query";
import Axios from "../../../network/Axios";
import { useParams } from "react-router-dom";

const Quotation = () => {
  const { quotationId } = useParams();
  const { data: quotationInfo, isLoading } = useQuery({
    queryKey: ["quotation", "info", quotationId],
    queryFn: async () => {
      const response = await Axios.get("/Quotations", {
        params: {
          SelectedNo: quotationId,
          State: "CardDetailed",
        },
      });
      return response.data;
    },
  });

  return <QuotationInfo quotationInfo={quotationInfo} isLoading={isLoading} />;
};
export default Quotation;
