import { Box } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useQuery } from "@tanstack/react-query";
import Axios from "../../../../../network/Axios";
import InfoModal from "../../../../../components/Modals/InfoModal";
import FormsDataGrid from "../../../../../components/DataGrid/FormsDataGrid";
import ButtonGroup from "../../../../../components/forms/ButtonGroup/ButtonGroup";

const SelectEmployee = ({
  open,
  handleClose,
  selectedEmp,
  setSelectedEmp,
  selectedItemsEmp,
  setSelectedItemsEmp,
  setFieldValue,
}) => {
  const [t] = useTranslation("global");

  const { data, isLoading } = useQuery({
    queryKey: ["employeeRecords", "wareHouse"],
    queryFn: async () => {
      const response = await Axios.get("/EmployeeRecords", {
        params: {
          State: "WareHouse",
        },
      });
      return response.data.result;
    },
    enabled: open,
  });

  const handleRowSelectionModelChange = (selectedItems) => {
    if (selectedItems.length > 1) {
      const lastSelected = selectedItems[selectedItems.length - 1];
      selectedItems = [lastSelected];
    }
    const selectedData = data.filter((row) =>
      selectedItems.includes(row.idEmpInfo)
    );
    setSelectedItemsEmp(selectedItems);
    setSelectedEmp(selectedData);
  };

  const handleSelectEmployee = () => {
    if (selectedEmp.length > 0) {
      setFieldValue("employeeName", selectedEmp[0].EmpName);
      handleClose();
      setSelectedItemsEmp([]);
    }
  };
  const columns = [
    {
      field: "EmpName",
      headerName: t("data_grid.headers.emp_name"),
      width: 330,
    },
    {
      field: "EmpTitle",
      headerName: t("data_grid.headers.title"),
      width: 260,
    },
  ];
  return (
    <InfoModal
      open={open}
      handleClose={handleClose}
      title={t("info_modules.material_request.description.select_emp")}
      content={
        <Box padding={1.2}>
          <FormsDataGrid
            rows={data || []}
            columns={columns}
            loading={isLoading}
            getRowId={(row) => row.idEmpInfo}
            height={380}
            showQuickFilter
            toolbar
            checkboxSelection
            disableSelectionOnClick
            onRowSelectionModelChange={handleRowSelectionModelChange}
            rowSelectionModel={selectedItemsEmp}
          />
          <ButtonGroup
            onClickClose={() => {
              handleClose();
              setSelectedItemsEmp([]);
            }}
            onClickSave={() => handleSelectEmployee()}
            saveLabel={t("modules.buttons.select")}
          />
        </Box>
      }
    />
  );
};

export default SelectEmployee;
