import axios from "axios";
import AxiosRefresh from "./AxiosRefresh";
import store from "../store/store";
import { setErrorModalState, setTokens } from "../store/slices/authSlice";
import { jwtDecode } from "jwt-decode";

const Axios = axios.create({
  baseURL: process.env.React_App_API_KEY_axios_defaults_baseURL,
});

Axios.interceptors.request.use(
  function (config) {
    const accessToken = localStorage.getItem("token");
    if (accessToken) {
      config.headers["Authorization"] = accessToken;
    }
    return config;
  },
  function (error) {
    return Promise.reject(error);
  }
);

Axios.interceptors.response.use(
  (response) => {
    return response;
  },
  async (error) => {
    const statusCode = error.response.status;

    const originalRequest = error.config;

    // Handle 404 error
    if (statusCode === 404) {
      console.log("404 error");
      window.location.replace("/404");
    }

    if (statusCode === 401 && !originalRequest._retry) {
      originalRequest._retry = true;

      try {
        const newAccessToken = await refreshToken();
        originalRequest.headers["Authorization"] = newAccessToken;
        return Axios(originalRequest);
      } catch (refreshError) {
        console.error("Failed to refresh token:", refreshError);
      }
    }

    if (statusCode === 400 || statusCode === 403) {
      const decoder = new TextDecoder("utf-8");

      // Show Error Modal
      store.dispatch(
        setErrorModalState({
          message:
            statusCode === 400
              ? error.response.data.msg ||
                JSON.parse(decoder.decode(error.response.data)).msg
              : error.response.data.error || error.response.data.msg,
          type: statusCode === 400 ? "error" : "warning",
          state: true,
        })
      );
    }

    return Promise.reject(error);
  }
);
async function refreshToken() {
  try {
    const token = localStorage.getItem("token");
    const refreshToken = localStorage.getItem("refreshToken");
    const response = await AxiosRefresh.post("/token", {
      refreshToken,
      token,
    });
    const newAccessToken = response.data.token;
    const tokenDecoded = jwtDecode(newAccessToken);
    const UserPrivTable = tokenDecoded.UserPriv;

    const NickName = UserPrivTable[0].ERPUserNickName;

    localStorage.setItem("userPriv", JSON.stringify(UserPrivTable));

    localStorage.setItem("token", newAccessToken);

    store.dispatch(
      setTokens({
        accessToken: newAccessToken,
        refreshToken,
        userPriv: UserPrivTable,
        nickName: NickName,
      })
    );

    return newAccessToken;
  } catch (error) {
    if (error.response.status === 401) {
      alert("Session expired, please log in again");
      localStorage.clear();
      window.location.replace("/login");
    }
  }
}
export default Axios;
