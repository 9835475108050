import { useDispatch, useSelector } from "react-redux";
import { setNewForm, setSideDrawerState } from "../../store/slices/themeSlice";
import MobileInternetLinesForm from "../forms/MobileInternetLinesForm";
import VacationsRequestsForm from "../forms/VacationsRequests/VacationsRequestsForm";
import { EmployeeRecordsForm } from "../forms/EmployeeRecordsForm";
import FormCard from "../forms/FormCard";
import { useTranslation } from "react-i18next";
import CompanyFleetForm from "../forms/CompanyFleet/CompanyFleetForm";
import AttendanceForm from "../forms/AttendanceForm";
import InventoryManagementForm from "../forms/InventoryManagement/InventoryManagementForm";
import PurchaseRequestsForm from "../forms/PurchaseRequests/PurchaseRequestsForm";
import PriceListsForm from "../forms/PriceListsForm/PriceListsForm";
import UsersManagementForm from "../forms/UsersManagement/UsersManagementForm";
import SitesDatabaseForm from "../forms/SitesDataBaseForm";
import ClientInvoiceForm from "../forms/ClientInvoices/ClientInvoicesForm";
import PurchaseOrderForm from "../forms/PurchaseOrderForm";
import MaterialRequestsForm from "../forms/MaterialRequests/MaterialRequestForm";
import ChangePasswordForm from "../forms/ChangePasswordForm";
import MissionsForm from "../forms/Missions/MissionsForm";
import DeliveryNotesItemsForm from "../forms/DeliveryNotesItemsForm";
import WarrantyVisitForm from "../forms/WarrantyVisit/WarrantyVisitForm";
import AcceptanceQuotationsForm from "../forms/Acceptance/AcceptanceForm";
import WorkOrdersForm from "../forms/WorkOrders/WorkOrdersForm";
import NewStatementForm from "../forms/StatementsManagement/NewStatementForm";

export const new_forms = {
  "mobile&internetlines": {
    form: MobileInternetLinesForm,
    title: "modules.mobile_internet_lines.new_form.title",
    formWidth: "30rem",
  },
  vacation_requests: {
    form: VacationsRequestsForm,
    title: "modules.vacation_requests.new_form.title",
  },
  employee_records: {
    form: EmployeeRecordsForm,
    title: "modules.employee_records.new_form.title",
  },
  company_fleet: {
    form: CompanyFleetForm,
    title: "modules.company_fleet.new_form.title",
  },
  attendance: {
    form: AttendanceForm,
    title: "modules.attendance.new_form.title2",
    formWidth: "34rem",
  },
  inventory_management: {
    form: InventoryManagementForm,
    title: "modules.inventory_management.new_form.title",
  },
  purchase_requests: {
    form: PurchaseRequestsForm,
    title: "modules.purchase_requests.new_form.title",
  },
  price_lists: {
    form: PriceListsForm,
    title: "modules.prices_list.new_form.title",
    formWidth: "75rem",
  },
  users_management: {
    form: UsersManagementForm,
    title: "modules.users_management.new_form.title",
    formWidth: "60rem",
  },
  acceptance: {
    form: (props) => <AcceptanceQuotationsForm {...props} type="acceptance" />,
    title: "modules.acceptance.new_form.title",
    formWidth: "66rem",
  },
  quotations: {
    form: (props) => <AcceptanceQuotationsForm {...props} />,
    title: "modules.quotation.new_form.title",
    formWidth: "66rem",
  },
  sites_database: {
    form: SitesDatabaseForm,
    title: "modules.sites_database.new_form.title",
    formWidth: "52rem",
  },
  client_invoice: {
    form: ClientInvoiceForm,
    title: "modules.client_invoice.new_form.title",
    formWidth: "70rem",
  },
  purchase_order: {
    form: PurchaseOrderForm,
    title: "modules.purchase_order.new_form.title",
    formWidth: "35rem",
  },
  material_requests: {
    form: MaterialRequestsForm,
    title: "modules.material_request.new_form.title",
    formWidth: "62rem",
  },
  change_password: {
    form: ChangePasswordForm,
    title: "navbar.account_menu.change_password",
    formWidth: "35rem",
  },
  missions: {
    form: (props) => <MissionsForm {...props} />,
    title: "modules.missions.new_form.title",
    formWidth: "62rem",
  },
  delivery_note_items: {
    form: DeliveryNotesItemsForm,
    title: "modules.delivery_note_items.new_form.title",
    formWidth: "30rem",
  },
  warranty_visit: {
    form: WarrantyVisitForm,
    title: "modules.warranty_visit.new_form.title",
    formWidth: "62rem",
  },
  work_orders: {
    form: WorkOrdersForm,
    title: "modules.work_orders.new_form.title",
    formWidth: "30rem",
  },
  statements_management_tech_approval: {
    form: (props) => <NewStatementForm {...props} type="Technical Approval" />,
    title: "modules.statements_management.new_form.tech_approval.title",
    formWidth: "60rem",
  },
  statements_management_tech_approved: {
    form: (props) => <NewStatementForm {...props} type="Technical Approved" />,
    title: "modules.statements_management.new_form.tech_approved.title",
    formWidth: "60rem",
  },
  statements_management_price_confirmation: {
    form: (props) => <NewStatementForm {...props} type="Price Confirmation" />,
    title: "modules.statements_management.new_form.price_confirmation.title",
    formWidth: "60rem",
  },
  statements_management_ready_for_invoice: {
    form: (props) => <NewStatementForm {...props} type="Ready For Invoice" />,
    title: "modules.statements_management.new_form.ready_for_invoice.title",
    formWidth: "60rem",
  },
  statements_management_invoice: {
    form: (props) => <NewStatementForm {...props} type="Invoice" />,
    title: "modules.statements_management.new_form.invoice.title",
    formWidth: "60rem",
  },
};

const SideDrawer = () => {
  const [t] = useTranslation("global");

  const dispatch = useDispatch();
  const { sideDrawerState, newForm } = useSelector((state) => state.theme);

  const toggleDrawer = () => {
    dispatch(setSideDrawerState());
  };

  const handleCancel = () => {
    toggleDrawer();
    dispatch(setNewForm(null));
  };

  const Form = new_forms[newForm]?.form;

  return Form ? (
    <FormCard
      width={new_forms[newForm]?.formWidth || "50rem"}
      open={sideDrawerState}
      handleClose={toggleDrawer}
      handleCancel={handleCancel}
      title={t(new_forms[newForm]?.title)}
      content={(props) => <Form {...props} />}
    />
  ) : null;
};

export default SideDrawer;
