import { useState } from "react";
import { useSnackbar } from "notistack";
import Axios from "../../../network/Axios";
import { useParams } from "react-router-dom";
import { Tooltip, Box } from "@mui/material";
import { useTranslation } from "react-i18next";
import Spinner from "../../../components/Spinners/Spinner";
import PersonIcon from "@mui/icons-material/Person";
import WorkIcon from "@mui/icons-material/Work";
import CloseIcon from "@mui/icons-material/Close";
import Edit from "@mui/icons-material/Edit";
import WorkHistoryIcon from "@mui/icons-material/WorkHistory";
import EmployeeDetails from "./InfoModals/EmployeeDetails";
import AccountDetails from "./InfoModals/AccountDetails";
import { GridActionsCellItem } from "@mui/x-data-grid-pro";
import EditMail from "./InfoModals/EditMail";
import AddGroup from "./InfoModals/AddGroup";
import AddStore from "./InfoModals/AddStore";
import AddMail from "./InfoModals/AddMail";
import SimplePopup from "../../../components/PopUp/PopUp";
import InfoCard from "../../../components/InfoPagesCard/InfoCard";
import FormsDataGrid from "../../../components/DataGrid/FormsDataGrid";
import UseAsTemplate from "./InfoModals/UseAsTemplate/UseAsTemplate";
import ConfirmationInfoModal from "../../../components/Modals/ConfirmationInfoModal";
import { useQuery, useMutation, useQueryClient } from "@tanstack/react-query";

const UsersManagementInfo = () => {
  const [t] = useTranslation("global");
  const { userId } = useParams();
  const queryClient = useQueryClient();
  const { enqueueSnackbar } = useSnackbar();
  const [anchor, setAnchor] = useState(null);
  const [isEditable, setIsEditable] = useState(false);
  const [actionType, setActionType] = useState(null);
  const [idToDelete, setIdToDelete] = useState(null);
  const [selectedId, setSelectedId] = useState(null);
  const [confirmModal, setConfirmModal] = useState(false);
  const [addGroup, setAddGroup] = useState(false);
  const [addStore, setAddStore] = useState(false);
  const [addMail, setAddMail] = useState(false);
  const [useAsTemplateModal, setUseAsTemplateModal] = useState(false);

  const handleClick = (rowData, event = null) => {
    setSelectedId(rowData);
    setAnchor(anchor ? null : event ? event.currentTarget : null);
  };

  const open = Boolean(anchor);
  const id = open ? "simple-popup" : undefined;

  const getActionType = (modalType) => {
    switch (modalType) {
      case "confirmActive":
        return "active";
      case "confirmDeactive":
        return "deactive";
      case "confirmResetPassword":
        return "resetPassword";
      case "confirmDeleteGroup":
        return "deleteGroup";
      case "confirmDeleteMail":
        return "deleteMail";
      case "confirmDeleteStore":
        return "deleteStore";
      default:
        return null;
    }
  };

  const handleOpenModal = (modalType, id) => {
    switch (modalType) {
      case "useAsTemplate":
        setUseAsTemplateModal(true);
        break;
      case "addGroup":
        setAddGroup(true);
        break;
      case "addStore":
        setAddStore(true);
        break;
      case "addMail":
        setAddMail(true);
        break;
      case "confirmActive":
      case "confirmDeactive":
      case "confirmResetPassword":
      case "confirmDeleteGroup":
      case "confirmDeleteMail":
      case "confirmDeleteStore":
        setActionType(getActionType(modalType));
        setIdToDelete(id);
        setConfirmModal(true);
        break;
      default:
        break;
    }
  };

  const { data: usersManagementInfo, isLoading } = useQuery({
    queryKey: ["usersManagement", "info", userId],
    queryFn: async () => {
      const response = await Axios.get("/UsersManagement", {
        params: {
          SelectedNo: userId,
          State: "CardDetailed",
        },
      });
      return response.data;
    },
  });

  const { mutateAsync: handleActivate, isPending: activate } = useMutation({
    mutationFn: async () => {
      return Axios.patch("/UsersManagement", {
        ERPUserStatus:
          usersManagementInfo.result[0].ERPUserStatus === "Active"
            ? "Deactive"
            : "Active",
        SelectedNo: userId,
        State: "Activate",
      });
    },
    onSuccess: (data) => {
      if (!data) return;
      enqueueSnackbar(
        usersManagementInfo.result[0].ERPUserStatus === "Active"
          ? "Deactivated Successfully"
          : "Activated Successfully",
        {
          variant: "success",
        }
      );
      setConfirmModal(false);
      queryClient.invalidateQueries({
        queryKey: ["usersManagement", "info"],
      });
    },
  });

  const { mutateAsync: handleReset, isPending: reset } = useMutation({
    mutationFn: async () => {
      return Axios.patch("/UsersManagement", {
        SelectedNo: userId,
        State: "Password Reset",
      });
    },
    onSuccess: (data) => {
      if (!data) return;
      enqueueSnackbar("Password Reset Successfully", {
        variant: "success",
      });
      setConfirmModal(false);
      queryClient.invalidateQueries({
        queryKey: ["usersManagement", "info"],
      });
    },
  });
  const useUsers = (State, successMessage) => {
    const { mutateAsync, isPending } = useMutation({
      mutationFn: async () => {
        return Axios.patch("/UsersManagement", {
          id: idToDelete,
          State: State,
        });
      },
      onSuccess: (data) => {
        if (!data) return;
        enqueueSnackbar(successMessage, {
          variant: "success",
        });
        queryClient.invalidateQueries({
          queryKey: ["usersManagement", "info"],
        });
      },
    });

    return { mutateAsync, isPending };
  };

  const { mutateAsync: handleDeleteGroup, isPending: pendingDeleteGroup } =
    useUsers("DeleteGroups", "Group Deleted Successfully");

  const { mutateAsync: handleDeleteMail, isPending: pendingDeleteMail } =
    useUsers(
      "DeleteMailServicesList",
      "Mail Service List Deleted Successfully"
    );

  const { mutateAsync: handleDeleteStore, isPending: pendingDeleteStore } =
    useUsers("DeleteStoreProfile", "Store Deleted Successfully");

  const employeeInfoList = [
    {
      id: 0,
      icon: <WorkHistoryIcon />,
      data: usersManagementInfo?.result[0].EmpCode,
    },
    {
      id: 1,
      icon: <PersonIcon />,
      data: usersManagementInfo?.result[0].EmpName,
    },
    {
      id: 2,
      icon: <PersonIcon />,
      data: usersManagementInfo?.result[0].EmpNameEn,
    },
    {
      id: 3,
      icon: <WorkIcon />,
      data: usersManagementInfo?.result[0].EmpTitle,
    },
  ];
  const groupsColumns = [
    {
      field: "GroupsName",
      headerName: t("data_grid.headers.groups_name"),
      flex: 1,
    },
    {
      field: "Actions",
      type: "actions",
      headerName: t("data_grid.headers.actions"),
      getActions: (params) => [
        <Tooltip
          title={t("info_modules.users_management.tooltips.remove_access")}
          key="delete"
        >
          <GridActionsCellItem
            icon={<CloseIcon color="error" />}
            label={t("info_modules.users_management.tooltips.remove_access")}
            onClick={() =>
              handleOpenModal("confirmDeleteGroup", params.row.idUserGroups)
            }
          />
        </Tooltip>,
      ],
    },
  ];
  const mailColumns = [
    {
      field: "MailServicesName",
      headerName: t("data_grid.headers.mail_services_names"),
      flex: 1,
    },
    {
      field: "TOCCBCC",
      headerName: t("data_grid.headers.recipient_type"),
    },
    {
      field: "Actions",
      type: "actions",
      headerName: t("data_grid.headers.actions"),
      getActions: (params) => [
        <>
          <Tooltip
            title={t(
              "info_modules.users_management.tooltips.change_recipient_type"
            )}
            key="edit"
          >
            <GridActionsCellItem
              icon={<Edit />}
              label={t(
                "info_modules.users_management.tooltips.change_recipient_type"
              )}
              onClick={(event) => handleClick(params.row, event)}
            />
          </Tooltip>
          <Tooltip
            title={t(
              "info_modules.users_management.tooltips.remove_mail_notification"
            )}
            key="delete"
          >
            <GridActionsCellItem
              icon={<CloseIcon color="error" />}
              label={t(
                "info_modules.users_management.tooltips.remove_mail_notification"
              )}
              onClick={() =>
                handleOpenModal(
                  "confirmDeleteMail",
                  params.row.idMailServicesMailingList
                )
              }
            />
          </Tooltip>
        </>,
      ],
    },
  ];

  const storeColumns = [
    {
      field: "CompanyStoresName",
      headerName: t("data_grid.headers.store_name"),
      flex: 1,
    },
    {
      field: "Actions",
      type: "actions",
      headerName: t("data_grid.headers.actions"),
      getActions: (params) => [
        <Tooltip
          title={t("info_modules.users_management.tooltips.remove_access")}
          key="delete"
        >
          <GridActionsCellItem
            icon={<CloseIcon color="error" />}
            label={t("info_modules.users_management.tooltips.remove_access")}
            onClick={() =>
              handleOpenModal("confirmDeleteStore", params.row.IdStoreProfile)
            }
          />
        </Tooltip>,
      ],
    },
  ];
  const actionTypeMap = {
    active: {
      onClick: handleActivate,
      description: t("info_modules.users_management.confirmation.active"),
      isPending: activate,
    },
    deactive: {
      onClick: handleActivate,
      description: t("info_modules.users_management.confirmation.deactive"),
      isPending: activate,
    },
    resetPassword: {
      onClick: handleReset,
      description: t("info_modules.users_management.confirmation.reset"),
      isPending: reset,
    },
    deleteGroup: {
      onClick: handleDeleteGroup,
      description: t("info_modules.users_management.confirmation.delete_group"),
      isPending: pendingDeleteGroup,
    },
    deleteMail: {
      onClick: handleDeleteMail,
      description: t("info_modules.users_management.confirmation.delete_mail"),
      isPending: pendingDeleteMail,
    },
    deleteStore: {
      onClick: handleDeleteStore,
      description: t("info_modules.users_management.confirmation.delete_store"),
      isPending: pendingDeleteStore,
    },
  };

  return (
    <>
      {isLoading ? (
        <Spinner />
      ) : (
        <>
          <SimplePopup
            id={id}
            open={open}
            anchor={anchor}
            onClose={() => setAnchor(null)}
            content={
              <EditMail
                label={selectedId?.MailServicesName}
                setAnchor={setAnchor}
                mailType={selectedId?.TOCCBCC}
                selectedId={selectedId}
              />
            }
          />
          <Box display="flex" flexDirection="column" gap={1.5}>
            <Box display="grid" gridTemplateColumns="1fr 3.5fr" gap={1.5}>
              <Box>
                <EmployeeDetails
                  data={usersManagementInfo.result[0]}
                  employeeInfoList={employeeInfoList}
                />
              </Box>
              <Box gridColumn="span -2">
                <InfoCard
                  title={t(
                    "info_modules.users_management.description.account_details"
                  )}
                  editButton
                  onClickEdit={() => setIsEditable(true)}
                  content={
                    <AccountDetails
                      isEditable={isEditable}
                      setIsEditable={setIsEditable}
                      usersManagementInfo={usersManagementInfo}
                      handleOpenModal={handleOpenModal}
                      setUseAsTemplateModal={setUseAsTemplateModal}
                    />
                  }
                />
              </Box>
            </Box>
            <Box display="flex" gap={1.2}>
              <InfoCard
                title={t("info_modules.users_management.description.groups")}
                addIcon
                onClick={() => setAddGroup(true)}
                content={
                  <FormsDataGrid
                    rows={usersManagementInfo?.UserGroups1 || []}
                    columns={groupsColumns}
                    getRowId={(row) => row.idUserGroups}
                    height={230}
                    hideFooter
                  />
                }
              />
              <InfoCard
                title={t(
                  "info_modules.users_management.description.mail_services"
                )}
                addIcon
                onClick={() => setAddMail(true)}
                content={
                  <FormsDataGrid
                    rows={usersManagementInfo?.UserMailServices1 || []}
                    columns={mailColumns}
                    getRowId={(row) => row.idMailServicesMailingList}
                    height={230}
                    hideFooter
                  />
                }
              />
              <InfoCard
                title={t("info_modules.users_management.description.stores")}
                addIcon
                onClick={() => setAddStore(true)}
                content={
                  <FormsDataGrid
                    rows={usersManagementInfo?.UserStores1 || []}
                    columns={storeColumns}
                    getRowId={(row) => row.IdStoreProfile}
                    height={230}
                    hideFooter
                  />
                }
              />
            </Box>
          </Box>
          <ConfirmationInfoModal
            open={confirmModal}
            handleClose={() => setConfirmModal(false)}
            onClick={actionTypeMap[actionType]?.onClick}
            description={actionTypeMap[actionType]?.description}
            isPending={actionTypeMap[actionType]?.isPending}
            color="error"
          />
          <UseAsTemplate
            open={useAsTemplateModal}
            handleClose={() => setUseAsTemplateModal(false)}
          />
          <AddGroup open={addGroup} handleClose={() => setAddGroup(false)} />
          <AddStore open={addStore} handleClose={() => setAddStore(false)} />
          <AddMail open={addMail} handleClose={() => setAddMail(false)} />
        </>
      )}
    </>
  );
};
export default UsersManagementInfo;
