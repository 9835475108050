import { useState } from "react";
import { Box, Tooltip } from "@mui/material";
import DateFormatGrid from "../../../components/DateFormatGrid";
import { useQuery, useMutation, useQueryClient } from "@tanstack/react-query";
import { useParams } from "react-router-dom";
import Axios from "../../../network/Axios";
import Spinner from "../../../components/Spinners/Spinner";
import FormsDataGrid from "../../../components/DataGrid/FormsDataGrid";
import { GridActionsCellItem } from "@mui/x-data-grid-pro";
import { useTranslation } from "react-i18next";
import DeleteIcon from "@mui/icons-material/Delete";
import Edit from "@mui/icons-material/Edit";
import InfoCard from "../../../components/InfoPagesCard/InfoCard";
import CloseMission from "./InfoModals/CloseMission";
import AddEmployees from "./InfoModals/AddEmployees";
import AddCars from "./InfoModals/AddCars";
import { useSnackbar } from "notistack";
import ConfirmationInfoModal from "../../../components/Modals/ConfirmationInfoModal";
import SimplePopup from "../../../components/PopUp/PopUp";
import EditEmployee from "./InfoModals/EditEmployee";
import MissionCard from "./InfoModals/MissionCard";
import WorkOrderCard from "./InfoModals/WorkOrderCard";
import { useSelector } from "react-redux";
import ChangeRequestForm from "../../../components/forms/ChangeRequestForm";

const MissionInfo = () => {
  const { missionId } = useParams();
  const queryClient = useQueryClient();
  const [t] = useTranslation("global");
  const { enqueueSnackbar } = useSnackbar();
  const { language } = useSelector((state) => state.theme);
  const [closeMissionModal, setCloseMissionModal] = useState(false);
  const [confirmModal, setConfirmModal] = useState(false);
  const [changeMissionModal, setChangeMissionModal] = useState(false);
  const [addEmployeeModal, setAddEmployeeModal] = useState(false);
  const [addCarModal, setAddCarModal] = useState(false);
  const [actionType, setActionType] = useState(null);
  const [idToDelete, setIdToDelete] = useState(null);
  const [anchor, setAnchor] = useState(null);
  const [selectedId, setSelectedId] = useState(null);
  const [isCar, setIsCar] = useState(false);
  const [isEditable, setIsEditable] = useState(false);

  const handleClick = (rowData, event = null) => {
    setSelectedId(rowData);
    setIsCar(rowData?.CarsNumber !== undefined);
    setAnchor(anchor ? null : event ? event.currentTarget : null);
  };
  const open = Boolean(anchor);
  const id = open ? "simple-popup" : undefined;

  const getActionType = (modalType) => {
    switch (modalType) {
      case "confirmDeleteEmployee":
        return "deleteEmployee";
      case "confirmDeleteCar":
        return "deleteCar";
      case "confirmResetDateEmp":
        return "resetDateEmp";
      case "confirmResetDateCar":
        return "resetDateCar";
      default:
        return null;
    }
  };

  const handleOpenModal = (modalType, id) => {
    switch (modalType) {
      case "cancelMission":
        setActionType("cancel");
        setConfirmModal(true);
        break;
      case "closeMission":
        setCloseMissionModal(true);
        break;
      case "changeMission":
        setChangeMissionModal(true);
        break;
      case "addEmployee":
        setAddEmployeeModal(true);
        break;
      case "addCar":
        setAddCarModal(true);
        break;
      case "confirmDeleteEmployee":
      case "confirmDeleteCar":
      case "confirmResetDateEmp":
      case "confirmResetDateCar":
        setActionType(getActionType(modalType));
        setIdToDelete(id);
        setConfirmModal(true);
        break;
      default:
        break;
    }
  };
  const handleEditMission = () => {
    setIsEditable(true);
  };

  const { data: missionInfo, isLoading } = useQuery({
    queryKey: ["missionsInfo", missionId],
    queryFn: async () => {
      const response = await Axios.get("/Missions", {
        params: {
          SelectedNo: missionId,
          State: "Card",
        },
      });
      return response.data;
    },
  });
  const { mutateAsync: handleDelete, isPending: deleteRow } = useMutation({
    mutationFn: async (state) => {
      return Axios.put("/Missions", {
        State: state,
        SelectedID: idToDelete,
        SelectedNo: missionId,
      });
    },
    onSuccess: (data) => {
      if (!data) return;
      enqueueSnackbar(
        t("info_modules.missions.description.deleted_successfully"),
        {
          variant: "success",
        }
      );
      queryClient.invalidateQueries({
        queryKey: ["missionsInfo", missionId],
      });
    },
  });
  const { mutateAsync: handleReset, isPending: reset } = useMutation({
    mutationFn: async (state) => {
      return Axios.put("/Missions", {
        State: state,
        SelectedNo: missionId,
      });
    },
    onSuccess: (data) => {
      if (!data) return;
      enqueueSnackbar("Date Reset Successfully", {
        variant: "success",
      });
      queryClient.invalidateQueries({
        queryKey: ["missionsInfo", missionId],
      });
    },
  });

  const handleResetEmp = () => {
    handleReset("ResetEmpDate");
  };

  const handleResetCar = () => {
    handleReset("ResetCarDate");
  };

  const handleDeleteEmployee = () => {
    handleDelete("DeleteEmployees");
  };

  const handleDeleteCar = () => {
    handleDelete("DeleteCars");
  };

  const { mutateAsync: cancelMission, isPending: cancel } = useMutation({
    mutationFn: async () => {
      return Axios.patch("/Missions", {
        SelectedNo: missionId,
        Status: "Cancelled",
      });
    },
    onSuccess: (data) => {
      if (!data) return;
      enqueueSnackbar(
        t("info_modules.missions.description.mission_cancelled"),
        {
          variant: "success",
        }
      );
      queryClient.invalidateQueries({
        queryKey: ["missionsInfo", missionId],
      });
    },
  });

  const employeeColumns = [
    {
      field: "EmpCode",
      headerName: t("data_grid.headers.emp_code"),
      width: 140,
    },
    {
      field: language === "EN" ? "EmpNameEn" : "EmpName",
      headerName: t("data_grid.headers.emp_name"),
      width: 140,
      flex: 1,
    },
    {
      field: "EmpTitle",
      headerName: t("data_grid.headers.emp_title"),
      width: 140,
    },
    {
      field: "MissionEmpStart",
      headerName: t("data_grid.headers.start_date"),
      width: 160,
      renderCell: ({ value }) => {
        return <> {value ? <DateFormatGrid value={value} /> : <> </>}</>;
      },
    },
    {
      field: "MissionEmpEnd",
      headerName: t("data_grid.headers.end_date"),
      width: 160,
      renderCell: ({ value }) => {
        return <> {value ? <DateFormatGrid value={value} /> : <> </>}</>;
      },
    },
  ];
  const carsColumns = [
    {
      field: "CarsNumber",
      headerName: t("data_grid.headers.cars"),
      width: 120,
    },
    {
      field: "CarsType",
      headerName: t("data_grid.headers.type"),
      width: 120,
    },
    {
      field: "EmpCode",
      headerName: t("data_grid.headers.emp_code"),
      width: 120,
    },
    {
      field: language === "EN" ? "EmpNameEn" : "EmpName",
      headerName: t("data_grid.headers.driver_name"),
      width: 130,
      flex: 1,
    },
    {
      field: "EmpTitle",
      headerName: t("data_grid.headers.title"),
      width: 130,
    },
    {
      field: "MissionCarStart",
      headerName: t("data_grid.headers.start_date"),
      width: 160,
      renderCell: ({ value }) => {
        return <> {value ? <DateFormatGrid value={value} /> : <> </>}</>;
      },
    },
    {
      field: "MissionCarEnd",
      headerName: t("data_grid.headers.end_date"),
      width: 160,
      renderCell: ({ value }) => {
        return <> {value ? <DateFormatGrid value={value} /> : <> </>}</>;
      },
    },
  ];
  if (missionInfo?.result[0]?.Status === "In Progress") {
    employeeColumns.push({
      field: "Actions",
      type: "actions",
      headerName: t("data_grid.headers.actions"),
      width: 90,
      getActions: (params) => [
        <Tooltip title={t("tooltip.edit")} key={params.id}>
          <GridActionsCellItem
            icon={<Edit />}
            label="Edit"
            onClick={(event) => handleClick(params.row, event)}
          />
        </Tooltip>,
        <Tooltip title={t("tooltip.delete")} key={params.id}>
          <GridActionsCellItem
            icon={
              <DeleteIcon
                color={missionInfo?.Employees.length === 1 ? "" : "error"}
              />
            }
            label="Delete"
            disabled={missionInfo?.Employees.length === 1}
            onClick={() =>
              handleOpenModal("confirmDeleteEmployee", params.row.idMissionEmp)
            }
          />
        </Tooltip>,
      ],
    });
    carsColumns.push({
      field: "Actions",
      type: "actions",
      headerName: t("data_grid.headers.actions"),
      width: 110,
      getActions: (params) => [
        <Tooltip title={t("tooltip.edit")} key={params.id}>
          <GridActionsCellItem
            icon={<Edit />}
            label="Edit"
            onClick={(event) => handleClick(params.row, event)}
          />
        </Tooltip>,
        <Tooltip title={t("tooltip.delete")} key={params.id}>
          <GridActionsCellItem
            icon={<DeleteIcon color="error" />}
            label="Delete"
            onClick={() =>
              handleOpenModal("confirmDeleteCar", params.row.idMissionCars)
            }
          />
        </Tooltip>,
      ],
    });
  }
  const actionTypeMap = {
    cancel: {
      onClick: cancelMission,
      description: t("info_modules.missions.description.confirmation_cancel"),
      isPending: cancel,
    },
    deleteEmployee: {
      onClick: handleDeleteEmployee,
      description: t(
        "info_modules.missions.description.delete_employee_confirmation"
      ),
      isPending: deleteRow,
    },
    deleteCar: {
      onClick: handleDeleteCar,
      description: t(
        "info_modules.missions.description.delete_car_confirmation"
      ),
      isPending: deleteRow,
    },
    resetDateEmp: {
      onClick: handleResetEmp,
      description: t("info_modules.missions.description.reset_emp_date"),
      isPending: reset,
    },
    resetDateCar: {
      onClick: handleResetCar,
      description: t("info_modules.missions.description.reset_car_date"),
      isPending: reset,
    },
  };
  return (
    <>
      {isLoading ? (
        <Spinner />
      ) : (
        <>
          <SimplePopup
            id={id}
            open={open}
            anchor={anchor}
            onClose={() => setAnchor(null)}
            content={
              <EditEmployee
                missionStart={
                  isCar
                    ? selectedId?.MissionCarStart
                    : selectedId?.MissionEmpStart
                }
                empName={isCar ? selectedId?.CarsNumber : selectedId?.EmpName}
                missionEnd={
                  isCar ? selectedId?.MissionCarEnd : selectedId?.MissionEmpEnd
                }
                isCar={isCar}
                selectedId={selectedId}
                setAnchor={setAnchor}
              />
            }
          />
          <Box
            display="flex"
            flexDirection="column"
            gap={1.5}
            marginBottom={2}
            // width="99%"
          >
            <InfoCard
              title={t("info_modules.missions.description.mission_details")}
              editButton={missionInfo?.Actions.includes("Edit Details")}
              onClickEdit={handleEditMission}
              content={
                <MissionCard
                  missionInfo={missionInfo}
                  handleOpenModal={handleOpenModal}
                  isEditable={isEditable}
                  setIsEditable={setIsEditable}
                />
              }
            />
            <InfoCard
              title={t("info_modules.missions.description.work_order_details")}
              content={<WorkOrderCard missionInfo={missionInfo} />}
            />
            <InfoCard
              title={t("info_modules.missions.description.employees_missions")}
              addIcon={missionInfo?.Actions.includes("Edit Details")}
              addIconLabel={t("info_modules.missions.fields.add_employee")}
              resetButton={missionInfo?.Actions.includes("Edit Details")}
              onClick={() => handleOpenModal("addEmployee")}
              onClickReset={() => handleOpenModal("confirmResetDateEmp")}
              content={
                <Box>
                  <FormsDataGrid
                    rows={missionInfo?.Employees}
                    columns={employeeColumns}
                    getRowId={(row) => row.idMissionEmp}
                    hideFooter
                  />
                </Box>
              }
            />
            <InfoCard
              title={t("info_modules.missions.description.cars_missions")}
              addIcon={missionInfo?.Actions.includes("Edit Details")}
              addIconLabel={t("info_modules.missions.fields.add_car")}
              resetButton={missionInfo?.Actions.includes("Edit Details")}
              onClick={() => handleOpenModal("addCar")}
              onClickReset={() => handleOpenModal("confirmResetDateCar")}
              content={
                <FormsDataGrid
                  rows={missionInfo?.Cars}
                  columns={carsColumns}
                  getRowId={(row) => row.idMissionCars}
                  hideFooter
                  height={missionInfo?.Cars.length > 0 ? "auto" : 100}
                />
              }
            />
          </Box>
          <ConfirmationInfoModal
            open={confirmModal}
            handleClose={() => setConfirmModal(false)}
            onClick={actionTypeMap[actionType]?.onClick}
            description={actionTypeMap[actionType]?.description}
            isPending={actionTypeMap[actionType]?.isPending}
            color="error"
          />
          <CloseMission
            open={closeMissionModal}
            handleClose={() => setCloseMissionModal(false)}
            missionNo={missionInfo?.result[0].MissionNo}
            missionStart={missionInfo?.result[0].MissionStart}
          />

          <ChangeRequestForm
            open={changeMissionModal}
            handleClose={() => setChangeMissionModal(false)}
            requestId={missionInfo?.result[0].MissionNo}
            apiEndpoint={"/MissionChangeRequests"}
            radioBtnLabel1={t("info_modules.missions.description.edit_mission")}
            radioBtnLabel2={t(
              "info_modules.missions.description.cancel_mission"
            )}
            queryKey={["missionsInfo"]}
            titleTranslationKey={t(
              "info_modules.missions.description.mission_change_description"
            )}
            successMessage={"Mission Change Requested Successfully"}
            width="30rem"
          />
          <AddEmployees
            open={addEmployeeModal}
            handleClose={() => setAddEmployeeModal(false)}
            missionStart={missionInfo?.result[0].MissionStart}
            missionEnd={missionInfo?.result[0].MissionEnd}
          />
          <AddCars
            open={addCarModal}
            handleClose={() => setAddCarModal(false)}
            missionStart={missionInfo?.result[0].MissionStart}
            missionEnd={missionInfo?.result[0].MissionEnd}
          />
        </>
      )}
    </>
  );
};

export default MissionInfo;
