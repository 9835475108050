import dayjs from "dayjs";
import { useState } from "react";
import Axios from "../network/Axios";
import { Tooltip } from "@mui/material";
import { useSnackbar } from "notistack";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import { statusColors } from "./statuses";
import Edit from "@mui/icons-material/Edit";
import Image from "@mui/icons-material/Image";
import { useTranslation } from "react-i18next";
import ChipStatus from "../components/ChipStatus";
import useImagePopup from "../hooks/useImagePopup";
import DeleteIcon from "@mui/icons-material/Delete";
import Spinner from "../components/Spinners/Spinner";
import useOpenNewForm from "../hooks/useOpenNewForm";
import InfoModal from "../components/Modals/InfoModal";
import ImageModal from "../components/Modals/ImageModal";
import { GridActionsCellItem } from "@mui/x-data-grid-pro";
import CustomDataGrid from "../components/DataGrid/CustomDataGrid";
import { vacationsRequestsService } from "../api/vacationsRequests";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { useConfirmationDialog } from "../contexts/ConfirmationDialogContext";
import EditVacationRequest from "./InformationPages/EmployeeRecords/cards/Vacations/NewEditForm/EditVacationRequest";

const Vacations = () => {
  const { enqueueSnackbar } = useSnackbar();
  const queryClient = useQueryClient();
  const [t] = useTranslation("global");

  const { newForm } = useSelector((state) => state.theme);

  const { startDateVacation, endDateVacation } = useSelector(
    (state) => state.dateRangeVacation
  );

  // Handling the vacation edit modal
  const [isModalOpen, setIsModalOpen] = useState(false);

  const handleOpenModal = () => setIsModalOpen(true);
  const handleCloseModal = () => setIsModalOpen(false);

  // VacationID state
  const [vacationID, setVacationID] = useState(null);

  const statusNames = [
    { name: "Regular", displayName: t("data_grid.tabs.regular") },
    { name: "Absence", displayName: t("data_grid.tabs.absence") },
    { name: "Instead Of", displayName: t("data_grid.tabs.instead_of") },
    { name: "Sick Leave", displayName: t("data_grid.tabs.sick_leave") },
    { name: "Formal", displayName: t("data_grid.tabs.formal") },
    { name: "Casual", displayName: t("data_grid.tabs.casual") },
  ];

  // Handle image modal save label
  const [saveLabel, setSaveLabel] = useState("");

  const columns = [
    {
      field: "EmpCode",
      headerName: t("data_grid.headers.emp_code"),
      width: 110,
      renderCell: (params) => {
        return (
          <Link
            style={{
              color: "#1790FF",
            }}
            to={`/employeerecords/${params.value.replace("/", "%2F")}`}
            target="_blank"
          >
            {params.value}
          </Link>
        );
      },
    },
    {
      field: "EmpNameEn",
      headerName: t("data_grid.headers.employee_name_en"),
      width: 110,
      flex: 1,
    },
    {
      field: "EmpName",
      headerName: t("data_grid.headers.employee_name_ar"),
      width: 100,
      flex: 1,
    },
    {
      field: "EmpTitle",
      headerName: t("data_grid.headers.emp_title"),
      width: 100,
      flex: 1,
    },
    {
      field: "VacationStartDate",
      headerName: t("data_grid.headers.start_date"),
      width: 90,
      flex: 1,
      type: "date",
      valueGetter: ({ value }) => (value ? new Date(value) : null),
      renderCell: ({ value }) => {
        return value ? dayjs(value).format("YYYY/MM/DD") : "";
      },
    },
    {
      field: "VacationEndDate",
      headerName: t("data_grid.headers.end_date"),
      minwidth: 90,
      flex: 1,
      type: "date",
      valueGetter: ({ value }) => (value ? new Date(value) : null),
      renderCell: ({ value }) => {
        return value ? dayjs(value).format("YYYY/MM/DD") : "";
      },
    },
    {
      field: "VacationsTypesName",
      headerName: t("data_grid.headers.type"),
      width: 130,
      renderCell: ({ value }) => {
        return <ChipStatus value={value} />;
      },
    },
    {
      field: "Actions",
      type: "actions",
      headerName: t("data_grid.headers.actions"),
      getActions: (params) => [
        <Tooltip title={t("tooltip.view")} key="image">
          <GridActionsCellItem
            icon={<Image />}
            label="Image"
            onClick={() => {
              setSaveLabel(
                `${params.row.EmpCode.replace("/", "-")}-${
                  params.row.VacationsTypesName
                }-${params.row.VacationStartDate}`
              );
              handleImageModalOpen(params.row.idEmpVacation);
            }}
            disabled={params.row.VacationAttachementStatus === "False"}
          />
        </Tooltip>,
        <Tooltip title={t("tooltip.edit")} key="edit">
          <GridActionsCellItem
            icon={<Edit />}
            label="Edit"
            onClick={() => {
              setVacationID(params.row.idEmpVacation);
              handleOpenModal();
            }}
          />
        </Tooltip>,
        <Tooltip title={t("tooltip.delete")} key="delete">
          <GridActionsCellItem
            icon={<DeleteIcon color="error" />}
            label="Delete"
            onClick={() => {
              setOpen(
                t("info_modules.employee_records.confirmation.delete_record"),
                () => () => {
                  deleteVacationRecord(params.row.idEmpVacation);
                },
                "error"
              );
            }}
          />
        </Tooltip>,
      ],
    },
  ];

  const { mutateAsync: deleteVacationRecord } = useMutation({
    mutationFn: vacationsRequestsService.deleteVacationRecord,
    onSuccess: (data) => {
      enqueueSnackbar(data.msg, { variant: "success" });
      queryClient.invalidateQueries({
        predicate: (query) => {
          return [
            "employeeHistory",
            "attendance",
            "vacationsRequests",
          ].includes(query.queryKey[0]);
        },
      });
    },
  });

  const fetchData = async (id) => {
    try {
      const response = await Axios.get("/VacationRequests", {
        params: {
          SelectedID: id,
        },
      });
      return response.data.result;
    } catch (error) {
      console.log(error);
    }
  };

  const {
    isImageLoading,
    isImageModalOpen,
    image,
    handleImageModalOpen,
    handleCloseImageModal,
  } = useImagePopup(fetchData, "VacationAttachement");

  const {
    data: rows,
    isLoading,
    refetch,
    isRefetching,
  } = useQuery({
    queryKey: ["vacationsRequests"],
    queryFn: async () => {
      const response = await Axios.get("/VacationRequests", {
        params: {
          Start: dayjs(startDateVacation).format("YYYY-MM-DD"),
          End: dayjs(endDateVacation).format("YYYY-MM-DD"),
        },
      });
      return response.data.result.map((row, index) => ({
        id: index + 1,
        ...row,
      }));
    },
  });

  // Handling new form logic
  const { handleOpen } = useOpenNewForm();
  const { setOpen } = useConfirmationDialog();

  if (isLoading) {
    return <Spinner />;
  }

  return (
    <>
      <CustomDataGrid
        rows={rows}
        columns={columns}
        loading={isLoading}
        statusColors={statusColors}
        statusNames={statusNames}
        filterField="VacationsTypesName"
        reloadFunction={refetch}
        showReloadOverlay={isRefetching}
        showDatePicker
        attendanceDate={false}
        vacationDate
        mailServiceDate={false}
        newButton
        newFunction={() => {
          if (newForm === "vacation_requests" || newForm === null) {
            handleOpen("vacation_requests");
          } else {
            setOpen(t("modules.new_form_confirmation_message"), () => () => {
              handleOpen("vacation_requests");
            });
          }
        }}
      />
      <ImageModal
        open={isImageModalOpen}
        handleClose={handleCloseImageModal}
        image={image}
        loading={isImageLoading}
        saveLabel={saveLabel}
        title={saveLabel}
      />
      <InfoModal
        open={isModalOpen}
        title={t(
          "info_modules.employee_records.cards.vacations.edit_form.title"
        )}
        content={
          <EditVacationRequest
            handleClose={handleCloseModal}
            vacationID={vacationID}
          />
        }
        handleClose={handleCloseModal}
        width="30rem"
      />
    </>
  );
};

export default Vacations;
