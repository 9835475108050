import { Box, Grid } from "@mui/material";
import ChipComponent from "../../../../components/Chips/ChipComponent";
import { useTranslation } from "react-i18next";
import ChipStatus from "../../../../components/ChipStatus";
import EastIcon from "@mui/icons-material/East";
import CheckIcon from "@mui/icons-material/Check";
import CloseIcon from "@mui/icons-material/Close";
import AddIcon from "@mui/icons-material/Add";
import ActionButton from "../../../../components/forms/ButtonGroup/ActionButton";
import ActionMenuButton from "../../../../components/forms/ButtonGroup/ActionMenuButton";

const PurchaseCard = ({ purchaseRequestInfo, handleOpenModal }) => {
  const [t] = useTranslation("global");
  const purchaseProperty = [
    {
      label: t("data_grid.headers.request_no"),
      value: purchaseRequestInfo?.result[0]?.MaterialsPurchaseRequestNo,
    },
    {
      label: t("data_grid.headers.creation_date"),
      value: purchaseRequestInfo?.result[0]?.ActionDate,
    },
    {
      label: t("data_grid.headers.requested_by"),
      value: purchaseRequestInfo?.result[0]?.ERPUserNickName,
    },
    {
      label: t("data_grid.headers.status"),
      value: (
        <ChipStatus
          value={purchaseRequestInfo?.result[0]?.MaterialsPurchaseRequestStatus}
        />
      ),
    },
  ];

  const actions =
    purchaseRequestInfo.Actions.filter(
      (action) => action !== "Delete" && action !== "Edit Details"
    ) || [];

  const actionsConfig = {
    Request: {
      label: t("info_modules.material_request.buttons.request"),
      icon: <EastIcon sx={{ width: "1.1rem", height: "1.1rem" }} />,
      color: "success",
      onClick: () => handleOpenModal("confirmationRequest"),
    },
    Approve: {
      label: t("info_modules.purchase_request.buttons.approve"),
      icon: <CheckIcon sx={{ width: "1.1rem", height: "1.1rem" }} />,
      color: "success",
      onClick: () => handleOpenModal("confirmationApprove"),
    },
    Reject: {
      label: t("info_modules.purchase_request.buttons.reject"),
      icon: <CloseIcon sx={{ width: "1.1rem", height: "1.1rem" }} />,
      color: "error",
      onClick: () => handleOpenModal("confirmationReject"),
    },
    Add: {
      label: t("info_modules.purchase_request.buttons.add"),
      icon: <AddIcon sx={{ width: "1.1rem", height: "1.1rem" }} />,
      color: "primary",
      onClick: () => handleOpenModal("add"),
    },
  };
  return (
    <Box>
      {actions.length > 0 && (
        <Box display="flex" justifyContent="flex-end" marginBottom="4px">
          {actions.length > 1 ? (
            <ActionMenuButton actions={actions} actionsConfig={actionsConfig} />
          ) : (
            <ActionButton
              icon={actionsConfig[actions[0]].icon}
              text={actionsConfig[actions[0]].label}
              onClick={actionsConfig[actions[0]].onClick}
              color={actionsConfig[actions[0]].color}
            />
          )}
        </Box>
      )}
      <Grid container spacing={1}>
        {purchaseProperty.map((item, index) => (
          <Grid item xs={4} key={index}>
            <ChipComponent label={item.label} value={item.value} />
          </Grid>
        ))}
      </Grid>
    </Box>
  );
};
export default PurchaseCard;
