import { useState } from "react";
import { useSnackbar } from "notistack";
import { useTranslation } from "react-i18next";
import { Autocomplete, Box, Chip, TextField } from "@mui/material";
import ButtonGroup from "../../../../components/forms/ButtonGroup/ButtonGroup";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import Axios from "../../../../network/Axios";
import FormSpinner from "../../../../components/Spinners/FormSpinner";
import { useParams } from "react-router-dom";

const ChangeD6Type = ({ quotationD6Type, handleClose }) => {
  const { quotationId } = useParams();

  const { data, isLoading, isRefetching } = useQuery({
    queryKey: ["quotationD6Types"],
    queryFn: async () => {
      const response = await Axios.get("/Quotations", {
        params: {
          State: "GetChangeD6Type",
          SelectedNo: quotationId,
        },
      });
      return response.data.WorkOrderD6TypeList;
    },
  });

  if (isLoading || isRefetching) return <FormSpinner />;

  return (
    <ChangeD6TypeContent
      data={data}
      handleClose={handleClose}
      quotationId={quotationId}
      quotationD6Type={quotationD6Type}
    />
  );
};

const ChangeD6TypeContent = ({
  data,
  handleClose,
  quotationId,
  quotationD6Type,
}) => {
  const [t] = useTranslation("global");
  const queryClient = useQueryClient();
  const { enqueueSnackbar } = useSnackbar();

  const d6Types = data.map((item) => item.WorkOrderD6TypeName);

  const [d6Type, setD6Type] = useState(quotationD6Type);

  const { mutateAsync: changeD6Type, isPending } = useMutation({
    mutationFn: async () => {
      const res = await Axios.patch("/Quotations", {
        State: "UpdateWorkOrderD6Type",
        SelectedNo: quotationId,
        WorkOrderD6TypeName: d6Type,
      });

      return res.data;
    },
    onSuccess: (data) => {
      enqueueSnackbar(data.msg, {
        variant: "success",
      });
      queryClient.invalidateQueries({
        queryKey: ["quotation"],
      });

      handleClose();
    },
  });

  const isSubmitDisabled = !d6Types.includes(d6Type);

  return (
    <Box padding={1} display="flex" flexDirection="column" gap={1.6}>
      <Chip label={quotationId} sx={{ borderRadius: 1, fontSize: "0.9rem" }} />
      <Autocomplete
        disableClearable
        value={d6Type}
        id="combo-box-demo"
        options={d6Types || []}
        getOptionLabel={(option) => option}
        sx={{ width: "27rem" }}
        size="small"
        onChange={(event, value) => {
          setD6Type(value);
        }}
        renderInput={(params) => (
          <TextField
            {...params}
            label={t("info_modules.quotation.description.d6_types")}
            error={isSubmitDisabled}
          />
        )}
      />
      <ButtonGroup
        onClickClose={handleClose}
        isPending={isPending}
        disabled={isSubmitDisabled}
        onClickSave={() => changeD6Type()}
        saveLabel={t("modules.buttons.save")}
      />
    </Box>
  );
};

export default ChangeD6Type;
