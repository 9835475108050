import { TextField, styled } from "@mui/material";

export const formatCurrency = (amount, locale = "en-EG", currency = "EGP") => {
  if (amount === 0 || amount === null) {
    return "EGP 0.0";
  }
  return (amount ?? 0).toLocaleString(locale, {
    style: "currency",
    currency: currency,
  });
};

export const generateId = () => {
  let nextId = 0;
  return () => nextId++;
};

export const generateIdNum = () => {
  let nextId = 0;
  return () => nextId++;
};

export const StyledTextField = styled((props) => (
  <TextField
    size="small"
    InputLabelProps={{
      sx: {
        "&.Mui-error:not(.Mui-focused)": {
          transform: "translate(10px, 5px) scale(.75)",
        },
      },
    }}
    {...props}
  />
))({
  width: "13.5rem",
  "& .MuiInputBase-input": {
    height: "10px",
    fontSize: "0.85rem",
    fontWeight: 600,
  },
});
export const autoCompleteStyles = {
  width: "100%",
  "&:not(.Mui-focused) label.Mui-error": {
    transform: "translate(10px, -8px) scale(0.75)",
    backgroundColor: "background.default",
    padding: "0 5px",
  },
};
