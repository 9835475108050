import { useState } from "react";
import { convertImage } from "../utils/utils";

const useEmployeeImagePopup = () => {
  const [isImageModalOpen, setIsImageModalOpen] = useState(false);
  const [isImageLoading, setIsImageLoading] = useState(false);

  const [image, setImage] = useState(null);

  const handleImageModalOpen = async (fetchData, imageAttachment, id) => {
    setIsImageModalOpen(true);
    setIsImageLoading(true);

    fetchData(id)
      .then((data) => {
        const convertedImage = convertImage(data[0]?.[imageAttachment]?.data);
        setImage(convertedImage);
      })
      .finally(() => {
        setIsImageLoading(false);
      });
  };

  const handleCloseImageModal = () => {
    setIsImageModalOpen(false);
  };

  return {
    isImageLoading,
    image,
    handleImageModalOpen,
    handleCloseImageModal,
    isImageModalOpen,
  };
};

export default useEmployeeImagePopup;
