import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { useTranslation } from "react-i18next";
import * as yup from "yup";
import { useSnackbar } from "notistack";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFnsV3";
import { Autocomplete, Box, Stack, TextField } from "@mui/material";
import dayjs from "dayjs";
import { useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import ButtonGroup from "../../../../../components/forms/ButtonGroup/ButtonGroup";
import { employeeRecordsService } from "../../../../../api/employeeRecords";
import FormSpinner from "../../../../../components/Spinners/FormSpinner";
import ImagePickerBox from "../../../../../components/forms/CompanyFleet/ImagePickerBox";
import { useParams } from "react-router-dom";
import {
  MAX_FILE_SIZE,
  supportedImageTypes,
} from "../../../../../constants/constants";

const NewDocument = ({ handleClose }) => {
  const { empCode } = useParams();
  const { data, isLoading } = useQuery({
    queryKey: ["employeeNewDocumentFormData", empCode],
    queryFn: () => employeeRecordsService.getNewDocumentData(empCode),
  });

  if (isLoading) return <FormSpinner />;

  return <Form data={data} handleClose={handleClose} />;
};

const currentDate = dayjs().format("YYYY/MM/DD");

const Form = ({ data, handleClose }) => {
  const { t } = useTranslation("global");
  const { empCode } = useParams();

  const options = data.map((item) => item.EmpInfoDocumentName);

  const initialValues = {
    image: null,
    documentType: "",
    documentDate: currentDate,
  };

  const queryClient = useQueryClient();
  const { enqueueSnackbar } = useSnackbar();

  const schema = yup.object().shape({
    image: yup
      .mixed()
      .test("fileSize", t("modules.global_schema.file_size"), (value) => {
        return !value || (value && value.size <= MAX_FILE_SIZE);
      })
      .test(
        "fileType",
        t("modules.global_schema.file_type"),
        (value) => !value || (value && supportedImageTypes.includes(value.type))
      )
      .required(t("modules.global_schema.required")),
    documentType: yup
      .string()
      .required(t("modules.global_schema.select_value"))
      .oneOf(options, t("modules.global_schema.invalid_value")),
    documentDate: yup.date().required(t("modules.global_schema.required")),
  });

  const handleFormSubmit = (values) => {
    const { image, documentType, documentDate } = values;

    const formData = new FormData();

    formData.append("SelectedNo", empCode);
    formData.append("EmpInfoDocumentName", documentType);
    formData.append(
      "EmpInfoDocumentDate",
      dayjs(documentDate).format("YYYY/MM/DD")
    );
    formData.append("EmpInfoDocument", image);
    formData.append("State", "Documents");

    addNewEmployeeDocument(formData);
  };

  const {
    handleSubmit,
    setValue,
    setError,
    control,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
    mode: "onChange",
    defaultValues: initialValues,
  });

  const { mutateAsync: addNewEmployeeDocument, isPending } = useMutation({
    mutationFn: employeeRecordsService.addNewEmployeeDocument,
    onSuccess: (data) => {
      handleClose();
      enqueueSnackbar(data.msg, { variant: "success" });
      queryClient.invalidateQueries({ queryKey: ["employeeInfo"] });
    },
  });

  // Images handling logic
  const [selectedImage, setSelectedImage] = useState(null);

  const handleImageChange = (event) => {
    setError("image", null);
    const file = event.currentTarget.files[0];
    if (!file) return;
    setSelectedImage(URL.createObjectURL(file));
  };

  const handleRemoveFrontImage = () => {
    setValue("image", null);
    setSelectedImage(null);
  };

  return (
    <form onSubmit={handleSubmit(handleFormSubmit)} noValidate>
      <Box p={2}>
        <Box
          sx={{
            display: "grid",
            gridTemplateColumns: "1fr 1fr",
            gap: "10px",
            justifyItems: "center",
          }}
        >
          <Stack spacing={2}>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <Controller
                control={control}
                name="documentDate"
                render={({ field }) => {
                  return (
                    <DatePicker
                      label={t(
                        "info_modules.employee_records.cards.documents.new_form.fields.document_date"
                      )}
                      views={["year", "month", "day"]}
                      name="documentDate"
                      value={field.value}
                      inputRef={field.ref}
                      onChange={(date) => {
                        field.onChange(date);
                      }}
                      slotProps={{
                        textField: {
                          size: "small",
                          error: Boolean(errors.documentDate),
                          helperText: errors.documentDate?.message,
                          required: true,
                        },
                      }}
                    />
                  );
                }}
              />
            </LocalizationProvider>
            <Controller
              control={control}
              name="documentType"
              render={({ field: { onChange, value } }) => (
                <Autocomplete
                  disableClearable
                  size="small"
                  options={options}
                  onChange={(event, item) => {
                    onChange(item);
                  }}
                  value={value || null}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label={t(
                        "info_modules.employee_records.cards.documents.new_form.fields.document_type"
                      )}
                      error={!!errors.documentType}
                      helperText={errors.documentType?.message}
                      required
                    />
                  )}
                />
              )}
            />
          </Stack>
          <ImagePickerBox
            error={errors.image?.message}
            image={selectedImage}
            label={t(
              "info_modules.employee_records.cards.documents.new_form.fields.document_image"
            )}
            handleImage={handleImageChange}
            handleRemoveImage={handleRemoveFrontImage}
            name="image"
            control={control}
          />
        </Box>
      </Box>

      <ButtonGroup
        isPending={isPending}
        onClickClose={handleClose}
        saveLabel={t("modules.buttons.save")}
      />
    </form>
  );
};

export default NewDocument;
