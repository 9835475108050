import { useState } from "react";
import PopUpsButtons from "../../../../components/forms/ButtonGroup/PopUpsButtons";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { Box, Chip, TextField } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import Axios from "../../../../network/Axios";
import { useSnackbar } from "notistack";

const EditQuantity = ({ setAnchor, label, quantity, selectedId }) => {
  const { quotationId } = useParams();
  const [t] = useTranslation("global");
  const queryClient = useQueryClient();
  const { enqueueSnackbar } = useSnackbar();
  const [newQuantity, setNewQuantity] = useState(quantity);

  const { mutateAsync: editItem, isPending } = useMutation({
    mutationFn: async () => {
      return Axios.put("/Quotations", {
        State: "UpdateWorkOrderD6Items",
        idWorkOrderD6Items: selectedId.idWorkOrderD6Items,
        SelectedNo: quotationId,
        ItemQTY: Number(newQuantity),
      });
    },
    onSuccess: (data) => {
      enqueueSnackbar("Edited Successfully", {
        variant: "success",
      });
      queryClient.invalidateQueries({
        queryKey: ["quotation", "info"],
      });
      setAnchor(null);
    },
  });
  return (
    <Box display="flex" flexDirection="column" justifyContent="center">
      <Chip
        size="small"
        label={label}
        sx={{ borderRadius: 1, marginBottom: 1.8 }}
      />
      <TextField
        label={t("info_modules.quotation.description.quantity")}
        variant="outlined"
        fullWidth
        size="small"
        value={newQuantity}
        onChange={(e) => setNewQuantity(e.target.value)}
      />
      <PopUpsButtons
        saveLabel={t("modules.buttons.save")}
        onClickSave={editItem}
        onClickClose={() => {
          setAnchor(null);
          setNewQuantity(quantity);
        }}
        isPending={isPending}
      />
    </Box>
  );
};
export default EditQuantity;
