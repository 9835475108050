import { Box } from "@mui/material";
import InfoCard from "../../../../../components/InfoPagesCard/InfoCard";
import FormsDataGrid from "../../../../../components/DataGrid/FormsDataGrid";
import { useTranslation } from "react-i18next";

const Cars = ({ data }) => {
  const { t } = useTranslation("global");
  const columns = [
    { field: "CarsNumber", headerName: t("data_grid.headers.car_number") },
    { field: "CarsModelName", headerName: t("data_grid.headers.car_model") },
    { field: "CarsType", headerName: t("data_grid.headers.car_type") },
    {
      field: "AssignmentDateStart",
      headerName: t("data_grid.headers.start_date"),
      flex: 1,
    },
    {
      field: "AssignmentDateEnd",
      headerName: t("data_grid.headers.end_date"),
      flex: 1,
    },
  ];

  return (
    <InfoCard
      title={t("info_modules.employee_records.cards.cars.title")}
      content={
        <Box>
          <FormsDataGrid
            rows={data}
            columns={columns}
            getRowId={(row) => row.idEmpInfoCars}
            hideFooter={data.length === 0}
          />
        </Box>
      }
    />
  );
};

export default Cars;
