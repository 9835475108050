import * as yup from "yup";
import Axios from "../../network/Axios";
import { Box, Typography, FormControlLabel, Checkbox } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useSnackbar } from "notistack";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useState } from "react";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import IconButton from "@mui/material/IconButton";
import { useSelector } from "react-redux";
import FormsDataGrid from "../DataGrid/FormsDataGrid";
import { GridActionsCellItem } from "@mui/x-data-grid-pro";
import DeleteIcon from "@mui/icons-material/Delete";
import TimePickerComponent from "../DateAndTime/TimePicker";
import dayjs from "dayjs";
import { useFormik } from "formik";
import EmployeeModal from "../Modals/EmployeeModal";
import ButtonGroup from "./ButtonGroup/ButtonGroup";
import useHandleForms from "../../hooks/useHandleForms";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";

const AttendanceForm = ({ onDirtyChange, onClickClose }) => {
  const { enqueueSnackbar } = useSnackbar();
  const queryClient = useQueryClient();
  const [t] = useTranslation("global");
  const theme = useSelector((state) => state.theme.colorTheme);
  const [employeeModalOpen, setEmployeeModalOpen] = useState(false);
  const [newRows, setNewRows] = useState([]);
  const [selectedRows, setSelectedRows] = useState([]);
  const [timeOutDisabled, setTimeOutDisabled] = useState(true);
  const [checked, setChecked] = useState(false);
  const dateChange = dayjs();
  const timeIn = dayjs().set("hour", 9).set("minute", 0);
  const timeOut = dayjs().set("hour", 17).set("minute", 0);

  const initialValues = {
    employeeData: newRows,
    checked: false,
    date: dateChange.format("YYYY-MM-DD"),
    timeIn: timeIn.format("HH:mm:00"),
    timeOut: timeOutDisabled ? null : timeOut.format("HH:mm:00"),
  };

  const schema = yup.object().shape({
    employeeData: yup.array().required(t("modules.global_schema.required")),
    date: yup.string().required(t("modules.global_schema.required")),
    timeIn: yup.string().required(t("modules.global_schema.required")),
  });

  const handleOpenEmployeeModal = () => {
    setEmployeeModalOpen(true);
  };

  const handleCloseEmployeeModal = () => {
    setEmployeeModalOpen(false);
  };

  const handleCheckChange = (event) => {
    setChecked(event.target.checked);
    setTimeOutDisabled(!event.target.checked);
    if (event.target.checked && !values.timeOut) {
      setFieldValue(
        "timeOut",
        dayjs().set("hour", 17).set("minute", 0).format("HH:mm:00")
      );
    } else if (!event.target.checked) {
      setFieldValue("timeOut", null);
    }
  };

  const handleDeleteRow = (id) => {
    setNewRows((prevRows) => prevRows.filter((row) => row.idEmpInfo !== id));
    setSelectedRows((prevSelectedRows) => {
      return prevSelectedRows.filter((selectedId) => selectedId !== id);
    });
  };

  const handleFormClose = () => {
    handleClose();
    setNewRows([]);
    resetForm();
    setTimeOutDisabled(true);
    setChecked(false);
    setSelectedRows([]);
  };

  const { mutateAsync: addNewAttendance, isPending } = useMutation({
    mutationFn: async (values) => {
      const { date, timeIn, timeOut } = values;
      const endValue = timeOut ? `${date} ${timeOut}` : null;
      return Axios.post("/Attendance", {
        Employees: newRows,
        Start: `${date} ${timeIn}`,
        End: endValue,
      });
    },
    onSuccess: () => {
      handleFormClose();
      enqueueSnackbar("Added Successfully", { variant: "success" });
      queryClient.invalidateQueries({ queryKey: ["attendance"] });
    },
  });

  const handleFormSubmit = (values) => {
    addNewAttendance(values);
  };

  const { handleSubmit, setFieldValue, values, dirty, resetForm } = useFormik({
    initialValues,
    validationSchema: schema,
    onSubmit: handleFormSubmit,
  });

  const { handleClose } = useHandleForms({ dirty, onDirtyChange });

  const columns = [
    {
      field: "EmpName",
      headerName: t("data_grid.headers.name"),
      width: 210,
      flex: 1,
    },
    {
      field: "Actions",
      headerName: t("data_grid.headers.actions"),
      type: "actions",
      getActions: (params) => [
        <GridActionsCellItem
          key={params.row.idEmpInfo}
          icon={<DeleteIcon color="error" />}
          label="Delete"
          onClick={() => handleDeleteRow(params.row.idEmpInfo)}
        />,
      ],
    },
  ];

  return (
    <form onSubmit={handleSubmit}>
      <IconButton
        onClick={handleOpenEmployeeModal}
        aria-label="Add Employee"
        sx={{
          borderRadius: 3,
          textTransform: "capitalize",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          gap: "5px",
          margin: "4px 2px",
          ":hover": {
            backgroundColor:
              theme === "dark"
                ? "rgba(34, 85, 153, 0.1)"
                : "rgba(80, 152, 236, 0.05)",
          },
        }}
      >
        <AddCircleOutlineIcon color="primary" style={{ fontSize: "18px" }} />
        <Typography color="primary" fontSize="13px" fontWeight="600">
          {t("modules.attendance.add_buttons.add_employee")}
        </Typography>
      </IconButton>
      <EmployeeModal
        open={employeeModalOpen}
        handleClose={handleCloseEmployeeModal}
        setNewRows={setNewRows}
        newRows={newRows}
        setFieldValue={setFieldValue}
        selectedRows={selectedRows}
        setSelectedRows={setSelectedRows}
      />
      <Box display="flex" flexDirection="column" gap="12px">
        <FormsDataGrid
          rows={newRows}
          columns={columns}
          height={300}
          getRowId={(row) => row.idEmpInfo}
        />
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <DatePicker
            value={dayjs(values.date)}
            onChange={(date) => {
              setFieldValue("date", date.format("YYYY-MM-DD"));
            }}
            label={t("modules.attendance.fields.date")}
            slotProps={{
              textField: {
                size: "small",
              },
            }}
          />
        </LocalizationProvider>
        <Box display="flex">
          <TimePickerComponent
            label={t("modules.attendance.fields.time_in")}
            value={dayjs(values.timeIn, "HH:mm:00")}
            onChange={(time) => {
              setFieldValue("timeIn", time.format("HH:mm:00"));
            }}
            disabled={false}
          />
          <FormControlLabel
            control={
              <Checkbox checked={checked} onChange={handleCheckChange} />
            }
            sx={{
              margin: 0,
            }}
          />
          <TimePickerComponent
            label={t("modules.attendance.fields.time_out")}
            value={values.timeOut ? dayjs(values.timeOut, "HH:mm:00") : null}
            onChange={(time) => {
              setFieldValue("timeOut", time.format("HH:mm:00"));
            }}
            disabled={timeOutDisabled}
          />
        </Box>
      </Box>
      <ButtonGroup
        isPending={isPending}
        onClickClose={onClickClose}
        saveLabel={t("modules.buttons.save")}
      />
    </form>
  );
};

export default AttendanceForm;
