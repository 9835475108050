import { useTranslation } from "react-i18next";
import FormsDataGrid from "../../../DataGrid/FormsDataGrid";
import ChipStatus from "../../../ChipStatus";
import { Box } from "@mui/material";

const SelectStatement = ({ setValue, statements, rows }) => {
  const [t] = useTranslation("global");

  const columns = [
    {
      field: "InvoiceNo",
      headerName: t("data_grid.headers.statement_no"),
      width: 130,
    },
    {
      field: "GovernmentInvoice",
      headerName: t("data_grid.headers.governorate_invoice"),
      width: 140,
      flex: 1,
    },
    {
      field: "ClientPONo",
      headerName: t("data_grid.headers.client_po"),
      width: 140,
      flex: 1,
    },
    {
      field: "Activity",
      headerName: t("data_grid.headers.activity"),
      width: 140,
      flex: 1,
    },
    {
      field: "InvoiceType",
      headerName: t("data_grid.headers.invoice_type"),
      width: 140,
      flex: 1,
    },
    {
      field: "StatementStatus",
      headerName: t("data_grid.headers.statement_status"),
      headerAlign: "center",
      width: 130,
      renderCell: ({ value }) => {
        return <ChipStatus value={value} />;
      },
    },
  ];

  const handleRowSelectionModelChange = (newSelectedRows) => {
    setValue(newSelectedRows);
  };

  return (
    <Box pb={1}>
      <FormsDataGrid
        rows={rows || []}
        columns={columns}
        getRowId={(row) => row.InvoiceNo}
        disableColumnMenu
        checkboxSelection
        disableMultipleRowSelection
        onRowSelectionModelChange={handleRowSelectionModelChange}
        rowSelectionModel={statements}
        showQuickFilter
        toolbar
        height={400}
      />
    </Box>
  );
};

export default SelectStatement;
