import {
  Autocomplete,
  Box,
  Button,
  IconButton,
  Stack,
  Typography,
} from "@mui/material";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { useSnackbar } from "notistack";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import { useRef, useState } from "react";
import EmployeeModal from "../../Modals/EmployeeModal";
import { useFormik } from "formik";
import dayjs from "dayjs";
import * as yup from "yup";
import { GridActionsCellItem } from "@mui/x-data-grid-pro";
import DeleteIcon from "@mui/icons-material/Delete";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { DateRangePicker } from "@mui/x-date-pickers-pro";
import { vacationsRequestsService } from "../../../api/vacationsRequests";
import { CustomTextField } from "../EmployeeRecordsForm";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import ImageModal from "../../Modals/ImageModal";
import Image from "@mui/icons-material/Image";
import useHandleForms from "../../../hooks/useHandleForms";
import ButtonGroup from "../ButtonGroup/ButtonGroup";
import FormsDataGrid from "../../DataGrid/FormsDataGrid";
import FormSpinner from "../../Spinners/FormSpinner";
import VisuallyHiddenInput from "../../VisuallyHiddenInput";
import {
  MAX_FILE_SIZE,
  supportedImageTypes,
} from "../../../constants/constants";

const VacationsRequestsForm = ({ onDirtyChange, onClickClose }) => {
  const [newRows, setNewRows] = useState([]);
  const [selectedRows, setSelectedRows] = useState([]);
  const [employeeModalOpen, setEmployeeModalOpen] = useState(false);

  const { enqueueSnackbar } = useSnackbar();
  const queryClient = useQueryClient();
  const [t] = useTranslation("global");
  const theme = useSelector((state) => state.theme.colorTheme);

  const columns = [
    {
      field: "EmpName",
      headerName: t("data_grid.headers.name"),
      width: 210,
      flex: 1,
    },
    {
      field: "Actions",
      headerName: t("data_grid.headers.actions"),
      type: "actions",
      getActions: (params) => [
        <GridActionsCellItem
          key={params.row.id}
          icon={<DeleteIcon color="error" />}
          label="Delete"
          onClick={() => {
            handleDeleteRow(params.row.idEmpInfo);
            setFieldValue(
              "employeeData",
              values.employeeData.filter(
                (row) => row.idEmpInfo !== params.row.idEmpInfo
              )
            );
          }}
        />,
      ],
    },
  ];

  const { mutateAsync: addNewVacationsRequest, isPending } = useMutation({
    mutationFn: vacationsRequestsService.addNewVacationsRequest,
    onSuccess: (data) => {
      handleClose();
      enqueueSnackbar(data.msg, { variant: "success" });
      queryClient.invalidateQueries({ queryKey: ["vacationsRequests"] });
    },
  });

  const handleFormSubmit = (values) => {
    const {
      vacationAttachment,
      vacationDateRange,
      vacationType,
      employeeData,
    } = values;

    const formData = new FormData();
    formData.append("VacationAttachement", vacationAttachment);
    formData.append("Start", dayjs(vacationDateRange[0]).format("YYYY/MM/DD"));
    formData.append("End", dayjs(vacationDateRange[1]).format("YYYY/MM/DD"));
    formData.append("VacationsTypesName", vacationType);
    const employeesCodes = employeeData.map((employee) => employee.EmpCode);
    for (const element of employeesCodes) {
      formData.append("Employees", element);
    }
    formData.append("ImageState", vacationAttachment ? "Exist" : "NotExist");

    addNewVacationsRequest(formData);
  };

  const initialValues = {
    vacationDateRange: [null, null],
    vacationAttachment: null,
    employeeData: newRows,
    vacationType: "",
  };

  const schema = yup.object().shape({
    vacationDateRange: yup
      .array()
      .of(yup.date().required(t("modules.global_schema.required"))),
    employeeData: yup
      .array()
      .test("arrayLength", t("modules.global_schema.required"), (value) => {
        return value.length > 0;
      }),
    vacationType: yup.string().required(t("modules.global_schema.required")),
    vacationAttachment: yup
      .mixed()
      .test("fileSize", t("modules.global_schema.file_size"), (value) => {
        return !value || (value && value.size <= MAX_FILE_SIZE);
      })
      .test(
        "fileType",
        t("modules.global_schema.file_type"),
        (value) => !value || (value && supportedImageTypes.includes(value.type))
      )
      .nullable(),
  });

  const {
    handleSubmit,
    setFieldValue,
    values,
    dirty,
    errors,
    touched,
    setFieldTouched,
  } = useFormik({
    initialValues,
    validationSchema: schema,
    onSubmit: handleFormSubmit,
  });

  const { handleClose } = useHandleForms({ dirty, onDirtyChange });

  const handleDeleteRow = (id) => {
    setNewRows((prevRows) => prevRows.filter((row) => row.idEmpInfo !== id));
    setSelectedRows((prevSelectedRows) => {
      return prevSelectedRows.filter((selectedId) => selectedId !== id);
    });
  };

  // Image handling logic
  const fileInputRef = useRef(null);
  const [image, setImage] = useState(null);

  const handleImageChange = (event) => {
    if (!event.currentTarget.files[0]) return;
    const file = event.currentTarget.files[0];
    setFieldTouched("vacationAttachment", true, false); // Set touched to true (to show error message if any
    setFieldValue("vacationAttachment", file);
    setImage(URL.createObjectURL(file));
  };

  const handleRemoveImage = () => {
    setFieldValue("vacationAttachment", null);
    setImage(null);
    if (fileInputRef.current) {
      fileInputRef.current.value = ""; // Reset the input value
    }
  };

  const handleOpenEmployeeModal = () => {
    setEmployeeModalOpen(true);
  };

  const handleCloseEmployeeModal = () => {
    setEmployeeModalOpen(false);
  };

  const { data, isLoading } = useQuery({
    queryKey: ["vacationsRequestsFormData"],
    queryFn: vacationsRequestsService.GetVacationRequestsForm,
  });

  const vacationTypes = data?.map((item) => item.VacationsTypesName) || [];

  const [isImageModalOpen, setIsImageModalOpen] = useState(false);

  const handleImageModalOpen = () => {
    setIsImageModalOpen(true);
  };

  const handleCloseImageModal = () => {
    setIsImageModalOpen(false);
  };

  if (isLoading) {
    return <FormSpinner />;
  }

  return (
    <form onSubmit={handleSubmit} noValidate>
      <Stack spacing={3} alignItems={"flex-start"}>
        <Stack direction={"row"} spacing={3} alignItems={"center"}>
          <IconButton
            onClick={handleOpenEmployeeModal}
            aria-label="Add Employee"
            sx={{
              borderRadius: 3,
              textTransform: "capitalize",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              gap: "5px",
              margin: "4px 2px",
              ":hover": {
                backgroundColor:
                  theme === "dark"
                    ? "rgba(34, 85, 153, 0.1)"
                    : "rgba(80, 152, 236, 0.05)",
              },
            }}
          >
            <AddCircleOutlineIcon
              color="primary"
              style={{ fontSize: "18px" }}
            />
            <Typography color="primary" fontSize="13px" fontWeight="600">
              {t("modules.attendance.add_buttons.add_employee")}
            </Typography>
          </IconButton>
          {touched.employeeData && errors.employeeData && (
            <Typography
              color="red"
              sx={{
                backgroundColor: "#ffdfdf",
                p: "5px 10px",
                borderRadius: "5px",
                fontSize: "14px",
              }}
            >
              {errors.employeeData}
            </Typography>
          )}
        </Stack>
        <FormsDataGrid
          rows={newRows}
          columns={columns}
          height={300}
          getRowId={(row) => row.idEmpInfo}
        />
        <Box
          sx={{
            display: "grid",
            gridTemplateColumns: "1fr 1fr",
            alignItems: "end",
            gap: "16px",
          }}
        >
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DemoContainer components={["DateRangePicker"]}>
              <DateRangePicker
                localeText={{
                  start: t(
                    "modules.vacation_requests.new_form.fields.start_date"
                  ),
                  end: t("modules.vacation_requests.new_form.fields.end_date"),
                }}
                format="YYYY/MM/DD"
                sx={{
                  "& .MuiInputBase-root": {
                    borderRadius: "10px",
                  },
                }}
                onChange={(newValue) => {
                  setFieldValue("vacationDateRange", newValue);
                }}
                value={values.vacationDateRange}
                slotProps={{
                  textField: ({ position }) => ({
                    size: "small",
                    helperText:
                      position === "start"
                        ? touched.vacationDateRange &&
                          errors.vacationDateRange?.[0]
                        : position === "end"
                        ? touched.vacationDateRange &&
                          errors.vacationDateRange?.[1]
                        : null,
                    error:
                      position === "start"
                        ? touched.vacationDateRange &&
                          errors.vacationDateRange?.[0]
                        : position === "end"
                        ? touched.vacationDateRange &&
                          errors.vacationDateRange?.[1]
                        : false,
                    required: true,
                  }),
                }}
              />
            </DemoContainer>
          </LocalizationProvider>
          <Autocomplete
            disableClearable
            size="small"
            name="vacationType"
            options={vacationTypes}
            getOptionLabel={(option) => option}
            onChange={(event, value) => {
              setFieldValue("vacationType", value);
            }}
            value={values.vacationType}
            renderInput={(params) => (
              <CustomTextField
                {...params}
                label={t(
                  "modules.vacation_requests.new_form.fields.vacation_type"
                )}
                variant="outlined"
                fullWidth
                error={touched.vacationType && Boolean(errors.vacationType)}
                helperText={touched.vacationType && errors.vacationType}
                required
              />
            )}
          />
        </Box>
        <Stack spacing={2} alignItems={"flex-start"} direction={"row"}>
          <Button
            component="label"
            role={undefined}
            variant="contained"
            tabIndex={-1}
            startIcon={<CloudUploadIcon />}
          >
            {t("modules.buttons.select_image")}
            <VisuallyHiddenInput
              type="file"
              name="vacationAttachment"
              accept="image/*"
              onChange={handleImageChange}
              ref={fileInputRef}
            />
          </Button>
          {values.vacationAttachment !== null && !errors.vacationAttachment && (
            <>
              <IconButton onClick={handleImageModalOpen}>
                <Image />
              </IconButton>
              <IconButton aria-label="delete" onClick={handleRemoveImage}>
                <DeleteIcon color="error" />
              </IconButton>
            </>
          )}
          {touched.vacationAttachment && errors.vacationAttachment && (
            <Typography
              color="red"
              sx={{
                backgroundColor: "#ffdfdf",
                p: 1,
                borderRadius: "5px",
                fontSize: "14px",
              }}
            >
              {errors.vacationAttachment}
            </Typography>
          )}
        </Stack>
      </Stack>
      <ButtonGroup
        isPending={isPending}
        onClickClose={onClickClose}
        saveLabel={t("modules.buttons.save")}
      />
      <EmployeeModal
        open={employeeModalOpen}
        handleClose={handleCloseEmployeeModal}
        setNewRows={setNewRows}
        newRows={newRows}
        setFieldValue={setFieldValue}
        selectedRows={selectedRows}
        setSelectedRows={setSelectedRows}
      />
      <ImageModal
        open={isImageModalOpen}
        handleClose={handleCloseImageModal}
        image={image}
      />
    </form>
  );
};

export default VacationsRequestsForm;
