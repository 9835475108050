import * as yup from "yup";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { useSnackbar } from "notistack";
import { useTranslation } from "react-i18next";
import { Autocomplete, Box, Stack, TextField, Typography } from "@mui/material";
import { useParams } from "react-router-dom";
import { employeeRecordsService } from "../../../../../api/employeeRecords";
import ButtonGroup from "../../../../../components/forms/ButtonGroup/ButtonGroup";
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { useState } from "react";
import { convertImage } from "../../../../../utils/utils";
import ImageModal from "../../../../../components/Modals/ImageModal";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFnsV3";
import dayjs from "dayjs";
import {
  MAX_FILE_SIZE,
  supportedImageTypes,
} from "../../../../../constants/constants";
import FormSpinner from "../../../../../components/Spinners/FormSpinner";
import ImagePickerBox from "../../components/ImagePickerBox";

const EditForm = ({ handleClose }) => {
  const { empCode } = useParams();

  const { data, isLoading, isRefetching } = useQuery({
    queryKey: ["employeeMedicalInsurance", empCode],
    queryFn: () =>
      employeeRecordsService.getEmployeeMedicalInsuranceData(empCode),
  });

  if (isLoading || isRefetching) return <FormSpinner />;

  return <Form data={data} handleClose={handleClose} />;
};

const Form = ({ data, handleClose }) => {
  const [t] = useTranslation("global");
  const {
    EndAt,
    MedicalInsuranceProviderName,
    MedicalNo,
    StartFrom,
    MedicalPhotoCopy,
  } = data.MedicalInsurance[0] || {
    EndAt: dayjs().format("YYYY-MM-DD"),
    MedicalInsuranceProviderName: "",
    MedicalNo: "",
    StartFrom: dayjs().format("YYYY-MM-DD"),
    MedicalPhotoCopy: null,
  };

  const options = data.MedicalInsuranceProvider.map(
    (item) => item.MedicalInsuranceProviderName
  );

  const initialValues = {
    MedicalInsuranceProviderName,
    MedicalNo,
    StartFrom,
    MedicalPhotoCopy: null,
    EndAt,
  };

  const { empCode } = useParams();

  const { enqueueSnackbar } = useSnackbar();
  const queryClient = useQueryClient();

  const schema = yup.object().shape({
    MedicalNo: yup.string().required(t("modules.global_schema.required")),
    MedicalInsuranceProviderName: yup
      .string()
      .required(t("modules.global_schema.select_value")),
    StartFrom: yup.date().required(t("modules.global_schema.required")),
    EndAt: yup.date().required(t("modules.global_schema.required")),
    MedicalPhotoCopy: yup
      .mixed()
      .test("fileSize", t("modules.global_schema.file_size"), (value) => {
        return !value || (value && value.size <= MAX_FILE_SIZE);
      })
      .test(
        "fileType",
        t("modules.global_schema.file_type"),
        (value) => !value || (value && supportedImageTypes.includes(value.type))
      )
      .nullable(),
  });

  const handleFormSubmit = (values) => {
    const {
      MedicalPhotoCopy,
      StartFrom,
      EndAt,
      MedicalNo,
      MedicalInsuranceProviderName,
    } = values;

    if (!convertedImage && getValues().MedicalPhotoCopy === null) {
      return setError("MedicalPhotoCopy", {
        message: t("modules.global_schema.required"),
        type: "required",
      });
    }

    const formData = new FormData();

    formData.append("SelectedNo", empCode);
    formData.append("State", "MedicalInsurance");
    formData.append("MedicalNo", MedicalNo);
    formData.append("StartFrom", dayjs(StartFrom).format("YYYY-MM-DD"));
    formData.append("EndAt", dayjs(EndAt).format("YYYY-MM-DD"));
    formData.append(
      "MedicalInsuranceProviderName",
      MedicalInsuranceProviderName
    );
    formData.append("MedicalPhotoCopy", MedicalPhotoCopy);
    formData.append("ImageState", MedicalPhotoCopy ? "Exist" : "NotExist");

    updateMedicalInsurance(formData);
  };

  const { mutateAsync: updateMedicalInsurance, isPending } = useMutation({
    mutationFn: employeeRecordsService.addNewEmployeeDocument,
    onSuccess: (data) => {
      if (!data) return;
      handleClose();
      enqueueSnackbar(data.msg, {
        variant: "success",
      });
      queryClient.invalidateQueries("employeeInfo");
    },
  });

  const {
    register,
    handleSubmit,
    setValue,
    getValues,
    trigger,
    control,
    setError,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
    mode: "onChange",
    defaultValues: initialValues,
  });

  const convertedImage =
    MedicalPhotoCopy && convertImage(MedicalPhotoCopy.data);

  const [isImageModalOpen, setIsImageModalOpen] = useState(false);

  const handleCloseImageModal = () => {
    setIsImageModalOpen(false);
  };

  // Image handling logic
  const [image, setImage] = useState(null);

  const handleImageChange = (event) => {
    if (!event.currentTarget.files[0]) return;
    const file = event.currentTarget.files[0];
    setValue("MedicalPhotoCopy", file);
    trigger("MedicalPhotoCopy"); // Trigger validation
    setImage(URL.createObjectURL(file));
  };

  return (
    <>
      <Box
        component="form"
        onSubmit={handleSubmit(handleFormSubmit)}
        noValidate
        sx={{
          padding: "1rem",
        }}
      >
        <Box
          sx={{
            display: "grid",
            gridTemplateColumns: "1fr 1fr",
            gap: "1rem",
          }}
        >
          <Stack spacing={2}>
            <Typography>
              {t(
                "info_modules.employee_records.cards.medical_insurance.edit_form.labels.information"
              )}
            </Typography>
            <TextField
              size="small"
              {...register("MedicalNo")}
              error={Boolean(errors.MedicalNo?.message)}
              helperText={errors.MedicalNo?.message}
              label={t(
                "info_modules.employee_records.cards.medical_insurance.fields.insurance_no"
              )}
              autoFocus
            />
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <Controller
                control={control}
                name="StartFrom"
                render={({ field }) => {
                  return (
                    <DatePicker
                      label={t(
                        "info_modules.employee_records.cards.medical_insurance.fields.insurance_start_date"
                      )}
                      views={["year", "month", "day"]}
                      name="StartFrom"
                      value={field.value}
                      inputRef={field.ref}
                      onChange={(date) => {
                        field.onChange(date);
                      }}
                      slots={{
                        textField: TextField,
                      }}
                      slotProps={{
                        textField: {
                          size: "small",
                          error: Boolean(errors.StartFrom?.message),
                          label: t(
                            "info_modules.employee_records.cards.medical_insurance.fields.insurance_start_date"
                          ),
                        },
                      }}
                    />
                  );
                }}
              />
            </LocalizationProvider>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <Controller
                control={control}
                name="EndAt"
                render={({ field }) => {
                  return (
                    <DatePicker
                      label={t(
                        "info_modules.employee_records.cards.medical_insurance.fields.insurance_end_date"
                      )}
                      views={["year", "month", "day"]}
                      name="EndAt"
                      value={field.value}
                      inputRef={field.ref}
                      onChange={(date) => {
                        field.onChange(date);
                      }}
                      slots={{
                        textField: TextField,
                      }}
                      slotProps={{
                        textField: {
                          size: "small",
                          error: Boolean(errors.EndAt?.message),
                          label: t(
                            "info_modules.employee_records.cards.medical_insurance.fields.insurance_end_date"
                          ),
                        },
                      }}
                    />
                  );
                }}
              />
            </LocalizationProvider>
            <Controller
              control={control}
              name={`MedicalInsuranceProviderName`}
              render={({ field: { onChange, value } }) => (
                <Autocomplete
                  disableClearable
                  size="small"
                  options={options}
                  onChange={(event, item) => {
                    onChange(item);
                  }}
                  value={value || null}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label={t(
                        "info_modules.employee_records.cards.medical_insurance.fields.insurance_provider"
                      )}
                      error={!!errors.MedicalInsuranceProviderName}
                      helperText={errors.MedicalInsuranceProviderName?.message}
                      required
                    />
                  )}
                />
              )}
            />
          </Stack>
          <Stack spacing={1}>
            <Typography
              sx={{
                fontSize: "14px",
                fontStyle: "italic",
              }}
            >
              *
              {t(
                "info_modules.employee_records.cards.medical_insurance.edit_form.labels.document"
              )}
            </Typography>
            <ImagePickerBox
              error={errors.MedicalPhotoCopy}
              image={image || convertedImage}
              handleImage={handleImageChange}
              control={control}
              name="MedicalPhotoCopy"
            />
          </Stack>
        </Box>
        <ButtonGroup
          saveLabel={t("modules.buttons.save")}
          isPending={isPending}
          onClickClose={handleClose}
        />
      </Box>
      <ImageModal
        open={isImageModalOpen}
        handleClose={handleCloseImageModal}
        image={image || convertedImage}
      />
    </>
  );
};

export default EditForm;
