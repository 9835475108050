import { useEffect } from "react";
import FormsDataGrid from "../../../DataGrid/FormsDataGrid";
import { Box } from "@mui/material";
import { useTranslation } from "react-i18next";

const SelectItem = ({
  onRowSelection,
  loading,
  rows,
  selectedRows,
  setSelectedRows,
  getRowClassName,
}) => {
  useEffect(() => {
    onRowSelection(selectedRows);
  }, [selectedRows, onRowSelection]);

  const [t] = useTranslation("global");

  const columns = [
    {
      field: "StoreItemsTemplateCode",
      headerName: t("data_grid.headers.item_code"),
    },
    {
      field: "StoreItemsTemplateName",
      headerName: t("data_grid.headers.item_description"),
      flex: 1,
    },
    {
      field: "StoreItemsTemplateUnit",
      headerName: t("data_grid.headers.unit"),
      width: 100,
    },
    {
      field: "StoreItemsTemplateType",
      headerName: t("data_grid.headers.type"),
    },
    {
      field: "StoreItemsTemplateQty",
      headerName: t("data_grid.headers.item_total_balance"),
      width: 150,
    },
  ];

  return (
    <Box sx={{ margin: "12px 1px" }}>
      <FormsDataGrid
        rows={rows}
        columns={columns}
        loading={loading}
        getRowId={(row) => row.idStoreItemsTemplateInfo}
        height={400}
        showQuickFilter
        checkboxSelection
        toolbar
        disableSelectionOnClick
        getRowClassName={getRowClassName}
        onRowSelectionModelChange={(newSelectedRows) => {
          setSelectedRows(newSelectedRows);
        }}
        rowSelectionModel={selectedRows}
      />
    </Box>
  );
};
export default SelectItem;
