import Axios from "../network/Axios";

const AddNewMobileLine = async (data) => {
  const res = await Axios.post("/Mobile&InternetLines", {
    ...data,
    State: "New",
  });

  return res.data;
};
const AddNewAccount = async (data) => {
  const res = await Axios.post("/Mobile&InternetLines", {
    ...data,
    State: "Add Account",
  });

  return res.data;
};
const getAccounts = async () => {
  const response = await Axios.get("/Mobile&InternetLines", {
    params: { State: "Accounts" },
  });
  return response.data.result;
};
const EditAccount = async (data) => {
  const res = await Axios.patch("/Mobile&InternetLines", data);

  return res.data;
};
const getAccountsEdit = async (SelectedNo) => {
  const response = await Axios.get("/Mobile&InternetLines", {
    params: { State: "EditAccount", SelectedNo: SelectedNo },
  });
  return response.data;
};

const GetPhoneLinesPackages = async (companyPhoneLinesOperatorName) => {
  const response = await Axios.get("/Mobile&InternetLines", {
    params: {
      State: "Packages",
      CompanyPhoneLinesOperatorName: companyPhoneLinesOperatorName,
    },
  });
  return response.data;
};

const GetMobileInternetLinesFormData = async () => {
  const response = await Axios.get("/Mobile&InternetLines", {
    params: {
      State: "New",
    },
  });
  return response.data;
};

const GetMobileInternetLines = async () => {
  const response = await Axios.get("/Mobile&InternetLines");
  return response.data.result;
};

const GetMobileInternetLineData = async (
  selectedNo,
  State = "CardDetailed"
) => {
  const response = await Axios.get("/Mobile&InternetLines", {
    params: {
      SelectedNo: selectedNo,
      State,
    },
  });
  return response.data;
};

const updateMobileLineState = async (data) => {
  const res = await Axios.patch("/Mobile&InternetLines", data);

  return res.data;
};

const updateMobileLine = async (data) => {
  const res = await Axios.put("/Mobile&InternetLines", data);
  return res.data;
};

export const MobileInternetService = {
  AddNewMobileLine,
  GetPhoneLinesPackages,
  GetMobileInternetLinesFormData,
  GetMobileInternetLines,
  GetMobileInternetLineData,
  updateMobileLineState,
  updateMobileLine,
  AddNewAccount,
  getAccounts,
  EditAccount,
  getAccountsEdit,
};
