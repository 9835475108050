import { Box, Grid } from "@mui/material";
import { useTranslation } from "react-i18next";
import ChipComponent from "../../../../../../components/Chips/ChipComponent";
import FormsDataGrid from "../../../../../../components/DataGrid/FormsDataGrid";

const Review = ({ values, rows }) => {
  const [t] = useTranslation("global");
  const getRowClassName = (params) => {
    const balance = params.row.idCompanyStoreTempleteItems;
    return balance === null ? "read-only-row" : "";
  };
  const dataReview = [
    {
      label: t("info_modules.material_request.description.paper_no"),
      value: values.paperNo,
    },
    {
      label: t("info_modules.material_request.description.date"),
      value: values.date.format("YYYY/MM/DD"),
    },
    {
      label: t("info_modules.material_request.description.emp_name"),
      value: values.employeeName,
    },
    {
      label: t("info_modules.material_request.description.destination"),
      value: values.destination,
    },
  ];
  const columns = [
    {
      field: "StoreItemsTemplateCode",
      headerName: t("data_grid.headers.code"),
    },
    {
      field: "StoreItemsTemplateName",
      headerName: t("data_grid.headers.name"),
      flex: 1,
    },
    {
      field: "StoreItemsTemplateUnit",
      headerName: t("data_grid.headers.unit"),
    },
    {
      field: "StoreItemsTemplateType",
      headerName: t("data_grid.headers.type"),
    },
    {
      field: "CompanyStoreTempleteItemsBalance",
      headerName: t("data_grid.headers.balance"),
    },
    {
      field: "MaterialsRequestQty",
      headerName: t("data_grid.headers.requested_qty"),
    },
    {
      field: "IssuedQty",
      headerName: t("data_grid.headers.issued_qty"),
    },
    {
      field: "QTY",
      headerName: t("data_grid.headers.qty"),
      valueGetter: (params) => params.row.QTY || 0,
    },
  ];

  return (
    <Box padding="12px 1px" display="flex" flexDirection="column" gap="13px">
      <Grid container spacing={1.2}>
        {dataReview.map((item, index) => (
          <Grid item xs={4} key={index}>
            <ChipComponent label={item.label} value={item.value} />
          </Grid>
        ))}
        <Grid item xs={8}>
          <ChipComponent
            label={t("info_modules.material_request.description.car_name")}
            value={values.carName}
          />
        </Grid>
      </Grid>
      <FormsDataGrid
        rows={rows || []}
        columns={columns}
        getRowId={(row) => row.idMaterialsRequestTemplates}
        disableColumnMenu
        getRowClassName={getRowClassName}
        height={300}
      />
    </Box>
  );
};
export default Review;
