import { useState, useEffect } from "react";
import { Box, FormControlLabel, Checkbox, Chip } from "@mui/material";
import PopUpsButtons from "../../../../components/forms/ButtonGroup/PopUpsButtons";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import Axios from "../../../../network/Axios";
import { useSnackbar } from "notistack";
import dayjs from "dayjs";

const EditEmployee = ({
  missionStart,
  empName,
  missionEnd,
  isCar,
  selectedId,
  setAnchor,
}) => {
  const { missionId } = useParams();
  const [t] = useTranslation("global");
  const queryClient = useQueryClient();
  const { enqueueSnackbar } = useSnackbar();
  const [startDate, setStartDate] = useState(
    missionStart ? dayjs(missionStart) : null
  );
  const [endDate, setEndDate] = useState(
    missionEnd ? dayjs(missionEnd) : dayjs()
  );
  const [isEndDateEnabled, setIsEndDateEnabled] = useState(false);

  const handleStartDateChange = (date) => {
    setStartDate(date);
  };

  const handleEndDateChange = (date) => {
    setEndDate(date);
  };
  useEffect(() => {
    if (missionEnd === null) {
      setIsEndDateEnabled(false);
      setEndDate(dayjs());
    } else {
      setIsEndDateEnabled(true);
      setEndDate(dayjs(missionEnd));
    }
  }, [missionEnd]);

  const { mutateAsync: editEmployee, isPending: editEmployeePending } =
    useMutation({
      mutationFn: async () => {
        return Axios.put("/Missions", {
          State: "UpdateEmployee",
          SelectedID: selectedId.idMissionEmp,
          SelectedNo: missionId,
          MissionEmpStart: startDate
            ? startDate.format("YYYY/MM/DD HH:mm:ss")
            : null,
          MissionEmpEnd:
            isEndDateEnabled && endDate
              ? endDate.format("YYYY/MM/DD HH:mm:ss")
              : null,
        });
      },
      onSuccess: (data) => {
        if (!data) return;
        enqueueSnackbar("Edited Successfully", {
          variant: "success",
        });
        queryClient.invalidateQueries({
          queryKey: ["missionsInfo", missionId],
        });
        setAnchor(null);
      },
    });
  const { mutateAsync: editCar, isPending: editCarPending } = useMutation({
    mutationFn: async () => {
      return Axios.put("/Missions", {
        State: "UpdateCar",
        SelectedID: selectedId.idMissionCars,
        SelectedNo: missionId,
        MissionCarStart: startDate
          ? startDate.format("YYYY/MM/DD HH:mm:ss")
          : null,
        MissionCarEnd:
          isEndDateEnabled && endDate
            ? endDate.format("YYYY/MM/DD HH:mm:ss")
            : null,
      });
    },
    onSuccess: (data) => {
      if (!data) return;
      enqueueSnackbar("Edited Successfully", {
        variant: "success",
      });
      queryClient.invalidateQueries({
        queryKey: ["missionsInfo", missionId],
      });
      setAnchor(null);
    },
  });
  return (
    <>
      <Box display="flex" flexDirection="column" justifyContent="center">
        <Chip
          size="small"
          label={empName}
          sx={{ borderRadius: 1, marginBottom: 1.8 }}
        />
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <DateTimePicker
            label={
              isCar
                ? t("info_modules.missions.fields.car_start_date")
                : t("info_modules.missions.fields.employee_start_date")
            }
            value={startDate}
            format="YYYY/MM/DD hh:mm A"
            onChange={handleStartDateChange}
            sx={{
              width: "20rem",
            }}
            slotProps={{
              textField: {
                size: "small",
              },
              popper: {
                placement: "right",
              },
            }}
          />
        </LocalizationProvider>

        <FormControlLabel
          sx={{ padding: "0px", margin: "0px", marginBottom: "4px" }}
          control={
            <Checkbox
              checked={isEndDateEnabled}
              onChange={() => {
                setIsEndDateEnabled(!isEndDateEnabled);
              }}
            />
          }
          label={t("info_modules.missions.fields.enable_end_date")}
        />

        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <DateTimePicker
            label={
              isCar
                ? t("info_modules.missions.fields.car_end_date")
                : t("info_modules.missions.fields.employee_end_date")
            }
            value={endDate}
            format="YYYY/MM/DD hh:mm A"
            onChange={handleEndDateChange}
            disabled={!isEndDateEnabled}
            sx={{
              width: "20rem",
            }}
            slotProps={{
              textField: {
                size: "small",
              },
              popper: {
                placement: "right",
              },
            }}
          />
        </LocalizationProvider>
      </Box>
      <PopUpsButtons
        saveLabel={t("modules.buttons.save")}
        onClickSave={isCar ? editCar : editEmployee}
        onClickClose={() => {
          setAnchor(null);
          setStartDate(null);
          setEndDate(dayjs());
          setIsEndDateEnabled(false);
        }}
        isPending={isCar ? editCarPending : editEmployeePending}
        disabled={!dayjs(startDate).isValid() || !dayjs(endDate).isValid()}
      />
    </>
  );
};

export default EditEmployee;
