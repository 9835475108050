import { useState } from "react";
import * as Yup from "yup";
import { useFormik } from "formik";
import { useTranslation } from "react-i18next";
import { useSnackbar } from "notistack";
import Axios from "../../../../../network/Axios";
import { useParams } from "react-router-dom";
import Select from "./Steps/Select";
import BasicInfo from "./Steps/BasicInfo";
import { Stepper, Step, StepLabel, Box } from "@mui/material";
import InfoModal from "../../../../../components/Modals/InfoModal";
import StepperButton from "../../../../../components/forms/ButtonGroup/StepperButton";
import FormSpinner from "../../../../../components/Spinners/FormSpinner";
import { useQuery, useMutation, useQueryClient } from "@tanstack/react-query";

const UseAsTemplate = ({ open, handleClose }) => {
  const [t] = useTranslation("global");
  const { userId } = useParams();
  const queryClient = useQueryClient();
  const { enqueueSnackbar } = useSnackbar();
  const [activeStep, setActiveStep] = useState(0);
  const [isRowSelected, setIsRowSelected] = useState(false);
  const [selectedUsers, setSelectedUsers] = useState([]);
  const [selectedNo, setSelectedNo] = useState("");

  const validationSchema = Yup.object().shape({
    userName: Yup.string().required("Required"),
    nickName: Yup.string().required("Required"),
    userMail: Yup.string().email("Invalid email").required("Required"),
  });

  const initialValues = {
    userName: "",
    nickName: "",
    userMail: "",
  };

  const { data: itemsRows, isLoading: loadingItems } = useQuery({
    queryKey: ["users", "useAsTemplate"],
    queryFn: async () => {
      const response = await Axios.get("/UsersManagement", {
        params: {
          State: "New",
        },
      });
      return response.data.Employees;
    },
    enabled: open,
  });

  const { data: empInfo, isLoading: loadingEmp } = useQuery({
    queryKey: ["users", "empInfo"],
    queryFn: async () => {
      const response = await Axios.get("/EmployeeRecords", {
        params: {
          "SelectedNo[]": selectedNo,
          State: "EmpPhoto",
        },
      });
      return response.data.result[0];
    },
    enabled: activeStep === 1 && open,
  });

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleRowSelection = (selectedItems) => {
    setIsRowSelected(selectedItems.length > 0);
  };

  const handleCloseSave = () => {
    resetForm();
    handleClose();
    setActiveStep(0);
    setSelectedUsers([]);
  };

  const steps = [
    t("info_modules.users_management.description.select_employees"),
    t("info_modules.users_management.description.basic_info"),
  ];

  const { mutateAsync: handleTemplate, isPending: pendingTemplate } =
    useMutation({
      mutationFn: async () => {
        return Axios.post("/UsersManagement", {
          ERPUserMail: values.userMail,
          ERPUserName: values.userName,
          ERPUserNickName: values.nickName,
          EmpCode: selectedNo,
          SelectedNo: userId,
          State: "UseAsTemplate",
        });
      },
      onSuccess: (data) => {
        if (!data) return;
        enqueueSnackbar("User Created Successfully", {
          variant: "success",
        });
        handleCloseSave();
        queryClient.invalidateQueries({
          queryKey: ["usersManagement", "info"],
        });
      },
    });
  const handleNext = async () => {
    if (activeStep === 0) {
      const selectedData = itemsRows.filter((row) =>
        selectedUsers.includes(row.idEmpInfo)
      );
      setSelectedUsers(selectedData);
      setSelectedNo(selectedData[0].EmpCode);
    }
    if (activeStep === 1) {
      const data = await validateForm();
      if (Object.keys(data).length > 0) return handleSubmit();
      handleTemplate();
      return;
    }
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };
  const {
    handleSubmit,
    values,
    resetForm,
    errors,
    touched,
    handleBlur,
    handleChange,
    validateForm,
  } = useFormik({
    initialValues,
    validationSchema,
    onSubmit: handleNext,
  });
  const views = {
    0: (
      <Select
        rows={itemsRows}
        onRowSelection={handleRowSelection}
        selectedRows={selectedUsers}
        setSelectedRows={setSelectedUsers}
      />
    ),
    1: (
      <>
        {loadingEmp ? (
          <FormSpinner />
        ) : (
          <BasicInfo
            newUsers={empInfo}
            errors={errors}
            handleBlur={handleBlur}
            handleChange={handleChange}
            touched={touched}
            values={values}
            handleSubmit={handleSubmit}
          />
        )}
      </>
    ),
  };

  return (
    <InfoModal
      open={open}
      handleClose={handleCloseSave}
      width="52rem"
      title={t("info_modules.quotation.description.use_template")}
      content={
        <>
          {loadingItems ? (
            <FormSpinner />
          ) : (
            <Box margin="10px">
              <Stepper activeStep={activeStep}>
                {steps.map((label) => {
                  return (
                    <Step key={label}>
                      <StepLabel>{label}</StepLabel>
                    </Step>
                  );
                })}
              </Stepper>

              {views[activeStep]}
              <StepperButton
                isPending={pendingTemplate}
                onClick={handleNext}
                handleBack={handleBack}
                activeStep={activeStep}
                disabled={!isRowSelected}
                label={
                  activeStep === 1
                    ? t("modules.buttons.save")
                    : t("modules.buttons.next")
                }
                isLastStep={activeStep === 1}
              />
            </Box>
          )}
        </>
      }
    />
  );
};

export default UseAsTemplate;
