import dayjs from "dayjs";
import { Box } from "@mui/material";
import InfoCard from "../../../../../components/InfoPagesCard/InfoCard";
import FormsDataGrid from "../../../../../components/DataGrid/FormsDataGrid";
import { useTranslation } from "react-i18next";

const CompanyLines = ({ data }) => {
  const [t] = useTranslation("global");

  const columns = [
    {
      field: "CompanyPhoneLinesNumber",
      headerName: t("data_grid.headers.line_number"),
      flex: 1,
    },
    {
      field: "CompanyPhoneLinesOperatorName",
      headerName: t("data_grid.headers.operator"),
      flex: 1,
    },
    {
      field: "CompanyPhoneLinesPackageName",
      headerName: t("data_grid.headers.internet_package"),
      flex: 1,
    },
    {
      field: "CompanyPhoneLinesType",
      headerName: t("data_grid.headers.type"),
      flex: 1,
    },
    {
      field: "EmpCompanyPhoneLinesEndDate",
      headerName: t("data_grid.headers.end_date"),
      flex: 1,
      renderCell: ({ value }) => {
        return value ? dayjs(value).format("YYYY/MM/DD") : "";
      },
    },
    {
      field: "EmpCompanyPhoneLinesStartDate",
      headerName: t("data_grid.headers.assigned_on"),
      flex: 1,
      renderCell: ({ value }) => {
        return value ? dayjs(value).format("YYYY/MM/DD") : "";
      },
    },
  ];

  return (
    <InfoCard
      title={t("info_modules.employee_records.cards.phone_lines.title")}
      content={
        <Box>
          <FormsDataGrid
            rows={data}
            columns={columns}
            getRowId={(row) => row.CompanyPhoneLinesNumber}
            hideFooter={data.length === 0}
          />
        </Box>
      }
    />
  );
};

export default CompanyLines;
