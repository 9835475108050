import { Box } from "@mui/material";
import dayjs from "dayjs";
import { useSelector } from "react-redux";

const DateFormatGrid = ({ value }) => {
  const { language } = useSelector((state) => state.theme);

  return (
    <Box padding="7px">
      <Box fontSize="13.5px" fontWeight="500">
        {dayjs(value).format("YYYY/MM/DD")}
      </Box>
      <Box
        display="flex"
        justifyContent={language === "AR" ? "flex-start" : "flex-end"}
        color="text.secondary"
        fontWeight="500"
      >
        {dayjs(value).format("hh:mm a")}
      </Box>
    </Box>
  );
};
export default DateFormatGrid;
