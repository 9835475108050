import { Box, Button, IconButton, Typography } from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import { Controller } from "react-hook-form";
import VisuallyHiddenInput from "../../VisuallyHiddenInput";

const ImagePickerBox = ({
  error,
  image,
  handleRemoveImage,
  handleImage,
  label,
  control,
  name,
  maxWidth,
  height,
}) => {
  return (
    <Box
      sx={{
        maxWidth: maxWidth || "15rem",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        width: "100%",
      }}
    >
      {(!image || error) && (
        <Box
          sx={{
            py: 10,
            border: "1px dashed #277da1",
            borderRadius: "5px",
            display: "grid",
            placeItems: "center",
            gap: "10px",
            width: "100%",
            height: height || "auto",
          }}
        >
          <Button
            component="label"
            role={undefined}
            variant="contained"
            startIcon={<CloudUploadIcon />}
          >
            {label}
            <Controller
              name={name}
              control={control}
              render={({ field }) => {
                return (
                  <VisuallyHiddenInput
                    type="file"
                    accept="image/*"
                    onChange={(e) => {
                      field.onChange(e.target.files[0]);
                      handleImage(e);
                    }}
                  />
                );
              }}
            />
          </Button>

          {error && (
            <Typography
              color="red"
              sx={{
                backgroundColor: "#ffdfdf",
                p: 1,
                borderRadius: "5px",
                fontSize: "14px",
              }}
            >
              {error}
            </Typography>
          )}
        </Box>
      )}

      {!error && image && (
        <Box
          sx={{
            flexGrow: 1,
            position: "relative",
            overflow: "hidden",
            borderRadius: "5px",
            aspectRatio: "1",
          }}
        >
          <img
            src={image}
            alt="Selected"
            style={{
              width: "100%",
              height: "100%",
              objectFit: "cover",
              filter: "brightness(0.5)",
            }}
          />

          <IconButton
            aria-label="delete"
            onClick={handleRemoveImage}
            sx={{
              position: "absolute",
              left: "50%",
              top: "50%",
              transform: "translate(-50%, -50%)",
              backgroundColor: "#fff",
              "&:hover": {
                backgroundColor: "#333",
              },
            }}
          >
            <DeleteIcon color="error" />
          </IconButton>
        </Box>
      )}
    </Box>
  );
};

export default ImagePickerBox;
