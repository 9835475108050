import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  colorTheme: localStorage.getItem("DarkMode")
    ? JSON.parse(localStorage.getItem("DarkMode")) == 0
      ? "light"
      : "dark"
    : "light", // light | dark
  language: JSON.parse(localStorage.getItem("Language")), // "EN" | "AR"
  previousData: null,
  FabState: false,
  sideDrawerState: false,
  newForm: null,
  submitFunction: null,
  columnsConfig: JSON.parse(localStorage.getItem("ColumnsConfig")) || {},
};

const themeSlice = createSlice({
  name: "theme",
  initialState,
  reducers: {
    setSubmitFunction: (state, action) => {
      state.submitFunction = action.payload;
    },
    setNewForm: (state, action) => {
      state.newForm = action.payload;
    },
    setFabState: (state) => {
      state.FabState = !state.FabState;
    },
    setSideDrawerState: (state) => {
      state.sideDrawerState = !state.sideDrawerState;
    },
    setInitialTheme: (state, action) => {
      state.colorTheme = action.payload.DarkMode == 1 ? "dark" : "light";
      localStorage.setItem(
        "DarkMode",
        JSON.stringify(state.colorTheme == "light" ? 0 : 1)
      );
      state.language = action.payload.Language;
    },
    setTheme: (state) => {
      state.colorTheme = state.colorTheme === "light" ? "dark" : "light";
      localStorage.setItem(
        "DarkMode",
        JSON.stringify(state.colorTheme == "light" ? 0 : 1)
      );
    },
    setLanguage: (state, action) => {
      localStorage.setItem("Language", JSON.stringify(action.payload));
      state.language = action.payload;
    },
    clearUserPreferences: (state) => {
      state.colorTheme = "light";
    },
    setPreviousData: (state, action) => {
      state.previousData = action.payload;
    },
    setColumnsConfig: (state, action) => {
      state.columnsConfig = action.payload;
      localStorage.setItem("ColumnsConfig", JSON.stringify(action.payload));
    },
  },
});

export const {
  setTheme,
  setLanguage,
  setInitialTheme,
  clearUserPreferences,
  setPreviousData,
  setFabState,
  setSideDrawerState,
  setNewForm,
  setSubmitFunction,
  setColumnsConfig,
} = themeSlice.actions;

export default themeSlice.reducer;
