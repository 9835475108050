import Axios from "../network/Axios";

const addNewUser = async (data) => {
  const res = await Axios.post("/UsersManagement", data);

  return res.data;
};

const GetUsersManagementData = async () => {
  const response = await Axios.get("/UsersManagement");
  return response.data.result;
};

const GetUsersManagementFormData = async () => {
  const response = await Axios.get("/UsersManagement", {
    params: {
      State: "New",
    },
  });
  return response.data;
};

const CheckUsername = async (username) => {
  const response = await Axios.get("/UsersManagement", {
    params: {
      State: "ERPUserName",
      ERPUserName: username,
    },
  });
  return response.data;
};

const CheckNickname = async (nickname) => {
  const response = await Axios.get("/UsersManagement", {
    params: {
      State: "ERPUserNickName",
      ERPUserNickName: nickname,
    },
  });
  return response.data;
};

export const usersManagementService = {
  GetUsersManagementData,
  GetUsersManagementFormData,
  CheckUsername,
  CheckNickname,
  addNewUser,
};
