import { Box } from "@mui/material";
import { useQuery } from "@tanstack/react-query";
import Notifications from "../../components/charts/Notifications";
import NoDataView from "./NoDataView";
import { DashboardService } from "../../api/dashboard";
import AcceptanceSection from "./Sections/Acceptance/AcceptanceSection";
import OperationsSection from "./Sections/Operations/OperationsSection";
import FinancialSection from "./Sections/Financial/FinancialSection";
import HumanResourcesSection from "./Sections/HumanResources/HumanResourcesSection";
import Spinner from "../../components/Spinners/Spinner";

const Dashboard = () => {
  const { data, isLoading } = useQuery({
    queryKey: ["dashboardData"],
    queryFn: DashboardService.getDashboard,
    refetchInterval: 10000,
  });

  if (isLoading) {
    return <Spinner />;
  }

  const {
    AllWorkOrdersInProgressPerProject: workOrdersInProgressPerProject,
    AllWorkOrdersInProgressUser: workOrdersInProgressPerUser,
    AllMissionsInProgressPerProject: missionInProgressPerProject,
    AllMissionsInProgressPerUser: missionInProgressPerUser,
    GetCountNotInsured: socialNotInsured,
    CountInsured: socialInsured,
    CountCoveredMedical: medicalInsured,
    CountNotCoveredMedical: medicalNotInsured,
    AllQuotationsNotInvoiced: quotationInvoices,
    AllInvoicesWithNoPO: allNoPoInvoices,
    AllInvoicesWithNoGovernmentInvoice: allNoGovernmentInvoice,
    AllInvoicesWithNoSubmissionDate: allNoSubmissionDate,
    GetWorkOrdersPendingPerProject: workOrdersPendingPerProject,
    GetWorkOrdersPendingPerUser: workOrdersPendingPerUser,
    InvoicesSignatureAmount: invoicesSignatureAmount,
    InvoicesSignatureCount: invoicesSignatureCount,
    AcceptanceProgressPerProject: acceptanceProgressPerProject,
    AcceptanceProgressPerUser: acceptanceProgressPerUser,
    FinalAcceptanceNeedCreationPerProject,
    FinalAcceptanceNeedCreationPerUser,
    GetOpenAllPO: allPO,
    AcceptedWorkNotInvoicedCountPerProject:
      acceptedWorkNotInvoicedCountPerProject,
    AcceptedWorkNotInvoicedCountPerUser: acceptedWorkNotInvoicedCountPerUser,
    InvoicesPerAmount: invoicesPerAmount,
    InvoicesPerCount: invoicesPerCount,
    CompleteWorkWithoutAcceptancePerProject,
    CompleteWorkWithoutAcceptancePerTeamLeader,
    GetOpenNAPO: openNAPOs,
  } = data;

  // Notifications
  const notificationTypes = {
    quotationNotInvoiced: quotationInvoices?.[0]?.Count,
    invoicesWithNoPo: allNoPoInvoices?.[0]?.Count,
    noGovernorateInvoices: allNoGovernmentInvoice?.[0]?.Count,
    noSubmissionDateInvoices: allNoSubmissionDate?.[0]?.Count,
  };

  // If there is no data, show the NoDataView component
  const noDataExists = Object.values(data).every((value) => value.length === 0);

  // Check if acceptance data exists
  const doesAcceptanceDataExist =
    (CompleteWorkWithoutAcceptancePerProject.length > 0 &&
      CompleteWorkWithoutAcceptancePerTeamLeader.length > 0) ||
    (acceptanceProgressPerProject.length > 0 &&
      acceptanceProgressPerUser.length > 0) ||
    (FinalAcceptanceNeedCreationPerProject.length > 0 &&
      FinalAcceptanceNeedCreationPerUser.length > 0);

  // Check if Operations data exists
  const doesOperationsDataExist =
    (workOrdersPendingPerProject.length > 0 &&
      workOrdersPendingPerUser.length > 0) ||
    (workOrdersInProgressPerProject.length > 0 &&
      workOrdersInProgressPerUser.length > 0) ||
    (missionInProgressPerProject.length > 0 &&
      missionInProgressPerUser.length > 0);

  // Check if Financial data exists
  const doesFinancialDataExist =
    (invoicesSignatureAmount.length > 0 && invoicesSignatureCount.length > 0) ||
    allPO.length > 0 ||
    (invoicesPerAmount.length > 0 && invoicesPerCount.length > 0) ||
    openNAPOs.length > 0 ||
    (acceptedWorkNotInvoicedCountPerProject.length > 0 &&
      acceptedWorkNotInvoicedCountPerUser.length > 0);

  // Check if Human Resources data exists
  const doesHumanResourcesDataExist =
    socialInsured.length > 0 ||
    socialNotInsured.length > 0 ||
    medicalInsured.length > 0 ||
    medicalNotInsured.length > 0;

  if (noDataExists) return <NoDataView />;

  return (
    <Box
      sx={{
        display: "grid",
        gridTemplateColumns: "repeat(4, 1fr)",
        gap: "1rem",
        paddingBottom: "3rem",
      }}
    >
      <Box
        sx={{
          gridColumn: "span 3",
          display: "flex",
          gap: ".25rem",
          flexDirection: "column",
        }}
      >
        {doesOperationsDataExist && (
          <OperationsSection
            data={{
              workOrdersPendingPerProject,
              workOrdersPendingPerUser,
              workOrdersInProgressPerProject,
              workOrdersInProgressPerUser,
              missionInProgressPerProject,
              missionInProgressPerUser,
            }}
          />
        )}

        {doesAcceptanceDataExist && (
          <AcceptanceSection
            data={{
              CompleteWorkWithoutAcceptancePerProject,
              CompleteWorkWithoutAcceptancePerTeamLeader,
              acceptanceProgressPerProject,
              acceptanceProgressPerUser,
              FinalAcceptanceNeedCreationPerProject,
              FinalAcceptanceNeedCreationPerUser,
            }}
          />
        )}

        {doesFinancialDataExist && (
          <FinancialSection
            data={{
              invoicesSignatureAmount,
              invoicesSignatureCount,
              allPO,
              invoicesPerAmount,
              invoicesPerCount,
              acceptedWorkNotInvoicedCountPerProject,
              acceptedWorkNotInvoicedCountPerUser,
              openNAPOs,
            }}
          />
        )}

        {doesHumanResourcesDataExist && (
          <HumanResourcesSection
            data={{
              socialInsured,
              socialNotInsured,
              medicalInsured,
              medicalNotInsured,
            }}
          />
        )}
      </Box>
      <Box
        sx={{
          gridColumn: "span 1",
        }}
      >
        {Object.values(notificationTypes).some((count) => count) && (
          <Notifications {...notificationTypes} />
        )}
      </Box>
    </Box>
  );
};

export default Dashboard;
