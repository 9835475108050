import Axios from "../network/Axios";

const API_URL = "/InventoryManagement";

const AddNewItem = async (data) => {
  const res = await Axios.post(API_URL, data);

  return res.data;
};
const EditStore = async (data) => {
  const res = await Axios.put(API_URL, data);

  return res.data;
};

const checkNewStorePrivilege = async () => {
  const response = await Axios.get(API_URL, {
    params: { State: "NewStore" },
  });
  return response.data;
};
const getStores = async () => {
  const response = await Axios.get(API_URL, {
    params: { State: "Stores" },
  });
  return response.data.Stores;
};
const getStoresEdit = async (SelectedNo) => {
  const response = await Axios.get(API_URL, {
    params: { State: "EditStore", SelectedNo: SelectedNo },
  });
  return response.data;
};

const GetInventoryManagement = async () => {
  const response = await Axios.get(API_URL);
  return response.data.result;
};

const getReplacementsInventory = async () => {
  const response = await Axios.get(API_URL, {
    params: { State: "ReplacementGroups" },
  });
  return response.data.ReplacementGroups;
};

const editGroupName = async ({ GroupName, SelectedNo }) => {
  const response = await Axios.patch(API_URL, {
    State: "EditGroup",
    GroupName: GroupName,
    SelectedNo: SelectedNo,
  });
  return response.data;
};

export const InventoryManagementService = {
  GetInventoryManagement,
  AddNewItem,
  getReplacementsInventory,
  editGroupName,
  checkNewStorePrivilege,
  getStores,
  getStoresEdit,
  EditStore,
};
