import { useQuery } from "@tanstack/react-query";
import CustomDataGrid from "../components/DataGrid/CustomDataGrid";
import { useTranslation } from "react-i18next";
import Spinner from "../components/Spinners/Spinner";
import useOpenNewForm from "../hooks/useOpenNewForm";
import { useConfirmationDialog } from "../contexts/ConfirmationDialogContext";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { SitesDatabaseService } from "../api/sitesDatabase";
import dayjs from "dayjs";

const SitesDatabase = () => {
  const { newForm } = useSelector((state) => state.theme);

  const [t] = useTranslation("global");

  const columns = [
    {
      field: "CSiteCode",
      headerName: t("data_grid.headers.site_code"),
      width: 130,
      renderCell: (params) => {
        return (
          <Link
            style={{
              color: "#1790FF",
            }}
            to={`/sitesdatabase/${params.row.SiteName}?id=${params.row.idSitesDatabase}`}
          >
            {params.value}
          </Link>
        );
      },
    },
    {
      field: "SiteType",
      headerName: t("data_grid.headers.site_type"),
      width: 100,
    },
    {
      field: "SiteName",
      headerName: t("data_grid.headers.site_name"),
      width: 70,
      flex: 1,
    },
    {
      field: "Latitude",
      headerName: t("data_grid.headers.latitude"),
      width: 90,
    },
    {
      field: "Longitude",
      headerName: t("data_grid.headers.longitude"),
      width: 90,
    },
    {
      field: "OwnerContact",
      headerName: t("data_grid.headers.owner_contact"),
      width: 115,
    },
    {
      field: "OwnerName",
      headerName: t("data_grid.headers.owner_name"),
      width: 115,
    },
    {
      field: "SiteAddress",
      headerName: t("data_grid.headers.site_address"),
      width: 115,
    },
    {
      field: "Typology",
      headerName: t("data_grid.headers.typology"),
      width: 80,
    },
    {
      field: "GovernoratesName",
      headerName: t("data_grid.headers.governorate_name"),
      width: 110,
    },
    {
      field: "ERPUserNickName",
      headerName: t("data_grid.headers.created_by"),
      width: 120,
    },
    {
      field: "CreationDate",
      headerName: t("data_grid.headers.creation_date"),
      width: 100,
      type: "date",
      valueGetter: ({ value }) => (value ? new Date(value) : null),
      renderCell: ({ value }) => {
        return value ? dayjs(value).format("YYYY/MM/DD") : "";
      },
    },
  ];

  const {
    data: rows,
    isLoading,
    refetch,
    isRefetching,
  } = useQuery({
    queryKey: ["sitesDatabase"],
    queryFn: SitesDatabaseService.getSitesDatabase,
  });

  // Handling new form logic
  const { handleOpen } = useOpenNewForm();
  const { setOpen } = useConfirmationDialog();

  if (isLoading) {
    return <Spinner />;
  }

  return (
    <CustomDataGrid
      rows={rows}
      columns={columns}
      loading={isLoading}
      getRowId={(row) => row.idSitesDatabase}
      disableStatusTabs
      reloadFunction={refetch}
      showReloadOverlay={isRefetching}
      newButton
      newFunction={() => {
        if (newForm === "sites_database" || newForm === null) {
          handleOpen("sites_database");
        } else {
          setOpen(t("modules.new_form_confirmation_message"), () => () => {
            handleOpen("sites_database");
          });
        }
      }}
    />
  );
};

export default SitesDatabase;
