import { Box, Grid, TextField } from "@mui/material";
import { useTranslation } from "react-i18next";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";

const ModifyItems = ({ setFieldValue, handleSubmit, values }) => {
  const [t] = useTranslation("global");

  return (
    <Box padding="12px 10px">
      <form onSubmit={handleSubmit}>
        <Grid container spacing={1.2}>
          <Grid item xs={6}>
            <TextField
              label={t("info_modules.material_request.description.paper_no")}
              size="small"
              fullWidth
              value={values.paperNo}
              onChange={(e) => setFieldValue("paperNo", e.target.value)}
            />
          </Grid>
          <Grid item xs={6}>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DateTimePicker
                value={values.date}
                label={t("info_modules.material_request.description.date")}
                onChange={(newValue) => {
                  setFieldValue("date", newValue);
                }}
                sx={{
                  width: "29rem",
                }}
                format="YYYY/MM/DD"
                slotProps={{
                  textField: {
                    size: "small",
                  },
                }}
              />
            </LocalizationProvider>
          </Grid>
        </Grid>
      </form>
    </Box>
  );
};
export default ModifyItems;
