import { Box, Autocomplete, TextField } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useState } from "react";
import BasicDatePicker from "../../../DateAndTime/DatePicker";
import ImageModal from "../../../Modals/ImageModal";
import useImagePopup from "../../../../hooks/useImagePopup";
import Image from "@mui/icons-material/Image";
import Axios from "../../../../network/Axios";
import { GridActionsCellItem } from "@mui/x-data-grid-pro";
import CustomAccordion from "../../../Accordion/CustomAccordion";

const InvoiceDetails = ({
  poNo,
  type,
  errors,
  values,
  setFieldValue,
  touched,
  totalInstall,
  totalSupply,
  newInvoices,
  currentTab,
}) => {
  const [expanded, setExpanded] = useState("panel1");
  const [t] = useTranslation("global");
  const handleChange = (panel) => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false);
  };
  const fetchData = async (id) => {
    try {
      const response = await Axios.get("/Quotations", {
        params: {
          SelectedNo: id,
          State: "Document",
        },
      });
      return response.data.result;
    } catch (error) {
      console.log(error);
    }
  };
  const {
    isImageLoading,
    isImageModalOpen,
    image,
    handleImageModalOpen,
    handleCloseImageModal,
  } = useImagePopup(fetchData, "AcceptanceAttachement");

  const columns = [
    {
      field: "WorkOrderD6No",
      headerName: t("data_grid.headers.ref_no"),
      width: 150,
    },

    {
      field: "SiteName",
      headerName: t("data_grid.headers.site_name"),
      width: 90,
      flex: 1,
    },
    {
      field: "SiteCode",
      headerName: t("data_grid.headers.site_code"),
      width: 90,
      flex: 1,
    },
    {
      field: "CompanyProjectsName",
      headerName: t("data_grid.headers.project"),
      width: 90,
    },
    {
      field: "WorkOrderD6Amount",
      headerName: t("data_grid.headers.amount"),
      width: 80,
    },
    {
      field: "WorkOrderD6Supply",
      headerName: t("data_grid.headers.supply"),
      width: 80,
    },
    {
      field: "WorkOrderD6Install",
      headerName: t("data_grid.headers.install"),
      width: 80,
    },
    {
      field: "ERPUserNickName",
      headerName: t("data_grid.headers.created_by"),
      width: 90,
    },
    {
      field: "AcceptanceDate",
      headerName: t("data_grid.headers.date"),
      width: 80,
      valueFormatter: (params) => {
        const date = new Date(params.value);
        return date.toISOString().split("T")[0];
      },
    },
    {
      field: "RequestorsName",
      headerName: t("data_grid.headers.requestor"),
      width: 80,
    },
    {
      field: "TeamLeadersName",
      headerName: t("data_grid.headers.team_leader"),
      width: 80,
    },
    {
      field: "Actions",
      type: "actions",
      headerName: t("data_grid.headers.actions"),
      width: 60,
      getActions: (params) => [
        <GridActionsCellItem
          key="image"
          icon={<Image />}
          label="Image"
          onClick={() => {
            handleImageModalOpen(params.row.WorkOrderD6No);
          }}
        />,
      ],
    },
  ];
  return (
    <Box
      display="flex"
      flexDirection="column"
      gap="13px"
      margin="10px 5px"
      marginBottom="20px"
    >
      <Box display="flex" gap="14px" marginTop="8px">
        <Autocomplete
          options={poNo}
          getOptionLabel={(option) => option.ClientPONo}
          sx={{ width: "24%" }}
          size="small"
          onChange={(event, value) => {
            setFieldValue("selectedPoNo", value?.ClientPONo);
          }}
          renderInput={(params) => (
            <TextField
              {...params}
              label={t("modules.client_invoice.fields.select_po")}
              error={touched.selectedPoNo && Boolean(errors.selectedPoNo)}
              helperText={touched.selectedPoNo && errors.selectedPoNo}
            />
          )}
        />
        <Autocomplete
          options={type}
          getOptionLabel={(option) => option.Activity}
          sx={{ width: "24%" }}
          size="small"
          onChange={(event, value) => {
            setFieldValue("selectedType", value?.Activity);
          }}
          renderInput={(params) => (
            <TextField
              {...params}
              label={t("modules.client_invoice.fields.select_type")}
              error={touched.selectedType && Boolean(errors.selectedType)}
              helperText={touched.selectedType && errors.selectedType}
            />
          )}
        />
        <BasicDatePicker
          dateChange={values.d6Date}
          handleDateChange={(date) => setFieldValue("d6Date", date)}
          width="14rem"
          label={t("modules.client_invoice.fields.d6_date")}
        />
        <BasicDatePicker
          dateChange={values.invoiceDate}
          handleDateChange={(date) => setFieldValue("invoiceDate", date)}
          width="14rem"
          label={t("modules.client_invoice.fields.invoice_date")}
        />
      </Box>
      {(currentTab === 0 || currentTab === 1) && (
        <Box display="flex" gap="18px">
          <TextField
            name="supplySerial"
            label={t("modules.client_invoice.fields.supply_serial")}
            variant="outlined"
            size="small"
            fullWidth
            value={values.supplySerial}
            onChange={(value) => {
              setFieldValue("supplySerial", value.target.value);
            }}
            error={touched.supplySerial && Boolean(errors.supplySerial)}
            helperText={touched.supplySerial && errors.supplySerial}
          />
          <TextField
            name="totalSupply"
            label={t("modules.client_invoice.fields.supply_amount")}
            variant="outlined"
            size="small"
            fullWidth
            value={totalSupply}
            InputProps={{
              readOnly: true,
            }}
          />
        </Box>
      )}
      {(currentTab === 0 || currentTab === 2) && (
        <Box display="flex" gap="18px">
          <TextField
            name="installSerial"
            label={t("modules.client_invoice.fields.install_serial")}
            variant="outlined"
            size="small"
            fullWidth
            value={values.installSerial}
            onChange={(value) => {
              setFieldValue("installSerial", value.target.value);
            }}
            error={touched.installSerial && Boolean(errors.installSerial)}
            helperText={touched.installSerial && errors.installSerial}
          />
          <TextField
            name="totalInstall"
            label={t("modules.client_invoice.fields.install_amount")}
            variant="outlined"
            size="small"
            fullWidth
            value={totalInstall}
            InputProps={{
              readOnly: true,
            }}
          />
        </Box>
      )}

      <CustomAccordion
        title={t("modules.material_request.label.work_orders")}
        rows={newInvoices}
        columns={columns}
        height={230}
        getRowId={(row) => row.idWorkOrderD6}
        hideFooter
        expanded={expanded === "panel1"}
        onChange={handleChange("panel1")}
      />
      <ImageModal
        open={isImageModalOpen}
        handleClose={handleCloseImageModal}
        image={image}
        loading={isImageLoading}
      />
    </Box>
  );
};
export default InvoiceDetails;
