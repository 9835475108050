import { useState } from "react";

const useGetRowClassName = (newRows) => {
  const [applyRowClassName, setApplyRowClassName] = useState(false);

  const getRowClassName = (params) => {
    const { idStoreItemsTemplateInfo } = params.row;
    const isInvalidRow = newRows.find(
      (row) =>
        row.idStoreItemsTemplateInfo === idStoreItemsTemplateInfo &&
        (row.Quantity <= 0 || row.Quantity === "" || !row.Quantity)
    );
    return applyRowClassName && isInvalidRow ? "read-only-row" : "";
  };

  return { getRowClassName, setApplyRowClassName };
};

export default useGetRowClassName;
