import * as d3 from "d3";
import Edit from "./Modals/Edit";
import { Box, Chip } from "@mui/material";
import { useSnackbar } from "notistack";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import NewAccount from "./Modals/NewAccount";
import NewProject from "./Modals/NewProject";
import NewSubProject from "./Modals/NewSubProject";
import { useEffect, useRef, useState } from "react";
import Spinner from "../../components/Spinners/Spinner";
import { AccountsProjectsService } from "../../api/accounts&projects";
import { useQuery, useMutation, useQueryClient } from "@tanstack/react-query";
import { useConfirmationDialog } from "../../contexts/ConfirmationDialogContext";

const CollapsibleTree = () => {
  const svgRef = useRef();
  const { setOpen } = useConfirmationDialog();
  const theme = useSelector((state) => state.theme.colorTheme);
  const queryClient = useQueryClient();
  const { enqueueSnackbar } = useSnackbar();
  const [t] = useTranslation("global");
  const [modals, setModals] = useState({
    openNewAccount: false,
    openNewProject: false,
    openNewSubProject: false,
    openEdit: false,
  });

  const handleModalToggle = (modalName, value, name, node) => {
    setModals((prevModals) => ({
      ...prevModals,
      [modalName]: value,
      currentName: name,
      currentNode: node,
    }));
  };
  const getLabel = (d) => {
    if (d.depth === 1) return "Account Name";
    if (d.depth === 2) return "Project Name";
    return "Sub Project Name";
  };
  const getTitles = (d) => {
    if (d.depth === 1)
      return t("modules.accounts_projects.modals.edit_account");
    if (d.depth === 2)
      return t("modules.accounts_projects.modals.edit_project");
    return t("modules.accounts_projects.modals.edit_sub_project");
  };
  const { data: rows, isLoading } = useQuery({
    queryKey: ["accountsProjects"],
    queryFn: AccountsProjectsService.getAllAccountsProjects,
  });

  const useDeletionMutation = (deleteFunction) => {
    const { mutateAsync } = useMutation({
      mutationFn: async (selectedName) => {
        return deleteFunction({ SelectedNo: selectedName });
      },
      onSuccess: (data) => {
        if (!data) return;
        enqueueSnackbar(data.msg, { variant: "success" });
        queryClient.invalidateQueries(["accountsProjects"]);
      },
    });

    return { mutateAsync };
  };

  const { mutateAsync: handleDeleteAccount } = useDeletionMutation(
    AccountsProjectsService.deleteAccount
  );

  const { mutateAsync: handleDeleteProject } = useDeletionMutation(
    AccountsProjectsService.deleteProject
  );

  const { mutateAsync: handleDeleteSubProject } = useDeletionMutation(
    AccountsProjectsService.deleteSubProject
  );

  useEffect(() => {
    const transformData = (rows) => {
      const treeData = [];
      rows?.forEach((row) => {
        const { EmployeeCount, WorkOrderCount } = row;
        if (!row.CompanyAccountName) return; // Skip null account names

        let companyNode = treeData.find(
          (item) => item.name === row.CompanyAccountName
        );
        if (!companyNode) {
          companyNode = {
            name: row.CompanyAccountName,
            children: [],
            empCount: EmployeeCount,
            workOrderCount: WorkOrderCount,
          };
          treeData.push(companyNode);
        }

        if (row.CompanyProjectsName) {
          let projectNode = companyNode.children.find(
            (item) => item.name === row.CompanyProjectsName
          );
          if (!projectNode) {
            projectNode = {
              name: row.CompanyProjectsName,
              children: [],
              empCount: EmployeeCount,
              workOrderCount: WorkOrderCount,
            };
            companyNode.children.push(projectNode);
          }

          if (row.SubProjectsName) {
            projectNode.children.push({
              name: row.SubProjectsName,
              empCount: EmployeeCount,
              workOrderCount: WorkOrderCount,
            });
          }
        }
      });
      return { name: "Company", children: treeData };
    };

    const data = transformData(rows);

    const svg = d3.select(svgRef.current);
    svg.selectAll("*").remove();

    // Adjusted dimensions for the SVG
    const width = 1750;
    const marginTop = 35;
    const marginBottom = 40;
    const marginLeft = -140;
    const root = d3.hierarchy(data);

    let dx = 54; // Reduced distance between nodes
    const dy = width / (1 + root.height);
    const tree = d3.tree().nodeSize([dx, dy]);
    const diagonal = d3
      .linkHorizontal()
      .x((d) => d.y)
      .y((d) => d.x);

    svg
      .attr("width", width)
      .attr("height", dx)
      .attr("viewBox", [-marginLeft, -marginTop, width, dx])
      .attr(
        "style",
        "max-width: 100%; height: auto; font: 8px sans-serif; user-select: none"
      );

    const gLink = svg
      .append("g")
      .attr("fill", "none")
      .attr("stroke", theme === "dark" ? "white" : "#888") // Change lines
      .attr("stroke-opacity", 0.6)
      .attr("stroke-width", 1.2);

    const gNode = svg
      .append("g")
      .attr("cursor", "pointer")
      .attr("pointer-events", "all");

    const update = (event, source) => {
      const duration = event?.altKey ? 2000 : 500;
      const nodes = root.descendants().reverse();
      const links = root.links();

      tree(root);

      let left = root;
      let right = root;
      root.eachBefore((node) => {
        if (node.x < left.x) left = node;
        if (node.x > right.x) right = node;
      });

      const height = right.x - left.x + marginTop + marginBottom;

      svg
        .transition()
        .duration(duration)
        .attr("height", height)
        .attr("viewBox", [-marginLeft, left.x - marginTop, width, height]);

      const node = gNode.selectAll("g").data(nodes, (d) => d.id);
      const nodeEnter = node
        .enter()
        .append("g")
        .attr("transform", (d) => `translate(${source.y0},${source.x0})`)
        .attr("fill-opacity", 0)
        .attr("stroke-opacity", 0)
        .on("click", (event, d) => {
          d.children = d.children ? null : d._children;
          update(event, d);
        });

      const tooltip = d3
        .select("body")
        .append("div")
        .style("position", "absolute")
        .style("visibility", "hidden")
        .style("background", theme === "dark" ? "#c7c7c7" : "#f9f9f9")
        .style("color", "black") // Text color
        .style("padding", "2px 4px")
        .style("border-radius", "3px")
        .style("font-size", "12px")
        .style("pointer-events", "none")
        .style("box-shadow", "0 2px 5px rgba(0,0,0,0.2)");

      const iconSize = 24;
      const iconYOffset = 8;

      // Add a box behind the text and icon
      nodeEnter
        .append("rect")
        .attr("x", -95)
        .attr("y", -15)
        .attr("width", 320)
        .attr("height", 50)
        .attr("fill", theme === "dark" ? "#1E1E1E" : "#f9f9f9")
        .attr("stroke", "#888")
        .attr("rx", 5)
        .attr("ry", 5);

      // Append the text
      const textNode = nodeEnter
        .append("text")
        .attr("dy", "0.15em")
        .attr("x", -90)
        .attr("text-anchor", "start")
        .style("font-size", "14px")
        .style("font-family", "Verdana, sans-serif")
        .style("font-weight", "bold")
        .style("fill", "white")
        .text((d) => d.data.name)
        .attr("stroke-linejoin", "round");

      // Add a background rect specifically for the text
      nodeEnter
        .insert("rect", "text")
        .attr("x", -95)
        .attr("y", -15)
        .attr("width", 320)
        .attr("height", 22)
        .attr("fill", theme === "dark" ? "#565656" : "#8f8f8f")
        .attr("rx", 4)
        .attr("ry", 4);

      // Add icons for "Add Account" for root nodes
      nodeEnter
        .filter((d) => d.depth === 0)
        .append("image")
        .attr("xlink:href", "/add.png")
        .attr("x", 195)
        .attr("y", iconYOffset)
        .attr("width", iconSize)
        .attr("height", iconSize)
        .on("mouseover", () =>
          tooltip.style("visibility", "visible").text("Add Account")
        )
        .on("mousemove", (event) =>
          tooltip
            .style("top", event.pageY + 5 + "px")
            .style("left", event.pageX + 5 + "px")
        )
        .on("mouseout", () => tooltip.style("visibility", "hidden"))
        .on("click", (event, d) => {
          event.stopPropagation(); // Prevent click from collapsing/expanding tree
          handleModalToggle("openNewAccount", true);
        });
      // Count the number of children for each node
      nodeEnter
        .append("text")
        .attr("dy", "1.75em")
        .attr("x", -90)
        .attr("text-anchor", "start")
        .style("font-size", "14px")
        .style("font-family", "Verdana, sans-serif")
        .style("fill", theme === "dark" ? "white" : "#393939")
        .text((d) => {
          if (!d._children) return "";
          let label;
          if (d.depth === 0) {
            label = "Accounts:";
          } else if (d.depth === 1) {
            label = "Projects:";
          } else {
            label = "Sub Projects:";
          }
          return `${label} ${d._children.length}`;
        })
        .attr("stroke-linejoin", "round");
      // projects => emp
      nodeEnter
        .filter((d) => d.depth === 2 || d.depth === 3)
        .append("text")
        .attr("dy", "1.75em")
        .attr("x", (d) => (d._children ? 40 : -90))
        .attr("text-anchor", "start")
        .style("font-size", "14px")
        .style("font-family", "Verdana, sans-serif")
        .style("fill", theme === "dark" ? "white" : "#565656")
        .text((d) => {
          if (d.depth === 2) {
            return d.data.empCount ? `Employees: ${d.data.empCount}` : "";
          } else if (d.depth === 3) {
            return d.data.workOrderCount
              ? `Work Orders: ${d.data.workOrderCount}`
              : "";
          }
          return "";
        });
      // Add icons for "Add Project" for project nodes
      nodeEnter
        .filter((d) => d.depth === 1)
        .append("image")
        .attr("xlink:href", "/add.png")
        .attr("x", (d) => (d._children ? 175 : 152))
        .attr("y", iconYOffset)
        .attr("width", iconSize)
        .attr("height", iconSize)
        .on("mouseover", () =>
          tooltip.style("visibility", "visible").text("Add Project")
        )
        .on("mousemove", (event) =>
          tooltip
            .style("top", event.pageY + 5 + "px")
            .style("left", event.pageX + 5 + "px")
        )
        .on("mouseout", () => tooltip.style("visibility", "hidden"))
        .on("click", (event, d) => {
          event.stopPropagation();
          handleModalToggle("openNewProject", true, d.data.name);
        });
      // Add icons for "Add Project" for project nodes
      nodeEnter
        .filter((d) => d.depth === 1)
        .append("image")
        .attr("xlink:href", theme === "dark" ? "edit-dark.png" : "/edit.png")
        .attr("x", (d) => (d._children ? 198 : 175))
        .attr("y", iconYOffset)
        .attr("width", iconSize)
        .attr("height", iconSize)
        .on("mouseover", () =>
          tooltip.style("visibility", "visible").text("Edit Account")
        )
        .on("mousemove", (event) =>
          tooltip
            .style("top", event.pageY + 5 + "px")
            .style("left", event.pageX + 5 + "px")
        )
        .on("mouseout", () => tooltip.style("visibility", "hidden"))
        .on("click", (event, d) => {
          event.stopPropagation();
          handleModalToggle("openEdit", true, d.data.name, d);
        });
      nodeEnter
        .filter((d) => d.depth === 1 && !d._children)
        .append("image")
        .attr("xlink:href", "/delete.png")
        .attr("x", 198)
        .attr("y", iconYOffset)
        .attr("width", iconSize)
        .attr("height", iconSize)
        .on("mouseover", () =>
          tooltip.style("visibility", "visible").text("Delete Account")
        )
        .on("mousemove", (event) =>
          tooltip
            .style("top", event.pageY + 5 + "px")
            .style("left", event.pageX + 5 + "px")
        )
        .on("mouseout", () => tooltip.style("visibility", "hidden"))
        .on("click", (event, d) => {
          event.stopPropagation();
          setOpen(
            t("modules.accounts_projects.confirmation.delete_account"),
            () => () => {
              handleDeleteAccount(d.data.name);
            }
          );
        });
      nodeEnter
        .filter((d) => d.depth === 2)
        .append("image")
        .attr("xlink:href", "/add.png")
        .attr("x", (d) => (d._children || d.data.empCount !== 0 ? 175 : 152))
        .attr("y", iconYOffset)
        .attr("width", iconSize)
        .attr("height", iconSize)
        .on("mouseover", () =>
          tooltip.style("visibility", "visible").text("Add Sub Project")
        )
        .on("mousemove", (event) =>
          tooltip
            .style("top", event.pageY + 5 + "px")
            .style("left", event.pageX + 5 + "px")
        )
        .on("mouseout", () => tooltip.style("visibility", "hidden"))
        .on("click", (event, d) => {
          event.stopPropagation();
          handleModalToggle("openNewSubProject", true, d.data.name);
        });
      // Add icons for "Add Project" for project nodes
      nodeEnter
        .filter((d) => d.depth === 2)
        .append("image")
        .attr("xlink:href", theme === "dark" ? "edit-dark.png" : "/edit.png")
        .attr("x", (d) => (d._children || d.data.empCount !== 0 ? 198 : 175))
        .attr("y", iconYOffset)
        .attr("width", iconSize)
        .attr("height", iconSize)
        .on("mouseover", () =>
          tooltip.style("visibility", "visible").text("Edit Project")
        )
        .on("mousemove", (event) =>
          tooltip
            .style("top", event.pageY + 5 + "px")
            .style("left", event.pageX + 5 + "px")
        )
        .on("mouseout", () => tooltip.style("visibility", "hidden"))
        .on("click", (event, d) => {
          event.stopPropagation();
          handleModalToggle("openEdit", true, d.data.name, d);
        });
      nodeEnter
        .filter((d) => d.depth === 2 && !d._children && d.data.empCount === 0)
        .append("image")
        .attr("xlink:href", "/delete.png")
        .attr("x", 198)
        .attr("y", iconYOffset)
        .attr("width", iconSize)
        .attr("height", iconSize)
        .on("mouseover", () =>
          tooltip.style("visibility", "visible").text("Delete Project")
        )
        .on("mousemove", (event) =>
          tooltip
            .style("top", event.pageY + 5 + "px")
            .style("left", event.pageX + 5 + "px")
        )
        .on("mouseout", () => tooltip.style("visibility", "hidden"))
        .on("click", (event, d) => {
          event.stopPropagation();
          setOpen(
            t("modules.accounts_projects.confirmation.delete_project"),
            () => () => {
              handleDeleteProject(d.data.name);
            }
          );
        });

      // Add icons for "Add Project" for project node
      nodeEnter
        .filter((d) => d.depth === 3)
        .append("image")
        .attr("xlink:href", theme === "dark" ? "edit-dark.png" : "/edit.png")
        .attr("x", (d) => (d.data.workOrderCount !== 0 ? 200 : 180))
        .attr("y", iconYOffset)
        .attr("width", iconSize)
        .attr("height", iconSize)
        .on("mouseover", () =>
          tooltip.style("visibility", "visible").text("Edit Sub Project")
        )
        .on("mousemove", (event) =>
          tooltip
            .style("top", event.pageY + 5 + "px")
            .style("left", event.pageX + 5 + "px")
        )
        .on("mouseout", () => tooltip.style("visibility", "hidden"))
        .on("click", (event, d) => {
          event.stopPropagation();
          handleModalToggle("openEdit", true, d.data.name, d);
        });
      nodeEnter
        .filter(
          (d) => d.depth === 3 && !d._children && d.data.workOrderCount === 0
        )
        .append("image")
        .attr("xlink:href", "/delete.png")
        .attr("x", 200)
        .attr("y", iconYOffset)
        .attr("width", iconSize)
        .attr("height", iconSize)
        .on("mouseover", () =>
          tooltip.style("visibility", "visible").text("Delete Sub Project")
        )
        .on("mousemove", (event) =>
          tooltip
            .style("top", event.pageY + 5 + "px")
            .style("left", event.pageX + 5 + "px")
        )
        .on("mouseout", () => tooltip.style("visibility", "hidden"))
        .on("click", (event, d) => {
          event.stopPropagation();
          setOpen(
            t("modules.accounts_projects.confirmation.delete_sub_project"),
            () => () => {
              handleDeleteSubProject(d.data.name);
            }
          );
        });

      const nodeUpdate = node
        .merge(nodeEnter)
        .transition()
        .duration(duration)
        .attr("transform", (d) => {
          const xOffset = 260;
          return `translate(${d.y + xOffset},${d.x - 10})`;
        })
        .attr("fill-opacity", 1)
        .attr("stroke-opacity", 1);

      const nodeExit = node
        .exit()
        .transition()
        .duration(duration)
        .attr("transform", (d) => `translate(${source.y},${source.x})`)
        .attr("fill-opacity", 0)
        .attr("stroke-opacity", 0)
        .remove();

      const boxWidth = 395;

      // Update the diagonal link generator to draw lines from the right side of the box
      const diagonal = d3
        .linkHorizontal()
        .x((d) => d.y + boxWidth / 2) // Start from the right side of the node
        .y((d) => d.x);

      const link = gLink.selectAll("path").data(links, (d) => d.target.id);

      link
        .enter()
        .append("path")
        .attr("d", (d) => {
          const o = { x: source.x0, y: source.y0 + boxWidth / 2 };
          return diagonal({ source: o, target: o });
        })
        .merge(link)
        .transition()
        .duration(duration)
        .attr("d", diagonal);

      link
        .exit()
        .transition()
        .duration(duration)
        .attr("d", (d) => {
          const o = { x: source.x, y: source.y + boxWidth / 2 }; // Update the exit position
          return diagonal({ source: o, target: o });
        });

      root.eachBefore((d) => {
        d.x0 = d.x;
        d.y0 = d.y;
      });
    };

    root.x0 = dy / 2;
    root.y0 = 0;
    root.descendants().forEach((d, i) => {
      d.id = i;
      d._children = d.children;
    });

    update(null, root);
  }, [rows, isLoading, theme]);

  return (
    <>
      {isLoading ? (
        <Spinner />
      ) : (
        <Box>
          <Box display="flex" position="relative" marginBottom={3}>
            {["Accounts", "Projects", "Sub Projects"].map((label, index) => (
              <Chip
                key={label}
                label={label}
                size="small"
                style={{
                  position: "absolute",
                  left: index === 0 ? "26.5%" : index === 1 ? "51.5%" : "76.5%",
                  borderRadius: 5,
                  width: "18%",
                }}
              />
            ))}
          </Box>
          <svg ref={svgRef}>
            <NewAccount
              open={modals.openNewAccount}
              handleClose={() => handleModalToggle("openNewAccount", false)}
            />
            <NewProject
              open={modals.openNewProject}
              handleClose={() => handleModalToggle("openNewProject", false)}
              accountName={modals.currentName}
            />
            <NewSubProject
              open={modals.openNewSubProject}
              handleClose={() => handleModalToggle("openNewSubProject", false)}
              projectName={modals.currentName}
            />
            <Edit
              open={modals.openEdit}
              handleClose={() => handleModalToggle("openEdit", false)}
              label={modals.currentNode ? getLabel(modals.currentNode) : ""}
              title={modals.currentNode ? getTitles(modals.currentNode) : ""}
              value={modals.currentName}
            />
          </svg>
        </Box>
      )}
    </>
  );
};

export default CollapsibleTree;
