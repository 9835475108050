import { Autocomplete, Stack, TextField } from "@mui/material";
import { Controller } from "react-hook-form";
import ButtonGroup from "../ButtonGroup/ButtonGroup";
import { useTranslation } from "react-i18next";

const AddNewLineForm = ({
  onSubmit,
  isPending,
  onClickClose,
  data,
  packages,
  accounts,
  isRefetchingPackages,
  handleCompanyPhoneLinesOperatorNameChange,
  register,
  handleSubmit,
  control,
  errors,
  setValue,
}) => {
  const [t] = useTranslation("global");

  const typeOptions = ["Line", "Susp", "Data", "GPS", "Unknown"];
  const accountNumberOptions =
    accounts?.map((item) => item.CompanyPhoneLinesAccountNo) || [];
  const operatorNameOptions =
    data?.CompanyPhoneLinesOperatorName.map(
      (item) => item.CompanyPhoneLinesOperatorName
    ) || [];
  const packagesOptions =
    packages?.map((item) => item.CompanyPhoneLinesPackageName) || [];

  return (
    <form
      onSubmit={handleSubmit(onSubmit)}
      style={{ width: "100%" }}
      noValidate
    >
      <Stack spacing={2} padding="10px 3px">
        <TextField
          size="small"
          label={t(
            "modules.mobile_internet_lines.new_form.fields.phone_number"
          )}
          error={Boolean(errors.phoneNumber)}
          helperText={errors.phoneNumber?.message}
          {...register("phoneNumber")}
          required
        />
        <Controller
          control={control}
          name={`type`}
          render={({ field: { onChange, value } }) => (
            <Autocomplete
              size="small"
              options={typeOptions}
              onChange={(event, item) => {
                onChange(item);
              }}
              value={value || null}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label={t(
                    "modules.mobile_internet_lines.new_form.fields.type"
                  )}
                  error={!!errors.type}
                  helperText={errors.type?.message}
                  required
                />
              )}
            />
          )}
        />

        <Controller
          control={control}
          name={`operatorName`}
          render={({ field: { onChange, value } }) => (
            <Autocomplete
              size="small"
              options={operatorNameOptions}
              onChange={(event, item) => {
                onChange(item);
                setValue("package", "");
                setValue("accountNumber", "");
                handleCompanyPhoneLinesOperatorNameChange(item);
              }}
              value={value || null}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label={t(
                    "modules.mobile_internet_lines.new_form.fields.operator"
                  )}
                  error={!!errors.operatorName}
                  helperText={errors.operatorName?.message}
                  required
                />
              )}
            />
          )}
        />
        <Controller
          control={control}
          name={`accountNumber`}
          render={({ field: { onChange, value } }) => (
            <Autocomplete
              size="small"
              options={accountNumberOptions}
              onChange={(event, item) => {
                onChange(item);
              }}
              value={value || null}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label={t(
                    "modules.mobile_internet_lines.new_form.fields.account_number"
                  )}
                  error={!!errors.accountNumber}
                  helperText={errors.accountNumber?.message}
                  required
                />
              )}
            />
          )}
        />

        <Controller
          control={control}
          name={`package`}
          render={({ field: { onChange, value } }) => (
            <Autocomplete
              size="small"
              disabled={isRefetchingPackages}
              options={packagesOptions}
              onChange={(event, item) => {
                onChange(item);
              }}
              value={value || null}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label={t(
                    "modules.mobile_internet_lines.new_form.fields.package"
                  )}
                  error={!!errors.package}
                  helperText={errors.package?.message}
                  required
                />
              )}
            />
          )}
        />

        <TextField
          size="small"
          label={t(
            "modules.mobile_internet_lines.new_form.fields.mobile_internet"
          )}
          error={Boolean(errors.mobileInternet)}
          helperText={errors.mobileInternet?.message}
          {...register("mobileInternet")}
          required
        />
      </Stack>

      <ButtonGroup
        isPending={isPending}
        onClickClose={onClickClose}
        saveLabel={t("modules.buttons.save")}
      />
    </form>
  );
};

export default AddNewLineForm;
