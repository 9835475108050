import {
  Backdrop,
  Button,
  Fade,
  Modal,
  Stack,
  Typography,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";
import { useTranslation } from "react-i18next";
import { memo } from "react";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  backgroundColor: "background.default",
  boxShadow:
    "rgba(145, 158, 171, 0.24) 0px 0px 2px 0px, rgba(145, 158, 171, 0.24) -20px 20px 40px -4px",
  borderRadius: "10px",
  p: 3,
  "&:focus-visible": {
    outline: "none",
  },
};

const buttonStyles = {
  alignSelf: "center",
  fontSize: 16,
  textTransform: "capitalize",
  px: 1.5,
  borderRadius: 2,
};

const ConfirmationModal = memo(
  ({
    open,
    handleClose,
    confirmFunction,
    confirmModalMessage,
    isLoading,
    color = "success",
    cancelColor = "error",
    cancelLabel = "modules.buttons.no",
  }) => {
    const [t] = useTranslation("global");

    const handleConfirm = () => {
      if (confirmFunction) {
        confirmFunction();
      }
    };

    return (
      <Modal
        aria-labelledby="confirmation-modal"
        aria-describedby="confirmation-modal-description"
        open={open}
        closeAfterTransition
        slots={{ backdrop: Backdrop }}
        slotProps={{
          backdrop: {
            timeout: 500,
          },
        }}
      >
        <Fade in={open}>
          <Stack sx={style} spacing={3}>
            <Stack direction={"row"} spacing={2}>
              <Typography id="transition-modal-description">
                {confirmModalMessage}
              </Typography>
            </Stack>
            <Stack
              direction={"row"}
              spacing={2}
              sx={{
                justifyContent: "center",
                width: "100%",
              }}
            >
              <LoadingButton
                loading={isLoading}
                disabled={isLoading}
                variant="contained"
                color={color}
                sx={{ ...buttonStyles, color: "#fff" }}
                onClick={handleConfirm}
              >
                {t("confirmation_modal.buttons.yes")}
              </LoadingButton>
              <Button
                variant="contained"
                color={cancelColor}
                sx={{
                  ...buttonStyles,
                  color: cancelColor !== "secondary" && "#fff",
                }}
                onClick={handleClose}
              >
                {t(cancelLabel)}
              </Button>
            </Stack>
          </Stack>
        </Fade>
      </Modal>
    );
  }
);

export default ConfirmationModal;
