import {
  Box,
  FormLabel,
  Stack,
  TextField,
  ToggleButton,
  ToggleButtonGroup,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import { Controller } from "react-hook-form";

const ItemDescription = ({ errors, register, control }) => {
  const [t] = useTranslation("global");

  return (
    <Stack spacing={2} p={2}>
      <Box
        sx={{
          display: "grid",
          gridTemplateColumns: "repeat(3, 1fr)",
          gap: "16px",
        }}
      >
        <Box
          sx={{
            gridColumn: "span 3",
            display: "grid",
            gridTemplateColumns: "repeat(3, 1fr)",
            gap: "16px",
          }}
        >
          <TextField
            size="small"
            label={t("modules.inventory_management.new_form.fields.item_name")}
            {...register("itemName")}
            error={Boolean(errors.itemName)}
            helperText={errors.itemName?.message}
            required
          />
          <TextField
            size="small"
            label={t(
              "modules.inventory_management.new_form.fields.item_name_en"
            )}
            {...register("itemNameEn")}
            error={Boolean(errors.itemNameEn)}
            helperText={errors.itemNameEn?.message}
            required
          />
          <TextField
            size="small"
            label={t("modules.inventory_management.new_form.fields.item_code")}
            {...register("itemCode")}
            error={Boolean(errors.itemCode)}
            helperText={errors.itemCode?.message}
            required
          />
        </Box>
        <TextField
          size="small"
          label={t("modules.inventory_management.new_form.fields.description")}
          {...register("description")}
          error={Boolean(errors.description)}
          helperText={errors.description?.message}
          multiline
          rows={4}
          sx={{
            gridColumn: "span 3",
          }}
          required
        />

        <TextField
          size="small"
          label={t("modules.inventory_management.new_form.fields.unit")}
          {...register("unit")}
          error={Boolean(errors.unit)}
          helperText={errors.unit?.message}
          required
        />
        <TextField
          size="small"
          label={t("modules.inventory_management.new_form.fields.length")}
          {...register("length")}
          error={Boolean(errors.length)}
          helperText={errors.length?.message}
          required
        />
        <TextField
          size="small"
          label={t("modules.inventory_management.new_form.fields.min_quantity")}
          {...register("minQuantity")}
          error={Boolean(errors.minQuantity)}
          helperText={errors.minQuantity?.message}
          required
        />

        <Stack direction={"row"} spacing={2} alignItems={"center"}>
          <FormLabel>
            {t("modules.inventory_management.new_form.fields.type.title")}:
          </FormLabel>
          <Controller
            control={control}
            name="type"
            render={({ field: { onChange, value } }) => (
              <ToggleButtonGroup
                size="small"
                color="primary"
                exclusive
                aria-label="type"
                value={value}
                onChange={(event, newValue) => {
                  onChange(newValue);
                }}
              >
                <ToggleButton
                  size="small"
                  value="Item"
                  sx={{
                    textTransform: "capitalize",
                  }}
                >
                  {t(
                    "modules.inventory_management.new_form.fields.type.options.item"
                  )}
                </ToggleButton>
                <ToggleButton
                  size="small"
                  value="Template"
                  sx={{
                    textTransform: "capitalize",
                  }}
                >
                  {t(
                    "modules.inventory_management.new_form.fields.type.options.template"
                  )}
                </ToggleButton>
              </ToggleButtonGroup>
            )}
          />
        </Stack>
      </Box>
    </Stack>
  );
};

export default ItemDescription;
