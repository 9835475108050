import Axios from "../network/Axios";

const updateColumnsVisibilityModel = async (data) => {
  const res = await Axios.put("/ColumnsConfig", {
    ColumnsConfig: JSON.stringify(data),
  });
  return res.data;
};

export const ColumnsConfigService = {
  updateColumnsVisibilityModel,
};
