import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { getFinalArray, sortUserRoles } from "./utils";
import { useState } from "react";
import { useSnackbar } from "notistack";
import FormSpinner from "../../../components/Spinners/FormSpinner";
import { ERPSystemRolesService } from "../../../api/ERPSystemRoles";
import { UserRolePrivilegesTree } from "./common";

const SingleUserRole = ({ onClose, userRole, disableEdit, setDisableEdit }) => {
  const { data, isLoading, isRefetching } = useQuery({
    queryKey: ["userRole"],
    queryFn: () => ERPSystemRolesService.getSingleUserRole(userRole),
  });

  if (isLoading || isRefetching) return <FormSpinner />;

  return (
    <SingleUserRoleData
      data={data}
      onClose={onClose}
      userRole={userRole}
      usersList={data}
      disableEdit={disableEdit}
      setDisableEdit={setDisableEdit}
    />
  );
};

const SingleUserRoleData = ({
  data,
  onClose,
  userRole,
  disableEdit,
  setDisableEdit,
}) => {
  const { enqueueSnackbar } = useSnackbar();
  const queryClient = useQueryClient();

  // Handle user role state
  const [currentUserRole, setCurrentUserRole] = useState(userRole);

  // Handle errors
  const [error, setError] = useState({
    error: false,
    message: "",
  });

  // Handle is touched
  const [isTouched, setIsTouched] = useState(false);

  const handleUserRoleChange = (event) => {
    setIsTouched(true);
    setCurrentUserRole(event.target.value);
  };

  const checkedItems = data.result
    .filter((item) => item.Checked === 1)
    .map((item) =>
      [item.SystemPrivilegeName, item.idModulePrivilege].join("-")
    );

  const [selectedItems, setSelectedItems] = useState(checkedItems);

  const handleSelectedItemsChange = (event, ids) => {
    setSelectedItems(ids);
  };

  const checkedDashboardItems = data.Dashboard.filter(
    (item) => item.DashboardValue === "True"
  ).map((item) => item.idDashboard.toString());

  const [selectedDashboardItems, setSelectedDashboardItems] = useState(
    checkedDashboardItems
  );

  const handleDashboardItemsChange = (event, ids) => {
    setSelectedDashboardItems(ids);
  };

  const sortedUserRoles = sortUserRoles(data.result);

  const handleSubmit = () => {
    if (!currentUserRole) {
      setIsTouched(true);
      setError({ error: true, message: "Role name is required" });
      return;
    }

    const outputArray = getFinalArray(selectedItems, data.result); // Get the final array to be sent to the API
    const updatedDashboard = data.Dashboard.map((item) => {
      return {
        idDashboard: item.idDashboard,
        checked: selectedDashboardItems.includes(item.idDashboard.toString()),
      };
    });

    updateUserRolePrivileges({
      ERPUserRoleName: currentUserRole,
      ModulePriv: outputArray,
      Dashboard: updatedDashboard,
      SelectedNo: userRole,
    });
  };

  const { mutateAsync: updateUserRolePrivileges, isPending } = useMutation({
    mutationFn: ERPSystemRolesService.updateUserRolePrivileges,
    onSuccess: (data) => {
      onClose();
      enqueueSnackbar(data.msg, { variant: "success" });
      queryClient.invalidateQueries({ queryKey: ["userRoles"] });
    },
  });

  return (
    <UserRolePrivilegesTree
      currentUserRole={currentUserRole}
      handleUserRoleChange={handleUserRoleChange}
      selectedItems={selectedItems}
      handleSelectedItemsChange={handleSelectedItemsChange}
      sortedUserRoles={sortedUserRoles}
      data={data}
      isPending={isPending}
      handleSubmit={handleSubmit}
      onClose={onClose}
      error={error}
      isTouched={isTouched}
      disableEdit={disableEdit}
      setDisableEdit={setDisableEdit}
      dashboardData={data.Dashboard}
      selectedDashboardItems={selectedDashboardItems}
      handleDashboardItemsChange={handleDashboardItemsChange}
    />
  );
};

export default SingleUserRole;
