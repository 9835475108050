import { Grid, Stack } from "@mui/material";
import CustomAccordion from "../../../Accordion/CustomAccordion";
import { useQuery } from "@tanstack/react-query";
import Axios from "../../../../network/Axios";
import FormSpinner from "../../../Spinners/FormSpinner";
import { useTranslation } from "react-i18next";
import { useState } from "react";
import ChipComponent from "../../../Chips/ChipComponent";

const Review = ({ workOrderNo, workOrderData }) => {
  const { t } = useTranslation("global");

  const { data, isLoading, isRefetching } = useQuery({
    queryKey: ["WorkOrdersItems"],
    queryFn: async () => {
      const response = await Axios.get("/Quotations", {
        params: {
          State: "WorkOrdersItems",
          SelectedNo: workOrderNo,
        },
      });
      return response.data.result;
    },
  });

  const columns = [
    {
      field: "PriceListSN",
      headerName: t("data_grid.headers.code"),
      width: 80,
    },
    {
      field: "Description",
      headerName: t("data_grid.headers.description"),
      flex: 1,
    },
    {
      field: "SI",
      headerName: t("data_grid.headers.s_i"),
      width: 60,
    },
    {
      field: "Unit",
      headerName: t("data_grid.headers.unit"),
      width: 80,
    },
    {
      field: "ItemQTY",
      headerName: t("data_grid.headers.qty"),
      width: 80,
    },
  ];

  const finalAcceptanceData = [
    {
      label: t("data_grid.headers.work_order_no"),
      value: workOrderData.WorkOrderNo,
    },
    {
      label: t("data_grid.headers.site_name"),
      value: workOrderData.SiteName,
    },
    {
      label: t("data_grid.headers.site_code"),
      value: workOrderData.SiteCode,
    },
    {
      label: t("data_grid.headers.d6_type"),
      value: workOrderData.WorkOrderD6TypeName.replace("Initial", "Final"),
    },
  ];

  const [expanded, setExpanded] = useState("panel1");

  const handleChange = (panel) => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false);
  };

  if (isLoading || isRefetching) return <FormSpinner />;

  return (
    <Stack spacing={2}>
      <Grid container spacing={1}>
        {finalAcceptanceData.map(({ label, value }) => (
          <Grid item xs={4} key={label}>
            <ChipComponent label={label} value={value} />
          </Grid>
        ))}
      </Grid>
      <CustomAccordion
        title={t("info_modules.quotation.description.items_list")}
        rows={data}
        columns={columns}
        height={255}
        getRowId={(row) => row.idWorkOrderD6Items}
        hideFooter
        expanded={expanded === "panel1"}
        onChange={handleChange("panel1")}
      />
    </Stack>
  );
};

export default Review;
