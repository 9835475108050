import Axios from "../network/Axios";

const AddNewEmployee = async (data) => {
  const res = await Axios.post("/EmployeeRecords", data);

  return res.data;
};

const GetEmployeeRecords = async () => {
  const response = await Axios.get("/EmployeeRecords");
  return response.data.result.map((row, index) => ({
    id: index + 1,
    ...row,
  }));
};

const GetEmployeeRecordsForm = async () => {
  const response = await Axios.get("/EmployeeRecords", {
    params: {
      State: "New",
    },
  });
  return response.data;
};

// Gets the employee data like name AR and name EN
const GetEmployeeData = async (empCode) => {
  const response = await Axios.get("/EmployeeRecords", {
    params: {
      State: "EmpPhoto",
      SelectedNo: empCode,
    },
  });
  return response.data.result[0];
};

const GetAllEmployees = async () => {
  const response = await Axios.get("/EmployeeRecords", {
    params: {
      State: "Attendance",
    },
  });

  return response.data.result;
};

// Get all drivers
const GetAllDrivers = async () => {
  const response = await Axios.get("/EmployeeRecords", {
    params: {
      State: "Drivers",
    },
  });

  return response.data.result;
};

// Gets all the information of the employee
const GetEmployeeInfo = async (empCode) => {
  const response = await Axios.get("/EmployeeRecords", {
    params: {
      State: "Card",
      SelectedNo: empCode,
    },
  });

  return response.data;
};

const getEmployeeTimeLine = async (empCode) => {
  const response = await Axios.get("/EmployeeRecords", {
    params: {
      SelectedNo: empCode,
      State: "EmployeeTimeLine",
    },
  });

  return response.data.result;
};

const deleteEmployeeDocument = async (id) => {
  const res = await Axios.delete("/EmployeeRecords", {
    params: {
      idEmpInfoDocuments: id,
    },
  });

  return res.data;
};

const getNewDocumentData = async (SelectedNo) => {
  const response = await Axios.get("/EmployeeRecords", {
    params: {
      SelectedNo,
      State: "NewDocuments",
    },
  });
  return response.data.result;
};

const addNewEmployeeDocument = async (data) => {
  const res = await Axios.put("/EmployeeRecords", data);

  return res.data;
};

const getEmployeeDocument = async (SelectedNo) => {
  const response = await Axios.get("/EmployeeRecords", {
    params: {
      SelectedNo,
      State: "Documents",
    },
  });
  return response.data.result;
};

// Get social insurance data
const getEmployeeSocialInsuranceData = async (empCode) => {
  const response = await Axios.get("/EmployeeRecords", {
    params: {
      SelectedNo: empCode,
      State: "SocialInsurance",
    },
  });

  return response.data;
};

// Get medical insurance data
const getEmployeeMedicalInsuranceData = async (empCode) => {
  const response = await Axios.get("/EmployeeRecords", {
    params: {
      SelectedNo: empCode,
      State: "MedicalInsurance",
    },
  });

  return response.data;
};

const getEmployeeInfoEditFormData = async (empCode) => {
  const response = await Axios.get("/EmployeeRecords", {
    params: {
      SelectedNo: empCode,
      State: "Edit",
    },
  });

  return response.data;
};

const getNationalIdFormData = async (empCode) => {
  const response = await Axios.get("/EmployeeRecords", {
    params: {
      SelectedNo: empCode,
      State: "NationalID",
    },
  });

  return response.data;
};

// Get license data
const getEmployeeDrivingLicenseData = async (empCode) => {
  const response = await Axios.get("/EmployeeRecords", {
    params: {
      SelectedNo: empCode,
      State: "DrivingLicense",
    },
  });

  return response.data;
};

// Get social insurance image
const getEmployeeSocialInsuranceImage = async (empCode) => {
  const response = await Axios.get("/EmployeeRecords", {
    params: {
      SelectedNo: empCode,
      State: "SocialInsurance",
    },
  });

  return response.data;
};

export const employeeRecordsService = {
  AddNewEmployee,
  GetEmployeeRecords,
  GetEmployeeRecordsForm,
  GetEmployeeData,
  GetAllEmployees,
  GetEmployeeInfo,
  getEmployeeTimeLine,
  deleteEmployeeDocument,
  getNewDocumentData,
  addNewEmployeeDocument,
  getEmployeeDocument,
  getEmployeeSocialInsuranceData,
  getEmployeeInfoEditFormData,
  getNationalIdFormData,
  getEmployeeMedicalInsuranceData,
  getEmployeeDrivingLicenseData,
  GetAllDrivers,
  getEmployeeSocialInsuranceImage,
};
