import { useState } from "react";
import { Box } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { useQuery, useMutation, useQueryClient } from "@tanstack/react-query";
import Axios from "../../../../network/Axios";
import InfoModal from "../../../../components/Modals/InfoModal";
import FormsDataGrid from "../../../../components/DataGrid/FormsDataGrid";
import ButtonGroup from "../../../../components/forms/ButtonGroup/ButtonGroup";
import ConfirmationInfoModal from "../../../../components/Modals/ConfirmationInfoModal";
import { useSnackbar } from "notistack";

const AddStore = ({ open, handleClose }) => {
  const { userId } = useParams();
  const [t] = useTranslation("global");
  const queryClient = useQueryClient();
  const { enqueueSnackbar } = useSnackbar();
  const [selectedRows, setSelectedRows] = useState([]);
  const [selectedData, setSelectedData] = useState([]);
  const [confirmModal, setConfirmModal] = useState(false);

  const handleOpenConfirmModal = () => {
    setConfirmModal(true);
  };

  const { data, isLoading } = useQuery({
    queryKey: ["stores", "add"],
    queryFn: async () => {
      const response = await Axios.get("/UsersManagement", {
        params: {
          SelectedNo: userId,
          State: "Stores",
        },
      });
      return response.data.CompanyStores;
    },
    enabled: open,
  });
  const { mutateAsync: addStore, isPending: add } = useMutation({
    mutationFn: async () => {
      const stores = selectedData.map((store) => ({
        id: store.idCompanyStores,
        CompanyStoresName: store.CompanyStoresName,
      }));

      return Axios.patch("/UsersManagement", {
        State: "UpdateStoreProfile",
        CompanyStores: stores,
        SelectedNo: userId,
      });
    },
    onSuccess: (data) => {
      if (!data) return;
      enqueueSnackbar(t("User Store Profile Updated Successfully"), {
        variant: "success",
      });
      handleCloseCheck();
      setConfirmModal(false);
      queryClient.invalidateQueries({
        queryKey: ["usersManagement", "info"],
      });
    },
  });
  const handleRowSelectionModelChange = (newSelectedRows) => {
    const selectedData = data.filter((row) =>
      newSelectedRows.includes(row.idCompanyStores)
    );
    setSelectedData(selectedData);
    setSelectedRows(newSelectedRows);
  };
  const handleCloseCheck = () => {
    handleClose();
    setSelectedRows([]);
  };
  const columns = [
    {
      field: "CompanyStoresName",
      headerName: t("data_grid.headers.store_name"),
      width: 400,
    },
  ];
  return (
    <>
      <InfoModal
        open={open}
        handleClose={handleCloseCheck}
        title={t("info_modules.users_management.description.add_store")}
        content={
          <Box padding="20px 10px">
            <FormsDataGrid
              rows={data || []}
              columns={columns}
              loading={isLoading}
              getRowId={(row) => row.idCompanyStores}
              height={380}
              checkboxSelection
              disableSelectionOnClick
              onRowSelectionModelChange={handleRowSelectionModelChange}
              rowSelectionModel={selectedRows}
            />
            <ButtonGroup
              onClickClose={handleCloseCheck}
              onClickSave={handleOpenConfirmModal}
              saveLabel={t("modules.buttons.select")}
            />
          </Box>
        }
      />
      <ConfirmationInfoModal
        open={confirmModal}
        handleClose={() => {
          setConfirmModal(false);
        }}
        onClick={addStore}
        description={t("info_modules.users_management.confirmation.add_store")}
        isPending={add}
        color="error"
      />
    </>
  );
};

export default AddStore;
