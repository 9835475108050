import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useTranslation } from "react-i18next";
import * as yup from "yup";
import { useSnackbar } from "notistack";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFnsV3";
import { Autocomplete, Box, Stack, TextField, Typography } from "@mui/material";
import dayjs from "dayjs";
import { useState } from "react";
import { CompanyFleetService } from "../../../api/companyFleet";
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import ButtonGroup from "../ButtonGroup/ButtonGroup";
import useHandleForms from "../../../hooks/useHandleForms";
import {
  MAX_FILE_SIZE,
  carTypeOptions,
  supportedImageTypes,
} from "../../../constants/constants";
import ImagePickerBox from "../../../pages/InformationPages/EmployeeRecords/components/ImagePickerBox";

const currentDate = dayjs().format("YYYY/MM/DD");

const CompanyFleetForm = ({ onDirtyChange, onClickClose }) => {
  const queryClient = useQueryClient();

  const [t] = useTranslation("global");

  const initialValues = {
    frontImage: null,
    backImage: null,
    plateNumber: "",
    model: "",
    modelYear: currentDate,
    ownership: "",
    licenseExpireDate: currentDate,
    carType: "",
    chassisNumber: "",
    motorNumber: "",
    color: "",
  };

  const schema = yup.object().shape({
    frontImage: yup
      .mixed()
      .test("fileSize", t("modules.global_schema.file_size"), (value) => {
        return !value || (value && value.size <= MAX_FILE_SIZE);
      })
      .test(
        "fileType",
        t("modules.global_schema.file_type"),
        (value) => !value || (value && supportedImageTypes.includes(value.type))
      )
      .required(t("modules.global_schema.required")),
    backImage: yup
      .mixed()
      .test("fileSize", t("modules.global_schema.file_size"), (value) => {
        return !value || (value && value.size <= MAX_FILE_SIZE);
      })
      .test(
        "fileType",
        t("modules.global_schema.file_type"),
        (value) => !value || (value && supportedImageTypes.includes(value.type))
      )
      .required(t("modules.global_schema.required")),
    carType: yup
      .string()
      .required(t("modules.global_schema.select_value"))
      .oneOf(carTypeOptions, t("modules.global_schema.invalid_value")),
    plateNumber: yup.string().required(t("modules.global_schema.select_value")),
    model: yup.string().required(t("modules.global_schema.select_value")),
    modelYear: yup.date().required(t("modules.global_schema.required")),
    ownership: yup.string().required(t("modules.global_schema.select_value")),
    licenseExpireDate: yup.date().required(t("modules.global_schema.required")),
    chassisNumber: yup
      .string()
      .required(t("modules.global_schema.select_value")),
    motorNumber: yup.string().required(t("modules.global_schema.select_value")),
    color: yup.string().required(t("modules.global_schema.select_value")),
  });

  const handleFormSubmit = (values) => {
    const {
      frontImage,
      backImage,
      plateNumber,
      model,
      modelYear,
      ownership,
      licenseExpireDate,
      carType,
      chassisNumber,
      motorNumber,
      color,
    } = values;

    if (getValues().frontImage === null) {
      return setError("frontImage", {
        message: t("modules.global_schema.required"),
        type: "required",
      });
    } else if (getValues().backImage === null) {
      return setError("backImage", {
        message: t("modules.global_schema.required"),
        type: "required",
      });
    }

    const formData = new FormData();

    formData.append("CarsInfoCarsNumbersLicenseFront", frontImage);
    formData.append("CarsInfoCarsNumbersLicenseBack", backImage);
    formData.append("CarsNumber", plateNumber);
    formData.append("CarsModelName", model);
    formData.append("CarsModelYear", dayjs(modelYear).format("YYYY-MM-DD"));
    formData.append("CarsOwnership", ownership);
    formData.append(
      "CarsLicenseEndDate",
      dayjs(licenseExpireDate).format("YYYY-MM-DD")
    );
    formData.append("CarsType", carType);
    formData.append("CarsColor", color);
    formData.append("CarsMotorNo", motorNumber);
    formData.append("CarsBodyNo", chassisNumber);

    AddNewFleetCar(formData);
  };

  const {
    register,
    handleSubmit,
    setValue,
    setError,
    control,
    getValues,
    formState: { errors, isDirty },
  } = useForm({
    resolver: yupResolver(schema),
    mode: "onChange",
    defaultValues: initialValues,
  });

  const { handleClose } = useHandleForms({ dirty: isDirty, onDirtyChange });

  const { enqueueSnackbar } = useSnackbar();

  const { mutateAsync: AddNewFleetCar, isPending } = useMutation({
    mutationFn: CompanyFleetService.AddNewFleetCar,
    onSuccess: (data) => {
      handleClose();
      enqueueSnackbar(data.msg, { variant: "success" });
      queryClient.invalidateQueries({ queryKey: ["companyFleet"] });
    },
  });

  return (
    <NewFleetCarForm
      handleFormSubmit={handleFormSubmit}
      register={register}
      errors={errors}
      handleSubmit={handleSubmit}
      isPending={isPending}
      onClickClose={onClickClose}
      control={control}
      setError={setError}
      setValue={setValue}
    />
  );
};

export const NewFleetCarForm = ({
  handleFormSubmit,
  register,
  errors,
  handleSubmit,
  isPending,
  onClickClose,
  control,
  setError,
  setValue,
  currentFrontImage,
  currentBackImage,
}) => {
  const [t] = useTranslation("global");

  // Images handling logic
  const [selectedFrontImage, setSelectedFrontImage] = useState(null);
  const [selectedBackImage, setSelectedBackImage] = useState(null);

  const handleImageFrontChange = (event) => {
    setError("frontImage", null);
    const file = event.currentTarget.files[0];
    if (!file) return;
    setSelectedFrontImage(URL.createObjectURL(file));
  };

  const handleImageBackChange = (event) => {
    setError("backImage", null);
    const file = event.currentTarget.files[0];
    if (!file) return;
    setValue("backImage", file);
    setSelectedBackImage(URL.createObjectURL(file));
  };

  const labels = {
    plateNumber: t("modules.company_fleet.new_form.fields.plate_number"),
    model: t("modules.company_fleet.new_form.fields.car_model"),
    modelYear: t("modules.company_fleet.new_form.fields.model_year"),
    ownership: t("modules.company_fleet.new_form.fields.ownership"),
    licenseExpireDate: t(
      "modules.company_fleet.new_form.fields.license_expire_date"
    ),
    carType: t("modules.company_fleet.new_form.fields.car_type"),
    chassisNumber: t("modules.company_fleet.new_form.fields.chassis_number"),
    motorNumber: t("modules.company_fleet.new_form.fields.motor_number"),
    color: t("modules.company_fleet.new_form.fields.color"),
  };

  return (
    <form onSubmit={handleSubmit(handleFormSubmit)} noValidate>
      <Stack spacing={2} p={2}>
        <Box
          sx={{
            display: "grid",
            gridTemplateColumns: "1fr 1fr",
            gap: "10px",
            justifyItems: "center",
          }}
        >
          <Stack spacing={1}>
            <Typography
              sx={{
                fontSize: "14px",
                fontStyle: "italic",
              }}
            >
              * {t("modules.company_fleet.new_form.fields.upload_front")}
            </Typography>
            <ImagePickerBox
              error={errors.frontImage}
              image={selectedFrontImage || currentFrontImage}
              label={t("modules.company_fleet.new_form.fields.upload_front")}
              handleImage={handleImageFrontChange}
              name="frontImage"
              control={control}
            />
          </Stack>

          <Stack spacing={1}>
            <Typography
              sx={{
                fontSize: "14px",
                fontStyle: "italic",
              }}
            >
              * {t("modules.company_fleet.new_form.fields.upload_back")}
            </Typography>
            <ImagePickerBox
              error={errors.backImage}
              image={selectedBackImage || currentBackImage}
              label={t("modules.company_fleet.new_form.fields.upload_back")}
              handleImage={handleImageBackChange}
              name="backImage"
              control={control}
            />
          </Stack>
        </Box>
        <Box
          sx={{
            display: "grid",
            gridTemplateColumns: "1fr 1fr 1fr",
            gap: "16px",
          }}
        >
          <TextField
            size="small"
            label={labels.plateNumber}
            error={Boolean(errors.plateNumber)}
            helperText={errors.plateNumber?.message}
            {...register("plateNumber")}
            required
          />
          <TextField
            size="small"
            label={labels.model}
            error={Boolean(errors.model)}
            helperText={errors.model?.message}
            {...register("model")}
            required
          />
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <Controller
              control={control}
              name="modelYear"
              render={({ field }) => {
                return (
                  <DatePicker
                    label={labels.modelYear}
                    views={["year"]}
                    name="modelYear"
                    value={field.value}
                    inputRef={field.ref}
                    onChange={(date) => {
                      field.onChange(date);
                    }}
                    slotProps={{
                      textField: {
                        size: "small",
                        error: Boolean(errors.modelYear),
                        helperText: errors.modelYear?.message,
                        required: true,
                      },
                    }}
                  />
                );
              }}
            />
          </LocalizationProvider>
          <TextField
            size="small"
            label={labels.ownership}
            error={Boolean(errors.ownership)}
            helperText={errors.ownership?.message}
            {...register("ownership")}
            required
          />
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <Controller
              control={control}
              name="licenseExpireDate"
              render={({ field }) => {
                return (
                  <DatePicker
                    label={labels.licenseExpireDate}
                    views={["year", "month", "day"]}
                    name="licenseExpireDate"
                    format="yyyy/MM/dd"
                    value={field.value}
                    inputRef={field.ref}
                    onChange={(date) => {
                      field.onChange(date);
                    }}
                    slotProps={{
                      textField: {
                        error: Boolean(errors.licenseExpireDate),
                        helperText: errors.licenseExpireDate?.message,
                        required: true,
                        size: "small",
                      },
                    }}
                  />
                );
              }}
            />
          </LocalizationProvider>
          <Controller
            control={control}
            name={`carType`}
            render={({ field: { onChange, value } }) => (
              <Autocomplete
                disableClearable
                size="small"
                options={carTypeOptions}
                onChange={(event, item) => {
                  onChange(item);
                }}
                value={value || null}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label={labels.carType}
                    error={!!errors.carType}
                    helperText={errors.carType?.message}
                    required
                  />
                )}
              />
            )}
          />

          <TextField
            size="small"
            label={labels.chassisNumber}
            error={Boolean(errors.chassisNumber)}
            helperText={errors.chassisNumber?.message}
            {...register("chassisNumber")}
            required
          />
          <TextField
            size="small"
            label={labels.motorNumber}
            error={Boolean(errors.motorNumber)}
            helperText={errors.motorNumber?.message}
            {...register("motorNumber")}
            required
          />
          <TextField
            size="small"
            label={labels.color}
            error={Boolean(errors.color)}
            helperText={errors.color?.message}
            {...register("color")}
            required
          />
        </Box>
      </Stack>

      <ButtonGroup
        isPending={isPending}
        onClickClose={onClickClose}
        saveLabel={t("modules.buttons.save")}
      />
    </form>
  );
};

export default CompanyFleetForm;
