import CloseIcon from "@mui/icons-material/Close";
import {
  Box,
  Typography,
  IconButton,
  Stack,
  Modal,
  Backdrop,
  Fade,
} from "@mui/material";

const InfoModal = ({
  open,
  handleClose,
  content,
  title,
  width,
  topBarBackgroundColor,
  topBarIcon,
  disableCloseOnBackdropClick,
}) => {
  const style = {
    position: "absolute",
    bgcolor: "background.paper",
    borderRadius: "8px",
    overflow: "hidden",
    boxShadow: 24,
    width: { width },
  };
  return (
    <Modal
      open={open}
      closeAfterTransition
      slots={{ backdrop: Backdrop }}
      slotProps={{
        backdrop: {
          timeout: 400,
        },
      }}
      onClose={!disableCloseOnBackdropClick && handleClose}
      sx={{
        display: "grid",
        placeItems: "center",
      }}
    >
      <Fade in={open}>
        <Box sx={style}>
          {title && (
            <Box
              display="flex"
              justifyContent="space-between"
              alignItems="center"
              px="8px"
              py={topBarBackgroundColor === "error.main" ? 1 : 0}
              backgroundColor={topBarBackgroundColor || "background.formHeader"}
              color="white"
            >
              <Stack direction="row" spacing={1}>
                {topBarIcon}
                <Typography
                  sx={{
                    fontSize: "17px",
                    fontWeight: 500,
                  }}
                >
                  {title}
                </Typography>
              </Stack>
              <Stack direction="row" spacing={2}>
                <IconButton onClick={handleClose} color="error">
                  <CloseIcon
                    style={{
                      color:
                        topBarBackgroundColor === "error.main"
                          ? "#fff"
                          : "#ff4d6d",
                      fontSize: 20,
                    }}
                  />
                </IconButton>
              </Stack>
            </Box>
          )}

          {content}
        </Box>
      </Fade>
    </Modal>
  );
};

export default InfoModal;
