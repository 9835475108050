import { useEffect, useState } from "react";
import FormsDataGrid from "../../../DataGrid/FormsDataGrid";
import ChipStatus from "../../../ChipStatus";
import { Box, TextField } from "@mui/material";
import { useTranslation } from "react-i18next";

const SelectWorkOrder = ({
  rows,
  onRowSelection,
  selectedRows,
  setSelectedRows,
  loading,
  type, // missions | warrantyVisit
}) => {
  useEffect(() => {
    onRowSelection(selectedRows);
  }, [selectedRows, onRowSelection]);

  const [t] = useTranslation("global");
  const [workOrderDescription, setWorkOrderDescription] = useState("");

  const handleOnCellClick = (params) => {
    setWorkOrderDescription(params.row.WorkOrderDiscription);
  };

  const columns = [
    {
      field: "WorkOrderNo",
      headerName: t("data_grid.headers.ref_no"),
      width: 120,
    },
    {
      field: "SiteName",
      headerName: t("data_grid.headers.site_name"),
      minWidth: 120,
      flex: 0.65,
    },
    {
      field: "SiteCode",
      headerName: t("data_grid.headers.site_code"),
      minWidth: 120,
      flex: 0.35,
    },
    {
      field: "WorkOrderDate",
      headerName: t("data_grid.headers.date"),
    },
    {
      field: "ERPUserNickName",
      headerName: t("data_grid.headers.created_by"),
      width: 120,
    },
    {
      field: "WorkOrderStatus",
      headerName: t("data_grid.headers.status"),
      renderCell: ({ value }) => {
        return <ChipStatus value={value} />;
      },
    },
  ];

  return (
    <Box sx={{ margin: "12px 1px" }}>
      <FormsDataGrid
        rows={rows}
        columns={columns}
        loading={loading}
        getRowId={(row) => row.idWorkOrder}
        height={310}
        showQuickFilter
        checkboxSelection
        toolbar
        disableSelectionOnClick
        disableMultipleRowSelection
        onRowSelectionModelChange={(newSelectedRows) => {
          // If type is "missions", keep the selected rows
          if (type === "missions") {
            setSelectedRows(newSelectedRows);
            return;
          }

          // if type is "warrantyVisit", keep only the first selected row
          // If newSelectedRows array has more than one item, keep only the second item
          if (newSelectedRows.length > 1) {
            newSelectedRows = [newSelectedRows[1]];
          }

          // Disable the "Select All" checkbox
          const updatedSelectionModel =
            newSelectedRows.length === 1 ? newSelectedRows : [];

          setSelectedRows(updatedSelectionModel);
        }}
        rowSelectionModel={selectedRows}
        onCellClick={handleOnCellClick}
        sx={{
          "& .MuiDataGrid-columnHeaderCheckbox": {
            pointerEvents: type === "missions" ? "auto" : "none",
          },
        }}
      />
      <TextField
        label={t("modules.material_request.label.description")}
        sx={{ width: "100%", marginTop: "10px" }}
        multiline
        rows={1}
        value={selectedRows.length > 0 ? workOrderDescription : ""}
      />
    </Box>
  );
};
export default SelectWorkOrder;
