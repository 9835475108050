import {
  DataGridPro,
  GridToolbarContainer,
  GridToolbarQuickFilter,
  GridToolbarColumnsButton,
  GridToolbarFilterButton,
  useGridApiContext,
  useGridApiRef,
} from "@mui/x-data-grid-pro";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DateRangePicker } from "@mui/x-date-pickers-pro/DateRangePicker";
import {
  Box,
  IconButton,
  MenuItem,
  Stack,
  Typography,
  InputAdornment,
  TextField,
} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import { useTheme } from "@emotion/react";
import { Add, RemoveRedEye } from "@mui/icons-material";
import { darken, lighten } from "@mui/material/styles";
import { useSelector } from "react-redux";
import RefreshIcon from "@mui/icons-material/Refresh";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import { useTranslation } from "react-i18next";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";
import { useState, React, useEffect } from "react";
import MoreVert from "@mui/icons-material/MoreVert";
import DownloadIcon from "@mui/icons-material/Download";
import PrintIcon from "@mui/icons-material/Print";
import { LoadingButton } from "@mui/lab";
import ActionMenuButton from "../forms/ButtonGroup/ActionMenuButton";

const CustomExportMenuItem = ({ icon, label, onClick }) => (
  <MenuItem
    onClick={onClick}
    sx={{ padding: "5px", display: "flex", alignItems: "center" }}
  >
    {icon}
    <Box sx={{ fontSize: "0.85rem" }}>{label}</Box>
  </MenuItem>
);

const CustomGridCsvExportMenuItem = ({ options, ...other }) => {
  const apiRef = useGridApiContext();
  const [t] = useTranslation("global");

  const handleExport = () => {
    apiRef.current.exportDataAsCsv(options);
  };

  return (
    <CustomExportMenuItem
      icon={<DownloadIcon sx={{ fontSize: "1.1rem", marginRight: "6px" }} />}
      label={t("data_grid.buttons.displayed_data_csv_format")}
      onClick={handleExport}
    />
  );
};

const CustomGridPrintExportMenuItem = ({ options, ...other }) => {
  const apiRef = useGridApiContext();
  const [t] = useTranslation("global");

  const handleExport = () => {
    apiRef.current.exportDataAsPrint(options);
  };

  return (
    <CustomExportMenuItem
      icon={<PrintIcon sx={{ fontSize: "1.1rem", marginRight: "6px" }} />}
      label={t("data_grid.buttons.print")}
      onClick={handleExport}
    />
  );
};

export const GridToolbarExport = ({
  csvOptions,
  printOptions,
  thirdExportOption,
  fourthExportOption,
  thirdOptionName,
  fourthOptionName,
  thirdExport,
  fourthExport,
  ...other
}) => (
  <>
    <CustomGridCsvExportMenuItem options={csvOptions} />
    <CustomGridPrintExportMenuItem options={printOptions} />
    {thirdExport && (
      <CustomExportMenuItem
        icon={<DownloadIcon sx={{ fontSize: "1.1rem", marginRight: "6px" }} />}
        label={thirdOptionName}
        onClick={thirdExportOption}
      />
    )}
    {fourthExport && (
      <CustomExportMenuItem
        icon={<DownloadIcon sx={{ fontSize: "1.1rem", marginRight: "6px" }} />}
        label={fourthOptionName}
        onClick={fourthExportOption}
      />
    )}
  </>
);
const CustomIconButton = ({
  onClick,
  ariaLabel,
  icon,
  label,
  color = "primary",
  borderColor = "#2196f3",
  backgroundColor,
  hoverBackgroundColor,
  loading = false,
  isLoadingButton = false,
  size = "small",
}) => {
  const theme = useSelector((state) => state.theme.colorTheme);
  const buttonStyle = {
    borderRadius: "7px",
    padding: "4px 6px",
    textTransform: "capitalize",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    gap: "2px",
    margin: "0 1px",
    border: `2px solid ${borderColor}`,
    backgroundColor: backgroundColor || "transparent",
    ":hover": {
      backgroundColor:
        hoverBackgroundColor ||
        (theme === "dark"
          ? "rgba(34, 85, 153, 0.1)"
          : "rgba(80, 152, 236, 0.05)"),
    },
  };

  if (isLoadingButton) {
    return (
      <LoadingButton
        onClick={onClick}
        loading={loading}
        aria-label={ariaLabel}
        sx={buttonStyle}
      >
        <Typography fontSize="13px" fontWeight="600" color="white">
          {label}
        </Typography>
      </LoadingButton>
    );
  }

  return (
    <IconButton
      onClick={onClick}
      aria-label={ariaLabel}
      sx={buttonStyle}
      size={size}
    >
      {icon}
      {label && (
        <Typography color={color} fontSize="13px" fontWeight="600">
          {label}
        </Typography>
      )}
    </IconButton>
  );
};

const CustomToolbar = ({
  showQuickFilter,
  showColumnsFilter,
  showExportButton,
  showFilters,
  showDatePicker,
  showDateTimePicker,
  setEndDatePicker,
  setStartDatePicker,
  startDatePicker,
  endDatePicker,
  reloadFunction,
  showReload,
  showAdd,
  addFunction,
  newButton,
  newFunction,
  thirdExportOption,
  fourthExportOption,
  thirdOptionName,
  fourthOptionName,
  thirdExport,
  fourthExport,
  bulkEditBtn,
  resetGrid,
  addInvoiceBtn,
  addInvoiceFn,
  addMaterialsBtn,
  searchValueCustom,
  customSearchOnChange,
  customSearchValue,
}) => {
  const [t] = useTranslation("global");
  const theme = useSelector((state) => state.theme.colorTheme);

  return (
    <GridToolbarContainer
      sx={{
        p: 1,
        display: "flex",
        gap: "10px",
        justifyContent:
          showQuickFilter || searchValueCustom ? "space-between" : "flex-end",
      }}
    >
      {newButton && (
        <CustomIconButton
          onClick={newFunction}
          ariaLabel="new"
          icon={<AddCircleIcon color="primary" style={{ fontSize: "18px" }} />}
          label={t("data_grid.buttons.new")}
        />
      )}
      {bulkEditBtn && (
        <Stack direction="row" gap={1}>
          {
            // If the bulk edit button is enabled, show the save button
            // Otherwise, show the multi edit button
            bulkEditBtn.isBulkEditEnabled ? (
              <LoadingButton
                onClick={bulkEditBtn.bulkEditFunction}
                loading={bulkEditBtn.isLoading}
                aria-label="save-bulk-edit"
                sx={{
                  borderRadius: 3,
                  px: 1,
                  textTransform: "capitalize",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  gap: "5px",
                  backgroundColor: "success.main",
                  marginLeft: "5px",
                  ":hover": {
                    backgroundColor: "success.dark",
                  },
                }}
              >
                <Typography
                  fontSize="13px"
                  fontWeight="600"
                  sx={{
                    color: "white",
                  }}
                >
                  {t("data_grid.buttons.save")}
                </Typography>
              </LoadingButton>
            ) : (
              <IconButton
                onClick={bulkEditBtn.bulkEditFunction}
                aria-label="multi-edit"
                sx={{
                  border: "2px solid #2196f3",
                  borderRadius: "7px",
                  padding: "4px 6px",
                  textTransform: "capitalize",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  gap: "2px",
                  margin: "0 1px",
                  ":hover": {
                    backgroundColor:
                      theme === "dark"
                        ? "rgba(34, 85, 153, 0.1)"
                        : "rgba(80, 152, 236, 0.05)",
                  },
                }}
              >
                <MoreVert color="primary" style={{ fontSize: "18px" }} />
                <Typography color="primary" fontSize="13px" fontWeight="600">
                  {t("data_grid.buttons.edit_quantities")}
                </Typography>
              </IconButton>
            )
          }

          {bulkEditBtn.isBulkEditEnabled && (
            <IconButton
              onClick={() => {
                resetGrid();
                bulkEditBtn.cancelBulkEdit();
              }}
              aria-label="cancel-bulk-edit"
              sx={{
                borderRadius: 3,
                px: 1,
                textTransform: "capitalize",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                gap: "5px",
                ":hover": {
                  backgroundColor:
                    theme === "dark"
                      ? "rgba(34, 85, 153, 0.1)"
                      : "rgba(80, 152, 236, 0.05)",
                },
              }}
            >
              <Typography color="error" fontSize="13px" fontWeight="600">
                {t("data_grid.buttons.cancel")}
              </Typography>
            </IconButton>
          )}
        </Stack>
      )}
      {showQuickFilter && <GridToolbarQuickFilter debounceMs={500} />}
      {searchValueCustom && (
        <TextField
          id="standard-basic"
          placeholder="Search..."
          variant="standard"
          value={customSearchValue}
          onChange={customSearchOnChange}
          size="small"
          name="search"
          autoFocus
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <SearchIcon sx={{ fontSize: "1.1rem" }} />
              </InputAdornment>
            ),
          }}
        />
      )}
      <Box display="flex" gap={0.8}>
        {showDatePicker && (
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DateRangePicker
              defaultValue={[startDatePicker, endDatePicker]}
              localeText={{
                start: t("data_grid.headers.start_date"),
                end: t("data_grid.headers.end_date"),
              }}
              format={showDateTimePicker ? "YYYY-MM-DD hh:mm a" : "YYYY-MM-DD"}
              value={[startDatePicker, endDatePicker]}
              onAccept={(newValue) => {
                setStartDatePicker(newValue[0]);
                setEndDatePicker(newValue[1]);
              }}
              sx={{
                "& .MuiInputBase-input": {
                  height: "12px",
                  fontSize: "0.85rem",
                  fontWeight: 600,
                  width: "135px",
                },
              }}
              slotProps={{
                textField: {
                  size: "small",
                },
              }}
            />
          </LocalizationProvider>
        )}

        {showReload && (
          <CustomIconButton
            onClick={reloadFunction}
            ariaLabel="reload"
            icon={<RefreshIcon color="primary" style={{ fontSize: "18px" }} />}
            label={t("data_grid.buttons.reload")}
          />
        )}
        {showColumnsFilter && (
          <GridToolbarColumnsButton
            startIcon={<RemoveRedEye />}
            sx={{
              padding: "2.4px 5px",
              textTransform: "capitalize",
              borderRadius: "7px",
              border: "2px solid #2196f3",
            }}
          />
        )}
        {showFilters && (
          <GridToolbarFilterButton
            sx={{
              border: "2px solid #2196f3",
              borderRadius: "7px",
              textTransform: "capitalize",
              padding: "2.4px 5px",
            }}
          />
        )}
        {addInvoiceBtn && (
          <CustomIconButton
            onClick={addInvoiceFn}
            ariaLabel="add-invoice"
            icon={<Add color="primary" style={{ fontSize: "18px" }} />}
            label={t("data_grid.buttons.add_invoice")}
          />
        )}
        {showExportButton && (
          <ActionMenuButton
            title={t("data_grid.buttons.export")}
            padding="1.7px 4.5px"
            textTransform="capitalize"
            borderRadius="7px"
            customContent={
              <GridToolbarExport
                fourthExportOption={fourthExportOption}
                thirdExportOption={thirdExportOption}
                fourthOptionName={fourthOptionName}
                thirdOptionName={thirdOptionName}
                thirdExport={thirdExport}
                fourthExport={fourthExport}
                csvOptions={{
                  allColumns: true,
                  utf8WithBom: true,
                }}
              />
            }
          />
        )}
        {showAdd && (
          <CustomIconButton
            onClick={addFunction}
            ariaLabel="add"
            icon={
              <AddCircleOutlineIcon
                color="primary"
                style={{ fontSize: "18px" }}
              />
            }
            label={t("data_grid.buttons.add")}
          />
        )}
        {addMaterialsBtn && (
          <CustomIconButton
            onClick={addMaterialsBtn.addMaterialsFunction}
            ariaLabel="add materials"
            icon={
              <AddCircleOutlineIcon
                color="primary"
                style={{ fontSize: "18px" }}
              />
            }
            label={t("modules.missions.buttons.add_materials")}
          />
        )}
      </Box>
    </GridToolbarContainer>
  );
};

const FormsDataGrid = ({
  rows,
  columns,
  checkboxSelection,
  showQuickFilter,
  showColumnsFilter,
  showDatePicker,
  showDateTimePicker,
  setEndDatePicker,
  setStartDatePicker,
  startDatePicker,
  endDatePicker,
  showReload,
  showAdd,
  reloadFunction,
  addFunction,
  height,
  onRowSelectionModelChange,
  rowSelectionModel,
  getRowId,
  loading,
  onCellEditStop,
  onCellClick,
  hideFooter,
  initialState,
  toolbar,
  sx,
  columnVisibilityModel,
  disableMultipleRowSelection,
  disableColumnMenu,
  disableColumnResize,
  showReloadOverlay,
  getRowClassName,
  showExportButton,
  showFilters,
  getDetailPanelHeight,
  getDetailPanelContent,
  backgroundDetails,
  titleDetails,
  headerHeight,
  cellFontDetails,
  newButton,
  newFunction,
  thirdExportOption,
  fourthExportOption,
  thirdOptionName,
  fourthOptionName,
  thirdExport,
  fourthExport,
  bulkEditBtn,
  addInvoiceBtn,
  addInvoiceFn,
  addMaterialsBtn,
  searchValueCustom,
  customSearchValue,
  customSearchOnChange,
}) => {
  const theme = useTheme();

  const [key, setKey] = useState(0); // Change key to force remount

  // Function to reset by forcing remount
  const resetGrid = () => {
    setKey((prevKey) => prevKey + 1);
  };

  const apiRef = useGridApiRef();

  useEffect(() => {
    if (bulkEditBtn?.isBulkEditEnabled) {
      const firstRowId = apiRef.current.getAllRowIds()[0];

      apiRef.current.setCellFocus(firstRowId, "ItemQTY");
      apiRef.current.startCellEditMode({
        id: firstRowId,
        field: "ItemQTY",
      });
    }
  }, [apiRef, bulkEditBtn?.isBulkEditEnabled]);

  return (
    <Box height={height} width="100%">
      <style>
        {`
          .read-only-row {
            background-color: ${
              theme.palette.mode === "dark"
                ? darken(theme.palette.error.main, 0.7)
                : lighten(theme.palette.error.main, 0.7)
            };
          }
        `}
      </style>
      <DataGridPro
        apiRef={apiRef}
        key={key}
        showReloadOverlay={showReloadOverlay}
        disableMultipleRowSelection={disableMultipleRowSelection}
        columnVisibilityModel={columnVisibilityModel}
        slotProps={{
          basePopper: {
            placement: "bottom-end",
          },
        }}
        slots={{
          toolbar: () =>
            toolbar ? (
              <CustomToolbar
                showColumnsFilter={showColumnsFilter}
                showQuickFilter={showQuickFilter}
                showExportButton={showExportButton}
                showFilters={showFilters}
                showDatePicker={showDatePicker}
                setEndDatePicker={setEndDatePicker}
                setStartDatePicker={setStartDatePicker}
                startDatePicker={startDatePicker}
                endDatePicker={endDatePicker}
                showDateTimePicker={showDateTimePicker}
                showReload={showReload}
                showAdd={showAdd}
                reloadFunction={reloadFunction}
                addFunction={addFunction}
                showReloadOverlay={showReloadOverlay}
                newButton={newButton}
                newFunction={newFunction}
                thirdExportOption={thirdExportOption}
                fourthExportOption={fourthExportOption}
                thirdOptionName={thirdOptionName}
                fourthOptionName={fourthOptionName}
                thirdExport={thirdExport}
                fourthExport={fourthExport}
                bulkEditBtn={bulkEditBtn}
                resetGrid={resetGrid}
                addInvoiceBtn={addInvoiceBtn}
                addInvoiceFn={addInvoiceFn}
                addMaterialsBtn={addMaterialsBtn}
                searchValueCustom={searchValueCustom}
                customSearchValue={customSearchValue}
                customSearchOnChange={customSearchOnChange}
              />
            ) : (
              ""
            ),
          detailPanelExpandIcon: () => (
            <AddIcon
              style={{
                color: theme === "dark" ? "#3399ff" : "#1976d2",
                fontSize: "20px",
              }}
            />
          ),
          detailPanelCollapseIcon: () => (
            <RemoveIcon
              style={{
                color: theme === "dark" ? "#3399ff" : "#1976d2",
                fontSize: "20px",
              }}
            />
          ),
        }}
        rows={rows}
        columns={columns}
        checkboxSelection={checkboxSelection}
        disableSelectionOnClick
        columnHeaderHeight={headerHeight ? headerHeight : 33}
        rowHeight={headerHeight ? headerHeight : 34}
        loading={showReloadOverlay || loading}
        onRowSelectionModelChange={onRowSelectionModelChange}
        rowSelectionModel={rowSelectionModel}
        getRowId={getRowId}
        onCellEditStop={onCellEditStop}
        onCellClick={onCellClick}
        hideFooter={hideFooter}
        initialState={initialState}
        disableColumnMenu={disableColumnMenu}
        disableColumnResize={disableColumnResize}
        getRowClassName={getRowClassName}
        getDetailPanelHeight={getDetailPanelHeight}
        getDetailPanelContent={getDetailPanelContent}
        sx={{
          border: "none",
          backgroundColor: "background.card",
          fontFamily: "Roboto, sans-serif",
          transition: "box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
          boxShadow: theme.palette.boxShadow.main,
          borderRadius: "2px",
          ".MuiDataGrid-columnHeaders": {
            backgroundColor: backgroundDetails
              ? backgroundDetails
              : "background.headerGrid",
            color: backgroundDetails
              ? "#fff"
              : theme.palette.mode === "dark"
              ? "#ffffff"
              : "#313132",
            display: "flex",
            justifyContent: "flex-start",
          },
          ".MuiDataGrid-columnHeaderTitleContainer": {
            display: "flex",
            justifyContent: "center",
            fontSize: titleDetails ? titleDetails : "12px",
          },
          "& .MuiDataGrid-cell": {
            display: "flex",
            justifyContent: "center",
            borderColor: theme.palette.mode === "dark" ? "#666666" : "#cccccc",
            fontSize: cellFontDetails ? cellFontDetails : "12px",
          },
          "&.css-1vq2q2m-MuiPopper-root-MuiDataGrid-panel": {
            backgroundColor: "red",
          },
          ...sx,
        }}
      />
    </Box>
  );
};

export default FormsDataGrid;
