import { useEffect, useState } from "react";
import FormsDataGrid from "../../../../../../components/DataGrid/FormsDataGrid";
import { Autocomplete, Box, TextField } from "@mui/material";
import FindReplaceOutlinedIcon from "@mui/icons-material/FindReplaceOutlined";
import { GridActionsCellItem } from "@mui/x-data-grid-pro";
import { useTranslation } from "react-i18next";
import { useSnackbar } from "notistack";
import ReplaceItems from "../Replace";
import { replaceEqualDeep } from "@tanstack/react-query";

const SelectItems = ({
  comboBoxData,
  rows,
  setRows,
  selectedNo,
  setSelectedNo,
  getItems,
  loadingItems,
  handleQuantity,
  action,
  setMessage,
  replaced,
  setReplaced,
}) => {
  const [t] = useTranslation("global");
  const { enqueueSnackbar } = useSnackbar();
  const [replaceModal, setReplaceModal] = useState(false);
  const [selectedCode, setSelectedCode] = useState("");
  const [selectItems, setSelectItems] = useState([]);
  const [newSelectedRows, setNewSelectedRows] = useState([]);

  const getRowClassName = (params) => {
    const balance = params.row.idCompanyStoreTempleteItems;
    return balance === null ? "read-only-row" : "";
  };

  const handleClick = (rowData) => {
    setSelectedCode(rowData.StoreItemsTemplateCode);
    setReplaceModal(true);
  };

  const columns = [
    {
      field: "StoreItemsTemplateCode",
      headerName: t("data_grid.headers.code"),
    },
    {
      field: "StoreItemsTemplateName",
      headerName: t("data_grid.headers.name"),
      width: 210,
    },
    {
      field: "StoreItemsTemplateUnit",
      headerName: t("data_grid.headers.unit"),
    },
    {
      field: "StoreItemsTemplateType",
      headerName: t("data_grid.headers.type"),
    },
    {
      field: "CompanyStoreTempleteItemsBalance",
      headerName: t("data_grid.headers.balance"),
      valueGetter: (params) =>
        params.row.CompanyStoreTempleteItemsBalance === null
          ? "N/A"
          : params.row.CompanyStoreTempleteItemsBalance,
    },
    {
      field: "MaterialsRequestQty",
      headerName: t("data_grid.headers.requested_qty"),
    },
    {
      field: "IssuedQty",
      headerName: t("data_grid.headers.issued_qty"),
      valueGetter: (params) =>
        params.row.IssuedQty === null ? 0 : params.row.IssuedQty,
    },
    {
      field: "QTY",
      headerName: t("data_grid.headers.qty"),
      type: "number",
      valueGetter: (params) => params.row.QTY || 0,
      editable: (params) =>
        params.row.CompanyStoreTempleteItemsBalance !== null,
    },
    {
      field: "Actions",
      headerName: t("data_grid.headers.actions"),
      type: "actions",
      getActions: (params) => [
        <GridActionsCellItem
          key={params.row.id}
          icon={
            <FindReplaceOutlinedIcon
              color={
                !(
                  params.row.IssuedQty === 0 &&
                  params.row.idCompanyStoreTempleteItems != null
                ) || action === "return"
                  ? ""
                  : "primary"
              }
            />
          }
          label="Replace"
          disabled={
            action === "return"
              ? true
              : !(
                  params.row.IssuedQty === 0 &&
                  params.row.idCompanyStoreTempleteItems != null
                )
          }
          onClick={() => {
            handleClick(params.row);
          }}
        />,
      ],
    },
  ];

  const handleSelectionChange = (value) => {
    if (!value) return;
    setSelectedNo(value?.CompanyStoresName);
    getItems();
    setMessage(null);
  };

  useEffect(() => {
    const updatedRows = rows.map((row) => {
      const balance = row.CompanyStoreTempleteItemsBalance;
      return {
        ...row,
      };
    });
    setRows(updatedRows);
  }, [rows]);

  const handleReplaceSelection = (selectedRow) => {
    const isItemExists = rows.some(
      (row) =>
        row.idCompanyStoreTempleteItems ===
        selectedRow[0].idCompanyStoreTempleteItems
    );
    if (isItemExists) {
      enqueueSnackbar("Item already exists", {
        variant: "error",
      });
      return;
    }
    let replacementMade = false; // Local variable to track if any replacement was made
    console.log("selectedRow", selectedRow);

    const updatedRows = rows.map((row) => {
      if (row.StoreItemsTemplateCode === selectedCode) {
        replacementMade = true; // Indicate that a replacement was made
        return {
          ...row,
          StoreItemsTemplateCode: selectedRow[0].StoreItemsTemplateCode,
          StoreItemsTemplateName: selectedRow[0].StoreItemsTemplateName,
          StoreItemsTemplateUnit: selectedRow[0].StoreItemsTemplateUnit,
          StoreItemsTemplateType: selectedRow[0].StoreItemsTemplateType,
          CompanyStoreTempleteItemsBalance:
            selectedRow[0].CompanyStoreTempleteItemsBalance,
          idCompanyStoreTempleteItems:
            selectedRow[0].idCompanyStoreTempleteItems,
          Replaced: true, // This row was replaced
        };
      } else {
        return {
          ...row,
          Replaced: false, // This row was not replaced
          QTY: row.QTY || 0,
        };
      }
    });
    setRows(updatedRows);
    setReplaced(replacementMade);
    setReplaceModal(false);
    setSelectItems([]);
  };

  return (
    <Box sx={{ margin: "12px 1px" }}>
      <Autocomplete
        id="combo-box-demo"
        options={comboBoxData}
        getOptionLabel={(option) => option.CompanyStoresName}
        sx={{ width: "100%", margin: "8px 0" }}
        size="small"
        value={
          comboBoxData.find(
            (option) => option.CompanyStoresName === selectedNo
          ) || null
        }
        onChange={(event, value) => handleSelectionChange(value)}
        renderInput={(params) => (
          <TextField
            {...params}
            label={t("info_modules.material_request.description.store")}
          />
        )}
      />
      <FormsDataGrid
        rows={rows || []}
        columns={columns}
        getRowId={(row) => row.idMaterialsRequestTemplates}
        disableColumnMenu
        loading={loadingItems}
        onCellEditStop={handleQuantity}
        getRowClassName={getRowClassName}
        height={300}
      />
      <ReplaceItems
        open={replaceModal}
        handleClose={() => setReplaceModal(false)}
        selectedNo={selectedNo}
        selectedCode={selectedCode}
        selectItems={selectItems}
        setSelectItems={setSelectItems}
        newSelectedRows={newSelectedRows}
        setNewSelectedRows={setNewSelectedRows}
        handleReplaceSelection={handleReplaceSelection}
      />
    </Box>
  );
};

export default SelectItems;
