import { useTranslation } from "react-i18next";
import PendingWorkOrders from "./Charts/PendingWorkOrders";
import WorkOrdersInProgress from "./Charts/WorkOrdersInProgress";
import OpenMissions from "./Charts/OpenMissions";
import DashboardAccordion from "../../DashboardAccordion";
import { renderIfDataExists } from "../common";

const OperationsSection = ({ data }) => {
  const { t } = useTranslation("global");
  const {
    workOrdersPendingPerProject,
    workOrdersPendingPerUser,
    workOrdersInProgressPerProject,
    workOrdersInProgressPerUser,
    missionInProgressPerProject,
    missionInProgressPerUser,
  } = data;

  return (
    <DashboardAccordion title={t("main_cards.titles.operations")}>
      {renderIfDataExists(PendingWorkOrders, {
        workOrdersPendingPerProject,
        workOrdersPendingPerUser,
      })}
      {renderIfDataExists(WorkOrdersInProgress, {
        workOrdersInProgressPerProject,
        workOrdersInProgressPerUser,
      })}
      {renderIfDataExists(OpenMissions, {
        missionInProgressPerProject,
        missionInProgressPerUser,
      })}
    </DashboardAccordion>
  );
};

export default OperationsSection;
