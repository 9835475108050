import * as yup from "yup";
import { useFormik } from "formik";
import { useSnackbar } from "notistack";
import { useTranslation } from "react-i18next";
import { Link as RouterLink } from "react-router-dom";
import ChipStatus from "../../../../components/ChipStatus";
import { priceListsService } from "../../../../api/priceLists";
import InfoModal from "../../../../components/Modals/InfoModal";
import FormSpinner from "../../../../components/Spinners/FormSpinner";
import { Box, Stack, TextField, Typography, Link } from "@mui/material";
import FormsDataGrid from "../../../../components/DataGrid/FormsDataGrid";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import ButtonGroup from "../../../../components/forms/ButtonGroup/ButtonGroup";
import { useConfirmationDialog } from "../../../../contexts/ConfirmationDialogContext";

const EditItem = ({ open, handleClose, itemId }) => {
  const [t] = useTranslation("global");
  return (
    <InfoModal
      open={open}
      title={t("info_modules.price_list.forms.edit_item.title")}
      handleClose={handleClose}
      content={<ModalContent itemId={itemId} handleClose={handleClose} />}
    />
  );
};

const ModalContent = ({ itemId, handleClose }) => {
  const { data, isLoading } = useQuery({
    queryKey: ["priceListEditItemData", itemId],
    queryFn: () => priceListsService.getEditItemData(itemId),
  });

  if (isLoading) return <FormSpinner />;

  return <Form data={data} handleClose={handleClose} />;
};

const Form = ({ data, handleClose }) => {
  const [t] = useTranslation("global");
  const { enqueueSnackbar } = useSnackbar();
  const queryClient = useQueryClient();
  const { setOpen } = useConfirmationDialog();

  const { mutateAsync: editItem, isPending } = useMutation({
    mutationFn: priceListsService.patchPriceList,
    onSuccess: (data) => {
      handleClose();
      enqueueSnackbar(data.msg, { variant: "success" });
      queryClient.invalidateQueries({ queryKey: ["priceList"] });
      queryClient.invalidateQueries({ queryKey: ["priceListEditItemData"] });
    },
  });

  const requiredMessage = t("modules.global_schema.required");
  const selectValueMessage = t("modules.global_schema.select_value");

  const schema = yup.object().shape({
    code: yup.string().required(requiredMessage),
    price: yup.string().required(requiredMessage),
    unit: yup.string().required(requiredMessage),
    type: yup.string().required(selectValueMessage),
    description: yup.string().required(requiredMessage),
  });

  const { Description, Price, PriceListSN, SI, Unit, idPriceList } =
    data.Itemes[0];

  const initialValues = {
    code: PriceListSN,
    price: Price,
    unit: Unit,
    type: SI,
    description: Description,
  };

  const handleFormSubmit = (values) => {
    const { code, price, unit, description } = values;
    setOpen(
      t("info_modules.price_list.confirmation.edit_item"),
      () => () => {
        editItem({
          Description: description,
          Price: price.toString(),
          PriceListSN: code,
          SelectedID: idPriceList,
          State: "EditItems",
          Unit: unit,
        });
      },
      "success"
    );
  };

  const { values, errors, touched, handleSubmit, handleBlur, handleChange } =
    useFormik({
      initialValues,
      validationSchema: schema,
      onSubmit: handleFormSubmit,
    });

  return (
    <Box
      component={"form"}
      onSubmit={handleSubmit}
      noValidate
      sx={{
        padding: 2,
        display: "flex",
        flexDirection: "column",
        gap: 2,
        width: 1200,
      }}
    >
      <FormContent
        values={values}
        errors={errors}
        touched={touched}
        handleChange={handleChange}
        handleBlur={handleBlur}
      />
      <DataGrid rows={data.result} type="edit" />
      <ButtonGroup
        onClickClose={handleClose}
        isPending={isPending}
        saveLabel={t("modules.buttons.save")}
      />
    </Box>
  );
};

export const FormContent = ({
  values,
  errors,
  touched,
  handleChange,
  handleBlur,
}) => {
  const [t] = useTranslation("global");
  return (
    <Stack spacing={2}>
      <Stack direction="row" spacing={2}>
        <TextField
          size="small"
          required
          fullWidth
          label={t("info_modules.price_list.forms.add_item.fields.code")}
          name="code"
          value={values.code}
          onChange={handleChange}
          onBlur={handleBlur}
          error={touched.code && Boolean(errors.code)}
          helperText={touched.code && errors.code}
          sx={{
            flex: 1,
          }}
        />
        <TextField
          size="small"
          required
          fullWidth
          label={t("info_modules.price_list.forms.add_item.fields.price")}
          name="price"
          value={values.price}
          onChange={handleChange}
          onBlur={handleBlur}
          error={touched.price && Boolean(errors.price)}
          helperText={touched.price && errors.price}
          sx={{
            flex: 1,
          }}
        />
        <TextField
          size="small"
          required
          fullWidth
          label={t("info_modules.price_list.forms.add_item.fields.unit")}
          name="unit"
          value={values.unit}
          onChange={handleChange}
          onBlur={handleBlur}
          error={touched.unit && Boolean(errors.unit)}
          helperText={touched.unit && errors.unit}
          sx={{
            flex: 1,
          }}
        />

        <TextField
          disabled
          aria-readonly
          size="small"
          fullWidth
          label={t("info_modules.price_list.forms.add_item.fields.type")}
          name="type"
          value={values.type}
          sx={{
            flex: 1,
          }}
        />
      </Stack>
      <TextField
        size="small"
        required
        fullWidth
        label={t("info_modules.price_list.forms.add_item.fields.description")}
        name="description"
        value={values.description}
        onChange={handleChange}
        onBlur={handleBlur}
        error={touched.description && Boolean(errors.description)}
        helperText={touched.description && errors.description}
        multiline
        rows={4}
      />
    </Stack>
  );
};

// type = "replace" | "edit" | "delete"
export const DataGrid = ({
  rows,
  type,
  selectedRows,
  onRowSelectionModelChange,
}) => {
  const [t] = useTranslation("global");

  const columns = [
    {
      field: "WorkOrderD6No",
      headerName: t("data_grid.headers.ref_no"),
      width: 120,
      renderCell: (params) => {
        return (
          <Link
            component={RouterLink}
            to={`/quotations/${params.value}`}
            underline="none"
            target="_blank"
          >
            {params.value}
          </Link>
        );
      },
    },
    {
      field: "WorkOrderNo",
      headerName: t("data_grid.headers.order_no"),
      width: 120,
    },
    {
      field: "SiteName",
      headerName: t("data_grid.headers.site_name"),
      width: 70,
      flex: 1,
    },

    {
      field: "SiteCode",
      headerName: t("data_grid.headers.site_code"),
      width: 140,
      flex: 1,
    },
    {
      field: "WorkOrderD6Amount",
      headerName: t("data_grid.headers.amount"),
      width: 70,
    },
    {
      field: "WorkOrderD6Install",
      headerName: t("data_grid.headers.install_amount"),
      width: 70,
    },
    {
      field: "WorkOrderD6Supply",
      headerName: t("data_grid.headers.supply_amount"),
      width: 70,
    },
    {
      field: "WorkOrderStatus",
      headerName: t("data_grid.headers.wo_status"),
      headerAlign: "center",
      width: 130,
      renderCell: ({ value }) => {
        return <ChipStatus value={value} />;
      },
    },
    {
      field: "WorkOrderD6Status",
      headerName: t("data_grid.headers.d6_status"),
      headerAlign: "center",
      width: 130,
      renderCell: ({ value }) => {
        return <ChipStatus value={value} />;
      },
    },
  ];

  return (
    <Stack spacing={2}>
      <Typography variant="h6" gutterBottom>
        {t("info_modules.price_list.description.related_quotations")}
      </Typography>
      <FormsDataGrid
        height={300}
        rows={rows}
        columns={columns}
        getRowId={(row) => row.WorkOrderD6No}
        toolbar
        showExportButton
        showQuickFilter
        hideFooter={type !== "replace"}
        checkboxSelection={type === "replace"}
        rowSelectionModel={
          type === "replace" && selectedRows.map((row) => row.WorkOrderD6No)
        }
        onRowSelectionModelChange={
          type === "replace" && onRowSelectionModelChange
        }
      />
    </Stack>
  );
};

export default EditItem;
