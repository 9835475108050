import Axios from "../network/Axios";

const API_ENDPOINT = "/StatementsManagement";

const getStatements = async () => {
  const response = await Axios.get(API_ENDPOINT);
  return response.data.result;
};

const getStatementEditFormData = async (id) => {
  const response = await Axios.get(API_ENDPOINT, {
    params: {
      SelectedNo: id,
      State: "Edit",
    },
  });
  return response.data.result[0];
};

const getStatementsByState = async (state) => {
  const response = await Axios.get(API_ENDPOINT, {
    params: {
      State: state,
    },
  });
  return response.data.result;
};

export const StatementsManagementService = {
  getStatements,
  getStatementEditFormData,
  getStatementsByState,
};
