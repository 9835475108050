import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { useTranslation } from "react-i18next";
import { useNavigate, useSearchParams, Link } from "react-router-dom";
import { SitesDatabaseService } from "../../../api/sitesDatabase";
import Spinner from "../../../components/Spinners/Spinner";
import InfoCard from "../../../components/InfoPagesCard/InfoCard";
import {
  Autocomplete,
  Box,
  Checkbox,
  Chip,
  FormControlLabel,
  Grid,
} from "@mui/material";
import ChipComponent from "../../../components/Chips/ChipComponent";
import NoteAddIcon from "@mui/icons-material/NoteAdd";
import FormsDataGrid from "../../../components/DataGrid/FormsDataGrid";
import ChipStatus from "../../../components/ChipStatus";
import { useState } from "react";
import { useFormik } from "formik";
import * as yup from "yup";
import { useSnackbar } from "notistack";
import ButtonGroup from "../../../components/forms/ButtonGroup/ButtonGroup";
import dayjs from "dayjs";
import { StyledTextField } from "../MobileInternetLine/MobileInternetLine";
import ActionButton from "../../../components/forms/ButtonGroup/ActionButton";
import InfoModal from "../../../components/Modals/InfoModal";
import WorkOrdersForm from "../../../components/forms/WorkOrders/WorkOrdersForm";
import { useConfirmationDialog } from "../../../contexts/ConfirmationDialogContext";
import DateFormatGrid from "../../../components/DateFormatGrid";
import ActionMenuButton from "../../../components/forms/ButtonGroup/ActionMenuButton";

const SiteInfo = () => {
  const [t] = useTranslation("global");

  const columns = [
    {
      field: "WorkOrderNo",
      headerName: t("data_grid.headers.ref_no"),
      width: 120,
      renderCell: (params) => (
        <Link
          style={{
            color: "#1790FF",
          }}
          to={`/workorders/${params.value}`}
          target="_blank"
        >
          {params.value}
        </Link>
      ),
    },
    {
      field: "WorkOrderDate",
      headerName: t("data_grid.headers.received_date"),
      valueFormatter: (params) => {
        return dayjs(params.value).format("DD/MM/YYYY HH:mm A");
      },
      width: 150,
      renderCell: ({ value }) => {
        return <> {value ? <DateFormatGrid value={value} /> : <> </>}</>;
      },
    },
    {
      field: "LastUpdateDate",
      headerName: t("data_grid.headers.last_update"),
      valueFormatter: (params) => {
        return dayjs(params.value).format("DD/MM/YYYY HH:mm A");
      },
      width: 150,
      renderCell: ({ value }) => {
        return <> {value ? <DateFormatGrid value={value} /> : <> </>}</>;
      },
    },
    {
      field: "SubProjectsName",
      headerName: t("data_grid.headers.sub_project"),
      flex: 1,
    },
    {
      field: "RequestorsName",
      headerName: t("data_grid.headers.requested_by"),
      flex: 1,
    },
    {
      field: "ERPUserNickName",
      headerName: t("data_grid.headers.created_by"),
      flex: 1,
    },
    {
      field: "WorkOrderStatus",
      headerName: t("data_grid.headers.wo_status"),
      headerAlign: "center",
      width: 130,
      renderCell: ({ value }) => {
        return <ChipStatus value={value} />;
      },
    },
  ];

  const [params] = useSearchParams();

  const siteId = params.get("id");

  const [editMode, setEditMode] = useState(false);

  // turn off editMode when the user clicks on the cancel button
  const handleCancelEditMode = () => {
    setEditMode(false);
  };

  // turn on editMode when the user clicks on the edit button
  const handleOpenEditMode = () => {
    setEditMode(true);
  };

  const { data: siteInfo, isLoading: isLoadingSiteData } = useQuery({
    queryKey: ["siteInfo", siteId],
    queryFn: () => SitesDatabaseService.getSiteData(siteId),
  });

  const { data: siteInfoToEdit, refetch } = useQuery({
    queryKey: ["siteInfoToEdit", siteId],
    queryFn: () => SitesDatabaseService.getSiteData(siteId, "Edit"),
    enabled: false,
  });

  // Handle new work order modal
  const [openNewWorkOrderModal, setOpenNewWorkOrderModal] = useState(false);

  const handleOpenNewWorkOrderModal = () => setOpenNewWorkOrderModal(true);

  const handleCloseNewWorkOrderModal = () => setOpenNewWorkOrderModal(false);

  // Handle dirty state for the sites database form
  const [dirty, setDirty] = useState(false);

  const onDirtyChange = (isDirty) => {
    setDirty(isDirty);
  };

  const { setOpen } = useConfirmationDialog();

  const onClickClose = () => {
    if (dirty) {
      setOpen(
        t("modules.confirmation_message"),
        () => handleCloseNewWorkOrderModal
      );
    } else {
      handleCloseNewWorkOrderModal();
    }
  };

  if (isLoadingSiteData) return <Spinner />;

  const {
    SiteName,
    CSiteCode,
    SiteType,
    Typology,
    OwnerName,
    OwnerContact,
    Latitude,
    Longitude,
    GovernoratesName,
    SiteAddress,
    ERPUserNickName,
    Candidate,
    Phase,
    idSitesDatabase,
  } = siteInfo.result[0];

  const siteInfoDetails = [
    {
      label: t("modules.sites_database.fields.site_name"),
      value: SiteName,
    },
    {
      label: t("modules.sites_database.fields.site_code"),
      value: <Chip color="primary" size="small" label={CSiteCode} />,
    },
    {
      label: t("modules.sites_database.fields.site_type"),
      value: SiteType,
    },
    {
      label: t("modules.sites_database.fields.typology"),
      value: Typology,
    },
    {
      label: t("modules.sites_database.fields.user"),
      value: ERPUserNickName,
    },
    {
      label: t("modules.sites_database.fields.owner_name"),
      value: OwnerName,
    },
    {
      label: t("modules.sites_database.fields.owner_contact"),
      value: OwnerContact,
    },
    {
      label: t("modules.sites_database.fields.latitude"),
      value: Latitude,
    },
    {
      label: t("modules.sites_database.fields.longitude"),
      value: Longitude,
    },
    {
      label: t("modules.sites_database.fields.governorate"),
      value: GovernoratesName,
    },
    {
      label: t("modules.sites_database.fields.site_address"),
      value: SiteAddress,
    },
  ];
  const actions =
    siteInfo.Actions.filter((action) => action !== "Edit Details") || [];
  const actionsConfig = {
    "New WorkOrder": {
      label: t("info_modules.sites_database.buttons.new_work_order"),
      icon: <NoteAddIcon sx={{ width: "1.1rem", height: "1.1rem" }} />,
      color: "primary",
      onClick: handleOpenNewWorkOrderModal,
    },
  };

  return (
    <>
      <Box display="flex" flexDirection="column" gap={1.7} marginBottom={2}>
        {editMode ? (
          <EditForm
            initialData={siteInfo.result[0]}
            siteTypes={siteInfoToEdit.SitesTypes}
            siteTypologies={siteInfoToEdit.SitesTypologies}
            governorates={siteInfoToEdit.Governorate}
            siteId={siteId}
            handleCancelEditMode={handleCancelEditMode}
          />
        ) : (
          <InfoCard
            editButton={siteInfo.Actions.find(
              (action) => action === "Edit Details"
            )}
            onClickEdit={() => {
              refetch().then(() => {
                handleOpenEditMode();
              });
            }}
            title={t("info_modules.sites_database.description.site_details")}
            content={
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  gap: "0.2rem",
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "flex-end",
                    marginBottom: "4px",
                  }}
                >
                  {actions.length > 1 ? (
                    <ActionMenuButton
                      actions={actions}
                      actionsConfig={actionsConfig}
                    />
                  ) : (
                    <ActionButton
                      icon={actionsConfig[actions[0]].icon}
                      text={actionsConfig[actions[0]].label}
                      onClick={actionsConfig[actions[0]].onClick}
                      color={actionsConfig[actions[0]].color}
                    />
                  )}
                </Box>
                <Grid container spacing={1}>
                  {siteInfoDetails.map((property, index) => (
                    <Grid
                      item
                      xs={index === siteInfoDetails.length - 1 ? 8 : 4}
                      key={property.label}
                    >
                      <ChipComponent
                        label={property.label}
                        value={editMode ? property.input : property.value}
                      />
                    </Grid>
                  ))}
                </Grid>
              </Box>
            }
          />
        )}
        <InfoCard
          title={t("info_modules.sites_database.description.work_orders_ist")}
          content={
            <Box>
              <FormsDataGrid
                height={400}
                rows={siteInfo.workorder}
                columns={columns}
                getRowId={(row) => row.WorkOrderNo}
              />
            </Box>
          }
        />
      </Box>
      <InfoModal
        open={openNewWorkOrderModal}
        handleClose={handleCloseNewWorkOrderModal}
        title={t("modules.work_orders.new_form.title")}
        content={
          <Box p={2}>
            <WorkOrdersForm
              onClickClose={onClickClose}
              onDirtyChange={onDirtyChange}
              handleCloseFromAnotherForm={handleCloseNewWorkOrderModal}
              siteDetails={{ Candidate, Phase, idSitesDatabase, SiteName }}
            />
          </Box>
        }
        width="30rem"
      />
    </>
  );
};

const autoCompleteStyles = {
  width: "100%",
  "&:not(.Mui-focused) label.Mui-error": {
    transform: "translate(14px, -9px) scale(0.75)",
    backgroundColor: "background.default",
    padding: "0 6px",
  },
};

const EditForm = ({
  initialData,
  governorates,
  siteTypes,
  siteTypologies,
  siteId,
  handleCancelEditMode,
}) => {
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const queryClient = useQueryClient();
  const [t] = useTranslation("global");

  const requiredMessage = t("modules.global_schema.required");

  const schema = yup.object().shape({
    siteType: yup.string().required(requiredMessage),
    typology: yup.string().required(requiredMessage),
    siteName: yup.string().required(requiredMessage),
    siteCode: yup.string().required(requiredMessage),
    candidate: yup.string().required(requiredMessage),
    phase: yup.string().required(requiredMessage),
    governorate: yup.string().required(requiredMessage),
    checkboxValue: yup.boolean(),
    siteAddress: yup.string().required(requiredMessage),
    latitude: yup.string().required(requiredMessage),
    longitude: yup.string().required(requiredMessage),
    ownerContact: yup.string().required(requiredMessage),
    ownerName: yup.string().required(requiredMessage),
    replacement: yup.boolean(),
  });

  const {
    SiteName,
    CSiteCode,
    SiteType,
    Typology,
    OwnerName,
    OwnerContact,
    Latitude,
    Longitude,
    GovernoratesName,
    SiteAddress,
    Candidate,
    Phase,
  } = initialData;

  const handleFormSubmit = (values) => {
    const {
      siteType,
      typology,
      siteName,
      siteCode,
      candidate,
      phase,
      governorate,
      siteAddress,
      latitude,
      longitude,
      ownerContact,
      ownerName,
      replacement,
    } = values;

    updateSiteInfo({
      SiteName: siteName,
      SiteCode: siteCode,
      SiteType: siteType,
      Typology: typology,
      OwnerName: ownerName,
      OwnerContact: ownerContact,
      Latitude: latitude,
      Longitude: longitude,
      Governorates: governorate,
      SiteAddress: siteAddress,
      Candidate: candidate,
      Phase: phase,
      Replacement: replacement,
      SelectedID: Number(siteId),
    });
  };

  const initialValues = {
    siteType: SiteType,
    typology: Typology,
    siteName: SiteName,
    siteCode: CSiteCode.split("_").slice(1, -1).join("_"),
    candidate: Candidate,
    phase: Phase,
    governorate: GovernoratesName,
    siteAddress: SiteAddress,
    latitude: Latitude,
    longitude: Longitude,
    ownerContact: OwnerContact,
    ownerName: OwnerName,
    replacement: false,
  };

  const {
    values,
    errors,
    touched,
    handleBlur,
    handleChange,
    handleSubmit,
    setFieldValue,
  } = useFormik({
    initialValues,
    validationSchema: schema,
    onSubmit: handleFormSubmit,
  });

  const siteInfoDetails = [
    {
      label: t("modules.sites_database.fields.site_name"),
      input: (
        <StyledTextField
          size="small"
          name="siteName"
          value={values.siteName}
          onBlur={handleBlur}
          onChange={handleChange("siteName")}
          error={!!touched.siteName && !!errors.siteName}
          label={touched.siteName ? errors.siteName : ""}
          autoFocus
          sx={{
            "& .MuiInputBase-input": {
              height: "10px",
              fontSize: "0.85rem",
              fontWeight: 600,
            },
          }}
        />
      ),
    },
    {
      label: t("modules.sites_database.fields.site_code"),
      input: (
        <Box
          sx={{
            display: "grid",
            gridTemplateColumns: "1fr 2fr 1fr",
            gap: ".2rem",
          }}
        >
          <StyledTextField
            name="candidate"
            value={values.candidate}
            onBlur={handleBlur}
            onChange={handleChange("candidate")}
            error={!!touched.candidate && !!errors.candidate}
            disableWidth
          />
          <StyledTextField
            name="siteCode"
            value={values.siteCode}
            onBlur={handleBlur}
            onChange={handleChange("siteCode")}
            error={!!touched.siteCode && !!errors.siteCode}
            disableWidth
          />
          <StyledTextField
            name="phase"
            value={values.phase}
            onBlur={handleBlur}
            onChange={handleChange("phase")}
            error={!!touched.phase && !!errors.phase}
            disableWidth
          />
        </Box>
      ),
    },
    {
      label: t("modules.sites_database.fields.site_type"),
      input: (
        <Autocomplete
          disableClearable
          disablePortal
          id="combo-box-demo"
          options={siteTypes || []}
          getOptionLabel={(option) => option.SiteTypesNames}
          sx={autoCompleteStyles}
          size="small"
          onChange={(event, newValue) => {
            setFieldValue("siteType", newValue ? newValue.SiteTypesNames : "");
          }}
          value={
            siteTypes.find(
              (option) => option.SiteTypesNames === values.siteType
            ) || null
          }
          renderInput={(params) => (
            <StyledTextField
              {...params}
              onBlur={handleBlur}
              error={!!touched.siteType && !!errors.siteType}
              label={touched.siteType ? errors.siteType : ""}
            />
          )}
        />
      ),
    },
    {
      label: t("modules.sites_database.fields.typology"),
      input: (
        <Autocomplete
          disableClearable
          disablePortal
          id="combo-box-demo"
          options={siteTypologies || []}
          getOptionLabel={(option) => option.Typology}
          sx={autoCompleteStyles}
          size="small"
          onChange={(event, newValue) => {
            setFieldValue("typology", newValue ? newValue.Typology : "");
          }}
          value={
            siteTypologies.find(
              (option) => option.Typology === values.typology
            ) || null
          }
          renderInput={(params) => (
            <StyledTextField
              {...params}
              onBlur={handleBlur}
              error={!!touched.typology && !!errors.typology}
              label={touched.typology ? errors.typology : ""}
            />
          )}
        />
      ),
    },
    {
      label: t("modules.sites_database.fields.owner_name"),
      input: (
        <StyledTextField
          size="small"
          name="ownerName"
          value={values.ownerName}
          onBlur={handleBlur}
          onChange={handleChange("ownerName")}
          error={!!touched.ownerName && !!errors.ownerName}
          label={touched.ownerName ? errors.ownerName : ""}
        />
      ),
    },
    {
      label: t("modules.sites_database.fields.owner_contact"),
      input: (
        <StyledTextField
          size="small"
          name="ownerContact"
          value={values.ownerContact}
          onBlur={handleBlur}
          onChange={handleChange("ownerContact")}
          error={!!touched.ownerContact && !!errors.ownerContact}
          label={touched.ownerContact ? errors.ownerContact : ""}
        />
      ),
    },
    {
      label: t("modules.sites_database.fields.latitude"),
      input: (
        <StyledTextField
          size="small"
          name="latitude"
          value={values.latitude}
          onBlur={handleBlur}
          onChange={handleChange("latitude")}
          error={!!touched.latitude && !!errors.latitude}
          label={touched.latitude ? errors.latitude : ""}
        />
      ),
    },
    {
      label: t("modules.sites_database.fields.longitude"),
      input: (
        <StyledTextField
          size="small"
          name="longitude"
          value={values.longitude}
          onBlur={handleBlur}
          onChange={handleChange("longitude")}
          error={!!touched.longitude && !!errors.longitude}
          label={touched.longitude ? errors.longitude : ""}
        />
      ),
    },
    {
      label: t("modules.sites_database.fields.governorate"),
      input: (
        <Autocomplete
          disableClearable
          disablePortal
          id="combo-box-demo"
          options={governorates || []}
          getOptionLabel={(option) => option.GovernoratesName}
          sx={autoCompleteStyles}
          size="small"
          onChange={(event, newValue) => {
            setFieldValue(
              "governorate",
              newValue ? newValue.GovernoratesName : ""
            );
          }}
          value={
            governorates.find(
              (option) => option.GovernoratesName === values.governorate
            ) || null
          }
          renderInput={(params) => (
            <StyledTextField
              {...params}
              onBlur={handleBlur}
              error={!!touched.governorate && !!errors.governorate}
              label={touched.governorate ? errors.governorate : ""}
            />
          )}
        />
      ),
    },
    {
      label: t("modules.sites_database.fields.site_address"),
      input: (
        <StyledTextField
          size="small"
          name="siteAddress"
          value={values.siteAddress}
          onBlur={handleBlur}
          onChange={handleChange("siteAddress")}
          error={!!touched.siteAddress && !!errors.siteAddress}
          label={touched.siteAddress ? errors.siteAddress : ""}
        />
      ),
    },
  ];

  const { mutateAsync: updateSiteInfo, isPending } = useMutation({
    mutationFn: SitesDatabaseService.updateSiteInfo,
    onSuccess: (data) => {
      if (!data) return;
      handleCancelEditMode();
      enqueueSnackbar(data.msg, {
        variant: "success",
      });
      queryClient.invalidateQueries({ queryKey: ["siteInfo"] });
      navigate(`/sitesdatabase/${values.siteName}?id=${siteId}`, {
        replace: true,
      });
    },
  });

  return (
    <InfoCard
      title={t("info_modules.sites_database.description.site_details")}
      content={
        <Box display="flex" flexDirection="column" gap={1.7} marginBottom={2}>
          <form onSubmit={handleSubmit}>
            <Grid container spacing={2} columnSpacing={3}>
              {siteInfoDetails.map((property) => (
                <Grid
                  item
                  xs={4}
                  key={property.label}
                  sx={{
                    display: "flex",
                    "& > div": {
                      width: "100%",
                    },
                  }}
                >
                  <ChipComponent
                    label={property.label}
                    value={property.input}
                  />
                </Grid>
              ))}
              <Grid item xs={4}>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={values.replacement}
                      onChange={handleChange("replacement")}
                      color="primary"
                    />
                  }
                  label={t("modules.sites_database.fields.replacement_site")}
                />
              </Grid>
            </Grid>
            <ButtonGroup
              saveLabel={t("modules.buttons.save")}
              isPending={isPending}
              onClickClose={handleCancelEditMode}
            />
          </form>
        </Box>
      }
    />
  );
};

export default SiteInfo;
