import { Box, TextField } from "@mui/material";
import ChipStatus from "../../../ChipStatus";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import DeleteIcon from "@mui/icons-material/Delete";
import { GridActionsCellItem } from "@mui/x-data-grid-pro";
import ChipComponent from "../../../Chips/ChipComponent";
import CustomAccordion from "../../../Accordion/CustomAccordion";

const Review = ({
  workOrderRows,
  employeeRows,
  carsRows,
  itemsRows,
  onClick,
  no,
  dateTime,
  description,
  setDescription,
  descriptionLabel,
  isThreeStepProcess,
  isMaterialRequestEnabled,
  skipBtnClicked,
}) => {
  const handleDeleteClick = (id, type) => {
    onClick(id, type);
  };
  const [expanded, setExpanded] = useState("panel1");
  const [t] = useTranslation("global");
  const handleChange = (panel) => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false);
  };

  const workOrderColumns = [
    {
      field: "WorkOrderNo",
      headerName: t("data_grid.headers.ref_no"),
      width: 200,
    },
    {
      field: "SiteName",
      headerName: t("data_grid.headers.site_name"),
      width: 120,
      flex: 1,
    },
    {
      field: "SiteCode",
      headerName: t("data_grid.headers.site_code"),
      width: 120,
      flex: 1,
    },
    {
      field: "WorkOrderDate",
      headerName: t("data_grid.headers.date"),
      width: 100,
    },
    {
      field: "ERPUserNickName",
      headerName: t("data_grid.headers.created_by"),
      width: 160,
    },
    {
      field: "WorkOrderStatus",
      headerName: t("data_grid.headers.status"),
      width: 150,
      renderCell: ({ value }) => {
        return <ChipStatus value={value} />;
      },
    },
  ];

  const employeeColumns = [
    {
      field: "EmpCode",
      headerName: t("data_grid.headers.emp_code"),
    },
    {
      field: "EmpName",
      headerName: t("data_grid.headers.emp_name"),
      flex: 1,
    },
    {
      field: "EmpNameEn",
      headerName: t("data_grid.headers.employee_name_en"),
      flex: 1,
    },
    {
      field: "EmpTitle",
      headerName: t("data_grid.headers.emp_title"),
    },
    {
      field: "Actions",
      headerName: t("data_grid.headers.actions"),
      type: "actions",

      getActions: (params) => [
        <GridActionsCellItem
          key={params.row.idEmpInfo}
          icon={<DeleteIcon color="error" />}
          label="Delete"
          onClick={() => handleDeleteClick(params.row.idEmpInfo, "team")}
        />,
      ],
    },
  ];

  const carColumns = [
    {
      field: "CarsNumber",
      headerName: t("data_grid.headers.cars"),
      width: 120,
    },
    {
      field: "EmpName",
      headerName: t("data_grid.headers.driver"),
      flex: 1,
    },
    {
      field: "Actions",
      headerName: t("data_grid.headers.actions"),
      type: "actions",
      getActions: (params) => [
        <GridActionsCellItem
          key={params.row.idCarsInfoCarsNumbers}
          icon={<DeleteIcon color="error" />}
          label="Delete"
          onClick={() =>
            handleDeleteClick(params.row.idCarsInfoCarsNumbers, "car")
          }
        />,
      ],
    },
  ];

  const itemsColumns = [
    {
      field: "StoreItemsTemplateCode",
      headerName: t("data_grid.headers.item_code"),
      width: 160,
    },
    {
      field: "StoreItemsTemplateName",
      headerName: t("data_grid.headers.item_description"),
      width: 190,
      flex: 1,
    },
    {
      field: "StoreItemsTemplateUnit",
      headerName: t("data_grid.headers.unit"),
      width: 120,
    },
    {
      field: "StoreItemsTemplateType",
      headerName: t("data_grid.headers.type"),
      width: 120,
    },
    {
      field: "StoreItemsTemplateQty",
      headerName: t("data_grid.headers.item_total_balance"),
      width: 120,
    },
    {
      field: "Quantity",
      headerName: t("data_grid.headers.quantity"),
      width: 120,
    },
  ];

  return (
    <Box sx={{ margin: "15px 2px" }}>
      <Box display="grid" gridTemplateColumns="repeat(2, 1fr)" marginBottom={1}>
        <ChipComponent
          label={t("modules.missions.fields.mission_no")}
          value={no}
        />
        <ChipComponent
          label={t("modules.missions.fields.mission_start_date")}
          value={dateTime}
        />
      </Box>
      {isThreeStepProcess === false && (
        <CustomAccordion
          title={t("modules.missions.label.work_orders")}
          rows={workOrderRows}
          columns={workOrderColumns}
          height={190}
          getRowId={(row) => row.idWorkOrder}
          hideFooter
          expanded={expanded === "panel2"}
          onChange={handleChange("panel2")}
        />
      )}

      <CustomAccordion
        title={t("modules.missions.label.team")}
        rows={employeeRows}
        columns={employeeColumns}
        height={180}
        getRowId={(row) => row.idEmpInfo}
        hideFooter
        expanded={expanded === "panel1"}
        onChange={handleChange("panel1")}
      />
      <CustomAccordion
        title={t("modules.missions.label.cars")}
        rows={carsRows}
        columns={carColumns}
        height={180}
        getRowId={(row) => row.idCarsInfoCarsNumbers}
        hideFooter
        expanded={expanded === "panel3"}
        onChange={handleChange("panel3")}
      />
      {isMaterialRequestEnabled && !skipBtnClicked && (
        <CustomAccordion
          title={t("modules.missions.label.items")}
          rows={itemsRows}
          columns={itemsColumns}
          height={180}
          getRowId={(row) => row.idStoreItemsTemplateInfo}
          hideFooter
          expanded={expanded === "panel4"}
          onChange={handleChange("panel4")}
        />
      )}
      <TextField
        label={descriptionLabel}
        sx={{ width: "100%", marginTop: "10px" }}
        multiline
        rows={2}
        value={description}
        onChange={(event) => {
          setDescription(event.target.value);
        }}
        required
        error={description === ""}
        helperText={
          description === "" ? t("modules.global_schema.required") : ""
        }
      />
    </Box>
  );
};
export default Review;
