import { Box, useTheme } from "@mui/material";

const CardContainer = ({ children }) => {
  const theme = useTheme();

  return (
    <Box
      sx={{
        backgroundColor: "background.innerCard",
        borderRadius: "5px",
        padding: "10px",
        border: `1px solid ${theme.palette.secondary.main}`,
      }}
    >
      {children}
    </Box>
  );
};

export default CardContainer;
