// dateRangeSlice.js
import { createSlice } from "@reduxjs/toolkit";
import dayjs from "dayjs";

const mailServiceStartDate = dayjs().subtract(1, "month").startOf("day");
const mailServiceEndDate = dayjs().endOf("day");

const initialState = {
  mailServiceStartDate,
  mailServiceEndDate,
};

const dateRangeMailServiceSlice = createSlice({
  name: "dateRangeMailService",
  initialState,
  reducers: {
    setMailServiceStartDate(state, action) {
      state.mailServiceStartDate = action.payload;
    },
    setMailServiceEndDate(state, action) {
      state.mailServiceEndDate = action.payload;
    },
  },
});

export const { setMailServiceStartDate, setMailServiceEndDate } =
  dateRangeMailServiceSlice.actions;
export default dateRangeMailServiceSlice.reducer;
