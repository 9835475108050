import { Box, Stack, TextField, Typography } from "@mui/material";
import { SimpleTreeView, TreeItem2 } from "@mui/x-tree-view";
import { forwardRef } from "react";
import { useTranslation } from "react-i18next";
import ButtonGroup from "../../../components/forms/ButtonGroup/ButtonGroup";
import ChipStatus from "../../../components/ChipStatus";
import FormsDataGrid from "../../../components/DataGrid/FormsDataGrid";

const CustomTreeItemCheckbox = forwardRef(() => null);

export const DataGroupsPrivilegesTree = ({
  currentDataGroupName,
  handleDataGroupNameChange,
  selectedItems,
  handleSelectedItemsChange,
  sortedDataGroups,
  data,
  isNewDataGroup,
  isPending,
  handleSubmit,
  onClose,
  isTouched,
}) => {
  const { t } = useTranslation("global");

  return (
    <Stack p={2} spacing={2}>
      <TextField
        label={t("modules.users_management.data_groups.labels.group_name")}
        size="small"
        value={currentDataGroupName}
        onChange={handleDataGroupNameChange}
        error={isTouched && currentDataGroupName.length === 0}
        helperText={
          isTouched && currentDataGroupName.length === 0
            ? t(
                "modules.users_management.data_groups.error_messages.group_name_required"
              )
            : ""
        }
        required
      />
      <Box
        sx={{
          display: "grid",
          gridTemplateColumns: "1fr 1fr",
          gap: 2,
        }}
      >
        <Stack spacing={2}>
          <Typography variant="h6">
            {t("modules.users_management.data_groups.headlines.group_access")}
          </Typography>
          <Box sx={{ height: 352, overflowY: "auto" }}>
            <SimpleTreeView
              selectedItems={selectedItems}
              onSelectedItemsChange={handleSelectedItemsChange}
              multiSelect
              checkboxSelection
            >
              {Object.entries(sortedDataGroups).map(([category, modules]) => (
                <TreeItem2
                  key={category}
                  itemId={category}
                  label={category}
                  slots={{
                    checkbox: CustomTreeItemCheckbox,
                  }}
                >
                  {Object.entries(modules).map(([module, subProjects]) => (
                    <TreeItem2
                      key={module}
                      itemId={module}
                      label={module}
                      slots={{
                        checkbox: CustomTreeItemCheckbox,
                      }}
                    >
                      {subProjects.map(({ SubProjectsName, idSubProjects }) => (
                        <TreeItem2
                          key={idSubProjects}
                          itemId={[SubProjectsName, idSubProjects].join("-")}
                          label={SubProjectsName}
                        />
                      ))}
                    </TreeItem2>
                  ))}
                </TreeItem2>
              ))}
            </SimpleTreeView>
          </Box>
        </Stack>
        {!isNewDataGroup && (
          <Stack spacing={2}>
            <Typography variant="h6">
              {t("modules.users_management.data_groups.headlines.users_list")}
            </Typography>
            <UsersList rows={data.UsersInGroups} />
          </Stack>
        )}
      </Box>
      <ButtonGroup
        isPending={isPending}
        onClickSave={handleSubmit}
        onClickClose={onClose}
        saveLabel={t("modules.buttons.save")}
      />
    </Stack>
  );
};

export const UsersList = ({ rows }) => {
  const { t } = useTranslation("global");
  const columns = [
    {
      field: "ERPUserNickName",
      headerName: t("data_grid.headers.user_name"),
      flex: 1,
    },
    {
      field: "ERPUserStatus",
      headerName: t("data_grid.headers.status"),
      headerAlign: "center",
      flex: 1,
      renderCell: ({ value }) => {
        return <ChipStatus value={value} />;
      },
    },
  ];

  return (
    <FormsDataGrid
      rows={rows}
      columns={columns}
      height={300}
      getRowId={(row) => row.ERPUserNickName}
      disableColumnMenu
    />
  );
};
