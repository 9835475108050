import { Box, Stack, TextField, Typography } from "@mui/material";
import { Toggle } from "../common";

const ChartOptions = ({
  values,
  setFieldValue,
  errors,
  touched,
  handleBlur,
  handleChange,
  chartTypesMap,
}) => {
  const {
    chartName,
    chartType,
    totalToggleState,
    multiValueToggleState,
    countAmountToggleState,
    isHorizontalBar,
    leftValue,
    rightValue,
    barThickness,
    distributed,
    clickableValues,
    circleChartType,
    percentageBar,
  } = values;

  const handleToggle = (event) => {
    const { name, checked } = event.target;
    setFieldValue(name, checked);
    if (name === "percentageBar" && checked) {
      setFieldValue("totalToggleState", false);
      setFieldValue("distributed", false);
      setFieldValue("countAmountToggleState", false);
      setFieldValue("multiValueToggleState", false);
      setFieldValue("isHorizontalBar", true);
    }
  };

  const handleBarThickness = (event) => {
    const value = event.target.value;
    if (value >= 1 && value <= 100) setFieldValue("barThickness", value);
  };

  return (
    <Box
      sx={{
        display: "grid",
        gridTemplateColumns: "1fr 1fr",
        gap: 2,
        padding: "1rem",
        flexGrow: 1,
      }}
    >
      <Stack spacing={2}>
        <TextField
          name="chartName"
          label="Chart Title"
          size="small"
          variant="outlined"
          onChange={handleChange}
          onBlur={handleBlur}
          value={chartName}
          error={!!touched.chartName && !!errors.chartName}
          helperText={touched.chartName && errors.chartName}
        />
        {
          // Bar chart options
          chartType === "Bar" && (
            <Box
              sx={{
                display: "grid",
                gridTemplateColumns: "repeat(2, 1fr)",
                gap: 2,
              }}
            >
              <Stack direction="row" spacing={1} alignItems="center">
                <Typography variant="body">Vertical</Typography>
                <Toggle
                  name="isHorizontalBar"
                  checked={isHorizontalBar}
                  onChange={handleToggle}
                  disabled={percentageBar}
                />
                <Typography variant="body">Horizontal</Typography>
              </Stack>
              <Toggle
                label="Distributed"
                name="distributed"
                checked={distributed}
                onChange={handleToggle}
                disabled={percentageBar}
              />

              <TextField
                name="barThickness"
                label="Bar Thickness"
                value={barThickness}
                onChange={handleBarThickness}
                onBlur={handleBlur}
                size="small"
                variant="outlined"
                error={!!touched.barThickness && !!errors.barThickness}
                helperText={touched.barThickness && errors.barThickness}
              />
            </Box>
          )
        }
        {chartType === "Donut" && (
          <Stack direction="row" spacing={1} alignItems="center">
            <Typography variant="body">Donut</Typography>
            <Toggle
              name="circleChartType"
              checked={circleChartType}
              onChange={handleToggle}
            />
            <Typography variant="body">Pie</Typography>
          </Stack>
        )}
        {chartType === "Bar" && (
          <Toggle
            name="percentageBar"
            label="Percentage"
            checked={percentageBar}
            onChange={handleToggle}
          />
        )}

        <Toggle
          name="clickableValues"
          label="Clickable Values"
          checked={clickableValues}
          onChange={handleToggle}
        />
        {chartType === "Bar" && (
          <Toggle
            name="totalToggleState"
            label="Show Total Bar"
            checked={totalToggleState}
            onChange={handleToggle}
            disabled={percentageBar}
          />
        )}

        <Toggle
          name="countAmountToggleState"
          label="Show Count/Amount Toggle"
          checked={countAmountToggleState}
          onChange={handleToggle}
          disabled={percentageBar}
        />

        <Toggle
          name="multiValueToggleState"
          label="Show MultiValue Toggle"
          checked={multiValueToggleState}
          onChange={handleToggle}
          disabled={percentageBar}
        />
        {multiValueToggleState && (
          <Stack direction="row" spacing={2}>
            <TextField
              name="leftValue"
              label="Value 1"
              size="small"
              variant="outlined"
              placeholder="Value 1"
              value={leftValue}
              onChange={handleChange}
              onBlur={handleBlur}
              error={!!touched.leftValue && !!errors.leftValue}
              helperText={touched.leftValue && errors.leftValue}
            />
            <TextField
              name="rightValue"
              label="Value 2"
              size="small"
              variant="outlined"
              placeholder="Value 2"
              onChange={handleChange}
              onBlur={handleBlur}
              value={rightValue}
              error={!!touched.rightValue && !!errors.rightValue}
              helperText={touched.rightValue && errors.rightValue}
            />
          </Stack>
        )}
      </Stack>
      <Box>{chartTypesMap[chartType]}</Box>
    </Box>
  );
};

export default ChartOptions;
