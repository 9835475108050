import { useTranslation } from "react-i18next";

export const useLabels = () => {
  const [t] = useTranslation("global");

  const labels = {
    nationalID: t(
      "info_modules.employee_records.cards.national_id.fields.national_id"
    ),
    date_of_birth: t(
      "info_modules.employee_records.cards.national_id.fields.date_of_birth"
    ),
    religion: t(
      "info_modules.employee_records.cards.national_id.fields.religion"
    ),
    marital_status: t(
      "info_modules.employee_records.cards.national_id.fields.marital_status"
    ),
    home_address: t(
      "info_modules.employee_records.cards.national_id.fields.home_address"
    ),
    gender: t("info_modules.employee_records.cards.national_id.fields.gender"),
    job_title: t(
      "info_modules.employee_records.cards.national_id.fields.job_title"
    ),
    effective_date: t(
      "info_modules.employee_records.cards.national_id.fields.effective_date"
    ),
  };

  return labels;
};
