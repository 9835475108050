import dayjs from "dayjs";
import Axios from "../network/Axios";
import CustomDataGrid from "../components/DataGrid/CustomDataGrid";
import { useTranslation } from "react-i18next";
import { statusColors } from "./statuses";
import Spinner from "../components/Spinners/Spinner";
import ChipStatus from "../components/ChipStatus";
import { useQuery } from "@tanstack/react-query";
import useOpenNewForm from "../hooks/useOpenNewForm";
import { useSelector } from "react-redux";
import { useConfirmationDialog } from "../contexts/ConfirmationDialogContext";
import { Link } from "react-router-dom";

const MaterialRequests = () => {
  const { newForm } = useSelector((state) => state.theme);

  const [t] = useTranslation("global");

  const statusNames = [
    {
      name: "Pending",
      displayName: t("data_grid.tabs.pending"),
    },
    { name: "Sent", displayName: t("data_grid.tabs.sent") },
    {
      name: "Partially Issued",
      displayName: t("data_grid.tabs.partially_issued"),
    },
    { name: "Issued", displayName: t("data_grid.tabs.issued") },
    { name: "Cancelled", displayName: t("data_grid.tabs.cancelled") },
    { name: "Returned", displayName: t("data_grid.tabs.returned") },
  ];

  const columns = [
    {
      field: "MaterialsRequestNo",
      headerName: t("data_grid.headers.ref_no"),
      width: 130,
      hideable: false,
      renderCell: (params) => {
        return (
          <Link
            style={{
              color: "#1790FF",
            }}
            to={`/materialrequests/${params.value}`}
          >
            {params.value}
          </Link>
        );
      },
    },

    {
      field: "WorkOrderNo",
      headerName: t("data_grid.headers.order_no"),
      width: 120,
      renderCell: (params) => (
        <Link
          style={{
            color: "#1790FF",
          }}
          to={`/workorders/${params.value}`}
          target="_blank"
        >
          {params.value}
        </Link>
      ),
    },
    {
      field: "SiteName",
      headerName: t("data_grid.headers.site_name"),
      flex: 1,
    },
    {
      field: "SiteCode",
      headerName: t("data_grid.headers.site_code"),
      width: 120,
    },
    {
      field: "ERPUserNickName",
      headerName: t("data_grid.headers.created_by"),
      width: 130,
    },
    {
      field: "MaterialsRequestOrigin",
      headerName: t("data_grid.headers.mr_origin"),
      width: 100,
    },
    {
      field: "ActionDate",
      headerName: t("data_grid.headers.creation_date"),
      type: "date",
      valueGetter: ({ value }) => (value ? new Date(value) : null),
      width: 100,
      renderCell: ({ value }) => {
        return value ? dayjs(value).format("YYYY/MM/DD") : "";
      },
    },
    {
      field: "SentOn",
      headerName: t("data_grid.headers.sent_on"),
      type: "date",
      valueGetter: ({ value }) => (value ? new Date(value) : null),
      width: 100,
      renderCell: ({ value }) => {
        return value ? dayjs(value).format("YYYY/MM/DD") : "";
      },
    },
    {
      field: "IssuedOn",
      headerName: t("data_grid.headers.issued_on"),
      type: "date",
      valueGetter: ({ value }) => (value ? new Date(value) : null),
      width: 100,
      renderCell: ({ value }) => {
        return value ? dayjs(value).format("YYYY/MM/DD") : "";
      },
    },

    {
      field: "WorkOrderStatus",
      headerName: t("data_grid.headers.wo_status"),
      width: 160,
      renderCell: ({ value }) => {
        return <ChipStatus value={value} />;
      },
    },
    {
      field: "MaterialsRequestStatus",
      headerName: t("data_grid.headers.mr_status"),
      width: 130,
      renderCell: ({ value }) => {
        return <ChipStatus value={value} />;
      },
    },
  ];

  const {
    data: rows,
    isLoading,
    refetch,
    isRefetching,
  } = useQuery({
    queryKey: ["materialRequests"],
    queryFn: async () => {
      const response = await Axios.get("/MaterialRequests");
      return response.data.result;
    },
  });

  // Handling new form logic
  const { handleOpen } = useOpenNewForm();
  const { setOpen } = useConfirmationDialog();

  if (isLoading) {
    return <Spinner />;
  }

  return (
    <CustomDataGrid
      rows={rows}
      columns={columns}
      loading={isLoading}
      getRowId={(row) => row.idMaterialsRequest}
      statusColors={statusColors}
      statusNames={statusNames}
      filterField="MaterialsRequestStatus"
      showReloadOverlay={isRefetching}
      reloadFunction={refetch}
      newButton
      initialState={{
        columns: {
          columnVisibilityModel: {
            MaterialsRequestOrigin: false,
          },
        },
      }}
      newFunction={() => {
        if (newForm === "material_requests" || newForm === null) {
          handleOpen("material_requests");
        } else {
          setOpen(t("modules.new_form_confirmation_message"), () => () => {
            handleOpen("material_requests");
          });
        }
      }}
    />
  );
};

export default MaterialRequests;
