import { LoadingButton } from "@mui/lab";
import { Box, Button } from "@mui/material";
import { useTranslation } from "react-i18next";

const PopUpsButtons = ({
  isPending,
  onClickClose,
  onClickSave,
  saveLabel,
  disabled,
}) => {
  const [t] = useTranslation("global");

  return (
    <Box display="flex" gap="10px" justifyContent="flex-end" marginTop="15px">
      <LoadingButton
        type={"submit"}
        onClick={onClickSave}
        loading={isPending}
        disabled={isPending || disabled}
        sx={{
          backgroundColor: "primary.main",
          "&:hover": {
            backgroundColor: "primary.dark",
          },
          color: "white",
          fontWeight: 500,
          fontSize: "13px",
          width: "4rem",
          height: "2rem",
        }}
        variant="contained"
      >
        {saveLabel}
      </LoadingButton>
      <Button
        onClick={onClickClose}
        sx={{
          backgroundColor: "error.main",
          "&:hover": {
            backgroundColor: "rgb(255 77 109 / 67%)",
          },
          color: "white",
          fontWeight: 500,
          fontSize: "13px",
          width: "4rem",
          height: "2rem",
        }}
      >
        {t("modules.buttons.cancel")}
      </Button>
    </Box>
  );
};

export default PopUpsButtons;
