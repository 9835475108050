import { GridActionsCellItem } from "@mui/x-data-grid-pro";
import { useTranslation } from "react-i18next";
import DeleteIcon from "@mui/icons-material/Delete";
import FormsDataGrid from "../../../DataGrid/FormsDataGrid";

const ModifyTemplateItems = ({ rows, dispatch }) => {
  const [t] = useTranslation("global");

  const columns = [
    {
      field: "StoreItemsTemplateCode",
      headerName: t("data_grid.headers.item_code"),
    },

    {
      field: "StoreItemsTemplateName",
      headerName: t("data_grid.headers.item_description"),
      flex: 1,
    },
    {
      field: "StoreItemsTemplateType",
      headerName: t("data_grid.headers.type"),
    },
    {
      field: "StoreItemsTemplateDescription",
      headerName: t("data_grid.headers.description"),
    },
    {
      field: "StoreItemsTemplateUnit",
      headerName: t("data_grid.headers.unit"),
    },
    {
      field: "StoreItemsTemplateQty",
      headerName: t("data_grid.headers.item_total_balance"),
      flex: 1,
    },
    {
      field: "QTY",
      headerName: t("data_grid.headers.quantity"),
      editable: true,
      type: "number",
    },
    {
      field: "Actions",
      headerName: "Actions",
      type: "actions",
      getActions: (params) => [
        <GridActionsCellItem
          key={params.id}
          icon={<DeleteIcon color="error" />}
          label="Delete"
          onClick={() => handleDeleteRow(params.id)}
        />,
      ],
    },
  ];

  const handleDeleteRow = (id) => {
    const newRows = rows.filter((row) => row.StoreItemsTemplateCode !== id);
    dispatch({ type: "rows", payload: newRows });
  };

  const handleQuantityChange = (params, event) => {
    const newValue = event.target.value;
    const newRows = rows.map((row) => {
      if (row.StoreItemsTemplateCode === params.id) {
        return { ...row, QTY: newValue };
      }
      return row;
    });

    dispatch({ type: "rows", payload: newRows });
  };

  return (
    <FormsDataGrid
      rows={rows}
      columns={columns}
      getRowId={(row) => row.StoreItemsTemplateCode}
      disableColumnMenu
      onCellEditStop={handleQuantityChange}
      height={300}
    />
  );
};

export default ModifyTemplateItems;
