import Axios from "../network/Axios";

const addNewVacationsRequest = async (data) => {
  const res = await Axios.post("/VacationRequests", data);

  return res.data;
};

const GetVacationRequestsForm = async () => {
  const response = await Axios.get("/VacationRequests", {
    params: {
      State: "New",
    },
  });
  return response.data.result;
};

const deleteVacationRecord = async (id) => {
  const res = await Axios.delete("/VacationRequests", {
    params: {
      SelectedID: id,
    },
  });

  return res.data;
};

const getEmployeeVacationListByYear = async (empCode, year) => {
  const response = await Axios.get("/VacationRequests", {
    params: {
      SelectedNo: empCode,
      Year: year,
    },
  });

  return response.data;
};

const getNewVacationRequestData = async (empCode) => {
  const response = await Axios.get("/VacationRequests", {
    params: {
      SelectedNo: empCode,
      State: "New",
    },
  });
  return response.data.result;
};

const getEditVacationRequestData = async (vacationId) => {
  const response = await Axios.get("/VacationRequests", {
    params: {
      SelectedNo: vacationId,
      State: "Edit",
    },
  });

  return response.data;
};

const editVacationRequest = async (data) => {
  const res = await Axios.put("/VacationRequests", data);

  return res.data;
};

export const vacationsRequestsService = {
  GetVacationRequestsForm,
  addNewVacationsRequest,
  deleteVacationRecord,
  getEmployeeVacationListByYear,
  getNewVacationRequestData,
  getEditVacationRequestData,
  editVacationRequest,
};
