import { useQuery } from "@tanstack/react-query";
import Axios from "../network/Axios";
import CustomDataGrid from "../components/DataGrid/CustomDataGrid";
import { useTranslation } from "react-i18next";
import { statusColors } from "./statuses";
import Spinner from "../components/Spinners/Spinner";
import ChipStatus from "../components/ChipStatus";
import useOpenNewForm from "../hooks/useOpenNewForm";
import { useSelector } from "react-redux";
import { useConfirmationDialog } from "../contexts/ConfirmationDialogContext";
import { Link } from "react-router-dom";
import dayjs from "dayjs";

const Quotations = () => {
  const { newForm } = useSelector((state) => state.theme);

  const [t] = useTranslation("global");

  const D6StatusNames = [
    { name: "Hold", displayName: t("data_grid.tabs.hold") },
    { name: "In Progress", displayName: t("data_grid.tabs.in_progress") },
    {
      name: "Waiting Approval",
      displayName: t("data_grid.tabs.waiting_approval"),
    },
    { name: "Approval Check", displayName: t("data_grid.tabs.approval_check") },
    {
      name: "Approved",
      displayName: t("data_grid.tabs.approved"),
    },
    { name: "Cancelled", displayName: t("data_grid.tabs.cancelled") },
  ];

  const columns = [
    {
      field: "WorkOrderD6No",
      headerName: t("data_grid.headers.ref_no"),
      width: 120,
      hideable: false,
      renderCell: (params) => {
        return (
          <Link
            style={{
              color: "#1790FF",
            }}
            to={`/quotations/${params.value}`}
          >
            {params.value}
          </Link>
        );
      },
    },
    {
      field: "WorkOrderNo",
      headerName: t("data_grid.headers.order_no"),
      width: 120,
      renderCell: (params) => (
        <Link
          style={{
            color: "#1790FF",
          }}
          to={`/workorders/${params.value}`}
          target="_blank"
        >
          {params.value}
        </Link>
      ),
    },
    {
      field: "SiteName",
      headerName: t("data_grid.headers.site_name"),
      width: 70,
      flex: 1,
    },

    {
      field: "CSiteCode",
      headerName: t("data_grid.headers.site_code"),
      width: 120,
    },

    {
      field: "SubProjectsName",
      headerName: t("data_grid.headers.sub_project"),
      width: 120,
    },
    {
      field: "WorkOrderD6TypeName",
      headerName: t("data_grid.headers.d6_type"),
      width: 120,
    },
    {
      field: "WorkOrderD6Amount",
      headerName: t("data_grid.headers.amount"),
      width: 120,
    },
    {
      field: "ERPUserNickName",
      headerName: t("data_grid.headers.created_by"),
      width: 120,
    },
    {
      field: "WorkOrderStatus",
      headerName: t("data_grid.headers.wo_status"),
      width: 190,
      renderCell: ({ value }) => {
        return <ChipStatus value={value} />;
      },
    },
    {
      field: "WorkOrderD6Status",
      headerName: t("data_grid.headers.acceptance_status"),
      width: 150,
      renderCell: ({ value }) => {
        return <ChipStatus value={value} />;
      },
    },
    {
      field: "GovernoratesName",
      headerName: t("data_grid.headers.governorate_name"),
      width: 120,
    },
    {
      field: "RequestorsName",
      headerName: t("data_grid.headers.requested_by"),
      width: 120,
    },
    {
      field: "TeamLeadersName",
      headerName: t("data_grid.headers.team_leader"),
      width: 120,
    },
    {
      field: "ActionDate",
      headerName: t("data_grid.headers.creation_date"),
      width: 120,
      type: "date",
      valueGetter: ({ value }) => (value ? new Date(value) : null),
      renderCell: ({ value }) => {
        return value ? dayjs(value).format("YYYY/MM/DD") : "";
      },
    },
    {
      field: "LastUpdateDate",
      headerName: t("data_grid.headers.last_update"),
      minwidth: 110,
      type: "date",
      valueGetter: ({ value }) => (value ? new Date(value) : null),
      renderCell: ({ value }) => {
        return value ? dayjs(value).format("YYYY/MM/DD") : "";
      },
    },
    {
      field: "WorkOrderDate",
      headerName: t("data_grid.headers.wo_date"),
      width: 115,
      type: "date",
      valueGetter: ({ value }) => (value ? new Date(value) : null),
      renderCell: ({ value }) => {
        return value ? dayjs(value).format("YYYY/MM/DD") : "";
      },
    },
    {
      field: "AcceptanceDate",
      headerName: t("data_grid.headers.sign_date"),
      width: 70,
      type: "date",
      valueGetter: ({ value }) => (value ? new Date(value) : null),
      renderCell: ({ value }) => {
        return value ? dayjs(value).format("YYYY/MM/DD") : "";
      },
    },
    {
      field: "Supply",
      headerName: t("data_grid.headers.invoice_s"),
      width: 70,
    },
    {
      field: "SPO",
      headerName: t("data_grid.headers.pos"),
      width: 70,
    },
    {
      field: "Install",
      headerName: t("data_grid.headers.invoice_i"),
      width: 70,
    },
    {
      field: "IPO",
      headerName: t("data_grid.headers.poi"),
      width: 70,
    },
  ];

  const {
    data: rows,
    isLoading,
    refetch,
    isRefetching,
  } = useQuery({
    queryKey: ["quotations"],
    queryFn: async () => {
      const response = await Axios.get("/Quotations");
      return response.data.result;
    },
  });

  // Handling new form logic
  const { handleOpen } = useOpenNewForm();
  const { setOpen } = useConfirmationDialog();

  if (isLoading) {
    return <Spinner />;
  }

  return (
    <CustomDataGrid
      rows={rows}
      columns={columns}
      loading={isLoading}
      getRowId={(row) => row.idWorkOrderD6}
      statusColors={statusColors}
      statusNames={D6StatusNames}
      filterField="WorkOrderD6Status"
      reloadFunction={refetch}
      showReloadOverlay={isRefetching}
      initialState={{
        columns: {
          columnVisibilityModel: {
            GovernoratesName: false,
            RequestorsName: false,
            ActionDate: false,
            LastUpdateDate: false,
            WorkOrderDate: false,
            AcceptanceDate: false,
            Supply: false,
            SPO: false,
            Install: false,
            IPO: false,
            WorkOrderNo: false,
            SubProjectsName: false,
            TeamLeadersName: false,
          },
        },
      }}
      newButton
      newFunction={() => {
        if (newForm === "quotations" || newForm === null) {
          handleOpen("quotations");
        } else {
          setOpen(t("modules.new_form_confirmation_message"), () => () => {
            handleOpen("quotations", true);
          });
        }
      }}
    />
  );
};

export default Quotations;
