import { Typography, Box, Button } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

const NotFound404 = () => {
  const { t } = useTranslation("global");
  const theme = useSelector((state) => state.theme.colorTheme);
  const navigate = useNavigate();
  const handleClick = () => {
    navigate("/");
  };
  return (
    <>
      <img
        src={
          theme === "dark"
            ? "/logoNoBackgroundDark.png"
            : "/logoNoBackgroundLight.png"
        }
        alt="logo"
        style={{ width: "8%", padding: "1% 1.5%" }}
      />
      <Box display="flex" flexDirection="column" alignItems="center">
        <Typography sx={{ fontWeight: 500, fontSize: "28px" }}>
          {t("info_modules.404.title")}
        </Typography>
        <Typography
          sx={{
            fontSize: "18px",
            fontWeight: 300,
            width: "450px",
            textAlign: "center",
            marginTop: "20px",
          }}
        >
          {t("info_modules.404.message")}
        </Typography>
        <img
          src="/404.png"
          alt="not found"
          style={{
            width: "30%",
            height: "85%",
            marginTop: "-2%",
          }}
        />
      </Box>
      <Box display="flex" justifyContent="center" marginTop="3%">
        <Button
          onClick={handleClick}
          sx={{
            background: theme === "dark" ? "#e6e6e6" : "#212b36",
            color: theme == "dark" ? "#212b36" : "#e6e6e6",
            ":hover": {
              backgroundColor: theme === "dark" ? "#b3b3b3" : "#4d555e",
            },
            fontSize: "16px",
            fontWeight: 600,
            padding: "8px 14px",
            textTransform: "none",
          }}
        >
          Go to Home
        </Button>
      </Box>
    </>
  );
};

export default NotFound404;
