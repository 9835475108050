import { Box, Stack, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";

const NoDataView = () => {
  const { t } = useTranslation("global");
  return (
    <Box
      display="flex"
      justifyContent="center"
      alignItems="center"
      height="80vh"
    >
      <Stack spacing={3} justifyContent="center" alignItems="center">
        <Box
          sx={{
            width: "300px",
            userSelect: "none",
            pointerEvents: "none",
          }}
        >
          <img
            src="/no-data.png"
            alt="no data"
            style={{
              width: "100%",
              height: "100%",
              objectFit: "contain",
              filter: "grayscale(100%)",
            }}
          />
        </Box>
        <Typography variant="h4">{t("dashboard.no_data")}</Typography>
      </Stack>
    </Box>
  );
};

export default NoDataView;
