import { Grid, Box, TextField } from "@mui/material";
import { useTranslation } from "react-i18next";
import { convertImage } from "../../../../../../utils/utils";
import ChipComponent from "../../../../../../components/Chips/ChipComponent";

const BasicInfo = ({
  newUsers,
  handleSubmit,
  values,
  errors,
  touched,
  handleBlur,
  handleChange,
}) => {
  const [t] = useTranslation("global");

  const convertedImage =
    newUsers.EmpPhoto === null
      ? "/person.png"
      : convertImage(newUsers.EmpPhoto.data);

  return (
    <Box padding="10px 8px">
      <Grid container spacing={1} marginBottom="15px">
        <Grid item xs={6}>
          <ChipComponent
            label={t("info_modules.users_management.description.english_name")}
            value={newUsers.EmpNameEn}
          />
        </Grid>
        <Grid item xs={6}>
          <ChipComponent
            label={t("info_modules.users_management.description.arabic_name")}
            value={newUsers.EmpName}
          />
        </Grid>
      </Grid>

      <form onSubmit={handleSubmit}>
        <Box display="flex" gap={10}>
          <Box display="flex" flexDirection="column" gap={2.5} width="60%">
            <TextField
              label={t("data_grid.headers.user_name")}
              sx={{ width: "100%" }}
              size="small"
              value={values.userName}
              onBlur={handleBlur}
              onChange={handleChange}
              name="userName"
              error={touched.userName && Boolean(errors.userName)}
              helperText={touched.userName && errors.userName}
            />
            <TextField
              label={t("data_grid.headers.nick_name")}
              sx={{ width: "100%" }}
              size="small"
              value={values.nickName}
              onBlur={handleBlur}
              onChange={handleChange}
              name="nickName"
              error={touched.nickName && Boolean(errors.nickName)}
              helperText={touched.nickName && errors.nickName}
            />
            <TextField
              label={t("data_grid.headers.user_mail")}
              sx={{ width: "100%" }}
              size="small"
              value={values.userMail}
              onBlur={handleBlur}
              onChange={handleChange}
              name="userMail"
              error={touched.userMail && Boolean(errors.userMail)}
              helperText={touched.userMail && errors.userMail}
            />
          </Box>
          <Box width="20%">
            <img
              src={convertedImage}
              alt="Employee"
              style={{
                width: "14rem",
                height: "15rem",
                borderRadius: "5px",
              }}
            />
          </Box>
        </Box>
      </form>
    </Box>
  );
};

export default BasicInfo;
