import { TextField } from "@mui/material";
import { Controller } from "react-hook-form";

const TextInput = ({ name, label, control, ...rest }) => {
  return (
    <Controller
      name={name}
      control={control}
      render={({ field }) => (
        <TextField {...field} label={label} size="small" {...rest} />
      )}
    />
  );
};

export default TextInput;
