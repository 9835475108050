// Sort user roles data to be used in the tree view component
export const sortUserRoles = (data) => {
  const sortedUserRoles = data.reduce((acc, item) => {
    const {
      Checked,
      ModulesCategoryName,
      SystemModuleName,
      SystemPrivilegeName,
      idModulePrivilege,
    } = item;

    // Ensure the ModulesCategoryName object exists
    if (!acc[ModulesCategoryName]) {
      acc[ModulesCategoryName] = {};
    }

    // Ensure the SystemModuleName object exists
    if (!acc[ModulesCategoryName][SystemModuleName]) {
      acc[ModulesCategoryName][SystemModuleName] = [];
    }

    // Add the privilege to the SystemModuleName array
    acc[ModulesCategoryName][SystemModuleName].push({
      SystemPrivilegeName,
      Checked,
      idModulePrivilege,
    });

    return acc;
  }, {});

  return sortedUserRoles;
};

// Get the final array to be sent to the API
export const getFinalArray = (selectedItems, data) => {
  // Format the selected items array
  const formattedSelectedItems = selectedItems.map((item) => {
    const [SystemPrivilegeName, idModulePrivilege] = item.split("-");
    return {
      SystemPrivilegeName,
      idModulePrivilege,
    };
  });

  // Map the data result array and check if the item is selected
  const outputArray = data.map((item) => {
    const isSelected = formattedSelectedItems.some(
      (selected) => selected.idModulePrivilege == item.idModulePrivilege
    );
    return {
      SystemPrivilegeName: item.SystemPrivilegeName,
      checked: isSelected,
      idModulePrivilege: item.idModulePrivilege,
    };
  });

  return outputArray;
};
