import { useSelector } from "react-redux";

const ChartIcon = () => {
  const theme = useSelector((state) => state.theme.colorTheme); // light | dark

  const fill = theme === "dark" ? "#4f4f4f" : "#e3e3e3";
  return (
    <svg viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g id="SVGRepo_bgCarrier" strokeWidth="0"></g>
      <g
        id="SVGRepo_tracerCarrier"
        strokeLinecap="round"
        strokeLinejoin="round"
      ></g>
      <g id="SVGRepo_iconCarrier">
        <path d="M16 1H12V15H16V1Z" fill={fill}></path>
        <path d="M6 5H10V15H6V5Z" fill={fill}></path>
        <path d="M0 9H4V15H0V9Z" fill={fill}></path>
      </g>
    </svg>
  );
};

export default ChartIcon;
