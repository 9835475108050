import Axios from "../network/Axios";

const getClientInvoices = async () => {
  const response = await Axios.get("/ClientInvoices");
  return response.data.result;
};

// Handle invoices bulk export
const exportInvoices = async (ids) => {
  const response = await Axios.get("/ClientInvoices", {
    responseType: "blob",
    params: {
      State: "PDFMulti",
      Invoices: ids,
    },
  });

  return response.data;
};

const patchClientInvoices = async (data) => {
  const response = await Axios.patch("/ClientInvoices", data);
  return response.data;
};

export const clientInvoicesService = {
  getClientInvoices,
  exportInvoices,
  patchClientInvoices,
};
