import { Box, Button, Stack, TextField } from "@mui/material";
import { Toggle } from "../common";

const ChartClickableData = ({
  chart,
  values,
  setFieldValue,
  errors,
  touched,
  handleBlur,
  handleChange,
}) => {
  const {
    totalToggleState,
    totalQuery,
    elementQuery,
    clickableValuesUserDefined,
  } = values;

  const handleUserDefined = (event) =>
    setFieldValue("clickableValuesUserDefined", event.target.checked);

  return (
    <Box
      sx={{
        display: "grid",
        gridTemplateColumns: "1fr 1fr",
        gap: 2,
        padding: "1rem",
        flexGrow: 1,
      }}
    >
      <Stack spacing={2}>
        {totalToggleState && (
          <TextField
            multiline
            rows={6}
            name="totalQuery"
            label="Total Query"
            size="small"
            variant="outlined"
            onChange={handleChange}
            onBlur={handleBlur}
            value={totalQuery}
            error={!!touched.totalQuery && !!errors.totalQuery}
            helperText={touched.totalQuery && errors.totalQuery}
          />
        )}

        <TextField
          multiline
          rows={6}
          name="elementQuery"
          label="Element Query"
          size="small"
          variant="outlined"
          onChange={handleChange}
          onBlur={handleBlur}
          value={elementQuery}
          error={!!touched.elementQuery && !!errors.elementQuery}
          helperText={touched.elementQuery && errors.elementQuery}
        />

        <Stack
          direction="row"
          spacing={1}
          alignItems="center"
          justifyContent="space-between"
        >
          <Toggle
            label="User Defined"
            checked={clickableValuesUserDefined}
            onChange={handleUserDefined}
          />
          <Button
            variant="contained"
            color="success"
            //   onClick={handleCloseDemoModal}
            sx={{
              textTransform: "capitalize",
              color: "white",
            }}
          >
            Test
          </Button>
        </Stack>
      </Stack>
      <Box>{chart}</Box>
    </Box>
  );
};

export default ChartClickableData;
