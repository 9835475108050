import { useQuery } from "@tanstack/react-query";
import Axios from "../network/Axios";
import CustomDataGrid from "../components/DataGrid/CustomDataGrid";
import { useTranslation } from "react-i18next";
import { statusColors } from "./statuses";
import Spinner from "../components/Spinners/Spinner";
import ChipStatus from "../components/ChipStatus";
import dayjs from "dayjs";

const DeliveryNotes = () => {
  const [t] = useTranslation("global");

  const D6StatusNames = [
    { name: "In Progress", displayName: t("data_grid.tabs.in_progress") },
    {
      name: "Approved",
      displayName: t("data_grid.tabs.approved"),
    },
    { name: "Hold", displayName: t("data_grid.tabs.hold") },
    { name: "Cancelled", displayName: t("data_grid.tabs.cancelled") },
    { name: "Approval Check", displayName: t("data_grid.tabs.approval_check") },
    {
      name: "Waiting Approval",
      displayName: t("data_grid.tabs.waiting_approval"),
    },
  ];

  const columns = [
    {
      field: "DeliveryNotesNo",
      headerName: t("data_grid.headers.ref_no"),
      width: 130,
      hideable: false,
    },

    {
      field: "SiteName",
      headerName: t("data_grid.headers.site_name"),
      width: 70,
      flex: 1,
    },
    {
      field: "SiteCode",
      headerName: t("data_grid.headers.site_code"),
      width: 140,
      flex: 1,
    },
    {
      field: "WorkOrderNo",
      headerName: t("data_grid.headers.order_no"),
      width: 130,
    },
    {
      field: "ERPUserNickName",
      headerName: t("data_grid.headers.created_by"),
      width: 140,
      flex: 1,
    },
    {
      field: "ActionDate",
      headerName: t("data_grid.headers.creation_date"),
      type: "date",
      valueGetter: ({ value }) => (value ? new Date(value) : null),
      renderCell: (params) =>
        params.value === null ? "" : dayjs(params.value).format("YYYY/MM/DD"),
    },
    {
      field: "LastUpdateDate",
      headerName: t("data_grid.headers.last_update"),
      type: "date",
      valueGetter: ({ value }) => (value ? new Date(value) : null),
      renderCell: (params) =>
        params.value === null ? "" : dayjs(params.value).format("YYYY/MM/DD"),
    },
    {
      field: "DeliveryNotesStatus",
      headerName: t("data_grid.headers.status"),
      headerAlign: "center",
      width: 140,
      renderCell: ({ value }) => {
        return <ChipStatus value={value} />;
      },
    },
  ];
  const {
    data: rows,
    isLoading,
    refetch,
    isRefetching,
  } = useQuery({
    queryKey: ["deliveryNotes"],
    queryFn: async () => {
      const response = await Axios.get("/DeliveryNotes");
      return response.data.result;
    },
  });
  if (isLoading) {
    return <Spinner />;
  }

  return (
    <CustomDataGrid
      rows={rows}
      columns={columns}
      loading={isLoading}
      getRowId={(row) => row.idDeliveryNotes}
      statusColors={statusColors}
      statusNames={D6StatusNames}
      filterField="DeliveryNotesStatus"
      showReloadOverlay={isRefetching}
      reloadFunction={refetch}
      newButton
    />
  );
};

export default DeliveryNotes;
