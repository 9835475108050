import { useState } from "react";
import { Box, Chip } from "@mui/material";
import { useTranslation } from "react-i18next";
import ButtonGroup from "../../../../components/forms/ButtonGroup/ButtonGroup";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";
import dayjs from "dayjs";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useParams } from "react-router-dom";
import Axios from "../../../../network/Axios";
import { useSnackbar } from "notistack";
import InfoModal from "../../../../components/Modals/InfoModal";

const CloseMission = ({ open, handleClose, missionStart }) => {
  const [t] = useTranslation("global");
  const [dateTime, setDateTime] = useState(
    dayjs(missionStart).hour(16).minute(0)
  );
  const queryClient = useQueryClient();
  const { missionId } = useParams();
  const { enqueueSnackbar } = useSnackbar();

  const handleCloseSave = () => {
    setDateTime(dayjs(missionStart).hour(16).minute(0));
    handleClose();
  };

  const { mutateAsync: closeMission, isPending } = useMutation({
    mutationFn: async () => {
      return Axios.patch("/Missions", {
        SelectedNo: missionId,
        Status: "Done",
        EndDate: dateTime.format("YYYY-MM-DD HH:mm:ss"),
      });
    },
    onSuccess: (data) => {
      if (!data) return;
      enqueueSnackbar(t("info_modules.missions.description.mission_closed"), {
        variant: "success",
      });
      queryClient.invalidateQueries({
        queryKey: ["missionsInfo", missionId],
      });
      handleCloseSave();
    },
  });

  return (
    <InfoModal
      open={open}
      handleClose={handleCloseSave}
      title={t("info_modules.missions.description.mission_end")}
      content={
        <Box padding={1.2} display="flex" flexDirection="column" gap={2}>
          <Chip
            label={missionId}
            sx={{ borderRadius: 1, fontSize: "0.9rem" }}
          />
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DateTimePicker
              label={t("info_modules.missions.description.mission_start")}
              value={dayjs(dateTime.format("YYYY-MM-DD HH:mm:ss"))}
              format="YYYY/MM/DD hh:mm A"
              onChange={(newValue) => {
                setDateTime(newValue);
              }}
              sx={{
                width: "28.9rem",
              }}
              slotProps={{
                textField: {
                  size: "small",
                },
                popper: {
                  placement: "right",
                },
              }}
            />
          </LocalizationProvider>
          <ButtonGroup
            onClickClose={handleCloseSave}
            isPending={isPending}
            onClickSave={closeMission}
            saveLabel={t("modules.buttons.save")}
            disabled={!dayjs(dateTime).isValid()}
          />
        </Box>
      }
    />
  );
};

export default CloseMission;
