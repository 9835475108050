import { Box } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useQuery } from "@tanstack/react-query";
import Axios from "../../../../../network/Axios";
import InfoModal from "../../../../../components/Modals/InfoModal";
import FormsDataGrid from "../../../../../components/DataGrid/FormsDataGrid";
import ButtonGroup from "../../../../../components/forms/ButtonGroup/ButtonGroup";
import dayjs from "dayjs";

const SelectCar = ({
  open,
  handleClose,
  selectedCar,
  setSelectedCar,
  selectedItemsCar,
  setSelectedItemsCar,
  setFieldValue,
}) => {
  const [t] = useTranslation("global");

  const { data, isLoading } = useQuery({
    queryKey: ["companyFleet", "wareHouse"],
    queryFn: async () => {
      const response = await Axios.get("/CompanyFleet", {
        params: {
          MissionDateTime: dayjs().format("YYYY-MM-DD hh:mm:00"),
        },
      });
      return response.data.result;
    },
    enabled: open,
  });

  const handleRowSelectionModelChange = (selectedItems) => {
    if (selectedItems.length > 1) {
      const lastSelected = selectedItems[selectedItems.length - 1];
      selectedItems = [lastSelected];
    }
    const selectedData = data.filter((row) =>
      selectedItems.includes(row.idCarsInfoCarsNumbers)
    );
    setSelectedItemsCar(selectedItems);
    setSelectedCar(selectedData);
  };

  const handleSelectCar = () => {
    if (selectedCar.length > 0) {
      setFieldValue(
        "carName",
        `${selectedCar[0].CarsNumber} - ${selectedCar[0].EmpName}`
      );
      handleClose();
      setSelectedItemsCar([]);
    }
  };
  const columns = [
    {
      field: "CarsNumber",
      headerName: t("data_grid.headers.cars"),
      width: 190,
    },
    {
      field: "CarsType",
      headerName: t("data_grid.headers.type"),
      width: 120,
    },
    {
      field: "EmpName",
      headerName: t("data_grid.headers.emp_name"),
      width: 190,
    },
    {
      field: "EmpTitle",
      headerName: t("data_grid.headers.title"),
      width: 160,
    },
  ];
  return (
    <InfoModal
      open={open}
      handleClose={handleClose}
      title={t("info_modules.material_request.description.select_emp")}
      content={
        <Box padding={1.2}>
          <FormsDataGrid
            rows={data || []}
            columns={columns}
            loading={isLoading}
            getRowId={(row) => row.idCarsInfoCarsNumbers}
            height={380}
            showQuickFilter
            toolbar
            checkboxSelection
            disableSelectionOnClick
            onRowSelectionModelChange={handleRowSelectionModelChange}
            rowSelectionModel={selectedItemsCar}
          />
          <ButtonGroup
            onClickClose={() => {
              handleClose();
              setSelectedItemsCar([]);
            }}
            onClickSave={() => handleSelectCar()}
            saveLabel={t("modules.buttons.select")}
          />
        </Box>
      }
    />
  );
};

export default SelectCar;
