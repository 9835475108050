import { Box, Stack, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import ChartIcon from "../Icons/Chart";

const NoData = () => {
  const { t } = useTranslation("global");

  return (
    <Box
      sx={{
        height: "15rem",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        flexGrow: 1,
      }}
    >
      <Stack alignItems={"center"} justifyContent={"center"} spacing={2}>
        <ChartIcon />
        <Typography
          sx={{
            fontSize: "14px",
            fontWeight: "600",
            color: "text.primary",
          }}
        >
          {t("main_cards.labels.no_data")}
        </Typography>
      </Stack>
    </Box>
  );
};

export default NoData;
