import { useQuery } from "@tanstack/react-query";
import { useParams } from "react-router-dom";
import { employeeRecordsService } from "../../../../../../api/employeeRecords";
import { useState } from "react";
import FormSpinner from "../../../../../../components/Spinners/FormSpinner";
import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import timeGridPlugin from "@fullcalendar/timegrid";
import listPlugin from "@fullcalendar/list";
import { Box } from "@mui/material";
import { orange, blue, green } from "@mui/material/colors";
import InfoModal from "../../../../../../components/Modals/InfoModal";
import { useSelector } from "react-redux";
import arLocale from "@fullcalendar/core/locales/ar";
import MissionSnapshot from "./MissionSnapshot";
import { useTranslation } from "react-i18next";

const TimeLine = () => {
  const { language } = useSelector((state) => state.theme); // AR | EN
  const { t } = useTranslation("global");

  const { empCode } = useParams();

  const { data, isLoading } = useQuery({
    queryKey: ["employeeTimeline", empCode],
    queryFn: () => employeeRecordsService.getEmployeeTimeLine(empCode),
  });

  // Handle Modal
  const [isModalOpen, setIsModalOpen] = useState(false);

  const handleCloseModal = () => setIsModalOpen(false);
  const handleOpenModal = () => setIsModalOpen(true);

  const [missionRefNo, setMissionRefNo] = useState("");

  if (isLoading) return <FormSpinner />;

  // Types = ["Mission", "Signature", "Vacation"]
  const vacationEvents = data
    .filter((item) => item.Type === "Vacation")
    .map(({ RefNo, Start, End }) => {
      return {
        title: RefNo,
        start: Start,
        end: End,
        color: orange[500],
        extendedProps: {
          RefNo,
        },
      };
    });

  const signatureEvents = data
    .filter((item) => item.Type === "Signature")
    .map(({ RefNo, Start, End, Type }) => {
      return {
        title: Type,
        start: Start,
        end: End,
        color: blue[500],
        extendedProps: {
          RefNo,
        },
      };
    });

  const missionEvents = data
    .filter((item) => item.Type === "Mission")
    .map(({ RefNo, SiteName, Area, Start, End, Status }) => {
      return {
        title: `${RefNo} - ${SiteName} - ${Area}`,
        start: Start,
        end: End,
        color: green[500],
        extendedProps: {
          Area,
          RefNo,
          SiteName,
          Status,
          isMission: true,
        },
      };
    });

  const events = [...vacationEvents, ...signatureEvents, ...missionEvents];

  return (
    <>
      <Box p={2}>
        <FullCalendar
          plugins={[timeGridPlugin, dayGridPlugin, listPlugin]}
          initialView="dayGridMonth"
          headerToolbar={{
            left: "prev,next today",
            center: "title",
            right: "dayGridMonth,timeGridWeek,timeGridDay,listMonth",
          }}
          eventClick={(info) => {
            info.jsEvent.preventDefault();
            if (info.event.extendedProps.isMission) {
              setMissionRefNo(info.event.extendedProps.RefNo);
              handleOpenModal();
            }
          }}
          height={500}
          events={events}
          navLinks
          direction={language === "AR" ? "rtl" : "ltr"}
          locale={language}
          locales={[arLocale]}
        />
      </Box>
      <InfoModal
        title={`${t(
          "info_modules.employee_records.cards.attendance.timeline.snapshots.mission_details"
        )} - ${missionRefNo}`}
        content={<MissionSnapshot missionRefNo={missionRefNo} />}
        handleClose={handleCloseModal}
        open={isModalOpen}
        width="60rem"
      />
    </>
  );
};

export default TimeLine;
