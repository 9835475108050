import { useState } from "react";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import DataGroupsModal from "./DataGroups";
import { statusColors } from "../statuses";
import { UserRolesModal } from "./UserRoles";
import { useTranslation } from "react-i18next";
import { useQuery } from "@tanstack/react-query";
import NewUserRole from "./UserRoles/NewUserRole";
import NewDataGroup from "./DataGroups/NewDataGroup";
import ChipStatus from "../../components/ChipStatus";
import Spinner from "../../components/Spinners/Spinner";
import SingleUserRole from "./UserRoles/SingleUserRole";
import useOpenNewForm from "../../hooks/useOpenNewForm";
import InfoModal from "../../components/Modals/InfoModal";
import { usersManagementService } from "../../api/usersManagement";
import CustomDataGrid from "../../components/DataGrid/CustomDataGrid";
import { useConfirmationDialog } from "../../contexts/ConfirmationDialogContext";
import PersonIcon from "@mui/icons-material/Person";
import ManageAccountsIcon from "@mui/icons-material/ManageAccounts";
import GroupsIcon from "@mui/icons-material/Groups";
import { DashboardChartsModal } from "./DashboardCharts/DashboardCharts";

const UsersManagement = () => {
  const [t] = useTranslation("global");
  const { newForm, language } = useSelector((state) => state.theme);
  const [userRoleModal, setUserRoleModal] = useState(false);
  const [roleName, setRoleName] = useState("");
  const [disableEdit, setDisableEdit] = useState(false);

  // Handle dashboard button
  const [dashboardModal, setDashboardModal] = useState(false);

  const handleDashboardModal = () => setDashboardModal(true);
  const handleCloseDashboardModal = () => setDashboardModal(false);

  // Consolidated state and handlers
  const [modals, setModals] = useState({
    openNewUserRole: false,
    openUserRolesModal: false,
    openDataGroupsModal: false,
    openNewDataGroup: false,
  });

  const handleModalToggle = (modalName, value) => {
    setModals((prevModals) => ({ ...prevModals, [modalName]: value }));
  };

  const statusNames = [
    {
      name: "Active",
      displayName: t("data_grid.tabs.active"),
    },
    { name: "Deactive", displayName: t("data_grid.tabs.deactive") },
  ];

  const columns = [
    {
      field: "ERPUserName",
      headerName: t("data_grid.headers.username"),
      width: 115,
      renderCell: (params) => {
        return (
          <Link
            style={{
              color: "#1790FF",
            }}
            to={`/usersmanagement/${params.value}`}
          >
            {params.value}
          </Link>
        );
      },
    },
    {
      field: "ERPUserNickName",
      headerName: t("data_grid.headers.nickname"),
      width: 140,
      flex: 1,
    },
    {
      field: "EmpCode",
      headerName: t("data_grid.headers.emp_code"),
      width: 110,
    },
    {
      field: language === "EN" ? "EmpNameEn" : "EmpName",
      headerName: t("data_grid.headers.emp_name"),
      width: 140,
      flex: 1,
    },
    {
      field: "EmpTitle",
      headerName: t("data_grid.headers.emp_title"),
      width: 140,
    },
    {
      field: "ERPUserRoleName",
      headerName: t("data_grid.headers.role_name"),
      width: 140,
      renderCell: (params) => {
        const role = params.value;
        return (
          <Link
            onClick={() => {
              setRoleName(role);
              setDisableEdit(true);
              setUserRoleModal(true);
            }}
            style={{
              color: "#1790FF",
              textAlign: "center",
              display: "block",
              width: "100%",
            }}
          >
            {role}
          </Link>
        );
      },
    },
    {
      field: "ERPUserMail",
      headerName: t("data_grid.headers.user_mail"),
      width: 140,
      flex: 1,
    },
    {
      field: "ERPUserStatus",
      headerName: t("data_grid.headers.status"),
      headerAlign: "center",
      width: 130,
      renderCell: ({ value }) => {
        return <ChipStatus value={value} />;
      },
    },
  ];

  const {
    data: rows,
    isLoading,
    refetch,
    isRefetching,
  } = useQuery({
    queryKey: ["usersManagement"],
    queryFn: usersManagementService.GetUsersManagementData,
  });

  // Handling new form logic
  const { handleOpen } = useOpenNewForm();
  const { setOpen } = useConfirmationDialog();

  const actions = ["new", "user roles", "data groups"];

  const actionsConfig = {
    "user roles": {
      label: t("data_grid.buttons.data_group"),
      icon: <GroupsIcon sx={{ width: "1.1rem", height: "1.1rem" }} />,
      onClick: () => handleModalToggle("openNewDataGroup", true),
    },
    new: {
      label: t("data_grid.buttons.user"),
      icon: <PersonIcon sx={{ width: "1.1rem", height: "1.1rem" }} />,
      onClick: () => {
        if (newForm === "users_management" || newForm === null) {
          handleOpen("users_management");
        } else {
          setOpen(t("modules.new_form_confirmation_message"), () => () => {
            handleOpen("users_management");
          });
        }
      },
    },
    "data groups": {
      label: t("data_grid.buttons.user_role"),
      icon: <ManageAccountsIcon sx={{ width: "1.1rem", height: "1.1rem" }} />,
      onClick: () => handleModalToggle("openNewUserRole", true),
    },
  };

  if (isLoading) {
    return <Spinner />;
  }

  return (
    <>
      <CustomDataGrid
        rows={rows}
        columns={columns}
        loading={isLoading}
        getRowId={(row) => row.ERPUserName}
        statusColors={statusColors}
        statusNames={statusNames}
        filterField="ERPUserStatus"
        reloadFunction={refetch}
        showReloadOverlay={isRefetching}
        newButtonList={{
          actions,
          actionsConfig,
        }}
        userRolesBtn
        userRolesFunction={() => handleModalToggle("openUserRolesModal", true)}
        dataGroupsBtn
        dataGroupsFunction={() =>
          handleModalToggle("openDataGroupsModal", true)
        }
        dashBoardBtn={{
          function: handleDashboardModal,
        }}
      />
      <UserRolesModal
        open={modals.openUserRolesModal}
        onClose={() => handleModalToggle("openUserRolesModal", false)}
      />
      <DataGroupsModal
        open={modals.openDataGroupsModal}
        onClose={() => handleModalToggle("openDataGroupsModal", false)}
      />
      <InfoModal
        title={roleName}
        open={userRoleModal}
        handleClose={() => setUserRoleModal(false)}
        content={
          <SingleUserRole disableEdit={disableEdit} userRole={roleName} />
        }
        width="65rem"
      />
      <InfoModal
        open={modals.openNewUserRole}
        handleClose={() => handleModalToggle("openNewUserRole", false)}
        title={t("modules.users_management.user_roles.new_user_role")}
        content={
          <NewUserRole
            onClose={() => handleModalToggle("openNewUserRole", false)}
          />
        }
        width="48rem"
      />
      <InfoModal
        open={modals.openNewDataGroup}
        handleClose={() => handleModalToggle("openNewDataGroup", false)}
        title="New Data Group"
        content={
          <NewDataGroup
            onClose={() => handleModalToggle("openNewDataGroup", false)}
          />
        }
        width="50rem"
      />
      <DashboardChartsModal
        open={dashboardModal}
        onClose={handleCloseDashboardModal}
      />
    </>
  );
};

export default UsersManagement;
