import { useState, useEffect } from "react";
import { Box, Tooltip } from "@mui/material";
import { useSnackbar } from "notistack";
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import Image from "@mui/icons-material/Image";
import Axios from "../../../../network/Axios";
import { GridActionsCellItem } from "@mui/x-data-grid-pro";
import useImagePopup from "../../../../hooks/useImagePopup";
import ImageModal from "../../../../components/Modals/ImageModal";
import { useQuery, useMutation, useQueryClient } from "@tanstack/react-query";
import InfoModal from "../../../../components/Modals/InfoModal";
import FormsDataGrid from "../../../../components/DataGrid/FormsDataGrid";
import ButtonGroup from "../../../../components/forms/ButtonGroup/ButtonGroup";
import ConfirmationInfoModal from "../../../../components/Modals/ConfirmationInfoModal";
import ChipComponent from "../../../../components/Chips/ChipComponent";
import { formatCurrency } from "../../../../utils/globalFunctions";

const AddItems = ({ open, handleClose, clientInvoicesInfo }) => {
  const { clientInvoiceId } = useParams();
  const [t] = useTranslation("global");
  const queryClient = useQueryClient();
  const { enqueueSnackbar } = useSnackbar();
  const [selectedRows, setSelectedRows] = useState([]);
  const [selectedData, setSelectedData] = useState([]);
  const [confirmModal, setConfirmModal] = useState(false);
  const [newAmount, setNewAmount] = useState(0);
  const [total, setTotal] = useState(0);

  const handleOpenConfirmModal = () => {
    setConfirmModal(true);
  };

  const { data, isLoading, refetch, isRefetching } = useQuery({
    queryKey: ["clientInvoices", "add"],
    queryFn: async () => {
      let state;
      if (clientInvoicesInfo?.Details[0]?.InvoiceType === "S") {
        state = "InvoiceSupply";
      } else if (clientInvoicesInfo?.Details[0]?.InvoiceType === "I") {
        state = "InvoiceInstall";
      } else {
        state = "InvoiceSupplyInstall";
      }
      const response = await Axios.get("/ClientInvoices", {
        params: {
          State: state,
        },
      });
      return response.data.result;
    },
    enabled: open,
  });
  let sumSTotalForTypeS = 0;
  if (clientInvoicesInfo?.Acceptance) {
    for (const acceptance of clientInvoicesInfo.Acceptance) {
      if (clientInvoicesInfo.Details[0].InvoiceType === "S") {
        sumSTotalForTypeS += Number(acceptance.STotal.replace(/,/g, ""));
      }
      if (clientInvoicesInfo.Details[0].InvoiceType === "I") {
        sumSTotalForTypeS += Number(acceptance.ITotal.replace(/,/g, ""));
      }
    }
  }
  useEffect(() => {
    let newAmountSum = 0.0;
    let totalSum = 0.0;
    selectedRows.forEach((rowId) => {
      const row = data.find((r) => r.idWorkOrderD6 === rowId);
      if (row) {
        const value = parseFloat(
          clientInvoicesInfo.Details[0].InvoiceType === "S"
            ? row.WorkOrderD6Supply.replace(/,/g, "")
            : row.WorkOrderD6Install.replace(/,/g, "")
        );
        newAmountSum += value;
        totalSum += value;
      }
    });
    const newAmountFormatted = formatCurrency(newAmountSum);
    const totalFormatted = formatCurrency(sumSTotalForTypeS + totalSum);
    setNewAmount(newAmountFormatted);
    setTotal(totalFormatted);
  }, [selectedRows, data]);

  const fetchData = async (id) => {
    try {
      const response = await Axios.get("/Quotations", {
        params: {
          SelectedNo: id,
          State: "Document",
        },
      });
      return response.data.result;
    } catch (error) {
      console.log(error);
    }
  };
  const { mutateAsync: addItem, isPending: add } = useMutation({
    mutationFn: async () => {
      return Axios.patch("/ClientInvoices", {
        SelectedNo: clientInvoiceId,
        State: "Update",
        WorkOrderD6: selectedData.map((item) => item.WorkOrderD6No),
      });
    },
    onSuccess: (data) => {
      if (!data) return;
      enqueueSnackbar(t("modules.error_messages.added_successfully"), {
        variant: "success",
      });
      handleCloseCheck();
      setConfirmModal(false);
      queryClient.invalidateQueries({
        queryKey: ["clientInvoice", "info"],
      });
    },
  });
  const {
    isImageLoading,
    isImageModalOpen,
    image,
    handleImageModalOpen,
    handleCloseImageModal,
  } = useImagePopup(fetchData, "AcceptanceAttachement");

  const handleRowSelectionModelChange = (newSelectedRows) => {
    const selectedData = data.filter((row) =>
      newSelectedRows.includes(row.idWorkOrderD6)
    );
    setSelectedData(selectedData);
    setSelectedRows(newSelectedRows);
  };

  const handleSaveClick = () => {
    handleOpenConfirmModal();
  };

  const handleCloseCheck = () => {
    handleClose();
    setSelectedRows([]);
  };

  const columns = [
    {
      field: "WorkOrderD6No",
      headerName: t("data_grid.headers.ref_no"),
      width: 140,
    },
    {
      field: "SiteName",
      headerName: t("data_grid.headers.site_name"),
      width: 180,
    },
    {
      field: "SiteCode",
      headerName: t("data_grid.headers.site_code"),
      width: 100,
    },
    {
      field: "RequestorsName",
      headerName: t("data_grid.headers.requestor"),
      width: 90,
    },
    {
      field: "TeamLeadersName",
      headerName: t("data_grid.headers.team_leader"),
      width: 90,
    },
    {
      field: "CompanyProjectsName",
      headerName: t("data_grid.headers.project"),
      width: 100,
    },
    {
      field: "WorkOrderD6Amount",
      headerName: t("data_grid.headers.amount"),
      width: 100,
    },
    {
      field: "WorkOrderD6Supply",
      headerName: t("data_grid.headers.supply"),
      width: 80,
    },
    {
      field: "WorkOrderD6Install",
      headerName: t("data_grid.headers.install"),
      width: 80,
    },
    {
      field: "ERPUserNickName",
      headerName: t("data_grid.headers.user"),
      width: 90,
    },
    {
      field: "AcceptanceDate",
      headerName: t("data_grid.headers.date"),
      renderCell: (params) => params.row.AcceptanceDate.split("T")[0],
      width: 90,
    },

    {
      field: "Actions",
      type: "actions",
      width: 90,
      headerName: t("data_grid.headers.actions"),
      getActions: (params) => [
        <Tooltip title="View Acceptance" key="image">
          <GridActionsCellItem
            icon={<Image />}
            label="Image"
            onClick={() => {
              handleImageModalOpen(params.row.WorkOrderD6No);
            }}
          />
        </Tooltip>,
      ],
    },
  ];

  return (
    <>
      <InfoModal
        open={open}
        handleClose={handleCloseCheck}
        title={t("info_modules.client_invoices.description.add_new_item")}
        content={
          <Box padding={1.2}>
            <FormsDataGrid
              rows={data || []}
              columns={columns}
              loading={isLoading}
              getRowId={(row) => row.idWorkOrderD6}
              height={380}
              toolbar
              showQuickFilter
              showColumnsFilter
              checkboxSelection
              showReload
              reloadFunction={refetch}
              showReloadOverlay={isRefetching}
              disableSelectionOnClick
              onRowSelectionModelChange={handleRowSelectionModelChange}
              rowSelectionModel={selectedRows}
              initialState={{
                columns: {
                  columnVisibilityModel: {
                    TeamLeadersName: false,
                    RequestorsName: false,
                  },
                },
              }}
            />
            <Box
              marginTop="15px"
              display="grid"
              gridTemplateColumns="repeat(3, 1fr)"
            >
              <ChipComponent
                label={t("info_modules.client_invoices.description.old_amount")}
                value={formatCurrency(sumSTotalForTypeS)}
              />
              <ChipComponent
                label={t("info_modules.client_invoices.description.new_amount")}
                value={newAmount}
              />
              <ChipComponent
                label={t("info_modules.client_invoices.description.total")}
                value={total}
              />
            </Box>
            <ButtonGroup
              onClickClose={handleCloseCheck}
              onClickSave={handleSaveClick}
              saveLabel={t("modules.buttons.select")}
            />
          </Box>
        }
      />
      <ConfirmationInfoModal
        open={confirmModal}
        handleClose={() => {
          setConfirmModal(false);
        }}
        onClick={addItem}
        description={t("info_modules.client_invoices.confirmation.add_item")}
        color="error"
        isPending={add}
      />
      <ImageModal
        open={isImageModalOpen}
        handleClose={handleCloseImageModal}
        image={image}
        loading={isImageLoading}
      />
    </>
  );
};

export default AddItems;
