import { Box } from "@mui/material";
import InfoModal from "../../../components/Modals/InfoModal";
import FormsDataGrid from "../../../components/DataGrid/FormsDataGrid";
import FormSpinner from "../../../components/Spinners/FormSpinner";

const DashBoardLinksModal = ({
  open,
  handleClose,
  data,
  columns,
  getRowId,
  title,
  isLoading,
  width,
}) => {
  return (
    <InfoModal
      open={open}
      handleClose={handleClose}
      title={title}
      width={width || "67rem"}
      content={
        isLoading ? (
          <FormSpinner />
        ) : (
          <Box padding="20px 10px">
            <FormsDataGrid
              rows={data}
              columns={columns}
              loading={isLoading}
              getRowId={getRowId}
              height={420}
              toolbar
              showExportButton
              showQuickFilter
            />
          </Box>
        )
      }
    />
  );
};

export default DashBoardLinksModal;
