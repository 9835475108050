import { useState } from "react";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import * as Yup from "yup";
import dayjs from "dayjs";
import { useFormik } from "formik";
import Axios from "../../../../network/Axios";
import { useSnackbar } from "notistack";
import { useParams, useNavigate } from "react-router-dom";
import ChipStatus from "../../../../components/ChipStatus";
import { Box, Grid, Autocomplete, TextField } from "@mui/material";
import { darken, lighten } from "@mui/material/styles";
import {
  StyledTextField,
  autoCompleteStyles,
} from "../../../../utils/globalFunctions";
import { useQuery, useMutation, useQueryClient } from "@tanstack/react-query";
import CheckOutlinedIcon from "@mui/icons-material/CheckOutlined";
import ReplayOutlinedIcon from "@mui/icons-material/ReplayOutlined";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";
import ChipComponent from "../../../../components/Chips/ChipComponent";
import ActionButton from "../../../../components/forms/ButtonGroup/ActionButton";
import ButtonGroup from "../../../../components/forms/ButtonGroup/ButtonGroup";
import ConfirmationInfoModal from "../../../../components/Modals/ConfirmationInfoModal";
import ActionMenuButton from "../../../../components/forms/ButtonGroup/ActionMenuButton";

const ClientPurchaseDetailsCard = ({
  clientPurchaseInfo,
  handleOpenModal,
  isEditable,
  setIsEditable,
}) => {
  const [t] = useTranslation("global");
  const { enqueueSnackbar } = useSnackbar();
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const { clientPurchaseId } = useParams();
  const theme = useSelector((state) => state.theme.colorTheme);
  const [confirmModal, setConfirmModal] = useState(false);
  const [isCancel, setIsCancel] = useState(false);

  const { data: comoBoxData } = useQuery({
    queryKey: ["clientPurchase", "edit"],
    queryFn: async () => {
      const response = await Axios.get("/ClientPurchaseOrders", {
        params: {
          State: "Edit",
          SelectedNo: clientPurchaseId,
        },
      });
      return response.data.CompanyAccounts;
    },
    enabled: isEditable,
  });

  const validationSchema = Yup.object().shape({
    poNo: Yup.string().required("Required"),
    account: Yup.string().required("Required"),
    amount: Yup.string().required("Required"),
    date: Yup.string().required("Required"),
    description: Yup.string().required("Required"),
  });

  const initialValues = {
    poNo: clientPurchaseInfo?.Details[0]?.ClientPONo,
    account: clientPurchaseInfo?.Details[0]?.CompanyAccountName,
    amount: parseInt(
      clientPurchaseInfo?.Details[0]?.ClientPOAmount.replace(/,/g, ""),
      10
    ),
    date: dayjs(clientPurchaseInfo?.Details[0]?.ClientPODate),
    description: clientPurchaseInfo?.Details[0]?.ClientPODescription,
  };
  const {
    handleSubmit,
    values,
    resetForm,
    dirty,
    errors,
    touched,
    handleBlur,
    handleChange,
    setFieldValue,
  } = useFormik({
    initialValues,
    validationSchema,
    onSubmit: () => {
      if (isCancel) {
        setConfirmModal(false);
      } else {
        setConfirmModal(true);
      }
    },
    enableReinitialize: true,
  });

  const { mutateAsync: editClientPO, isPending: pendingEdit } = useMutation({
    mutationFn: async () => {
      return Axios.put("/ClientPurchaseOrders", {
        ClientPOAmount: values.amount,
        ClientPODate: dayjs(values.date).format("YYYY-MM-DD"),
        ClientPODescription: values.description,
        ClientPONo: values.poNo,
        CompanyAccountName: values.account,
        SelectedNo: clientPurchaseId,
      });
    },
    onSuccess: (data) => {
      if (!data) return;
      enqueueSnackbar("Edited Successfully", {
        variant: "success",
      });
      queryClient.invalidateQueries({
        queryKey: ["clientPurchase", "info"],
      });
      navigate(`/clientpurchaseorders/${values.poNo}`);
      setIsEditable(false);
      resetForm();
    },
  });
  const clientPurchases = [
    {
      label: t("data_grid.headers.client_po_no"),
      value: isEditable ? (
        <StyledTextField
          name="poNo"
          value={values.poNo}
          onChange={handleChange("poNo")}
        />
      ) : (
        clientPurchaseInfo?.Details[0]?.ClientPONo
      ),
    },
    {
      label: t("data_grid.headers.received_on"),
      value: isEditable ? (
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <DateTimePicker
            value={values.date}
            onChange={(newValue) => {
              setFieldValue("date", newValue);
            }}
            format="YYYY/MM/DD"
            sx={{
              "& .MuiInputBase-input": {
                width: "9.8rem",
                height: "10px",
                fontSize: "0.85rem",
                fontWeight: 600,
              },
              "& .MuiSvgIcon-root": {
                width: "0.8em",
                height: "0.8em",
              },
            }}
            slotProps={{
              textField: {
                size: "small",
              },
            }}
          />
        </LocalizationProvider>
      ) : (
        clientPurchaseInfo?.Details[0]?.ClientPODate
      ),
    },
    {
      label: t("data_grid.headers.account"),
      value: isEditable ? (
        <Autocomplete
          disablePortal
          id="combo-box-demo"
          options={comoBoxData || []}
          getOptionLabel={(option) => option.CompanyAccountName}
          sx={autoCompleteStyles}
          size="small"
          onChange={(event, newValue) => {
            setFieldValue(
              "account",
              newValue ? newValue.CompanyAccountName : ""
            );
          }}
          value={
            (comoBoxData || []).find(
              (option) => option.CompanyAccountName === values.account
            ) || null
          }
          renderInput={(params) => (
            <StyledTextField
              {...params}
              onBlur={handleBlur}
              error={!!touched.account && !!errors.account}
              label={touched.account && errors.account}
            />
          )}
        />
      ) : (
        clientPurchaseInfo?.Details[0]?.CompanyAccountName
      ),
    },
    {
      label: t("data_grid.headers.status"),
      value: (
        <ChipStatus value={clientPurchaseInfo?.Details[0]?.ClientPOStatus} />
      ),
    },
    {
      label: t("data_grid.headers.po_amount"),
      value: isEditable ? (
        <StyledTextField
          name="amount"
          value={values.amount}
          onChange={handleChange("amount")}
        />
      ) : (
        clientPurchaseInfo?.Details[0]?.ClientPOAmount
      ),
    },
    {
      label: t("data_grid.headers.invoiced_amount"),
      value: clientPurchaseInfo?.Details[0]?.POAmount,
    },
  ];

  const actions =
    clientPurchaseInfo.Actions.filter(
      (action) => action !== "Edit" && action !== "AddInvoices"
    ) || [];

  const actionsConfig = {
    "Close PO": {
      label: t("info_modules.client_purchase.buttons.close_po"),
      icon: <CheckOutlinedIcon sx={{ width: "1.1rem", height: "1.1rem" }} />,
      color: "success",
      onClick: () => handleOpenModal("confirmClosePo"),
    },
    Open: {
      label: t("info_modules.client_purchase.buttons.reopen_po"),
      icon: <ReplayOutlinedIcon sx={{ width: "1.1rem", height: "1.1rem" }} />,
      sx: {
        color: "#ff9800",
        borderColor: "#ff9800",
        ":hover": {
          background:
            theme === "dark" ? darken("#ff9800", 0.7) : lighten("#ff9800", 0.9),
          borderColor: "#ff9800",
          color: "#ff9800",
          border: 2.3,
        },
      },
      onClick: () => handleOpenModal("confirmReopenPo"),
    },
  };

  return (
    <Box>
      <form onSubmit={handleSubmit}>
        {!isEditable && actions.length > 0 && (
          <Box display="flex" justifyContent="flex-end" marginBottom="4px">
            {actions.length > 1 ? (
              <ActionMenuButton
                actions={actions}
                actionsConfig={actionsConfig}
              />
            ) : (
              <ActionButton
                icon={actionsConfig[actions[0]].icon}
                text={actionsConfig[actions[0]].label}
                onClick={actionsConfig[actions[0]].onClick}
                sx={{ textTransform: "capitalize" }}
                color={actionsConfig[actions[0]].color}
              />
            )}
          </Box>
        )}
        <Grid container spacing={1}>
          {clientPurchases.map((item, index) => (
            <Grid item xs={4} key={index}>
              <ChipComponent label={item.label} value={item.value} />
            </Grid>
          ))}
          <Grid item xs={8}>
            <ChipComponent
              label={t("data_grid.headers.description")}
              value={
                isEditable ? (
                  <TextField
                    name="description"
                    value={values.description}
                    onChange={handleChange("description")}
                    onBlur={handleBlur}
                    error={!!touched.description && !!errors.description}
                    label={touched.description ? errors.description : ""}
                    sx={{
                      "& .MuiInputBase-input": {
                        height: "9px",
                        fontSize: "0.85rem",
                        fontWeight: 600,
                        width: "36.7rem",
                      },
                      "& .MuiOutlinedInput-root": {
                        paddingTop: "5px",
                      },
                    }}
                    rows={2}
                    multiline
                  />
                ) : (
                  clientPurchaseInfo?.Details[0]?.ClientPODescription
                )
              }
            />
          </Grid>
        </Grid>
        {isEditable && (
          <ButtonGroup
            onClickSave={() => {
              setIsCancel(false);
              if (dirty && Object.keys(errors).length === 0) {
                setConfirmModal(true);
              }
            }}
            onClickClose={() => {
              if (dirty) {
                setIsCancel(true);
                setConfirmModal(true);
              } else {
                setIsEditable(false);
              }
            }}
            saveLabel={t("modules.buttons.save")}
          />
        )}
      </form>

      <ConfirmationInfoModal
        open={confirmModal}
        handleClose={() => {
          setConfirmModal(false);
        }}
        onClick={() =>
          isCancel
            ? (setIsEditable(false), setConfirmModal(false), resetForm())
            : editClientPO()
        }
        description={
          isCancel
            ? t("info_modules.missions.description.cancel_without_saving")
            : t("info_modules.client_purchase.confirmation.edit_purchase")
        }
        isPending={isCancel ? null : pendingEdit}
        color="error"
      />
    </Box>
  );
};
export default ClientPurchaseDetailsCard;
