import { useState } from "react";
import { Box } from "@mui/material";
import { useSnackbar } from "notistack";
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import Axios from "../../../../network/Axios";
import InfoModal from "../../../../components/Modals/InfoModal";
import FormSpinner from "../../../../components/Spinners/FormSpinner";
import FormsDataGrid from "../../../../components/DataGrid/FormsDataGrid";
import ButtonGroup from "../../../../components/forms/ButtonGroup/ButtonGroup";
import { useQuery, useMutation, useQueryClient } from "@tanstack/react-query";

const ChangeSite = ({ open, handleClose }) => {
  const [t] = useTranslation("global");
  const queryClient = useQueryClient();
  const { workOrderId } = useParams();
  const { enqueueSnackbar } = useSnackbar();
  const [selectSites, setSelectSites] = useState({
    selectedRowIds: [],
    selectedItems: [],
  });
  const [siteId, setSiteId] = useState(null);

  const { data, isLoading } = useQuery({
    queryKey: ["changeSite"],
    queryFn: async () => {
      const response = await Axios.get("/SitesDatabase", {
        params: {
          State: "WorkOrder",
        },
      });
      return response.data.result;
    },
    enabled: open,
  });

  const handleCloseSave = () => {
    handleClose();
    setSelectSites({
      selectedRowIds: [],
      selectedItems: [],
    });
  };

  const { mutateAsync: changeSite, isPending } = useMutation({
    mutationFn: async () => {
      return Axios.put("/WorkOrders", {
        State: "ChangeSite",
        SelectedID: siteId,
        SelectedNo: workOrderId,
      });
    },
    onSuccess: (data) => {
      if (!data) return;
      handleCloseSave();
      enqueueSnackbar("Site Changes Successfully", {
        variant: "success",
      });
      queryClient.invalidateQueries({
        queryKey: ["workOrderInfo"],
      });
    },
  });

  const columns = [
    {
      field: "SiteName",
      headerName: t("data_grid.headers.site_name"),
      width: 120,
    },
    {
      field: "CSiteCode",
      headerName: t("data_grid.headers.site_code"),
      width: 180,
      flex: 1,
    },
    {
      field: "SiteAddress",
      headerName: t("data_grid.headers.site_address"),
      width: 160,
    },
  ];

  return (
    <InfoModal
      open={open}
      handleClose={handleCloseSave}
      title={t("info_modules.work_orders.description.change_site")}
      width="50rem"
      content={
        <Box padding={1.2}>
          {isLoading ? (
            <FormSpinner />
          ) : (
            <FormsDataGrid
              rows={data || []}
              columns={columns}
              getRowId={(row) => row.idSitesDatabase}
              height={380}
              showQuickFilter
              toolbar
              checkboxSelection
              disableSelectionOnClick
              onRowSelectionModelChange={(newSelectedRows) => {
                const lastSelectedRow =
                  newSelectedRows.length > 0
                    ? [newSelectedRows[newSelectedRows.length - 1]]
                    : [];
                const updatedSelection = {
                  selectedRowIds: lastSelectedRow,
                  selectedItems: data.filter((row) =>
                    lastSelectedRow.includes(row.idSitesDatabase)
                  ),
                };
                setSelectSites(updatedSelection);
                setSiteId(updatedSelection.selectedItems[0].idSitesDatabase);
              }}
              rowSelectionModel={selectSites.selectedRowIds}
            />
          )}
          <ButtonGroup
            onClickSave={changeSite}
            isPending={isPending}
            onClickClose={handleCloseSave}
            saveLabel={t("modules.buttons.select")}
          />
        </Box>
      }
    />
  );
};
export default ChangeSite;
