import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { getFinalArray, sortUserRoles } from "./utils";
import { useState } from "react";
import { useSnackbar } from "notistack";
import FormSpinner from "../../../components/Spinners/FormSpinner";
import { ERPSystemRolesService } from "../../../api/ERPSystemRoles";
import { UserRolePrivilegesTree } from "./common";
import { Dashboard } from "@mui/icons-material";

const NewUserRole = ({ onClose }) => {
  const { data, isLoading, isRefetching } = useQuery({
    queryKey: ["newUserRoleList"],
    queryFn: ERPSystemRolesService.getNewUserRolePrivileges,
  });

  if (isLoading || isRefetching) return <FormSpinner />;

  return <NewUserRoleData data={data} onClose={onClose} />;
};

const NewUserRoleData = ({ data, onClose }) => {
  const { enqueueSnackbar } = useSnackbar();
  const queryClient = useQueryClient();

  // Handle user role state
  const [currentUserRole, setCurrentUserRole] = useState("");

  // Handle errors
  const [error, setError] = useState({
    error: false,
    message: "",
  });

  // Handle is touched
  const [isTouched, setIsTouched] = useState(false);

  const handleUserRoleChange = (event) => {
    setIsTouched(true);
    setCurrentUserRole(event.target.value);
  };

  const [selectedItems, setSelectedItems] = useState([]);
  const [selectedDashboardItems, setSelectedDashboardItems] = useState([]);

  const handleSelectedItemsChange = (event, ids) => {
    setSelectedItems(ids);
  };
  const handleDashboardItemsChange = (event, ids) => {
    setSelectedDashboardItems(ids);
  };

  const sortedUserRoles = sortUserRoles(data.ModuleCategoryandSub);

  const handleSubmit = () => {
    if (!currentUserRole) {
      setIsTouched(true);
      setError({ error: true, message: "Role name is required" });
      return;
    }

    const outputArray = getFinalArray(selectedItems, data.ModuleCategoryandSub);
    const updatedDashboard = data.Dashboard.map((item) => {
      return {
        idDashboard: item.idDashboard,
        checked: selectedDashboardItems.includes(item.idDashboard),
      };
    });

    addNewUserRole({
      ERPUserRoleName: currentUserRole,
      ModulePriv: outputArray,
      Dashboard: updatedDashboard,
    });
  };

  const { mutateAsync: addNewUserRole, isPending } = useMutation({
    mutationFn: ERPSystemRolesService.addNewUserRole,
    onSuccess: (data) => {
      onClose();
      enqueueSnackbar(data.msg, { variant: "success" });
      queryClient.invalidateQueries({ queryKey: ["userRoles"] });
    },
  });

  return (
    <UserRolePrivilegesTree
      currentUserRole={currentUserRole}
      handleUserRoleChange={handleUserRoleChange}
      selectedItems={selectedItems}
      handleSelectedItemsChange={handleSelectedItemsChange}
      sortedUserRoles={sortedUserRoles}
      data={data.ModuleCategoryandSub}
      isNewUserRole
      isPending={isPending}
      handleSubmit={handleSubmit}
      onClose={onClose}
      error={error}
      isTouched={isTouched}
      selectedDashboardItems={selectedDashboardItems}
      handleDashboardItemsChange={handleDashboardItemsChange}
      dashboardData={data.Dashboard}
    />
  );
};

export default NewUserRole;
