import { useDispatch } from "react-redux";
import { setNewForm, setSideDrawerState } from "../store/slices/themeSlice";
import { useEffect } from "react";

const useHandleForms = ({ dirty, onDirtyChange }) => {
  const dispatch = useDispatch();

  useEffect(() => {
    // Call the onDirtyChange function whenever the dirty state changes
    onDirtyChange(dirty);
  }, [dirty, onDirtyChange]);

  const handleClose = () => {
    dispatch(setSideDrawerState());
    dispatch(setNewForm(null));
  };
  return { handleClose };
};

export default useHandleForms;
