import { Autocomplete, Box, Stack, TextField } from "@mui/material";
import { useQuery } from "@tanstack/react-query";
import FormSpinner from "../../../../Spinners/FormSpinner";
import { usersManagementService } from "../../../../../api/usersManagement";
import { Controller } from "react-hook-form";
import MultipleSelectChip from "./MultipleSelectChip";
import { useTranslation } from "react-i18next";

const Privilege = ({ errors, register, control, setValue, values }) => {
  const [t] = useTranslation("global");

  const { data, isLoading } = useQuery({
    queryKey: ["usersManagementFormData"],
    queryFn: usersManagementService.GetUsersManagementFormData,
  });

  if (isLoading) return <FormSpinner />;

  const PriceList = data.PriceList.map((item) => item.PriceListsGroupName);
  const UserRoles = data.UserRoles.map((item) => item.ERPUserRoleName);

  return (
    <Stack justifyContent={"space-evenly"}>
      <Box
        sx={{
          display: "grid",
          gridTemplateColumns: "1fr 1fr",
          gap: 2,
        }}
      >
        <Controller
          control={control}
          name={`priceList`}
          render={({ field: { onChange, value } }) => (
            <Autocomplete
              size="small"
              options={PriceList}
              onChange={(event, item) => {
                onChange(item);
              }}
              value={value || null}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label={t(
                    "modules.users_management.new_form.labels.price_list"
                  )}
                  error={!!errors.priceList}
                  helperText={errors.priceList?.message}
                  required
                />
              )}
            />
          )}
        />

        <Controller
          control={control}
          name={`userRole`}
          render={({ field: { onChange, value } }) => (
            <Autocomplete
              size="small"
              options={UserRoles}
              onChange={(event, item) => {
                onChange(item);
              }}
              value={value || null}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label={t(
                    "modules.users_management.new_form.labels.user_role"
                  )}
                  error={!!errors.userRole}
                  helperText={errors.userRole?.message}
                  required
                />
              )}
            />
          )}
        />

        <TextField
          size="small"
          type="text"
          label={t("modules.users_management.new_form.labels.level")}
          error={Boolean(errors.level)}
          helperText={errors.level?.message}
          {...register("level")}
          required
        />

        <MultipleSelectChip
          stores={data.Stores}
          setValue={setValue}
          control={control}
          errors={errors}
          values={values.stores}
        />
      </Box>
    </Stack>
  );
};

export default Privilege;
