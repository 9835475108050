import { Box, Stack, TextField, Typography } from "@mui/material";
import { useMutation, useQuery } from "@tanstack/react-query";
import FormSpinner from "../../components/Spinners/FormSpinner";
import { MailServiceService } from "../../api/mailService";
import { useFormik } from "formik";
import * as yup from "yup";
import { useSnackbar } from "notistack";
import { useTranslation } from "react-i18next";
import AntSwitch from "../../components/Switches/AntSwitch";
import ButtonGroup from "../../components/forms/ButtonGroup/ButtonGroup";

export const SettingsModal = ({ onClose }) => {
  const { data, isLoading, isRefetching } = useQuery({
    queryKey: ["mailServiceSettingsFormData"],
    queryFn: MailServiceService.getSettingsFormData,
  });

  if (isLoading || isRefetching) return <FormSpinner />;

  return <SettingsModalContent data={data} onClose={onClose} />;
};

const SettingsModalContent = ({ data, onClose }) => {
  const { t } = useTranslation("global");
  const { enqueueSnackbar } = useSnackbar();

  const schema = yup.object().shape({
    host: yup.string().required(t("modules.global_schema.required")),
    port: yup.string().required(t("modules.global_schema.required")),
    enable_ssl: yup.boolean().required(t("modules.global_schema.required")),
    credentials_username: yup
      .string()
      .required(t("modules.global_schema.required")),
    credentials_password: yup
      .string()
      .required(t("modules.global_schema.required")),
    mail_address_from: yup
      .string()
      .required(t("modules.global_schema.required")),
    is_body_html: yup.boolean().required(t("modules.global_schema.required")),
    domain: yup.string().required(t("modules.global_schema.required")),
    logo_link: yup.string().required(t("modules.global_schema.required")),
  });

  const handleFormSubmit = (values) => {
    const {
      host,
      port,
      enable_ssl,
      credentials_username,
      credentials_password,
      mail_address_from,
      is_body_html,
      domain,
      logo_link,
    } = values;

    updateMailServiceSettings({
      Host: host,
      Port: port,
      EnableSsl: enable_ssl ? "True" : "False",
      CredentialsUserName: credentials_username,
      CredentialsPassword: credentials_password,
      MailAddressFrom: mail_address_from,
      IsBodyHtml: is_body_html ? "True" : "False",
      Domain: domain,
      LogoLink: logo_link,
      State: "Parameters",
    });
  };

  const { mutateAsync: updateMailServiceSettings, isPending } = useMutation({
    mutationFn: MailServiceService.updateMailServiceSettings,
    onSuccess: (data) => {
      onClose();
      enqueueSnackbar(data.msg, { variant: "success" });
    },
  });

  // Convert the data array into an object for quick lookups
  const parameters = data.reduce((acc, item) => {
    acc[item.MailServiceParameterName] = item.MailServiceParameterValue;
    return acc;
  }, {});

  // Use the parameters object to set the initial values
  const initialValues = {
    host: parameters["Host"],
    port: parameters["Port"],
    enable_ssl: parameters["EnableSsl"] === "True",
    credentials_username: parameters["Credentials UserName"],
    credentials_password: parameters["Credentials Password"],
    mail_address_from: parameters["MailAddress From"],
    is_body_html: parameters["IsBodyHtml"] === "True",
    domain: parameters["Domain"],
    logo_link: parameters["LogoLink"],
  };

  const {
    values,
    errors,
    touched,
    handleBlur,
    handleChange,
    handleSubmit,
    setFieldValue,
  } = useFormik({
    initialValues,
    validationSchema: schema,
    onSubmit: handleFormSubmit,
  });

  return (
    <Box
      component="form"
      onSubmit={handleSubmit}
      noValidate
      sx={{
        p: 2,
      }}
    >
      <Stack spacing={2}>
        <TextField
          label={t("modules.mail_service.fields.domain")}
          size="small"
          name="domain"
          value={values.domain}
          onBlur={handleBlur}
          onChange={handleChange("domain")}
          error={!!touched.domain && !!errors.domain}
          helperText={!!touched.domain && errors.domain}
          required
        />
        <TextField
          label={t("modules.mail_service.fields.logo_link")}
          size="small"
          name="logo_link"
          value={values.logo_link}
          onBlur={handleBlur}
          onChange={handleChange("logo_link")}
          error={!!touched.logo_link && !!errors.logo_link}
          helperText={!!touched.logo_link && errors.logo_link}
          required
        />
        <TextField
          label={t("modules.mail_service.fields.mail_address_from")}
          size="small"
          name="mail_address_from"
          value={values.mail_address_from}
          onBlur={handleBlur}
          onChange={handleChange("mail_address_from")}
          error={!!touched.mail_address_from && !!errors.mail_address_from}
          helperText={!!touched.mail_address_from && errors.mail_address_from}
          required
        />
        <Stack spacing={1.5}>
          <Typography variant="h6" fontSize={16}>
            {t("modules.mail_service.labels.service_settings")}
          </Typography>
          <Box
            sx={{
              display: "grid",
              gap: 2,
              gridTemplateColumns: "repeat(4, 1fr)",
            }}
          >
            <TextField
              label={t("modules.mail_service.fields.host")}
              size="small"
              name="host"
              value={values.host}
              onBlur={handleBlur}
              onChange={handleChange("host")}
              error={!!touched.host && !!errors.host}
              helperText={!!touched.host && errors.host}
              required
              sx={{ gridColumn: "span 3" }}
            />
            <TextField
              label={t("modules.mail_service.fields.port")}
              size="small"
              name="port"
              value={values.port}
              onBlur={handleBlur}
              onChange={handleChange("port")}
              error={!!touched.port && !!errors.port}
              helperText={!!touched.port && errors.port}
              required
              sx={{ gridColumn: "span 1" }}
            />
            <Stack
              direction="row"
              spacing={1}
              alignItems="center"
              sx={{ gridColumn: "span 2" }}
            >
              <Typography>
                {t("modules.mail_service.fields.is_body_html")}
              </Typography>
              <AntSwitch
                checked={values.is_body_html}
                onChange={(e) =>
                  setFieldValue("is_body_html", e.target.checked)
                }
              />
            </Stack>
            <Stack
              direction="row"
              spacing={1}
              alignItems="center"
              sx={{ gridColumn: "span 2" }}
            >
              <Typography>
                {t("modules.mail_service.fields.enable_ssl")}
              </Typography>
              <AntSwitch
                checked={values.enable_ssl}
                onChange={(e) => setFieldValue("enable_ssl", e.target.checked)}
              />
            </Stack>
          </Box>
        </Stack>

        <Stack spacing={1.5}>
          <Typography variant="h6" fontSize={16}>
            {t("modules.mail_service.labels.credentials")}
          </Typography>
          <TextField
            label={t("modules.mail_service.fields.username")}
            size="small"
            name="credentials_username"
            value={values.credentials_username}
            onBlur={handleBlur}
            onChange={handleChange("credentials_username")}
            error={
              !!touched.credentials_username && !!errors.credentials_username
            }
            helperText={
              !!touched.credentials_username && errors.credentials_username
            }
            required
          />
          <TextField
            label={t("modules.mail_service.fields.password")}
            size="small"
            name="credentials_password"
            value={values.credentials_password}
            onBlur={handleBlur}
            onChange={handleChange("credentials_password")}
            error={
              !!touched.credentials_password && !!errors.credentials_password
            }
            helperText={
              !!touched.credentials_password && errors.credentials_password
            }
            required
          />
        </Stack>

        <ButtonGroup
          isPending={isPending}
          onClickClose={onClose}
          saveLabel={t("modules.buttons.save")}
        />
      </Stack>
    </Box>
  );
};
