import { Box, Stack, Typography } from "@mui/material";
import { useState } from "react";
import PersonIcon from "@mui/icons-material/Person";
import PhoneIphoneIcon from "@mui/icons-material/PhoneIphone";
import WorkIcon from "@mui/icons-material/Work";
import VisibilityIcon from "@mui/icons-material/Visibility";
import IconButton from "@mui/material/IconButton";
import ImageModal from "../../../../components/Modals/ImageModal";
import { convertImage } from "../../../../utils/utils";
import { useParams } from "react-router-dom";

const EmployeeInfoElement = ({ icon, data }) => {
  return (
    <Stack direction="row" spacing={1} alignItems="center">
      {icon}
      <Typography
        sx={{
          fontSize: 12,
        }}
      >
        {data}
      </Typography>
    </Stack>
  );
};

const EmployeeInfoGlimpse = ({ data }) => {
  const { empCode } = useParams();
  const { EmpName, EmpNameEn, EmpPhone, EmpPhoto, EmpTitle } = data;

  const convertedImage =
    EmpPhoto === null ? "/person.png" : convertImage(EmpPhoto.data);

  const employeeInfoList = [
    { id: 0, icon: <PersonIcon />, data: EmpName },
    {
      id: 1,
      icon: <PersonIcon />,
      data: EmpNameEn,
    },
    {
      id: 2,
      icon: <WorkIcon />,
      data: EmpTitle,
    },
    {
      id: 3,
      icon: <PhoneIphoneIcon />,
      data: EmpPhone,
    },
  ];

  // Handle image modal
  const [isImageModalOpen, setIsImageModalOpen] = useState(false);
  const handleCloseImageModal = () => setIsImageModalOpen(false);
  const handleOpenImageModal = () => setIsImageModalOpen(true);

  return (
    <>
      <Box
        sx={{
          padding: "1rem",
          backgroundColor: "background.default",
          borderRadius: 2,
          border: "1px solid",
          borderColor: "secondary.main",
        }}
      >
        <Stack spacing={2} justifyContent="space-between">
          <Box
            sx={{
              width: "100px",
              aspectRatio: "1/1",
              borderRadius: "50%",
              overflow: "hidden",
              alignSelf: "center",
              position: "relative",
            }}
          >
            <img
              src={convertedImage}
              alt="Employee"
              style={{
                width: "100%",
                height: "100%",
                objectFit: "cover",
                objectPosition: "80% 28%",
              }}
            />
            <Box
              sx={{
                position: "absolute",
                top: 0,
                left: 0,
                width: "100%",
                height: "100%",
                backgroundColor: "rgba(0, 0, 0, 0.5)",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                opacity: 0,
                transition: "opacity 0.3s",
                "&:hover": {
                  opacity: 1,
                },
              }}
            >
              <IconButton
                aria-label="show image modal"
                onClick={handleOpenImageModal}
                sx={{
                  backgroundColor: "#fff",
                  "&:hover": {
                    backgroundColor: "#333",
                  },
                }}
              >
                <VisibilityIcon color="primary" />
              </IconButton>
            </Box>
          </Box>
          <Stack spacing={2}>
            {employeeInfoList.map(({ id, icon, data }) => (
              <EmployeeInfoElement key={id} icon={icon} data={data} />
            ))}
          </Stack>
        </Stack>
      </Box>
      <ImageModal
        open={isImageModalOpen}
        handleClose={handleCloseImageModal}
        image={convertedImage}
        saveLabel={`${empCode}-Photo`}
      />
    </>
  );
};

export default EmployeeInfoGlimpse;
