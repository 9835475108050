import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import { Provider } from "react-redux";
import store from "./store/store";
import { LicenseInfo } from "@mui/x-license-pro";
import { SnackbarProvider } from "notistack";
import { I18nextProvider } from "react-i18next";
import i18n from "./i18n/i18n";
import { HelmetProvider } from "react-helmet-async";

if ("serviceWorker" in navigator) {
  navigator.serviceWorker
    .register("/service-worker.js")
    .then()
    .catch((error) => {
      console.error("Service Worker registration failed:", error);
    });
}

const root = ReactDOM.createRoot(document.getElementById("root"));
LicenseInfo.setLicenseKey(
  "6f1408a4b8c8d5b04f75a8322a5735d9Tz05NTIxMyxFPTE3NTM5NjgyMDIwMDAsUz1wcm8sTE09c3Vic2NyaXB0aW9uLEtWPTI="
);

const helmetContext = {};

root.render(
  <React.StrictMode>
    <Provider store={store}>
      <SnackbarProvider
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
      >
        <I18nextProvider i18n={i18n}>
          <HelmetProvider context={helmetContext}>
            <App />
          </HelmetProvider>
        </I18nextProvider>
      </SnackbarProvider>
    </Provider>
  </React.StrictMode>
);
