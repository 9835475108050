import { useParams } from "react-router-dom";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { employeeRecordsService } from "../../../../api/employeeRecords";
import { attendanceService } from "../../../../api/attendance";
import { Box, IconButton, Stack, Typography, Tooltip } from "@mui/material";
import EmployeeInfoGlimpse from "../cards/EmployeeInfoGlimpse";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { DateRangePicker } from "@mui/x-date-pickers-pro";
import dayjs from "dayjs";
import { useRef, useState } from "react";
import SmallSkeletonCard from "../SkeletonLoaders/SmallSkeletonCard";
import { SearchRounded } from "@mui/icons-material";
import { CardHeader, NoVacationData } from "../components/common";
import InfoCard from "../../../../components/InfoPagesCard/InfoCard";
import FormsDataGrid from "../../../../components/DataGrid/FormsDataGrid";
import DataGridSkeleton from "../SkeletonLoaders/DataGridSkeleton";
import { useTranslation } from "react-i18next";
import ChipStatus from "../../../../components/ChipStatus";
import { GridActionsCellItem } from "@mui/x-data-grid";
import DeleteIcon from "@mui/icons-material/Delete";
import Edit from "@mui/icons-material/Edit";
import Image from "@mui/icons-material/Image";
import ImageModal from "../../../../components/Modals/ImageModal";
import Axios from "../../../../network/Axios";
import { useSnackbar } from "notistack";
import { useConfirmationDialog } from "../../../../contexts/ConfirmationDialogContext";
import { vacationsRequestsService } from "../../../../api/vacationsRequests";
import Print from "@mui/icons-material/Print";
import { useReactToPrint } from "react-to-print";
import InfoModal from "../../../../components/Modals/InfoModal";
import EditVacationRequest from "../cards/Vacations/NewEditForm/EditVacationRequest";
import EditAttendance from "./EditAttendance";
import useEmployeeImagePopup from "../../../../hooks/useEmployeeImagePopup";
import {
  blue,
  cyan,
  green,
  orange,
  pink,
  purple,
  red,
} from "@mui/material/colors";
import { useSelector } from "react-redux";
import MissionSnapshot from "../cards/Attendance/TimeLine/MissionSnapshot";

const EmployeeHistory = () => {
  const [t] = useTranslation("global");
  // This ref is used for printing the page
  const pageRef = useRef();

  const handlePrintImage = useReactToPrint({
    content: () => pageRef.current,
  });

  const { empCode } = useParams();

  const [selectedDate, setSelectedDate] = useState([
    dayjs().subtract(1, "month"),
    dayjs(),
  ]);

  const handleChangeDate = (newValue) => {
    setSelectedDate(newValue);
  };

  const { data, isLoading } = useQuery({
    queryKey: ["employeeInfo", empCode],
    queryFn: () => employeeRecordsService.GetEmployeeInfo(empCode),
  });

  const {
    data: employeeHistory,
    isLoading: isEmployeeHistoryLoading,
    refetch,
    isRefetching,
  } = useQuery({
    queryKey: ["employeeHistory"],
    queryFn: () =>
      attendanceService.getEmployeeHistory(
        empCode,
        dayjs(selectedDate[0]).format("YYYY-MM-DD"),
        dayjs(selectedDate[1]).format("YYYY-MM-DD")
      ),
  });

  return (
    <Stack spacing={2} ref={pageRef}>
      <Box
        display="flex"
        justifyContent="space-between"
        padding="0.5rem 1.1rem"
        backgroundColor="background.default"
        borderRadius={2}
        border="1px solid"
        borderColor="secondary.main"
      >
        <Box display="flex" gap={1} alignItems="center">
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DemoContainer components={["DateRangePicker"]}>
              <DateRangePicker
                disableFuture
                value={selectedDate}
                onAccept={handleChangeDate}
                onChange={handleChangeDate}
                sx={{
                  "& .MuiInputBase-root": {
                    fontSize: "14px",
                    width: "200px",
                  },
                }}
                slotProps={{
                  textField: {
                    size: "small",
                  },
                }}
                localeText={{
                  start: t(
                    "info_modules.employee_records.cards.attendance.employee_history.labels.start_date"
                  ),
                  end: t(
                    "info_modules.employee_records.cards.attendance.employee_history.labels.end_date"
                  ),
                  toolbarTitle: "sdsjdsdhj",
                }}
              />
            </DemoContainer>
          </LocalizationProvider>

          <IconButton
            onClick={() => refetch()}
            aria-label="get employee history"
            size="small"
          >
            <SearchRounded color="primary" />
          </IconButton>
        </Box>
        <IconButton
          aria-label="print"
          onClick={handlePrintImage}
          size="small"
          sx={{
            alignSelf: "flex-start",
            padding: "0.5rem",
            backgroundColor: "background.default",
            border: "1px solid",
            borderColor: "secondary.main",
          }}
        >
          <Print />
        </IconButton>
      </Box>
      <Box
        sx={{
          display: "grid",
          gridTemplateColumns: "1fr 3fr",
          gap: "1rem",
        }}
      >
        {isLoading ? (
          <SmallSkeletonCard />
        ) : (
          <EmployeeInfoGlimpse
            data={data.EmpInfo[0]}
            showPrintIcon
            pageRef={pageRef}
          />
        )}

        {isEmployeeHistoryLoading || isRefetching ? (
          <SmallSkeletonCard />
        ) : (
          <Stack
            spacing={1}
            justifyContent="space-between"
            sx={{
              padding: "1rem",
              backgroundColor: "background.default",
              borderRadius: 2,
              border: "1px solid",
              borderColor: "secondary.main",
            }}
          >
            <EmployeeStats
              data={employeeHistory.Analytics}
              selectedDate={selectedDate}
              handleChangeDate={handleChangeDate}
              refetch={refetch}
            />
            <VacationDetails data={employeeHistory.Vacations} />
          </Stack>
        )}
      </Box>
      {isEmployeeHistoryLoading || isRefetching ? (
        <DataGridSkeleton />
      ) : (
        <EmployeeHistoryTable
          rows={employeeHistory.result}
          empName={data?.EmpInfo[0].EmpName}
        />
      )}
    </Stack>
  );
};

const EmployeeStats = ({ data }) => {
  const [t] = useTranslation("global");

  return (
    <Stack spacing={2} alignItems={"flex-start"}>
      <CardHeader>
        {t(
          "info_modules.employee_records.cards.attendance.employee_history.headlines.employee_stats"
        )}
      </CardHeader>
      <Box
        sx={{
          display: "grid",
          gridTemplateColumns: "repeat(7, 1fr)",
          gap: "1rem",
        }}
      >
        {data.map(({ Type, Total }, index) => {
          return (
            <EmployeeStatsCard
              key={Type}
              type={Type}
              total={Total}
              index={index}
            />
          );
        })}
      </Box>
    </Stack>
  );
};

const EmployeeStatsCard = ({ total, type, index }) => {
  const colors = {
    0: {
      light: cyan[100],
      main: cyan[500],
    },
    1: {
      light: pink[100],
      main: pink[500],
    },
    2: {
      light: purple[100],
      main: purple[500],
    },
    3: {
      light: green[100],
      main: green[500],
    },
    4: {
      light: red[100],
      main: red[500],
    },
    5: {
      light: blue[100],
      main: blue[500],
    },
    6: {
      light: orange[100],
      main: orange[500],
    },
  };

  return (
    <Stack
      sx={{
        p: 1,
        border: "1px solid",
        borderBottomWidth: 4,
        borderColor: "secondary.main",
        borderRadius: 2,
        backgroundColor: "background.default",
        alignItems: "center",
      }}
    >
      <Typography
        sx={{
          fontSize: 32,
          fontWeight: 600,
          color: colors[index].main,
        }}
      >
        {total}
      </Typography>
      <Typography
        sx={{
          fontSize: 12,
          color: colors[index].main,
          fontWeight: 600,
        }}
      >
        {type}
      </Typography>
    </Stack>
  );
};

const VacationDetails = ({ data }) => {
  const [t] = useTranslation("global");

  const { language } = useSelector((state) => state.theme); // AR | EN

  return (
    <Stack spacing={2} alignItems="flex-start">
      <CardHeader>
        {t(
          "info_modules.employee_records.cards.attendance.employee_history.headlines.vacation_stats"
        )}
      </CardHeader>
      {data.length === 0 ? (
        <Box
          sx={{
            display: "grid",
            placeItems: "center",
            flexGrow: "1",
            width: "100%",
          }}
        >
          <NoVacationData />
        </Box>
      ) : (
        <Box
          sx={{
            display: "grid",
            gridTemplateColumns: "repeat(7, 1fr)",
            gap: "1rem",
            width: "100%",
          }}
        >
          {[
            ...data,
            {
              VacationsTypesName: "Total",
              VacationsTypesNameArabic: "المجموع",
              Total: data.reduce((acc, item) => acc + item.Total, 0),
            },
          ].map(
            (
              { VacationsTypesName, VacationsTypesNameArabic, Total },
              index
            ) => {
              return (
                <EmployeeStatsCard
                  key={VacationsTypesName}
                  type={
                    language === "AR"
                      ? VacationsTypesNameArabic
                      : VacationsTypesName
                  }
                  total={Total}
                  index={index}
                />
              );
            }
          )}
        </Box>
      )}
    </Stack>
  );
};

export const fetchVacationImage = async (id) => {
  try {
    const response = await Axios.get("/VacationRequests", {
      params: {
        SelectedID: id,
      },
    });
    return response.data.result;
  } catch (error) {
    console.log(error);
  }
};

const EmployeeHistoryTable = ({ rows }) => {
  const [t] = useTranslation("global");
  const { enqueueSnackbar } = useSnackbar();
  const queryClient = useQueryClient();

  const { setOpen } = useConfirmationDialog();

  // Handling the vacation edit modal
  const [isModalOpen, setIsModalOpen] = useState(false);

  const handleOpenModal = () => setIsModalOpen(true);
  const handleCloseModal = () => setIsModalOpen(false);

  // VacationID state
  const [vacationID, setVacationID] = useState(null);

  // Handling the signature edit modal
  const [isSignatureModalOpen, setIsSignatureModalOpen] = useState(false);

  const handleOpenSignatureModal = () => setIsSignatureModalOpen(true);
  const handleCloseSignatureModal = () => setIsSignatureModalOpen(false);

  // SignatureID state
  const [signatureID, setSignatureID] = useState(null);

  const imagePopupDetails = {
    Vacation: {
      function: fetchVacationImage,
      type: "VacationAttachement",
    },
    Signature: {
      function: attendanceService.getAttendanceEditData,
      type: "PermissionAttachement",
    },
  };

  // Handle Snapshot Modal
  const [isSnapshotModalOpen, setIsSnapshotModalOpen] = useState(false);

  const handleOpenSnapshotModal = () => setIsSnapshotModalOpen(true);
  const handleCloseSnapshotModal = () => setIsSnapshotModalOpen(false);

  const [missionRefNo, setMissionRefNo] = useState("");

  // Handle save label
  const [saveLabel, setSaveLabel] = useState("");

  // create an id and add it to the rows
  rows = rows.map((row, index) => {
    return { ...row, id: index + 1 };
  });

  const columns = [
    { field: "Type", headerName: t("data_grid.headers.type") },
    {
      field: "RefNo",
      headerName: t("data_grid.headers.ref_no"),
      width: 120,
      renderCell: (params) => {
        return (
          <Typography
            component="a"
            onClick={() => {
              setMissionRefNo(params.value);
              handleOpenSnapshotModal();
            }}
            sx={{
              cursor: params.row.Type === "Mission" ? "pointer" : "default",
              color: params.row.Type === "Mission" ? "primary.main" : "inherit",
              fontSize: "12px",
            }}
          >
            {params.value}
          </Typography>
        );
      },
    },
    { field: "PaperNo", headerName: t("data_grid.headers.paper_no") },
    {
      field: "SiteName",
      headerName: t("data_grid.headers.site_name"),
      flex: 1,
    },
    { field: "Area", headerName: t("data_grid.headers.area") },
    { field: "CarsType", headerName: t("data_grid.headers.car_type") },
    { field: "CarsNumber", headerName: t("data_grid.headers.car_number") },
    {
      field: "Start",
      headerName: t("data_grid.headers.start_date"),
      width: 150,
    },
    { field: "End", headerName: t("data_grid.headers.end_date"), width: 150 },
    {
      field: "Status",
      headerName: t("data_grid.headers.status"),
      renderCell: ({ value }) => {
        return <ChipStatus value={value} />;
      },
    },
    {
      field: "Actions",
      type: "actions",
      headerName: "Actions",
      getActions: (params) => [
        <Tooltip title={t("tooltip.view")} key="image">
          <GridActionsCellItem
            icon={<Image />}
            label="Image"
            onClick={() => {
              setSaveLabel(
                `${params.row.EmpCode.replace("/", "-")}-${
                  params.row.Type === "Vacation"
                    ? params.row.RefNo
                    : params.row.Type
                }-${params.row.Start}`
              );

              handleImageModalOpen(
                imagePopupDetails[params.row.Type].function,
                imagePopupDetails[params.row.Type].type,
                params.row.SelectedID
              );
            }}
            disabled={params.row.AttachementStatus !== "True"}
          />
        </Tooltip>,
        <Tooltip title={t("tooltip.edit")} key="edit">
          <GridActionsCellItem
            icon={<Edit />}
            label="Edit"
            onClick={() => {
              if (params.row.Type === "Vacation") {
                setVacationID(params.row.SelectedID);
                handleOpenModal();
              } else {
                setSignatureID(params.row.SelectedID);
                handleOpenSignatureModal();
              }
            }}
            disabled={params.row.Type === "Mission"}
          />
        </Tooltip>,
        <Tooltip title={t("tooltip.delete")} key="delete">
          <GridActionsCellItem
            icon={
              <DeleteIcon
                color={params.row.Type === "Mission" ? "disabled" : "error"}
              />
            }
            label="Delete"
            disabled={params.row.Type === "Mission"}
            onClick={() => {
              setOpen(
                t("info_modules.employee_records.confirmation.delete_record"),
                () => () => {
                  if (params.row.Type === "Vacation") {
                    deleteVacationRecord(params.row.SelectedID);
                  } else if (params.row.Type === "Signature") {
                    deleteSignatureRecord(params.row.SelectedID);
                  }
                },
                "error"
              );
            }}
          />
        </Tooltip>,
      ],
    },
  ];

  const { mutateAsync: deleteVacationRecord } = useMutation({
    mutationFn: vacationsRequestsService.deleteVacationRecord,
    onSuccess: (data) => {
      enqueueSnackbar(data.msg, { variant: "success" });
      queryClient.invalidateQueries({ queryKey: ["employeeHistory"] });
    },
  });

  const { mutateAsync: deleteSignatureRecord } = useMutation({
    mutationFn: attendanceService.deleteSignatureRecord,
    onSuccess: (data) => {
      enqueueSnackbar(data.msg, { variant: "success" });
      queryClient.invalidateQueries({ queryKey: ["employeeHistory"] });
    },
  });

  const {
    isImageLoading,
    isImageModalOpen,
    image,
    handleImageModalOpen,
    handleCloseImageModal,
  } = useEmployeeImagePopup();

  return (
    <>
      <InfoCard
        content={
          <Box>
            <FormsDataGrid
              rows={rows}
              columns={columns}
              getRowId={(row) => row.id}
              toolbar
              showExportButton
              showQuickFilter
              height={rows.length === 0 && 200}
            />
          </Box>
        }
      />
      <ImageModal
        open={isImageModalOpen}
        handleClose={handleCloseImageModal}
        image={image}
        loading={isImageLoading}
        saveLabel={saveLabel}
      />
      <InfoModal
        open={isModalOpen}
        title={t(
          "info_modules.employee_records.cards.vacations.edit_form.title"
        )}
        content={
          <EditVacationRequest
            handleClose={handleCloseModal}
            vacationID={vacationID}
          />
        }
        handleClose={handleCloseModal}
        width="30rem"
      />
      <InfoModal
        open={isSignatureModalOpen}
        title={t(
          "info_modules.employee_records.cards.attendance.edit_form.title"
        )}
        content={
          <EditAttendance
            handleClose={handleCloseSignatureModal}
            signatureID={signatureID}
          />
        }
        handleClose={handleCloseSignatureModal}
        width="30rem"
      />
      <InfoModal
        title={`Mission Details - ${missionRefNo}`}
        content={<MissionSnapshot missionRefNo={missionRefNo} />}
        handleClose={handleCloseSnapshotModal}
        open={isSnapshotModalOpen}
        width="62rem"
      />
    </>
  );
};

export default EmployeeHistory;
