import { Tabs, Tab, Box } from "@mui/material";
import { useState } from "react";
import { useQuery, useMutation, useQueryClient } from "@tanstack/react-query";
import { useSnackbar } from "notistack";
import { useTranslation } from "react-i18next";
import AddNewLineForm from "./MobileTabs/AddNewLine";
import CreateNewAccountForm from "./MobileTabs/CreateNewAccountForm";
import { MobileInternetService } from "../../api/mobileInternetLines";
import FormSpinner from "../Spinners/FormSpinner";
import useHandleForms from "../../hooks/useHandleForms";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { useForm } from "react-hook-form";

const MobileInternetLinesForm = ({ onDirtyChange, onClickClose }) => {
  const queryClient = useQueryClient();
  const [t] = useTranslation("global");
  const [tabIndex, setTabIndex] = useState(0);
  const [companyPhoneLinesOperatorName, setCompanyPhoneLinesOperatorName] =
    useState(null);

  const handleCompanyPhoneLinesOperatorNameChange = (value) => {
    setCompanyPhoneLinesOperatorName(value);
  };

  const handleTabChange = (event, newValue) => {
    setTabIndex(newValue);
    setCompanyPhoneLinesOperatorName(null); // Reset operator name when switching tabs
  };

  const schemaAccount = yup.object().shape({
    accountNumber: yup.string().required(t("modules.global_schema.required")),
    operatorName: yup.string().required(t("modules.global_schema.required")),
  });
  const schemaLine = yup.object().shape({
    phoneNumber: yup
      .string()
      .required(t("modules.global_schema.required"))
      .length(
        11,
        t("modules.mobile_internet_lines.new_form.schema.phone_number_length")
      )
      .matches(
        /^(011|012|010)\d{8}$/,
        t("modules.mobile_internet_lines.new_form.schema.valid_phone_number")
      ),
    type: yup.string().required(t("modules.global_schema.required")),
    accountNumber: yup.string().required(t("modules.global_schema.required")),
    operatorName: yup.string().required(t("modules.global_schema.required")),
    package: yup.string().required(t("modules.global_schema.required")),
    mobileInternet: yup
      .string()
      .required(t("modules.global_schema.required"))
      .matches(/^\d+$/, "Mobile internet value must contain numbers only"),
  });

  const {
    register: registerLine,
    handleSubmit: handleSubmitLine,
    setValue: setValueLine,
    control: controlLine,
    formState: { errors: errorsLine, isDirty: isDirtyLine },
  } = useForm({
    resolver: yupResolver(schemaLine),
    mode: "onChange",
    defaultValues: {
      phoneNumber: "",
      type: "",
      accountNumber: "",
      operatorName: "",
      package: "",
      mobileInternet: "",
    },
  });

  const {
    register: registerAccount,
    handleSubmit: handleSubmitAccount,
    control: controlAccount,
    formState: { errors: errorsAccount, isDirty: isDirtyAccount },
  } = useForm({
    resolver: yupResolver(schemaAccount),
    mode: "onChange",
    defaultValues: {
      accountNumber: "",
      operatorName: "",
    },
  });

  const { handleClose } = useHandleForms({
    dirty: tabIndex === 0 ? isDirtyLine : isDirtyAccount,
    onDirtyChange,
  });

  const { data, isLoading, isSuccess } = useQuery({
    queryKey: ["mobileInternetLinesFormData"],
    queryFn: MobileInternetService.GetMobileInternetLinesFormData,
  });

  const { data: packages, isRefetching: isRefetchingPackages } = useQuery({
    queryKey: ["mobileInternetLinesPackages", companyPhoneLinesOperatorName],
    queryFn: () =>
      MobileInternetService.GetPhoneLinesPackages(
        companyPhoneLinesOperatorName
      ),
    enabled: Boolean(companyPhoneLinesOperatorName),
  });

  const { enqueueSnackbar } = useSnackbar();

  const { mutateAsync: AddNewMobileLine, isPending } = useMutation({
    mutationFn: MobileInternetService.AddNewMobileLine,
    onSuccess: (data) => {
      handleClose();
      enqueueSnackbar(data.msg, { variant: "success" });
      queryClient.invalidateQueries({ queryKey: ["mobileInternetLines"] });
    },
  });

  const { mutateAsync: AddNewAccount, isPending: isAddingNewAccount } =
    useMutation({
      mutationFn: MobileInternetService.AddNewAccount,
      onSuccess: (data) => {
        handleClose();
        enqueueSnackbar(data.msg, { variant: "success" });
        queryClient.invalidateQueries({ queryKey: ["mobileInternetLines"] });
      },
    });

  if (isLoading) {
    return <FormSpinner />;
  }

  if (isSuccess) {
    return (
      <Box sx={{ width: "100%" }}>
        <Tabs
          value={tabIndex}
          onChange={handleTabChange}
          aria-label="Mobile Internet Lines Tabs"
        >
          <Tab
            label={t(
              "modules.mobile_internet_lines.new_form.fields.add_new_line"
            )}
          />
          <Tab
            label={t(
              "modules.mobile_internet_lines.new_form.fields.create_new_account"
            )}
          />
        </Tabs>

        {tabIndex === 0 && (
          <AddNewLineForm
            onSubmit={(values) =>
              AddNewMobileLine({
                CompanyPhoneLinesAccountNo: values.accountNumber,
                CompanyPhoneLinesMobInternetPackage: values.mobileInternet,
                CompanyPhoneLinesNumber: values.phoneNumber,
                CompanyPhoneLinesPackageName: values.package,
                CompanyPhoneLinesType: values.type,
                CompanyPhoneLinesOperatorName: values.operatorName,
              })
            }
            isPending={isPending}
            onClickClose={onClickClose}
            data={data}
            packages={packages?.result}
            accounts={packages?.Accounts}
            isRefetchingPackages={isRefetchingPackages}
            handleCompanyPhoneLinesOperatorNameChange={
              handleCompanyPhoneLinesOperatorNameChange
            }
            register={registerLine}
            handleSubmit={handleSubmitLine}
            control={controlLine}
            errors={errorsLine}
            isDirty={isDirtyLine}
            setValue={setValueLine}
          />
        )}

        {tabIndex === 1 && (
          <CreateNewAccountForm
            onSubmit={(values) =>
              AddNewAccount({
                CompanyPhoneLinesAccountNo: values.accountNumber,
                CompanyPhoneLinesOperatorName: values.operatorName,
              })
            }
            isPending={isAddingNewAccount}
            onClickClose={onClickClose}
            data={data}
            register={registerAccount}
            handleSubmit={handleSubmitAccount}
            control={controlAccount}
            errors={errorsAccount}
            isDirty={isDirtyAccount}
          />
        )}
      </Box>
    );
  }

  return null;
};

export default MobileInternetLinesForm;
