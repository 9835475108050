import {
  Collapse,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Link,
} from "@mui/material";
import { NavLink as RouterLink, useLocation } from "react-router-dom";
import InboxIcon from "@mui/icons-material/MoveToInbox";
import { useEffect, useState } from "react";
import { ExpandLess, ExpandMore } from "@mui/icons-material";
import { useSelector } from "react-redux";

const DropDown = ({ item, searchQuery }) => {
  // Reformat arabic and english submenu items
  const submenuEN = item.SystemModuleName.split(", ");
  const submenuAR = item.SystemModuleNameAR.split(", ");
  const submenu = submenuEN.reduce((acc, curr, index) => {
    acc[submenuEN[index]] = {
      ar: submenuAR[index],
      en: submenuEN[index],
    };
    return acc;
  }, {});

  const { language } = useSelector((state) => state.theme); // AR | EN

  // Dropdown logic
  const [dropDownStatus, setDropDownStatus] = useState(false);

  const handleDropDownClick = () => {
    setDropDownStatus(!dropDownStatus);
  };

  const { pathname } = useLocation();

  useEffect(() => {
    if (
      item.SystemModuleName.split(", ")
        .map((menuItem) => menuItem.toLowerCase().split(" ").join(""))
        .includes(pathname.split("/")[1])
    ) {
      setDropDownStatus(true);
    } else {
      setDropDownStatus(false);
    }
  }, [pathname, item]);

  const loweredSearchQuery = searchQuery.toLowerCase();

  const doesModuleExist = (item) =>
    searchQuery === "" ||
    item.toLowerCase().includes(loweredSearchQuery) ||
    submenu[item].ar.toLowerCase().includes(loweredSearchQuery);

  return (
    <>
      <ListItemButton
        onClick={handleDropDownClick}
        sx={{
          px: 2.5,
          "&:hover": {
            backgroundColor: "background.sidebarMenuHover",
          },
        }}
      >
        <ListItemIcon sx={{ minWidth: 0, mr: 1 }}>
          <InboxIcon sx={{ fill: "transparent" }} />
        </ListItemIcon>
        <ListItemText
          primary={
            language === "EN"
              ? item.ModulesCategoryName
              : item.ModulesCategoryNameAR
          }
          sx={{
            color: dropDownStatus ? "primary.main" : "text.primary",
          }}
        />
        {dropDownStatus ? <ExpandLess /> : <ExpandMore />}
      </ListItemButton>
      <Collapse
        in={dropDownStatus || searchQuery !== ""}
        timeout="auto"
        unmountOnExit
      >
        <List component="ul" disablePadding>
          {Object.keys(submenu)
            .filter(doesModuleExist)
            .map((menuItem) => {
              return (
                <ListItem
                  key={submenu[menuItem].en}
                  disablePadding
                  sx={{ display: "block", position: "relative" }}
                >
                  <Link
                    component={RouterLink}
                    to={"/" + menuItem.toLowerCase().split(" ").join("")}
                    underline="none"
                    sx={{
                      color: "text.primary",
                      "&.active > div": {
                        backgroundColor: "#1790ff24",
                        borderRight: "2px solid",
                        borderRightColor: "primary.main",
                      },
                    }}
                  >
                    <ListItemButton
                      sx={{
                        pl: 4,
                        pr: 2.5,
                        "&:hover": {
                          backgroundColor: "background.sidebarMenuHover",
                        },
                      }}
                    >
                      <ListItemIcon sx={{ minWidth: 0, mr: 1 }}>
                        <InboxIcon sx={{ fill: "transparent" }} />
                      </ListItemIcon>
                      <ListItemText
                        primary={
                          language === "EN"
                            ? submenu[menuItem].en
                            : submenu[menuItem].ar
                        }
                        sx={{
                          opacity: 1,
                          color: pathname
                            .split("/")
                            .includes(
                              menuItem.toLowerCase().split(" ").join("")
                            )
                            ? "primary.main"
                            : "text.primary",
                          "& span": {
                            fontSize: 14,
                          },
                        }}
                      />
                    </ListItemButton>
                  </Link>
                </ListItem>
              );
            })}
        </List>
      </Collapse>
    </>
  );
};
export default DropDown;
