import { useState } from "react";
import { Box } from "@mui/material";
import { useSnackbar } from "notistack";
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import Axios from "../../../../network/Axios";
import InfoModal from "../../../../components/Modals/InfoModal";
import ChipStatus from "../../../../components/ChipStatus";
import { useQuery, useMutation, useQueryClient } from "@tanstack/react-query";
import FormsDataGrid from "../../../../components/DataGrid/FormsDataGrid";
import ButtonGroup from "../../../../components/forms/ButtonGroup/ButtonGroup";
import ConfirmationInfoModal from "../../../../components/Modals/ConfirmationInfoModal";

const AddInvoices = ({ open, handleClose }) => {
  const { clientPurchaseId } = useParams();
  const [t] = useTranslation("global");
  const queryClient = useQueryClient();
  const { enqueueSnackbar } = useSnackbar();
  const [selectedRows, setSelectedRows] = useState([]);
  const [selectedData, setSelectedData] = useState([]);
  const [confirmModal, setConfirmModal] = useState(false);

  const handleOpenConfirmModal = () => {
    setConfirmModal(true);
  };

  const { data, isLoading } = useQuery({
    queryFn: async () => {
      const response = await Axios.get("/ClientInvoices", {
        params: {
          State: "AllInvoicesWithNoPO",
        },
      });
      return response.data.AllInvoicesWithNoPO;
    },
    enabled: open,
  });
  const { mutateAsync: addInvoice, isPending: add } = useMutation({
    mutationFn: async () => {
      return Axios.patch("/ClientPurchaseOrders", {
        Invoices: selectedData.map((item) => item.idInvoices),
        SelectedNo: clientPurchaseId,
      });
    },
    onSuccess: (data) => {
      if (!data) return;
      enqueueSnackbar(t("modules.error_messages.added_successfully"), {
        variant: "success",
      });
      handleCloseCheck();
      setConfirmModal(false);
      queryClient.invalidateQueries({
        queryKey: ["clientPurchase", "info"],
      });
    },
  });

  const handleRowSelectionModelChange = (newSelectedRows) => {
    const selectedData = data.filter((row) =>
      newSelectedRows.includes(row.idInvoices)
    );
    setSelectedData(selectedData);
    setSelectedRows(newSelectedRows);
  };
  const handleCloseCheck = () => {
    handleClose();
    setSelectedRows([]);
  };
  const columns = [
    {
      field: "InvoiceNo",
      headerName: t("data_grid.headers.ref_no"),
      width: 120,
    },
    {
      field: "ClientPONo",
      headerName: t("data_grid.headers.client_po"),
      width: 120,
    },
    {
      field: "Activity",
      headerName: t("data_grid.headers.activity"),
      width: 130,
    },
    {
      field: "CreationDate",
      headerName: t("data_grid.headers.creation_date"),
      width: 100,
    },
    {
      field: "SubmissionDate",
      headerName: t("data_grid.headers.submission_date"),
      width: 100,
    },
    {
      field: "InvoiceDate",
      headerName: t("data_grid.headers.invoice_date"),
      width: 100,
    },
    {
      field: "D6Date",
      headerName: t("data_grid.headers.d6_date"),
      width: 100,
    },
    {
      field: "InvoicesAmount",
      headerName: t("data_grid.headers.amount"),
      width: 110,
    },
    {
      field: "InvoiceType",
      headerName: t("data_grid.headers.type"),
      width: 90,
    },
    {
      field: "InvoiceStatus",
      headerName: t("data_grid.headers.status"),
      width: 120,
      renderCell: ({ value }) => {
        return <ChipStatus value={value} />;
      },
    },
  ];

  return (
    <>
      <InfoModal
        open={open}
        handleClose={handleCloseCheck}
        title={t("info_modules.client_purchase.description.add_new_Invoices")}
        content={
          <Box padding={1.2}>
            <FormsDataGrid
              rows={data || []}
              columns={columns}
              loading={isLoading}
              getRowId={(row) => row.idInvoices}
              height={380}
              showQuickFilter
              toolbar
              checkboxSelection
              disableSelectionOnClick
              onRowSelectionModelChange={handleRowSelectionModelChange}
              rowSelectionModel={selectedRows}
            />
            <ButtonGroup
              onClickClose={handleCloseCheck}
              onClickSave={handleOpenConfirmModal}
              saveLabel={t("modules.buttons.add_selected")}
              backgroundColor={"#43a047"}
              backgroundColorHover={"#388e3c"}
              disabled={selectedRows.length === 0}
            />
          </Box>
        }
      />
      <ConfirmationInfoModal
        open={confirmModal}
        handleClose={() => {
          setConfirmModal(false);
        }}
        onClick={() => {
          addInvoice();
        }}
        description={`${t(
          "info_modules.client_purchase.confirmation.add_item"
        )} #(${clientPurchaseId})?`}
        isPending={add}
        color="error"
      />
    </>
  );
};

export default AddInvoices;
