import FormsDataGrid from "../../../../../DataGrid/FormsDataGrid";

const SelectInvoices = ({
  rows,
  columns,
  selectedRows,
  setSelectedRows,
  setSelectedData,
}) => {
  const handleRowSelectionModelChange = (newSelectedRows) => {
    const selectedData = rows.filter((row) =>
      newSelectedRows.includes(row.InvoiceNo)
    );
    setSelectedData(selectedData);
    setSelectedRows(newSelectedRows);
  };

  return (
    <FormsDataGrid
      rows={rows || []}
      columns={columns}
      getRowId={(row) => row.InvoiceNo}
      height={380}
      showQuickFilter
      toolbar
      checkboxSelection
      disableSelectionOnClick
      onRowSelectionModelChange={handleRowSelectionModelChange}
      rowSelectionModel={selectedRows}
    />
  );
};

export default SelectInvoices;
