import { useSelector } from "react-redux";
import {
  Link,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
} from "@mui/material";

import { Dashboard } from "@mui/icons-material";
import DropDown from "./DropDown";
import { NavLink as RouterLink } from "react-router-dom";

const SidebarMenu = ({ searchQuery }) => {
  const { language } = useSelector((state) => state.theme); // AR | EN
  const userPriv = useSelector((state) => state.auth.userPriv);

  const loweredSearchQuery = searchQuery.toLowerCase();

  const doesModuleExist = (item) =>
    item.ModulesCategoryName.toLowerCase().includes(loweredSearchQuery) ||
    item.ModulesCategoryNameAR.toLowerCase().includes(loweredSearchQuery) ||
    item.SystemModuleNameAR.split(", ").find((el) =>
      el.toLowerCase().includes(loweredSearchQuery)
    ) ||
    item.SystemModuleName.split(", ").find((el) =>
      el.toLowerCase().includes(loweredSearchQuery)
    );

  return (
    <List sx={{ overflowY: "auto" }}>
      <ListItem disablePadding sx={{ display: "block" }}>
        <Link
          component={RouterLink}
          to="/"
          underline="none"
          sx={{
            color: "text.primary",
            "&.active > div": {
              backgroundColor: "#1790ff24",
              borderRight: "2px solid",
              borderRightColor: "#1790ff",
            },
          }}
        >
          <ListItemButton
            sx={{
              minHeight: 48,
              justifyContent: "initial",
              px: 2.5,
              "&:hover": {
                backgroundColor: "background.sidebarMenuHover",
              },
            }}
          >
            <ListItemIcon
              sx={{
                minWidth: 0,
                mr: 1,
                justifyContent: "center",
              }}
            >
              <Dashboard />
            </ListItemIcon>
            <ListItemText
              primary={language === "EN" ? "Dashboard" : "الرئيسية"}
              sx={{
                opacity: 1,
              }}
            />
          </ListItemButton>
        </Link>
      </ListItem>
      {userPriv.filter(doesModuleExist).map((item) => (
        <ListItem
          key={item.ModulesCategoryName}
          disablePadding
          sx={{ display: "block" }}
        >
          <DropDown item={item} searchQuery={searchQuery} />
        </ListItem>
      ))}
    </List>
  );
};

export default SidebarMenu;
