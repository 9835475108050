import Axios from "../../../../network/Axios";
import { Box, Stepper, Step, StepLabel, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useQuery } from "@tanstack/react-query";
import { useState } from "react";
import InfoModal from "../../../../components/Modals/InfoModal";
import SelectItem from "../../../../components/forms/MaterialRequests/Steps/SelectItem";
import ModifyItem from "../../../../components/forms/MaterialRequests/Steps/ModifyItem";
import StepperButton from "../../../../components/forms/ButtonGroup/StepperButton";
import FormSpinner from "../../../../components/Spinners/FormSpinner";
import ConfirmationInfoModal from "../../../../components/Modals/ConfirmationInfoModal";

const AddMaterial = ({
  open,
  newRows,
  setNewRows,
  selectedItems,
  setSelectedItems,
  activeStep,
  setActiveStep,
  addItems,
  isPending,
  handleCloseSave,
  message,
  setMessage,
  applyRowClassName,
  setApplyRowClassName,
  materialRequestInfo,
  title,
}) => {
  const [isRowSelected, setIsRowSelected] = useState(false);
  const [confirmModal, setConfirmModal] = useState(false);
  const [t] = useTranslation("global");

  const getRowClassName = (params) => {
    const { idStoreItemsTemplateInfo } = params.row;
    const isInvalidRow = newRows.find(
      (row) =>
        row.idStoreItemsTemplateInfo === idStoreItemsTemplateInfo &&
        (row.Quantity <= 0 || row.Quantity === "" || !row.Quantity)
    );

    return applyRowClassName && isInvalidRow ? "read-only-row" : "";
  };

  const { data: rows, isLoading } = useQuery({
    queryKey: ["materialRequests", "new"],
    queryFn: async () => {
      const response = await Axios.get("/MaterialRequests", {
        params: {
          State: "New",
        },
      });
      return response.data.result;
    },
    enabled: open,
  });
  const handleRowSelection = (selectedItems) => {
    setIsRowSelected(selectedItems.length > 0);
  };

  const handleDeleteRow = (id) => {
    setNewRows((prevRows) =>
      prevRows.filter((row) => row.idStoreItemsTemplateInfo !== id)
    );
    setSelectedItems((prevSelectedItems) =>
      prevSelectedItems.filter((selectedId) => selectedId !== id)
    );
  };
  const handleNext = () => {
    if (activeStep === 0) {
      const selectedData = rows.filter((row) =>
        selectedItems.includes(row.idStoreItemsTemplateInfo)
      );
      const hasDuplicateItem = selectedData.some((selectedRow) =>
        materialRequestInfo?.ItemsDetailed.some(
          (item) =>
            item.StoreItemsTemplateCode === selectedRow.StoreItemsTemplateCode
        )
      );
      if (hasDuplicateItem) {
        setMessage("Duplicate item found. Please select a different item.");
        return;
      } else {
        setNewRows(selectedData);
        setMessage(null);
      }
    }
    if (activeStep === 1) {
      if (
        newRows.find(
          (row) => row.Quantity <= 0 || row.Quantity === "" || !row.Quantity
        )
      ) {
        setApplyRowClassName(true);
        setMessage("Quantity must be greater than zero ");
        return;
      } else {
        setApplyRowClassName(false);
        setConfirmModal(true);
        setMessage(null);
      }
      return;
    }

    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const steps = [
    t("modules.material_request.steps.select_item"),
    t("modules.material_request.steps.modify_item"),
  ];

  const views = {
    0: (
      <SelectItem
        onRowSelection={handleRowSelection}
        rows={rows || []}
        loading={isLoading}
        selectedRows={selectedItems}
        setSelectedRows={setSelectedItems}
        getRowClassName={(params) =>
          materialRequestInfo?.ItemsDetailed.some(
            (item) =>
              item.StoreItemsTemplateCode === params.row.StoreItemsTemplateCode
          )
            ? "read-only-row"
            : ""
        }
      />
    ),
    1: (
      <ModifyItem
        rows={newRows}
        onClick={handleDeleteRow}
        setNewRows={setNewRows}
        setMessage={setMessage}
        getRowClassName={getRowClassName}
      />
    ),
  };

  return (
    <InfoModal
      open={open}
      handleClose={() => {
        handleCloseSave();
      }}
      width="65rem"
      title={title}
      content={
        <>
          {isLoading ? (
            <FormSpinner />
          ) : (
            <Box margin="10px">
              <Stepper activeStep={activeStep}>
                {steps.map((label) => {
                  return (
                    <Step key={label}>
                      <StepLabel>{label}</StepLabel>
                    </Step>
                  );
                })}
              </Stepper>

              {views[activeStep]}
              <Box
                display="flex"
                alignItems="center"
                justifyContent={message !== null ? "space-between" : "flex-end"}
              >
                {message && (
                  <Typography
                    color="error"
                    fontSize="16px"
                    fontWeight={500}
                    paddingLeft="10px"
                  >
                    {message}
                  </Typography>
                )}
                <StepperButton
                  isPending={isPending}
                  onClick={handleNext}
                  handleBack={handleBack}
                  activeStep={activeStep}
                  disabled={!isRowSelected}
                  label={
                    activeStep === 1
                      ? t("modules.buttons.save")
                      : t("modules.buttons.next")
                  }
                  isLastStep={activeStep === 1}
                />
              </Box>
            </Box>
          )}
          <ConfirmationInfoModal
            open={confirmModal}
            handleClose={() => {
              setConfirmModal(false);
            }}
            onClick={() => {
              addItems();
            }}
            description={t(
              "info_modules.material_request.confirmation.add_item"
            )}
            isPending={isPending}
            color="error"
          />
        </>
      }
    />
  );
};

export default AddMaterial;
