import * as React from "react";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";

export default function BasicDatePicker({
  dateChange,
  width,
  label,
  handleDateChange,
  disabled,
}) {
  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <DatePicker
        label={label}
        value={dateChange}
        onChange={handleDateChange}
        disabled={disabled}
        format="YYYY/MM/DD"
        slotProps={{
          textField: {
            size: "small",
          },
        }}
      />
    </LocalizationProvider>
  );
}
