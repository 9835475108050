import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import CustomDataGrid from "../../components/DataGrid/CustomDataGrid";
import { useTranslation } from "react-i18next";
import { Tooltip } from "@mui/material";
import DateFormatGrid from "../../components/DateFormatGrid";
import { statusColors } from "../statuses";
import Spinner from "../../components/Spinners/Spinner";
import dayjs from "dayjs";
import { useSelector } from "react-redux";
import Send from "@mui/icons-material/Send";
import { GridActionsCellItem } from "@mui/x-data-grid-pro";
import ChipStatus from "../../components/ChipStatus";
import { MailServiceService } from "../../api/mailService";
import { Link } from "react-router-dom";
import { useSnackbar } from "notistack";
import { useState } from "react";
import { SettingsModal } from "./SettingsModal";
import InfoModal from "../../components/Modals/InfoModal";

const MailService = () => {
  const queryClient = useQueryClient();
  const { enqueueSnackbar } = useSnackbar();

  const { mailServiceStartDate, mailServiceEndDate } = useSelector(
    (state) => state.dateRangeMailService
  );

  const [t] = useTranslation("global");

  const statusNames = [
    {
      name: "Success",
      displayName: t("data_grid.tabs.success"),
    },
    { name: "Failed", displayName: t("data_grid.tabs.failed") },
  ];

  const columns = [
    {
      field: "messageSubject",
      headerName: t("data_grid.headers.message_subject"),
      width: 130,
      renderCell: (params) => {
        return (
          <Link
            style={{
              color: "#1790FF",
            }}
            to={`/mailservice/${params.row.messageSubject}?id=${params.row.idMailServiceMails}`}
          >
            {params.value}
          </Link>
        );
      },
    },
    {
      field: "messageFrom",
      headerName: t("data_grid.headers.message_from"),
      width: 140,
      flex: 1,
    },
    {
      field: "messageTo",
      headerName: t("data_grid.headers.message_to"),
      width: 140,
      flex: 1,
    },
    {
      field: "messageCC",
      headerName: t("data_grid.headers.message_cc"),
      width: 140,
      flex: 1,
    },
    {
      field: "messageBCC",
      headerName: t("data_grid.headers.message_bcc"),
      width: 140,
      flex: 1,
    },
    {
      field: "messageResponse",
      headerName: t("data_grid.headers.message_response"),
      width: 140,
      flex: 1,
    },

    {
      field: "messageAccepted",
      headerName: t("data_grid.headers.message_accepted"),
      width: 140,
      flex: 1,
    },
    {
      field: "messageTime",
      headerName: t("data_grid.headers.message_time"),
      width: 140,
      flex: 1,
      type: "date",
      valueGetter: ({ value }) => (value ? new Date(value) : null),
      renderCell: ({ value }) => {
        return <> {value ? <DateFormatGrid value={value} /> : <> </>}</>;
      },
    },
    {
      field: "messageRejected",
      headerName: t("data_grid.headers.message_rejected"),
      width: 140,
      flex: 1,
    },
    {
      field: "messageError",
      headerName: t("data_grid.headers.message_error"),
      width: 140,
      flex: 1,
    },
    {
      field: "messageState",
      headerName: t("data_grid.headers.message_state"),
      headerAlign: "center",
      width: 140,
      renderCell: ({ value }) => {
        return <ChipStatus value={value} />;
      },
    },
    {
      field: "messageStatus",
      headerName: t("data_grid.headers.message_status"),
      headerAlign: "center",
      width: 140,
      renderCell: ({ value }) => {
        return <ChipStatus value={value} />;
      },
    },
    {
      field: "Actions",
      type: "actions",
      headerName: t("data_grid.headers.actions"),
      getActions: (params) => [
        <Tooltip title={t("tooltip.send")} key="resend">
          <GridActionsCellItem
            icon={<Send />}
            label="Resend"
            onClick={() => resendMail(params.row.idMailServiceMails)}
            disabled={params.row.messageStatus === "Success"}
          />
        </Tooltip>,
      ],
    },
  ];

  const { mutateAsync: resendMail } = useMutation({
    mutationFn: MailServiceService.resendMail,
    onSuccess: () => {
      enqueueSnackbar("Added Successfully", { variant: "success" });
      queryClient.invalidateQueries({ queryKey: ["mailService"] });
    },
  });

  const {
    data: rows,
    isLoading,
    refetch,
    isRefetching,
  } = useQuery({
    queryKey: ["mailService"],
    queryFn: () =>
      MailServiceService.getMailService(
        dayjs(mailServiceStartDate).format("YYYY-MM-DD"),
        dayjs(mailServiceEndDate).format("YYYY-MM-DD")
      ),
  });

  // Handling settings modal
  const [openSettingsModal, setOpenSettingsModal] = useState(false);

  const handleOpenSettingsModal = () => setOpenSettingsModal(true);
  const handleCloseSettingsModal = () => setOpenSettingsModal(false);

  if (isLoading) return <Spinner />;

  return (
    <>
      <CustomDataGrid
        rows={rows}
        columns={columns}
        loading={isLoading}
        getRowId={(row) => row.idMailServiceMails}
        statusColors={statusColors}
        statusNames={statusNames}
        filterField="messageStatus"
        showDatePicker
        showReloadOverlay={isRefetching}
        reloadFunction={refetch}
        mailServiceDate
        settingsBtn
        settingsFunction={handleOpenSettingsModal}
      />
      <InfoModal
        open={openSettingsModal}
        handleClose={handleCloseSettingsModal}
        title={t("modules.mail_service.settings_form.title")}
        content={<SettingsModal onClose={handleCloseSettingsModal} />}
        width="30rem"
      />
    </>
  );
};

export default MailService;
