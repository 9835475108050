import { useTranslation } from "react-i18next";
import Spinner from "../../../Spinners/Spinner";
import { useQuery } from "@tanstack/react-query";
import { usersManagementService } from "../../../../api/usersManagement";
import { Stack, Typography } from "@mui/material";
import Checkbox from "@mui/material/Checkbox";
import FormsDataGrid from "../../../DataGrid/FormsDataGrid";

const MailService = ({
  mailService,
  setValue,
  errors,
  mailServiceSelectionModel,
  dispatch,
}) => {
  const [t] = useTranslation("global");

  const columns = [
    {
      field: "MailServicesName",
      headerName: t("data_grid.headers.mail_services_name"),
      width: 120,
      flex: 1,
    },
    {
      field: "BCC",
      type: "actions",
      headerName: "BCC",
      width: 120,
      flex: 1,
      renderCell: (params) => {
        return (
          <Checkbox
            {...params}
            disabled={!mailServiceSelectionModel.includes(params.id)}
            checked={
              mailService.map((service) => service.id).includes(params.id)
                ? mailService.find((service) => service.id === params.id).BCC
                : false
            }
            onChange={(e, checked) => {
              setValue(
                "mailService",
                mailService.map((service) =>
                  service.id === params.id
                    ? { ...service, BCC: checked }
                    : service
                )
              );
            }}
          />
        );
      },
    },

    {
      field: "CC",
      type: "actions",
      headerName: "CC",
      width: 120,
      flex: 1,
      renderCell: (params) => {
        return (
          <Checkbox
            {...params}
            disabled={!mailServiceSelectionModel.includes(params.id)}
            checked={
              mailService.map((service) => service.id).includes(params.id)
                ? mailService.find((service) => service.id === params.id).CC
                : false
            }
            onChange={(e, checked) => {
              setValue(
                "mailService",
                mailService.map((service) =>
                  service.id === params.id
                    ? { ...service, CC: checked }
                    : service
                )
              );
            }}
          />
        );
      },
    },
    {
      field: "TO",
      type: "actions",
      headerName: "TO",
      width: 120,
      flex: 1,
      renderCell: (params) => {
        return (
          <Checkbox
            {...params}
            disabled={!mailServiceSelectionModel.includes(params.id)}
            checked={
              mailService.map((service) => service.id).includes(params.id)
                ? mailService.find((service) => service.id === params.id).TO
                : false
            }
            onChange={(e, checked) => {
              setValue(
                "mailService",
                mailService.map((service) =>
                  service.id === params.id
                    ? { ...service, TO: checked }
                    : service
                )
              );
            }}
          />
        );
      },
    },
  ];

  const { data, isLoading } = useQuery({
    queryKey: ["usersManagementFormData"],
    queryFn: usersManagementService.GetUsersManagementFormData,
  });

  function findOutlier(arr1, arr2) {
    for (let num of arr1) {
      if (!arr2.includes(num)) {
        return num;
      }
    }

    for (let num of arr2) {
      if (!arr1.includes(num)) {
        return num;
      }
    }

    return null; // If no outlier found
  }

  const handleRowSelectionModelChange = (newSelectedRows) => {
    dispatch({ type: "mailServiceSelectionModel", payload: newSelectedRows });
    if (newSelectedRows.length > mailService.length) {
      setValue("mailService", [
        ...mailService,
        {
          id: newSelectedRows[newSelectedRows.length - 1],
          MailServicesName: data.mailservices.find(
            (service) =>
              service.idMailServices ===
              newSelectedRows[newSelectedRows.length - 1]
          ).MailServicesName,
          BCC: false,
          CC: false,
          TO: true,
        },
      ]);
    } else if (newSelectedRows.length < mailService.length) {
      const deletedRow = findOutlier(
        mailService.map((service) => service.id),
        newSelectedRows
      );
      setValue(
        "mailService",
        mailService.filter((service) => service.id !== deletedRow)
      );
    }
  };

  if (isLoading) return <Spinner />;

  return (
    <Stack spacing={2}>
      {errors.mailService && (
        <Typography
          color="red"
          sx={{
            backgroundColor: "#ffdfdf",
            p: 1,
            borderRadius: "5px",
            fontSize: "14px",
          }}
        >
          {errors.mailService?.message}
        </Typography>
      )}
      <FormsDataGrid
        rows={data?.mailservices || []}
        columns={columns}
        getRowId={(row) => row.idMailServices}
        disableColumnMenu
        checkboxSelection
        disableMultipleRowSelection
        onRowSelectionModelChange={handleRowSelectionModelChange}
        rowSelectionModel={mailServiceSelectionModel}
        disableRowSelectionOnClick
      />
    </Stack>
  );
};

export default MailService;
