import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { WorkOrdersService } from "../../../api/workOrders";
import { useTranslation } from "react-i18next";
import { useSnackbar } from "notistack";
import FormSpinner from "../../Spinners/FormSpinner";
import {
  Autocomplete,
  Box,
  Button,
  Link,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import * as yup from "yup";
import useHandleForms from "../../../hooks/useHandleForms";
import dayjs from "dayjs";
import ButtonGroup from "../ButtonGroup/ButtonGroup";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { useFormik } from "formik";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { useState } from "react";
import SelectSite from "./Modals/SelectSite";
import InfoModal from "../../Modals/InfoModal";
import ChipStatus from "../../ChipStatus";
import FormsDataGrid from "../../DataGrid/FormsDataGrid";
import { LoadingButton } from "@mui/lab";
import { Link as RouterLink } from "react-router-dom";
import ReportProblemIcon from "@mui/icons-material/ReportProblem";
import { useSelector } from "react-redux";
import { useConfirmationDialog } from "../../../contexts/ConfirmationDialogContext";

const WorkOrdersForm = ({
  onClickClose,
  onDirtyChange,
  handleCloseFromAnotherForm,
  siteDetails,
}) => {
  const { data, isLoading } = useQuery({
    queryKey: ["workOrderFormData"],
    queryFn: WorkOrdersService.getNewWorkOrderFormData,
  });

  if (isLoading) return <FormSpinner />;

  return (
    <WorkOrdersFormData
      onClickClose={onClickClose}
      onDirtyChange={onDirtyChange}
      data={data}
      handleCloseFromAnotherForm={handleCloseFromAnotherForm}
      siteDetails={siteDetails}
    />
  );
};

const WorkOrdersFormData = ({
  onClickClose,
  onDirtyChange,
  data,
  handleCloseFromAnotherForm,
  siteDetails,
}) => {
  const queryClient = useQueryClient();
  const [t] = useTranslation("global");
  const { enqueueSnackbar } = useSnackbar();

  const theme = useSelector((state) => state.theme.colorTheme);

  // Check if the work order form is opened from another form
  const isFromAnotherForm = typeof handleCloseFromAnotherForm === "function";

  const { WorkOrderNo, CompanyProjects, Requestors, TeamLeaders } = data;

  const schema = yup.object().shape({
    date: yup.date().required(t("modules.global_schema.required")),
    site: yup.string().required(t("modules.global_schema.required")),
    candidate: yup.string().required(t("modules.global_schema.required")),
    phase: yup.string().required(t("modules.global_schema.required")),
    project: yup.string().required(t("modules.global_schema.select_value")),
    sub_project: yup.string().required(t("modules.global_schema.select_value")),
    requestor: yup.string().required(t("modules.global_schema.select_value")),
    team_leader: yup.string().required(t("modules.global_schema.select_value")),
    description: yup.string().required(t("modules.global_schema.required")),
  });

  const handleFormSubmit = (values) => {
    const {
      date,
      candidate,
      phase,
      project,
      sub_project,
      requestor,
      team_leader,
      description,
    } = values;

    const newDate = dayjs(date).format("YYYY-MM-DD");

    AddNewWorkOrder({
      WorkOrderNo: WorkOrderNo,
      WorkOrderDate: newDate,
      idSitesDatabase: siteId[0],
      WorkOrderCandidate: candidate,
      WorkOrderPhase: phase,
      ProjectName: project,
      SubProjectName: sub_project,
      RequestorName: requestor,
      TeamLeaderName: team_leader,
      WorkOrderDiscription: description,
    });
  };

  const { mutateAsync: AddNewWorkOrder, isPending } = useMutation({
    mutationFn: WorkOrdersService.addNewWorkOrder,
    onSuccess: (data) => {
      if (isFromAnotherForm) {
        handleCloseFromAnotherForm();
      } else {
        handleClose();
      }

      enqueueSnackbar(data.msg, { variant: "success" });
      queryClient.invalidateQueries({
        predicate: (query) => {
          return ["workOrders", "siteInfo"].includes(query.queryKey[0]);
        },
      });
    },
  });

  const initialValues = {
    date: dayjs(),
    site: isFromAnotherForm ? siteDetails.SiteName : "",
    candidate: isFromAnotherForm ? siteDetails.Candidate : "",
    phase: isFromAnotherForm ? siteDetails.Phase : "",
    project: null,
    sub_project: null,
    requestor: null,
    team_leader: null,
    description: "",
  };

  const {
    values,
    errors,
    touched,
    handleBlur,
    handleChange,
    handleSubmit,
    setFieldValue,
    dirty,
    validateForm,
    setErrors,
    setTouched,
  } = useFormik({
    initialValues,
    validationSchema: schema,
    onSubmit: handleFormSubmit,
    validateOnChange: true,
  });

  const {
    data: subProjectsData,
    isLoading: isLoadingSubProjects,
    isRefetching: isRefetchingSubProjects,
  } = useQuery({
    queryKey: ["newWorkOrdersSubProjects", values.project],
    queryFn: () =>
      WorkOrdersService.getNewWorkOrdersSubProjects(values.project),
    enabled: Boolean(values.project),
  });

  const { handleClose } = useHandleForms({ dirty, onDirtyChange });

  // Handling site selection modal
  const [openSiteModal, setOpenSiteModal] = useState(false);

  const handleOpenSiteModal = () => {
    setOpenSiteModal(true);
  };

  // site id
  const [siteId, setSiteId] = useState(
    typeof handleCloseFromAnotherForm === "function"
      ? [siteDetails.idSitesDatabase]
      : []
  );

  const handleSiteIdChange = ({ siteId, candidate, phase }) => {
    setSiteId(siteId);
    setFieldValue("candidate", candidate);
    setFieldValue("phase", phase);
  };

  const handleCloseSiteModal = () => {
    setOpenSiteModal(false);
  };

  // site name
  const handleSiteNameChange = (value) => {
    setFieldValue("site", value);
  };

  const projects = CompanyProjects.map(
    (project) => project.CompanyProjectsName
  );
  const subProjects = subProjectsData?.map(
    (project) => project.SubProjectsName
  );
  const requestors = Requestors.map((requestor) => requestor.RequestorsName);
  const teamLeaders = TeamLeaders.map(
    (teamLeader) => teamLeader.TeamLeadersName
  );

  const { data: workOrdersBySite } = useQuery({
    queryKey: ["workOrdersBySite", siteId],
    queryFn: () => WorkOrdersService.getWorkOrdersBySite(siteId[0]),
    enabled: !isFromAnotherForm && siteId.length > 0,
  });

  const [isWorkOrdersBySiteModalOpen, setIsWorkOrdersBySiteModalOpen] =
    useState(false);

  const handleOpenWorkOrdersBySiteModal = () =>
    setIsWorkOrdersBySiteModalOpen(true);

  const handleCloseWorkOrdersBySiteModal = () =>
    setIsWorkOrdersBySiteModalOpen(false);

  const { mutateAsync: cancelWorkOrder, isPending: isPendingCancelWorkOrder } =
    useMutation({
      mutationFn: WorkOrdersService.patchWorkOrder,
      onSuccess: (data) => {
        enqueueSnackbar(data.msg, {
          variant: "success",
        });
        queryClient.invalidateQueries({
          queryKey: ["workOrdersBySite"],
        });
      },
    });

  const { setOpen } = useConfirmationDialog();

  const columns = [
    {
      field: "WorkOrderNo",
      headerName: t("data_grid.headers.ref_no"),
      width: 150,
      renderCell: ({ value }) => {
        return (
          <Link
            to={`/workorders/${value}`}
            target="_blank"
            component={RouterLink}
            sx={{
              color: "primary.main",
            }}
          >
            {value}
          </Link>
        );
      },
    },
    {
      field: "WorkOrderDiscription",
      headerName: t("data_grid.headers.description"),
      flex: 1,
    },
    {
      field: "SubProjectsName",
      headerName: t("data_grid.headers.sub_project"),
      width: 200,
    },
    {
      field: "ERPUserNickName",
      headerName: t("data_grid.headers.created_by"),
    },
    {
      field: "WorkOrderStatus",
      headerName: t("data_grid.headers.status"),
      width: 185,
      renderCell: ({ value }) => {
        return <ChipStatus value={value} />;
      },
    },
    {
      field: "Action",
      headerName: t("data_grid.headers.actions"),
      renderCell: ({ row }) => {
        return (
          <LoadingButton
            size="small"
            variant="contained"
            color="error"
            disabled={
              isPendingCancelWorkOrder || row.WorkOrderStatus !== "Pending"
            }
            onClick={() =>
              setOpen(
                t("info_modules.work_orders.confirmation.cancel"),
                () => () => {
                  cancelWorkOrder({
                    SelectedNo: row.WorkOrderNo,
                    Status: "Cancelled",
                  });
                },
                "success",
                "error",
                t("modules.buttons.no")
              )
            }
            sx={{
              textTransform: "capitalize",
              p: 0.25,
              borderRadius: 3,
            }}
          >
            {t("modules.buttons.cancel")}
          </LoadingButton>
        );
      },
    },
  ];

  return (
    <>
      <Box component="form" noValidate onSubmit={(e) => e.preventDefault()}>
        <Box
          sx={{
            display: "grid",
            gridTemplateColumns: "1fr 1fr",
            gap: "16px",
            padding: 1,
          }}
        >
          <TextField
            disabled
            label={t("modules.work_orders.fields.work_order_no")}
            size="small"
            name="WorkOrderNo"
            value={WorkOrderNo}
          />

          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DatePicker
              label={t("modules.work_orders.fields.work_order_date")}
              views={["year", "month", "day"]}
              format="YYYY/MM/DD"
              name="date"
              value={values.date}
              onChange={(date) => {
                setFieldValue("date", date);
              }}
              slotProps={{
                textField: {
                  size: "small",
                  error: Boolean(errors.date),
                  helperText: errors.date?.message,
                  required: true,
                  onBlur: handleBlur,
                },
              }}
            />
          </LocalizationProvider>

          <Stack
            direction="row"
            spacing={2}
            alignItems="center"
            sx={{
              gridColumn: "span 2",
            }}
          >
            <Button
              variant="contained"
              onClick={handleOpenSiteModal}
              disabled={isFromAnotherForm}
            >
              {t("modules.work_orders.buttons.select_site")}
            </Button>

            {values.site && (
              <Typography variant="body2">{values.site}</Typography>
            )}

            {errors.site && (
              <Typography color="error" variant="caption">
                {errors.site}
              </Typography>
            )}
          </Stack>

          <TextField
            label={t("modules.work_orders.fields.candidate")}
            size="small"
            name="candidate"
            value={values.candidate}
            onBlur={handleBlur}
            onChange={handleChange("candidate")}
            error={!!touched.candidate && !!errors.candidate}
            helperText={!!touched.candidate && errors.candidate}
            required
          />

          <TextField
            label={t("modules.work_orders.fields.phase")}
            size="small"
            name="phase"
            value={values.phase}
            onBlur={handleBlur}
            onChange={handleChange("phase")}
            error={!!touched.phase && !!errors.phase}
            helperText={!!touched.phase && errors.phase}
            required
          />

          <Autocomplete
            disableClearable
            options={projects || []}
            sx={{
              gridColumn: "span 2",
            }}
            size="small"
            onChange={(_, newValue) => {
              setFieldValue("project", newValue);
              setFieldValue("sub_project", null);
            }}
            value={values.project}
            renderInput={(params) => (
              <TextField
                {...params}
                label={t("modules.work_orders.fields.project")}
                onBlur={handleBlur}
                error={!!touched.project && !!errors.project}
                helperText={touched.project && errors.project}
                required
              />
            )}
          />

          <Autocomplete
            disabled={isLoadingSubProjects || isRefetchingSubProjects}
            disableClearable
            options={subProjects || []}
            sx={{
              gridColumn: "span 2",
            }}
            size="small"
            onChange={(_, newValue) => {
              setFieldValue("sub_project", newValue);
            }}
            value={values.sub_project}
            renderInput={(params) => (
              <TextField
                {...params}
                label={t("modules.work_orders.fields.sub_project")}
                onBlur={handleBlur}
                error={!!touched.sub_project && !!errors.sub_project}
                helperText={touched.sub_project && errors.sub_project}
                required
              />
            )}
          />

          <Autocomplete
            disableClearable
            options={requestors || []}
            size="small"
            onChange={(_, newValue) => {
              setFieldValue("requestor", newValue);
            }}
            value={values.requestor}
            renderInput={(params) => (
              <TextField
                {...params}
                label={t("modules.work_orders.fields.requestor")}
                onBlur={handleBlur}
                error={!!touched.requestor && !!errors.requestor}
                helperText={touched.requestor && errors.requestor}
                required
              />
            )}
          />

          <Autocomplete
            disableClearable
            options={teamLeaders || []}
            size="small"
            onChange={(_, newValue) => {
              setFieldValue("team_leader", newValue);
            }}
            value={values.team_leader}
            renderInput={(params) => (
              <TextField
                {...params}
                label={t("modules.work_orders.fields.team_leader")}
                onBlur={handleBlur}
                error={!!touched.team_leader && !!errors.team_leader}
                helperText={touched.team_leader && errors.team_leader}
                required
              />
            )}
          />

          <TextField
            label={t("modules.work_orders.fields.description")}
            size="small"
            sx={{
              gridColumn: "span 2",
            }}
            name="description"
            value={values.description}
            onBlur={handleBlur}
            onChange={handleChange("description")}
            error={!!touched.description && !!errors.description}
            helperText={!!touched.description && errors.description}
            multiline
            rows={4}
            required
          />
        </Box>
        <ButtonGroup
          onClickSave={() => {
            validateForm().then((errors) => {
              if (Object.keys(errors).length === 0) {
                workOrdersBySite?.length > 0
                  ? handleOpenWorkOrdersBySiteModal()
                  : handleSubmit();
              } else {
                setTouched({
                  date: errors.date,
                  site: errors.site,
                  candidate: errors.candidate,
                  phase: errors.phase,
                  project: errors.project,
                  sub_project: errors.sub_project,
                  requestor: errors.requestor,
                  team_leader: errors.team_leader,
                  description: errors.description,
                });
                setErrors(errors);
              }
            });
          }}
          saveLabel={t("modules.buttons.save")}
          cancelLabel={t("modules.buttons.cancel")}
          onClickClose={onClickClose}
          isPending={isPending}
        />
      </Box>
      <InfoModal
        open={openSiteModal}
        handleClose={handleCloseSiteModal}
        title={t("modules.work_orders.modals.site.title")}
        content={
          <SelectSite
            setValue={handleSiteIdChange}
            siteId={siteId}
            handleClose={handleCloseSiteModal}
            handleSiteNameChange={handleSiteNameChange}
          />
        }
      />
      <InfoModal
        open={isWorkOrdersBySiteModalOpen}
        handleClose={handleCloseWorkOrdersBySiteModal}
        title={t("modules.work_orders.modals.existing_work_orders.title")}
        topBarIcon={
          <ReportProblemIcon
            sx={{
              color: "#fff",
            }}
          />
        }
        topBarBackgroundColor="error.main"
        content={
          <Stack spacing={2}>
            <Stack
              spacing={2}
              sx={{
                padding: 2,
              }}
            >
              <Typography>
                {t(
                  "modules.work_orders.modals.existing_work_orders.labels.first_subHeadline_first_part"
                )}
                <span style={{ fontWeight: 700 }}>{values.site}</span>{" "}
                {t(
                  "modules.work_orders.modals.existing_work_orders.labels.first_subHeadline_second_part"
                )}
              </Typography>

              <FormsDataGrid
                rows={workOrdersBySite}
                columns={columns}
                getRowId={(row) => row.WorkOrderNo}
                height={310}
                hideFooter
                disableMultipleRowSelection
              />
            </Stack>
            <Stack
              direction="row"
              spacing={4}
              alignItems="center"
              sx={{
                alignSelf: "center",
                backgroundColor: theme === "light" ? "#ffdada" : "#ff80804d",
                borderTop: `2px dashed ${
                  theme === "light" ? "#ff9696" : "#ff6a6a"
                }`,
                width: "100%",
                justifyContent: "center",
                padding: "1rem",
              }}
            >
              <Typography
                sx={{
                  fontWeight: 500,
                  fontStyle: "italic",
                  color: "error.main",
                }}
              >
                {t(
                  "modules.work_orders.modals.existing_work_orders.labels.confirm_continue"
                )}
              </Typography>
              <Stack direction="row" spacing={2} alignItems="center">
                <LoadingButton
                  variant="contained"
                  color="success"
                  sx={{
                    color: "white",
                    textTransform: "capitalize",
                  }}
                  onClick={handleSubmit}
                  loading={isPending}
                >
                  {t("buttons.continue")}
                </LoadingButton>
                <Button
                  variant="contained"
                  color="error"
                  sx={{
                    color: "white",
                    textTransform: "capitalize",
                  }}
                  onClick={handleCloseWorkOrdersBySiteModal}
                >
                  {t("modules.buttons.cancel")}
                </Button>
              </Stack>
            </Stack>
          </Stack>
        }
        width="70rem"
      />
    </>
  );
};

export default WorkOrdersForm;
