import { Box, Typography } from "@mui/material";
import { keyframes } from "@emotion/react";
import { useSelector } from "react-redux";

const spin = keyframes`
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
    `;

const waveAnimation2 = keyframes`
     0% {
    border-top-right-radius: 70%;
    border-bottom-left-radius: 80%;
  }
  15% {
    border-top-left-radius: 60%;
    border-bottom-right-radius: 60%;
  }
  30% {
    border-bottom-left-radius: 50%;
    border-bottom-right-radius: 70%;
  }
  45% {
    border-top-left-radius: 70%;
    border-top-right-radius: 40%;
  }
  60% {
    border-top-left-radius: 40%;
    border-bottom-left-radius: 70%;
  }
  75% {
    border-top-left-radius: 70%;
    border-top-right-radius: 40%;
  }
  100% {
    border-top-right-radius: 80%;
    border-bottom-right-radius: 50%;
  }
    `;
const waveAnimation3 = keyframes`
     0% {
    border-bottom-left-radius: 60%;
    border-top-right-radius: 80%;
  }
  15% {
    border-top-right-radius: 80%;
    border-bottom-left-radius: 70%;
  }
  30% {
    border-top-left-radius: 80%;
    border-top-right-radius: 50%;
  }
  45% {
    border-top-right-radius: 60%;
    border-bottom-right-radius: 50%;
  }
  60% {
    border-bottom-left-radius: 70%;
    border-bottom-right-radius: 50%;
  }
  75% {
    border-top-right-radius: 50%;
    border-bottom-right-radius: 60%;
  }
  100% {
    border-bottom-right-radius: 70%;
    border-top-right-radius: 40%;
  }
    `;
const waveAnimation4 = keyframes`
      0% {
    border-bottom-right-radius: 50%;
    border-top-right-radius: 70%;
  }
  15% {
    border-top-right-radius: 80%;
    border-bottom-right-radius: 50%;
  }
  30% {
    border-bottom-left-radius: 70%;
    border-bottom-right-radius: 50%;
  }
  45% {
    border-top-right-radius: 50%;
    border-bottom-right-radius: 60%;
  }
  60% {
    border-top-left-radius: 80%;
    border-top-right-radius: 50%;
  }
  75% {
    border-top-right-radius: 70%;
    border-bottom-left-radius: 50%;
  }
  100% {
    border-bottom-left-radius: 50%;
    border-top-right-radius: 80%;
  }
    `;

const HollowCircle = () => {
  const theme = useSelector((state) => state.theme.colorTheme);
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        marginTop: "14%",
      }}
    >
      <Box
        sx={{
          width: "180px",
          height: "180px",
          borderRadius: "40%",
          backgroundColor:
            theme === "dark"
              ? "rgba(54, 139, 167, 0.1)"
              : "rgba(54, 139, 167, 0.25)",
          boxShadow: "0 4px 20px rgba(0, 0, 0, 0.2)",
          animation: `${waveAnimation2} 3s infinite, ${spin} 10s linear infinite`,
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Box
          sx={{
            width: "160px",
            height: "160px",
            borderRadius: "50%",
            background:
              theme === "dark"
                ? "linear-gradient(135deg, rgba(50, 124, 149, 0.1), rgba(50, 124, 149, 0.3), rgba(50, 124, 149, 0.4), rgba(50, 124, 149, 0.2))"
                : "linear-gradient(135deg, rgba(88, 179, 210, 0.1), rgba(88, 179, 210, 0.2), rgba(88, 179, 210, 0.3), rgba(88, 179, 210, 0.4))",
            boxShadow: "0 4px 20px rgba(0, 0, 0, 0.2)",
            animation: `${waveAnimation3} 3s infinite, ${spin} 10s linear infinite`,
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Box
            sx={{
              width: "140px",
              height: "140px",
              borderRadius: "50%",
              background:
                theme === "dark"
                  ? "linear-gradient(135deg, rgba(50, 124, 149, 0.3), rgba(50, 124, 149, 0.4), rgba(50, 124, 149, 0.5), rgba(50, 124, 149, 0.6))"
                  : "linear-gradient(135deg, rgba(57, 140, 168, 0.3), rgba(57, 140, 168, 0.4), rgba(57, 140, 168, 0.3), rgba(57, 140, 168, 0.5))",
              boxShadow: "0 4px 20px rgba(0, 0, 0, 0.3)",
              animation: `${waveAnimation4} 3s infinite, ${spin} 10s linear infinite`,
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                width: "110px",
                height: "110px",
                borderRadius: "50%",
                position: "relative",
              }}
            >
              <Box
                sx={{
                  position: "absolute",
                  top: "4px",
                  left: "4px",
                  right: "4px",
                  bottom: "5%",
                  background:
                    theme === "dark"
                      ? "linear-gradient(135deg, rgba(50, 124, 149, 0.4), rgba(87, 179, 209, 0.4), rgba(30, 100, 130, 0.4), rgba(10, 80, 110, 0.4))"
                      : "linear-gradient(135deg, rgba(70, 178, 200, 0.6), rgba(60, 124, 149, 0.6))",
                  borderRadius: "50%",
                  zIndex: 1,
                }}
              >
                <Box
                  sx={{
                    position: "absolute",
                    width: "100%",
                    height: "100%",
                    border: "4px solid transparent",
                    borderTop: "4px solid #9ebdd2",
                    borderRadius: "50%",
                    animation: `${spin} 3s linear infinite`,
                    zIndex: 2,
                    boxShadow: "0px -20px 15px rgba(74, 172, 237, 0.2)",
                  }}
                ></Box>
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>
      <Typography
        style={{
          fontSize: "20px",
          fontWeight: "bold",
          marginTop: "18px",
        }}
      >
        Please Wait...
      </Typography>
    </Box>
  );
};

export default HollowCircle;
