import Axios from "../network/Axios";

// Get sitesDatabase
const getSitesDatabase = async () => {
  const response = await Axios.get("/SitesDatabase");
  return response.data.result;
};

// Get single site data
const getSiteData = async (siteId, State = "Card") => {
  const response = await Axios.get("/SitesDatabase", {
    params: {
      SelectedID: siteId,
      State,
    },
  });
  return response.data;
};

const updateSiteInfo = async (data) => {
  const response = await Axios.put("/SitesDatabase", data);
  return response.data;
};

export const SitesDatabaseService = {
  getSitesDatabase,
  getSiteData,
  updateSiteInfo,
};
