import { useState } from "react";
import dayjs from "dayjs";
import { useSnackbar } from "notistack";
import Axios from "../../../../network/Axios";
import { useTranslation } from "react-i18next";
import { Box, Chip } from "@mui/material";
import ButtonGroup from "../../../../components/forms/ButtonGroup/ButtonGroup";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import InfoModal from "../../../../components/Modals/InfoModal";
import BasicDatePicker from "../../../../components/DateAndTime/DatePicker";

const SubmitModal = ({ open, handleClose, clientInvoiceId }) => {
  const [t] = useTranslation("global");
  const queryClient = useQueryClient();
  const { enqueueSnackbar } = useSnackbar();
  const [date, setDate] = useState(dayjs());

  const { mutateAsync: submit, isPending } = useMutation({
    mutationFn: async () => {
      return Axios.patch("/ClientInvoices", {
        SelectedNo: clientInvoiceId,
        State: "Submit",
        SubmissionDate: date.format("YYYY-MM-DD"),
      });
    },
    onSuccess: (data) => {
      if (!data) return;
      enqueueSnackbar(t("Submitted Successfully"), {
        variant: "success",
      });
      queryClient.invalidateQueries({
        predicate: (query) => {
          return ["clientInvoice", "statementsManagement"].includes(
            query.queryKey[0]
          );
        },
      });

      handleClose();
      setDate(dayjs());
    },
  });

  return (
    <InfoModal
      open={open}
      handleClose={() => {
        handleClose();
        setDate(dayjs());
      }}
      title={t("info_modules.client_invoices.description.invoice_date")}
      width="28rem"
      content={
        <Box padding={1} display="flex" flexDirection="column" gap={1.6}>
          <Chip
            label={clientInvoiceId}
            sx={{ borderRadius: 1, fontSize: "0.9rem" }}
          />
          <BasicDatePicker
            dateChange={date}
            width="27rem"
            handleDateChange={(date) => {
              setDate(date);
            }}
            label={t(
              "info_modules.client_invoices.description.invoice_submit_date"
            )}
          />
          <ButtonGroup
            onClickClose={handleClose}
            isPending={isPending}
            onClickSave={submit}
            saveLabel={t("modules.buttons.save")}
          />
        </Box>
      }
    />
  );
};

export default SubmitModal;
