import { Box } from "@mui/material";

const Save = ({ chart }) => {
  return (
    <Box
      sx={{
        padding: "1rem",
        flexGrow: 1,
      }}
    >
      {chart}
    </Box>
  );
};

export default Save;
