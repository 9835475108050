import Axios from "../network/Axios";

const WORK_ORDERS_PATH = "/WorkOrders";

const getAllWorkOrders = async () => {
  const response = await Axios.get(WORK_ORDERS_PATH);
  return response.data;
};

const getNewWorkOrderFormData = async () => {
  const response = await Axios.get(WORK_ORDERS_PATH, {
    params: {
      State: "New",
    },
  });

  return response.data;
};

const getAllSites = async () => {
  const response = await Axios.get("/SitesDatabase", {
    params: {
      State: "WorkOrder",
    },
  });
  return response.data.result;
};

const getNewWorkOrdersSubProjects = async (CompanyProjectsName) => {
  const response = await Axios.get(WORK_ORDERS_PATH, {
    params: {
      State: "SubProjects",
      CompanyProjectsName,
    },
  });
  return response.data.result;
};

const getWorkOrdersBySite = async (SiteId) => {
  const response = await Axios.get(WORK_ORDERS_PATH, {
    params: {
      State: "SitesDatabase",
      idSitesDatabase: SiteId,
    },
  });
  return response.data.result;
};

const addNewWorkOrder = async (workOrder) => {
  const response = await Axios.post(WORK_ORDERS_PATH, workOrder);
  return response.data;
};

const patchWorkOrder = async ({ SelectedNo, Status }) => {
  const res = await Axios.patch(WORK_ORDERS_PATH, {
    SelectedNo,
    Status,
  });

  return res.data;
};

const exportWorkOrders = async (ids) => {
  const response = await Axios.get(WORK_ORDERS_PATH, {
    responseType: "blob",
    params: {
      State: "ExportPDFMulti",
      WorkOrders: ids,
    },
  });

  return response.data;
};

const putWorkOrder = async ({ workOrderId, Status }) => {
  const res = await Axios.put(WORK_ORDERS_PATH, {
    SelectedNo: workOrderId,
    State: Status,
  });

  return res.data;
};

export const WorkOrdersService = {
  getAllWorkOrders,
  getNewWorkOrderFormData,
  getAllSites,
  getNewWorkOrdersSubProjects,
  addNewWorkOrder,
  getWorkOrdersBySite,
  patchWorkOrder,
  exportWorkOrders,
  putWorkOrder,
};
