import { useQuery } from "@tanstack/react-query";
import { useTranslation } from "react-i18next";
import { employeeRecordsService } from "../../api/employeeRecords";
import {
  Box,
  Button,
  IconButton,
  Modal,
  Slide,
  Typography,
} from "@mui/material";
import FormsDataGrid from "../DataGrid/FormsDataGrid";
import CloseIcon from "@mui/icons-material/Close";
import { useSelector } from "react-redux";

const style = {
  position: "absolute",
  transform: "translate(-50%, -50%)",
  bgcolor: "background.paper",
  borderRadius: "8px",
  overflow: "hidden",
  boxShadow: 24,
};

const EmployeeModal = ({
  open,
  handleClose,
  setNewRows,
  setFieldValue,
  selectedRows,
  setSelectedRows,
  newRows,
}) => {
  const { language } = useSelector((state) => state.theme);

  const [t] = useTranslation("global");
  const { data: rows } = useQuery({
    queryKey: ["employeeRecords"],
    queryFn: employeeRecordsService.GetAllEmployees,
  });

  const columns = [
    {
      field: "EmpName",
      headerName: t("data_grid.headers.name"),
      flex: 1,
      minWidth: 300,
    },
    { field: "EmpTitle", headerName: t("data_grid.headers.title") },
  ];
  const handleSelect = () => {
    const selectedData = rows.filter((row) =>
      selectedRows.includes(row.idEmpInfo)
    );
    const uniqueSelectedData = selectedData.filter(
      (selectedRow) =>
        !newRows.some((row) => row.idEmpInfo === selectedRow.idEmpInfo)
    );
    const updatedRows = [...newRows, ...uniqueSelectedData];
    setNewRows(updatedRows);
    setFieldValue("employeeData", updatedRows);
    handleClose();
  };

  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="select employees"
      aria-describedby="select employees to add to the form"
      sx={{
        display: "grid",
        placeItems: "center",
      }}
      keepMounted
    >
      <Slide
        direction={language == "EN" ? "left" : "right"}
        in={open}
        timeout={500}
      >
        <Box sx={style}>
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            padding="0px 8px"
            backgroundColor="background.formHeader"
            color="white"
          >
            <Typography
              sx={{
                fontSize: "17px",
                fontWeight: 500,
              }}
            >
              {t("modules.attendance.new_form.title")}
            </Typography>
            <IconButton onClick={handleClose} color="error">
              <CloseIcon style={{ color: "#ff4d6d", fontSize: 20 }} />
            </IconButton>
          </Box>
          <Box sx={{ padding: "0 10px", paddingTop: "10px" }}>
            <>
              <FormsDataGrid
                rows={rows || []}
                columns={columns}
                height={480}
                getRowId={(row) => row.idEmpInfo}
                showQuickFilter
                checkboxSelection
                toolbar
                disableSelectionOnClick
                onRowSelectionModelChange={(newSelectedRows) => {
                  setSelectedRows(newSelectedRows);
                }}
                rowSelectionModel={selectedRows}
              />
              <Box
                display="flex"
                gap="10px"
                justifyContent="flex-end"
                margin="15px 15px"
              >
                <Button
                  onClick={handleSelect}
                  sx={{
                    backgroundColor: "primary.main",
                    "&:hover": {
                      background: "#326c7f",
                    },
                    color: "white",
                    fontWeight: 500,
                  }}
                >
                  {t("modules.buttons.select")}
                </Button>
                <Button
                  onClick={handleClose}
                  sx={{
                    backgroundColor: "error.main",
                    "&:hover": {
                      background: "#8f0000",
                    },
                    color: "white",
                    fontWeight: 500,
                  }}
                >
                  {t("modules.buttons.cancel")}
                </Button>
              </Box>
            </>
          </Box>
        </Box>
      </Slide>
    </Modal>
  );
};

export default EmployeeModal;
