import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import Divider from "@mui/material/Divider";
import { useCallback, useEffect, useReducer, useState } from "react";
import {
  Avatar,
  Chip,
  IconButton,
  Link,
  Stack,
  Typography,
} from "@mui/material";
import {
  CloseRounded,
  LockClock,
  NotificationsTwoTone,
} from "@mui/icons-material";
import { LoadingButton } from "@mui/lab";
import { useDispatch, useSelector } from "react-redux";
import ConfirmationModal from "../Modals/ConfirmationModal";
import { setPreviousData } from "../../store/slices/themeSlice";
import useChangeRequest from "./hooks/useChangeRequest";
import { Trans, useTranslation } from "react-i18next";
import { Link as RouterLink } from "react-router-dom";
import DashBoardLinksModal from "../../pages/Dashboard/HyperLinksDataGrids/DashBoardLinksModal";
import VacationNoAttachment from "../../pages/Dashboard/HyperLinksDataGrids/VacationNoAttachment";
import { useQuery } from "@tanstack/react-query";
import { DashboardService } from "../../api/dashboard";

const NotificationCard = ({
  person,
  requestNumber,
  requestDate,
  description,
  id,
  type,
  requestType,
}) => {
  const [t] = useTranslation("global");
  const editMaterial =
    requestType === "Edit"
      ? t("navbar.notifications.materials.desc_edit")
      : t("navbar.notifications.materials.desc_cancel");
  const editMission =
    requestType === "Edit"
      ? t("navbar.notifications.missions.desc_edit")
      : t("navbar.notifications.missions.desc_cancel");

  const {
    confirmationModalState,
    confirmationModalMessage,
    handleOpenConfirmationModal,
    handleCloseConfirmationModal,
    updateChangeStatus,
    confirmFunction,
    isPending,
  } = useChangeRequest();

  return (
    <Box
      sx={{
        p: 2,
        transition: "background-color 500ms",
        "&:hover": {
          backgroundColor: "background.notificationsCardHover",
        },
      }}
    >
      <Stack spacing={2} direction={"row"}>
        {["Materials", "Missions"].includes(type) && (
          <Avatar src="/broken-image.jpg" />
        )}
        <Stack>
          <Box display="flex">
            <Typography
              sx={{
                color: "text.primary",
                fontSize: 14,
              }}
            >
              <span style={{ fontWeight: 500 }}>{person} </span>
              {type === "Materials" ? editMaterial : editMission}
            </Typography>
          </Box>
          <Box display="flex" flexDirection="column" gap={1}>
            <Stack direction={"row"} spacing={1} marginTop="5px">
              <Link
                component={RouterLink}
                to={`/${
                  type === "Materials" ? "materialrequests" : "missions"
                }/${requestNumber}`}
                target="_blank"
                sx={{
                  color: "#9D4EDD",
                  textDecoration: "none",
                  backgroundColor: "#F3E5FF",
                  py: 0.25,
                  px: 1.5,
                  borderRadius: 5,
                  fontSize: 13,
                }}
              >
                {requestNumber}
              </Link>

              <Chip
                label={requestDate}
                color="warning"
                variant="filled"
                size="small"
                sx={{
                  backgroundColor: "#FFEEEE",
                  color: "#FF6868",
                  py: 1.5,
                  px: 0.5,
                }}
                icon={<LockClock />}
              />
            </Stack>
            <Typography
              sx={{
                p: 2,
                backgroundColor: "background.notificationsDescription",
                color: "text.primary",
                alignSelf: "flex-start",
                borderRadius: 5,
                borderTopLeftRadius: 0,
                fontSize: 14,
              }}
            >
              {description}
            </Typography>
            <Stack direction={"row"} spacing={1}>
              <LoadingButton
                disableElevation
                sx={{
                  textTransform: "capitalize",
                  px: 1.5,
                  borderRadius: 3,
                  backgroundColor: "#00CA8D",
                  color: "white",
                  "&:hover": {
                    backgroundColor: "#01DE9C",
                  },
                }}
                variant="contained"
                onClick={() =>
                  handleOpenConfirmationModal(
                    t("confirmation_modal.message.approve"),
                    () =>
                      updateChangeStatus({
                        ChangeRequestStatus: "Approved",
                        SelectedID: id,
                        type,
                      })
                  )
                }
              >
                {t("navbar.notifications.buttons.approve")}
              </LoadingButton>
              <LoadingButton
                disableElevation
                sx={{
                  textTransform: "capitalize",
                  px: 1.5,
                  borderRadius: 3,
                  backgroundColor: "#FF5E5B",
                  "&:hover": {
                    backgroundColor: "#FF817F",
                  },
                }}
                variant="contained"
                onClick={() =>
                  handleOpenConfirmationModal(
                    t("confirmation_modal.message.reject"),
                    () =>
                      updateChangeStatus({
                        ChangeRequestStatus: "Not Approved",
                        SelectedID: id,
                        type,
                      })
                  )
                }
              >
                {t("navbar.notifications.buttons.reject")}
              </LoadingButton>
            </Stack>
          </Box>
        </Stack>
      </Stack>
      <ConfirmationModal
        open={confirmationModalState}
        handleClose={handleCloseConfirmationModal}
        confirmFunction={confirmFunction}
        confirmModalMessage={confirmationModalMessage}
        isLoading={isPending}
      />
    </Box>
  );
};

const SimpleNotificationCard = ({ notification }) => {
  const [t] = useTranslation("global");

  const { title, type, description, image } = notification;

  const [actionType, setActionType] = useState(null);

  const initialState = {
    expiredNationalId: false,
    expiredDriving: false,
    expiredCar: false,
    vacationNoAttachment: false,
  };

  const reducer = (state, action) => {
    switch (action.type) {
      case "EXPIRED_NATIONAL_ID":
        return { ...state, expiredNationalId: action.payload };
      case "EXPIRED_DRIVING":
        return { ...state, expiredDriving: action.payload };
      case "EXPIRED_CAR":
        return { ...state, expiredCar: action.payload };
      case "vacationNoAttachment":
        return { ...state, vacationNoAttachment: action.payload };
      default:
        return state;
    }
  };

  const [state, dispatch] = useReducer(reducer, initialState);

  const {
    expiredNationalId,
    expiredDriving,
    expiredCar,
    vacationNoAttachment,
  } = state;

  const getActionType = (modalType) => {
    switch (modalType) {
      case "modalNationalId":
        return "expiredNationalId";
      case "modalDrivingLicense":
        return "expiredDriving";
      case "modalCarLicense":
        return "expiredCar";
      case "modalVacationNoAttachment":
        return "vacationNoAttachment";
      default:
        return null;
    }
  };

  const handleOpenModal = (modalType) => {
    const actionTypes = {
      modalNationalId: "EXPIRED_NATIONAL_ID",
      modalDrivingLicense: "EXPIRED_DRIVING",
      modalCarLicense: "EXPIRED_CAR",
      modalVacationNoAttachment: "vacationNoAttachment",
    };
    if (Object.prototype.hasOwnProperty.call(actionTypes, modalType)) {
      dispatch({ type: actionTypes[modalType], payload: true });
      setActionType(getActionType(modalType));
    }
  };

  const useCustomQuery = (queryKey, state, enabled) => {
    return useQuery({
      queryKey: [queryKey],
      queryFn: () => DashboardService.getNotificationsModalData(state),
      enabled,
    });
  };

  const { data: nationalIdData, isLoading: nationalIdLoading } = useCustomQuery(
    "dashboardNationalId",
    "NationalIDexpired",
    expiredNationalId
  );

  const { data: drivingLicense, isLoading: drivingLicenseLoading } =
    useCustomQuery(
      "dashboardDrivingLicense",
      "DrivingLicenceExpired",
      expiredDriving
    );

  const { data: carLicense, isLoading: carLicenseLoading } = useCustomQuery(
    "dashboardCarLicense",
    "CarLicenseExpired",
    expiredCar
  );

  const nationalIdColumns = [
    {
      field: "EmpCode",
      headerName: t("data_grid.headers.emp_code"),
      width: 110,
      renderCell: (params) => {
        return (
          <Link
            component={RouterLink}
            to={`/employeerecords/${params.value.replace("/", "%2F")}`}
            target="_blank"
          >
            {params.value}
          </Link>
        );
      },
    },
    {
      field: "EmpNameEn",
      headerName: t("data_grid.headers.english_name"),
      width: 130,
      flex: 1,
    },
    {
      field: "EmpName",
      headerName: t("data_grid.headers.arabic_name"),
      width: 120,
      flex: 1,
    },
    {
      field: "EmpTitle",
      headerName: t("data_grid.headers.title"),
      width: 120,
    },
    {
      field: "EmpEffictiveDate",
      headerName: t("data_grid.headers.national_id_expired"),
      width: 150,
    },
  ];

  const drivingColumns = [
    ...nationalIdColumns.slice(0, -1),
    {
      field: "LicenseEndDate",
      headerName: t("data_grid.headers.driving_license_exp_date"),
      width: 170,
    },
  ];

  const carColumns = [
    {
      field: "CarsNumber",
      headerName: t("data_grid.headers.car_number"),
      width: 110,
      renderCell: (params) => {
        return (
          <Link
            component={RouterLink}
            to={`/companyfleet/${params.value}`}
            target="_blank"
          >
            {params.value}
          </Link>
        );
      },
    },
    {
      field: "CarsOwnership",
      headerName: t("data_grid.headers.owner_ship"),
      width: 130,
      flex: 1,
    },
    {
      field: "CarsType",
      headerName: t("data_grid.headers.car_type"),
      width: 120,
    },
    {
      field: "CarsModelName",
      headerName: t("data_grid.headers.car_model"),
      width: 120,
    },
    {
      field: "CarsModelYear",
      headerName: t("data_grid.headers.model_year"),
      width: 130,
    },
    {
      field: "CarsLicenseEndDate",
      headerName: t("data_grid.headers.license_expire_date"),
      width: 150,
    },
  ];

  const actionTypeMap = {
    expiredNationalId: {
      open: expiredNationalId,
      handleClose: () =>
        dispatch({ type: "EXPIRED_NATIONAL_ID", payload: false }),
      data: nationalIdData || [],
      columns: nationalIdColumns,
      isLoading: nationalIdLoading,
      getRowId: (row) => row.EmpCode,
      title: t("hyper_links_data_grid.expired_national_id"),
    },
    expiredDriving: {
      open: expiredDriving,
      handleClose: () => dispatch({ type: "EXPIRED_DRIVING", payload: false }),
      data: drivingLicense || [],
      columns: drivingColumns,
      isLoading: drivingLicenseLoading,
      getRowId: (row) => row.EmpCode,
      title: t("hyper_links_data_grid.expired_driving_license"),
    },
    expiredCar: {
      open: expiredCar,
      handleClose: () => dispatch({ type: "EXPIRED_CAR", payload: false }),
      data: carLicense || [],
      columns: carColumns,
      isLoading: carLicenseLoading,
      getRowId: (row) => row.CarsNumber,
      title: t("hyper_links_data_grid.expired_car_license"),
    },
  };

  return (
    <>
      <Box
        sx={{
          p: 2,
          transition: "background-color 500ms",
          "&:hover": {
            backgroundColor: "background.notificationsCardHover",
          },
        }}
      >
        <Stack spacing={2} direction={"row"}>
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              width: "40px",
              height: "40px",
              flexShrink: 0,
            }}
          >
            <img
              src={image}
              alt={type}
              style={{ width: "100%", height: "100%" }}
            />
          </Box>
          <Stack
            sx={{
              alignItems: "flex-start",
            }}
            spacing={1}
          >
            <Typography
              sx={{
                color: "text.primary",
                fontSize: 14,
                fontWeight: 500,
              }}
            >
              {title}
            </Typography>
            <Typography
              sx={{
                p: 2,
                backgroundColor: "background.notificationsDescription",
                color: "text.primary",
                alignSelf: "flex-start",
                borderRadius: 5,
                borderTopLeftRadius: 0,
                fontSize: 14,
              }}
            >
              {description}
            </Typography>
            <LoadingButton
              disableElevation
              sx={{
                textTransform: "capitalize",
                px: 1.5,
                borderRadius: 3,
              }}
              variant="contained"
              color="primary"
              onClick={() => handleOpenModal(type)}
            >
              {t("navbar.notifications.buttons.check")}
            </LoadingButton>
          </Stack>
        </Stack>
      </Box>
      <DashBoardLinksModal
        open={actionTypeMap[actionType]?.open || false}
        handleClose={actionTypeMap[actionType]?.handleClose}
        data={actionTypeMap[actionType]?.data}
        columns={actionTypeMap[actionType]?.columns}
        getRowId={actionTypeMap[actionType]?.getRowId}
        title={actionTypeMap[actionType]?.title}
        isLoading={actionTypeMap[actionType]?.isLoading}
      />
      <VacationNoAttachment
        open={vacationNoAttachment}
        handleClose={() =>
          dispatch({ type: "vacationNoAttachment", payload: false })
        }
      />
    </>
  );
};

const NoDataMessage = () => {
  const [t] = useTranslation("global");

  return (
    <Box sx={{ p: 3 }}>
      <Typography
        sx={{
          p: 3,
          borderRadius: 5,
          backgroundColor: "background.notificationsDescription",
          color: "text.primary",
        }}
      >
        {t("navbar.notifications.empty")}
      </Typography>
    </Box>
  );
};

export default function NotificationsPanel({ data, isLoading }) {
  const [t] = useTranslation("global");

  const {
    confirmationModalState,
    confirmationModalMessage,
    handleOpenConfirmationModal,
    handleCloseConfirmationModal,
    updateChangeStatus,
    confirmFunction,
    isPending,
  } = useChangeRequest();

  const dispatch = useDispatch();

  const { previousData, language } = useSelector((state) => state.theme);

  const triggerPushNotification = useCallback(
    (type, id, sender, ProcessNo, description) => {
      if ("Notification" in window && "serviceWorker" in navigator) {
        Notification.requestPermission().then((permission) => {
          if (permission === "granted") {
            navigator.serviceWorker.ready.then((registration) => {
              registration.showNotification(
                type === "Materials"
                  ? `${sender} ${t("navbar.notifications.materials.desc")}`
                  : `${sender} ${t("navbar.notifications.missions.desc")}`,
                {
                  body: `${ProcessNo} - ${description.slice(0, 50)}`,
                  actions: [
                    {
                      action: "approve",
                      title: t("navbar.notifications.buttons.approve"),
                    },
                    {
                      action: "reject",
                      title: t("navbar.notifications.buttons.reject"),
                    },
                  ],
                  dir: language === "AR" ? "rtl" : "ltr",
                  lang: language === "AR" ? "ar-EG" : "en-US",
                }
              );

              // Listen for messages from the service worker
              const channel = new BroadcastChannel("notificationChannel");
              channel.addEventListener("message", (event) => {
                const data = event.data;

                // Check the message type and react accordingly
                if (data.type === "notificationClick") {
                  const action = data.action;

                  // Perform actions based on the received action
                  if (action === "approve") {
                    // Handle the "Approve" action in your application
                    handleOpenConfirmationModal(
                      t("confirmation_modal.message.approve"),

                      () =>
                        updateChangeStatus({
                          ChangeRequestStatus: "Approved",
                          SelectedID: id,
                          type,
                        })
                    );
                  } else if (action === "reject") {
                    // Handle the "Reject" action in your application
                    handleOpenConfirmationModal(
                      t("confirmation_modal.message.reject"),

                      () =>
                        updateChangeStatus({
                          ChangeRequestStatus: "Not Approved",
                          SelectedID: id,
                          type,
                        })
                    );
                  }
                }
              });
            });
          }
        });
      } else {
        console.error(
          "Notifications or service workers not supported in this browser."
        );
      }
    },
    [handleOpenConfirmationModal, updateChangeStatus]
  );

  useEffect(() => {
    if (previousData != null) {
      if (
        data.MissionsChangeRequests.length >
          previousData.MissionsChangeRequests.length ||
        data.MaterialsChangeRequests.length >
          previousData.MaterialsChangeRequests.length
      ) {
        const differentMissionObject = findDifferentObject(
          previousData.MissionsChangeRequests,
          data.MissionsChangeRequests,
          "idMissionsChangeRequests"
        );
        if (differentMissionObject) {
          triggerPushNotification(
            "Missions",
            differentMissionObject.idMissionsChangeRequests,
            differentMissionObject.ERPUserNickName,
            differentMissionObject.MissionNo,
            differentMissionObject.MissionsChangeRequestDiscription
          );
        }

        const differentMaterialObject = findDifferentObject(
          previousData.MaterialsChangeRequests,
          data.MaterialsChangeRequests,
          "idMaterialsRequest"
        );

        if (differentMaterialObject) {
          triggerPushNotification(
            "Materials",
            differentMaterialObject.idMaterialsRequestChangeRequests,
            differentMaterialObject.ERPUserNickName,
            differentMaterialObject.MaterialsRequestNo,
            differentMaterialObject.ChangeRequestDiscription
          );
        }
      }

      // Update previousData with the latest data
      dispatch(setPreviousData(data));
    } else {
      // If previousData is null, update it with the current data
      dispatch(setPreviousData(data));
    }
  }, [data, dispatch, previousData, triggerPushNotification]);

  function findDifferentObject(arr1, arr2, id) {
    if (!Array.isArray(arr1) || !Array.isArray(arr2)) return;
    // Convert arrays to sets for efficient comparison
    const set1 = new Set(arr1.map((obj) => obj[id]));
    const set2 = new Set(arr2.map((obj) => obj[id]));

    // Find the different idMissionsChangeRequests
    const differentId = [...set1, ...set2].find(
      (id) => !(set1.has(id) && set2.has(id))
    );

    // Find the object with the different idMissionsChangeRequests
    const differentObject =
      arr1.find((obj) => obj[id] === differentId) ||
      arr2.find((obj) => obj[id] === differentId);

    return differentObject;
  }

  const userPriv = useSelector((state) => state.auth.userPriv);

  // Notifications Panel logic
  const [notificationsPanelState, setNotificationsPanelState] = useState(false);

  const openNotificationsPanel = () => {
    setNotificationsPanelState(true);
  };

  const closeNotificationsPanel = () => {
    setNotificationsPanelState(false);
  };

  // Tabs state
  const doesMaterialChangeRequestsExist = userPriv
    .find((item) => item.ModulesCategoryName === "Change Requests")
    ?.SystemModuleName.includes("Material Change Requests");

  const doesMissionsChangeRequestsExist = userPriv
    .find((item) => item.ModulesCategoryName === "Change Requests")
    ?.SystemModuleName.includes("Mission Change Requests");

  const renderLoading = () => (
    <Typography>{t("navbar.notifications.loading")}</Typography>
  );

  const renderNoData = () => <NoDataMessage />;

  const renderMaterialChangeRequestCards = (materialChangeRequests) =>
    materialChangeRequests
      .sort((a, b) => new Date(b.RequestDate) - new Date(a.RequestDate))
      .map((card) => (
        <NotificationCard
          key={card.idMaterialsRequest}
          description={card.ChangeRequestDiscription}
          requestDate={card.RequestDate}
          person={card.ERPUserNickName}
          requestNumber={card.MaterialsRequestNo}
          type="Materials"
          id={card.idMaterialsRequestChangeRequests}
          requestType={card.ChangeRequestType}
        />
      ));

  const renderMissionChangeRequestCards = (missionsChangeRequests) =>
    missionsChangeRequests
      .sort((a, b) => new Date(b.RequestDate) - new Date(a.RequestDate))
      .map((card) => (
        <NotificationCard
          key={card.idMissions}
          description={card.MissionsChangeRequestDiscription}
          requestDate={card.RequestDate}
          person={card.ERPUserNickName}
          requestNumber={card.MissionNo}
          type="Missions"
          id={card.idMissionsChangeRequests}
          requestType={card.ChangeRequestType}
        />
      ));

  // Counts
  const {
    EmpNationalIDExpired,
    EmpNationalIDExpiredNextMonth,
    AllVacationNoAttachmentCount,
    CarNowithCarLicenceExpire,
    CarNowithCarLicenceExpireNextMonth,
    EmpDrivingLicenceExpired,
    EmpDrivingLicenceExpiredNextMonth,
  } = data;

  const expiredNationalIDs = EmpNationalIDExpired[0].Count;
  const expiredNationalIDsNextMonth = EmpNationalIDExpiredNextMonth[0].Count;
  const vacationsNoAttachments = AllVacationNoAttachmentCount[0].Count;
  const expiredCarLicenses = CarNowithCarLicenceExpire[0].Count;
  const expiredCarLicensesNextMonth =
    CarNowithCarLicenceExpireNextMonth[0].Count;
  const expiredDrivingLicenses = EmpDrivingLicenceExpired[0].Count;
  const expiredDrivingLicensesNextMonth =
    EmpDrivingLicenceExpiredNextMonth[0].Count;

  // Helper function to get notification description key
  const getDescriptionKey = (expiredCount, expiringCount, baseKey) => {
    if (expiredCount > 0 && expiringCount > 0) {
      return `${baseKey}.desc`;
    } else if (expiredCount > 0) {
      return `${baseKey}.expired_desc`;
    } else if (expiringCount > 0) {
      return `${baseKey}.expiring_desc`;
    }
    return "";
  };

  const notificationTypes = [
    {
      title: t("navbar.notifications.expiredNationalIDs.title"),
      description: (
        <Trans
          t={t}
          i18nKey={getDescriptionKey(
            expiredNationalIDs,
            expiredNationalIDsNextMonth,
            "navbar.notifications.expiredNationalIDs"
          )}
          values={{
            count: expiredNationalIDs,
            expiringCount: expiredNationalIDsNextMonth,
          }}
          components={{ strong: <strong /> }}
        />
      ),
      image: "/id-card.svg",
      type: "modalNationalId",
      totalCount: expiredNationalIDs + expiredNationalIDsNextMonth,
    },
    {
      title: t("navbar.notifications.vacationsNoAttachments.title"),
      description: (
        <Trans
          t={t}
          i18nKey="navbar.notifications.vacationsNoAttachments.desc"
          values={{ count: vacationsNoAttachments }}
          components={{ strong: <strong /> }}
        />
      ),
      image: "/document.svg",
      type: "modalVacationNoAttachment",
      totalCount: vacationsNoAttachments,
    },
    {
      title: t("navbar.notifications.expiredCarLicenses.title"),
      description: (
        <Trans
          t={t}
          i18nKey={getDescriptionKey(
            expiredCarLicenses,
            expiredCarLicensesNextMonth,
            "navbar.notifications.expiredCarLicenses"
          )}
          values={{
            count: expiredCarLicenses,
            expiringCount: expiredCarLicensesNextMonth,
          }}
          components={{ strong: <strong /> }}
        />
      ),
      image: "/car.svg",
      type: "modalCarLicense",
      totalCount: expiredCarLicenses + expiredCarLicensesNextMonth,
    },
    {
      title: t("navbar.notifications.expiredDrivingLicenses.title"),
      description: (
        <Trans
          t={t}
          i18nKey={getDescriptionKey(
            expiredDrivingLicenses,
            expiredDrivingLicensesNextMonth,
            "navbar.notifications.expiredDrivingLicenses"
          )}
          values={{
            count: expiredDrivingLicenses,
            expiringCount: expiredDrivingLicensesNextMonth,
          }}
          components={{ strong: <strong /> }}
        />
      ),
      image: "/driver-license-car.svg",
      type: "modalDrivingLicense",
      totalCount: expiredDrivingLicenses + expiredDrivingLicensesNextMonth,
    },
  ];

  const noNotifications =
    ((Array.isArray(data.MaterialsChangeRequests) &&
      data.MaterialsChangeRequests.length === 0 &&
      data.MissionsChangeRequests === 0) ||
      (Array.isArray(data.MissionsChangeRequests) &&
        data.MissionsChangeRequests.length === 0 &&
        data.MaterialsChangeRequests === 0) ||
      (Array.isArray(data.MaterialsChangeRequests) &&
        data.MaterialsChangeRequests.length === 0 &&
        Array.isArray(data.MissionsChangeRequests) &&
        data.MissionsChangeRequests.length === 0) ||
      (data.MaterialsChangeRequests === 0 &&
        data.MissionsChangeRequests === 0)) &&
    expiredNationalIDs === 0 &&
    expiredNationalIDsNextMonth === 0 &&
    vacationsNoAttachments === 0 &&
    expiredCarLicenses === 0 &&
    expiredCarLicensesNextMonth === 0 &&
    expiredDrivingLicenses === 0 &&
    expiredDrivingLicensesNextMonth === 0;

  const changeRequestData = {
    MaterialsChangeRequests: data.MaterialsChangeRequests,
    MissionsChangeRequests: data.MissionsChangeRequests,
  };

  const changeRequestsCount =
    data &&
    Object.values(changeRequestData).reduce((totalLength, value) => {
      if (Array.isArray(value)) {
        return totalLength + value.length;
      } else {
        return totalLength;
      }
    }, 0);

  // Other notifications count
  const groupedData = {
    NationalIDsCount: expiredNationalIDs + expiredNationalIDsNextMonth,
    DrivingLicensesCount:
      expiredDrivingLicenses + expiredDrivingLicensesNextMonth,
    CarLicensesCount: expiredCarLicenses + expiredCarLicensesNextMonth,
    AllVacationNoAttachmentCount: vacationsNoAttachments,
  };

  const notificationsCount =
    Object.values(groupedData).reduce((total, value) => {
      return value > 0 ? total + 1 : total;
    }, 0) + changeRequestsCount;

  return (
    <>
      <IconButton
        color="primary"
        aria-label="open notifications panel"
        onClick={openNotificationsPanel}
        sx={{ position: "relative", fontSize: "28px" }}
      >
        <NotificationsTwoTone fontSize="inherit" />
        {notificationsCount > 0 && (
          <Box
            sx={{
              width: 19,
              height: 19,
              backgroundColor: "#FF5E5B",
              borderRadius: "50%",
              position: "absolute",
              top: 0,
              right: 0,
              display: "grid",
              placeItems: "center",
            }}
          >
            <Typography
              sx={{
                fontSize: 13,
                fontWeight: 100,
                color: "white",
              }}
            >
              {data && notificationsCount}
            </Typography>
          </Box>
        )}
      </IconButton>
      <Drawer
        SlideProps={{ direction: language === "AR" ? "right" : "left" }}
        anchor="right"
        open={notificationsPanelState}
        onClose={closeNotificationsPanel}
        slotProps={{ backdrop: { sx: { backgroundColor: "transparent" } } }}
        PaperProps={{
          sx: {
            backdropFilter: "blur(7px)",
            backgroundColor: "background.notificationsPanel",
          },
        }}
        sx={{ overflow: "hidden" }}
        aria-label="notifications panel"
      >
        <Box sx={{ width: 400 }} role="presentation">
          <Stack
            direction={"row"}
            justifyContent={"space-between"}
            alignItems={"center"}
            p={2}
            pb={0}
          >
            <Typography variant="h6" fontWeight={500}>
              {t("navbar.notifications.title")}
            </Typography>
            <IconButton
              aria-label="close"
              onClick={closeNotificationsPanel}
              sx={{ cursor: "pointer" }}
            >
              <CloseRounded />
            </IconButton>
          </Stack>
          {noNotifications && renderNoData()}

          <Stack
            divider={
              <Divider
                sx={{
                  borderStyle: "dashed",
                  borderColor: "secondary.main",
                }}
              />
            }
          >
            {doesMaterialChangeRequestsExist !== 0 &&
              data.MaterialsChangeRequests.length !== 0 &&
              Array.isArray(data.MaterialsChangeRequests) &&
              (isLoading
                ? renderLoading()
                : renderMaterialChangeRequestCards([
                    ...data.MaterialsChangeRequests,
                  ]))}

            {doesMissionsChangeRequestsExist !== 0 &&
              data.MissionsChangeRequests.length !== 0 &&
              Array.isArray(data.MissionsChangeRequests) &&
              (isLoading
                ? renderLoading()
                : data.MissionsChangeRequests.length === 0
                ? renderNoData()
                : renderMissionChangeRequestCards([
                    ...data.MissionsChangeRequests,
                  ]))}

            {notificationTypes.map(
              (notification) =>
                notification.totalCount > 0 && (
                  <SimpleNotificationCard
                    key={notification.title}
                    notification={notification}
                  />
                )
            )}
          </Stack>
        </Box>
      </Drawer>
      <ConfirmationModal
        open={confirmationModalState}
        handleClose={handleCloseConfirmationModal}
        confirmFunction={confirmFunction}
        confirmModalMessage={confirmationModalMessage}
        isLoading={isPending}
      />
    </>
  );
}
