import {
  groupByInvoiceNo,
  DetailPanelContent,
} from "./StatementsInSignaturePhase";
import dayjs from "dayjs";
import { Link, Box } from "@mui/material";
import { useTranslation } from "react-i18next";
import Axios from "../../../../../network/Axios";
import { useQuery } from "@tanstack/react-query";
import CardContainer from "../../../CardContainer";
import { Link as RouterLink } from "react-router-dom";
import { useState, useMemo, useCallback } from "react";
import ChipStatus from "../../../../../components/ChipStatus";
import BarChart from "../../../../../components/charts/BarChart";
import FormSpinner from "../../../../../components/Spinners/FormSpinner";
import FormsDataGrid from "../../../../../components/DataGrid/FormsDataGrid";

const OpenNAPOs = ({ openNAPOs }) => {
  const { t } = useTranslation("global");
  const [open, setOpen] = useState(false);
  const [dataNameCustom, setDataNameCustom] = useState(null);
  const [searchInput, setSearchInput] = useState("");

  const { data, isLoading, isRefetching } = useQuery({
    queryKey: ["openNAPOs", dataNameCustom],
    queryFn: async () => {
      const response = await Axios.get("/UsersManagement", {
        params: {
          SelectedNo: dataNameCustom,
          State: "OpenPOData",
        },
      });
      return response.data.OpenPO || [];
    },
    enabled: open,
  });

  const groupedRows = useMemo(() => groupByInvoiceNo(data), [data]);

  const filteredInvoices = useMemo(() => {
    if (!searchInput) {
      return groupedRows.map((group) => group.rows[0]); // Return first row of each group
    }

    return groupedRows
      .flatMap((group) => group.rows) // Flatten the grouped rows for filtering
      .filter((invoice) =>
        Object.keys(invoice).some((key) =>
          invoice[key]
            ?.toString()
            .toLowerCase()
            .includes(searchInput.toLowerCase())
        )
      );
  }, [groupedRows, searchInput]);

  const getDetailPanelContent = useCallback(
    ({ row }) => {
      const invoiceRows = groupedRows.find(
        (group) => group.invoiceNo === row.InvoiceNo
      )?.rows;
      return <DetailPanelContent rows={invoiceRows || []} />; // Default to empty array if undefined
    },
    [groupedRows]
  );
  const totalCount = openNAPOs.reduce(
    (acc, data) => acc + data.InvoiceCount,
    0
  );

  const totalAmount = openNAPOs.reduce(
    (acc, data) => acc + parseFloat(data.POAmount.replace(/,/g, "")),
    0
  );

  const openNAPOsSorted = openNAPOs.toSorted(
    (a, b) =>
      parseFloat(b.POAmount.replace(/,/g, "")) -
      parseFloat(a.POAmount.replace(/,/g, ""))
  );

  const combinedOPsCount = ["Total", ...openNAPOs.map((po) => po.ClientPONo)];

  const combinedOPsAmount = [
    "Total",
    ...openNAPOsSorted.map((po) => po.ClientPONo),
  ];

  const combinedOPsCountSeries = [
    {
      name: "Count",
      data: [totalCount, ...openNAPOs.map((po) => po.InvoiceCount)],
    },
  ];

  const combinedOPsAmountSeries = [
    {
      name: "Amount",
      data: [
        totalAmount,
        ...openNAPOsSorted.map((po) =>
          parseFloat(po.POAmount.replace(/,/g, ""))
        ),
      ],
    },
  ];

  const columns = [
    {
      field: "InvoiceNo",
      headerName: t("data_grid.headers.ref_no"),
      width: 120,
      hideable: false,
      renderCell: (params) => {
        return (
          <Link
            component={RouterLink}
            to={`/clientinvoices/${params.value}`}
            target="_blank"
          >
            {params.value}
          </Link>
        );
      },
    },
    {
      field: "Activity",
      headerName: t("data_grid.headers.activity"),
      width: 110,
      flex: 1,
    },
    {
      field: "CreationDate",
      headerName: t("data_grid.headers.creation_date"),
      type: "date",
      width: 100,
      valueGetter: ({ value }) => (value ? new Date(value) : null),
      renderCell: ({ value }) => {
        return value ? dayjs(value).format("YYYY/MM/DD") : "";
      },
    },
    {
      field: "SubmissionDate",
      headerName: t("data_grid.headers.submission_date"),
      width: 110,
      type: "date",
      valueGetter: ({ value }) => (value ? new Date(value) : null),
      renderCell: ({ value }) => {
        return value ? dayjs(value).format("YYYY/MM/DD") : "";
      },
    },
    {
      field: "D6Date",
      headerName: t("data_grid.headers.d6_date"),
      width: 80,
      type: "date",
      valueGetter: ({ value }) => (value ? new Date(value) : null),
      renderCell: ({ value }) => {
        return value ? dayjs(value).format("YYYY/MM/DD") : "";
      },
    },
    {
      field: "InvoiceDate",
      headerName: t("data_grid.headers.invoice_date"),
      type: "date",
      width: 90,
      valueGetter: ({ value }) => (value ? new Date(value) : null),
      renderCell: ({ value }) => {
        return value ? dayjs(value).format("YYYY/MM/DD") : "";
      },
    },
    {
      field: "InvoicesAmount",
      headerName: t("data_grid.headers.invoices_amount"),
      type: "number",
      width: 120,
      flex: 1,
      valueFormatter: ({ value }) => value.toLocaleString(),
    },
    {
      field: "InvoiceType",
      headerName: t("data_grid.headers.type"),
      width: 30,
    },
    {
      field: "GovernmentInvoice",
      headerName: t("data_grid.headers.governorate_invoice"),
      width: 90,
    },
    {
      field: "InvoiceStatus",
      headerName: t("data_grid.headers.status"),
      width: 190,
      renderCell: ({ value }) => {
        return <ChipStatus value={value} />;
      },
    },
  ];

  return (
    <CardContainer>
      <BarChart
        chartName="openNAPOs"
        title={t("main_cards.open_na_po")}
        chartData={combinedOPsAmount}
        chartData2={combinedOPsCount}
        seriesData={combinedOPsAmountSeries}
        seriesData2={combinedOPsCountSeries}
        horizontal
        type="bar"
        modalWidth="73rem"
        switchConfig={{
          leftLabel: t("main_cards.labels.by_count"),
          rightLabel: t("main_cards.labels.by_amount"),
        }}
        open={open}
        handleOpen={() => setOpen(true)}
        handleCloseCustom={() => setOpen(false)}
        dataNameCustom={dataNameCustom}
        setDataNameCustom={setDataNameCustom}
        content={
          <Box p={2}>
            {isLoading || isRefetching ? (
              <FormSpinner />
            ) : (
              <FormsDataGrid
                showExportButton
                rows={filteredInvoices || []}
                columns={columns}
                height={420}
                getDetailPanelContent={getDetailPanelContent}
                getDetailPanelHeight={() => "auto"}
                getRowId={(row) => row.InvoiceNo}
                toolbar
                searchValueCustom
                customSearchValue={searchInput}
                customSearchOnChange={(e) => setSearchInput(e.target.value)}
              />
            )}
          </Box>
        }
      />
    </CardContainer>
  );
};

export default OpenNAPOs;
