import Axios from "../network/Axios";

const ERPSystemRoles = "/ERPSystemRoles";
const DataGroups = "/DataGroups";

// Get All user roles
const getAllUserRoles = async () => {
  const response = await Axios.get(ERPSystemRoles);
  return response.data.result;
};

// Get single user role
const getSingleUserRole = async (id) => {
  const response = await Axios.get(ERPSystemRoles, {
    params: {
      SelectedNo: id,
    },
  });
  return response.data;
};

// Update user role privileges
const updateUserRolePrivileges = async (data) => {
  const response = await Axios.put(ERPSystemRoles, data);
  return response.data;
};

// Get new user role privileges list
const getNewUserRolePrivileges = async () => {
  const response = await Axios.get(ERPSystemRoles, {
    params: {
      State: "New",
    },
  });
  return response.data;
};

// Add new user role
const addNewUserRole = async (data) => {
  const response = await Axios.post(ERPSystemRoles, {
    ...data,
    State: "New",
  });
  return response.data;
};

// Get all data groups
const getAllDataGroups = async () => {
  const response = await Axios.get(DataGroups);
  return response.data.result;
};

// Get single data group
const getSingleDataGroup = async (id) => {
  const response = await Axios.get(DataGroups, {
    params: {
      SelectedNo: id,
    },
  });
  return response.data;
};

// Get new data group list
const getNewDataGroupList = async () => {
  const response = await Axios.get(DataGroups, {
    params: {
      State: "New",
    },
  });
  return response.data;
};

// Update data group privileges
const updateDataGroupPrivileges = async (data) => {
  const response = await Axios.put(DataGroups, data);
  return response.data;
};

// Add new data group
const addNewDataGroup = async (data) => {
  const response = await Axios.post(DataGroups, data);
  return response.data;
};

// Delete user role
const deleteUserRole = async (id) => {
  const response = await Axios.delete(ERPSystemRoles, {
    params: {
      SelectedNo: id,
    },
  });
  return response.data;
};
const cloneUsersManagement = async (oldRole, newRole) => {
  const response = await Axios.post(ERPSystemRoles, {
    State: "Clone",
    SelectedNo: oldRole,
    ERPUserRoleName: newRole,
  });
  return response.data;
};

export const ERPSystemRolesService = {
  getAllUserRoles,
  getSingleUserRole,
  updateUserRolePrivileges,
  getNewUserRolePrivileges,
  addNewUserRole,
  getAllDataGroups,
  getSingleDataGroup,
  getNewDataGroupList,
  updateDataGroupPrivileges,
  addNewDataGroup,
  deleteUserRole,
  cloneUsersManagement,
};
