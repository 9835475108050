import { Box, Typography } from "@mui/material";
import ButtonGroup from "../../../../components/forms/ButtonGroup/ButtonGroup";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import InfoModal from "../../../../components/Modals/InfoModal";
import { useTranslation } from "react-i18next";
import { useSnackbar } from "notistack";
import Axios from "../../../../network/Axios";
import { useParams } from "react-router-dom";
import { useState } from "react";

const ConfirmApprove = ({ open, handleClose, quotationInfo, outerModal }) => {
  const [t] = useTranslation("global");
  const queryClient = useQueryClient();
  const { quotationId } = useParams();
  const { enqueueSnackbar } = useSnackbar();

  const handleApprovalMutation = () => {
    return Axios.patch("/Quotations", {
      SelectedNo: quotationId,
      State: "Approved",
    });
  };

  const handleWorkOrderMutation = (workOrderStatus) => {
    return Axios.patch("/WorkOrders", {
      SelectedNo: quotationInfo?.Details[0]?.WorkOrderNo,
      Status: workOrderStatus,
    });
  };

  const handleSystemApproveMutation = (value) => {
    return Axios.patch("/Quotations", {
      SelectedNo: quotationId,
      Status: value,
      State: "Approved",
    });
  };

  const handleApproval = useMutation({
    mutationFn: handleApprovalMutation,
    onSuccess: (data) => {
      enqueueSnackbar(data.data.msg, {
        variant: "success",
      });
      handleClose();
      outerModal.handleClose();
      queryClient.invalidateQueries({
        queryKey: ["quotation", "info"],
      });
    },
  });

  const handleWorkOrder = useMutation({
    mutationFn: handleWorkOrderMutation,
    onSuccess: (data) => {
      enqueueSnackbar(data.data.msg, {
        variant: "success",
      });
      handleClose();
      outerModal.handleClose();
      handleCloseWorkOrderModal();
      queryClient.invalidateQueries({
        queryKey: ["quotation", "info"],
      });
    },
  });

  const handleSystemApprove = useMutation({
    mutationFn: handleSystemApproveMutation,
    onSuccess: (data) => {
      enqueueSnackbar("Approved Successfully", {
        variant: "success",
      });
      handleClose();
      outerModal.handleClose();
      queryClient.invalidateQueries({
        queryKey: ["quotation", "info"],
      });
    },
  });

  const d6Type = quotationInfo?.Details[0]?.WorkOrderD6TypeName;
  const workOrderStatus = quotationInfo?.Details[0]?.WorkOrderStatus;

  // Handle changing workOrder status modal
  const [openWorkOrderModal, setOpenWorkOrderModal] = useState(false);

  const handleOpenWorkOrderModal = () => setOpenWorkOrderModal(true);
  const handleCloseWorkOrderModal = () => setOpenWorkOrderModal(false);

  const renderWorkOrderConfirmation = ({ message, workOrderStatus }) => {
    return (
      <>
        <Typography>{message}</Typography>
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <ButtonGroup
            isPending={handleApproval.isPending || handleWorkOrder.isPending}
            onClickClose={() => handleApproval.mutate()}
            onClickSave={() => {
              handleWorkOrder.mutate(workOrderStatus);
              if (workOrderStatus === "Complete With Reserves") {
                handleSystemApprove.mutate("Yes");
              } else {
                handleApproval.mutate();
              }
            }}
            cancelLabel={t("modules.buttons.no")}
            saveLabel={t("modules.buttons.yes")}
          />
        </Box>
      </>
    );
  };

  const renderSystemApproveConfirmation = () => {
    return (
      <>
        <Typography>
          {t("info_modules.quotation.description.confirm_with_complete")}
        </Typography>
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <ButtonGroup
            onClickClose={() => handleSystemApprove.mutate("No")}
            onClickSave={
              ["In Progress", "Pending"].includes(workOrderStatus)
                ? handleOpenWorkOrderModal
                : () => handleSystemApprove.mutate("Yes")
            }
            cancelLabel={t("modules.buttons.no")}
            saveLabel={t("modules.buttons.yes")}
            backgroundColor="success.main"
            backgroundColorHover="success.dark"
          />
        </Box>
      </>
    );
  };

  return (
    <>
      <InfoModal
        open={open}
        handleClose={handleClose}
        disableCloseOnBackdropClick
        title={t(
          "info_modules.quotation.description.confirmation_work_order_modal"
        )}
        content={
          <Box padding="10px 15px">
            {["Final", "Single"].some((type) => d6Type?.includes(type))
              ? renderWorkOrderConfirmation({
                  message: t(
                    "info_modules.quotation.description.confirmation_work_order"
                  ),
                  workOrderStatus: "Work Complete",
                })
              : null}
            {d6Type?.includes("Initial")
              ? renderSystemApproveConfirmation()
              : null}
          </Box>
        }
      />
      <InfoModal
        open={openWorkOrderModal}
        handleClose={handleCloseWorkOrderModal}
        disableCloseOnBackdropClick
        title={t(
          "info_modules.quotation.description.confirmation_work_order_modal"
        )}
        content={
          <Box padding="10px 15px">
            {renderWorkOrderConfirmation({
              message: t(
                "info_modules.quotation.description.confirmation_work_order_complete_with_reserves"
              ),
              workOrderStatus: "Complete With Reserves",
            })}
          </Box>
        }
      />
    </>
  );
};

export default ConfirmApprove;
