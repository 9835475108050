import { Box, Stack, TextField } from "@mui/material";
import { Toggle } from "../common";
import { LoadingButton } from "@mui/lab";

const ChartData = ({
  chart,
  values,
  setFieldValue,
  errors,
  touched,
  handleBlur,
  handleChange,
  refetch,
  isRefetching,
}) => {
  const {
    dataQuery,
    leftValueQuery,
    rightValueQuery,
    chartDataUserDefined,
    multiValueToggleState,
    leftValue,
    rightValue,
  } = values;

  const handleUserDefined = (event) =>
    setFieldValue("chartDataUserDefined", event.target.checked);

  return (
    <Box
      sx={{
        display: "grid",
        gridTemplateColumns: "1fr 1fr",
        gap: 2,
        padding: "1rem",
        flexGrow: 1,
      }}
    >
      <Stack spacing={2}>
        {multiValueToggleState ? (
          <>
            <TextField
              multiline
              rows={6}
              name="leftValueQuery"
              label={`${leftValue} Query`}
              size="small"
              variant="outlined"
              onChange={handleChange}
              onBlur={handleBlur}
              value={leftValueQuery}
              error={!!touched.leftValueQuery && !!errors.leftValueQuery}
              helperText={touched.leftValueQuery && errors.leftValueQuery}
            />
            <TextField
              multiline
              rows={6}
              name="rightValueQuery"
              label={`${rightValue} Query`}
              size="small"
              variant="outlined"
              onChange={handleChange}
              onBlur={handleBlur}
              value={rightValueQuery}
              error={!!touched.rightValueQuery && !!errors.rightValueQuery}
              helperText={touched.rightValueQuery && errors.rightValueQuery}
            />
          </>
        ) : (
          <TextField
            multiline
            rows={13}
            name="dataQuery"
            label="Data Query"
            size="small"
            variant="outlined"
            onChange={handleChange}
            onBlur={handleBlur}
            value={dataQuery}
            error={!!touched.dataQuery && !!errors.dataQuery}
            helperText={touched.dataQuery && errors.dataQuery}
          />
        )}

        <Stack
          direction="row"
          spacing={1}
          alignItems="center"
          justifyContent="space-between"
        >
          <Toggle
            label="User Defined"
            checked={chartDataUserDefined}
            onChange={handleUserDefined}
          />
          <LoadingButton
            variant="contained"
            color="success"
            onClick={() => refetch()}
            disabled={isRefetching}
            loading={isRefetching}
            sx={{
              textTransform: "capitalize",
              color: "white",
            }}
          >
            Test
          </LoadingButton>
        </Stack>
      </Stack>
      <Box>{chart}</Box>
    </Box>
  );
};

export default ChartData;
