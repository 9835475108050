import InfoModal from "../../../../components/Modals/InfoModal";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { priceListsService } from "../../../../api/priceLists";
import FormSpinner from "../../../../components/Spinners/FormSpinner";
import { useSnackbar } from "notistack";
import { useTranslation } from "react-i18next";
import { Box, Typography } from "@mui/material";
import ButtonGroup from "../../../../components/forms/ButtonGroup/ButtonGroup";
import { DataGrid } from "./EditItem";

const DeleteItem = ({ open, handleClose, itemId }) => {
  const [t] = useTranslation("global");
  return (
    <InfoModal
      open={open}
      title={t("info_modules.price_list.forms.delete_item.title")}
      handleClose={handleClose}
      content={<ModalContent itemId={itemId} handleClose={handleClose} />}
    />
  );
};

const ModalContent = ({ itemId, handleClose }) => {
  const { data, isLoading } = useQuery({
    queryKey: ["priceListEditItemData", itemId],
    queryFn: () => priceListsService.getEditItemData(itemId),
  });

  if (isLoading) return <FormSpinner />;

  return <Form data={data} handleClose={handleClose} />;
};

const Form = ({ data, handleClose }) => {
  const [t] = useTranslation("global");
  const { enqueueSnackbar } = useSnackbar();
  const queryClient = useQueryClient();

  const { idPriceList } = data.Itemes[0];

  const { mutateAsync: deleteItem, isPending } = useMutation({
    mutationFn: priceListsService.patchPriceList,
    onSuccess: (data) => {
      handleClose();
      enqueueSnackbar(data.msg, { variant: "success" });
      queryClient.invalidateQueries({ queryKey: ["priceList"] });
    },
  });

  const message =
    data.result.length > 0
      ? t("info_modules.price_list.forms.delete_item.cannot_delete_item")
      : t("info_modules.price_list.forms.delete_item.delete_item");

  return (
    <Box
      sx={{
        padding: 2,
        display: "flex",
        flexDirection: "column",
        gap: 2,
        width: 1200,
      }}
    >
      <Typography variant="p" gutterBottom>
        {message}
      </Typography>

      <DataGrid rows={data.result} type="delete" />

      <ButtonGroup
        disabled={data.result.length > 0}
        onClickClose={handleClose}
        isPending={isPending}
        saveLabel={t("modules.buttons.delete")}
        onClickSave={() =>
          deleteItem({
            SelectedID: idPriceList,
            State: "DeleteItems",
          })
        }
      />
    </Box>
  );
};

export default DeleteItem;
