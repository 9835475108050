import BarChart from "../../../../../components/charts/BarChart";
import { useTranslation } from "react-i18next";
import CardContainer from "../../../CardContainer";
import { Link as RouterLink } from "react-router-dom";
import { Link } from "@mui/material";

const FinalAcceptancesThatNeedCreation = ({
  FinalAcceptanceNeedCreationPerProject,
  FinalAcceptanceNeedCreationPerUser,
}) => {
  const { t } = useTranslation("global");

  const totalCountByProject = FinalAcceptanceNeedCreationPerProject.reduce(
    (acc, data) => acc + data.Count,
    0
  );

  const totalCountByUser = FinalAcceptanceNeedCreationPerUser.reduce(
    (acc, data) => acc + data.Count,
    0
  );

  const combinedFinalAcceptancePerProject = [
    "Total",
    ...FinalAcceptanceNeedCreationPerProject.map(
      (project) => project.CompanyProjectsName
    ),
  ];

  const combinedFinalAcceptancePerUser = [
    "Total",
    ...FinalAcceptanceNeedCreationPerUser.map((user) => user.ERPUserNickName),
  ];

  const finalAcceptancePerProjectSeries = [
    {
      name: "Count",
      data: [
        totalCountByProject,
        ...FinalAcceptanceNeedCreationPerProject.map(
          (project) => project.Count
        ),
      ],
    },
  ];

  const finalAcceptancePerUserSeries = [
    {
      name: "Count",
      data: [
        totalCountByUser,
        ...FinalAcceptanceNeedCreationPerUser.map((user) => user.Count),
      ],
    },
  ];

  const columns = [
    {
      field: "WorkOrderD6No",
      headerName: t("data_grid.headers.ref_no"),
      width: 110,
      renderCell: (params) => {
        return (
          <Link
            component={RouterLink}
            to={`/quotations/${params.value}`}
            target="_blank"
          >
            {params.value}
          </Link>
        );
      },
    },
    {
      field: "SiteName",
      headerName: t("data_grid.headers.site_name"),
      width: 140,
      flex: 1,
    },
    {
      field: "SiteCode",
      headerName: t("data_grid.headers.site_code"),
      width: 140,
      flex: 1,
    },
    {
      field: "QUser",
      headerName: t("data_grid.headers.created_by"),
      width: 140,
      flex: 1,
    },
    {
      field: "WorkOrderD6TypeName",
      headerName: t("data_grid.headers.d6_type"),
      width: 140,
      flex: 1,
    },
    {
      field: "WorkOrderD6Install",
      headerName: t("data_grid.headers.install_amount"),
      width: 140,
      flex: 1,
    },
    {
      field: "WorkOrderD6Supply",
      headerName: t("data_grid.headers.supply_amount"),
      width: 140,
      flex: 1,
    },
    {
      field: "WorkOrderD6Amount",
      headerName: t("data_grid.headers.amount"),
      width: 140,
      flex: 1,
    },
    {
      field: "AcceptanceDate",
      headerName: t("data_grid.headers.acceptance_date"),
      width: 140,
      flex: 1,
    },
    {
      field: "SubProjectsName",
      headerName: t("data_grid.headers.sub_project"),
      width: 140,
      flex: 1,
    },
  ];

  return (
    <CardContainer>
      <BarChart
        isClickable
        chartName="finalAcceptancesThatNeedCreation"
        chartData={combinedFinalAcceptancePerProject}
        chartData2={combinedFinalAcceptancePerUser}
        seriesData={finalAcceptancePerProjectSeries}
        seriesData2={finalAcceptancePerUserSeries}
        title={t("main_cards.final_acceptances_need_creation")}
        horizontal
        type="bar"
        switchConfig={{
          leftLabel: t("main_cards.labels.per_user"),
          rightLabel: t("main_cards.labels.per_project"),
        }}
        modalConfig={{
          queryKey: "finalAcceptancesThatNeedCreation",
          perUserState: "AcceptanceCountPerUser",
          perProjectState: "AcceptanceCountPerProject",
          columns,
          dataGridId: "WorkOrderD6No",
        }}
      />
    </CardContainer>
  );
};

export default FinalAcceptancesThatNeedCreation;
