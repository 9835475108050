import {
  Stepper,
  Step,
  StepLabel,
  Box,
  TextField,
  Autocomplete,
} from "@mui/material";
import { useState } from "react";
import { useSnackbar } from "notistack";
import { useParams } from "react-router-dom";
import Axios from "../../../../network/Axios";
import { useTranslation } from "react-i18next";
import ChipStatus from "../../../../components/ChipStatus";
import InfoModal from "../../../../components/Modals/InfoModal";
import { formatCurrency } from "../../../../utils/globalFunctions";
import FormSpinner from "../../../../components/Spinners/FormSpinner";
import { useQuery, useMutation, useQueryClient } from "@tanstack/react-query";
import CustomAccordion from "../../../../components/Accordion/CustomAccordion";
import StepperButton from "../../../../components/forms/ButtonGroup/StepperButton";
import SelectWorkOrder from "../../../../components/forms/Acceptance/Steps/SelectWorkOrder";

const UseAsTemplate = ({ open, handleClose, type }) => {
  const [t] = useTranslation("global");
  const { quotationId } = useParams();
  const queryClient = useQueryClient();
  const { enqueueSnackbar } = useSnackbar();
  const [activeStep, setActiveStep] = useState(0);
  const [isRowSelected, setIsRowSelected] = useState(false);
  const [selectedWorkOrders, setSelectedWorkOrders] = useState([]);
  const [newWorkOrder, setNewWorkOrder] = useState([]);
  const [d6Type, setD6Type] = useState("");
  const [expanded, setExpanded] = useState("panel1");

  const handleChange = (panel) => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false);
  };
  const { data: formData, isLoading } = useQuery({
    queryKey: ["workOrders", "quotations"],
    queryFn: async () => {
      const response = await Axios.get("/WorkOrders", {
        params: {
          State: "Quotations",
        },
      });
      return response.data.result;
    },
    enabled: open,
  });
  const { data: itemsRows, isLoading: loadingItems } = useQuery({
    queryKey: ["items", "useAsTemplate"],
    queryFn: async () => {
      const response = await Axios.get("/Quotations", {
        params: {
          State: "UseAsTemplate",
          SelectedNo: quotationId,
        },
      });
      return response.data;
    },
    enabled: open,
  });
  const workOrders = newWorkOrder.map((order) => ({
    WorkOrderNo: order.WorkOrderNo,
  }));
  const { mutateAsync: handleTemplate, isPending: pendingTemplate } =
    useMutation({
      mutationFn: async () => {
        return Axios.post("/Quotations", {
          SelectedNo: quotationId,
          State: "UseAsTemplate",
          WorkOrders: workOrders,
          WorkOrderD6TypeName: d6Type,
        });
      },
      onSuccess: (data) => {
        handleCloseSave();
        enqueueSnackbar("WorkOrderD6 Created Successfully", {
          variant: "success",
        });
        queryClient.invalidateQueries({
          queryKey: ["quotation", "info"],
        });
      },
    });
  const handleNext = async () => {
    if (activeStep === 0) {
      const selectedData = formData.filter((row) =>
        selectedWorkOrders.includes(row.idWorkOrder)
      );
      setNewWorkOrder(selectedData);
    }
    if (activeStep === 1) {
      return handleTemplate();
    }
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };
  const handleRowSelection = (selectedItems) => {
    setIsRowSelected(selectedItems.length > 0);
  };
  const steps = [
    t("modules.material_request.steps.select_work_order"),
    t("modules.material_request.steps.review"),
  ];
  const workOrderColumns = [
    {
      field: "WorkOrderNo",
      headerName: t("data_grid.headers.work_order_no"),
      width: 120,
    },

    {
      field: "WorkOrderDiscription",
      headerName: t("data_grid.headers.description"),
      width: 140,
    },
    {
      field: "SiteName",
      headerName: t("data_grid.headers.site_name"),
      width: 110,
    },
    {
      field: "SiteCode",
      headerName: t("data_grid.headers.site_code"),
      width: 110,
    },
    {
      field: "SubProjectsName",
      headerName: t("data_grid.headers.sub_project"),
      width: 100,
    },
    {
      field: "WorkOrderDate",
      headerName: t("data_grid.headers.date"),
      width: 120,
    },
    {
      field: "ERPUserNickName",
      headerName: t("data_grid.headers.created_by"),
      width: 120,
      flex: 1,
    },
    {
      field: "WorkOrderStatus",
      headerName: t("data_grid.headers.status"),
      width: 150,
      renderCell: ({ value }) => {
        return <ChipStatus value={value} />;
      },
    },
  ];

  const unitPriceColumn = {
    field: "TheItemPrice",
    headerName: t("data_grid.headers.unit_price"),
    width: 120,
    flex: 1,
  };

  const totalColumn = {
    field: "TotalPrice",
    headerName: t("data_grid.headers.total"),
    width: 120,
    flex: 1,
    renderCell: (params) => formatCurrency(params.value),
  };

  const itemsBaseColumns = [
    {
      field: "PriceListSN",
      headerName: t("data_grid.headers.code"),
      width: 160,
    },
    {
      field: "Description",
      headerName: t("data_grid.headers.description"),
      width: 210,
      flex: 1,
    },
    {
      field: "SI",
      headerName: t("data_grid.headers.s_i"),
    },
    {
      field: "Unit",
      headerName: t("data_grid.headers.unit"),
    },
    {
      field: "ItemQTY",
      headerName: t("data_grid.headers.quantity"),
      width: 120,
    },
  ];

  const itemsColumns =
    type === "acceptance"
      ? itemsBaseColumns
      : [...itemsBaseColumns, unitPriceColumn, totalColumn];

  const handleCloseSave = () => {
    handleClose();
    setActiveStep(0);
    setSelectedWorkOrders([]);
    setD6Type("");
  };

  const views = {
    0: (
      <SelectWorkOrder
        rows={formData}
        selectedRows={selectedWorkOrders}
        setSelectedRows={setSelectedWorkOrders}
        isUseTemplate
      />
    ),
    1: (
      <Box margin="12px 0">
        <Autocomplete
          disablePortal
          id="combo-box-demo"
          options={itemsRows?.WorkOrderD6TypeList}
          getOptionLabel={(option) => option.WorkOrderD6TypeName}
          sx={{ width: "40%", marginBottom: "10px" }}
          size="small"
          onChange={(event, newValue) => {
            setD6Type(newValue ? newValue.WorkOrderD6TypeName : "");
          }}
          renderInput={(params) => (
            <TextField
              {...params}
              label={t("info_modules.quotation.description.select_d6")}
              value={d6Type}
            />
          )}
        />
        <CustomAccordion
          title={t("modules.material_request.label.work_orders")}
          rows={newWorkOrder}
          columns={workOrderColumns}
          height={250}
          getRowId={(row) => row.idWorkOrder}
          hideFooter
          expanded={expanded === "panel1"}
          onChange={handleChange("panel1")}
        />
        <CustomAccordion
          title={t("modules.material_request.label.items")}
          rows={itemsRows?.Items}
          columns={itemsColumns}
          height={250}
          getRowId={(row) => row.idPriceList}
          hideFooter
          expanded={expanded === "panel2"}
          onChange={handleChange("panel2")}
        />
      </Box>
    ),
  };

  const isNextBtnDisabled = activeStep === 0 && selectedWorkOrders.length === 0;

  return (
    <InfoModal
      open={open}
      handleClose={handleCloseSave}
      width="66.5rem"
      title={t("info_modules.quotation.description.use_template")}
      content={
        <>
          {isLoading || loadingItems ? (
            <FormSpinner />
          ) : (
            <Box margin="10px">
              <Stepper activeStep={activeStep}>
                {steps.map((label) => {
                  return (
                    <Step key={label}>
                      <StepLabel>{label}</StepLabel>
                    </Step>
                  );
                })}
              </Stepper>

              {views[activeStep]}
              <StepperButton
                isPending={pendingTemplate}
                onClick={handleNext}
                handleBack={handleBack}
                activeStep={activeStep}
                disabled={isNextBtnDisabled}
                label={
                  activeStep === 1
                    ? t("modules.buttons.save")
                    : t("modules.buttons.next")
                }
                isLastStep={activeStep === 1}
              />
            </Box>
          )}
        </>
      }
    />
  );
};
export default UseAsTemplate;
