import { useQuery } from "@tanstack/react-query";
import Axios from "../../../network/Axios";
import { BaseForm } from "../Missions/MissionsForm";
import FormSpinner from "../../Spinners/FormSpinner";

const WarrantyVisitForm = ({
  onDirtyChange,
  isThreeStepProcess = false,
  workOrderId,
  handleCloseFromAnotherForm,
}) => {
  const { data: missionData, isLoading: missionLoading } = useQuery({
    queryKey: ["warrantyVisit", "new"],
    queryFn: async () => {
      const response = await Axios.get("/WarrantyVisit", {
        params: {
          State: "New",
        },
      });
      return response.data;
    },
  });

  const { data: workOrderData, isLoading } = useQuery({
    queryKey: ["workOrders", "warrantyVisit"],
    queryFn: async () => {
      const response = await Axios.get("/WorkOrders", {
        params: {
          State: "WarrantyVisits",
        },
      });
      return response.data.result;
    },
  });

  const { data: empData, isLoading: loadingEmpData } = useQuery({
    queryKey: ["employeeRecords", "missions"],
    queryFn: async () => {
      const response = await Axios.get("/EmployeeRecords", {
        params: {
          State: "Mission",
        },
      });
      return response.data.result;
    },
  });

  if (missionLoading) {
    return <FormSpinner />;
  }

  return (
    <BaseForm
      {...{
        onDirtyChange,
        isThreeStepProcess,
        missionData,
        workOrderData,
        empData,
        loadingEmpData,
        isLoading,
        workOrderId,
        handleCloseFromAnotherForm,
        type: "warrantyVisit",
      }}
    />
  );
};

export default WarrantyVisitForm;
