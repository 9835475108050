import { Box, CircularProgress } from "@mui/material";

const FormSpinner = () => {
  return (
    <Box
      display="flex"
      justifyContent="center"
      minHeight="25rem"
      minWidth="25rem"
      alignItems="center"
    >
      <CircularProgress
        sx={{
          color: "primary",
        }}
      />
    </Box>
  );
};

export default FormSpinner;
