import { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Stepper, Step, StepLabel, Box, Typography } from "@mui/material";
import InfoModal from "../../../../../components/Modals/InfoModal";
import StepperButton from "../../../../../components/forms/ButtonGroup/StepperButton";
import FormSpinner from "../../../../../components/Spinners/FormSpinner";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import SelectItems from "./Steps/SelectItems";
import ModifyItems from "./Steps/ModifyItems";
import Review from "./Steps/Review";
import { useSnackbar } from "notistack";
import Axios from "../../../../../network/Axios";
import { useParams } from "react-router-dom";
import { useFormik } from "formik";
import dayjs from "dayjs";

const Add = ({ open, handleClose, loading, comboBoxData }) => {
  const [t] = useTranslation("global");
  const { purchaseRequestId } = useParams();
  const queryClient = useQueryClient();
  const { enqueueSnackbar } = useSnackbar();
  const [activeStep, setActiveStep] = useState(0);
  const [selectedNo, setSelectedNo] = useState([]);
  const [disableNextRow, setDisableNextRow] = useState(false);
  const [rows, setRows] = useState([]);
  const [message, setMessage] = useState(null);

  const initialValues = {
    paperNo: "",
    date: dayjs(),
  };
  const { mutateAsync: getItems, isPending: loadingItems } = useMutation({
    mutationFn: async () => {
      return Axios.get("/PurchaseRequests", {
        params: {
          SelectedNo: purchaseRequestId,
          State: "AddMaterials",
          ToStore: selectedNo,
        },
      });
    },
    onSuccess: (data) => {
      if (!data) return;
      setRows(data.data.MRItemsInStore);
    },
  });
  const { mutateAsync: handleAdd, isPending: pendingAdd } = useMutation({
    mutationFn: async () => {
      const mutationData = {
        Items: rows,
        MaterialsPurchaseRequestNo: purchaseRequestId,
        StoreTransitionDate: values.date.format("YYYY-MM-DD hh:mm:00"),
        StoreTransitionInvoiceNo: values.paperNo,
        ToStore: selectedNo,
        State: "Add",
      };
      return Axios.patch("/InventoryManagement", mutationData);
    },
    onSuccess: (data) => {
      if (!data) return;
      enqueueSnackbar("Added Successfully", {
        variant: "success",
      });
      handleCloseSave();
      queryClient.invalidateQueries({
        queryKey: ["purchaseRequest", "info"],
      });
    },
  });

  const handleNext = async () => {
    if (activeStep === 2) {
      await handleAdd();
      handleCloseSave();
      return;
    }
    // Set the quantity to 0 for any rows that haven't been edited
    const updatedRows = rows.map((row) => {
      if (!row.QTY) {
        return { ...row, QTY: 0 };
      }
      return row;
    });
    setRows(updatedRows);
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const { handleSubmit, values, setFieldValue, resetForm } = useFormik({
    initialValues,
    onSubmit: handleNext,
  });

  const handleCloseSave = () => {
    handleClose();
    resetForm();
    setSelectedNo([]);
    setRows([]);
    setActiveStep(0);
  };

  const steps = [
    t("modules.material_request.steps.select_item"),
    t("modules.material_request.steps.modify_item"),
    t("modules.material_request.steps.review"),
  ];

  useEffect(() => {
    const totalQuantity = rows.some((row) => row.QTY > 0);
    setDisableNextRow(totalQuantity);
  }, [rows]);

  const handleQuantity = (params, event) => {
    if (!event || !event.target || typeof event.target.value === "undefined") {
      return;
    }

    const newValue = event.target.value;

    const updateRowsAndSetMessage = (message) => {
      setMessage(message);
      const newRows = rows.map((row) => {
        if (row.idMaterialsPurchaseRequestItems === params.id) {
          return { ...row, QTY: 0 };
        }
        return row;
      });
      setRows(newRows);
    };

    if (params.row.CompanyStoreTempleteItemsBalance === null) {
      updateRowsAndSetMessage("Ths Item is not available in the store");
      return;
    }

    if (
      newValue >
      params.row.MaterialsPurchaseRequestItemsQty -
        params.row.MaterialsPurchaseRequestIssuedQty
    ) {
      updateRowsAndSetMessage("Quantity can't be more than requested quantity");
      return;
    }

    const newRows = rows.map((row) => {
      if (row.idMaterialsPurchaseRequestItems === params.id) {
        return { ...row, QTY: newValue };
      }
      return row;
    });
    setRows(newRows);
    setMessage(null);
  };

  const views = {
    0: (
      <SelectItems
        comboBoxData={comboBoxData}
        selectedNo={selectedNo}
        setSelectedNo={setSelectedNo}
        rows={rows}
        getItems={getItems}
        loadingItems={loadingItems}
        handleQuantity={handleQuantity}
      />
    ),
    1: (
      <ModifyItems
        setFieldValue={setFieldValue}
        handleSubmit={handleSubmit}
        values={values}
      />
    ),
    2: <Review values={values} rows={rows} />,
  };

  return (
    <InfoModal
      open={open}
      handleClose={() => {
        handleCloseSave();
      }}
      width="60rem"
      title={t(
        "info_modules.purchase_request.description.add_purchase_request"
      )}
      content={
        <>
          {loading ? (
            <FormSpinner />
          ) : (
            <Box margin="10px">
              <Stepper activeStep={activeStep}>
                {steps.map((label) => {
                  return (
                    <Step key={label}>
                      <StepLabel>{label}</StepLabel>
                    </Step>
                  );
                })}
              </Stepper>

              {views[activeStep]}
              <Box
                display="flex"
                alignItems="center"
                justifyContent={message !== null ? "space-between" : "flex-end"}
              >
                {message && (
                  <Typography
                    color="error"
                    fontSize="16px"
                    fontWeight={500}
                    paddingLeft="10px"
                  >
                    {message}
                  </Typography>
                )}
                <StepperButton
                  isPending={pendingAdd}
                  onClick={handleNext}
                  handleBack={handleBack}
                  activeStep={activeStep}
                  disabled={!disableNextRow}
                  label={
                    activeStep === 2
                      ? t("modules.buttons.save")
                      : t("modules.buttons.next")
                  }
                  isLastStep={activeStep === 2}
                />
              </Box>
            </Box>
          )}
        </>
      }
    />
  );
};

export default Add;
