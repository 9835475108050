import { Box, Typography, Button } from "@mui/material";
import ErrorIcon from "@mui/icons-material/Error";
import { useSelector } from "react-redux";

const FallBackError = () => {
  const theme = useSelector((state) => state.theme.colorTheme);
  return (
    <Box>
      <Box
        display="flex"
        flexDirection="column"
        alignItems="center"
        marginTop="150px"
        gap="4px"
      >
        <ErrorIcon style={{ fontSize: 80, color: "#f44336" }} />
        <Typography variant="h6">
          Something went wrong, try again later.
        </Typography>
        <Button
          onClick={() => window.location.reload()}
          sx={{
            background: theme === "dark" ? "#e6e6e6" : "#212b36",
            color: theme == "dark" ? "#212b36" : "#e6e6e6",
            ":hover": {
              backgroundColor: theme === "dark" ? "#b3b3b3" : "#4d555e",
            },
            fontSize: "16px",
            fontWeight: 600,
            padding: "4px 8px",
            textTransform: "none",
            marginTop: "10px",
          }}
        >
          Reload Page
        </Button>
      </Box>
    </Box>
  );
};
export default FallBackError;
