import { useTranslation } from "react-i18next";
import DashboardAccordion from "../../DashboardAccordion";
import Insurance from "./Charts/Insurance";

const HumanResourcesSection = ({ data }) => {
  const { t } = useTranslation("global");
  const { socialInsured, socialNotInsured, medicalInsured, medicalNotInsured } =
    data;

  return (
    <DashboardAccordion title={t("main_cards.titles.human_resources")}>
      <Insurance
        medicalInsured={medicalInsured}
        medicalNotInsured={medicalNotInsured}
        socialInsured={socialInsured}
        socialNotInsured={socialNotInsured}
      />
    </DashboardAccordion>
  );
};

export default HumanResourcesSection;
