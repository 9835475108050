import { useQuery } from "@tanstack/react-query";
import { useTranslation } from "react-i18next";
import { Box, Stack } from "@mui/material";
import FormsDataGrid from "../../../../components/DataGrid/FormsDataGrid";
import FormSpinner from "../../../../components/Spinners/FormSpinner";
import { WorkOrdersService } from "../../../../api/workOrders";
import ButtonGroup from "../../ButtonGroup/ButtonGroup";
import SitesDataBaseForm from "../../SitesDataBaseForm";
import InfoModal from "../../../Modals/InfoModal";
import { useState } from "react";
import { useConfirmationDialog } from "../../../../contexts/ConfirmationDialogContext";

const SelectSite = ({
  setValue,
  siteId,
  handleClose,
  handleSiteNameChange,
}) => {
  const [t] = useTranslation("global");

  const columns = [
    {
      field: "CSiteCode",
      headerName: t("data_grid.headers.site_code"),
      width: 130,
    },
    {
      field: "SiteAddress",
      headerName: t("data_grid.headers.site_address"),
      width: 115,
    },
    {
      field: "SiteName",
      headerName: t("data_grid.headers.site_name"),
      width: 70,
      flex: 1,
    },
  ];

  const { data, isLoading, isRefetching } = useQuery({
    queryKey: ["workOrderSites"],
    queryFn: WorkOrdersService.getAllSites,
  });

  const handleRowSelectionModelChange = (newSelectedRows) => {
    // If newSelectedRows array has more than one item, keep only the second item
    if (newSelectedRows.length > 1) {
      newSelectedRows = [newSelectedRows[1]];
    }

    // Disable the "Select All" checkbox
    const updatedSelectionModel =
      newSelectedRows.length === 1 ? newSelectedRows : [];

    // Update selectedRows state with the updatedSelectionModel
    const siteDetails = data.find(
      (row) => row.idSitesDatabase === updatedSelectionModel[0]
    );

    setValue({
      siteId: updatedSelectionModel,
      candidate: siteDetails?.Candidate,
      phase: siteDetails?.Phase,
    });

    handleSiteNameChange(siteDetails?.SiteName);
    handleClose();
  };

  // Handle sites database modal
  const [openNewSiteModal, setOpenNewSiteModal] = useState(false);

  const handleOpenNewSiteModal = () => setOpenNewSiteModal(true);

  const handleCloseNewSiteModal = () => setOpenNewSiteModal(false);

  // Handle dirty state for the sites database form
  const [dirty, setDirty] = useState(false);

  const onDirtyChange = (isDirty) => {
    setDirty(isDirty);
  };

  const { setOpen } = useConfirmationDialog();

  const onClickClose = () => {
    if (dirty) {
      setOpen(t("modules.confirmation_message"), () => handleCloseNewSiteModal);
    } else {
      handleCloseNewSiteModal();
    }
  };

  if (isLoading || isRefetching) return <FormSpinner />;

  return (
    <>
      <Stack spacing={2} p={2}>
        <Box width={400}>
          <FormsDataGrid
            rows={data}
            columns={columns}
            getRowId={(row) => row.idSitesDatabase}
            disableColumnMenu
            checkboxSelection
            disableMultipleRowSelection
            onRowSelectionModelChange={handleRowSelectionModelChange}
            rowSelectionModel={siteId}
            showQuickFilter
            toolbar
            height={400}
            sx={{
              "& .MuiDataGrid-columnHeaderCheckbox": {
                pointerEvents: "none",
              },
            }}
          />
        </Box>
        <ButtonGroup
          cancelLabel={t("modules.buttons.cancel")}
          saveLabel={t("modules.work_orders.buttons.add_new_site")}
          onClickClose={handleClose}
          onClickSave={handleOpenNewSiteModal}
        />
      </Stack>
      <InfoModal
        open={openNewSiteModal}
        handleClose={handleCloseNewSiteModal}
        title={t("modules.sites_database.new_form.title")}
        content={
          <Box p={2}>
            <SitesDataBaseForm
              onClickClose={onClickClose}
              onDirtyChange={onDirtyChange}
              handleCloseFromAnotherForm={handleCloseNewSiteModal}
            />
          </Box>
        }
        width="52rem"
      />
    </>
  );
};

export default SelectSite;
