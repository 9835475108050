import FormSpinner from "../../../../../components/Spinners/FormSpinner";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { employeeRecordsService } from "../../../../../api/employeeRecords";
import { useParams } from "react-router-dom";
import { Autocomplete, Box, Stack, TextField, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useSnackbar } from "notistack";
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import dayjs from "dayjs";
import { useState } from "react";
import ButtonGroup from "../../../../../components/forms/ButtonGroup/ButtonGroup";
import {
  MAX_FILE_SIZE,
  supportedImageTypes,
} from "../../../../../constants/constants";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFnsV3";
import ImagePickerBox from "../../components/ImagePickerBox";
import { convertImage } from "../../../../../utils/utils";
import { LoadingButton } from "@mui/lab";
import { useLabels } from "./hooks/useLabels";
import { useConfirmationDialog } from "../../../../../contexts/ConfirmationDialogContext";

const currentDate = dayjs().format("YYYY/MM/DD");

const licenseTypes = ["Private", "First", "Second", "Third"];

const EditDrivingLicense = ({ handleClose, nationalIdData }) => {
  const { empCode } = useParams();
  const { data, isLoading, isRefetching } = useQuery({
    queryKey: ["drivingLicenseFormData", empCode],
    queryFn: () =>
      employeeRecordsService.getEmployeeDrivingLicenseData(empCode),
  });

  if (isLoading || isRefetching) return <FormSpinner />;

  return (
    <Form
      data={data.DrivingLicense[0]}
      handleClose={handleClose}
      nationalIdData={nationalIdData}
    />
  );
};

const Form = ({ data, handleClose, nationalIdData }) => {
  const { empCode } = useParams();
  const queryClient = useQueryClient();
  const [t] = useTranslation("global");
  const { enqueueSnackbar } = useSnackbar();

  const currentFrontImage = data
    ? data.EmpInfoDrivingLicenseFront !== null &&
      convertImage(data.EmpInfoDrivingLicenseFront.data)
    : null;

  const currentBackImage = data
    ? data.EmpInfoDrivingLicenseBack !== null &&
      convertImage(data.EmpInfoDrivingLicenseBack.data)
    : null;

  const {
    EmpInfoDrivingLicenseNo,
    LicenseEndDate,
    LicenseRegistrationDate,
    LicenseTrafficDepartment,
    LicenseTrafficUnit,
    DrivingLicenseType,
  } = data || {
    EmpInfoDrivingLicenseNo: "",
    LicenseEndDate: currentDate,
    LicenseRegistrationDate: currentDate,
    LicenseTrafficDepartment: "",
    LicenseTrafficUnit: "",
    DrivingLicenseType: "",
  };

  const initialValues = {
    frontImage: null,
    backImage: null,
    drivingLicenseNo: EmpInfoDrivingLicenseNo,
    licenseType: DrivingLicenseType,
    registrationDate: LicenseRegistrationDate,
    endData: LicenseEndDate,
    trafficUnit: LicenseTrafficUnit,
    trafficDepartment: LicenseTrafficDepartment,
  };

  const schema = yup.object().shape({
    drivingLicenseNo: yup
      .string()
      .required(t("modules.global_schema.required")),
    frontImage: yup
      .mixed()
      .test("fileSize", t("modules.global_schema.file_size"), (value) => {
        return !value || (value && value.size <= MAX_FILE_SIZE);
      })
      .test(
        "fileType",
        t("modules.global_schema.file_type"),
        (value) => !value || (value && supportedImageTypes.includes(value.type))
      )
      .nullable(),
    backImage: yup
      .mixed()
      .test("fileSize", t("modules.global_schema.file_size"), (value) => {
        return !value || (value && value.size <= MAX_FILE_SIZE);
      })
      .test(
        "fileType",
        t("modules.global_schema.file_type"),
        (value) => !value || (value && supportedImageTypes.includes(value.type))
      )
      .nullable(),
    licenseType: yup
      .string()
      .required(t("modules.global_schema.select_value"))
      .oneOf(licenseTypes, t("modules.global_schema.invalid_value")),
    registrationDate: yup.date().required(t("modules.global_schema.required")),
    endData: yup.date().required(t("modules.global_schema.required")),
    trafficUnit: yup.string().required(t("modules.global_schema.required")),
    trafficDepartment: yup
      .string()
      .required(t("modules.global_schema.required")),
  });

  const {
    register,
    handleSubmit,
    setValue,
    setError,
    control,
    getValues,
    formState: { errors, isDirty },
  } = useForm({
    resolver: yupResolver(schema),
    mode: "onChange",
    defaultValues: initialValues,
  });

  const handleFormSubmit = (values) => {
    const {
      frontImage,
      backImage,
      drivingLicenseNo,
      licenseType,
      registrationDate,
      endData,
      trafficUnit,
      trafficDepartment,
    } = values;

    if (!currentFrontImage && getValues().frontImage === null) {
      return setError("frontImage", {
        message: t("modules.global_schema.required"),
        type: "required",
      });
    } else if (!currentBackImage && getValues().backImage === null) {
      return setError("backImage", {
        message: t("modules.global_schema.required"),
        type: "required",
      });
    }

    const formData = new FormData();

    formData.append("EmpInfoDrivingLicenseFront", frontImage);
    formData.append("EmpInfoDrivingLicenseBack", backImage);
    formData.append(
      "LicenseRegistrationDate",
      dayjs(registrationDate).format("YYYY-MM-DD")
    );
    formData.append("LicenseEndDate", dayjs(endData).format("YYYY-MM-DD"));
    formData.append("EmpInfoDrivingLicenseNo", drivingLicenseNo);
    formData.append("LicenseTrafficDepartment", trafficDepartment);
    formData.append("LicenseTrafficUnit", trafficUnit);
    formData.append("DrivingLicenseType", licenseType);
    formData.append("SelectedNo", empCode);
    formData.append("State", "DrivingLicense");

    updateDrivingLicenseData(formData);
  };

  const { mutateAsync: updateDrivingLicenseData, isPending } = useMutation({
    mutationFn: employeeRecordsService.addNewEmployeeDocument,
    onSuccess: (data) => {
      handleClose();
      enqueueSnackbar(data.msg, { variant: "success" });
      queryClient.invalidateQueries({ queryKey: ["employeeInfo"] });
    },
  });

  // Images handling logic
  const [selectedFrontImage, setSelectedFrontImage] = useState(null);
  const [selectedBackImage, setSelectedBackImage] = useState(null);

  const handleImageFrontChange = (event) => {
    setError("frontImage", null);
    const file = event.currentTarget.files[0];
    if (!file) return;
    setSelectedFrontImage(URL.createObjectURL(file));
  };

  const handleImageBackChange = (event) => {
    setError("backImage", null);
    const file = event.currentTarget.files[0];
    if (!file) return;
    setValue("backImage", file);
    setSelectedBackImage(URL.createObjectURL(file));
  };

  const labels = useLabels();

  const { setOpen } = useConfirmationDialog();

  const onClickClose = () => {
    if (isDirty) {
      setOpen(t("modules.confirmation_message"), () => handleClose);
    } else {
      handleClose();
    }
  };

  const [isArabic, setIsArabic] = useState(false);

  const handleChange = (event) => {
    const inputValue = event.target.value;

    // Check if the input contains any Arabic characters
    const isArabicInput = /[\u0600-\u06FF]/.test(inputValue);
    setIsArabic(isArabicInput);
  };

  const { isLoading, isRefetching, refetch } = useQuery({
    queryKey: ["nationalIdFormData", empCode],
    queryFn: () => employeeRecordsService.getNationalIdFormData(empCode),
    enabled: false,
  });

  return (
    <Box component="form" onSubmit={handleSubmit(handleFormSubmit)} noValidate>
      <Stack spacing={2} p={2}>
        <Box
          sx={{
            display: "grid",
            gridTemplateColumns: "1fr 1fr",
            gap: "10px",
            justifyItems: "center",
          }}
        >
          <Stack spacing={1}>
            <Typography
              sx={{
                fontSize: "14px",
                fontStyle: "italic",
              }}
            >
              * {t("modules.company_fleet.new_form.fields.upload_front")}
            </Typography>
            <ImagePickerBox
              error={errors.frontImage}
              image={selectedFrontImage || currentFrontImage}
              handleImage={handleImageFrontChange}
              control={control}
              name="frontImage"
            />
          </Stack>
          <Stack spacing={1}>
            <Typography
              sx={{
                fontSize: "14px",
                fontStyle: "italic",
              }}
            >
              * {t("modules.company_fleet.new_form.fields.upload_back")}
            </Typography>
            <ImagePickerBox
              error={errors.backImage}
              image={selectedBackImage || currentBackImage}
              handleImage={handleImageBackChange}
              control={control}
              name="backImage"
            />
          </Stack>
        </Box>
        <Box
          sx={{
            display: "grid",
            gridTemplateColumns: "1fr 1fr",
            gap: "16px",
          }}
        >
          <Stack direction="row" spacing={2}>
            <TextField
              size="small"
              label={labels.drivingLicenseNo}
              error={Boolean(errors.drivingLicenseNo)}
              helperText={errors.drivingLicenseNo?.message}
              {...register("drivingLicenseNo")}
              required
              InputProps={{
                value: getValues("drivingLicenseNo"),
              }}
            />
            <LoadingButton
              disabled={!nationalIdData}
              variant="contained"
              color="secondary"
              onClick={() =>
                refetch().then((data) => {
                  setValue(
                    "drivingLicenseNo",
                    data?.data?.NationalID[0]?.EmpNationalID || ""
                  );
                })
              }
              loading={isLoading || isRefetching}
              sx={{
                fontSize: "12px",
                flexShrink: 0,
                padding: "6px 10px",
                alignSelf: "flex-start",
              }}
            >
              {t(
                "info_modules.employee_records.cards.driving_license.edit_form.buttons.use_national_id"
              )}
            </LoadingButton>
          </Stack>
          <Controller
            control={control}
            name="licenseType"
            render={({ field: { onChange, value } }) => (
              <Autocomplete
                disableClearable
                size="small"
                options={licenseTypes}
                onChange={(event, item) => {
                  onChange(item);
                }}
                value={value || null}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label={labels.drivingLicenseType}
                    error={Boolean(errors.licenseType)}
                    helperText={errors.licenseType?.message}
                  />
                )}
              />
            )}
          />

          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <Controller
              control={control}
              name="registrationDate"
              render={({ field }) => {
                return (
                  <DatePicker
                    label={labels.licenseRegistrationDate}
                    views={["year", "month", "day"]}
                    name="registrationDate"
                    format="yyyy/MM/dd"
                    disableFuture
                    value={field.value}
                    inputRef={field.ref}
                    onChange={(date) => {
                      field.onChange(date);
                    }}
                    slotProps={{
                      textField: {
                        error: Boolean(errors.registrationDate),
                        helperText: errors.registrationDate?.message,
                        required: true,
                        size: "small",
                      },
                    }}
                  />
                );
              }}
            />
          </LocalizationProvider>
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <Controller
              control={control}
              name="endData"
              render={({ field }) => {
                return (
                  <DatePicker
                    label={labels.licenseEndDate}
                    views={["year", "month", "day"]}
                    name="endData"
                    format="yyyy/MM/dd"
                    value={field.value}
                    inputRef={field.ref}
                    onChange={(date) => {
                      field.onChange(date);
                    }}
                    slotProps={{
                      textField: {
                        error: Boolean(errors.endData),
                        helperText: errors.endData?.message,
                        required: true,
                        size: "small",
                      },
                    }}
                  />
                );
              }}
            />
          </LocalizationProvider>
          <TextField
            size="small"
            label={labels.licenseTrafficUnit}
            error={Boolean(errors.trafficUnit)}
            helperText={errors.trafficUnit?.message}
            {...register("trafficUnit", {
              onChange: handleChange,
            })}
            required
            inputProps={{
              style: {
                direction: isArabic ? "rtl" : "ltr",
                unicodeBidi: "plaintext",
              },
            }}
          />
          <TextField
            size="small"
            label={labels.licenseTrafficDepartment}
            error={Boolean(errors.trafficDepartment)}
            helperText={errors.trafficDepartment?.message}
            {...register("trafficDepartment", {
              onChange: handleChange,
            })}
            required
            inputProps={{
              style: {
                direction: isArabic ? "rtl" : "ltr",
                unicodeBidi: "plaintext",
              },
            }}
          />
        </Box>
      </Stack>

      <ButtonGroup
        isPending={isPending}
        onClickClose={onClickClose}
        saveLabel={t("modules.buttons.save")}
      />
    </Box>
  );
};

export default EditDrivingLicense;
