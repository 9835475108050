import { Box, Autocomplete, TextField, Typography, Grid } from "@mui/material";
import { useTranslation } from "react-i18next";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DateRangePicker } from "@mui/x-date-pickers-pro/DateRangePicker";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";

const QuotationDetails = ({
  startDate,
  endDate,
  setStartDate,
  setEndDate,
  comboBoxData,
  errors,
  values,
  handleChange,
  setFieldValue,
  d6Type,
  isThreeStepProcess,
}) => {
  const [t] = useTranslation("global");

  const handleSelectionChange = (value) => {
    setFieldValue("selectedD6", value?.WorkOrderD6TypeName);
  };

  const fields = [
    {
      name: "acquisitionContractor",
      label: "data_grid.headers.acquisition_contractor",
      error: errors.acquisitionContractor,
      helperText: errors.acquisitionContractor,
    },
    {
      name: "constructionContractor",
      label: "data_grid.headers.construction_contractor",
      error: errors.constructionContractor,
      helperText: errors.constructionContractor,
    },
    {
      name: "supportType",
      label: "data_grid.headers.support_type",
      error: errors.supportType,
      helperText: errors.supportType,
    },
    {
      name: "supportHeight",
      label: "data_grid.headers.support_height",
      error: errors.supportHeight,
      helperText: errors.supportHeight,
    },
    {
      name: "numberOfSectors",
      label: "data_grid.headers.number_of_sectors",
      error: errors.numberOfSectors,
      helperText: errors.numberOfSectors,
    },
    {
      name: "equipmentRoom",
      label: "data_grid.headers.equipment_room",
      error: errors.equipmentRoom,
      helperText: errors.equipmentRoom,
    },
    {
      name: "firstRunPower",
      label: "data_grid.headers.first_run_power",
      error: errors.firstRunPower,
      helperText: errors.firstRunPower,
    },
    {
      name: "sharingPowerSource",
      label: "data_grid.headers.sharing_power_source",
      error: errors.sharingPowerSource,
      helperText: errors.sharingPowerSource,
    },
    {
      name: "consultant",
      label: "data_grid.headers.consultant",
      error: errors.consultant,
      helperText: errors.consultant,
    },
    {
      name: "sharingStatus",
      label: "data_grid.headers.sharing_status",
      error: errors.sharingStatus,
      helperText: errors.sharingStatus,
    },
  ];

  return (
    <Box
      sx={{
        margin: "20px 10px",
        display: "flex",
        flexDirection: "column",
        gap: 2.4,
      }}
    >
      <Grid container spacing={1.5}>
        <Grid item xs={8}>
          <LocalizationProvider
            dateAdapter={AdapterDayjs}
            localeText={{
              start: t("modules.acceptance.labels.start_date"),
              end: t("modules.acceptance.labels.end_date"),
            }}
          >
            <DemoContainer components={["DateRangePicker"]}>
              <DateRangePicker
                defaultValue={[startDate, endDate]}
                value={[startDate, endDate]}
                format="YYYY/MM/DD"
                onAccept={(date) => {
                  setStartDate(date[0]);
                  setEndDate(date[1]);
                }}
                slotProps={{
                  textField: {
                    size: "small",
                  },
                  popper: {
                    placement: "top-start",
                  },
                }}
              />
            </DemoContainer>
          </LocalizationProvider>
        </Grid>
        <Grid item xs={4}>
          <Autocomplete
            id="combo-box-demo"
            options={comboBoxData}
            getOptionLabel={(option) => option.WorkOrderD6TypeName}
            sx={{ width: "100%", marginTop: "8px" }}
            size="small"
            value={
              comboBoxData.find(
                (option) => option.WorkOrderD6TypeName === values.selectedD6
              ) || null
            }
            onChange={(event, value) => {
              handleSelectionChange(value);
            }}
            renderInput={(params) => (
              <TextField
                {...params}
                label={t("modules.acceptance.labels.select_d6_type")}
                error={Boolean(errors.selectedD6)}
                helperText={errors.selectedD6}
              />
            )}
          />
        </Grid>
      </Grid>

      {isThreeStepProcess && (
        <Grid container spacing={1.5}>
          {fields.map((field) => (
            <Grid item xs={4} key={field.name}>
              <TextField
                name={field.name}
                label={t(field.label)}
                variant="outlined"
                size="small"
                fullWidth
                value={values[field.name]}
                onChange={handleChange}
                error={Boolean(errors[field.name])}
                helperText={errors[field.name]}
              />
            </Grid>
          ))}
        </Grid>
      )}

      {values.selectedD6 === "MSC ORA Single" && (
        <Box sx={{ display: "flex", flexDirection: "column", gap: 2 }}>
          <Typography sx={{ marginLeft: 0.5, fontSize: "14px" }}>
            MSC Information
          </Typography>
          <Box sx={{ display: "flex", gap: 4 }}>
            <TextField
              name="survey"
              label={t("modules.acceptance.labels.survey")}
              variant="outlined"
              size="small"
              fullWidth
              value={values.survey}
              onChange={handleChange}
            />
            <TextField
              name="powerUp"
              label={t("modules.acceptance.labels.power_up")}
              variant="outlined"
              size="small"
              fullWidth
              value={values.powerUp}
              onChange={handleChange}
            />
          </Box>
          <Box sx={{ display: "flex", gap: 4 }}>
            <TextField
              name="installation"
              label={t("modules.acceptance.labels.installation")}
              variant="outlined"
              size="small"
              fullWidth
              value={values.installation}
              onChange={handleChange}
            />
            <TextField
              name="projectName"
              label={t("modules.acceptance.labels.project_name")}
              variant="outlined"
              size="small"
              fullWidth
              value={values.projectName}
              onChange={handleChange}
            />
          </Box>
        </Box>
      )}
    </Box>
  );
};

export default QuotationDetails;
