import { Autocomplete, TextField, Box } from "@mui/material";
import { Controller } from "react-hook-form";
import ButtonGroup from "../ButtonGroup/ButtonGroup";
import { useTranslation } from "react-i18next";

const CreateNewAccountForm = ({
  onSubmit,
  isPending,
  onClickClose,
  data,
  register,
  handleSubmit,
  control,
  errors,
}) => {
  const [t] = useTranslation("global");

  const operatorNameOptions =
    data?.CompanyPhoneLinesOperatorName.map(
      (item) => item.CompanyPhoneLinesOperatorName
    ) || [];

  return (
    <form
      onSubmit={handleSubmit(onSubmit)}
      style={{ width: "100%" }}
      noValidate
    >
      <Box
        sx={{
          padding: "10px 3px",
          display: "flex",
          flexDirection: "column",
          gap: "10px",
        }}
      >
        <TextField
          size="small"
          label={t(
            "modules.mobile_internet_lines.new_form.fields.account_number"
          )}
          error={Boolean(errors.accountNumber)}
          helperText={errors.accountNumber?.message}
          {...register("accountNumber")}
          required
        />
        <Controller
          control={control}
          name={`operatorName`}
          render={({ field: { onChange, value } }) => (
            <Autocomplete
              size="small"
              options={operatorNameOptions}
              onChange={(event, item) => {
                onChange(item);
              }}
              value={value || null}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label={t(
                    "modules.mobile_internet_lines.new_form.fields.operator"
                  )}
                  error={!!errors.operatorName}
                  helperText={errors.operatorName?.message}
                  required
                />
              )}
            />
          )}
        />
        <ButtonGroup
          isPending={isPending}
          onClickClose={onClickClose}
          saveLabel={t("modules.buttons.save")}
        />
      </Box>
    </form>
  );
};

export default CreateNewAccountForm;
