import Axios from "../../network/Axios";
import { useSelector } from "react-redux";
import InfoModal from "../Modals/InfoModal";
import { useState, useReducer } from "react";
import { useTranslation } from "react-i18next";
import { useQuery } from "@tanstack/react-query";
import FormSpinner from "../Spinners/FormSpinner";
import ChipStatus from "../../components/ChipStatus";
import FormsDataGrid from "../DataGrid/FormsDataGrid";
import { Link as RouterLink } from "react-router-dom";
import { DashboardService } from "../../api/dashboard";
import { Box, Typography, Link, useTheme } from "@mui/material";
import WithOutGovInv from "../../pages/Dashboard/HyperLinksDataGrids/WithOutGovInv";

const ColorSquare = ({ color, statusColor }) => (
  <div
    style={{
      width: "11px",
      height: "11px",
      backgroundColor: statusColor || color,
      borderRadius: "2px",
      fontSize: "10px",
    }}
  />
);

const NotificationItem = ({ item }) => {
  const [t] = useTranslation("global");

  const theme = useSelector((state) => state.theme.colorTheme);

  // Check if this is the invoices notification
  const isInvoiceNotification = item.title === t("notifications.invoices");

  // Handle the invoices modal state when user clicks on the title
  const [isInvoicesModalOpen, setIsInvoicesModalOpen] = useState(false);

  const handleOpenInvoicesModal = () => setIsInvoicesModalOpen(true);

  const handleCloseInvoicesModal = () => setIsInvoicesModalOpen(false);

  return (
    <>
      <Box
        style={{
          padding: "8px",
          borderRadius: "5px",
          boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
          marginBottom: "6px",
        }}
        sx={{ backgroundColor: "background.innerCard" }}
      >
        <Box
          display="flex"
          gap="5px"
          alignItems="center"
          justifyContent="center"
        >
          <Link
            sx={{
              fontSize: "13px",
              fontWeight: "600",
              cursor: isInvoiceNotification ? "pointer" : "auto",
              color: "text.primary",
              textDecorationColor: theme === "dark" ? "#8a8a8a" : "#cfcfcf",
              ":hover": {
                textDecorationColor: theme === "dark" ? "#e6e6e6" : "#2e2e2e",
              },
            }}
            underline={isInvoiceNotification ? "always" : "none"}
            onClick={isInvoiceNotification ? handleOpenInvoicesModal : null}
          >
            {item.title}
          </Link>
        </Box>
        {item.status.map((status, index) => (
          <Box
            key={index}
            display="flex"
            gap="5px"
            alignItems="center"
            justifyContent="space-between"
            marginLeft="7px"
            marginTop={index > 0 ? "2px" : "5px"}
          >
            <Box display="flex" gap="5px" alignItems="center">
              <ColorSquare color={item.color} statusColor={status.color} />
              <Link
                underline="always"
                component="button"
                onClick={status.onClick}
                sx={{
                  fontSize: "13px",
                  color: "text.primary",
                  textDecorationColor: theme === "dark" ? "#8a8a8a" : "#cfcfcf",
                  ":hover": {
                    textDecorationColor:
                      theme === "dark" ? "#e6e6e6" : "#2e2e2e",
                  },
                }}
              >
                {status.text}
              </Link>
            </Box>
            <Typography sx={{ fontSize: "13px", fontWeight: 600 }}>
              {status.value}
            </Typography>
          </Box>
        ))}
      </Box>
      <InfoModal
        open={isInvoicesModalOpen}
        title={t("notifications.invoices")}
        content={<InvoicesModal />}
        handleClose={handleCloseInvoicesModal}
      />
    </>
  );
};

const InvoicesModal = () => {
  const { data, isLoading, isRefetching } = useQuery({
    queryKey: ["invoicesNotifications"],
    queryFn: DashboardService.getInvoicesNotifications,
  });

  if (isLoading || isRefetching) {
    return <FormSpinner />;
  }

  return <InvoicesModalContent data={data} />;
};

const InvoicesModalContent = ({ data }) => {
  const { t } = useTranslation("global");

  const columns = [
    {
      field: "InvoiceNo",
      headerName: t("data_grid.headers.ref_no"),
    },
    {
      field: "Activity",
      headerName: t("data_grid.headers.activity"),
    },
    {
      field: "ClientPONo",
      headerName: t("data_grid.headers.client_po_no"),
    },
    {
      field: "CreationDate",
      headerName: t("data_grid.headers.creation_date"),
    },
    {
      field: "D6Date",
      headerName: t("data_grid.headers.d6_date"),
    },
    {
      field: "ERPUserNickName",
      headerName: t("data_grid.headers.user_name"),
    },
    {
      field: "GovernmentInvoice",
      headerName: t("data_grid.headers.gov_invoice"),
    },
    {
      field: "InvoiceDate",
      headerName: t("data_grid.headers.invoice_date"),
    },
    {
      field: "InvoiceStatus",
      headerName: t("data_grid.headers.invoice_status"),
      renderCell: (params) => {
        return <ChipStatus value={params.value} />;
      },
    },
    {
      field: "InvoiceType",
      headerName: t("data_grid.headers.invoice_type"),
    },
    {
      field: "InvoicesAmount",
      headerName: t("data_grid.headers.amount"),
    },
    {
      field: "SubmissionDate",
      headerName: t("data_grid.headers.submission_date"),
    },
  ];

  return (
    <Box p={2}>
      <FormsDataGrid
        rows={data.AllInvoices}
        columns={columns}
        getRowId={(row) => row.idInvoices}
        disableColumnMenu
        showQuickFilter
        height={400}
        toolbar
        showExportButton
      />
    </Box>
  );
};

const NotificationItemWithOutClick = ({ item }) => {
  const { t } = useTranslation("global");

  const theme = useSelector((state) => state.theme.colorTheme);

  // Handle insurance modal state when user clicks on the label
  const [isInsuranceModalOpen, setIsInsuranceModalOpen] = useState(false);

  const handleOpenInsuranceModal = () => setIsInsuranceModalOpen(true);

  const handleCloseInsuranceModal = () => setIsInsuranceModalOpen(false);

  // Handle the insurance state
  const [insuranceState, setInsuranceState] = useState(null); // NotInsured or NotCovered

  return (
    <>
      <Box
        style={{
          padding: "8px",
          borderRadius: "5px",
          boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
          marginBottom: "6px",
        }}
        sx={{ backgroundColor: "background.innerCard" }}
      >
        <Box
          display="flex"
          gap="5px"
          alignItems="center"
          justifyContent="center"
        >
          <Typography
            sx={{
              fontSize: "13px",
              fontWeight: 600,
            }}
          >
            {item.title}
          </Typography>
        </Box>
        {item.status.map((status, index) => {
          const isClickable =
            status.text === t("notifications.not_insured") ||
            status.text === t("notifications.not_covered");

          const isNotInsured = status.text === t("notifications.not_insured");
          const isNotCovered = status.text === t("notifications.not_covered");

          return (
            <Box
              key={status.text}
              display="flex"
              gap="5px"
              alignItems="center"
              justifyContent="space-between"
              marginLeft="7px"
              marginTop={index > 0 ? "2px" : "5px"}
            >
              <Box display="flex" gap="5px" alignItems="center">
                <ColorSquare color={item.color} statusColor={status.color} />
                <Link
                  sx={{
                    fontSize: "13px",
                    cursor: isClickable ? "pointer" : "auto",
                    color: "text.primary",
                    textDecorationColor:
                      theme === "dark" ? "#8a8a8a" : "#cfcfcf",
                    ":hover": {
                      textDecorationColor:
                        theme === "dark" ? "#e6e6e6" : "#2e2e2e",
                    },
                  }}
                  underline={isClickable ? "always" : "none"}
                  onClick={
                    isClickable
                      ? () => {
                          if (isNotInsured) {
                            setInsuranceState("NotInsured");
                          } else if (isNotCovered) {
                            setInsuranceState("NotCovered");
                          }
                          handleOpenInsuranceModal();
                        }
                      : null
                  }
                >
                  {status.text}
                </Link>
              </Box>
              <Typography sx={{ fontSize: "13px", fontWeight: 600 }}>
                {status.value}
              </Typography>
            </Box>
          );
        })}
      </Box>
      <InfoModal
        open={isInsuranceModalOpen}
        title={
          insuranceState === "NotInsured"
            ? t("notifications.social_insurance")
            : t("notifications.medical_insurance")
        }
        content={<InsuranceModal insuranceState={insuranceState} />}
        handleClose={handleCloseInsuranceModal}
      />
    </>
  );
};

const InsuranceModal = ({ insuranceState }) => {
  const { data, isLoading, isRefetching } = useQuery({
    queryKey: ["insurancesNotifications"],
    queryFn: () => DashboardService.getInsurancesNotifications(insuranceState),
  });

  if (isLoading || isRefetching) {
    return <FormSpinner />;
  }

  return <InsuranceModalContent data={data} />;
};

const InsuranceModalContent = ({ data }) => {
  const { t } = useTranslation("global");

  const columns = [
    {
      field: "EmpCode",
      headerName: t("data_grid.headers.emp_code"),
      renderCell: (params) => {
        return (
          <RouterLink
            style={{
              color: "#1790FF",
            }}
            to={`/employeerecords/${params.value.replace("/", "%2F")}`}
            target="_blank"
          >
            {params.value}
          </RouterLink>
        );
      },
    },
    {
      field: "EmpName",
      headerName: t("data_grid.headers.arabic_name"),
      width: 200,
    },
    {
      field: "EmpNameEn",
      headerName: t("data_grid.headers.english_name"),
      width: 200,
    },
    {
      field: "EmpTitle",
      headerName: t("data_grid.headers.title"),
    },
    {
      field: "EmpDateOfHire",
      headerName: t("data_grid.headers.hire_date"),
    },
  ];

  return (
    <Box p={2}>
      <FormsDataGrid
        rows={data.NationalIDexpired}
        columns={columns}
        getRowId={(row) => row.EmpCode}
        disableColumnMenu
        showQuickFilter
        height={400}
        toolbar
        showExportButton
      />
    </Box>
  );
};

const Notifications = (props) => {
  const [t] = useTranslation("global");

  const initialState = {
    withOutGovInvModal: false,
    withNoSubmissionDateModal: false,
  };

  const reducer = (state, action) => {
    switch (action.type) {
      case "WITHOUT_GOV_INV":
        return { ...state, withOutGovInvModal: action.payload };
      case "WITH_NO_SUBMISSION_DATE":
        return { ...state, withNoSubmissionDateModal: action.payload };
      default:
        return state;
    }
  };

  const [state, dispatch] = useReducer(reducer, initialState);

  const { withOutGovInvModal, withNoSubmissionDateModal } = state;

  const stateToKeyMapping = {
    AllInvoicesWithNoGovernmentInvoice: "AllInvoicesWithNoGovernmentInvoice",
    AllInvoicesWithNoSubmissionDate: "AllInvoicesWithNoSubmissionDate",
    AllInvoicesWithNoPO: "AllInvoicesWithNoPO",
    QuotationsNotInvoiced: "QuotationsNotInvoiced",
  };

  const fetchData = async (state) => {
    const response = await Axios.get("/UsersManagement", {
      params: { State: state },
    });
    const key = stateToKeyMapping[state];
    return response.data[key];
  };

  const useCustomQuery = (queryKey, state, enabled) => {
    return useQuery({
      queryKey: [queryKey],
      queryFn: () => fetchData(state),
      enabled: enabled,
    });
  };

  const { data: withNoGovInvoice, isLoading: withNoGovLoading } =
    useCustomQuery(
      "dashboardNoGov",
      "AllInvoicesWithNoGovernmentInvoice",
      withOutGovInvModal
    );

  const { data: withNoSubmissionDate, isLoading: withNoSubmissionDateLoading } =
    useCustomQuery(
      "dashboardNoSubmissionDate",
      "AllInvoicesWithNoSubmissionDate",
      withNoSubmissionDateModal
    );

  const data = [
    {
      title: t("notifications.invoices"),
      color: "#7D7C7C",
      status: [
        {
          text: t("notifications.no_government_invoices"),
          value: props.noGovernorateInvoices,
          color: "#cc0000",
          onClick: () => dispatch({ type: "WITHOUT_GOV_INV", payload: true }),
        },
        {
          text: t("notifications.no_submission_date_invoices"),
          value: props.noSubmissionDateInvoices,
          color: "#cc0000",
          onClick: () =>
            dispatch({ type: "WITH_NO_SUBMISSION_DATE", payload: true }),
        },
      ],
    },
  ];

  const dataLinkWithOutLink = [
    {
      title: t("notifications.social_insurance"),
      color: "#7D7C7C",
      status: [
        {
          text: t("notifications.not_insured"),
          value: props.notInsured,
          color: "#cc0000",
        },
        {
          text: t("notifications.insured"),
          value: props.insured,
          color: "#357a38",
        },
      ],
    },
    {
      title: t("notifications.medical_insurance"),
      color: "#7D7C7C",
      status: [
        {
          text: t("notifications.not_covered"),
          value: props.notCovered,
          color: "#cc0000",
        },
        {
          text: t("notifications.covered"),
          value: props.covered,
          color: "#357a38",
        },
      ],
    },
  ];

  const filteredData = data.filter((item) =>
    item.status.some((status) => status.value)
  );

  const filteredDataWithOutLink = dataLinkWithOutLink.filter((item) =>
    item.status.some((status) => status.value)
  );

  const theme = useTheme();

  return (
    <Box
      backgroundColor="background.card"
      padding="10px"
      borderRadius="5px"
      border={`1px solid ${theme.palette.secondary.main}`}
    >
      {filteredData.map((item, index) => (
        <NotificationItem key={index} item={item} />
      ))}
      {filteredDataWithOutLink.map((item, index) => (
        <NotificationItemWithOutClick key={index} item={item} />
      ))}
      <WithOutGovInv
        open={withOutGovInvModal}
        handleClose={() =>
          dispatch({ type: "WITHOUT_GOV_INV", payload: false })
        }
        data={withNoGovInvoice || []}
        isLoading={withNoGovLoading}
        title={t("hyper_links_data_grid.no_gov_inv")}
      />
      <WithOutGovInv
        open={withNoSubmissionDateModal}
        handleClose={() =>
          dispatch({ type: "WITH_NO_SUBMISSION_DATE", payload: false })
        }
        data={withNoSubmissionDate || []}
        isLoading={withNoSubmissionDateLoading}
        title={t("hyper_links_data_grid.inv_without_sub_date")}
      />
    </Box>
  );
};

export default Notifications;
