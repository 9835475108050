import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";

const InfoDialog = ({ title, description, handleClose, open }) => {
  const [t] = useTranslation("global");

  const descriptionElementRef = useRef(null);

  useEffect(() => {
    if (open) {
      const { current: descriptionElement } = descriptionElementRef;
      if (descriptionElement !== null) {
        descriptionElement.focus();
      }
    }
  }, [open]);

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      scroll={"paper"}
      aria-labelledby="info dialog"
      aria-describedby="description of the mission change request"
    >
      <DialogTitle id="scroll-dialog-title">{title}</DialogTitle>
      <DialogContent>
        <DialogContentText
          id="info dialog description"
          ref={descriptionElementRef}
          tabIndex={-1}
        >
          {description}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>{t("buttons.close")}</Button>
      </DialogActions>
    </Dialog>
  );
};

export default InfoDialog;
