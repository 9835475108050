import { Box, Stack, Typography } from "@mui/material";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { vacationsRequestsService } from "../../../../../api/vacationsRequests";
import { useParams } from "react-router-dom";
import FormSpinner from "../../../../../components/Spinners/FormSpinner";
import { useSnackbar } from "notistack";
import { useState } from "react";
import { GridActionsCellItem } from "@mui/x-data-grid";
import DeleteIcon from "@mui/icons-material/Delete";
import Edit from "@mui/icons-material/Edit";
import Image from "@mui/icons-material/Image";
import { useConfirmationDialog } from "../../../../../contexts/ConfirmationDialogContext";
import FormsDataGrid from "../../../../../components/DataGrid/FormsDataGrid";
import ImageModal from "../../../../../components/Modals/ImageModal";
import Axios from "../../../../../network/Axios";
import useImagePopup from "../../../../../hooks/useImagePopup";
import InfoModal from "../../../../../components/Modals/InfoModal";
import EditVacationRequest from "./NewEditForm/EditVacationRequest";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import ChipStatus from "../../../../../components/ChipStatus";
import { statusColors } from "../../../../statuses";

const VacationsList = ({ year }) => {
  const [t] = useTranslation("global");

  const { language } = useSelector((state) => state.theme); // AR | EN

  const { empCode } = useParams();

  const { enqueueSnackbar } = useSnackbar();
  const queryClient = useQueryClient();

  const { setOpen } = useConfirmationDialog();

  // Handling the edit modal
  const [isModalOpen, setIsModalOpen] = useState(false);

  const handleOpenModal = () => setIsModalOpen(true);
  const handleCloseModal = () => setIsModalOpen(false);

  // VacationID state
  const [vacationID, setVacationID] = useState(null);

  const { data, isLoading } = useQuery({
    queryKey: ["employeeVacationsList", empCode, year],
    queryFn: () =>
      vacationsRequestsService.getEmployeeVacationListByYear(empCode, year),
  });

  const { mutateAsync: deleteVacationRecord } = useMutation({
    mutationFn: vacationsRequestsService.deleteVacationRecord,
    onSuccess: (data) => {
      enqueueSnackbar(data.msg, { variant: "success" });
      queryClient.invalidateQueries({
        predicate: (query) => {
          return ["employeeVacationsList", "employeeInfo"].includes(
            query.queryKey[0]
          );
        },
      });
    },
  });

  const fetchImage = async (id) => {
    try {
      const response = await Axios.get("/VacationRequests", {
        params: {
          SelectedID: id,
        },
      });
      return response.data.result;
    } catch (error) {
      console.log(error);
    }
  };

  const {
    isImageLoading,
    isImageModalOpen,
    image,
    handleImageModalOpen,
    handleCloseImageModal,
  } = useImagePopup(fetchImage, "VacationAttachement");

  // Handle save label
  const [saveLabel, setSaveLabel] = useState("");

  const columns = [
    {
      field: "VacationsTypesName",
      headerName: t("data_grid.headers.vacation_type"),
      renderCell: ({ value }) => {
        return <ChipStatus value={value} />;
      },
    },
    {
      field: "VacationStartDate",
      headerName: t("data_grid.headers.start_date"),
    },
    {
      field: "VacationEndDate",
      headerName: t("data_grid.headers.end_date"),
    },
    {
      field: "Total",
      headerName: t("data_grid.headers.total"),
    },
    {
      field: "Actions",
      type: "actions",
      headerName: "Actions",
      flex: 1,
      getActions: (params) => [
        <GridActionsCellItem
          key="image"
          icon={<Image />}
          label="Image"
          onClick={() => {
            setSaveLabel(
              `${params.row.EmpCode.replace("/", "-")}-${
                params.row.VacationsTypesName
              }-${params.row.VacationStartDate}`
            );
            handleImageModalOpen(params.row.idEmpVacation);
          }}
          disabled={params.row.VacationAttachementStatus !== "True"}
        />,
        <GridActionsCellItem
          key="edit"
          icon={<Edit />}
          label="Edit"
          onClick={() => {
            setVacationID(params.row.idEmpVacation);
            handleOpenModal();
          }}
        />,
        <GridActionsCellItem
          key="delete"
          icon={<DeleteIcon color="error" />}
          label="Delete"
          onClick={() => {
            setOpen(
              t("info_modules.employee_records.confirmation.delete_record"),
              () => () => {
                deleteVacationRecord(params.row.idEmpVacation);
              },
              "error"
            );
          }}
        />,
      ],
    },
  ];

  if (isLoading) return <FormSpinner />;

  return (
    <>
      <Box
        sx={{
          padding: "1rem",
          display: "flex",
          flexDirection: "column",
          gap: "1rem",
        }}
      >
        <Stack direction="row" spacing={1} flexWrap="wrap">
          {data.Vacations.map(
            ({ VacationsTypesName, VacationsTypesNameArabic, Total }) => {
              return (
                <Stack
                  key={VacationsTypesName}
                  direction="row"
                  alignItems="center"
                  sx={{
                    borderRadius: 1,
                  }}
                >
                  <Typography
                    sx={{
                      fontSize: 12,
                      backgroundColor: "background.main",
                      padding: "0.25rem 0.5rem",
                    }}
                  >
                    {language === "AR"
                      ? VacationsTypesNameArabic
                      : VacationsTypesName}
                  </Typography>
                  <Typography
                    sx={{
                      backgroundColor: statusColors.find(
                        (status) => status.name === VacationsTypesName
                      ).backgroundColor,
                      color: "white",
                      padding: "0.25rem 0.5rem",
                      fontSize: 12,
                      fontWeight: 600,
                    }}
                  >
                    {Total}
                  </Typography>
                </Stack>
              );
            }
          )}
        </Stack>
        <FormsDataGrid
          rows={data.result}
          columns={columns}
          getRowId={(row) => row.idEmpVacation}
          height={300}
        />
      </Box>
      <ImageModal
        open={isImageModalOpen}
        handleClose={handleCloseImageModal}
        image={image}
        loading={isImageLoading}
        saveLabel={saveLabel}
      />
      <InfoModal
        open={isModalOpen}
        title={t(
          "info_modules.employee_records.cards.vacations.edit_form.title"
        )}
        content={
          <EditVacationRequest
            handleClose={handleCloseModal}
            vacationID={vacationID}
          />
        }
        handleClose={handleCloseModal}
        width="30rem"
      />
    </>
  );
};

export default VacationsList;
