import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  workPerProject: [],
  uncompletedAcceptance: [],
  workOrderReceived: [],
  quotationApproaved: [],
  workOrderStatus: [],
  pendingStatus: [],
  workInProgress: [],
  openMissions: [],
  insured: [],
  notInsured: [],
  coverd: [],
  notCoverd: [],
  idExpired: [],
  idExpiredNextMonth: [],
  drivingExpired: [],
  drivingExpiredNextNonth: [],
  carExpired: [],
  carExpiredNextMonth: [],
  vacations: [],
  quotationNotInvoiced: [],
  noPoInvoices: [],
  noGovernmentInvoice: [],
  noSubmissionDate: [],
  materialRequest: [],
  materialRequestSent: [],
  missionsRequest: [],
  loading: true,
};

const dashboardSlice = createSlice({
  name: 'dashboard',
  initialState,
  reducers: {
    setWorkPerProject: (state, action) => {
      state.workPerProject = action.payload;
    },
    setUncompletedAcceptance: (state, action) => {
      state.uncompletedAcceptance = action.payload;
    },
    setWorkOrderReceived: (state, action) => {
      state.workOrderReceived = action.payload;
    },
    setQuotationApproaved: (state, action) => {
      state.quotationApproaved = action.payload;
    },
    setWorkOrderStatus: (state, action) => {
      state.workOrderStatus = action.payload;
    },
    setPendingStatus: (state, action) => {
      state.pendingStatus = action.payload;
    },
    setWorkInProgress: (state, action) => {
      state.workInProgress = action.payload;
    },
    setOpenMissions: (state, action) => {
      state.openMissions = action.payload;
    },
    setInsured: (state, action) => {
      state.insured = action.payload;
    },
    setNotInsured: (state, action) => {
      state.notInsured = action.payload;
    },
    setCovered: (state, action) => {
      state.coverd = action.payload;
    },
    setNotCovered: (state, action) => {
      state.notCoverd = action.payload;
    },
    setIdExpired: (state, action) => {
      state.idExpired = action.payload
    },
    setIdExpiredNextMonth: (state, action) => {
      state.idExpiredNextMonth = action.payload;
    },
    setDrivingExpired: (state, action) => {
      state.drivingExpired = action.payload;
    },
    setDrivingExpiredNextMonth: (state, action) => {
      state.drivingExpiredNextNonth = action.payload;
    },
    setCarExpired: (state, action) => {
      state.carExpired = action.payload;
    },
    setCarExpiredNextMonth: (state, action) => {
      state.carExpiredNextMonth = action.payload;
    },
    setVacations: (state, action) => {
      state.vacations = action.payload;
    },
    setQuotationNotInvoiced: (state, action) => {
      state.quotationNotInvoiced = action.payload;
    },
    setNoPoInvoices: (state, action) => {
      state.noPoInvoices = action.payload;
    },
    setNoGovernmentalInvoice: (state, action) => {
      state.noGovernmentInvoice = action.payload;
    },
    setNoSubmissionDate: (state, action) => {
      state.noSubmissionDate = action.payload;
    },
    setMaterialRequest: (state, action) => {
      state.materialRequest = action.payload;
    },
    setMaterialRequestSent: (state, action) => {
      state.materialRequestSent = action.payload;
    },
    setMissionsRequest: (state, action) => {
      state.missionsRequest = action.payload;
    },
    setLoading: (state, action) => {
      state.loading = action.payload;
    },
  },
});

export const {
  setWorkPerProject,
  setUncompletedAcceptance,
  setWorkOrderReceived,
  setQuotationApproaved,
  setWorkOrderStatus,
  setPendingStatus,
  setWorkInProgress,
  setOpenMissions,
  setInsured,
  setNotInsured,
  setCovered,
  setNotCovered,
  setIdExpired,
  setIdExpiredNextMonth,
  setDrivingExpired,
  setDrivingExpiredNextMonth,
  setCarExpired,
  setCarExpiredNextMonth,
  setVacations,
  setQuotationNotInvoiced,
  setNoPoInvoices,
  setNoGovernmentalInvoice,
  setNoSubmissionDate,
  setMaterialRequest,
  setMaterialRequestSent,
  setMissionsRequest,
  setLoading,
} = dashboardSlice.actions;

export default dashboardSlice.reducer;
