import { Box, Stack, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import FormsDataGrid from "../../../DataGrid/FormsDataGrid";

const DataPill = ({ label, value }) => {
  return (
    <Stack
      direction={"row"}
      spacing={1}
      sx={{
        p: 1,
        borderRadius: 1,
        border: "1px solid #e0e0e0",
        width: "fit-content",
      }}
    >
      <Typography fontWeight={500}>{label}:</Typography>
      <Typography>{value}</Typography>
    </Stack>
  );
};

const Save = ({ values, selectedRows }) => {
  const [t] = useTranslation("global");

  const columns = [
    {
      field: "StoreItemsTemplateCode",
      headerName: t("data_grid.headers.item_code"),
    },

    {
      field: "StoreItemsTemplateName",
      headerName: t("data_grid.headers.item_description"),
      flex: 1,
    },
    {
      field: "StoreItemsTemplateType",
      headerName: t("data_grid.headers.type"),
    },
    {
      field: "StoreItemsTemplateDescription",
      headerName: t("data_grid.headers.description"),
    },
    {
      field: "StoreItemsTemplateUnit",
      headerName: t("data_grid.headers.unit"),
    },
    {
      field: "StoreItemsTemplateQty",
      headerName: t("data_grid.headers.item_total_balance"),
    },
    {
      field: "QTY",
      headerName: t("data_grid.headers.quantity"),
    },
  ];

  const { itemName, itemCode, unit, length, type, minQuantity, description } =
    values;

  const dataPills = [
    {
      label: t("modules.inventory_management.new_form.fields.item_name"),
      value: itemName,
    },
    {
      label: t("modules.inventory_management.new_form.fields.item_code"),
      value: itemCode,
    },
    {
      label: t("modules.inventory_management.new_form.fields.unit"),
      value: unit,
    },
    {
      label: t("modules.inventory_management.new_form.fields.length"),
      value: length,
    },
    {
      label: t("modules.inventory_management.new_form.fields.type.title"),
      value: type,
    },
    {
      label: t("modules.inventory_management.new_form.fields.min_quantity"),
      value: minQuantity,
    },
    {
      label: t("modules.inventory_management.new_form.fields.description"),
      value: description,
    },
  ];

  return (
    <Stack spacing={3}>
      <Typography variant="h6" color={"primary"}>
        {t("modules.inventory_management.new_form.details")}
      </Typography>
      <Box
        sx={{
          display: "grid",
          gridTemplateColumns: "repeat(3, 1fr)",
          gap: "16px",
        }}
      >
        {dataPills.map((pill) => (
          <DataPill key={pill.label} label={pill.label} value={pill.value} />
        ))}
      </Box>
      {type === "Template" && (
        <FormsDataGrid
          rows={selectedRows}
          columns={columns}
          getRowId={(row) => row.StoreItemsTemplateCode}
          disableColumnMenu
          height={300}
        />
      )}
    </Stack>
  );
};

export default Save;
