import {
  Backdrop,
  Button,
  Fade,
  Modal,
  Stack,
  Typography,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";
import { useTranslation } from "react-i18next";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  backgroundColor: "background.default",
  boxShadow:
    "rgba(145, 158, 171, 0.24) 0px 0px 2px 0px, rgba(145, 158, 171, 0.24) -20px 20px 40px -4px",
  borderRadius: "10px",
  p: 3,
  "&:focus-visible": {
    outline: "none",
  },
};

const ConfirmationInfoModal = ({
  open,
  handleClose,
  onClick,
  description,
  isPending,
}) => {
  const [t] = useTranslation("global");
  return (
    <Modal
      open={open}
      closeAfterTransition
      slots={{ backdrop: Backdrop }}
      slotProps={{
        backdrop: {
          timeout: 500,
        },
      }}
    >
      <Fade in={open}>
        <Stack sx={style} spacing={3}>
          <Stack direction={"row"} spacing={2}>
            <Typography id="transition-modal-description">
              {description}
            </Typography>
          </Stack>
          <Stack
            direction={"row"}
            spacing={2}
            sx={{
              justifyContent: "center",
              width: "100%",
            }}
          >
            <LoadingButton
              loading={isPending}
              variant="contained"
              color="success"
              sx={{
                alignSelf: "center",
                fontSize: 16,
                textTransform: "capitalize",
                px: 1.5,
                borderRadius: 3,
                color: "white",
              }}
              onClick={() => {
                onClick();
                handleClose();
              }}
            >
              {t("modules.buttons.yes")}
            </LoadingButton>
            <Button
              variant="contained"
              color="error"
              sx={{
                alignSelf: "center",
                fontSize: 16,
                textTransform: "capitalize",
                px: 1.5,
                borderRadius: 3,
              }}
              onClick={handleClose}
            >
              {t("modules.buttons.no")}
            </Button>
          </Stack>
        </Stack>
      </Fade>
    </Modal>
  );
};

export default ConfirmationInfoModal;
