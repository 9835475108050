import { useState, useEffect } from "react";
import {
  Box,
  TextField,
  Chip,
  Autocomplete,
  Grid,
  Checkbox,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import ButtonGroup from "../../../../components/forms/ButtonGroup/ButtonGroup";
import { useMutation, useQueryClient, useQuery } from "@tanstack/react-query";
import { useParams } from "react-router-dom";
import Axios from "../../../../network/Axios";
import { useSnackbar } from "notistack";
import InfoModal from "../../../../components/Modals/InfoModal";
import FormSpinner from "../../../../components/Spinners/FormSpinner";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";

const HoldModal = ({ open, handleClose, holdReason }) => {
  const { t } = useTranslation("global");
  const queryClient = useQueryClient();
  const { workOrderId } = useParams();
  const { enqueueSnackbar } = useSnackbar();
  const [reason, setReason] = useState(holdReason || "");
  const [selectedAuthorities, setSelectedAuthorities] = useState([]);

  const { data: hold, isLoading } = useQuery({
    queryKey: ["holdReasons"],
    queryFn: async () => {
      const response = await Axios.get("/WorkOrders", {
        params: {
          State: "Hold",
          SelectedNo: workOrderId,
        },
      });
      return response.data;
    },
    enabled: open,
  });

  useEffect(() => {
    if (hold) {
      const currentHoldReason = holdReason || "";
      setReason(currentHoldReason);
      setSelectedAuthorities([]);
    }
  }, [hold, holdReason]);

  const { mutateAsync: holdReasons, isPending } = useMutation({
    mutationFn: async () => {
      return Axios.patch("/WorkOrders", {
        SelectedNo: workOrderId,
        Status: "Hold",
        WorkOrderHoldReason: reason,
        RejectedByAuthorities:
          selectedAuthorities.length > 0
            ? selectedAuthorities.join(", ")
            : null,
      });
    },
    onSuccess: (data) => {
      if (!data) return;
      enqueueSnackbar(t("Hold Successfully"), {
        variant: "success",
      });
      queryClient.invalidateQueries({
        queryKey: ["workOrderInfo"],
      });

      handleClose();
      setReason(holdReason);
      setSelectedAuthorities([]);
    },
  });

  const labels = [
    "Army",
    "Civil Aviation",
    "Environment",
    "District",
    "NTRA",
    "Municipality",
    "Bridge & Roads",
    "Irrigation",
    "Agriculture",
  ];

  const handleAuthorityChange = (event) => {
    const { value, checked } = event.target;
    setSelectedAuthorities((prev) =>
      checked
        ? [...prev, value]
        : prev.filter((authority) => authority !== value)
    );
  };

  return (
    <InfoModal
      open={open}
      handleClose={handleClose}
      title={t("info_modules.work_orders.description.hold_reason")}
      width="30rem"
      content={
        <Box padding={1.2} display="flex" flexDirection="column" gap={1.2}>
          {isLoading ? (
            <Box paddingBottom="60px">
              <FormSpinner />
            </Box>
          ) : (
            <>
              <Chip
                label={workOrderId}
                sx={{ borderRadius: 1, fontSize: "0.9rem" }}
              />
              <Autocomplete
                id="combo-box-demo"
                options={hold?.result1 || []}
                getOptionLabel={(option) => option.WorkOrderHoldReason}
                sx={{ width: "100%" }}
                size="small"
                value={
                  hold?.result1.find(
                    (option) => option.WorkOrderHoldReason === reason
                  ) || (reason === "NA" ? null : reason)
                }
                onChange={(event, value) => {
                  setReason(value ? value.WorkOrderHoldReason : "");
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label={t(
                      "info_modules.work_orders.description.hold_reason"
                    )}
                  />
                )}
              />
              {reason === "Rejected by Authorities" && (
                <Grid container spacing={0.4}>
                  {labels.map((label) => (
                    <Grid item xs={6} key={label}>
                      <FormGroup>
                        <FormControlLabel
                          control={
                            <Checkbox
                              value={label}
                              onChange={handleAuthorityChange}
                              checked={selectedAuthorities.includes(label)}
                            />
                          }
                          label={label}
                        />
                      </FormGroup>
                    </Grid>
                  ))}
                </Grid>
              )}
              <ButtonGroup
                onClickClose={handleClose}
                isPending={isPending}
                onClickSave={holdReasons}
                saveLabel={t("modules.buttons.save")}
              />
            </>
          )}
        </Box>
      }
    />
  );
};

export default HoldModal;
