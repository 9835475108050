import {
  Button,
  Menu,
  MenuItem,
  ListItemIcon,
  ListItemText,
} from "@mui/material";
import { useState, useRef } from "react";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";

const ActionMenuButton = ({
  actions,
  actionsConfig,
  customContent,
  title,
  padding,
  borderRadius,
  marginLeft,
  isMainDataGrid,
  showSubMenu,
  showSubMenuItem,
  subMenuTitle,
  subMenuConfig,
  menuWidth,
}) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [subMenuAnchorEl, setSubMenuAnchorEl] = useState(null);
  const subMenuTimeout = useRef(null);

  const openAction = Boolean(anchorEl);
  const openSubMenu = Boolean(subMenuAnchorEl);
  const theme = useSelector((state) => state.theme.colorTheme);
  const { language } = useSelector((state) => state.theme);
  const [t] = useTranslation("global");

  const handleClickAction = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleCloseAction = () => {
    setAnchorEl(null);
    setSubMenuAnchorEl(null);
  };

  const handleSubMenuClick = (event) => {
    setSubMenuAnchorEl(event.currentTarget);
    clearTimeout(subMenuTimeout.current); // Clear the timeout if any
  };

  const handleCloseSubMenu = () => {
    // Set timeout to delay the submenu close event
    subMenuTimeout.current = setTimeout(() => {
      setSubMenuAnchorEl(null);
    }, 100); // Adjust the timeout as needed
  };

  const handleSubMenuMouseEnter = () => {
    clearTimeout(subMenuTimeout.current); // Prevent submenu close if user hovers over it
  };

  const handleSubMenuMouseLeave = () => {
    handleCloseSubMenu(); // Close submenu on mouse leave
  };

  return (
    <div>
      <Button
        id="action-button"
        aria-controls={openAction ? "action-menu" : undefined}
        aria-haspopup="true"
        aria-expanded={openAction ? "true" : undefined}
        onClick={handleClickAction}
        startIcon={<MoreVertIcon />}
        endIcon={<KeyboardArrowDownIcon />}
        variant="outlined"
        sx={{
          "& .MuiButton-startIcon": {
            marginRight: 0,
            fontSize: "0.5rem",
          },
          "& .MuiButton-endIcon": {
            marginLeft: 0.1,
          },
          padding: padding || "3px 8px",
          height: "2rem",
          textTransform: "capitalize",
          fontSize: 13.8,
          borderRadius: borderRadius || "5px",
          border: 2.3,
          ":hover": {
            border: 2.3,
          },
        }}
      >
        {title || t("info_modules.work_orders.buttons.actions")}
      </Button>
      <Menu
        id="action-menu"
        anchorEl={anchorEl}
        open={openAction}
        onClose={handleCloseAction}
        MenuListProps={{
          "aria-labelledby": "action-button",
          sx: {
            pb: 0,
            pt: 0,
          },
        }}
        slotProps={{
          paper: {
            sx: {
              border: "3px solid",
              borderColor: theme === "dark" ? "#333" : "#e0e0e0",
              width: menuWidth,
            },
          },
        }}
        sx={{
          marginLeft: marginLeft || 0,
          "& .MuiList-root": {
            "& .MuiMenuItem-root": {
              fontSize: 13.8,
            },
            "& .MuiMenuItem-root:not(:last-child)": {
              borderBottom: "3px solid",
              borderBottomColor: theme === "dark" ? "#333" : "#e0e0e0",
            },
          },
        }}
        anchorOrigin={{
          vertical: isMainDataGrid ? "bottom" : 30,
          horizontal: isMainDataGrid
            ? language === "AR"
              ? "right"
              : "left"
            : "right",
        }}
        transformOrigin={{
          vertical: isMainDataGrid ? "top" : -2.5,
          horizontal: isMainDataGrid
            ? language === "AR"
              ? "right"
              : "left"
            : "right",
        }}
      >
        {customContent
          ? customContent // Render the custom content if provided
          : actions.map((action) =>
              actionsConfig[action] ? (
                <MenuItem
                  key={action}
                  onClick={() => {
                    actionsConfig[action].onClick();
                    handleCloseAction();
                  }}
                  sx={{
                    color: actionsConfig[action].color,
                    padding: "5px 10px",
                  }}
                >
                  {actionsConfig[action].iconFlag && (
                    <ListItemIcon>{actionsConfig[action].icon}</ListItemIcon>
                  )}
                  <ListItemText
                    sx={{
                      "& span": {
                        fontSize: 13.8,
                      },
                    }}
                  >
                    {actionsConfig[action].label}
                  </ListItemText>
                </MenuItem>
              ) : null
            )}
        {showSubMenuItem && (
          <MenuItem
            onMouseEnter={handleSubMenuClick}
            onMouseLeave={handleCloseSubMenu}
            sx={{
              display: "flex",
              justifyContent: "space-between",
              fontSize: 13.8,
              padding: "5px 6px",
            }}
          >
            <ListItemIcon>
              <ChevronLeftIcon
                sx={{
                  width: "1.1rem",
                  height: "1.1rem",
                }}
              />
            </ListItemIcon>
            <ListItemText
              sx={{
                "& span": {
                  fontSize: 13.8,
                },
              }}
            >
              {subMenuTitle}
            </ListItemText>
          </MenuItem>
        )}
      </Menu>
      {showSubMenu && (
        <Menu
          id="submenu"
          anchorEl={subMenuAnchorEl}
          open={openSubMenu}
          onClose={handleCloseSubMenu}
          onMouseEnter={handleSubMenuMouseEnter}
          onMouseLeave={handleSubMenuMouseLeave}
          anchorOrigin={{
            vertical: "top",
            horizontal: language === "AR" ? "right" : 0, // AR: "right", EN: 0
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: language === "AR" ? 0 : "right", // AR: 0, EN: "right"
          }}
          MenuListProps={{
            "aria-labelledby": "basic-button",
            sx: {
              pb: 0,
              pt: 0,
            },
          }}
          slotProps={{
            paper: {
              sx: {
                border: "3px solid",
                borderColor: theme === "dark" ? "#333" : "#e0e0e0",
                width: "12rem",
              },
            },
          }}
          sx={{
            // transform: "translate(-14.5%, -0.5%)",
            "& .MuiList-root": {
              "& .MuiMenuItem-root:not(:last-child)": {
                borderBottom: "3px solid",
                borderBottomColor: theme === "dark" ? "#333" : "#e0e0e0",
              },
            },
          }}
        >
          {actions.map((action) =>
            subMenuConfig[action] ? (
              <MenuItem
                key={action}
                onClick={() => {
                  subMenuConfig[action].onClick();
                  handleCloseAction();
                }}
                sx={{
                  color: subMenuConfig[action].color,
                }}
              >
                {subMenuConfig[action].iconFlag && (
                  <ListItemIcon>{subMenuConfig[action].icon}</ListItemIcon>
                )}

                <ListItemText
                  sx={{
                    "& span": {
                      fontSize: 13.8,
                      color: subMenuConfig[action].color,
                    },
                  }}
                >
                  {subMenuConfig[action].label}
                </ListItemText>
              </MenuItem>
            ) : null
          )}
        </Menu>
      )}
    </div>
  );
};

export default ActionMenuButton;
