import { useState } from "react";
import ReactApexChart from "react-apexcharts";
import { Box, Button, Stack, Typography } from "@mui/material";
import { grey } from "@mui/material/colors";
import { useSelector } from "react-redux";
import InfoModal from "../Modals/InfoModal";
import { useTranslation } from "react-i18next";
import ChartSwitch from "./ChartSwitch";
import NoData from "./NoData";
import GenericModal from "../Modals/DashboardModal";

const PieChart = ({
  chartData,
  chartData2,
  switchConfig,
  title,
  chartName,
  modalConfig,
  modalWidth = "70rem",
  colors,
  isSwitchChecked,
  isSwitchChecked2,
  height,
  chartType,
  secondView,
  switch2Config,
  isDemo,
}) => {
  const { t } = useTranslation("global");
  const theme = useSelector((state) => state.theme.colorTheme); // light | dark

  // Handle demo modal if isDemo is true
  const [demoModalOpen, setDemoModalOpen] = useState(false);

  const handleDemoModal = () => setDemoModalOpen(true);
  const handleCloseDemoModal = () => setDemoModalOpen(false);

  let currentChartData;
  if (switchConfig) {
    if (switch2Config) {
      if (isSwitchChecked && isSwitchChecked2) {
        currentChartData = secondView.chartData;
      } else if (isSwitchChecked && !isSwitchChecked2) {
        currentChartData = chartData;
      } else if (!isSwitchChecked && isSwitchChecked2) {
        currentChartData = secondView.chartData2;
      } else if (!isSwitchChecked && !isSwitchChecked2) {
        currentChartData = chartData2;
      }
    } else {
      currentChartData = isSwitchChecked ? chartData : chartData2;
    }
  } else {
    currentChartData = chartData;
  }

  const [currentDataSlice, setCurrentDataSlice] = useState(null);

  const options = {
    colors,
    plotOptions: {
      donut: {
        customScale: 0.8,
      },
    },
    chart: {
      events: {
        dataPointSelection: function (event, chartContext, config) {
          const sliceName = currentChartData[config.dataPointIndex].name;
          if (isDemo) {
            if (isDemo.isClickable) {
              setCurrentDataSlice(sliceName);
              handleDemoModal();
            }
            return;
          }

          const insuranceNotifications = {
            medical_insurance: t("notifications.not_covered"),
            social_insurance: t("notifications.not_insured"),
          };

          setCurrentDataSlice(insuranceNotifications[chartName] || sliceName);

          if (!["Medical Insured", "Social Insured"].includes(sliceName)) {
            handleOpenModal(chartName);
          }
        },
      },
    },
    labels: currentChartData.map((item) => item.name),
    legend: {
      show: true,
      position: "bottom",
      labels: {
        colors: theme === "dark" ? grey[300] : grey[700],
      },
    },
    stroke: {
      show: false,
    },
    dataLabels: {
      enabled: true,
      formatter: function (val, opts) {
        const projectName = opts.w.globals.labels[opts.seriesIndex];
        const dataItem = currentChartData.find(
          (item) => item.name === projectName
        );
        return dataItem ? dataItem.data : "";
      },
    },
  };

  const series = currentChartData.map((item) => item.data);

  // Handle modal states
  const [isModalOpen, setIsModalOpen] = useState({
    open_missions: false,
    social_insurance: false,
    medical_insurance: false,
  });

  const handleOpenModal = (chartName) =>
    setIsModalOpen((prev) => ({ ...prev, [chartName]: true }));

  const handleCloseModal = () =>
    setIsModalOpen({
      open_missions: false,
      social_insurance: false,
      medical_insurance: false,
    });

  return (
    <>
      {
        // If there is no data, show a message
        currentChartData.length === 0 ? (
          <NoData />
        ) : (
          <ReactApexChart
            key={`${chartType}-${isDemo ? isDemo.isClickable : ""}-${Number(
              isSwitchChecked
            )}-${Number(isSwitchChecked2)}`}
            options={options}
            series={series}
            type={chartType || "donut"}
            width="100%"
            height={height || "100%"}
            style={{ margin: "0 auto" }}
          />
        )
      }
      <InfoModal
        open={isModalOpen[chartName]}
        title={`${title} ${currentDataSlice !== null ? " - " : ""} ${
          currentDataSlice !== null ? currentDataSlice : ""
        }`}
        content={
          <GenericModal
            dataName={currentDataSlice}
            isSwitchChecked={isSwitchChecked}
            modalConfig={modalConfig}
            chartName={chartName}
          />
        }
        handleClose={handleCloseModal}
        width={modalWidth}
      />

      <InfoModal
        open={demoModalOpen}
        content={
          <Box
            sx={{
              padding: "1rem",
              display: "flex",
              flexDirection: "column",
              gap: "1rem",
            }}
          >
            <Typography variant="body1">
              {currentDataSlice} was clicked
            </Typography>
            <Button
              variant="contained"
              color="primary"
              onClick={handleCloseDemoModal}
              sx={{
                alignSelf: "center",
              }}
            >
              OK
            </Button>
          </Box>
        }
        handleClose={handleCloseDemoModal}
        width="20rem"
      />
    </>
  );
};

const PieChartContainer = ({
  chartData,
  chartData2,
  switchConfig,
  title,
  chartName,
  modalConfig,
  modalWidth = "70rem",
  colors,
  doubleChart,
  height,
  chartType, // donut | pie
  switch2Config,
  secondView,
  isDemo,
}) => {
  const { i18n } = useTranslation("global");

  const theme = useSelector((state) => state.theme.colorTheme); // light | dark

  const [isSwitchChecked, setIsSwitchChecked] = useState(false);

  const handleSwitchState = () => setIsSwitchChecked(!isSwitchChecked);

  // Second switch state
  const [isSwitchChecked2, setIsSwitchChecked2] = useState(false);

  const handleSwitchState2 = () => setIsSwitchChecked2(!isSwitchChecked2);

  return (
    <Box
      component="div"
      dir="ltr"
      sx={{
        display: "flex",
        flexDirection: "column",
        gap: "1rem",
        height: "100%",
      }}
    >
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
        dir={i18n.dir()}
      >
        <Typography
          sx={{
            fontSize: "14px",
            fontWeight: "600",
            color: "text.primary",
            textDecorationColor: theme === "dark" ? "#8a8a8a" : "#cfcfcf",
          }}
        >
          {title}
        </Typography>
        <Stack direction="row" spacing={3} sx={{ alignItems: "center" }}>
          {switchConfig && (
            <ChartSwitch
              isSwitchChecked={isSwitchChecked}
              handleSwitchState={handleSwitchState}
              leftLabel={switchConfig.leftLabel}
              rightLabel={switchConfig.rightLabel}
            />
          )}
          {switch2Config && (
            <ChartSwitch
              isSwitchChecked={isSwitchChecked2}
              handleSwitchState={handleSwitchState2}
              leftLabel={switch2Config.leftLabel}
              rightLabel={switch2Config.rightLabel}
            />
          )}
        </Stack>
      </Box>
      <Box
        sx={{
          flexGrow: 1,
          display: doubleChart ? "grid" : "block",
          gridTemplateColumns: "1fr 1fr",
        }}
      >
        <PieChart
          chartData={chartData}
          chartData2={chartData2}
          switchConfig={switchConfig}
          title={title}
          chartName={chartName}
          modalConfig={modalConfig}
          modalWidth={modalWidth}
          colors={colors}
          isSwitchChecked={isSwitchChecked}
          isSwitchChecked2={isSwitchChecked2}
          height={height}
          chartType={chartType}
          switch2Config={switch2Config}
          secondView={secondView}
          isDemo={isDemo}
        />
        {doubleChart && (
          <PieChart
            chartData={doubleChart.chartData}
            switchConfig={doubleChart.switchConfig}
            title={doubleChart.title}
            chartName={doubleChart.chartName}
            modalConfig={doubleChart.modalConfig}
            modalWidth={doubleChart.modalWidth}
            colors={doubleChart.colors}
          />
        )}
      </Box>
    </Box>
  );
};

export default PieChartContainer;
