import { useQuery } from "@tanstack/react-query";
import Axios from "../../../../network/Axios";

const useGetMaterialRequestsFormData = (enabled = true) => {
  const data = useQuery({
    queryKey: ["materialRequests", "new"],
    queryFn: async () => {
      const response = await Axios.get("/MaterialRequests", {
        params: { State: "New" },
      });
      return response.data.result;
    },
    enabled,
  });
  return data;
};

export default useGetMaterialRequestsFormData;
