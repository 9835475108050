import LinearProgress, {
  linearProgressClasses,
} from "@mui/material/LinearProgress";
import Box from "@mui/material/Box";
import { styled } from "@mui/material/styles";

let color = "";
let percent = 0;

const calculateProgressBarColor = (value) => {
  if (value <= 25) {
    color = "#f44336";
  } else if (value <= 50) {
    color = "#ffc107";
  } else if (value <= 75) {
    color = "#ef6c00";
  } else if (value <= 110) {
    color = "#388e3c";
  } else {
    color = "#6C8013";
  }
  return color;
};
const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
  height: "100%",
  [`&.${linearProgressClasses.colorPrimary}`]: {
    backgroundColor:
      theme.palette.grey[theme.palette.mode === "light" ? 200 : 800],
  },
  [`& .${linearProgressClasses.bar}`]: {
    backgroundColor: color,
    borderRadius: "50px",
  },
}));

const ProgressBarCell = (value) => {
  if (value === null || value === undefined) {
    return null;
  }

  if (value > 100) {
    percent = 100;
  } else {
    percent = value;
  }

  const color = calculateProgressBarColor(value);

  return (
    <Box
      position="relative"
      height={20}
      sx={{
        backgroundColor: color,
        width: "100%",
        borderRadius: "50px",
        color: "#fff",
        fontWeight: 500,
      }}
    >
      <BorderLinearProgress
        variant="determinate"
        value={percent}
        sx={{
          borderRadius: "50px",
          "&.MuiLinearProgress-colorPrimary": {
            backgroundColor: "#939393",
          },
        }}
      />
      <Box
        position="absolute"
        top="10%"
        left="34%"
        transform="translate(-50%, -50%)"
      >
        {`${value}%`}
      </Box>
    </Box>
  );
};

export default ProgressBarCell;
