import { useState } from "react";
import { useSnackbar } from "notistack";
import { useTranslation } from "react-i18next";
import CheckIcon from "@mui/icons-material/Check";
import { useSearchParams } from "react-router-dom";
import CancelIcon from "@mui/icons-material/Cancel";
import ChipStatus from "../../../components/ChipStatus";
import GroupAddIcon from "@mui/icons-material/GroupAdd";
import Spinner from "../../../components/Spinners/Spinner";
import { priceListsService } from "../../../api/priceLists";
import InfoCard from "../../../components/InfoPagesCard/InfoCard";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import ActionMenuButton from "../../../components/forms/ButtonGroup/ActionMenuButton";
import {
  Box,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  Grid,
  Stack,
  Typography,
} from "@mui/material";
import ChipComponent from "../../../components/Chips/ChipComponent";
import { useConfirmationDialog } from "../../../contexts/ConfirmationDialogContext";
import PriceListTable from "./components/PriceListTable";
import EditForm from "./components/EditForm";
import InfoModal from "../../../components/Modals/InfoModal";
import FormSpinner from "../../../components/Spinners/FormSpinner";
import ButtonGroup from "../../../components/forms/ButtonGroup/ButtonGroup";
import ActionButton from "../../../components/forms/ButtonGroup/ActionButton";

const PriceList = () => {
  const queryClient = useQueryClient();
  const { enqueueSnackbar } = useSnackbar();
  const [t] = useTranslation("global");
  const [params] = useSearchParams();
  const { setOpen } = useConfirmationDialog();
  const priceListId = params.get("SelectedID");
  const [editMode, setEditMode] = useState(false);
  const [assignGroupModal, setAssignGroupModal] = useState(false);

  // turn off editMode when the user clicks on the cancel button
  const handleCancelEditMode = () => {
    setEditMode(false);
  };

  // turn on editMode when the user clicks on the edit button
  const handleOpenEditMode = () => {
    setEditMode(true);
  };
  const handleOpenAssignGroup = () => {
    setAssignGroupModal(true);
  };

  const handleCloseAssignGroup = () => {
    setAssignGroupModal(false);
  };

  const { data, isLoading } = useQuery({
    queryKey: ["priceList", priceListId],
    queryFn: () => priceListsService.GetPriceList(priceListId),
  });

  const { mutateAsync: updatePriceListState } = useMutation({
    mutationFn: priceListsService.patchPriceList,
    onSuccess: (data) => {
      enqueueSnackbar(data.msg, { variant: "success" });
      queryClient.invalidateQueries({ queryKey: ["priceList"] });
    },
  });

  const actions = data?.Actions.filter((action) => action !== "Edit") || [];
  const actionsConfig = {
    Activate: {
      label: t("info_modules.price_list.buttons.activate"),
      icon: <CheckIcon sx={{ width: "1.1rem", height: "1.1rem" }} />,
      color: "success",
      onClick: () => {
        setOpen(
          t("info_modules.price_list.confirmation.activate"),
          () => () => {
            updatePriceListState({
              SelectedNo: PriceListRefNo,
              State: "Active",
            });
          },
          "success"
        );
      },
    },
    Assign: {
      label: t("info_modules.price_list.buttons.assign_to_group"),
      icon: <GroupAddIcon sx={{ width: "1.1rem", height: "1.1rem" }} />,
      color: "primary",
      onClick: handleOpenAssignGroup,
    },
    Deactive: {
      label: t("info_modules.price_list.buttons.deactive"),
      icon: <CancelIcon sx={{ width: "1.1rem", height: "1.1rem" }} />,
      color: "error",
      onClick: () => {
        setOpen(
          t("info_modules.price_list.confirmation.deactive"),
          () => () => {
            updatePriceListState({
              SelectedNo: PriceListRefNo,
              State: "Inactive",
            });
          },
          "error"
        );
      },
    },
  };

  if (isLoading) {
    return <Spinner />;
  }

  const {
    CreationDate,
    ERPUserNickName,
    InstallCount,
    LastUpdateDate,
    PriceListRefNo,
    PriceListYear,
    Status,
    SupplyCount,
    Total,
    CompanyAccountName,
  } = data.Details[0];

  const priceListDetails = [
    {
      label: t("data_grid.headers.ref_no"),
      value: PriceListRefNo,
    },
    {
      label: t("data_grid.headers.status"),
      value: <ChipStatus value={Status} />,
    },
    {
      label: t("data_grid.headers.price_list_year"),
      value: PriceListYear,
    },
    {
      label: t("data_grid.headers.account_name"),
      value: CompanyAccountName,
    },
    {
      label: t("data_grid.headers.total_items"),
      value: Total,
    },
    {
      label: t("data_grid.headers.created_by"),
      value: ERPUserNickName,
    },
    {
      label: t("data_grid.headers.creation_date"),
      value: CreationDate,
    },
    {
      label: t("data_grid.headers.last_update"),
      value: LastUpdateDate,
    },
    {
      label: t("data_grid.headers.install_count"),
      value: InstallCount,
    },
    {
      label: t("data_grid.headers.supply_count"),
      value: SupplyCount,
    },
  ];

  return (
    <>
      <Box display="flex" flexDirection="column" gap={1.5} marginBottom={1.4}>
        <InfoCard
          editButton={data.Actions.includes("Edit")}
          onClickEdit={handleOpenEditMode}
          title={t("info_modules.mobile_internet_line.description.details")}
          content={
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
              }}
            >
              {editMode ? (
                <EditForm
                  data={data.Details[0]}
                  handleCancelEditMode={handleCancelEditMode}
                />
              ) : (
                <>
                  {actions.length > 0 && (
                    <Box
                      display="flex"
                      justifyContent="flex-end"
                      marginBottom="4px"
                    >
                      {actions.length > 1 ? (
                        <ActionMenuButton
                          actions={actions}
                          actionsConfig={actionsConfig}
                        />
                      ) : (
                        <ActionButton
                          icon={actionsConfig[actions[0]].icon}
                          text={actionsConfig[actions[0]].label}
                          onClick={actionsConfig[actions[0]].onClick}
                          color={actionsConfig[actions[0]].color}
                        />
                      )}
                    </Box>
                  )}
                  <Grid container spacing={1}>
                    {priceListDetails.map(({ label, value, input }) => (
                      <Grid item xs={4} key={label}>
                        <ChipComponent
                          label={label}
                          value={editMode ? input || value : value}
                        />
                      </Grid>
                    ))}
                  </Grid>
                </>
              )}
            </Box>
          }
        />
      </Box>
      <PriceListTable rows={data.result} PriceListRefNo={PriceListRefNo} />
      <AssignGroup
        open={assignGroupModal}
        handleClose={handleCloseAssignGroup}
        PriceListRefNo={PriceListRefNo}
      />
    </>
  );
};

const AssignGroup = ({ open, handleClose, PriceListRefNo: priceListId }) => {
  const [t] = useTranslation("global");

  return (
    <InfoModal
      open={open}
      handleClose={handleClose}
      title={t("info_modules.price_list.forms.assign_group.title")}
      content={
        <AssignGroupFormContent
          priceListId={priceListId}
          handleClose={handleClose}
        />
      }
    />
  );
};

const AssignGroupFormContent = ({ priceListId, handleClose }) => {
  const queryClient = useQueryClient();
  const { enqueueSnackbar } = useSnackbar();
  const [t] = useTranslation("global");

  const { data, isLoading } = useQuery({
    queryKey: ["priceListGroups", priceListId],
    queryFn: () => priceListsService.getPriceListGroups(priceListId),
  });

  const [checkedItems, setCheckedItems] = useState([]);

  const handleChange = (event) => {
    const { name } = event.target;
    const updatedCheckedItems = !checkedItems.includes(name)
      ? [...checkedItems, name]
      : checkedItems.filter((item) => item !== name);
    setCheckedItems(updatedCheckedItems);
  };

  // Handle assign group
  const { mutateAsync: assignGroup, isPending } = useMutation({
    mutationFn: priceListsService.patchPriceList,
    onSuccess: (data) => {
      handleClose();
      enqueueSnackbar(data.msg, { variant: "success" });
      queryClient.invalidateQueries({ queryKey: ["priceList"] });
    },
  });

  const handleSubmit = () => {
    const transformedData = data.map((item) => ({
      GroupName: item.PriceListsGroupName,
      checked: checkedItems.includes(item.idPriceListsGroup.toString()),
      idPriceListsGroup: item.idPriceListsGroup,
    }));

    assignGroup({
      SelectedNo: priceListId,
      State: "Assign",
      PriceListGroups: transformedData,
    });
  };

  if (isLoading) return <FormSpinner />;

  return (
    <Box
      sx={{
        padding: "2rem",
        display: "flex",
        flexDirection: "column",
        gap: "1rem",
      }}
    >
      <Stack
        direction="row"
        gap={2}
        alignItems="center"
        sx={{
          padding: "1rem",
          borderRadius: "8px",
          backgroundColor: "background.main",
        }}
      >
        <Typography
          sx={{
            fontWeight: 500,
          }}
        >
          {t(
            "info_modules.price_list.forms.assign_group.labels.price_list_ref_no"
          )}
        </Typography>
        <Typography>{priceListId}</Typography>
      </Stack>
      <Box sx={{ maxHeight: "20rem", overflowY: "auto" }}>
        <FormControl component="fieldset" variant="standard">
          <FormGroup>
            {data.map(({ idPriceListsGroup, PriceListsGroupName }) => (
              <FormControlLabel
                key={idPriceListsGroup}
                control={
                  <Checkbox
                    checked={checkedItems.includes(
                      idPriceListsGroup.toString()
                    )}
                    onChange={handleChange}
                    name={idPriceListsGroup.toString()}
                  />
                }
                label={PriceListsGroupName}
              />
            ))}
          </FormGroup>
        </FormControl>
      </Box>

      <ButtonGroup
        onClickSave={handleSubmit}
        onClickClose={handleClose}
        saveLabel={t("modules.buttons.save")}
        isPending={isPending}
        sx={{
          margin: 0,
        }}
      />
    </Box>
  );
};

export default PriceList;
