import dayjs from "dayjs";
import { statusColors } from "./statuses";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { useQuery } from "@tanstack/react-query";
import ChipStatus from "../components/ChipStatus";
import Spinner from "../components/Spinners/Spinner";
import useOpenNewForm from "../hooks/useOpenNewForm";
import { Link as RouterLink } from "react-router-dom";
import { CompanyFleetService } from "../api/companyFleet";
import CustomDataGrid from "../components/DataGrid/CustomDataGrid";
import { useConfirmationDialog } from "../contexts/ConfirmationDialogContext";

const CompanyFleet = () => {
  const [t] = useTranslation("global");

  const statusNames = [
    { name: "Active", displayName: t("data_grid.tabs.active") },
    { name: "Deactivated", displayName: t("data_grid.tabs.deactive") },
  ];

  // Handling new form logic
  const { handleOpen } = useOpenNewForm();
  const { setOpen } = useConfirmationDialog();
  const { language, newForm } = useSelector((state) => state.theme);

  const assignedTo =
    language === "EN"
      ? {
          field: "EmpNameEn",
          headerName: t("data_grid.headers.assigned_to"),
          width: 140,
          flex: 1,
        }
      : {
          field: "EmpName",
          headerName: t("data_grid.headers.assigned_to"),
          width: 140,
          flex: 1,
        };

  const columns = [
    {
      field: "CarsNumber",
      headerName: t("data_grid.headers.car_number"),
      width: 130,
      renderCell: (params) => {
        return (
          <RouterLink
            style={{
              color: "#1790FF",
            }}
            to={`/companyfleet/${decodeURIComponent(params.value)}`}
          >
            {params.value}
          </RouterLink>
        );
      },
    },
    assignedTo,
    {
      field: "CarsOwnership",
      headerName: t("data_grid.headers.ownership"),
      width: 140,
    },
    {
      field: "CarsType",
      headerName: t("data_grid.headers.car_type"),
      width: 140,
      flex: 1,
    },
    {
      field: "CarsModelName",
      headerName: t("data_grid.headers.car_model"),
      width: 140,
      flex: 1,
    },
    {
      field: "CarsModelYear",
      headerName: t("data_grid.headers.model_year"),
      width: 140,
      flex: 1,
    },
    {
      field: "CarsLicenseEndDate",
      headerName: t("data_grid.headers.license_expire_date"),
      width: 140,
      type: "date",
      valueGetter: ({ value }) => (value ? new Date(value) : null),
      renderCell: ({ value }) => {
        return value ? dayjs(value).format("YYYY/MM/DD") : "";
      },
    },
    {
      field: "CarLicenseStatus",
      headerName: t("data_grid.headers.car_license"),
      width: 140,
      renderCell: (params) => {
        return <ChipStatus value={params.value} />;
      },
    },
    {
      field: "CarsStatus",
      headerName: t("data_grid.headers.status"),
      width: 140,
      renderCell: (params) => {
        return <ChipStatus value={params.value} />;
      },
    },
  ];
  const {
    data: rows,
    isLoading,
    refetch,
    isRefetching,
  } = useQuery({
    queryKey: ["companyFleet"],
    queryFn: CompanyFleetService.GetCompanyFleet,
  });

  if (isLoading) {
    return <Spinner />;
  }

  return (
    <CustomDataGrid
      rows={rows}
      columns={columns}
      loading={isLoading}
      getRowId={(row) => row.idCarsInfoCarsNumbers}
      filterField="CarsStatus"
      statusNames={statusNames}
      statusColors={statusColors}
      showReloadOverlay={isRefetching}
      reloadFunction={refetch}
      newButton
      initialState={{
        columns: {
          columnVisibilityModel: {
            CarsOwnership: false,
          },
        },
      }}
      newFunction={() => {
        if (newForm === "company_fleet" || newForm === null) {
          handleOpen("company_fleet");
        } else {
          setOpen(t("modules.new_form_confirmation_message"), () => () => {
            handleOpen("company_fleet");
          });
        }
      }}
    />
  );
};

export default CompanyFleet;
