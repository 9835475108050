import { LoadingButton } from "@mui/lab";

const ActionButton = ({ text, icon, onClick, sx, color, loading }) => {
  return (
    <LoadingButton
      variant="outlined"
      startIcon={icon}
      onClick={onClick}
      loading={loading}
      color={color || "primary"}
      sx={{
        "& .MuiButton-startIcon": {
          marginRight: 0.3,
          fontSize: "0.5rem",
        },
        padding: "3px 8px",
        height: "2rem",
        textTransform: "capitalize",
        fontSize: 13.8,
        border: 2.3,
        ":hover": {
          border: 2.3,
        },
        ...sx,
      }}
    >
      {text}
    </LoadingButton>
  );
};

export default ActionButton;
