import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Box,
  Avatar,
  Menu,
  MenuItem,
  ListItemIcon,
  IconButton,
  Divider,
  Tooltip,
  Typography,
} from "@mui/material";
import ChangeCircleIcon from "@mui/icons-material/ChangeCircle";
import Brightness4Icon from "@mui/icons-material/Brightness4";
import Brightness7Icon from "@mui/icons-material/Brightness7";
import Logout from "@mui/icons-material/Logout";
import LanguageIcon from "@mui/icons-material/Language";
import { useTheme } from "@mui/material/styles";
import { useMutation } from "@tanstack/react-query";
import { setTheme } from "../../../../store/slices/themeSlice";
import { DashboardService } from "../../../../api/dashboard";
import LanguageMenu from "./LanguageMenu";
import { useTranslation } from "react-i18next";
import AntSwitch from "../../../Switches/AntSwitch";
import useOpenNewForm from "../../../../hooks/useOpenNewForm";

export default function AccountIcon({ handleLogOut }) {
  const [anchorEl, setAnchorEl] = useState(null);
  const { handleOpen } = useOpenNewForm();

  const theme = useTheme();
  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const { colorTheme, language } = useSelector((state) => state.theme);
  const nickName = useSelector((state) => state.auth.nickName);

  const createAbbreviation = (fullName) => {
    const words = fullName?.split(" ") || [];
    const abbreviation = words
      .map((word) => word.charAt(0).toUpperCase())
      .join(".");
    return abbreviation;
  };

  const abbreviation = createAbbreviation(nickName);

  const dispatch = useDispatch();

  const { mutateAsync: updateUserPreferences } = useMutation({
    mutationFn: DashboardService.updateUserPreferences,
  });

  const handleThemeChange = (e) => {
    dispatch(setTheme());
    updateUserPreferences({
      DarkMode: e.target.checked ? 1 : 0,
      Language: language,
    });
  };

  const [t] = useTranslation("global");

  return (
    <>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          textAlign: "center",
        }}
      >
        <Tooltip title="Account settings">
          <IconButton onClick={handleClick} size="small">
            <Avatar
              sx={{
                width: 32,
                height: 32,
                fontSize: "16px",
                color: "white",
                boxShadow: theme.palette.boxShadow.icon,
              }}
            >
              {abbreviation}
            </Avatar>
          </IconButton>
        </Tooltip>
      </Box>
      <Menu
        anchorEl={anchorEl}
        id="account-menu"
        open={open}
        onClose={handleClose}
        slotProps={{
          paper: {
            elevation: 0,
            sx: {
              overflowY: "auto",
              filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
              mt: 1.5,
              "& .MuiAvatar-root": {
                width: 32,
                height: 32,
                ml: -0.5,
                mr: 1,
              },
              "&::before": {
                content: '""',
                display: "block",
                position: "absolute",
                top: 0,
                right: 14,
                width: 10,
                height: 10,
                bgcolor: "background.paper",
                transform: "translateY(-50%) rotate(45deg)",
                zIndex: 0,
              },
            },
          },
        }}
        transformOrigin={{ horizontal: "right", vertical: "top" }}
        anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
        useLayerForClickAway={true}
      >
        <MenuItem
          onClick={() => {
            handleOpen("change_password");
          }}
          sx={{ fontWeight: 500 }}
        >
          <ListItemIcon>
            <ChangeCircleIcon fontSize="small" />
          </ListItemIcon>
          {t("navbar.account_menu.change_password")}
        </MenuItem>
        <MenuItem sx={{ fontWeight: 500 }}>
          <ListItemIcon>
            {colorTheme === "light" ? (
              <Brightness4Icon fontSize="small" />
            ) : (
              <Brightness7Icon fontSize="small" />
            )}
          </ListItemIcon>
          {colorTheme === "light"
            ? t("navbar.account_menu.theme.light")
            : t("navbar.account_menu.theme.dark")}
          <AntSwitch
            checked={colorTheme === "dark"}
            onChange={handleThemeChange}
            sx={{ ml: "auto" }}
            size="medium"
            color="primary"
            inputProps={{ "aria-label": "toggle dark mode" }}
            switchSize={1.25}
          />
        </MenuItem>
        <MenuItem sx={{ fontWeight: 500 }}>
          <ListItemIcon>
            <LanguageIcon fontSize="small" />
          </ListItemIcon>

          <LanguageMenu />
        </MenuItem>

        <Divider />
        <MenuItem onClick={handleLogOut}>
          <ListItemIcon>
            <Logout fontSize="small" sx={{ color: "#ff3333" }} />
          </ListItemIcon>
          <Typography sx={{ color: "#ff3333", fontWeight: 600 }}>
            {t("navbar.account_menu.logout")}
          </Typography>
        </MenuItem>
      </Menu>
    </>
  );
}
