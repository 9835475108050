import { useQuery } from "@tanstack/react-query";
import Axios from "../../../../network/Axios";
import FormSpinner from "../../../../components/Spinners/FormSpinner";
import { Stack, Grid } from "@mui/material";
import { useTranslation } from "react-i18next";
import ChipComponent from "../../../../components/Chips/ChipComponent";
import ChipStatus from "../../../../components/ChipStatus";

const WorkOrdersSnapShot = ({ workOrderNo, open }) => {
  const { data, isLoading } = useQuery({
    queryKey: ["workOrdersCard", workOrderNo],
    queryFn: async () => {
      const response = await Axios.get("/WorkOrders", {
        params: {
          SelectedNo: workOrderNo,
          State: "Card",
        },
      });
      return response.data;
    },
    enabled: open,
  });

  if (isLoading) return <FormSpinner />;

  return <WorkOrdersSnap data={data} />;
};

const WorkOrdersSnap = ({ data }) => {
  const [t] = useTranslation("global");

  // WorkOrder data
  const {
    WorkOrderNo,
    SiteName,
    SiteCode,
    SiteType,
    WorkOrderDate,
    WorkOrderStatus,
    WorkOrderHoldReason,
    Typology,
    RejectedByAuthorities,
    RequestorsName,
    TeamLeadersName,
    ERPUserNickName,
    CompanyProjectsName,
    SubProjectsName,
    Latitude,
    Longitude,
    GovernoratesName,
    SiteAddress,
    WorkOrderDiscription,
  } = data.result[0];

  const Invoices = [
    {
      label: t("data_grid.headers.work_order_no"),
      value: WorkOrderNo,
    },
    {
      label: t("data_grid.headers.site_name"),
      value: SiteName,
    },
    {
      label: t("data_grid.headers.site_code"),
      value: SiteCode,
    },
    {
      label: t("data_grid.headers.site_type"),
      value: SiteType,
    },
    {
      label: t("data_grid.headers.date"),
      value: WorkOrderDate,
    },
    {
      label: t("data_grid.headers.status"),
      value: <ChipStatus value={WorkOrderStatus} />,
    },
    {
      label: t("data_grid.headers.hold_reason"),
      value: WorkOrderHoldReason,
    },
    {
      label: t("data_grid.headers.typology"),
      value: Typology,
    },
    {
      label: t("data_grid.headers.authorities"),
      value: RejectedByAuthorities,
    },
    {
      label: t("data_grid.headers.requestor"),
      value: RequestorsName,
    },
    {
      label: t("data_grid.headers.team_leader"),
      value: TeamLeadersName,
    },
    {
      label: t("data_grid.headers.user"),
      value: ERPUserNickName,
    },
    {
      label: t("data_grid.headers.project"),
      value: CompanyProjectsName,
    },
    {
      label: t("data_grid.headers.sub_project"),
      value: SubProjectsName,
    },
    {
      label: t("data_grid.headers.latitude"),
      value: Latitude,
    },
    {
      label: t("data_grid.headers.longitude"),
      value: Longitude,
    },
    {
      label: t("data_grid.headers.governorate_name"),
      value: GovernoratesName,
    },
    {
      label: t("data_grid.headers.site_address"),
      value: SiteAddress,
    },
    {
      label: t("data_grid.headers.description"),
      value: WorkOrderDiscription,
    },
  ];

  return (
    <Stack spacing={1.5} p={1}>
      <Grid container spacing={1}>
        {Invoices.map(({ label, value }, index) => (
          <Grid item xs={index === Invoices.length - 1 ? 8 : 4} key={label}>
            <ChipComponent label={label} value={value} />
          </Grid>
        ))}
      </Grid>
    </Stack>
  );
};
export default WorkOrdersSnapShot;
