import { useQuery } from "@tanstack/react-query";
import { useTranslation } from "react-i18next";
import { usersManagementService } from "../../../../api/usersManagement";
import FormSpinner from "../../../Spinners/FormSpinner";
import FormsDataGrid from "../../../DataGrid/FormsDataGrid";

const SelectEmployees = ({ setValue, empCode }) => {
  const [t] = useTranslation("global");

  const columns = [
    {
      field: "EmpCode",
      headerName: t("data_grid.headers.emp_code"),
    },
    {
      field: "EmpName",
      headerName: t("data_grid.headers.name"),
      width: 120,
      flex: 1,
    },
    {
      field: "EmpTitle",
      headerName: t("data_grid.headers.emp_title"),
    },
  ];

  const handleRowSelectionModelChange = (newSelectedRows) => {
    // If newSelectedRows array has more than one item, keep only the second item
    if (newSelectedRows.length > 1) {
      newSelectedRows = [newSelectedRows[1]];
    }

    // Disable the "Select All" checkbox
    const updatedSelectionModel =
      newSelectedRows.length === 1 ? newSelectedRows : [];

    // Update selectedRows state with the updatedSelectionModel
    setValue("empCode", updatedSelectionModel);
  };

  const { data, isLoading } = useQuery({
    queryKey: ["usersManagementFormData"],
    queryFn: usersManagementService.GetUsersManagementFormData,
  });

  if (isLoading) return <FormSpinner />;

  return (
    <FormsDataGrid
      rows={data?.Employees || []}
      columns={columns}
      getRowId={(row) => row.EmpCode}
      disableColumnMenu
      checkboxSelection
      disableMultipleRowSelection
      onRowSelectionModelChange={handleRowSelectionModelChange}
      rowSelectionModel={empCode}
      showQuickFilter
      toolbar
      height={400}
      sx={{
        "& .MuiDataGrid-columnHeaderCheckbox": {
          pointerEvents: "none",
        },
      }}
    />
  );
};

export default SelectEmployees;
