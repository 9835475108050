import { Box, Step, StepLabel, Stepper } from "@mui/material";
import StepperButton from "../../../../components/forms/ButtonGroup/StepperButton";
import ChartOptions from "./Steps/ChartOptions";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import ChartType from "./Steps/ChartType";
import * as yup from "yup";
import { useFormik } from "formik";
import ChartData from "./Steps/ChartData";
import PieChartContainer from "../../../../components/charts/PieChart";
import BarChart from "../../../../components/charts/BarChart";
import ChartClickableData from "./Steps/ChartClickableData";
import Save from "./Steps/Save";
import { useQuery } from "@tanstack/react-query";
import { DashboardService } from "../../../../api/dashboard";
import { useSelector } from "react-redux";
import { grey } from "@mui/material/colors";

const getChartData = (totalToggleState, barChartDataByCount) => {
  return totalToggleState
    ? barChartDataByCount
    : barChartDataByCount.filter((data) => data !== "Total");
};

const getSeriesData = (
  totalToggleState,
  barChartByCountSeries,
  type = "Count" // Count | Amount
) => {
  return totalToggleState
    ? barChartByCountSeries
    : [
        {
          name: type,
          data: barChartByCountSeries[0].data.slice(1),
        },
      ];
};

const placeholderData = {
  msg: [
    {
      Elements: "Element 1",
      Data1: 10,
      Data2: "3,343,000.00",
    },
    {
      Elements: "Element 2",
      Data1: 20,
      Data2: "2,343,000.00",
    },
    {
      Elements: "Element 3",
      Data1: 30,
      Data2: "5,343,000.00",
    },
    {
      Elements: "Element 4",
      Data1: 40,
      Data2: "9,566,565.55",
    },
  ],
  msg2: [
    {
      Elements: "Element 1",
      Data1: 66,
      Data2: "4,343,000.00",
    },
    {
      Elements: "Element 2",
      Data1: 90,
      Data2: "20,343,000.00",
    },
    {
      Elements: "Element 3",
      Data1: 45,
      Data2: "5,343,000.00",
    },
    {
      Elements: "Element 4",
      Data1: 25,
      Data2: "1,566,565.55",
    },
  ],
};

const percentageBarChartData = [
  "PO1",
  "PO2",
  "PO3",
  "PO4",
  "PO5",
  "PO6",
  "PO7",
  "PO8",
];

const percentageBarSeries = [
  {
    name: "Percentage",
    data: [102.73, 98.42, 95.74, 90.09, 70, 50.53, 20, 0],
  },
];

const NewChart = () => {
  const { t } = useTranslation("global");

  const initialValues = {
    chartName: "",
    chartType: "Bar", // Bar | Donut
    totalToggleState: false,
    multiValueToggleState: false,
    countAmountToggleState: false,
    isHorizontalBar: false,
    leftValue: "Value 1",
    rightValue: "Value 2",
    barThickness: "25",
    distributed: false, // This option controls if the bar colors should look different or not
    clickableValues: false, // This option controls if the values should be clickable or not
    circleChartType: false,
    chartDataUserDefined: false,
    clickableValuesUserDefined: false,
    dataQuery: "",
    leftValueQuery: "",
    rightValueQuery: "",
    totalQuery: "",
    elementQuery: "",
    percentageBar: false,
  };

  const schema = yup.object().shape({
    chartName: yup.string().required(t("modules.global_schema.required")),
    chartType: yup.string().required(t("modules.global_schema.required")),
    totalToggleState: yup.boolean(),
    multiValueToggleState: yup.boolean(),
    countAmountToggleState: yup.boolean(),
    isHorizontalBar: yup.boolean(),
    leftValue: yup.string().required(t("modules.global_schema.required")),
    rightValue: yup.string().required(t("modules.global_schema.required")),
    barThickness: yup
      .number()
      .required(t("modules.global_schema.required"))
      .min(1, "Minimum value is 1")
      .max(100, "Maximum value is 100"),
    distributed: yup.boolean(),
    clickableValues: yup.boolean(),
    circleChartType: yup.boolean(),
    dataQuery: yup.string().nullable(),
    valueOneQuery: yup.string().nullable(),
    valueTwoQuery: yup.string().nullable(),
    totalQuery: yup.string().nullable(),
    elementQuery: yup.string().nullable(),
    chartDataUserDefined: yup.boolean(),
    clickableValuesUserDefined: yup.boolean(),
    percentageBar: yup.boolean(),
  });

  const handleFormSubmit = (values) => {
    // addNewAttendance(values);
  };

  const {
    handleSubmit,
    setFieldValue,
    values,
    errors,
    touched,
    handleBlur,
    handleChange,
  } = useFormik({
    initialValues,
    validationSchema: schema,
    onSubmit: handleFormSubmit,
  });

  const [activeStep, setActiveStep] = useState(0);

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleChartType = (type) => setFieldValue("chartType", type);

  const {
    chartName,
    chartType,
    totalToggleState,
    multiValueToggleState,
    countAmountToggleState,
    isHorizontalBar,
    leftValue,
    rightValue,
    barThickness,
    distributed,
    clickableValues,
    circleChartType,
    chartDataUserDefined,
    dataQuery,
    leftValueQuery,
    rightValueQuery,
    percentageBar,
  } = values;

  const params = {
    State: "Test",
    Type: multiValueToggleState ? "MultiChartData" : "ChartData",
    UserDefined: chartDataUserDefined,
  };

  if (dataQuery !== "") {
    params.DataQuery = dataQuery;
  } else {
    params.DataQuery1 = leftValueQuery;
    params.DataQuery2 = rightValueQuery;
  }

  const { data, isRefetching, refetch } = useQuery({
    queryKey: ["dashboardQueryData"],
    queryFn: () => DashboardService.getChartQueryData(params),
    enabled: false,
    placeholderData,
  });

  // First View -> msg: [{Elements: "Element 1", Data1: 10, Data2: "3,343,000.00"}, ...]
  // Data 1 => Count
  const dataSorted = data.msg.toSorted((a, b) => b.Data1 - a.Data1);
  const total = dataSorted.reduce((acc, item) => acc + item.Data1, 0);
  const chartValues = dataSorted.map((item) => item.Data1);

  let categories = dataSorted.map((item) => item.Elements);
  categories = ["Total", ...categories];
  const series = [
    {
      name: "Count",
      data: [total, ...chartValues],
    },
  ];

  const chartData = getChartData(totalToggleState, categories);

  const firstViewCountData = getSeriesData(totalToggleState, series);

  // Data2 => Amount
  const dataSorted2 = data.msg.toSorted(
    (a, b) =>
      parseFloat(b.Data2?.replace(/,/g, "")) -
      parseFloat(a.Data2?.replace(/,/g, ""))
  );

  const total2 = dataSorted2.reduce(
    (acc, item) => acc + parseFloat(item.Data2?.replace(/,/g, "")),
    0
  );

  const chartValues2 = dataSorted2.map((item) =>
    parseFloat(item.Data2?.replace(/,/g, ""))
  );

  const series2 = [
    {
      name: "Amount",
      data: [total2, ...chartValues2],
    },
  ];

  const firstViewAmountData = getSeriesData(
    totalToggleState,
    series2,
    "Amount"
  );

  // Second View -> msg2: [{Elements: "Element 1", Data1: 10, Data2: "3,343,000.00"}, ...]

  // Data 1 => Count
  const dataSorted3 = data.msg2.toSorted((a, b) => b.Data1 - a.Data1);
  const total3 = dataSorted3.reduce((acc, item) => acc + item.Data1, 0);
  const chartValues3 = dataSorted3.map((item) => item.Data1);

  let categories3 = dataSorted3.map((item) => item.Elements);
  categories3 = ["Total", ...categories3];

  const series3 = [
    {
      name: "Count",
      data: [total3, ...chartValues3],
    },
  ];

  const chartData3 = getChartData(totalToggleState, categories3);

  const secondViewCountData = getSeriesData(totalToggleState, series3);

  // Data2 => Amount
  const dataSorted4 = data.msg2.toSorted(
    (a, b) =>
      parseFloat(b.Data2?.replace(/,/g, "")) -
      parseFloat(a.Data2?.replace(/,/g, ""))
  );

  const total4 = dataSorted4.reduce(
    (acc, item) => acc + parseFloat(item.Data2?.replace(/,/g, "")),
    0
  );

  const chartValues4 = dataSorted4.map((item) =>
    parseFloat(item.Data2?.replace(/,/g, ""))
  );

  const series4 = [
    {
      name: "Amount",
      data: [total4, ...chartValues4],
    },
  ];

  const secondViewAmountData = getSeriesData(
    totalToggleState,
    series4,
    "Amount"
  );

  // First View final series data
  const firstViewSeriesData =
    countAmountToggleState && multiValueToggleState
      ? secondViewCountData
      : !multiValueToggleState && countAmountToggleState
      ? firstViewAmountData
      : multiValueToggleState && !countAmountToggleState
      ? secondViewCountData
      : firstViewCountData;

  const firstViewSeriesData2 =
    countAmountToggleState && multiValueToggleState
      ? firstViewCountData
      : !multiValueToggleState && countAmountToggleState
      ? firstViewCountData
      : multiValueToggleState && !countAmountToggleState
      ? firstViewCountData
      : firstViewAmountData;

  // Second View final series data
  const secondViewSeriesData1 =
    countAmountToggleState && multiValueToggleState
      ? secondViewAmountData
      : !multiValueToggleState && countAmountToggleState
      ? secondViewAmountData
      : multiValueToggleState && !countAmountToggleState
      ? secondViewCountData
      : secondViewAmountData;

  const secondViewSeriesData2 =
    countAmountToggleState && multiValueToggleState
      ? firstViewAmountData
      : !multiValueToggleState && countAmountToggleState
      ? secondViewCountData
      : secondViewAmountData;

  // Pie Chart data
  const pieChartData = data.msg.map((item) => {
    return {
      name: item.Elements,
      data: item.Data1,
      amount: parseFloat(item.Data2?.replace(/,/g, "")),
    };
  });

  const pieChartData2 = data.msg2.map((item) => {
    return {
      name: item.Elements,
      data: item.Data1,
      amount: parseFloat(item.Data2?.replace(/,/g, "")),
    };
  });

  const theme = useSelector((state) => state.theme.colorTheme); // light | dark

  const percentageBarOptions = {
    dataLabels: {
      enabled: true,
    },
    plotOptions: {
      bar: {
        barHeight: `${barThickness}%`,
        horizontal: true,
        dataLabels: {
          position: "top",
        },
        colors: {
          ranges: [
            {
              from: 0,
              to: 25,
              color: "#26A0FC", // Red for values <= 25
            },
            {
              from: 26,
              to: 50,
              color: "#ffc107", // Yellow for values <= 50
            },
            {
              from: 51,
              to: 75,
              color: "#ef6c00", // Orange for values <= 75
            },
            {
              from: 76,
              to: 100,
              color: "#388e3c", // Blue for values between 76 and 100
            },
            {
              from: 100.01,
              to: Infinity,
              color: "#f00", // Red for values above 100
            },
          ],
        },
      },
    },
    xaxis: {
      min: 0,
      categories: percentageBarChartData,
      labels: {
        style: {
          fontSize: "11px",
          colors: theme === "dark" ? grey[300] : grey[700],
        },
        formatter: (value) => Math.round(value), // Rounds the value
      },
    },
    markers: {
      size: 6,
      colors: ["red"],
      strokeColor: "#fff",
      strokeWidth: 2,
      offsetY: 0,
      offsetX: 100, // This places the marker at value 100
      markerShape: "circle",
    },
    annotations: {
      xaxis: [
        {
          x: 100, // Marker at 100
          borderColor: "red",
          strokeDashArray: 0, // Make it a solid line
          strokeWidth: 4, // Make the marker bolder
          label: {
            borderColor: "red",
            borderWidth: "2px",
            style: {
              color: "#fff",
              background: "red",
            },
            text: "%100",
            position: "top", // Position the text above the marker
            offsetY: -10, // Offset the label upwards
            orientation: "horizontal", // Ensure the text is horizontal
          },
        },
      ],
    },
  };

  const chartTypesMap = {
    Bar: (
      <BarChart
        isDemo={{ isClickable: clickableValues }}
        height={350}
        barOptions={{
          horizontal: isHorizontalBar,
          columnWidth: `${barThickness}%`,
          barHeight: `${barThickness}%`,
          distributed,
        }}
        chartData={percentageBar ? percentageBarChartData : chartData}
        chartData2={chartData}
        seriesData={percentageBar ? percentageBarSeries : firstViewSeriesData}
        seriesData2={firstViewSeriesData2}
        title={chartName}
        type="bar"
        switchConfig={
          countAmountToggleState && multiValueToggleState
            ? {
                leftLabel: leftValue,
                rightLabel: rightValue,
              }
            : countAmountToggleState && !multiValueToggleState
            ? {
                leftLabel: t("main_cards.labels.by_count"),
                rightLabel: t("main_cards.labels.by_amount"),
              }
            : !countAmountToggleState &&
              multiValueToggleState && {
                leftLabel: leftValue,
                rightLabel: rightValue,
              }
        }
        switch2Config={
          countAmountToggleState &&
          multiValueToggleState && {
            leftLabel: t("main_cards.labels.by_count"),
            rightLabel: t("main_cards.labels.by_amount"),
          }
        }
        secondView={
          countAmountToggleState &&
          multiValueToggleState && {
            chartData: chartData3,
            chartData2: chartData3,
            seriesData: secondViewSeriesData1,
            seriesData2: secondViewSeriesData2,
          }
        }
        chartOptionsConfig={
          percentageBar && {
            plotOptions: percentageBarOptions.plotOptions,
            annotations: percentageBarOptions.annotations,
            xaxis: percentageBarOptions.xaxis,
            markers: percentageBarOptions.markers,
            dataLabels: percentageBarOptions.dataLabels,
          }
        }
        className={percentageBar ? "no-bolded-bar" : ""}
      />
    ),
    Donut: (
      <PieChartContainer
        isDemo={{ isClickable: clickableValues }}
        chartType={circleChartType ? "pie" : "donut"}
        height={300}
        chartData={
          countAmountToggleState && multiValueToggleState
            ? pieChartData2
            : countAmountToggleState && !multiValueToggleState
            ? pieChartData.map((item) => {
                return { ...item, data: item.amount };
              })
            : !countAmountToggleState && multiValueToggleState
            ? pieChartData2
            : !countAmountToggleState && !multiValueToggleState
            ? pieChartData
            : pieChartData
        }
        chartData2={pieChartData}
        title={chartName}
        switchConfig={
          countAmountToggleState && multiValueToggleState
            ? {
                leftLabel: leftValue,
                rightLabel: rightValue,
              }
            : countAmountToggleState && !multiValueToggleState
            ? {
                leftLabel: t("main_cards.labels.by_count"),
                rightLabel: t("main_cards.labels.by_amount"),
              }
            : !countAmountToggleState &&
              multiValueToggleState && {
                leftLabel: leftValue,
                rightLabel: rightValue,
              }
        }
        switch2Config={
          countAmountToggleState &&
          multiValueToggleState && {
            leftLabel: t("main_cards.labels.by_count"),
            rightLabel: t("main_cards.labels.by_amount"),
          }
        }
        secondView={
          multiValueToggleState &&
          countAmountToggleState && {
            chartData: pieChartData.map((item) => {
              return { ...item, data: item.amount };
            }),
            chartData2: pieChartData2.map((item) => {
              return { ...item, data: item.amount };
            }),
          }
        }
      />
    ),
  };

  let steps = [
    "Chart Type",
    "Chart Options",
    "Chart Data",
    "Chart Clickable Data",
    "Test & Save",
  ];

  steps = steps.filter((step) =>
    clickableValues ? true : step !== "Chart Clickable Data"
  );

  let views = {
    0: (
      <ChartType
        setActiveStep={setActiveStep}
        handleChartType={handleChartType}
      />
    ),
    1: (
      <ChartOptions
        values={values}
        setFieldValue={setFieldValue}
        errors={errors}
        touched={touched}
        handleBlur={handleBlur}
        handleChange={handleChange}
        chartTypesMap={chartTypesMap}
      />
    ),
    2: (
      <ChartData
        chart={chartTypesMap[chartType]}
        values={values}
        setFieldValue={setFieldValue}
        handleChange={handleChange}
        handleBlur={handleBlur}
        errors={errors}
        touched={touched}
        refetch={refetch}
        isRefetching={isRefetching}
      />
    ),
  };

  if (steps.includes("Chart Clickable Data")) {
    views[3] = (
      <ChartClickableData
        chart={chartTypesMap[chartType]}
        values={values}
        setFieldValue={setFieldValue}
        handleChange={handleChange}
        handleBlur={handleBlur}
        errors={errors}
        touched={touched}
      />
    );
    views[4] = <Save chart={chartTypesMap[chartType]} />;
  } else {
    views[3] = <Save chart={chartTypesMap[chartType]} />;
  }

  return (
    <Box
      sx={{
        width: "100%",
        display: "flex",
        flexDirection: "column",
        gap: 2,
        padding: "1rem",
        height: "35rem",
      }}
    >
      <Stepper activeStep={activeStep}>
        {steps.map((label) => {
          return (
            <Step key={label}>
              <StepLabel>{label}</StepLabel>
            </Step>
          );
        })}
      </Stepper>
      {views[activeStep]}
      {activeStep !== 0 && (
        <StepperButton
          onClick={handleNext}
          handleBack={handleBack}
          activeStep={activeStep}
          // disabled={isNextBtnDisabled}
          label={
            activeStep === steps.length - 1
              ? t("buttons.create")
              : t("modules.buttons.next")
          }
          isLastStep={activeStep === steps.length - 1}
        />
      )}
    </Box>
  );
};

export default NewChart;
