import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import CloseIcon from "@mui/icons-material/Close";
import { IconButton, Stack, Slide, Modal } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useSelector, useDispatch } from "react-redux";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import { useState } from "react";
import { setSideDrawerState } from "../../store/slices/themeSlice";
import { useConfirmationDialog } from "../../contexts/ConfirmationDialogContext";

const FormCard = ({
  open,
  handleClose,
  title,
  content,
  width,
  handleCancel,
}) => {
  const [dirty, setDirty] = useState(false);

  const onDirtyChange = (isDirty) => {
    setDirty(isDirty);
  };

  const { language } = useSelector((state) => state.theme);

  const [t] = useTranslation("global");

  const { setOpen } = useConfirmationDialog();

  const onClickClose = () => {
    if (dirty) {
      setOpen(t("modules.confirmation_message"), () => handleCancel);
    } else {
      handleCancel();
    }
  };

  const style = {
    position: "absolute",
    transform: "translate(-50%, -50%)",
    bgcolor: "background.paper",
    borderRadius: "8px",
    overflow: "hidden",
    boxShadow: 24,
    width: { width },
  };

  const dispatch = useDispatch();

  return (
    <Modal
      open={open}
      onClose={() => dispatch(setSideDrawerState())}
      aria-labelledby="modal-title"
      aria-describedby="modal-description"
      sx={{
        display: "grid",
        placeItems: "center",
      }}
      keepMounted
    >
      <Slide
        direction={language == "EN" ? "left" : "right"}
        in={open}
        timeout={500}
      >
        <Box sx={style}>
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            padding="0px 8px"
            backgroundColor="background.formHeader"
            color="white"
          >
            <Typography
              sx={{
                fontSize: "17px",
                fontWeight: 500,
              }}
            >
              {title}
            </Typography>
            <Stack direction="row" spacing={2}>
              <IconButton onClick={handleClose}>
                {language === "AR" ? (
                  <ArrowBackIosNewIcon
                    style={{ color: "#fff", fontSize: 20 }}
                  />
                ) : (
                  <ArrowForwardIosIcon
                    style={{ color: "#fff", fontSize: 20 }}
                  />
                )}
              </IconButton>

              <IconButton onClick={onClickClose} color="error">
                <CloseIcon style={{ color: "#ff4d6d", fontSize: 20 }} />
              </IconButton>
            </Stack>
          </Box>
          <Box sx={{ padding: "0 10px", paddingTop: "10px" }}>
            {typeof content === "function"
              ? content({ onDirtyChange, onClickClose })
              : content}
          </Box>
        </Box>
      </Slide>
    </Modal>
  );
};

export default FormCard;
