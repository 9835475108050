import Axios from "../network/Axios";

// Common function for GET requests with params
const fetchData = async (params) => {
  const response = await Axios.get("/UsersManagement", { params });
  return response.data;
};

// Specific service for updating user preferences or change request status
const updateUserPreferences = async (data) => {
  const res = await Axios.put("/UserInterface", {
    DarkMode: data.DarkMode,
    Language: data.Language,
  });
  return res.data;
};

const updateChangeRequestStatus = async (data) => {
  const url =
    data.type === "Materials"
      ? "/MaterialChangeRequests"
      : "/MissionChangeRequests";
  const payload =
    data.type === "Materials"
      ? {
          ChangeRequestStatus: data.ChangeRequestStatus,
          SelectedNo: data.SelectedID.toString(),
        }
      : {
          MissionsChangeRequestStatus: data.ChangeRequestStatus,
          SelectedID: data.SelectedID,
        };

  const res = await Axios.put(url, payload);
  return res.data;
};

// State-to-key mapping for notifications modal
const stateToKeyMapping = {
  NationalIDexpired: "NationalIDexpired",
  DrivingLicenceExpired: "NationalIDexpired",
  CarLicenseExpired: "CarLicenseExpired",
};

// API Services
export const DashboardService = {
  getNotifications: () => fetchData({ State: "NotificationsNew" }),
  updateUserPreferences,
  updateChangeRequestStatus,
  getWorkOrdersInProgress: (project, state) =>
    fetchData({ State: state, SelectedNo: project }),
  getOpenMissionsByState: (selectedNo, state) =>
    fetchData({ State: state, SelectedNo: selectedNo }),
  getInvoicesNotifications: () => fetchData({ State: "AllInvoices" }),
  getInsurancesNotifications: (State) => fetchData({ State }),
  getCompletedWorkWithoutAcceptanceByState: (selectedNo, state) =>
    fetchData({ State: state, SelectedNo: selectedNo }),
  getDashboard: () => fetchData({ State: "Dashboard" }),
  getDashboardChartDataByState: (selectedNo, state) =>
    fetchData({ State: state, SelectedNo: selectedNo }),
  getNotificationsModalData: async (state) => {
    const data = await fetchData({ State: state });
    return data[stateToKeyMapping[state]];
  },
  getDashboardChartData: async () => {
    const data = await fetchData({ State: "Graph" });
    return data.result;
  },
  getChartQueryData: async (data) => {
    const response = await Axios.get("/Dashboard", { params: data });
    return response.data;
  },
};
