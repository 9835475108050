import Axios from "../network/Axios";

const FILE_FOLDERS_PATH = "/CloudStorageNew";

const getAllFilesAndFolders = async (path) => {
  const response = await Axios.get(FILE_FOLDERS_PATH, {
    params: {
      action: "Directory",
      Directory: path,
    },
  });
  return response.data.msg;
};

// Get file
const getFile = async (path, fileName) => {
  const response = await Axios.get(FILE_FOLDERS_PATH, {
    params: {
      action: "File",
      Directory: path,
      FileName: fileName,
    },
  });
  return response.data.msg;
};

// Get DB and Cloud data
const getDBAndCloudData = async () => {
  const response = await Axios.get(FILE_FOLDERS_PATH, {
    params: {
      action: "Migrate",
    },
  });
  return response.data;
};

const getMigrateStatus = async () => {
  const response = await Axios.get(FILE_FOLDERS_PATH, {
    params: {
      action: "MigrateStatus",
    },
  });
  return response.data;
};
const getLogs = async () => {
  const response = await Axios.get(FILE_FOLDERS_PATH, {
    params: {
      action: "Log",
    },
  });
  return response.data;
};
const getLogsByDate = async (startDate, endDate) => {
  const response = await Axios.get(FILE_FOLDERS_PATH, {
    params: {
      action: "Log",
      Start: startDate,
      End: endDate,
    },
  });
  return response.data;
};
const updateData = async () => {
  const response = await Axios.patch(FILE_FOLDERS_PATH, {
    action: "UpdateData",
  });
  return response.data;
};
const migrationStart = async (action, deleteSrc) => {
  const response = await Axios.patch(FILE_FOLDERS_PATH, {
    action: "MigrationStart",
    Action: action,
    Delete: deleteSrc,
  });
  return response.data;
};
const cancelMigration = async () => {
  const response = await Axios.put(FILE_FOLDERS_PATH, {
    params: {
      action: "CancelMigrate",
    },
  });
  return response.data;
};

export const filesFoldersService = {
  getAllFilesAndFolders,
  getFile,
  getDBAndCloudData,
  getMigrateStatus,
  getLogs,
  getLogsByDate,
  updateData,
  migrationStart,
  cancelMigration,
};
