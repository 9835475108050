import { useQuery } from "@tanstack/react-query";
import { employeeRecordsService } from "../../../../api/employeeRecords";
import FormSpinner from "../../../Spinners/FormSpinner";
import { Box, Stack, TextField, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import { convertImage } from "../../../../utils/utils";
import ChipComponent from "../../../Chips/ChipComponent";

const NewInfoField = ({ label, name, errors, register }) => {
  return (
    <Stack
      direction={"row"}
      spacing={2}
      alignItems={"center"}
      sx={{
        position: "relative",
      }}
    >
      <TextField
        size="small"
        label={label}
        error={Boolean(errors[name])}
        helperText={errors[name]?.message}
        {...register(name)}
        sx={{
          flexGrow: 1,
        }}
        required
      />
    </Stack>
  );
};

const BasicInformation = ({
  isError,
  empCode,
  values,
  errors,
  dispatch,
  register,
}) => {
  const [t] = useTranslation("global");

  const { username, nickname } = values;

  const { data, isLoading } = useQuery({
    queryKey: ["employeeData"],
    queryFn: () => employeeRecordsService.GetEmployeeData(empCode),
  });

  if (isLoading) return <FormSpinner />;

  return (
    <Box display="flex" justifyContent="space-between" mx="1rem">
      <Box display="flex" flexDirection="column" width="55%" gap="1rem">
        <Box display="flex" flexDirection="column" gap="1rem">
          <ChipComponent
            label={t("modules.users_management.new_form.labels.english_name")}
            value={data.EmpNameEn}
          />
          <ChipComponent
            label={t("modules.users_management.new_form.labels.arabic_name")}
            value={data.EmpName}
          />
        </Box>
        <Box display="flex" flexDirection="column" gap="1rem">
          <NewInfoField
            label={t("modules.users_management.new_form.fields.username")}
            value={username}
            name="username"
            reactQueryKey="employeeUsername"
            getFunction="CheckUsername"
            errors={errors}
            isError={isError}
            dispatch={dispatch}
            register={register}
          />
          <NewInfoField
            label={t("modules.users_management.new_form.fields.display_name")}
            value={nickname}
            name="nickname"
            reactQueryKey="employeeNickname"
            getFunction="CheckNickname"
            errors={errors}
            isError={isError}
            dispatch={dispatch}
            register={register}
          />
          <TextField
            type="email"
            size="small"
            fullWidth
            label={t("modules.users_management.new_form.fields.user_email")}
            error={Boolean(errors.email)}
            helperText={errors.email?.message}
            {...register("email")}
            required
          />
        </Box>
      </Box>
      <Box
        sx={{
          border: "1px solid",
          borderColor: "secondary.main",
          borderRadius: 2,
          overflow: "hidden",
          width: "320px",
          height: "300px",
        }}
      >
        <img
          src={convertImage(data?.EmpPhoto?.data)}
          alt="employee"
          style={{ objectFit: "contain", width: "100%", height: "100%" }}
        />
      </Box>
    </Box>
    // <Stack direction={"row"} gap="2rem">
    //   <Stack justifyContent={"center"} spacing={3}>
    //     <Stack spacing={1}>
    //       <Stack direction={"row"} spacing={1}>
    //         <ChipComponent
    //           label={t("modules.users_management.new_form.labels.english_name")}
    //           value={data.EmpNameEn}
    //         />
    //         <ChipComponent
    //           label={t("modules.users_management.new_form.labels.arabic_name")}
    //           value={data.EmpName}
    //         />
    //       </Stack>
    //     </Stack>
    //     <Stack spacing={2}>
    //       <NewInfoField
    //         label={t("modules.users_management.new_form.fields.username")}
    //         value={username}
    //         name="username"
    //         reactQueryKey="employeeUsername"
    //         getFunction="CheckUsername"
    //         errors={errors}
    //         isError={isError}
    //         dispatch={dispatch}
    //         register={register}
    //       />
    //       <NewInfoField
    //         label={t("modules.users_management.new_form.fields.display_name")}
    //         value={nickname}
    //         name="nickname"
    //         reactQueryKey="employeeNickname"
    //         getFunction="CheckNickname"
    //         errors={errors}
    //         isError={isError}
    //         dispatch={dispatch}
    //         register={register}
    //       />

    //       <TextField
    //         type="email"
    //         size="small"
    //         label={t("modules.users_management.new_form.fields.user_email")}
    //         error={Boolean(errors.email)}
    //         helperText={errors.email?.message}
    //         {...register("email")}
    //         required
    //       />
    //     </Stack>
    //   </Stack>

    // </Stack>
  );
};

export default BasicInformation;
