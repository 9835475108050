import { Box, Grid } from "@mui/material";
import InfoCard from "../../../../../components/InfoPagesCard/InfoCard";
import ChipComponent from "../../../../../components/Chips/ChipComponent";
import { useState } from "react";
import EditEmployeeDetails from "./Edit/EditEmployeeDetails";
import { useQuery } from "@tanstack/react-query";
import { useParams } from "react-router-dom";
import { employeeRecordsService } from "../../../../../api/employeeRecords";
import ChipStatus from "../../../../../components/ChipStatus";
import { useTranslation } from "react-i18next";
import { useLabels } from "./hooks/useLabels";

const EmployeeDetails = ({ data }) => {
  const [t] = useTranslation("global");

  const {
    CompanyDepartmentName,
    CompanyProjectsName,
    EmpDateOfHire,
    EmpEmail,
    EmpInfoDrive,
    EmpInfoEntExt,
    EmpInfoMission,
    EmpInsurance,
    EmpMedical,
    EmpResignDate,
    EmpStatus,
  } = data;

  const {
    department,
    project,
    hireDate,
    email,
    status,
    medicalInsurance,
    drive,
    intExt,
    mission,
    socialInsurance,
    resignDate,
  } = useLabels();

  const employeeDetails = [
    {
      label: department,
      value: CompanyDepartmentName,
    },
    {
      label: project,
      value: CompanyProjectsName,
    },
    {
      label: hireDate,
      value: EmpDateOfHire,
    },
    {
      label: email,
      value: EmpEmail,
    },
    {
      label: status,
      value: <ChipStatus value={EmpStatus} />,
    },
    {
      label: medicalInsurance,
      value: EmpMedical,
    },
    {
      label: drive,
      value: EmpInfoDrive === "True" ? "Yes" : "No",
    },
    {
      label: intExt,
      value: EmpInfoEntExt,
    },
    {
      label: mission,
      value: EmpInfoMission === "True" ? "Yes" : "No",
    },
    {
      label: socialInsurance,
      value: EmpInsurance,
    },
    {
      label: resignDate,
      value: EmpResignDate,
      key: "resignDate",
    },
  ];

  const [editMode, setEditMode] = useState(false);

  // turn on editMode when the user clicks on the edit button
  const handleOpenEditMode = () => {
    setEditMode(true);
  };

  // turn off editMode when the user clicks on the cancel button
  const handleCancelEditMode = () => {
    setEditMode(false);
  };

  const { empCode } = useParams();

  const { data: employeeInfoEditFormData, refetch } = useQuery({
    queryKey: ["employeeInfoEditFormData", empCode],
    queryFn: () => employeeRecordsService.getEmployeeInfoEditFormData(empCode),
    enabled: false,
  });

  return (
    <InfoCard
      editButton
      onClickEdit={() => refetch().then(handleOpenEditMode)}
      title={t("info_modules.employee_records.cards.employee_details.title")}
      content={
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: "1rem",
          }}
        >
          {editMode ? (
            <EditEmployeeDetails
              data={employeeInfoEditFormData}
              handleClose={handleCancelEditMode}
            />
          ) : (
            <Grid container spacing={1}>
              {employeeDetails
                .filter((employee) =>
                  employee.key ? employee.value !== null && employee : employee
                )
                .map((property) => (
                  <Grid item xs={4} key={property.label}>
                    <ChipComponent
                      label={property.label}
                      value={property.value}
                    />
                  </Grid>
                ))}
            </Grid>
          )}
        </Box>
      }
    />
  );
};

export default EmployeeDetails;
