import { useQuery } from "@tanstack/react-query";
import CustomDataGrid from "../components/DataGrid/CustomDataGrid";
import { useTranslation } from "react-i18next";
import { statusColors } from "./statuses";
import Spinner from "../components/Spinners/Spinner";
import ChipStatus from "../components/ChipStatus";
import { priceListsService } from "../api/priceLists";
import useOpenNewForm from "../hooks/useOpenNewForm";
import { useSelector } from "react-redux";
import { useConfirmationDialog } from "../contexts/ConfirmationDialogContext";
import { Link } from "react-router-dom";
import dayjs from "dayjs";

const PriceLists = () => {
  const { newForm } = useSelector((state) => state.theme);

  const [t] = useTranslation("global");

  const statusNames = [
    {
      name: "Active",
      displayName: t("data_grid.tabs.active"),
    },
    { name: "Inactive", displayName: t("data_grid.tabs.inactive") },
  ];

  const columns = [
    {
      field: "PriceListRefNo",
      headerName: t("data_grid.headers.reference"),
      minWidth: 180,
      flex: 1,
      hideable: false,
      renderCell: (params) => {
        return (
          <Link
            style={{
              color: "#1790FF",
            }}
            to={{
              pathname: `/pricelists/${params.value}`,
              search: `?SelectedID=${params.row.idPriceListRef}`,
            }}
          >
            {params.value}
          </Link>
        );
      },
    },
    {
      field: "CompanyAccountName",
      headerName: t("data_grid.headers.account_name"),
    },
    {
      field: "PriceListYear",
      headerName: t("data_grid.headers.price_list_year"),
      width: 140,
    },
    {
      field: "CreationDate",
      headerName: t("data_grid.headers.creation_date"),
      width: 140,
      type: "date",
      valueGetter: ({ value }) => (value ? new Date(value) : null),
      renderCell: ({ value }) => {
        return value ? dayjs(value).format("YYYY/MM/DD") : "";
      },
    },
    {
      field: "Total",
      headerName: t("data_grid.headers.total_items"),
      width: 140,
    },
    {
      field: "LastUpdateDate",
      headerName: t("data_grid.headers.last_update"),
      width: 140,
      type: "date",
      valueGetter: ({ value }) => (value ? new Date(value) : null),
      renderCell: ({ value }) => {
        return value ? dayjs(value).format("YYYY/MM/DD") : "";
      },
    },
    {
      field: "ERPUserNickName",
      headerName: t("data_grid.headers.created_by"),
      width: 140,
    },
    {
      field: "Status",
      headerName: t("data_grid.headers.status"),
      width: 130,
      renderCell: ({ value }) => {
        return <ChipStatus value={value} />;
      },
    },
  ];
  const {
    data: rows,
    isLoading,
    refetch,
    isRefetching,
  } = useQuery({
    queryKey: ["priceLists"],
    queryFn: priceListsService.GetPriceLists,
  });

  // Handling the new form logic
  const { handleOpen } = useOpenNewForm();
  const { setOpen } = useConfirmationDialog();

  if (isLoading) {
    return <Spinner />;
  }

  return (
    <CustomDataGrid
      rows={rows}
      columns={columns}
      loading={isLoading}
      getRowId={(row) => row.PriceListRefNo}
      statusColors={statusColors}
      statusNames={statusNames}
      filterField="Status"
      reloadFunction={refetch}
      showReloadOverlay={isRefetching}
      newButton
      newFunction={() => {
        if (newForm === "price_lists" || newForm === null) {
          handleOpen("price_lists");
        } else {
          setOpen(t("modules.new_form_confirmation_message"), () => () => {
            handleOpen("price_lists");
          });
        }
      }}
    />
  );
};

export default PriceLists;
