import { Button, Stack, Typography } from "@mui/material";
import { useState } from "react";
import { useQuery } from "@tanstack/react-query";
import FormsDataGrid from "../../../components/DataGrid/FormsDataGrid";
import { ERPSystemRolesService } from "../../../api/ERPSystemRoles";
import InfoModal from "../../../components/Modals/InfoModal";
import FormSpinner from "../../../components/Spinners/FormSpinner";
import SingleUserRole from "./SingleUserRole";
import { useTranslation } from "react-i18next";

export const UserRolesModal = ({ open, onClose }) => {
  const { t } = useTranslation("global");
  return (
    <InfoModal
      open={open}
      handleClose={onClose}
      title={t("modules.users_management.user_roles.title")}
      content={<UserRoles onClose={onClose} />}
      width="30rem"
    />
  );
};

const UserRoles = ({ onClose }) => {
  const { t } = useTranslation("global");

  // Handling single user role modal
  const [openSingleUserRole, setOpenSingleUserRole] = useState(false);

  const handleOpenSingleUserRoleModal = () => setOpenSingleUserRole(true);
  const handleCloseSingleUserRoleModal = () => setOpenSingleUserRole(false);

  // Handling user role state
  const [userRole, setUserRole] = useState(null);

  const columns = [
    {
      field: "ERPUserRoleName",
      headerName: t("data_grid.headers.role_name"),
      flex: 1,
      renderCell: (params) => {
        return (
          <Typography
            style={{
              color: "#1790FF",
              fontSize: "14px",
              cursor: "pointer",
              userSelect: "none",
            }}
            onClick={() => {
              setUserRole(params.value);
              handleOpenSingleUserRoleModal();
            }}
          >
            {params.value}
          </Typography>
        );
      },
    },
    {
      field: "count",
      headerName: t("data_grid.headers.role_users_count"),
      width: 125,
    },
  ];

  const { data, isLoading } = useQuery({
    queryKey: ["userRoles"],
    queryFn: ERPSystemRolesService.getAllUserRoles,
  });

  if (isLoading) return <FormSpinner />;

  return (
    <>
      <Stack p={1.5} spacing={2}>
        <FormsDataGrid
          columns={columns}
          rows={data}
          height={400}
          toolbar
          showQuickFilter
          getRowId={(row) => row.idERPUserRole}
          disableMultipleRowSelection
        />
        <Button
          variant="contained"
          sx={{
            alignSelf: "flex-end",
          }}
          color="secondary"
          onClick={onClose}
        >
          {t("modules.buttons.cancel")}
        </Button>
      </Stack>

      <InfoModal
        open={openSingleUserRole}
        handleClose={handleCloseSingleUserRoleModal}
        title={userRole}
        content={
          <SingleUserRole
            onClose={handleCloseSingleUserRoleModal}
            userRole={userRole}
          />
        }
        width="65rem"
      />
    </>
  );
};
